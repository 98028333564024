import React, { Component } from 'react';
import styled from 'styled-components';
import { PRIVATE } from '../../../Paths';
import { NavLink } from 'react-router-dom';
import { forPhoneOnly } from '../../../utils/mediaqueries';
import { Logo } from './Logo';

class LogoWrapper extends Component {
	render() {
		return (
			<NavLink exact to={PRIVATE.HOME}>
				<LogoWrap><Logo type="LogoInverted" className="logo" /></LogoWrap>
			</NavLink>
		);
	}
}

const LogoWrap = styled.div`
	margin-bottom: 3em;

	${forPhoneOnly`
		display: none;
	`}
`;

export default LogoWrapper;
