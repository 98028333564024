import { applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { history } from '../store/history';
import { loggerMiddleware } from './loggerMiddleware';
import { logConfig } from '../config';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middleware = [
	thunk,
	routerMiddleware(history),
];

if (logConfig.middleware) {
	middleware.unshift(loggerMiddleware);
}

export const configureMiddleware = composeEnhancers(
	applyMiddleware(...middleware)
);
