import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { userManager } from '../../utils/userManager';
import { ReactComponent as BusWhite } from '../../images/bussen_vit.svg';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { SignupContainer } from './blocks/SignupContainer';
import { SignupBox } from './blocks/SignupBox';
import { SCHOOL } from '../../Paths';
import { HomePageMain } from './blocks/HomePageMain';
import LogoWrapper from '../common/blocks/LogoWrapper';
import { forPhoneOnly } from '../../utils/mediaqueries';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { ReactComponent as ChevronRight } from '../../images/chevron-right.svg';
import withTextContext from '../../utils/withTextContext';
import { getImageUrl, IMAGE_SIZES } from '../../reducer/cms';

class HomePage extends Component {
	componentDidMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			if (location.pathname === SCHOOL.HOME) {
				this.navigateToGroupsIfSignedIn();
			}
		});
	}

	componentDidUpdate() {
		this.navigateToGroupsIfSignedIn();
	}

	navigateToGroupsIfSignedIn = () => {
		if (this.props.user) {
			this.props.push(SCHOOL.GROUPS);
		}
	};

	componentWillUnmount() {
		this.unlisten();
	}

	login = () => {
		userManager.signinRedirect({ state: { location: SCHOOL.GROUPS } });
	};

	handleSuccessCallback = user => {
		this.props.push(user.state.location);
	};

	render() {
		const { texts } = this.props;
		const backgroundImage = texts.school.images.startpage.backgroundimage;
		const backgroundSmall = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_SMALL
		);
		const backgroundMedium = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_MEDIUM
		);
		const backgroundLarge = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_LARGE
		);

		return (
			<HomePageMain
				backgroundSmall={backgroundSmall}
				backgroundMedium={backgroundMedium}
				backgroundLarge={backgroundLarge}
			>
				<LogoContainer>
					<LogoWrapper />
				</LogoContainer>
				<HomePageSignupContainer>
					<SignupBox>
						<Fragment>
							<h1>Skolportalen</h1>
							<PageImage>
								<BusWhite />
							</PageImage>
							<SignInText>
								I Skolportalen kan du som administratör t.ex beställa resekort
								och biljetter samt administrera elever och biljetter
							</SignInText>
							<SecondaryButton onClick={this.login}>Logga in</SecondaryButton>
							<HomePageLink exact to={SCHOOL.REGISTER}>
								Skapa konto
								<ChevronRightIcon
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
							</HomePageLink>
						</Fragment>
					</SignupBox>
				</HomePageSignupContainer>
			</HomePageMain>
		);
	}
}

const LogoContainer = styled.div`
	margin-left: 2em;
	align-self: flex-start;

	${forPhoneOnly`
		align-self: center;
	`}
`;

const HomePageLink = styled(ThemedNavLink)`
	color: black;
`;

const ChevronRightIcon = styled(ChevronRight)`
	fill: none;
	stroke: black;
	margin-left: 4px;
`;

const HomePageSignupContainer = styled(SignupContainer)`
	max-width: 968px;
	width: 100%;
`;

const SignInText = styled.p`
	width: 300px;
	margin: 0 auto;
	display: flex;
	font-size: 15px;
`;

const PageImage = styled.div`
	background-color: ${props => props.theme.primary_color};
	width: 86px;
	height: 86px;
	border-radius: 86px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1em auto;

	svg {
		width: 100px;
		height: 100px;
	}

	path {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

export default withTextContext(
	connect(
		state => ({
			user: state.oidc.user,
		}),
		{ push }
	)(HomePage)
);
