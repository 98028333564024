import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

export class Info extends Component {
	render() {
		const { className, children, style } = this.props;

		return (
			<Container
				className={classNames('alert alert-info', className)}
				style={style}
				role="alert"
			>
				{children}
			</Container>
		);
	}
}

const Container = styled.p`
	margin-top: 1em;
	color: #000;
`;
