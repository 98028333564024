import { apiConfig } from './config';
import { getLastUrlSegment } from './utils/parsing';

const sendRequest = async ({ url, options = {} }) => {
	const response = await fetch(url, options);
	const json = await tryGetJsonBody(response);

	if (!response.ok) {
		throw Error(json ? json.message : response.statusText);
	}
	return json;
};

async function tryGetJsonBody(response) {
	try {
		return await response.json();
	} catch (error) {
		return;
	}
}

export async function updateCustomer(token, travellerId, customerModel) {
	const url = `${apiConfig.apiBaseUrl}/privatecustomer/${travellerId}`;
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(customerModel),
	};
	return await sendRequest({ url, options });
}

export async function updateCrmStudent(token, schoolId, travellerId, studentModel) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/contact/${travellerId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(studentModel),
	};
	return await sendRequest({ url, options });
}

export async function verifyField(travellerId, verifyModel, token) {
	const url = `${apiConfig.apiBaseUrl}/traveller/${travellerId}/verify`;
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(verifyModel),
	};
	return await sendRequest({ url, options });
}

export async function loadCustomer(token, travellerId) {
	const url = `${apiConfig.apiBaseUrl}/privatecustomer/${travellerId}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getTraveller(token) {
	const url = `${apiConfig.apiBaseUrl}/traveller`;
	const options = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function updateTraveller(token, traveller) {
	const url = `${apiConfig.apiBaseUrl}/traveller`;
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(traveller),
	};
	return await sendRequest({ url, options });
}

export async function updateTravellerReceiptEmail(token, traveller) {
	const url = `${apiConfig.apiBaseUrl}/traveller/receiptemail`;
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(traveller),
	};
	return await sendRequest({ url, options });
}

export async function getTravellerById(token, schoolId, travellerId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${travellerId}`;
	const options = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function updateTravellerById(
	token,
	schoolId,
	travellerId,
	traveller
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${travellerId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(traveller),
	};
	return await sendRequest({ url, options });
}

export async function getConsent(token, travellerId) {
	const url = `${apiConfig.apiBaseUrl}/privatecustomer/${travellerId}/consent`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function updateConsent(token, travellerId, consent) {
	const url = `${apiConfig.apiBaseUrl}/privatecustomer/${travellerId}/consent`;
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(consent),
	};
	return await sendRequest({ url, options });
}

export async function getLatestLegal() {
	const url = `${apiConfig.apiBaseUrl}/legal`;
	const options = {
		headers: {
			Accept: 'application/json',
		},
	};
	return await sendRequest({ url, options });
}

export async function createBearer(token, bearer) {
	const url = `${apiConfig.apiBaseUrl}/traveller/bearer`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(bearer),
	};
	return await sendRequest({ url, options });
}

export async function createBulkCardOrders(token, schoolId, cardOrder) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/card/bulkspecific`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(cardOrder),
	};
	return await sendRequest({ url, options });
}

export async function getSchoolBulkCardOrders(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/bulk`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getSchoolBulkCardOrderById(token, schoolId, orderId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/bulk/${orderId}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getSchoolBulkSpecificCardOrderById(token, schoolId, orderId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/bulkspecific/${orderId}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getPendingBulkSpecificOrders(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/bulkspecific/pending`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchFinishedTickets(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/finishedtickets`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchSchoolById(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchSchoolByVendorId(token, vendorId) {
	const url = `${apiConfig.apiBaseUrl}/school?vendorId=${vendorId}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function isExistingTraveller(personId) {
	const url = `${apiConfig.apiBaseUrl}/traveller?personId=${personId}`;
	const options = {
		headers: {
			Accept: 'application/json',
		},
	};
	const response = await fetch(url, options);
	return response.ok;
}

export async function getJourneyPoints(token, searchValue) {
	const url = `${apiConfig.apiBaseUrl
		}/journey/points?searchValue=${encodeURIComponent(searchValue)}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getJourneys(token, searchModel) {
	const url = `${apiConfig.apiBaseUrl}/journey`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(searchModel),
	};
	return await sendRequest({ url, options });
}

export async function getDeregistrationCauses(token) {
	const url = `${apiConfig.apiBaseUrl}/privatecustomer/cause`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function deregisterCustomer(token, travellerId, newReason) {
	const url = `${apiConfig.apiBaseUrl}/privatecustomer/${travellerId}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(newReason),
	};
	const response = await fetch(url, options);
	return response.ok;
}

export async function getProducts(journeySequence, token) {
	const url = `${apiConfig.apiBaseUrl}/product`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(journeySequence),
	};
	return await sendRequest({ url, options });
}

export async function fetchOffers(token, productType) {
	const url = `${apiConfig.apiBaseUrl}/product/offers?productType=${productType}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createIdentity(identityModel) {
	const url = `${apiConfig.apiBaseUrl}/identity`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(identityModel),
	};
	return await sendRequest({ url, options });
}

export async function verifyMobile(verificationModel) {
	const url = `${apiConfig.apiBaseUrl}/identity/verify`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(verificationModel),
	};
	return await sendRequest({ url, options });
}

export async function getTravellerParameters() {
	const url = `${apiConfig.apiBaseUrl}/parameters/traveller`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchPurseParameters(token) {
	const url = `${apiConfig.apiBaseUrl}/parameters/purse`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createProductSet(setId, productSetConfiguration, token) {
	const url = `${apiConfig.apiBaseUrl}/product/productsets/${setId}/new`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(productSetConfiguration),
	};
	return await sendRequest({ url, options });
}

export async function tryCreateProductSets(token, productSets) {
	const url = `${apiConfig.apiBaseUrl}/product/productsets/trycreate`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(productSets),
	};
	return await sendRequest({ url, options });
}

export async function getRepurchasableProductSets(token, travellerId, limit) {
	const url = `${apiConfig.apiBaseUrl}/product/repurchase/${travellerId}?limit=${limit}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createCart(cartInfo, token) {
	const url = `${apiConfig.apiBaseUrl}/cart`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(cartInfo),
	};
	return await sendRequest({ url, options });
}

export async function addItemToCart(cartId, itemInfo, token) {
	const url = `${apiConfig.apiBaseUrl}/cart/${cartId}/items`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(itemInfo),
	};

	const response = await fetch(url, options);
	const json = await tryGetJsonBody(response);

	if (!response.ok) {
		throw Error(json ? json.message : response.statusText);
	}

	const location = response.headers.get('location');
	const itemId = getLastUrlSegment(location);

	return { cart: json, itemId };
}

export async function deleteItemFromCart(cartId, itemId, token) {
	const url = `${apiConfig.apiBaseUrl}/cart/${cartId}/items/${itemId}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchPaymentMethods(token, walletId) {
	const url = `${apiConfig.apiBaseUrl}/wallet/${walletId}/payment_methods`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function purchaseCart(token, cartId, purchaseRequestModel) {
	const url = `${apiConfig.apiBaseUrl}/cart/${cartId}/purchase`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(purchaseRequestModel),
	};
	return await sendRequest({ url, options });
}

export async function deleteCart(token, cartId) {
	const url = `${apiConfig.apiBaseUrl}/cart/${cartId}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function verifyTransaction(token, transactionId) {
	const url = `${apiConfig.apiBaseUrl}/transaction/${transactionId}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function finalizeTransaction(token, transactionId) {
	const url = `${apiConfig.apiBaseUrl}/transaction/${transactionId}/finalize`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getBearers(token) {
	const url = `${apiConfig.apiBaseUrl}/traveller/bearer`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getPurse(token, walletId) {
	const url = `${apiConfig.apiBaseUrl}/wallet/${walletId}/purse`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function redeemPurseVoucher(token, walletId, voucher) {
	const url = `${apiConfig.apiBaseUrl}/wallet/${walletId}/purse/redeem?voucher=${voucher}`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function replenishPurse(token, walletId, replenishRequest) {
	const url = `${apiConfig.apiBaseUrl}/wallet/${walletId}/purse/replenish`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(replenishRequest),
	};
	return await sendRequest({ url, options });
}

export async function getTravellerProducts(token, travellerId) {
	const url = `${apiConfig.apiBaseUrl}/traveller/products/${travellerId}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function updateProductBearer(token, productId, bearerId) {
	const url = `${apiConfig.apiBaseUrl}/traveller/products/${productId}?bearerId=${bearerId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function activateProduct(token, productId) {
	const url = `${apiConfig.apiBaseUrl}/traveller/products/${productId}/activate`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function transferProduct(token, transferProduct, productId) {
	const url = `${apiConfig.apiBaseUrl}/traveller/products/${productId}/transfer`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(transferProduct),
	};
	return await sendRequest({ url, options });
}

export async function loanProduct(token, lendModel, productId) {
	const url = `${apiConfig.apiBaseUrl}/traveller/products/${productId}/lend`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(lendModel),
	};
	return await sendRequest({ url, options });
}

export async function fetchParticipants(token) {
	const url = `${apiConfig.apiBaseUrl}/participants`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getTransactionHistory(token) {
	const url = `${apiConfig.apiBaseUrl}/transaction/history`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function cancelTransaction(token, travellerId) {
	const url = `${apiConfig.apiBaseUrl}/transaction/${travellerId}/cancel`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function registerPaymentMethod(token, walletId, item) {
	const url = `${apiConfig.apiBaseUrl}/wallet/${walletId}/registered_payment_methods`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(item),
	};
	return await sendRequest({ url, options });
}

export async function finalizeRegisterPaymentMethod(
	token,
	walletId,
	paymentMethodId
) {
	const url = `${apiConfig.apiBaseUrl}/wallet/${walletId}/registered_payment_methods/${paymentMethodId}/finalize`;
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function deletePaymentCard(token, walletId, paymentCardId) {
	const url = `${apiConfig.apiBaseUrl}/wallet/${walletId}/registered_payment_methods/${paymentCardId}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await fetch(url, options);
	return response.ok;
}

export async function getSwishQR(token, qrModel) {
	const url = `${apiConfig.apiBaseUrl}/swish/qr`;
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(qrModel),
	};
	const response = await fetch(url, options);
	const blob = await response.blob();
	return URL.createObjectURL(blob);
}

export async function log(token, logModel) {
	const url = `${apiConfig.apiBaseUrl}/log`;
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(logModel),
	};
	return await sendRequest({ url, options });
}

export async function externalTest(model) {
	const url = `${apiConfig.apiBaseUrl}/external`;
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(model),
	};
	const response = await fetch(url, options);
	return response.ok;
}

export async function referStudent(
	contactId,
	phoneNumber
) {
	const url = `${apiConfig.apiBaseUrl
		}/traveller/refer?contactId=${contactId}&phoneNumber=${phoneNumber}`;
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	};
	return await sendRequest({ url, options });
}

export async function updateReferredAccount(
	contactId,
	phoneNumber
) {
	const url = `${apiConfig.apiBaseUrl
		}/traveller/refer/update?contactId=${contactId}&phoneNumber=${phoneNumber}`;
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchtDelayIntervals(token) {
	const url = `${apiConfig.apiBaseUrl}/issues/delay_intervals`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function deleteBearer(token, bearerId) {
	const url = `${apiConfig.apiBaseUrl}/traveller/bearer/${bearerId}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function registerDelayIncident(token, incidentModel) {
	const url = `${apiConfig.apiBaseUrl}/issues/incident`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(incidentModel),
	};
	return await sendRequest({ url, options });
}

export async function fetchCmsTexts() {
	const url = `${apiConfig.cmsApiUrl}/data/getjsonstore`;
	const options = {
		headers: {
			Accept: 'application/json',
		},
	};
	return await sendRequest({ url, options });
}

export async function getRegisteredIncidents(token, travellerId, email) {
	const url = `${apiConfig.apiBaseUrl}/issues?travellerId=${travellerId}&email=${email}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createCardOrder(token, cardOrder) {
	const url = `${apiConfig.apiBaseUrl}/cardOrder`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(cardOrder),
	};
	return await sendRequest({ url, options });
}

export async function fetchGroups(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/group`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createGroup(token, schoolId, adminId, groupName) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/group?administratorId=${adminId}`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({
			name: groupName,
		}),
	};
	return await sendRequest({ url, options });
}

export async function fetchStudentsInGroup(token, schoolId, groupId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/group/${groupId}/students`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function updateGroup(token, schoolId, adminId, groupId, group) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/group/${groupId}?administratorId=${adminId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(group),
	};
	return await sendRequest({ url, options });
}

export async function fetchBearersForGroup(
	token,
	schoolId,
	groupId,
	travellerIds
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/group/${groupId}/bearers`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(travellerIds),
	};
	return await sendRequest({ url, options });
}

export async function fetchStudents(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchCardLayouts(token) {
	const url = `${apiConfig.apiBaseUrl}/school/cardlayout`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchTicketTypes(token) {
	const url = `${apiConfig.apiBaseUrl}/school/tickettype`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getBearersFromStudent(token, schoolId, travellerId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${travellerId}/bearers`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createStudentBearer(
	token,
	bearer,
	schoolId,
	travellerId
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${travellerId}/bearers`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(bearer),
	};
	return await sendRequest({ url, options });
}

export async function fetchBearersForMultipleStudents(
	token,
	schoolId,
	travellerIds
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/bearers`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(travellerIds),
	};
	return await sendRequest({ url, options });
}

export async function fetchSchoolOrgAdmin(token, id) {
	const encodedId = encodeURIComponent(id);
	const url = `${apiConfig.apiBaseUrl}/school/admin/${encodedId}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function importStudents(
	token,
	schoolId,
	administratorId,
	groupId,
	file
) {
	let formData = new FormData();
	formData.append('studentList', file);

	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/group/importstudents?administratorId=${administratorId}&groupId=${groupId}`;

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: formData,
	};
	return await sendRequest({ url, options });
}

export async function fetchAdmins(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/admin/all?schoolId=${schoolId}`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchAdminRoles(token) {
	const url = `${apiConfig.apiBaseUrl}/school/admin/roles`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createAdmin(token, orgAdmin, school, admin) {
	const url = `${apiConfig.apiBaseUrl}/school/admin`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({
			schoolId: school.schoolId,
			createdBy: orgAdmin.id,
			admin,
		}),
	};
	return await sendRequest({ url, options });
}

export async function removeAdmin(token, orgAdmin, adminId) {
	const url = `${apiConfig.apiBaseUrl}/school/admin/${adminId}?orgAdminId=${orgAdmin.id}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function deleteStudentFromGroup(
	token,
	schoolId,
	groupId,
	travellerId
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/group/${groupId}/student/${travellerId}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function deleteStudentBearer(
	token,
	schoolId,
	bearerId,
	travellerId
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${travellerId}/bearers/${bearerId}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function getStudentTickets(token, schoolId, travellerId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${travellerId}/tickets`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function updateStudentProductBearer(
	token,
	schoolId,
	productId,
	mtbBearerId,
	travellerId
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${travellerId}/tickets/${productId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ mtbBearerId }),
	};
	return await sendRequest({ url, options });
}

export async function getTicketOrderProducts(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/products`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchTicketsForMultipleStudents(
	token,
	schoolId,
	travellerIds
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/tickets`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(travellerIds),
	};

	return await sendRequest({ url, options });
}

export async function cancelStudentTicket(token, schoolId, ticketId, adminId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/tickets/${ticketId}/cancel?administratorId=${adminId}`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};

	return await sendRequest({ url, options });
}

export async function createTicketOrder(token, ticketOrder) {
	const url = `${apiConfig.apiBaseUrl}/school/${ticketOrder.administratorId}/order/ticketorder`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(ticketOrder),
	};

	return await sendRequest({ url, options });
}

export async function fetchOrderHistoryTickets(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/ticket`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchOrderTicketDetails(token, schoolId, ticketOrderId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/ticket/${ticketOrderId}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchPurseReplenishValues(token) {
	const url = `${apiConfig.apiBaseUrl}/wallet/pursereplenishvalues`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchHomeCountys(token) {
	const url = `${apiConfig.apiBaseUrl}/school/homemunicipalities`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createStudent(token, admin, groupId, student) {
	const url = `${apiConfig.apiBaseUrl}/school/${admin.schoolId}/student`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({
			student,
			groupId,
			adminId: admin.id,
		}),
	};
	return await sendRequest({ url, options });
}

export async function removeGroup(token, orgAdmin, groupId) {
	const url = `${apiConfig.apiBaseUrl}/school/${orgAdmin.schoolId}/group/${groupId}?orgAdminId=${orgAdmin.id}`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	const response = await fetch(url, options);
	return response.ok;
}

export async function fetchAllReferredAccounts(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/referredaccounts`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchGroupReferredAccounts(token, schoolId, groupId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/group/${groupId}/referredaccounts`;
	const options = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function deleteReferredAccount(token, schoolId, contactId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/${contactId}/referredaccount`;
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function transferTicketToReferredAccount(
	token,
	schoolId,
	productId
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/referredaccounts/transfer/${productId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function returnTicketFromReferredAccount(
	token,
	schoolId,
	productId
) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/student/referredaccounts/return/${productId}`;
	const options = {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchLoanTransactions(token, schoolId, mtbProductIds) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/loantransactions?mtbProductIds=${mtbProductIds}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchCultureTrips(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/culturetrip`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchCultureTripDestinations(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/culturetrip/destinations`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function postCultureTripApplication(token, schoolId, cultureTripApplication) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/culturetrip`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(cultureTripApplication),
	};
	return await sendRequest({ url, options });
}

export async function fetchContactPersonHasApp(token, schoolId, mobilePhone) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/culturetrip/checkapp/${mobilePhone}`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function fetchBulkProducts(token, schoolId) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/getbulkproducts`;
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
	return await sendRequest({ url, options });
}

export async function createBulkMultipleCardOrder(token, schoolId, cardOrder) {
	const url = `${apiConfig.apiBaseUrl}/school/${schoolId}/order/card/bulkmultiplecardorder`;
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(cardOrder),
	};
	return await sendRequest({ url, options });
}
