import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

export class Error extends Component {
	renderBody = () => {
		const { children } = this.props;

		if (children) {
			return children;
		}

		return (
			<>
				<span
					className="glyphicon glyphicon-exclamation-sign"
					aria-hidden="true"
				/>
				&nbsp;Ett oväntat fel inträffade. Var god försök igen senare och
				kontakta kundtjänst ifall problemet kvarstår.
			</>
		);
	};

	render() {
		const { className } = this.props;

		return (
			<Container className={classNames('alert alert-danger', className)} role="alert">
				{this.renderBody()}
			</Container>
		);
	}
}

const Container = styled.p`
	margin-top: 1em;
	color: #000;
`;
