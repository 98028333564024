/* eslint-disable no-restricted-globals */
import { Component, Children } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

const PREFIX = '@@scroll/';

class ScrollBehavior extends Component {
	static propTypes = {
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		children: PropTypes.any,
	};

	state = { prevLocation: null };

	static locationVisited = {};

	componentDidMount() {
		if ('scrollRestoration' in window.history) {
			history.scrollRestoration = 'manual';
		}
		window.addEventListener('scroll', this.onScroll);
	}

	componentWillUnmount() {
		if ('scrollRestoration' in window.history) {
			history.scrollRestoration = 'auto';
		}
		window.removeEventListener('scroll', this.onScroll);
	}

	componentDidUpdate(nextProps) {
		if (nextProps.location !== this.props.location) {
			this.setState({ prevLocation: this.props.location });
		}
	}

	onScroll = () => {
		requestAnimationFrame(() => {
			const locationKey = ScrollBehavior.getLocationKey(
				this.props.location.key
			);
			ScrollBehavior.locationVisited[locationKey] = ScrollBehavior.getScrollY();
		});
	};

	static getScrollY() {
		if (window.scrollY) return window.scrollY;

		const y =
			(document.documentElement && document.documentElement.scrollTop) ||
			document.body.scrollTop;

		return y || 0;
	}

	static getLocationKey(key) {
		return PREFIX + (key || '');
	}

	static scrollTo(Y) {
		requestAnimationFrame(() => {
			window.scrollTo(0, Y);
		});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			location,
			history: { action },
		} = nextProps;
		const { prevLocation } = prevState;

		if (location !== prevLocation) {
			if (action === 'PUSH') {
				// new navigation - scroll to top
				ScrollBehavior.scrollTo(0);
			} else {
				const key = ScrollBehavior.getLocationKey(location.key);
				if (ScrollBehavior.locationVisited.hasOwnProperty(key)) {
					const scrollY = ScrollBehavior.locationVisited[key];
					ScrollBehavior.scrollTo(scrollY);
				}
			}
		}

		return null;
	}

	render() {
		return Children.only(this.props.children);
	}
}

export default withRouter(ScrollBehavior);
