import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	actionCreators as schoolActions,
	getSchool,
} from '../../../reducer/school';
import { getAdmin } from '../../../reducer/school/admins';
import { TextInput } from '../../common/elements/TextInput';
import { regexToInputPattern, Validation } from '../../../utils/validation';

const isValidPostalCode = postalCode =>
	Validation.postalCode.test(postalCode) && regexToInputPattern(postalCode).length === 5;

const isValidAddress = (schoolState) => {
	return (
		schoolState.schoolName.length > 0 &&
		schoolState.attention.length > 0 &&
		schoolState.address.length > 0 &&
		schoolState.city.length > 0 &&
		isValidPostalCode(schoolState.postalCode)
	);
};

class AddressForm extends Component {
	state = {
		schoolName: '',
		attention: '',
		address: '',
		postalCode: '',
		city: '',
		validButton: false,
	};

	componentDidMount() {
		this.fillAddressInfo();
	}

	fillAddressInfo = async () => {
		const { school, admin } = this.props;
		let schoolState = this.state;
		schoolState = {
			schoolName: schoolState.schoolName ? schoolState.schoolName : school.deliveryOrganisationName,
			attention: schoolState.attention ? schoolState.attention : admin.name,
			address: schoolState.address
				? schoolState.address
				: school.deliveryAddressLine1,
			postalCode: schoolState.postalCode
				? schoolState.postalCode
				: school.deliveryAddressPostalCode,
			city: schoolState.city ? schoolState.city : school.deliveryAddressCity,
		};

		await this.setState(schoolState);
		schoolState.validButton = isValidAddress(schoolState);
		this.props.getAddressInfo(schoolState);
	};

	handleChange = target => {
		let schoolState = this.state;
		schoolState[target.id] = target.value;
		schoolState.validButton = isValidAddress(schoolState);

		this.setState({
			[target.id]: target.value,
			validButton: schoolState.validButton,
		});
		this.props.getAddressInfo(schoolState);
	};

	render() {
		const { schoolName, attention, address, postalCode, city } = this.state;

		return (
			<>
				<h2>Leveransadress</h2>

				<label htmlFor="school">Skola*</label>
				<TextInput
					type="text"
					value={schoolName}
					handleChange={this.handleChange}
					className="form-control"
					id="schoolName"
				/>
				<label htmlFor="attention">Attention*</label>
				<TextInput
					type="text"
					value={attention}
					handleChange={this.handleChange}
					className="form-control"
					id="attention"
				/>
				<label htmlFor="address">Gatuadress*</label>
				<TextInput
					type="text"
					value={address}
					handleChange={this.handleChange}
					className="form-control"
					id="address"
				/>
				<div className="row">
					<div className="form-group col-xs-12 col-sm-4">
						<label htmlFor="postalCode">Postnummer*</label>
						<TextInput
							type="number"
							value={postalCode}
							handleChange={this.handleChange}
							className="form-control"
							id="postalCode"
							pattern={regexToInputPattern(Validation.postalCode)}
							validateOnChange={true}
						/>
					</div>
					<div className="form-group col-xs-12 col-sm-8">
						<label htmlFor="city">Postort*</label>
						<TextInput
							type="text"
							value={city}
							handleChange={this.handleChange}
							pattern={regexToInputPattern(Validation.city)}
							validateOnChange={true}
							className="form-control"
							id="city"
						/>
					</div>
				</div>
			</>
		);
	}
}

export default connect(
	store => ({
		school: getSchool(store),
		admin: getAdmin(store),
	}),
	{
		...schoolActions,
	}
)(AddressForm);
