import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Dropdown } from '../elements/Dropdown';
import { TravellerTypeControl } from './TravellerTypeControl';
import startsWith from 'lodash/startsWith';
import { TAG_PREFIXES } from '../../../reducer/products';
import { forZoomedInUsersWithSmallScreens } from '../../../utils/mediaqueries';

const getSelectedTravellerTypes = (types, counts) => {
	const keys = Object.keys(counts);
	let selectedTravellerTypes = keys.reduce((acc, key) => {
		const count = counts[key];
		const type = types.find(t => t.name === key);

		if (type && count) {
			acc.push({ title: type.title, count, name: type.name });
		}
		return acc;
	}, []);

	return selectedTravellerTypes;
}

const getSelectedItemTitle = (types, counts) => {
	let selectedTravellerTypes = getSelectedTravellerTypes(types, counts)

	const title =
		selectedTravellerTypes
			.map(t => {
				const current = types.find(x => x.name === t.name);
				return current.valueType === 'boolean'
					? t.title
					: `${t.count} ${t.title}`;
			})
			.join(', ') || 'Välj resenärstyp';

	return title;
}

export class MultipleTravellerTypeSelector extends React.Component {
	state = { counts: {}, title: '', lastTypes: undefined };

	componentDidMount() {
		this.selectTraveller();
	}

	componentDidUpdate() {
		this.selectTraveller();
	}

	selectTraveller = () => {
		const { types } = this.props;
		const { counts } = this.state;
		const selectedTravellerTypes = getSelectedTravellerTypes(types, counts);
		this.props.onSelectTraveller(selectedTravellerTypes);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (isEqual(nextProps.types, prevState.lastTypes)) return null;

		// clear component if no traveller types
		if (isEmpty(nextProps.types)) return { counts: {}, title: '', lastTypes: undefined };

		let counts = nextProps.types.reduce(
			(acc, type, idx) => {
				let count = 0;

				if (idx === 0) count = 1; // select 1 of first type as default
				if (type.valueType === 'boolean') count = type.default === 'true' ? 1 : 0;
				if (type.valueType === 'number') count = Number(type.default);

				return { ...acc, [type.name]: count };
			},
			{}
		);

		return {
			counts,
			title: getSelectedItemTitle(nextProps.types, counts),
			lastTypes: nextProps.types,
		};
	}

	inputChange = ({ target }) => {
		if (!target.checkValidity()) {
			return;
		}

		this.setState(({ counts }) => {
			const type = target.id;
			const value = parseInt(target.value, 10);

			if (this.isTravellerCategory(type) &&
					this.isMinValueAndOnlySelected(value, type, counts)) {
				return null;
			}

			const newCounts = { ...counts, [type]: value };

			return {
				counts: newCounts, 
				title: getSelectedItemTitle(this.props.types, newCounts),
			};
		});
	};

	handleCheckboxClick = (name, value) => {
		this.setState(({ counts }) => {
			const newCounts = { ...counts, [name]: value ? 1 : 0 };

			return {
				counts: newCounts,
				title: getSelectedItemTitle(this.props.types, newCounts),
			};
		});
	};

	increment = type => () => {
		this.setState(({ counts }) => {
			let value = counts[type] || 0;
			
			const newCounts = { ...counts, [type]: ++value };

			return {
				counts: newCounts,
				title: getSelectedItemTitle(this.props.types, newCounts),
			};
		});
	};

	decrement = type => () => {
		this.setState(({ counts }) => {
			let value = counts[type] || this.minValue;

			if (this.isMinValue(value)) return null;

			if (this.isTravellerCategory(type) &&
					this.isNextToMinValueAndOnlySelected(value, type, counts)) {
				return null;
			}

			const newCounts = { ...counts, [type]: --value };

			return {
				counts: newCounts,
				title: getSelectedItemTitle(this.props.types, newCounts),
			};
		});
	};

	isTravellerCategory = type => startsWith(type, TAG_PREFIXES.TRAVELLER);

	getTitle = () => this.state.title;

	minValue = 0;
	isMinValue = value => value === this.minValue;
	isMinValueAndOnlySelected = (value, type, counts) => {
		return value === this.minValue && this.isOnlySelected(type, counts);
	};
	isNextToMinValueAndOnlySelected = (value, type, counts) => {
		return value === this.minValue + 1 && this.isOnlySelected(type, counts);
	};
	isOnlySelected = (type, counts) => {
		return Object.keys(counts)
			.filter(this.isTravellerCategory)
			.filter(k => k !== type)
			.every(k => counts[k] === this.minValue);
	};

	render() {
		const { types = [] } = this.props;
		const { counts } = this.state;

		if (types.length === 0) {
			return (
				<>
					<h3>{this.props.texts.private.buyticketpage.tickettypeoptions.travellertype}</h3>

					<Dropdown
						id="TravellerTypeDropdown"
						disabled={true}
						onSelect={() => {}}
						items={[]}
						renderSelectedItem={() => (
							<p>Välj resa för att se resenärstyper</p>
						)}
						renderItem={() => {}}
					/>
				</>
			);
		}

		return (
			<>
				<h3>{this.props.texts.private.buyticketpage.tickettypeoptions.travellertype}</h3>

				<Dropdown
					id="TravellerTypeDropdown"
					onSelect={item => {
						return true; // do not close dropdown on click
					}}
					items={[{ title: types[0].title }]}
					renderSelectedItem={() => <p style={{ margin: '0' }}>{this.getTitle()}</p>}
					renderItem={() => (
						<Container>
							{types.map((t, idx) => (
								<TravellerTypeControl
									type={t}
									count={counts[t.name]}
									key={idx}
									onIncrement={this.increment}
									onDecrement={this.decrement}
									onInputChange={this.inputChange}
									onCheckboxClick={this.handleCheckboxClick}
								/>
							))}
						</Container>
					)}
				/>
			</>
		);
	}
}

const Container = styled.div`
	margin-top: 15px;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;

	${forZoomedInUsersWithSmallScreens`
		display: flex;
    	flex-wrap: wrap;
    	flex-direction: column;
		gap: 2rem;
	`}
`;
