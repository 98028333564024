import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CloseCardButton } from '../common/blocks/CloseCardButton';
import { PopUpBox, PopupContainer } from '../common/elements/PopUpBox';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { Error } from '../common/elements/Error';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import {
	actionCreators as adminActions,
	onlyHasOneOrgAdmin,
	hasOrgAdminRole,
} from '../../reducer/school/admins';
import { DefinitionList } from '../common/elements/DefinitionList';
import { ConfirmBox } from '../common/elements/ConfirmBox';

class RemoveAdminPopup extends Component {
	state = { showConfirmation: false, showError: false, verifyRemoval: false };

	removeAdmin = async () => {
		const { admin } = this.props;

		try {
			await this.props.removeAdmin(admin.id);
			this.setState({ showConfirmation: true });
		} catch {
			this.setState({ showError: true, verifyRemoval: false });
		}
	};

	toggleVerifyRemoval = () =>
		this.setState(state => ({ verifyRemoval: !state.verifyRemoval }));

	render() {
		const {
			admin,
			onClosePopup,
			onlyHasOneOrgAdmin,
		} = this.props;
		const { showError, showConfirmation, verifyRemoval } = this.state;

		const isOrgAdminRole = hasOrgAdminRole(admin);

		if (showConfirmation) {
			return (
				<PopupContainer>
					<ConfirmationCard
						onClick={onClosePopup}
						title="Klart!"
						text="Administratören är nu borttagen."
					/>
				</PopupContainer>
			);
		}

		if (verifyRemoval) {
			return (
				<ConfirmBox
					title="Vänta lite!"
					info={<p>Är du säker på att du vill radera administratören?</p>}
					onContinue={this.removeAdmin}
					onClose={onClosePopup}
					onBack={this.toggleVerifyRemoval}
					confirmButtonText="Radera"
				/>
			);
		}

		return (
			<PopUpBox>
				<CloseCardButton onClick={onClosePopup} />
				<h2 style={{ margin: '0 0 1em 0' }}>Administratör</h2>

				<DefinitionListAdmin>
					<dt>Namn</dt>
					<dd>{admin.name}</dd>
					<dt>E-post</dt>
					<dd>{admin.email}</dd>
					<dt>Personnummer</dt>
					<dd>{admin.personalIdentityNumber}</dd>
					<dt>Typ av administratör</dt>
					<dd>{admin.roleName}</dd>
					<dt>Telefonnummer</dt>
					<dd>{admin.phoneNumber}</dd>
				</DefinitionListAdmin>

				{showError && <Error />}

				<RemoveAdminButton
					onClick={this.toggleVerifyRemoval}
					disabled={isOrgAdminRole && onlyHasOneOrgAdmin}
				>
					Radera administratör
				</RemoveAdminButton>
			</PopUpBox>
		);
	}
}

const DefinitionListAdmin = styled(DefinitionList)`
	max-width: 350px;
	text-align: left;
`;

const RemoveAdminButton = styled(SecondaryButton)`
	max-width: 250px;
	width: 100%;
	margin-top: 1em;
`;

export default connect(
	store => ({
		onlyHasOneOrgAdmin: onlyHasOneOrgAdmin(store),
		hasOrgAdminRole: hasOrgAdminRole,
	}),
	{ ...adminActions }
)(RemoveAdminPopup);
