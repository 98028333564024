import React, { Component } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { Card } from '../../common/blocks/Card';
import { connect } from 'react-redux';
import withTextContext from '../../../utils/withTextContext';
import { Dropdown } from '../../common/elements/Dropdown';
import { Incrementer } from '../../common/elements/Incrementer';
import { StepVisualizer } from '../../common/blocks/StepVisualizer';
import {
	actionCreators as cultureTripActions,
	getCultureTripDestinations,
	isLoadingCultureTripDestinations,
} from '../../../reducer/school/culturetrips';
import isEmpty from 'lodash/isEmpty';
import { actionCreators as groupActions } from '../../../reducer/groups';
import { getAllGroups, isLoadingGroups } from '../../../reducer';
import { push } from 'connected-react-router';
import { SCHOOL } from '../../../Paths';
import CultureTripWrapper from './CultureTripWrapper';

//Step 1 in form
class ChooseTravelers extends Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef();
	}

	state = {
		selectedDestinationId: undefined,
		selectedGroupId: undefined,
		selectedAdultTravelerCount: 1,
		selectedChildTravelerCount: 0,
		selectedYouthTravelerCount: 0,
	};

	componentDidMount() {
		const { cultureTripDestinations, groups } = this.props;

		if (isEmpty(cultureTripDestinations))
			this.props.getCultureTripDestinations();

		if (isEmpty(groups)) this.props.getGroups();

		this.props.clearAppCheck();

		this.containerRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}

	saveAndContinue = e => {
		e.preventDefault();

		const {
			selectedDestinationId,
			selectedGroupId,
			selectedAdultTravelerCount,
			selectedChildTravelerCount,
			selectedYouthTravelerCount,
		} = this.state;
		const { cultureTripDestinations, groups } = this.props;

		const travelerData = {
			destination: cultureTripDestinations.find(
				d => d.id === selectedDestinationId
			),
			group: groups[selectedGroupId],
			adultTravellerCount: selectedAdultTravelerCount,
			childTravellerCount: selectedChildTravelerCount,
			youthTravellerCount: selectedYouthTravelerCount,
		};

		this.props.setTravelerInfo(travelerData);
		this.props.push(SCHOOL.CULTURE_TRIP_DEPARTURE);
	};

	handleChange = input => event => {
		this.setState({ [input]: event.target.value });
	};

	onSelectDestination = item => {
		const { selectedDestinationId } = this.state;
		if (item.id !== selectedDestinationId)
			this.setState({
				selectedDestinationId: item.id,
			});
	};

	onSelectGroup = item => {
		const { selectedGroupId } = this.state;
		if (item.groupId !== selectedGroupId)
			this.setState({
				selectedGroupId: item.groupId,
			});
	};

	onAdultTravelersChange = count =>
		this.setState({ selectedAdultTravelerCount: count });

	onChildTravelersChange = count =>
		this.setState({ selectedChildTravelerCount: count });

	onYouthTravelersChange = count =>
		this.setState({ selectedYouthTravelerCount: count });

	hasTravelers = () =>
		this.state.selectedAdultTravelerCount +
			this.state.selectedChildTravelerCount +
			this.state.selectedYouthTravelerCount >
		0;

	render() {
		const {
			selectedDestinationId,
			selectedGroupId,
			selectedAdultTravelerCount,
			selectedChildTravelerCount,
			selectedYouthTravelerCount,
		} = this.state;
		const {
			cultureTripDestinations,
			groups,
			isLoadingGroups,
			isLoadingCultureTripDestinations,
		} = this.props;
		let displayGroups = (Object.values(groups) || []).map(g => ({
			...g,
			title: g.name,
		}));

		const displayDestinations = cultureTripDestinations.map(d => ({
			...d,
			title: d.name,
		}));

		const emptyCultureTripDestinationText = isLoadingCultureTripDestinations
			? 'Besöksmål laddas...'
			: 'Inga besöksmål hittades';

		const emptyGroupDropdownText = isLoadingGroups
			? 'Grupper laddas...'
			: 'Inga grupper hittades';

		// The markup for the Step 1 UI
		return (
			<CultureTripWrapper>
				<Container ref={this.containerRef}>
					<CardBox>
						<h3>Ansökan om kulturresa</h3>
						<FormText>
							Varje läsår får länets skolklasser möjlighet att resa till en
							kulturorganisation gratis. Varje elev får en resa per läsår, genom
							att du som företräder skolan eller klassen ansöker om en
							Kulturresa.
						</FormText>
						<InnerContainer>
							<FormTextRequired>Besöksmål</FormTextRequired>
							<FormRow>
								{isEmpty(displayDestinations) ? (
									<TripDropdown
										onSelect={() => {}}
										items={[
											{ id: '-1', title: emptyCultureTripDestinationText },
										]}
										renderSelectedItem={item => <span>{item.title}</span>}
										renderItem={item => <span>{item.title}</span>}
									/>
								) : (
									<TripDropdown
										onSelect={this.onSelectDestination}
										items={displayDestinations}
										renderSelectedItem={item => <span>{item.title}</span>}
										renderItem={item => (
											<TripDropdownSpan>{item.title}</TripDropdownSpan>
										)}
									/>
								)}
							</FormRow>
							<FormTextRequired>Klass / avdelning</FormTextRequired>
							<FormRow>
								{isEmpty(displayGroups) ? (
									<TripDropdown
										onSelect={() => {}}
										items={[{ id: '-1', title: emptyGroupDropdownText }]}
										renderSelectedItem={item => <span>{item.title}</span>}
										renderItem={item => <span>{item.title}</span>}
									/>
								) : (
									<TripDropdown
										onSelect={this.onSelectGroup}
										items={displayGroups}
										renderSelectedItem={item => <span>{item.title}</span>}
										renderItem={item => <span>{item.title}</span>}
									/>
								)}
							</FormRow>
							<IncrementHeader>Resenärer</IncrementHeader>
							<IncrementRow>
								<p>Vuxna (fr.o.m. 20 år)</p>
								<Incrementer
									minValue={0}
									initialValue={selectedAdultTravelerCount}
									onIncrement={this.onAdultTravelersChange}
									onDecrement={this.onAdultTravelersChange}
									onInputChange={this.onAdultTravelersChange}
								/>
							</IncrementRow>
							<IncrementRow>
								<p>Ungdom (7-19 år)</p>
								<Incrementer
									minValue={0}
									initialValue={selectedYouthTravelerCount}
									onIncrement={this.onYouthTravelersChange}
									onDecrement={this.onYouthTravelersChange}
									onInputChange={this.onYouthTravelersChange}
								/>
							</IncrementRow>
							<IncrementRow>
								<p>Barn (under 7 år)</p>
								<Incrementer
									minValue={0}
									initialValue={selectedChildTravelerCount}
									onIncrement={this.onChildTravelersChange}
									onDecrement={this.onChildTravelersChange}
									onInputChange={this.onChildTravelersChange}
								/>
							</IncrementRow>
						</InnerContainer>
						<Button
							onClick={this.saveAndContinue}
							disabled={
								!selectedDestinationId ||
								!selectedGroupId ||
								!this.hasTravelers()
							}
						>
							Nästa
						</Button>
						<StepVisualizer activeStep={1} steps={5} />
					</CardBox>
				</Container>
			</CultureTripWrapper>
		);
	}
}

const Container = styled.div`
	justify-content: center;
`;

const CardBox = styled(Card)`
	display: block;
	align-items: center;
	width: 90vw;
	padding: 3em;
	margin: 1em auto;
`;

const FormRow = styled.div`
	clear: both;
	margin-top: 1em;
`;

const FormText = styled.div`
	max-width: 295px;
	text-align: center;
	margin: auto;
`;

const InnerContainer = styled.div`
	margin-top: 2em;
`;

const FormTextRequired = styled.div`
	float: left;
	margin-top: 10px;
	font-size: 16px;
	:after {
		content: ' *';
		color: black;
	}
`;

const TripDropdown = styled(Dropdown)`
	float: right;
	margin-bottom: 1em;
	width: 100%;
	text-align: left;
`;

const TripDropdownSpan = styled.span`
	white-space: normal;
`;

const IncrementHeader = styled.h4`
	margin: 30px 0;
`;

const IncrementRow = styled.div`
	display: flex;
	align-items: center;
	margin: 30px;

	p {
		margin: 0;
		flex-grow: 1;
		text-align: left;
	}
`;

const Button = styled(SecondaryButton)`
	width: 100%;
	max-width: 300px;
	margin-top: 1em;
`;

export default withTextContext(
	connect(
		store => ({
			cultureTripDestinations: getCultureTripDestinations(store),
			groups: getAllGroups(store),
			isLoadingCultureTripDestinations: isLoadingCultureTripDestinations(store),
			isLoadingGroups: isLoadingGroups(store),
		}),
		{ ...cultureTripActions, ...groupActions, push }
	)(ChooseTravelers)
);
