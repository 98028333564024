import React from "react";
import styled from 'styled-components';
import { forTabletPortraitUp } from "../../../utils/mediaqueries";

export function SignupContainer(props) {
	return (
		<Container {...props}>
			{props.children}
		</Container>
	);
}

const Container = styled.div`
	${forTabletPortraitUp`
		display: flex;
		justify-content: space-between;

		& > div {
			width: 58%;
		}
	`}
`;
