import { createIdentity, verifyMobile } from '../api';

export const IDENTITY_ERRORS = {
	CREATE_ERROR: 'CREATE_ERROR',
	VERIFICATION_ERROR: 'VERIFICATION_ERROR',
};

const initialState = {
	data: null,
	isLoading: false,
	errorMessage: '',
};

export const actionCreators = {
	createIdentity: identityModel => async dispatch => {
		dispatch({ type: 'CREATE_IDENTITY_REQUEST' });

		try {
			await createIdentity(identityModel);
			dispatch({ type: 'CREATE_IDENTITY_SUCCESS' });
		} catch (error) {
			dispatch({ type: 'CREATE_IDENTITY_FAILURE', message: IDENTITY_ERRORS.CREATE_ERROR });
		}
	},
	verifyMobile: verificationModel => async dispatch => {
		dispatch({ type: 'VERIFY_MOBILE_REQUEST' });

		try {
			let identity = await verifyMobile(verificationModel);
			dispatch({ type: 'VERIFY_MOBILE_SUCCESS', identity });
		} catch (error) {
			dispatch({
				type: 'VERIFY_MOBILE_FAILURE',
				message: IDENTITY_ERRORS.VERIFICATION_ERROR,
			});
		}
	},
	clearErrorMessage: () => dispatch => {
		dispatch({ type: 'CLEAR_IDENTITY_ERROR' });
	},
};

export default function indentityReducer (state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'CREATE_IDENTITY_REQUEST':
		case 'VERIFY_MOBILE_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'CREATE_IDENTITY_SUCCESS':
		case 'VERIFY_MOBILE_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.identity,
				errorMessage: '',
			};
		case 'CREATE_IDENTITY_FAILURE':
		case 'VERIFY_MOBILE_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'CLEAR_IDENTITY_ERROR':
			return {
				...state,
				isLoading: false,
				errorMessage: '',
			};
		default:
			return state;
	}
};

export function hasCreateError(state) {
	return state.errorMessage === IDENTITY_ERRORS.CREATE_ERROR;
}

export function hasVerifyError(state) {
	return state.errorMessage === IDENTITY_ERRORS.VERIFICATION_ERROR;
}

export function isLoading(state) {
	return state.isLoading;
}

export function getIdentity(state) {
	return state.data;
}
