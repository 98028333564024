import {
	createCart,
	addItemToCart,
	purchaseCart,
	deleteItemFromCart,
	deleteCart,
	createCardOrder,
	getPurse as fetchPurse,
} from '../api';
import {
	getToken,
	getTravellerId,
	getCartId as getCartIdFromStore,
	getWalletId,
	getPurchaseReceipt,
	getCardOrders as getCardOrdersFromStore,
	getSelectedProductSet,
} from '../reducer';
import { urlConfig } from '../config';
import { isIosDevice } from "./../utils/isIosDevice"

const initialState = {
	data: null,
	purchase: null,
	isLoading: false,
	manualActivation: true,
	errorMessage: '',
	bearer: undefined,
	newCartItem: {
		manualActivation: true,
		count: 1,
		mtbBearerId: undefined,
		orderAddress: undefined,
	},
	cardOrders: [],
};

export const ERRORS = {
	DELETE_ITEM_FROM_CART: 'DELETE_ITEM_FROM_CART',
	PURCHASE_CART: 'PURCHASE_CART',
};

export const BEARER_OPTIONS = {
	NONE: 'NONE',
	NEW: 'NEW',
};

export const isNoBearer = id => id === BEARER_OPTIONS.NONE;
export const isNewBearer = id => id === BEARER_OPTIONS.NEW;

export const actionCreators = {
	createCart: () => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_CART_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = getTravellerId(state);
		const cartInfo = {
			name: 'Mitt Konto',
			owner: { id: travellerId, type: 'traveller' },
		};

		try {
			const cart = await createCart(cartInfo, token);
			dispatch({ type: 'CREATE_CART_SUCCESS', cart });
		} catch (error) {
			dispatch({ type: 'CREATE_CART_FAILURE', message: error.message });
		}
	},
	addItemToCart: newCartItem => async (dispatch, getState) => {
		dispatch({ type: 'ADD_ITEM_TO_CART_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = getTravellerId(state);
		const cartId = getCartIdFromStore(state);
		const selectedProductSet = getSelectedProductSet(state);

		let mtbBearerId = newCartItem.mtbBearerId;
		if (isNewBearer(mtbBearerId) || isNoBearer(mtbBearerId)) {
			mtbBearerId = '';
		}

		const itemInfo = {
			productSetId: selectedProductSet.id,
			count: newCartItem.count,
			manualActivation: newCartItem.manualActivation,
			mtbBearerId,
			owner: { id: travellerId, type: 'traveller' },
		};

		try {
			const { cart, itemId } = await addItemToCart(cartId, itemInfo, token);
			dispatch({ type: 'ADD_ITEM_TO_CART_SUCCESS', cart });
			return itemId;
		} catch (error) {
			dispatch({ type: 'ADD_ITEM_TO_CART_FAILURE', message: error.message });
		}
	},
	deleteItemFromCart: itemId => async (dispatch, getState) => {
		dispatch({ type: 'DELETE_ITEM_FROM_CART_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const cartId = getCartIdFromStore(state);

		try {
			const deletedCart = await deleteItemFromCart(cartId, itemId, token);
			dispatch({ type: 'DELETE_ITEM_FROM_CART_SUCCESS', deletedCart });
		} catch (error) {
			dispatch({
				type: 'DELETE_ITEM_FROM_CART_FAILURE',
				message: ERRORS.DELETE_ITEM_FROM_CART,
			});
		}
	},
	setManualActivation: manualActivation => async dispatch => {
		dispatch({ type: 'SET_MANUAL_ACTIVATION', manualActivation });
	},
	setBearer: bearer => async dispatch => {
		dispatch({ type: 'SET_BEARER', bearer });
	},
	resetCartError: () => dispatch => dispatch({ type: 'RESET_CART_ERROR' }),
	purchaseCart: purchaseConfig => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_PURCHASE_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const walletId = getWalletId(state);
		const travellerId = getTravellerId(state);
		const cartId = getCartIdFromStore(state);

		const purchaseRequestModel = {
			walletId,
			...purchaseConfig,
			returnURL: !isIosDevice() ? urlConfig.confirmationUrl : "", //callback URL causes bug on iOS devices, see KF-408 for more details
			mtbProductOwner: {
				id: travellerId,
				type: 'traveller',
			},
		};

		try {
			const purchaseResponse = await purchaseCart(
				token,
				cartId,
				purchaseRequestModel
			);
			dispatch({ type: 'CREATE_PURCHASE_SUCCESS', purchase: purchaseResponse });

			try {
				const purse = await fetchPurse(token, walletId);
				dispatch({ type: 'GET_PURSE_SUCCESS', purse });
			} catch {
				//Ignore error getting purse after canceling a transaction
			}

			return purchaseResponse;
		} catch (error) {
			dispatch({
				type: 'CREATE_PURCHASE_FAILURE',
				message: ERRORS.PURCHASE_CART,
			});
		}
	},
	deleteCart: () => (dispatch, getState) => {
		dispatch({ type: 'DELETE_CART_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const cartId = getCartIdFromStore(state);

		try {
			deleteCart(token, cartId);
		} finally {
			// Ignore any errors, just remove cart data
			dispatch({ type: 'DELETE_CART_SUCCESS' });
		}
	},
	setOrderAddress: address => async dispatch => {
		dispatch({ type: 'SET_ORDER_ADDRESS', address });
	},
	setInvalidOrderAddress: showInvalidOrderAddressMessage => async dispatch => {
		dispatch({
			type: 'SET_INVALID_ORDER_ADDRESS',
			showInvalidOrderAddressMessage,
		});
	},
	addCardOrder: (cartItemId, deliveryAddress) => async dispatch => {
		const cardOrder = { cartItemId, deliveryAddress };
		dispatch({ type: 'ADD_CARD_ORDER', cardOrder });
	},
	resetNewCartItem: () => async dispatch => {
		dispatch({ type: 'RESET_NEW_CART_ITEM' });
	},
	placeCardOrders: () => async (dispatch, getState) => {
		const state = getState();
		const token = getToken(state);
		const bobcatTravellerId = getTravellerId(state);
		const receipt = getPurchaseReceipt(state);
		const cardOrders = getCardOrdersFromStore(state);

		if (cardOrders.length === 0) return;

		dispatch({ type: 'PLACE_CARD_ORDER_REQUESTS' });

		try {
			const cardOrderRequests = cardOrders.map(order => {
				const transactionItem = receipt.items.find(
					i => i.cartItemId === order.cartItemId
				);

				if (!transactionItem)
					throw Error(
						`Cannot find transactionitem with cartItemId ${order.cartItemId}`
					);

				return createCardOrder(token, {
					bobcatTravellerId,
					deliveryAddress: order.deliveryAddress,
					mtbProductIds: transactionItem.mtbProductIds,
				});
			});
			await Promise.all(cardOrderRequests);
			dispatch({ type: 'PLACE_CARD_ORDERS_SUCCESS' });
		} catch (error) {
			dispatch({
				type: 'PLACE_CARD_ORDERS_FAILURE',
				message: error.message,
			});
		}
	},
	setCartItemCount: count => dispatch => {
		dispatch({ type: 'SET_CART_ITEM_COUNT', count });
	},
	resetCartItemCount: () => dispatch => {
		dispatch({ type: 'RESET_CART_ITEM_COUNT' });
	},
};

export default function cartReducer(state, action) {
	state = state || initialState;
	switch (action.type) {
		case 'CREATE_CART_REQUEST':
		case 'ADD_ITEM_TO_CART_REQUEST':
		case 'CREATE_PURCHASE_REQUEST':
		case 'DELETE_ITEM_FROM_CART_REQUEST':
		case 'DELETE_CART_REQUEST':
		case 'PLACE_CARD_ORDER_REQUESTS':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'CREATE_CART_SUCCESS':
		case 'ADD_ITEM_TO_CART_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.cart,
			};
		case 'DELETE_ITEM_FROM_CART_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.deletedCart,
			};
		case 'SET_MANUAL_ACTIVATION':
			return {
				...state,
				newCartItem: {
					...state.newCartItem,
					manualActivation: action.manualActivation,
				},
			};
		case 'SET_BEARER':
			return {
				...state,
				newCartItem: { ...state.newCartItem, mtbBearerId: action.bearer },
			};
		case 'CREATE_PURCHASE_SUCCESS':
			return {
				...state,
				isLoading: false,
				purchase: action.purchase,
			};
		case 'DELETE_CART_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: null,
			};
		case 'CREATE_CART_FAILURE':
		case 'ADD_ITEM_TO_CART_FAILURE':
		case 'CREATE_PURCHASE_FAILURE':
		case 'DELETE_ITEM_FROM_CART_FAILURE':
		case 'PLACE_CARD_ORDERS_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'RESET_CART_ERROR':
			return {
				...state,
				errorMessage: '',
			};
		case 'SET_ORDER_ADDRESS':
			return {
				...state,
				newCartItem: {
					...state.newCartItem,
					orderAddress: {
						...state.newCartItem.orderAddress,
						...action.address,
					},
				},
			};
		case 'SET_INVALID_ORDER_ADDRESS':
			return {
				...state,
				newCartItem: {
					...state.newCartItem,
					orderAddress: {
						...state.newCartItem.orderAddress,
						showInvalidMessage: action.showInvalidOrderAddressMessage,
					},
				},
			};
		case 'SET_CART_ITEM_COUNT':
			return {
				...state,
				newCartItem: { ...state.newCartItem, count: action.count },
			};
		case 'RESET_CART_ITEM_COUNT':
			return {
				...state,
				newCartItem: {
					...state.newCartItem,
					count: initialState.newCartItem.count,
				},
			};
		case 'ADD_CARD_ORDER':
			return {
				...state,
				cardOrders: [...state.cardOrders, action.cardOrder],
			};
		case 'RESET_NEW_CART_ITEM':
			return {
				...state,
				newCartItem: initialState.newCartItem,
			};
		case 'PLACE_CARD_ORDERS_SUCCESS':
			return {
				...state,
				isLoading: false,
				cardOrders: initialState.cardOrders,
			};
		default:
			return state;
	}
}

// Private selectors

export function getCartId(state) {
	return state.data ? state.data.id : undefined;
}

export function hasError(state) {
	return state.errorMessage !== '';
}

export function hasDeleteCartItemError(state) {
	return state.errorMessage === ERRORS.DELETE_ITEM_FROM_CART;
}

export function hasPurchaseCartError(state) {
	return state.errorMessage === ERRORS.PURCHASE_CART;
}

export function getCart(state) {
	return state.data;
}

export function getNumberOfItems(state) {
	if (state.data && state.data.items) {
		return state.data.items.reduce((acc, curr) => acc + curr.count, 0);
	}

	return 0;
}

export function isLoading(state) {
	return state.isLoading;
}

export function getManualActivation(state) {
	return state.manualActivation;
}

export function getBearerId(state) {
	return state.bearer;
}

export function cartHasExpired(state) {
	return () => state.data && new Date(state.data.expire) < new Date();
}

export const getNewCartItem = state => state.newCartItem;

export const getCardOrders = state => state.cardOrders;

export const getShowInvalidOrderAddressMessage = state => {
	if (state.newCartItem && state.newCartItem.orderAddress)
		return state.newCartItem.orderAddress.showInvalidMessage;

	return false;
};

export const getCartItemCount = state => state.newCartItem.count;
