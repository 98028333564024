import { loadCustomer, updateCustomer, deregisterCustomer, getDeregistrationCauses } from '../api';
import { getToken } from '../reducer';

const initialState = {
	data: null,
	isLoading: false,
	errorMessage: '',
};

export const CUSTOMER_ERRORS = {
	GET_CUSTOMER: 'GET_CUSTOMER',
	UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
	DEREGISTER_CUSTOMER: 'DEREGISTER_CUSTOMER',
	GET_DEREGISTRATION_CAUSES: 'GET_DEREGISTRATION_CAUSES',
};

export const actionCreators = {
	getCustomer: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_CUSTOMER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = state.traveller.data.id;
		let customer = null;

		try {
			customer = await loadCustomer(token, travellerId);
			dispatch({ type: 'GET_CUSTOMER_SUCCESS', customer });
		} catch (error) {
			dispatch({ type: 'GET_CUSTOMER_FAILURE', message: CUSTOMER_ERRORS.GET_CUSTOMER });
		}
	},
	updateDeliveryAddress: deliveryAddress => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_ADDRESS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = state.traveller.data.id;
		let customer = null;

		try {
			customer = await updateCustomer(token, travellerId, { deliveryAddress });
			dispatch({ type: 'UPDATE_ADDRESS_SUCCESS', customer });
		} catch (error) {
			dispatch({ type: 'UPDATE_ADDRESS_FAILURE', message: CUSTOMER_ERRORS.UPDATE_CUSTOMER });
		}

	},
	deregisterCustomer: cause => async (dispatch, getState) => {
		dispatch({ type: 'DEREGISTER_CUSTOMER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = state.traveller.data.id;
		let customer = null;

		try {
			customer = await deregisterCustomer(token, travellerId, cause);
			dispatch({ type: 'DEREGISTER_CUSTOMER_SUCCESS', customer });
			return customer;
		} catch (error) {
			dispatch({ type: 'DEREGISTER_CUSTOMER_FAILURE', message: CUSTOMER_ERRORS.DEREGISTER_CUSTOMER });
			return;
		}
	},
	getDeregistrationCauses: () => async (dispatch, getState) => {
		dispatch({ type: 'DEREGISTRATION_CAUSES_REQUEST' });

		const state = getState();
		const token = getToken(state);
		let causes = null;

		try {
			causes = await getDeregistrationCauses(token);
			dispatch({type: 'DEREGISTRATION_CAUSES_SUCCESS', causes});
		} catch (error) {
			dispatch({ type: 'DEREGISTRATION_CAUSES_FAILURE', message: CUSTOMER_ERRORS.GET_DEREGISTRATION_CAUSES });
		}
	},
};

export default function customerReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_CUSTOMER_REQUEST':
		case 'UPDATE_ADDRESS_REQUEST':
		case 'DEREGISTER_CUSTOMER_REQUEST':
		case 'DEREGISTRATION_CAUSES_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_CUSTOMER_SUCCESS':
		case 'UPDATE_ADDRESS_SUCCESS':
		case 'DEREGISTER_CUSTOMER_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: { ...state.data, ...action.customer },
			};
		case 'DEREGISTRATION_CAUSES_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: { ...state.data, deregistrationCauses: action.causes },
			};
		case 'GET_CUSTOMER_FAILURE':
		case 'UPDATE_ADDRESS_FAILURE':
		case 'DEREGISTER_CUSTOMER_FAILURE':
		case 'DEREGISTRATION_CAUSES_FAILURE':			
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		default:
			return state;
	}
};

export function getCustomer(store){
	return store.data;
}

export function hasError(store){
	return store.errorMessage !== '';
}

export const isLoading = store => store.isLoading;

export const getCauses = store => store.data ? store.data.deregistrationCauses : [];
