import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { PRIVATE } from '../../../Paths';
import { forPhoneOnly } from '../../../utils/mediaqueries';
import { connect } from 'react-redux';

class CustomerDesktopNavigation extends Component {
	render() {
		return (
			<DesktopMenuItems>
				<DesktopMenuList>
					<Link to={PRIVATE.TICKET} activeClassName="active">
						Mina Biljetter
					</Link>
				</DesktopMenuList>
				<DesktopMenuList>
					<Link to={PRIVATE.PURCHASE_OFFER} activeClassName="active">
						Köp
					</Link>
				</DesktopMenuList>
				{/* <Link to={PRIVATE.ISSUES} activeClassName="active">
					Mina ärenden
				</Link> */}
				<DesktopMenuList>
					<Link to={PRIVATE.PROFILE} activeClassName="active">
						Min profil
					</Link>
				</DesktopMenuList>
			</DesktopMenuItems>
		);
	}
}

const DesktopMenuItems = styled.ul`
	flex-grow: 1;
	text-align: center;
	margin: 0;
	padding: 0;

	li + li {
		margin-left: 2em;
	}

	${forPhoneOnly`
		display: none;
	`}
`;

const Link = styled(NavLink)`
	color: black;
	text-decoration: none;

	&:hover,
	&:focus,
	&.active {
		color: black;
		text-decoration: none;
		border-bottom: solid 2px;
	}

	
`;

const DesktopMenuList = styled.li`
display: inline;
`;

//Connect is needed for navlink to update after redirect from /callback
export default connect(({ router }) => ({ location: router.location }))(
	CustomerDesktopNavigation
);
