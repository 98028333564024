import React, { Component } from 'react';
import styled from 'styled-components';
import { sanitizeCmsHtml } from '../../texts';
import { NextButton } from './../common/elements/NextButton';

export class RegisterCardInfo extends Component {
	onBuyTicket = () => this.props.onBuyTicket();

	render() {
		return (
			<div>
				<div dangerouslySetInnerHTML={sanitizeCmsHtml(this.props.text)} />
				<BuyTicketButton onClick={this.onBuyTicket}>
					Köp biljett
				</BuyTicketButton>
			</div>
		);
	}
}

const BuyTicketButton = styled(NextButton)`
	margin-top: 1em;
	margin-bottom: 1em;
`;
