import React from 'react';
import { Dropdown } from '../../common/elements/Dropdown';
import styled from 'styled-components';
import { groupByFour } from '../../../utils/formatting';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { LinkButton } from '../../common/elements/LinkButton';
import { ReactComponent as ChevronLeft } from '../../../images/chevron-left.svg';

export const TicketFunctions = props => {
	switch (props.activeFunction) {
		case FUNCTIONS.MOVE_TICKET:
			return <MoveForm {...props} />;
		case FUNCTIONS.CANCEL_TICKET:
			return <CancelForm {...props} />;
		case FUNCTIONS.TRANSFER_REFERRED_TICKET:
			return <TransferReferredForm {...props} />;
		case FUNCTIONS.RETURN_REFERRED_TICKET:
			return <ReturnReferredForm {...props} />;
		default:
			return null;
	}
};

export const FUNCTIONS = {
	MOVE_TICKET: 'MOVE_TICKET',
	CANCEL_TICKET: 'CANCEL_TICKET',
	TRANSFER_REFERRED_TICKET: 'TRANSFER_REFERRED_TICKET',
	RETURN_REFERRED_TICKET: 'RETURN_REFERRED_TICKET',
};

const MoveForm = props => (
	<>
		<h5>Flytta biljett</h5>
		<p>
			<i>
				Biljetten kan flyttas till andra resekort eller appar som är
				registrerade på eleven
			</i>
		</p>
		<Dropdown
			id="bearers"
			onSelect={props.onSelectTargetBearer}
			items={props.bearers}
			renderItem={renderBearerOption}
		/>
		<ConfirmFunctionButton
			onClick={props.onMoveTicket}
			disabled={props.isLoading}
		>
			Flytta biljett
		</ConfirmFunctionButton>

		<BackButton onClick={props.onBackClick}>
			<ChevronLeftIcon
				className="svg-icon"
				aria-hidden="true"
				focusable="false"
			/>
			Tillbaka
		</BackButton>
	</>
);

const CancelForm = props => (
	<>
		<h5>Avsluta biljett</h5>
		<p>
			<i>
				Biljetten avslutas med dagens datum. Observera att eleven inte kan
				använda biljetten efter avslut.
			</i>
		</p>
		<ConfirmFunctionButton
			onClick={props.onCancelTicket}
			disabled={props.isLoading}
		>
			Avsluta biljett
		</ConfirmFunctionButton>

		<BackButton onClick={props.onBackClick} disabled={props.isLoading}>
			<ChevronLeftIcon
				className="svg-icon"
				aria-hidden="true"
				focusable="false"
			/>
			Tillbaka
		</BackButton>
	</>
);

const TransferReferredForm = props => (
	<>
		<h5>Flytta biljett till reseapp</h5>
		<p>
			<i>
				Biljetten kan flyttas till det refererade kontot. Eleven kan då använda
				biljetten från det privata resenärskontot.
			</i>
		</p>
		<ConfirmFunctionButton
			onClick={props.onTransferTicketToReferred}
			disabled={props.isLoading}
		>
			Flytta biljett
		</ConfirmFunctionButton>

		<BackButton onClick={props.onBackClick}>
			<ChevronLeftIcon
				className="svg-icon"
				aria-hidden="true"
				focusable="false"
			/>
			Tillbaka
		</BackButton>
	</>
);

const ReturnReferredForm = props => (
	<>
		<h5>Returnera biljett från reseapp</h5>
		<p>
			<i>
				Biljetten kan returneras från det refererade kontot. Eleven kan då ej
				använda biljetten från det privata resenärskontot.
			</i>
		</p>
		<ConfirmFunctionButton
			onClick={props.onReturnTicketFromReferred}
			disabled={props.isLoading}
		>
			Returnera biljett
		</ConfirmFunctionButton>

		<BackButton onClick={props.onBackClick}>
			<ChevronLeftIcon
				className="svg-icon"
				aria-hidden="true"
				focusable="false"
			/>
			Tillbaka
		</BackButton>
	</>
);

const renderBearerOption = bearer => (
	<>
		<BearerTitle>{bearer.title}</BearerTitle>
		{bearer.serial && (
			<div style={{ fontSize: '.8em' }}>
				<span>{groupByFour(bearer.serial)}</span>
			</div>
		)}
	</>
);

const ConfirmFunctionButton = styled(SecondaryButton)`
	margin: 1em 0;
`;

const BearerTitle = styled.span`
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ChevronLeftIcon = styled(ChevronLeft)`
	fill: none;
	stroke: ${props => props.theme.dark_grey};
`;

const BackButton = styled(LinkButton)`
	align-self: center;
	color: ${props => props.theme.dark_grey};
`;
