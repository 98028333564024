import React, { Component } from 'react';
import styled from 'styled-components';
import BussenVit from '../../images/bussen_vit.svg';
import YoutubeIcon from '../../images/youtube.svg';
import FacebookIcon from '../../images/facebook.svg';
import LinkedInIcon from '../../images/linkedin.svg';
import InstagramIcon from '../../images/instagram.svg';
import withTextContext from '../../utils/withTextContext';
import { isEmpty } from 'lodash';
import { sanitizeCmsHtml } from '../../texts';
import { Logo } from '../common/blocks/Logo';

class Footer extends Component {
	render() {
		const { footer: footerTexts } = this.props.texts.private;

		return (
			<FooterContainer>
				<Logo type="LogoInverted" className="logo" />

				<SocialMedia>
					<nav aria-labelledby="footer-social-links">
						<h2
							className="sr-only"
							id="footer-social-links"
							dangerouslySetInnerHTML={sanitizeCmsHtml(
								`${footerTexts.information.contactEmail.value} i social media`
							)}
						/>
						<InlineList>
							{!isEmpty(footerTexts.socialmedia.facebookUrl) && (
								<li>
									<a
										href={footerTexts.socialmedia.facebookUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={FacebookIcon}
											className="svg-icon"
											aria-hidden="true"
											focusable="false"
											alt=""
										/>
										<span className="sr-only">Facebook</span>
									</a>
								</li>
							)}
							{!isEmpty(footerTexts.socialmedia.linkedinUrl) && (
								<li>
									<a
										href={footerTexts.socialmedia.linkedinUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={LinkedInIcon}
											className="svg-icon"
											aria-hidden="true"
											focusable="false"
											alt=""
										/>
										<span className="sr-only">LinkedIn</span>
									</a>
								</li>
							)}
							{!isEmpty(footerTexts.socialmedia.youtubeUrl) && (
								<li>
									<a
										href={footerTexts.socialmedia.youtubeUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={YoutubeIcon}
											className="svg-icon"
											aria-hidden="true"
											focusable="false"
											alt=""
										/>
										<span className="sr-only">Youtube</span>
									</a>
								</li>
							)}
							{!isEmpty(footerTexts.socialmedia.instagramUrl) && (
								<li>
									<a
										href={footerTexts.socialmedia.instagramUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={InstagramIcon}
											className="svg-icon"
											aria-hidden="true"
											focusable="false"
											alt=""
										/>
										<span className="sr-only">Instagram</span>
									</a>
								</li>
							)}
						</InlineList>
					</nav>
				</SocialMedia>

				<Address>
					<a href={footerTexts.information.contactEmail.value}>
						<p>{footerTexts.information.contactEmail.displayValue}</p>
					</a>

					<p>
						{footerTexts.information.thmName}
						<br />
						{footerTexts.information.address}
					</p>
				</Address>

				<BussenScaled
					with="150px"
					height="54px"
					src={BussenVit}
					aria-hidden="true"
					focusable="false"
					alt=""
				/>

				<SeparatedList>
					{!isEmpty(footerTexts.links.link1.value) && (
						<li>
							<a href={footerTexts.links.link1.value}>
								{footerTexts.links.link1.displayValue}
							</a>
						</li>
					)}
					{!isEmpty(footerTexts.links.link2.value) && (
						<li>
							<a href={footerTexts.links.link2.value}>
								{footerTexts.links.link2.displayValue}
							</a>
						</li>
					)}
					{!isEmpty(footerTexts.links.link3.value) && (
						<li>
							<a href={footerTexts.links.link3.value}>
								{footerTexts.links.link3.displayValue}
							</a>
						</li>
					)}
				</SeparatedList>
			</FooterContainer>
		);
	}
}

const FooterContainer = styled.footer`
	padding: 1em;
	font-size: 12px;
	background-color: ${props => props.theme.dark_grey};
	color: ${props => props.theme.white};
	flex-shrink: 0;
	text-align: center;

	.logo {
		margin-top: 4em;
		margin-bottom: 1em;
	}
`;

const SocialMedia = styled.div`
	font-size: 1.16em;

	svg,
	img {
		margin: auto 0.5em;
	}
`;

const InlineList = styled.ul`
	list-style: none;
	padding-left: 0;

	li {
		display: inline;
	}
`;

const Address = styled.address`
	a {
		color: ${props => props.theme.white};
	}
`;

const SeparatedList = styled(InlineList)`
	li + li::before {
		margin: 0 0.5em;
		content: '|';
	}

	a {
		color: ${props => props.theme.white};
	}
`;

const BussenScaled = styled.img`
	margin-top: 2em;
	margin-bottom: 1em;
`;

export default withTextContext(Footer, false);
