import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { PopUpBox } from '../../common/elements/PopUpBox';
import { CloseCardButton } from '../../common/blocks/CloseCardButton';
import { PrimaryButton } from '../../common/elements/PrimaryButton';
import {
	actionCreators as studentActions,
	canDeleteGroup,
} from '../../../reducer/students';
import { actionCreators as groupActions } from '../../../reducer/groups';
import { SCHOOL } from '../../../Paths';
import { ConfirmBox } from '../../common/elements/ConfirmBox';

class RemoveGroupPopup extends Component {
	state = { showError: false };

	removeGroup = async () => {
		const { group } = this.props;

		try {
			await this.props.removeGroup(group.groupId);
			this.props.push(SCHOOL.CONFIRMATION, {
				nextRoute: SCHOOL.GROUPS,
				title: 'Grupp borttagen',
			});
		} catch {
			this.setState({ showError: true });
		}
	};

	render() {
		const { closePopup, groupStudents, canDeleteGroup } = this.props;
		const { showError } = this.state;

		let groupCanBeDeleted = canDeleteGroup(groupStudents);

		return !groupCanBeDeleted ? (
			<PopUpBox>
				<CloseCardButton onClick={closePopup} />
				<BoldHeader>Vänta lite!</BoldHeader>

				<InfoSpan>
					Gruppen går inte att radera då det finns elever med aktiva, eller
					kommande aktiva biljetter som endast tillhör denna grupp
				</InfoSpan>
				<ButtonContainer>
					<BackButton onClick={closePopup}>Tillbaka</BackButton>
				</ButtonContainer>
			</PopUpBox>
		) : (
			<ConfirmBox
				title="Vänta lite!"
				info={<p>Är du säker på att du vill ta bort gruppen?</p>}
				onContinue={this.removeGroup}
				onClose={closePopup}
				onBack={closePopup}
				confirmButtonText="Ta bort grupp"
				hasError={showError}
			/>
		);
	}
}

const BoldHeader = styled.h4`
	font-weight: 600;
`;

const InfoSpan = styled.span`
	text-align: center;
	padding: 0 2em;
	width: 100%;
`;

const ButtonContainer = styled.div`
	button {
		width: 180px;
		margin: 1em;
	}
`;

const BackButton = styled(PrimaryButton)`
	background-color: white;
	color: gray;
	border: 1px solid gray;
`;

export default connect(
	store => ({
		canDeleteGroup: canDeleteGroup(store),
	}),
	{ ...studentActions, ...groupActions, push }
)(RemoveGroupPopup);
