import { RegisterCardForm } from './RegisterCardForm';
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { Card } from '../common/blocks/Card';
import { RegisterCardInfo } from './RegisterCardInfo';
import { actionCreators } from '../../reducer/bearer';
import { Main } from '../common/blocks/Main';
import { PRIVATE } from '../../Paths';
import { Loading } from '../common/blocks/Loading';
import { Error } from './../common/elements/Error';
import {
	getParticipants,
	hasCreateBearerError,
	hasParticipantsError,
	isLoadingMtbBearers,
	isLoadingParticipants,
} from '../../reducer';
import {
	forSmallPhoneOnly,
	forPhoneOnly,
	forTabletPortraitUp,
} from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

export class RegisterCardPage extends Component {
	componentDidMount() {
		document.title = "Registrera resekort | Mitt konto"
		const { participants } = this.props;

		if (!participants) this.props.getParticipants();
	}

	registerBearer = async bearer => {
		await this.props.createBearer(bearer);

		if (!this.props.hasCreateBearerError) {
			this.props.clearBearer();
			this.props.push(PRIVATE.TICKET_CONFIRMATION, {
				nextRoute: PRIVATE.TICKET,
				title: 'Registrera resekort',
				text: 'Resekortet är nu registrerat',
			});
		}
	};

	buyTicket = () => this.props.push(PRIVATE.BUY_TICKET);

	render() {
		const {
			isLoadingMtbBearers,
			hasCreateBearerError,
			participants,
			isLoadingParticipants,
			hasParticipantsError,
			texts,
		} = this.props;

		return (
			<Fragment>
				<Banner bannerImg={texts.private.images.banners.ticketpage}>
					<H1>Registrera resekort</H1>
				</Banner>
				<Main>
					<RegisterCardWrapper justifyContent="space-around">
						<RegisterCardInfo
							onBuyTicket={this.buyTicket}
							text={
								texts.private.ticketpage.travelcards
									.registertravelcardinformationtext
							}
						/>
						<RegisterCardBox>
							{hasParticipantsError ? (
								<Error />
							) : isLoadingParticipants ? (
								<Loading />
							) : (
								<RegisterCardForm
									isLoading={isLoadingMtbBearers}
									registerBearer={this.registerBearer}
									showError={!!hasCreateBearerError}
									participants={participants}
								/>
							)}
						</RegisterCardBox>
					</RegisterCardWrapper>
				</Main>
			</Fragment>
		);
	}
}

const RegisterCardWrapper = styled(FlexWrapper)`
	& > div {
		width: 46%;
	}

	${forPhoneOnly`
		& > div {
			width: 100%;
		}

		& > div + div {
			margin-top: 1em;
		}
	`}
`;

const RegisterCardBox = styled(Card)`
	height: fit-content;

	${forTabletPortraitUp`
		order: -1;
	`}

	${forSmallPhoneOnly`
		box-shadow: none;
		border: none;
		background: none;
		padding: 0;
	`}
`;

const H1 = styled.h1`
font-size: 30px;
`;

function mapStateToProps(store) {
	return {
		participants: getParticipants(store),
		hasCreateBearerError: hasCreateBearerError(store),
		hasParticipantsError: hasParticipantsError(store),
		isLoadingMtbBearers: isLoadingMtbBearers(store),
		isLoadingParticipants: isLoadingParticipants(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, { ...actionCreators, push })(RegisterCardPage)
);
