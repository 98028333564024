import React, { Component } from 'react';

export class TextCounter extends Component {
	render() {
		const { maxCount, text, className } = this.props;
		const currentCount = text ? text.length : 0;
		return (
			<span className={className}>
				({currentCount}/{maxCount}) tecken
			</span>
		);
	}
}
