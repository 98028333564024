import React, { Component } from 'react';
import styled from 'styled-components';
import { CloseCardButton } from '../../common/blocks/CloseCardButton';
import { ConfirmationCard } from '../../common/blocks/ConfirmationCard';
import { Card } from '../../common/blocks/Card';
import { ReactComponent as File } from '../../../images/file.svg';
import { ReactComponent as Download } from '../../../images/download.svg';
import { Loading } from '../../common/blocks/Loading';
import { Error } from './../../common/elements/Error';
import { ReactComponent as ExclamationCircle } from '../../../images/exclamation-circle.svg';
import { ReactComponent as Check } from '../../../images/check-thin.svg';
import { sanitizeCmsHtml } from '../../../texts';

export class ImportStudentsPopup extends Component {
	state = {
		selectedFile: null,
	};

	onFileChange = async event => {
		event.preventDefault();

		const file = event.target.files[0];
		if (file != null) this.setState({ selectedFile: file });
	};

	onSubmitImport = async event => {
		event.preventDefault();
		const { selectedFile } = this.state;

		this.props.onImportStudents(selectedFile);
	};

	clearSelectedFile = () => this.setState({ selectedFile: null });

	clearImportError = async () => {
		await this.props.onClearImportError();
		this.clearSelectedFile();
	};

	downloadImportTemplate = async () => {
		let templateDownloader = document.createElement('a');
		templateDownloader.href = 'templates/StudentImportTemplate.xlsx';	
		templateDownloader.click();
	};

	getStudentsByImportResult = result =>
		(this.props.postImportStudents || []).filter(
			s => s.importedSuccessfully === result && s.removedAtImport
		);

	getConfirmationCardText = () => {
		const { postImportStudents } = this.props;
		let importSuccessCount = this.getStudentsByImportResult(true).length;
		let importFailList = this.getStudentsByImportResult(false);
		const studentsNotRemovedAtImport = postImportStudents.filter(
			student => !student.removedAtImport
		).length;

		return (
			<ConfirmationTextBody>
				<ConfirmationImportTextSuccess>
					<CheckIcon />
					<span>{importSuccessCount} st elever importerades</span>
				</ConfirmationImportTextSuccess>
				{studentsNotRemovedAtImport > 0 && (
					<>
						<ConfirmationImportText>
							<ExclamationCircle /> {studentsNotRemovedAtImport} st elever kunde
							ej tas bort på grund av aktiv eller kommande aktiv biljett
						</ConfirmationImportText>
					</>
				)}
				{importFailList.length > 0 && (
					<>
						<ConfirmationImportFailedHeader>
							<ExclamationCircle />
							<span>Följande elever kunde ej importeras:</span>
						</ConfirmationImportFailedHeader>
						<ConfirmationImportFailedList>
							{importFailList.map((s, idx) => (
								<p key={idx}>{s.personalIdentityNumber}</p>
							))}
						</ConfirmationImportFailedList>
					</>
				)}
			</ConfirmationTextBody>
		);
	};

	render() {
		const {
			isImportingStudents,
			hasImportError,
			importErrorText,
			showImportConfirmation,
		} = this.props;
		const { selectedFile } = this.state;

		return (
			<Container id="popupOverlay">
				{showImportConfirmation ? (
					<ConfirmationCard
						onClick={this.props.closePopup}
						title="Import färdig"
						text={this.getConfirmationCardText()}
					/>
				) : (
					<>
						<PopupCard className="Card">
							<CloseCardButton onClick={this.props.closePopup} />
							<PopupHeader>Importera elever från lista</PopupHeader>
							<ImportInformation
								dangerouslySetInnerHTML={sanitizeCmsHtml(
									this.props.texts.school.grouppage.importstudents
										.importinformation
								)}
							/>
							{hasImportError ? (
								<>
									<Error>{importErrorText}</Error>
									<BackLink onClick={this.clearImportError}>Tillbaka</BackLink>
								</>
							) : isImportingStudents ? (
								<>
									<Loading />
									<ImportLoadingText>
										Vid stora importer kan detta dröja en stund, var god vänta..
									</ImportLoadingText>
								</>
							) : selectedFile == null ? (
								<ButtonsContainer>
									<DownloadTemplateButton
										type="button"
										onClick={this.downloadImportTemplate}
									>
										<DownloadIcon />
										Ladda ner mall för import
									</DownloadTemplateButton>
									<FileUploadInput
										type="file"
										id="importStudentsButton"
										onChange={this.onFileChange}
									/>
									<label htmlFor="importStudentsButton">
										<LabelText>
											<FileIcon />
											Välj Excel-fil att ladda upp...
										</LabelText>
									</label>
								</ButtonsContainer>
							) : (
								<ButtonsContainer>
									<SelectedFileText>{selectedFile.name}</SelectedFileText>
									<DownloadTemplateButton
										type="button"
										onClick={this.onSubmitImport}
									>
										Importera elever
									</DownloadTemplateButton>
									<BackLink onClick={this.clearSelectedFile}>Tillbaka</BackLink>
								</ButtonsContainer>
							)}
						</PopupCard>
					</>
				)}
			</Container>
		);
	}
}

const PopupHeader = styled.h2`
	color: #434343;
	font-family: ${props => props.theme.text};
	font-size: 27px;
	font-weight: bold;
	line-height: 37px;
`;

const ButtonsContainer = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ImportLoadingText = styled.p`
	font-family: ${props => props.theme.text};
	font-size: 12px;
	font-style: italic;
	margin: 6px 0;
`;

const SelectedFileText = styled.p`
	align-self: flex-start;
	color: #646464;
	font-family: ${props => props.theme.text};
	font-size: 14px;
	font-weight: bold;
	line-height: 14px;
	margin-bottom: 3px;
`;

const BackLink = styled.p`
	font-family: ${props => props.theme.text};
	color: ${props => props.theme.link_color};
	font-size: 14px;
	line-height: 16px;
	margin: 9px 0;

	:hover {
		color: ${props => props.theme.link_hover_color};
		text-decoration: underline;
		cursor: pointer;
	}
`;

const FileUploadInput = styled.input.attrs({ type: 'file' })`
	opacity: 0;
	position: absolute;
	pointer-events: none;
	// alternative to pointer-events, compatible with all browsers, just make it impossible to find
	width: 1px;
	height: 1px;

	& + label {
		border-radius: 3px;
		box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.06);
		padding: 14px 37px;
		cursor: pointer;
		font-size: 12px;
		line-height: 14px;
		font-weight: bold;
		background-color: ${props => props.theme.primary_color};
		font-family: ${props => props.theme.text};
		color: ${props => props.theme.textOnPrimaryColor};
		width: 100%;
		margin: 6px 0 27px;
	}
`;

const DownloadTemplateButton = styled.button`
	border-radius: 3px;
	box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.06);
	border: none;
	padding: 14px 37px;
	cursor: pointer;
	font-size: 12px;
	line-height: 14px;
	font-weight: bold;
	background-color: ${props => props.theme.primary_color};
	font-family: ${props => props.theme.text};
	color: ${props => props.theme.textOnPrimaryColor};
	width: 100%;
	margin: 6px 0;
`;

const LabelText = styled.span`
	font-size: 12px;
	display: inline-flex;
	align-self: center;
`;

const FileIcon = styled(File)`
	height: 1em;
	width: 1em;
	margin-right: 4px;
	display: inline-flex;
	align-self: center;
`;

const DownloadIcon = styled(Download)`
	height: 1em;
	width: 1em;
	margin-right: 4px;
	display: inline-flex;
	align-self: center;
`;

const CheckIcon = styled(Check)`
	width: 21px;
	height: 21px;

	g {
		stroke: #2e2e2e;
	}
`;

const ImportInformation = styled.div`
	margin: 0 120px;
	text-align: left;

	ul {
		padding-inline-start: 16px;
	}
`;

const ConfirmationTextBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 6px 10px;
	background: white;
	color: black;
	cursor: auto;

	p {
		margin: 0;
	}
`;

const ConfirmationImportText = styled.p`
	display: flex;
	padding-bottom: 10px;
	font-family: ${props => props.theme.text};
	svg {
		margin-right: 3px;
	}
`;

const ConfirmationImportTextSuccess = styled(ConfirmationImportText)`
	stroke: ${props => props.theme.textOnPrimaryColor};
`;

const ConfirmationImportFailedHeader = styled.p`
	font-weight: bold;
	font-family: ${props => props.theme.text};
	display: flex;
	svg {
		margin-right: 3px;
	}
`;

const ConfirmationImportFailedList = styled.div`
	overflow-x: auto;
	max-height: 250px;
	width: 100%;
`;

const Container = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1em;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10000;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`;
const PopupCard = styled(Card)`
	max-width: 600px;
	align-items: center;
	max-height: 100%;
	overflow: auto;
	h2 {
		margin: 1em;
		color: #2e2e2e;
	}
	@media screen and (max-width: 375px) {
		padding: 1em;
	}
`;
