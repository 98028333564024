import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from './Card';
import { CloseCardButton } from './CloseCardButton';
import { ReactComponent as DoneIcon } from '../../../images/done.svg';

export class ConfirmationCard extends Component {
	static propTypes = {
		renderFooter: PropTypes.func,
	};

	onClick = e => {
		this.props.onClick(e);
	};

	render() {
		const { className } = this.props;
		return (
			<MainContainer>
				<CardContainer onClick={this.onClick} className={className}>
					<CloseCardButton />
					{this.props.title && <h3>{this.props.title}</h3>}
					<IconWrapper>
						<DoneIcon />
					</IconWrapper>
					<div onClick={e => e.stopPropagation()} >
						{this.props.text}
					</div>

					{this.props.renderFooter && (
						<Complement>{this.props.renderFooter()}</Complement>
					)}
				</CardContainer>
			</MainContainer>
		);
	}
}

const MainContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
`;

const CardContainer = styled(Card)`
	min-height: 250px;
	justify-content: center;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
	max-width: 532px;
	width: 100%;
	background-color: ${props => props.theme.primary_color};
	color: ${props => props.theme.textOnPrimaryColor};
	padding: 1em 0;

	button {
		color: ${props => props.theme.textOnPrimaryColor};
	}
`;

const Complement = styled.div`
	color: ${props => props.theme.text_color};
	background-color: white;
	cursor: auto;
`;

const IconWrapper = styled.div`
	margin: 1em;

	path {
		fill: #000;
	}

	circle {
		fill: #fff;
	}
`;
