import React, { Component } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { Card } from '../common/blocks/Card';
import { TextInput } from '../common/elements/TextInput';
import { externalTest } from '../../api';
import { Info } from '../common/elements/Info';
import { Error } from '../common/elements/Error';

export class ExternalTestPage extends Component {
	state = { input: '', ok: undefined };

	handleChange = target => {
		this.setState({ input: target.value });
	};

	submitForm = async e => {
		const ok = await externalTest({ input: this.state.input });
		this.setState({ ok });
	};

	render() {
		return (
			<CardBox>
				<h4>Test av inbäddad webbsida</h4>
				<p>Denna sida säkerställer kommunikation med bakomliggande api.</p>
				<div className="form-group">
					<label className="sr-only" htmlFor="input">
						Text
					</label>
					<Input
						type="text"
						value={this.state.input}
						id="input"
						placeholder="Text"
						handleChange={this.handleChange}
					/>
				</div>
				<Button onClick={this.submitForm}>Skicka</Button>

				{this.state.ok !== undefined &&
					(this.state.ok ? (
						<Info>Det gick bra :)</Info>
					) : (
						<Error>Det gick dåligt :(</Error>
					))}
			</CardBox>
		);
	}
}

const CardBox = styled(Card)`
	display: block;
	align-items: center;
	width: 90vw;
	padding: 3em;
	margin: 1em auto;
`;

const Input = styled(TextInput)`
	width: 100%;
	max-width: 300px;
`

const Button = styled(SecondaryButton)`
	width: 100%;
	max-width: 300px;
`
