import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	actionCreators as schoolActions,
	getSchool,
	hasFetchedSchool,
	hasSchool,
} from '../reducer/school';
import {
	actionCreators as adminActions,
	getAdmin,
	isLoadingAdminUser,
} from '../reducer/school/admins';
import { LoadPage } from '../components/common/LoadPage';
import { idpConfig } from '../config';
import AdminSchoolChange from '../components/school/AdminSchoolChange';

function ensureSchoolSelected(WrappedComponent) {
	return class extends React.Component {
		componentDidMount() {
			this.loadDataIfNotAvailable();
		}

		loadDataIfNotAvailable = async () => {
			const { isLoadingAdmin, hasFetchedSchool, school } = this.props;

			if (!this.hasData() && !isLoadingAdmin)
				await this.props.getAdmin(
					this.props.user.profile[idpConfig.schoolIdClaim]
				);

			if (!hasFetchedSchool && school)
				this.props.getSchoolById(school.schoolId);
		};

		hasData = () => !!this.props.user && this.props.admin !== undefined;

		render() {
			const { hasSchool, isLoadingAdmin } = this.props;

			if (!this.hasData() || isLoadingAdmin) {
				return <LoadPage />;
			}

			if (hasSchool) return <WrappedComponent {...this.props} />;

			return (
				<AdminSchoolChange
					{...this.props}
					onSuccess={this.toggleConfirmation}
				/>
			);
		}
	};
}

export default compose(
	connect(
		store => ({
			user: store.oidc.user,
			school: getSchool(store),
			hasSchool: hasSchool(store),
			admin: getAdmin(store),
			isLoadingAdmin: isLoadingAdminUser(store),
			hasFetchedSchool: hasFetchedSchool(store),
		}),
		{
			...schoolActions,
			...adminActions,
		}
	),
	ensureSchoolSelected
);
