import { getToken, isLoadingSchool } from '../';
import {
	fetchSchoolOrgAdmin,
	fetchAdmins,
	fetchAdminRoles,
	createAdmin,
	removeAdmin,
} from '../../api';
import { getSchool } from '../school';

const initialState = {
	errorMessage: '',
	admin: undefined,
	schoolAdmins: null,
	adminRoles: null,
	isLoadingAdminUser: false,
	isLoadingAdmins: false,
	isLoadingAdminRoles: false,
	isLoadingRemoveAdmin: false,
	isLoadingCreateAdmin: false,
};

export const ADMINS_ERRORS = {
	GET_ADMIN_USER: 'GET_ADMIN_USER',
	GET_ALL_ADMINS: 'GET_ALL_ADMINS',
	CREATE_ADMIN: 'CREATE_ADMIN',
	GET_ADMIN_ROLES: 'GET_ADMIN_ROLES',
	REMOVE_ADMIN: 'REMOVE_ADMIN',
};

export const STORE_NAME = 'admins';

export const ADMIN_ROLE = {
	SCHOOL_ORG_ADMIN: 'organisationsadministratör',
	SCHOOL_ADMIN: 'verksamhetsadministratör',
};

export const actionCreators = {
	getAdmin: email => async (dispatch, getState) => {
		const state = getState();
		const token = getToken(state);

		if (isLoadingSchool(state)) {
			dispatch({ type: 'ABORT_GET_ADMIN_USER_REQUEST' });
			return;
		}

		try {
			dispatch({ type: 'GET_ADMIN_USER_REQUEST' });
			const admin = await fetchSchoolOrgAdmin(token, email);

			dispatch({ type: 'GET_ADMIN_USER_SUCCESS', admin });
		} catch (error) {
			dispatch({
				type: 'GET_ADMIN_USER_FAILURE',
				message: ADMINS_ERRORS.GET_ADMIN_USER,
			});
		}
	},
	getAllAdmins: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_ALL_ADMINS_REQUEST' });
		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const admins = await fetchAdmins(token, school.schoolId);
			dispatch({ type: 'GET_ALL_ADMINS_SUCCESS', admins });
		} catch (error) {
			dispatch({
				type: 'GET_ALL_ADMINS_FAILURE',
				message: ADMINS_ERRORS.GET_ALL_ADMINS,
			});
		}
	},
	getAdminRoles: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_ADMIN_ROLES_REQUEST' });
		const state = getState();
		const token = getToken(state);

		try {
			const adminRoles = await fetchAdminRoles(token);
			dispatch({ type: 'GET_ADMIN_ROLES_SUCCESS', adminRoles });
		} catch (error) {
			dispatch({
				type: 'GET_ADMIN_ROLES_FAILURE',
				message: ADMINS_ERRORS.GET_ADMIN_ROLES,
			});
		}
	},
	createAdmin: admin => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_ADMIN_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const orgAdmin = getAdmin(state);
		const school = getCurrentSchool(state);

		admin.Schools[0].SchoolId = school.schoolId;
		admin.Schools[0].SchoolName = school.schoolName ;

		try {
			const createdAdmin = await createAdmin(token, orgAdmin, school, admin);
			dispatch({ type: 'CREATE_ADMIN_SUCCESS', createdAdmin });
		} catch (error) {
			dispatch({
				type: 'CREATE_ADMIN_FAILURE',
				message: ADMINS_ERRORS.CREATE_ADMIN,
			});
			throw error;
		}
	},
	removeAdmin: adminId => async (dispatch, getState) => {
		dispatch({ type: 'REMOVE_ADMIN_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const orgAdmin = getAdmin(state);

		try {
			await removeAdmin(token, orgAdmin, adminId);

			dispatch({ type: 'REMOVE_ADMIN_SUCCESS', adminId });
		} catch (error) {
			dispatch({
				type: 'REMOVE_ADMIN_FAILURE',
				message: ADMINS_ERRORS.REMOVE_ADMIN,
			});
			throw error;
		}
	},
};

export default function adminsReducer(state = initialState, action) {
	switch (action.type) {
		case 'GET_ADMIN_USER_REQUEST':
			return {
				...state,
				isLoadingAdminUser: true,
				errorMessage: '',
			};
		case 'GET_ALL_ADMINS_REQUEST':
			return {
				...state,
				isLoadingAdmins: true,
				errorMessage: '',
			};
		case 'CREATE_ADMIN_REQUEST':
			return {
				...state,
				isLoadingCreateAdmin: true,
				errorMessage: '',
			};
		case 'GET_ADMIN_ROLES_REQUEST':
			return {
				...state,
				isLoadingAdminRoles: true,
				errorMessage: '',
			};
		case 'REMOVE_ADMIN_REQUEST':
			return {
				...state,
				isLoadingRemoveAdmin: true,
				errorMessage: '',
			};
		case 'REMOVE_ADMIN_SUCCESS':
			return {
				...state,
				isLoadingRemoveAdmin: false,
				schoolAdmins: state.schoolAdmins.reduce((list, admin) => {
					if (admin.id !== action.adminId) list.push(admin);
					return list;
				}, []),
			};
		case 'REMOVE_ADMIN_FAILURE':
			return {
				...state,
				isLoadingRemoveAdmin: false,
				errorMessage: action.message,
			};
		case 'GET_ADMIN_USER_SUCCESS':
			return {
				...state,
				isLoadingAdminUser: false,
				admin: action.admin,
			};
		case 'GET_ADMIN_ROLES_SUCCESS':
			return {
				...state,
				isLoadingAdminRoles: false,
				adminRoles: action.adminRoles,
			};
		case 'CREATE_ADMIN_SUCCESS':
			return {
				...state,
				isLoadingCreateAdmin: false,
				schoolAdmins: [...state.schoolAdmins, action.createdAdmin],
			};
		case 'GET_ALL_ADMINS_SUCCESS':
			return {
				...state,
				isLoadingAdmins: false,
				schoolAdmins: action.admins,
			};
		case 'GET_ADMIN_USER_FAILURE':
			return {
				...state,
				isLoadingAdminUser: false,
				errorMessage: action.message,
				admin: null,
			};
		case 'GET_ALL_ADMINS_FAILURE':
			return {
				...state,
				isLoadingAdmins: false,
				errorMessage: action.message,
			};
		case 'CREATE_ADMIN_FAILURE':
			return {
				...state,
				isLoadingCreateAdmin: false,
				errorMessage: action.message,
			};
		case 'GET_ADMIN_ROLES_FAILURE':
			return {
				...state,
				isLoadingAdminRoles: false,
				errorMessage: action.message,
			};
		case 'CLEAR_SCHOOL_STATE':
			return {
				...state,
				...initialState,
				admin: state.admin,
			};
		default:
			return state;
	}
}

export const getAdmin = store => store[STORE_NAME].admin;

export const getAllAdmins = store => store[STORE_NAME].schoolAdmins || [];

export const isOrgAdmin = store => hasOrgAdminRole(getCurrentSchool(store));

export const getAdminRoles = store => store[STORE_NAME].adminRoles || [];

export const hasCreateAdminError = store =>
	store[STORE_NAME].errorMessage === ADMINS_ERRORS.CREATE_ADMIN;

export const isLoadingAdmins = store => store[STORE_NAME].isLoadingAdmins;

export const isLoadingAdminRoles = store =>
	store[STORE_NAME].isLoadingAdminRoles;

export const isLoadingAdminUser = store => store[STORE_NAME].isLoadingAdminUser;

export const hasFetchedAdminUser = store =>
	store[STORE_NAME].admin !== undefined;

export const isLoadingCreateAdmin = store =>
	store[STORE_NAME].isLoadingCreateAdmin;

export const onlyHasOneOrgAdmin = store =>
	store[STORE_NAME].schoolAdmins.filter(hasOrgAdminRole).length === 1;

export const hasOrgAdminRole = admin =>
	admin !== undefined &&
	admin.roleName.toLowerCase() === ADMIN_ROLE.SCHOOL_ORG_ADMIN;

export const getSchools = store =>
	store[STORE_NAME].admin ? store[STORE_NAME].admin.schools : [];

export const getCurrentSchool = store => {
	return getAdmin(store) && getSchool(store)
		? store[STORE_NAME].admin.schools.find(
				s => s.schoolId === getSchool(store).schoolId
		  )
		: undefined;
};
