import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Main } from '../common/blocks/Main';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { SCHOOL } from '../../Paths';
import { GroupTable } from './GroupPage';
import {
	actionCreators as orderActions,
	getBulkCardOrder,
	isLoadingBulkCardOrders,
} from '../../reducer/school/orders';
import { Loading } from '../common/blocks/Loading';
import { Info } from '../common/elements/Info';
import { formatDate } from '../../utils/formatting';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class BulkTravelcardOrderHistoryDetailsPage extends Component {
	componentDidMount() {
		const { match, getBulkCardOrderById } = this.props;
		const { orderId } = match.params;

		const order = getBulkCardOrderById(orderId);

		if (!order) {
			this.props.getBulkCardOrderById(orderId);
		}
	}

	render() {
		const { getOrderDetails, isLoadingOrderDetails, match, texts } = this.props;

		if (isLoadingOrderDetails) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar på både resekort och biljetter</p>
					</Banner>
					<Main>
						<Loading text="Laddar order..." />
					</Main>
				</>
			);
		}

		const order = getOrderDetails(match.params.orderId);

		if (!order) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar på både resekort och biljetter</p>
					</Banner>
					<Main>
						<Info>Kan inte hitta aktuell order.</Info>
					</Main>
				</>
			);
		}

		const cardOrderRows = order.cardOrderRows || [];

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderhistory}
					showOnMobile={true}
				>
					<h2>Orderhistorik</h2>
					<p>Här visas ordrar på både resekort och biljetter</p>
				</Banner>
				<Main>
					<OrderHeader>
						<h3>Ordernummer: {order.orderNumber}</h3>
					</OrderHeader>
					<ThemedNavLink exact to={SCHOOL.ORDER_HISTORY_TRAVELCARD}>
						Tillbaka
					</ThemedNavLink>
					<OrderInfo>
						<span>Skapat datum: </span>
						<span>{formatDate(order.createdDate)}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Antal resekort: </span>
						<span>{order.numberOfCards}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Skapad av: </span>
						<span>{order.createdBy}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Leveransadress: </span>
						<span>
							{order.attention}, {order.addressLine1}, {order.postalCode}{' '}
							{order.city}
						</span>
					</OrderInfo>
					<TravelcardTable>
						<thead>
							<tr>
								<th>Korttyp</th>
								<th>Antal</th>
							</tr>
						</thead>
						<tbody>
							{cardOrderRows.map((product, idx) => (
								<tr key={idx}>
									<td>{product.cardLayoutName}</td>
									<td>{product.amount}st</td>
								</tr>
							))}
						</tbody>
					</TravelcardTable>
					<OrderAddress>
						<h4>Leveransadress</h4>
						<p>{order.addressLine1}</p>
						<p>
							{order.postalCode} {order.city}
						</p>
					</OrderAddress>
					<OrderTotal>
						<h4>Totalt antal</h4>
						<h3>{order.numberOfCards} st</h3>
					</OrderTotal>
				</Main>
			</>
		);
	}
}

const OrderHeader = styled.div`
	display: flex;
	margin-bottom: 3em;
	justify-content: center;
	flex-direction: row;
`;

const OrderInfo = styled.div`
	margin-top: 1em;
	margin-bottom: 0.5em;
	span:first-child {
		font-weight: 600;
	}
`;

const TravelcardTable = styled(GroupTable)`
	th {
		background-color: transparent;
		color: black;
	}
	th,
	td {
		:first-of-type {
			padding-left: 0px;
		}
		:last-of-type {
			padding-right: 0px;
			text-align: right;
		}
	}

	tbody > tr:hover {
		cursor: default;
		background-color: transparent;
	}
`;

const OrderTotal = styled.div`
	text-align: right;
	float: right;
`;

const OrderAddress = styled.div`
	text-align: left;
	float: left;
`;

export default withTextContext(
	connect(
		store => ({
			getOrderDetails: getBulkCardOrder(store),
			isLoadingOrderDetails: isLoadingBulkCardOrders(store),
		}),
		{ ...orderActions }
	)(BulkTravelcardOrderHistoryDetailsPage)
);
