const initialState = {
	profileTile: '',
};

export const STORE_NAME = 'utils';

export const actionCreators = {
	profileTileToNavigateTo: (page) => (dispatch) => {
		dispatch({
			type: 'PROFILE_TILE_TO_NAVIGATE_TO',
			profileTile: page,
		})
	},
};

export default function utilsReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'PROFILE_TILE_TO_NAVIGATE_TO':
			return {
				...state,
				profileTile: action.profileTile,
			};
		default:
			return state;
	}
}

// Private selectors

export function profileTileToNavigateTo(store) {
	return store.profileTile;
}
