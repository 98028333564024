import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CustomerMobileNavigation from './CustomerMobileNavigation';
import MenuIcon from '../../images/menu.svg';
import ShoppingCartIcon from '../../images/cart_black.svg';
import SignOutIcon from '../../images/sign_out.svg';
import { getNumberOfCartItems } from '../../reducer';
import { PRIVATE } from '../../Paths';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import CustomerDesktopNavigation from './blocks/CustomerDesktopNavigation';
import { RootContainer } from '../common/blocks/RootContainer';
import { Header } from '../common/blocks/Header';
import Footer from '../customer/Footer';
import { Logo } from '../common/blocks/Logo';
// import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
// import "@reach/skip-nav/styles.css";
import {SkipToMainContent} from "./../common/SkipToMainContent"

class Root extends Component {
	constructor(props) {
		super(props);
		this.globalNav = React.createRef();
		this.state = {
			isNavOpen: false,
		}
	}

	toggleGlobalNav = () => {
		// TODO: Don't manipulate body directly, publish an event or something
		if (document.body.classList.contains('active')) {
			document.body.classList.remove('active');
			this.disableNavLinks();
			this.setState({ isNavOpen: false });
		} else {
			document.body.classList.add('active');
			this.enableNavLinks();
			this.focusNavLink();
			this.setState({ isNavOpen: true })
		}
	};

	disableNavLinks = () => {
		this.globalNav.current.setAttribute('aria-hidden', true);
		const links = this.globalNav.current.querySelectorAll('a');
		const closeBtn = this.globalNav.current.querySelectorAll('button');
		Array.prototype.forEach.call(links, a => {
			a.setAttribute('tabIndex', '-1');
			
			
		});
		Array.prototype.forEach.call(closeBtn, button => {
			button.setAttribute('tabIndex', '-1');
			
		});
	};

	enableNavLinks = () => {
		this.globalNav.current.removeAttribute('aria-hidden');
		const links = this.globalNav.current.querySelectorAll('a');
		const closeBtn = this.globalNav.current.querySelectorAll('button');
		Array.prototype.forEach.call(links, a => {
			a.removeAttribute('tabIndex', '0')
			
		});
		Array.prototype.forEach.call(closeBtn, button => {
			button.removeAttribute('tabIndex', '0')
		});
	};

	focusNavLink = () => {
		this.globalNav.current.removeAttribute('aria-hidden');
		const a = this.globalNav.current.querySelector('a');
		a.focus();
	};

	signout = () =>
		this.props.push(PRIVATE.SIGNOUT, { redirectPath: PRIVATE.HOME });

	checkout = () => this.props.push(PRIVATE.CHECKOUT);


	componentDidMount() {
		this.disableNavLinks();
	}

	render() {
		const { numberOfCartItems } = this.props;
		const hasCartItems = numberOfCartItems > 0;
		const isRoleAlert = this.props?.location?.pathname.includes("checkout") //this will only add role="alert" when user is on the cart page, this way visually impaired users can be notified when they add a ticket to the cart 

		return (
			<Fragment>
				<SkipToMainContent currentPath={this.props.location.pathname} />
				<SlideInMenu id="global-nav" ref={this.globalNav}>
					<Fragment>
						<CustomerMobileNavigation onNavigation={this.toggleGlobalNav} isNavOpen={this.state.isNavOpen} />
					</Fragment>
				</SlideInMenu>
				<Container>
					<Header>
						<div>
							<NavLink exact to={PRIVATE.TICKET}>
								<span className="sr-only" id="customerLogoDescription">Sidans logotyp, klicka för att hoppa till Mina Biljetter sidan.</span>
								<Logo type="SecondaryLogo" isClickable={true} />
							</NavLink>
						</div>
						<Menu aria-label="Meny" aria-live="assertive" aria-atomic="true">
							<CustomerDesktopNavigation />
							<MenuButton onClick={this.checkout}>
								<img
									src={ShoppingCartIcon}
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								Varukorg
								{hasCartItems && (
									<CartCounter>
										<span className="sr-only" role={isRoleAlert ? "alert" : null}>{`${numberOfCartItems} ${numberOfCartItems === 1 ? "biljett" : "biljetter"} i varukorgen`}</span>
										<span aria-live="polite" aria-atomic="true" id="cartInnerTicketsCount">{numberOfCartItems}</span>
									</CartCounter>
								)}
							</MenuButton>
							<MenuButton onClick={this.signout}>
								<img
									src={SignOutIcon}
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								Logga ut
							</MenuButton>
							<MenuButtonMobile
								onClick={this.toggleGlobalNav}
								aria-controls="global-nav"
								aria-haspopup="true"
							>
								<img
									src={MenuIcon}
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								Meny
							</MenuButtonMobile>
						</Menu>
					</Header>
					{this.props.children}
					<Footer />
				</Container>
			</Fragment>
		);
	}
}

const CartCounter = styled.span`
	position: absolute;
	top: -28%;
	right: 2%;
	font-weight: 600;
	display: inline-block;
	border-radius: 50%;
	min-width: 12px;
	min-height: 12px;
	padding: 3px;
	background-color: ${props => props.theme.primary_color};
	text-align: center;
	line-height: 1;
	box-sizing: content-box;
	white-space: nowrap;

	&::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		padding-top: 100%;
		height: 0;
	}

	span {
		display: inline-block;
		vertical-align: middle;
		color: ${props => props.theme.textOnPrimaryColor};
	}
`;

const Container = styled(RootContainer)`
	position: relative;
	top: 0;
	right: 0;
	-moz-transition: all 300ms;
	-o-transition: all 300ms;
	-webkit-transition: all 300ms;
	transition: all 300ms;

	/* Important detail for mobile:
  container must be positioned on top of nav */
	z-index: 1;

	body.active & {
		transform: translateX(-220px);
	}
`;

const Menu = styled.nav`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-grow: 1;
`;

const SlideInMenu = styled.div`
	background-image: ${props =>
		`linear-gradient(90deg, ${props.theme.background1} 0%, ${props.theme.background2} 100%)`};
	padding: 1em 2em;
	bottom: 0;
	max-width: 220px;
	overflow: auto;
	position: fixed;
	top: 0;
	right: -220px;
	width: 100%;
	z-index: 0;
	transition: all 300ms;

	body.active & {
		transform: translateX(-220px);
	}
`;

export const CloseMenuButton = styled.button`
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	font-size: 2em;
	color: ${props => props.theme.textOnPrimaryColor};
	text-shadow: none;
	opacity: 1;

	&:hover,
	&:focus {
		color: ${props => props.theme.textOnPrimaryColor};
	}
`;

const MenuButton = styled.button`
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: block;
	line-height: 1;
	padding: 0;
	text-align: center;
	min-width: 4em;
	font-size: 0.857em;
	position: relative;
	line-height: 1.5em;

	svg,
	img {
		width: auto;
		height: 18px;
		display: block;
		margin: 0 auto 8px;
	}

	& + & {
		margin-left: 10px;
	}
`;

const MenuButtonMobile = styled(MenuButton)`
	${forTabletPortraitUp`
		display: none;
	`}
`;

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		numberOfCartItems: getNumberOfCartItems(state),
	};
}

export default withRouter(connect(
	mapStateToProps,
	{ push }
)(Root))

