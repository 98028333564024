import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import { TravelCardInfo } from '../common/blocks/TravelCardInfo';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import TicketNavigation from './blocks/TicketNavigation';
import { PageHeader } from './PageHeader';
import { Main } from '../common/blocks/Main';
import { actionCreators as bearerActions } from '../../reducer/bearer';
import { actionCreators as travellerActions } from '../../reducer/bearer';
import {
	getTraveller,
	isLoadingMtbBearers,
	hasGetBearerError,
	getTravelCards,
	hasDeleteBearerError,
} from '../../reducer';
import { Label } from './../common/elements/Label';
import { Error } from './../common/elements/Error';
import { Loading } from '../common/blocks/Loading';
import { PRIVATE } from '../../Paths';
import { RegisterTravelCardLink } from './../common/elements/RegisterTravelCardLink';
import { TravelCard } from '../common/blocks/TravelCard';
import { SignedInWelcomeText } from './blocks/SignedInWelcomeText';
import { MovingBus } from './blocks/MovingBus';
import { LinkButton } from '../common/elements/LinkButton';
import {
	forTabletPortraitUp,
	forSmallPhoneOnly,
	forPhoneAndTabletPortrait
} from '../../utils/mediaqueries';
import { Card } from '../common/blocks/Card';
import isEmpty from 'lodash/isEmpty';
import { groupByFour } from '../../utils/formatting';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

export class TravelCardPage extends Component {
	defaultNoOfBearersToShow = 3;

	state = {
		bearersToShow: [],
		displayShowMoreBearersButton: true,
		showConfirmationState: false,
		selectedBearer: null,
		showError: false,
	};

	componentDidMount() {
		document.title = "Resekort | Mitt konto"
		const { travelCards } = this.props;
		const { bearersToShow } = this.state;

		if (isEmpty(travelCards)) this.props.getBearers();

		if (this.props.travelCards) {
			this.setPreferredBearerOnBearerDelete();
		}

		if (!isEmpty(travelCards) && isEmpty(bearersToShow)) {
			this.setState({
				bearersToShow: travelCards.slice(0, this.defaultNoOfBearersToShow),
			});
			return;
		}
	}

	componentDidUpdate(prevProps) {
		const { travelCards } = this.props;
		const { bearersToShow } = this.state;

		if (this.props.bearers !== prevProps.bearers) {
			this.updatePreferredBearer();
		}

		if (!isEmpty(travelCards) && isEmpty(bearersToShow)) {
			this.setState({
				bearersToShow: travelCards.slice(0, this.defaultNoOfBearersToShow),
			});
			return;
		}
	}

	updatePreferredBearer = async () => {
		await this.props.updatePreferredBearer();
	}

	setPreferredBearerOnBearerDelete = async () => {
		await this.props.setPreferredBearerOnBearerDelete();
	}

	showMoreBearers = () => {
		const { travelCards } = this.props;
		const { bearersToShow } = this.state;

		if (bearersToShow.length <= this.defaultNoOfBearersToShow)
			this.setState({
				bearersToShow: travelCards.slice(0, 2 * this.defaultNoOfBearersToShow),
			});
		else {
			this.setState({
				bearersToShow: travelCards,
				displayShowMoreBearersButton: false,
			});
		}
	};

	buyTicket = () => {
		this.props.push(PRIVATE.BUY_TICKET);
	};

	hasBearers = bearers => {
		return !!bearers && bearers.length > 0;
	};

	confirmDeleteBearer = bearer =>
		this.setState({ showConfirmationState: true, selectedBearer: bearer });

	cancelVerifyAction = () =>
		this.setState({
			showConfirmationState: false,
			selectedBearer: null,
			showError: false,
		});

	deleteBearer = async () => {
		const { selectedBearer } = this.state;
		await this.props.deleteBearer(selectedBearer.id);

		if (this.props.hasDeleteBearerError) {
			this.setState({ showError: true });
			return;
		}

		this.props.clearBearer();
		this.props.push(PRIVATE.TICKET_CONFIRMATION, {
			nextRoute: PRIVATE.TRAVEL_CARD,
			title: 'Radera resekort',
			text: 'Resekortet är nu raderat',
		});

		await this.props.getTravellerProducts();
	};

	render() {
		const { travelCards, isLoading, hasError, traveller, texts } = this.props;
		const {
			bearersToShow,
			displayShowMoreBearersButton,
			showConfirmationState,
			selectedBearer,
			showError,
		} = this.state;

		const {
			header: headerText,
			signedin: signedinText,
		} = texts.private.ticketpage.banner;

		const deleteBearerText =
			texts.private.ticketpage.travelcards.deletetravelcardinformationtext;

		return (
			<>
				<Banner bannerImg={texts.private.images.banners.ticketpage}>
					<SignedInWelcomeText
						name={traveller.name}
						header={headerText}
						signedin={signedinText}
					/>
				</Banner>
				<main>
				<Main id="ticketsTravelCardContainer">
					<PageHeader>
						<H2>Mina Biljetter</H2>
						<TicketNavigation />
					</PageHeader>

					<FlexWrapper>
						{(() => {
							if (isLoading) {
								return <Loading />;
							}

							if (hasError) {
								return <Error />;
							}
							if (showConfirmationState) {
								return (
									<DeregisterCardBox>
										<Header>Vill du radera resekortet?</Header>
										<BearerName>
										<p><b>Namn:</b> {selectedBearer.name}</p>
										</BearerName>
										<BearerSerial>
										<p><b>Nummer:</b> {groupByFour(selectedBearer.serial)}</p>
										</BearerSerial>
										<DeleteInfoBox><p>{deleteBearerText}</p></DeleteInfoBox>
										<ConfirmButton onClick={this.deleteBearer}>
											Radera
										</ConfirmButton>
										<CancelButton onClick={this.cancelVerifyAction}>
											Avbryt
										</CancelButton>
										{showError && (
											<Error>
												Ett fel uppstod när du försökte radera resekortet. Var
												god och kontakta kundtjänst eller försök igen.
											</Error>
										)}
									</DeregisterCardBox>
								);
							} else if (this.hasBearers(bearersToShow)) {
								return bearersToShow.map(x => (
									<TravelCardWrapper key={x.id}>
										<TravelCard
											onConfirmDeleteBearer={this.confirmDeleteBearer}
											bearer={x}
										/>
									</TravelCardWrapper>
								));
							} else {
								return (
									<>
										<TravelCardBox>
											<H3>Du har inget registrerat resekort</H3>
										</TravelCardBox>
										<InfoCardBox>
											<TravelCardInfo
												text={
													this.props.texts.private.ticketpage.travelcards
														.registertravelcardintrotext
												}
											/>
											<RegisterTravelCardLink />
											<BuyTicketButton onClick={this.buyTicket}>
												Köp biljett
											</BuyTicketButton>
										</InfoCardBox>
									</>
								);
							}
						})()}
					</FlexWrapper>
					{this.hasBearers(bearersToShow) && !showConfirmationState && (
						<>
								{bearersToShow.length < travelCards.length && (
								<ShowMoreRow>
									<Label>
											{bearersToShow.length} av {travelCards.length}
									</Label>
									{displayShowMoreBearersButton && (
										<LinkButton onClick={this.showMoreBearers}>
											Visa fler
										</LinkButton>
									)}
								</ShowMoreRow>
							)}
							{!showConfirmationState && (
								<LinkContainer>
									<RegisterTravelCardLink />
								</LinkContainer>
							)}
						</>
					)}
				</Main>
				</main>
				<TravelCardPageMovingBus />
			</>
		);
	}
}

const ConfirmButton = styled(SecondaryButton)`
	padding: 0.8em;
	width: 100%;
`;

const H3 = styled.h3`
font-size: 18px;
color: #464646;
`

const CancelButton = styled.button`
	border: 0;
	background: none;
	padding: 0.8em;
	cursor: pointer;
	display: flex;
	justify-content: center;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.03);
	margin-top: 1em;
	width: 100%;

	margin-bottom: 1em;

	&:hover {
		box-shadow: 0 0 4px 8px rgba(0, 0, 0, 0.03);
	}

	span {
		width: 100%;
	}
`;

const BearerName = styled.p`
	margin-top: 0;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
`;

const BearerSerial = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
`;

const Header = styled.h4`
	margin-bottom: 21px;
	margin-top: 1em;
`;

const DeleteInfoBox = styled.div`
	margin-bottom: 21px;
`;

const TravelCardPageMovingBus = styled(MovingBus)`
	svg {
		margin-left: 48%;
	}
`;

const TravelCardWrapper = styled.div`
	max-width: 307px;
	width: 100%;
	height: 183px;
	margin: 0 1em 2em;

	${forTabletPortraitUp`
		margin-bottom: 2em;
	`}

	h4 {
		font-size: 18px;
		line-height: 30px;
	}
`;

const BuyTicketButton = styled(SecondaryButton)`
	margin-top: 1em;
`;

const LinkContainer = styled.div`
	text-align: center;
	margin-top: 1.5em;
`;

const InfoCardBox = styled.div`
	display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
	max-width: 306px;
	margin-right: 1em;
	margin-left: 1em;

	${forPhoneAndTabletPortrait`
		align-items: stretch;
	`}
`;

const TravelCardBox = styled.div`
	height: 183px;
	width: 307px;
	border-radius: 7px;
	background-color: ${props => props.theme.white};
	box-shadow: 0 25px 40px 2px rgba(0, 0, 0, 0.22);
	text-align: center;
	order: -1;
	margin: 0 1em 2em;
	padding: 4em;
	display: flex;
	flex-direction: column;
	justify-content: center;

	h4 {
		color: ${props => props.theme.light_grey};
	}
`;

const DeregisterCardBox = styled(Card)`
	height: fit-content;
	max-width: 450px;

	p {
		display: inherit;
		margin: 0;
	}

	${forSmallPhoneOnly`
		box-shadow: none;
		border: none;
		background: none;
		padding: 0;
	`}
`;

const H2 = styled.h2`
	margin-top: 0;
	margin-bottom: 1em;
`;

const ShowMoreRow = styled.div`
	text-align: center;

	* {
		display: block;
		margin: 5px auto 0;
		font-family: ${props => props.theme.text};
	}

	label {
		color: ${props => props.theme.text_color};
		font-size: 12px;
		font-style: italic;
		font-weight: 300;
		line-height: 17px;
	}
`;

function mapStateToProps(state) {
	return {
		traveller: getTraveller(state),
		travelCards: getTravelCards(state),
		isLoading: isLoadingMtbBearers(state),
		hasError: hasGetBearerError(state),
		hasDeleteBearerError: hasDeleteBearerError(state),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...bearerActions,
		...travellerActions,
		push,
	})(withTheme(TravelCardPage))
);
