import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import withTextContext from '../../utils/withTextContext';
import { RegistrationVerifyEmail } from './RegistrationVerifyEmail';
import { Error } from '../common/elements/Error';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { CloseCardButton } from '../common/blocks/CloseCardButton';
import { readCookie, writeCookie } from '../../utils/cookieManager';

import {
	hasVerifyPropertyError,
	getTraveller,
} from '../../reducer';

import {
	actionCreators as travellerActions,
} from '../../reducer/traveller';

import {
	actionCreators as utilsActions,
} from '../../reducer/utils';

class ValidateEmail extends Component {
	state = {
		verificationCode: '',
		hasVerifyPropertyError: false,
		hasSubmittedVerification: false,
		showVerifyEmailBanner: false,
		showEmailNotificationBanner: true,
	};

	componentDidMount() {
		this.maybeHideVerifyEmailBanner();
		this.maybeShowEmailNotificationBanner();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.showVerifyEmailBanner !== this.state.showVerifyEmailBanner) {
			this.maybeHideVerifyEmailBanner();
		}

		if (prevState.showEmailNotificationBanner !== this.state.showEmailNotificationBanner) {
			this.maybeShowEmailNotificationBanner();
		}
	}

	maybeShowEmailNotificationBanner() {
		const emailNotificationBanner = readCookie("EMAIL_NOTIFICATION_BANNER");

		if (emailNotificationBanner != null || this.props.traveller.email != null) {
			this.setState({ showEmailNotificationBanner: false });
			return;
		}

		this.setState({ showEmailNotificationBanner: true });
	}

	maybeHideVerifyEmailBanner() {
		const verifyEmailBanner = readCookie("VERIFY_EMAIL_BANNER");

		if (verifyEmailBanner == null || this.props.traveller.email != null) {
			this.setState({ showVerifyEmailBanner: false });
			return;
		}

		const d = new Date();

		if (d.getTime() > verifyEmailBanner) {
			this.setState({ showVerifyEmailBanner: false });
			return;
		}

		this.setState({
			showVerifyEmailBanner: true,
			showEmailNotificationBanner: false,
		});
	}

	removeVerifyEmailBannerCookie = () => {
		writeCookie("VERIFY_EMAIL_BANNER", null, -1);
	}

	handleVerificationCodeChange = (value) => {
		this.setState({ verificationCode: value });
	};

	onSubmitVerificationCode = async () => {
		const {
			verificationCode: code
		} = this.state;

		const verificationData = this.getVerificationData();

		this.setState({
			hasSubmittedVerification: true,
		});

		await this.props.verifyField({
			field: verificationData.type,
			value: verificationData.value,
			code,
		});

		if (!this.props.hasVerifyPropertyError) {
			this.removeVerifyEmailBannerCookie();
			this.setState({ showEmailNotificationBanner: false });
		}
	};

	getVerificationData = () => {
		return {
			type: 'email',
			value: null,
		};
	};

	closeValidateEmailForm = () => {
		this.setState({
			showVerifyEmailBanner: false,
			showEmailNotificationBanner: false,
		});

		this.removeVerifyEmailBannerCookie();
	}

	closeEmailNotificationBanner = () => {
		writeCookie("EMAIL_NOTIFICATION_BANNER", null, 1);

		this.setState({
			showEmailNotificationBanner: false,
		});
	}

	navigateToLoginDetails = (history) => {
		history.push('/profile');
		this.props.profileTileToNavigateTo('logindetails');
		this.closeEmailNotificationBanner();
	}

	render() {
		const {
			verificationCode,
			hasSubmittedVerification,
			showVerifyEmailBanner,
			showEmailNotificationBanner,
		} = this.state;

		const {
			hasVerifyPropertyError,
		} = this.props;

		if (showVerifyEmailBanner === false && showEmailNotificationBanner === false) {
			return null;
		}

		return (
			<Wrapper>
				{(showEmailNotificationBanner && !showVerifyEmailBanner) &&
					<AddEmailNotificationWrapper>
						<CloseCardButton
							onClick={this.closeEmailNotificationBanner}
						/>

						<p>Komplettera kontot med en e-postadress</p>
						<NavigationButtonRouter onClick={this.navigateToLoginDetails}>
							Lägg till e-postadress
						</NavigationButtonRouter>
					</AddEmailNotificationWrapper>
				}

				{showVerifyEmailBanner && 
					<VerifyEmailWrapper>
						<CardWrapper>
							{
								(hasSubmittedVerification && !hasVerifyPropertyError) ?
									<CardSuccessContainer
										onClick={this.closeValidateEmailForm}
										text={<p>Din e-post är nu verifierad</p>}
									/>
							:
								<CardContainer>
									<CloseCardButton
										onClick={this.closeValidateEmailForm}
									/>
									<RegistrationVerifyEmail
										verificationCode={verificationCode}
										verificationData={this.getVerificationData}
										handleVerificationCodeChange={this.handleVerificationCodeChange}
										submitVerificationCode={this.onSubmitVerificationCode}
										type="email"
									/>
									{hasVerifyPropertyError && <Error>Felaktig kod</Error>}
								</CardContainer>
							}
							</CardWrapper>
						</VerifyEmailWrapper>
				}
			</Wrapper>
		);
	}
}

class NavigationButton extends React.Component {
	render() {
		const { history, children } = this.props;

		return (
			<SecondaryButton
				onClick={this.props.onClick.bind(null, history)}
			>
				{children}
			</SecondaryButton >
		);
	}
}
const NavigationButtonRouter = withRouter(NavigationButton);

const Wrapper = styled.div`
	position: relative;

	:first-child {
		margin-top: 0;
	}

	h4 {
		color: rgba(46, 46, 46, 0.64);
		font-size: 20px;
		line-height: 23px;
	}
`;

const AddEmailNotificationWrapper = styled.div`
	position: absolute;
	text-align: center;
	padding: 0.9em;
	width: 100%;
	background-color: #def0d3;
	z-index: 120;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 1em;

	p {
		margin: 0;
	}
`;

const VerifyEmailWrapper = styled.div`
	padding: 2em;
`;

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background-color: ${props => props.theme.white};
	box-shadow: 0 10px 36px 0px rgba(0, 0, 0, 0.22);
	text-align: center;
	position: relative; /* in order to position close button */
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
`;

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	padding: 2em;
`;

const CardSuccessContainer = styled(ConfirmationCard)`
	padding: 2em;
	box-shadow: 0 10px 36px 0px rgba(0, 0, 0, 0.22);
`;

function mapStateToProps(store) {
	return {
		hasVerifyPropertyError: hasVerifyPropertyError(store),
		traveller: getTraveller(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...travellerActions,
		...utilsActions,
	})(ValidateEmail)
);
