import {
	verifyTransaction,
	finalizeTransaction,
	cancelTransaction,
	getSwishQR,
	getTransactionHistory,
	getPurse as fetchPurse,
} from '../api';
import { getToken, getWalletId } from '../reducer';

const initialState = {
	isLoading: false,
	transactionState: null,
	errorMessage: '',
	isLoadingCancelTransaction: false,
	swishQrCodeSource: null,
	isLoadingQrCode: false,
	history: {},
	ticketHistory: [],
	purchaseHistory: [],
	purseHistory: [],
	isLoadingHistory: false,
	purchaseReceipt: null,
};

export const ERRORS = {
	VERIFY_TRANSACTION: 'VERIFY_TRANSACTION',
	FINALIZE_TRANSACTION: 'FINALIZE_TRANSACTION',
	CANCEL_TRANSACTION: 'CANCEL_TRANSACTION',
	GET_TRANSACTION_HISTORY: 'GET_TRANSACTION_HISTORY',
};

export const TRANSACTION_STATES = {
	PURCHASED: 'purchased',
	FINALIZE_PENDING: 'finalizePending',
	CANCELLED: 'cancelled',
	CANCEL_PAYMENT_REFUNDED: 'cancelPaymentRefunded',
	DEPOSITED: 'deposited',
	DENIED: 'denied',
	USER_INTERACTION_PENDING: 'userInteractionPending',
	TIMEOUT: 'timeout',
};

export const TRANSACTION_TYPE = {
	PURCHASE: 'purchase',
	CANCEL: 'cancel',
	LOAN: 'loan',
	TRANSFER: 'transfer',
	PURSE_REPLENISH: 'purseReplenish',
	PURSE_VENDOR: 'purchaseVendor',
};

export const STORE_NAME = 'transaction';

export const actionCreators = {
	verifyTransaction: transactionId => async (dispatch, getState) => {
		dispatch({ type: 'VERIFY_TRANSACTION_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const transaction = await verifyTransaction(token, transactionId);
			dispatch({
				type: 'VERIFY_TRANSACTION_SUCCESS',
				transactionState: transaction.state,
			});
		} catch (error) {
			dispatch({
				type: 'VERIFY_TRANSACTION_FAILURE',
				message: ERRORS.VERIFY_TRANSACTION,
			});
		}
	},
	finalizeTransaction: transactionId => async (dispatch, getState) => {
		dispatch({ type: 'FINALIZE_TRANSACTION_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const purchaseReceipt = await finalizeTransaction(token, transactionId);
			dispatch({
				type: 'FINALIZE_TRANSACTION_SUCCESS',
				purchaseReceipt,
			});
		} catch (error) {
			dispatch({
				type: 'FINALIZE_TRANSACTION_FAILURE',
				message: ERRORS.FINALIZE_TRANSACTION,
			});
		}
	},
	getTransactionHistory: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_TRANSACTION_HISTORY_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const history = await getTransactionHistory(token);
			dispatch({ type: 'GET_TRANSACTION_HISTORY_SUCCESS', history });
		} catch (error) {
			dispatch({
				type: 'GET_TRANSACTION_HISTORY_FAILURE',
				message: ERRORS.GET_TRANSACTION_HISTORY,
			});
		}
	},
	cancelTransaction: transactionId => async (dispatch, getState) => {
		dispatch({ type: 'CANCEL_TRANSACTION_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const cancelledTransaction = await cancelTransaction(
				token,
				transactionId
			);
			const walletId = getWalletId(state);

			dispatch({ type: 'CANCEL_TRANSACTION_SUCCESS', cancelledTransaction });
			dispatch({
				type: 'REMOVE_CANCELED_TRAVELLER_PRODUCT',
				cancelledTransaction,
			});

			try {
				const purse = await fetchPurse(token, walletId);
				dispatch({ type: 'GET_PURSE_SUCCESS', purse });
			} catch {
				//Ignore error getting purse after canceling a transaction
			}

			return true;
		} catch (error) {
			dispatch({
				type: 'CANCEL_TRANSACTION_FAILURE',
				message: ERRORS.CANCEL_TRANSACTION,
			});
			return false;
		}
	},
	getSwishQrCode: swishToken => async (dispatch, getState) => {
		dispatch({ type: 'GET_SWISH_QR_CODE_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const qrModel = {
			format: 'png',
			size: 300,
			token: swishToken,
			transparent: true,
		};

		try {
			const swishQrCodeSource = await getSwishQR(token, qrModel);
			dispatch({ type: 'GET_SWISH_QR_CODE_SUCCESS', swishQrCodeSource });
		} catch (error) {
			dispatch({ type: 'GET_SWISH_QR_CODE_FAILURE', message: error.message });
		}
	},
};

export default function cartReducer(state, action) {
	state = state || initialState;
	let history;

	switch (action.type) {
		case 'FINALIZE_TRANSACTION_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: initialState.errorMessage,
				transactionState: initialState.transactionState,
				purchaseReceipt: initialState.purchaseReceipt,
			};
		case 'VERIFY_TRANSACTION_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: initialState.errorMessage,
				transactionState: initialState.transactionState,
			};
		case 'FINALIZE_TRANSACTION_SUCCESS':
			return {
				...state,
				isLoading: false,
				purchaseReceipt: action.purchaseReceipt,
			};
		case 'VERIFY_TRANSACTION_SUCCESS':
			return {
				...state,
				isLoading: false,
				transactionState: action.transactionState,
			};
		case 'VERIFY_TRANSACTION_FAILURE':
		case 'FINALIZE_TRANSACTION_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'CANCEL_TRANSACTION_REQUEST':
			return {
				...state,
				isLoadingCancelTransaction: true,
			};
		case 'CANCEL_TRANSACTION_SUCCESS':
			history = { ...state.history };
			history[action.cancelledTransaction.id] = action.cancelledTransaction;
			return {
				...state,
				isLoadingCancelTransaction: false,
				history,
			};
		case 'CANCEL_TRANSACTION_FAILURE':
			return {
				...state,
				isLoadingCancelTransaction: false,
				errorMessage: action.message,
			};
		case 'GET_SWISH_QR_CODE_REQUEST':
			return {
				...state,
				isLoadingQrCode: true,
				errorMessage: '',
			};
		case 'GET_SWISH_QR_CODE_SUCCESS':
			return {
				...state,
				isLoadingQrCode: false,
				swishQrCodeSource: action.swishQrCodeSource,
			};
		case 'GET_SWISH_QR_CODE_FAILURE':
			return {
				...state,
				isLoadingQrCode: false,
				errorMessage: action.message,
			};
		case 'GET_TRANSACTION_HISTORY_REQUEST':
			return {
				...state,
				isLoadingHistory: true,
			};
		case 'GET_TRANSACTION_HISTORY_SUCCESS':
			history = [
				...action.history.purchaseTransactions,
				...action.history.ticketTransactions,
				...action.history.purseTransactions,
			].reduce(transactionNormalizer, {});
			const purchaseHistory = action.history.purchaseTransactions.map(
				t => t.id
			);
			const ticketHistory = action.history.ticketTransactions.map(t => t.id);
			const purseHistory = action.history.purseTransactions.map(t => t.id);

			return {
				...state,
				isLoadingHistory: false,
				history,
				purchaseHistory,
				ticketHistory,
				purseHistory,
			};
		case 'GET_TRANSACTION_HISTORY_FAILURE':
			return {
				...state,
				isLoadingHistory: false,
				errorMessage: action.message,
			};
		default:
			return state;
	}
}

const transactionNormalizer = (acc, curr) => {
	acc[curr.id] = curr;
	return acc;
};

const filterPurchaseHistory = state => {
	return state.purchaseHistory
		.map(id => state.history[id])
		.filter(
			h =>
				h.totalAmount &&
				((h.type === TRANSACTION_TYPE.PURCHASE &&
					h.state === TRANSACTION_STATES.PURCHASED) ||
					(h.type === TRANSACTION_TYPE.CANCEL &&
						(h.state === TRANSACTION_STATES.CANCELLED ||
							h.state === TRANSACTION_STATES.CANCEL_PAYMENT_REFUNDED)) ||
					(h.type === TRANSACTION_TYPE.PURSE_VENDOR &&
						(h.state === TRANSACTION_STATES.PURCHASED ||
							h.state === TRANSACTION_STATES.CANCELLED)))
		);
};

// Private selectors

export const hasVerifyError = state =>
	state.errorMessage === ERRORS.VERIFY_TRANSACTION;

export const hasFinalizeError = state =>
	state.errorMessage === ERRORS.FINALIZE_TRANSACTION;

export const isTransactionPuchased = state =>
	state.transactionState === TRANSACTION_STATES.PURCHASED;

export const isTransactionDeposited = store =>
	store[STORE_NAME].transactionState === TRANSACTION_STATES.DEPOSITED;

export const isTransactionPendingFinalize = state =>
	state.transactionState === TRANSACTION_STATES.FINALIZE_PENDING;

export const hasCancelTransactionError = state =>
	state.errorMessage === ERRORS.CANCEL_TRANSACTION;

export const getErrorMessage = state => state.errorMessage;

export const isLoadingCancelTransaction = state =>
	state.isLoadingCancelTransaction;

export const isLoadingQrCode = state => state.isLoadingQrCode;
export const getQrCodeSource = state => state.swishQrCodeSource;

export const isLoadingHistory = state => state.isLoadingHistory;

export const getPurchaseHistory = state => filterPurchaseHistory(state);

export const getTicketHistory = state =>
	state.ticketHistory.map(id => state.history[id]);

export const getPurseHistory = store => {
	const state = store[STORE_NAME];
	return state.purseHistory
		.map(id => state.history[id])
		.filter(
			h =>
				((h.state !== TRANSACTION_STATES.DENIED &&
					h.type === TRANSACTION_TYPE.PURSE_REPLENISH) ||
					(h.state !== TRANSACTION_STATES.CANCEL_PAYMENT_REFUNDED &&
						h.type === TRANSACTION_TYPE.CANCEL)) &&
				h.state !== TRANSACTION_STATES.USER_INTERACTION_PENDING &&
				h.state !== TRANSACTION_STATES.TIMEOUT
		);
};

export const getPurchaseReceipt = state => state.purchaseReceipt;
