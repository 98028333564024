import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BusImg } from '../../../images/bus_solo.svg';

export function MovingBus(props) {
	return (
		<BusConstainer {...props}>
			<BusIcon className="svg-icon" aria-hidden="true" focusable="false" />
			<hr />
		</BusConstainer>
	);
}

const BusConstainer = styled.div`
	width: 70%;
	margin: auto;
	margin-top: 2em;

	hr {
		display: block;
		margin: 0 auto 4em;
		border: 1px inset #e6e6e6;
	}
`;

const BusIcon = styled(BusImg)`
	width: 40px;
	height: 30px;
	display: flex;
	justify-content: left;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		margin-bottom: -6px;
	}
`;
