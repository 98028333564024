import React from 'react';
import styled from 'styled-components';
import { sanitizeCmsHtml } from '../../../texts';

export function ActionInfoText(props) {
	return (
		<ActionInfo
			dangerouslySetInnerHTML={sanitizeCmsHtml(
				props.texts.private.ticketpage.tickets.activationinfo
			)}
		/>
	);
}

export const ActionInfo = styled.p`
	color: #000000;
	font-family: ${props => props.theme.text};
	margin-bottom: 1em;
	margin-top: 1em;
`;
