import React, { Component } from 'react';
import { formatISODate, formatCurrency } from '../../../utils/formatting';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	Container,
	ClosedInfo,
	ProductInfoWrapper,
	ExpandDetailed,
} from './HistoryContainer';
import { TRANSACTION_TYPE } from '../../../reducer/transaction';

export default class PurseHistory extends Component {
	state = {
		expandDetailed: false,
	};

	chevronIcon = selected => {
		if (selected) {
			return (
				<ChevronUpIcon
					className="svg-icon"
					aria-hidden="true"
					focusable="false"
					alt=""
				/>
			);
		} else {
			return (
				<ChevronDownIcon
					className="svg-icon"
					aria-hidden="true"
					focusable="false"
					alt=""
				/>
			);
		}
	};

	expandDetailedInfo = () => {
		this.setState(state => ({
			expandDetails: !state.expandDetails,
		}));
	};

	getAmount = () => {
		const { transaction } = this.props;
		return transaction.type === TRANSACTION_TYPE.PURSE_REPLENISH
			? transaction.purseAmount
			: transaction.totalAmount;
	};

	render() {
		const { expandDetails } = this.state;
		const { transaction } = this.props;

		return (
			<Container>
				<ClosedInfo>
					<span>{formatISODate(transaction.timestamp)}</span>
					<em>{formatCurrency(this.getAmount())}</em>
				</ClosedInfo>

				{expandDetails && (
					<ProductInfoWrapper>
						<span>{transaction.description}</span>
					</ProductInfoWrapper>
				)}
				<ExpandDetailed onClick={this.expandDetailedInfo}>
					{this.chevronIcon(expandDetails)}
				</ExpandDetailed>
			</Container>
		);
	}
}
