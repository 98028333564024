import { IMAGE_SIZES } from '../reducer/cms';
import heroImgSmall from '../images/hero-s.jpg';
import heroImgMedium from '../images/hero-m.jpg';
import heroImgLarge from '../images/hero-l.jpg';
import profilepagepageBannerImgS from '../images/bannerImage1-s.jpg';
import profilepagepageBannerImgM from '../images/bannerImage1-m.jpg';
import profilepagepageBannerImgL from '../images/bannerImage1-l.jpg';
import buyticketpageBannerImgS from '../images/bannerImage2-s.jpg';
import buyticketpageBannerImgM from '../images/bannerImage2-m.jpg';
import buyticketpageBannerImgL from '../images/bannerImage2-l.jpg';
import ticketpageBannerImgM from '../images/bannerImage3-m.jpg';
import ticketpageBannerImgS from '../images/bannerImage3-s.jpg';
import ticketpageBannerImgL from '../images/bannerImage3-l.jpg';
import schoolOrderTileImgS from '../images/jlt2-sm.jpg';

export const base = {
	private: {
		images: {
			startpage: {
				backgroundimage: {
					[IMAGE_SIZES.HOME_PAGE_SMALL.alias]: heroImgSmall,
					[IMAGE_SIZES.HOME_PAGE_MEDIUM.alias]: heroImgMedium,
					[IMAGE_SIZES.HOME_PAGE_LARGE.alias]: heroImgLarge,
				},
			},
			banners: {
				ticketpage: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: ticketpageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: ticketpageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: ticketpageBannerImgL,
				},
				buyticketpage: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: buyticketpageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: buyticketpageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: buyticketpageBannerImgL,
				},
				profilepage: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: profilepagepageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: profilepagepageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: profilepagepageBannerImgL,
				},
			},
		},
		profilepage: {
			pageinfo: {
				description: 'Här kan du se och administrera dina personliga uppgifter',
			},
			logindetails: {
				description: 'Hantera dina inloggningsuppgifter',
				informationtext:
					'Dina inloggningsuppgifter fungerar också som kontaktuppgifter.',
			},
			consent: {
				description: 'Välj hur vi får kontakta dig',
			},
			address: {
				description: 'Ange utskicksadress',
				informationtext: 'Ändra adress för utskick',
			},
			paymentmethods: {
				description: 'Hantera vilka betalmedel du vill använda vid köp',
			},
			deregisteraccount: {
				description: 'Begär avslut av ditt konto',
				informationtext:
					'Om du vill avregistrera ditt konto så fyller du i uppgifterna, vi kontaktar dig sedan när kontot är avregistrerat.',
				resontext: 'Av vilken anledning vill du avregistrera kontot?',
			},
			history: {
				description: 'Dina tidigare köp',
			},
		},
		ticketpage: {
			banner: {
				header: 'Välkommen till Mitt konto',
				signedin: 'Du är inloggad som',
			},
			mobileapp: {
				header: 'Dina registrerade reseappar',
				body:
					'Du har ingen mobilapp registrerad på ditt konto. Om du laddar ned appen kan du logga in med samma användaruppgifter som du använder på mitt konto. Med appen kan du köpa och hantera biljetter direkt i mobilen.',
				iosAppLink: { displayValue: 'Ladda ned för iPhone', value: '' },
				androidAppLink: { displayValue: 'Ladda ned för Android', value: '' },
			},
			tickets: {
				expand: 'Hantera biljett',
				missingbearer: 'Koppla biljett',
				moveticket: 'Välj vart du vill ha din biljett',
				moveticketconfirmation: 'Är du säker på att du vill ha din biljett på',
				moveticketaccept: 'JA',
				lendticketconfirmation: 'Är du säker på att du vill ha din biljett på',
				lendticketinformation:
					'Du kan låna ut din biljett genom att skicka den som ett sms eller e-post. Den kommer tillbaka till dig 04:00 imorgon. Personen du lånar ut till måste ha ett konto på Mitt konto kopplat till den e-post/mobilnummer du anger.',
				transferticketinformation:
					'Du kan överlåta din biljett till någon annan via e-post eller mobilnummer. Personen du överlåter biljetten till måste ha ett konto på Mitt konto kopplat till den e-post eller mobilnumret du anger',
				moveticketinformation: 'Välj den bärare du vill flytta biljetten till.',
				moveticketinformationnobearer:
					'Du har ingen app eller något resekort att koppla biljetten till.',
				moveticketinformationwithbearer:
					'Du har ingen app eller något resekort att flytta biljetten till.',
				emailorphone: 'Ange e-post (exemple.exemple @mejl.com) eller mobilnummer (46701234567)',
				lendticketaccept: 'JA',
				transferticketconfirmation:
					'Är du säker på att du vill ha din biljett på',
				transferticketaccept: 'JA',
				activatetickettext: 'Aktivera',
				activationinfo:
					'När din biljett har status väntande aktivering, innebär det att den måste aktiveras före användning. Reser du med buss och har den väntande biljetten i din reseapp aktiverar du den själv före ombordstigning och lägger biljetten mot läsaren i bussen. Reser du med buss och har den väntande biljetten på ett Resekort så aktiveras biljetten automatiskt när du lägger kortet mot kortläsaren i bussen. Du är skyldig att själv aktivera biljetten innan ombordstigning om du reser med tåg. En köpt biljett kan ligga som väntande i 365 dagar, därefter är den förfallen.',
				activatebutton: 'Aktivera nu',
				enableloantickets: 'False',
				enabletransfertickets: 'False',
			},
			moveticket: {
				header: 'Bekräftelse',
				confirmationtext: 'Biljetten finns nu på',
			},
			travelcards: {
				registertravelcardintrotext:
					'Om du registrerar till resekort behöver du aldrig oroa dig för att bli av med biljetten. Om du inte har något resekort kan du beställa ett i samband med att du köper en biljett',
				registertravelcardinformationtext:
					'<p>Om du registrerar ditt resekort behöver du aldrig oroa dig för att bli av med biljetten. Den täcks då av förlustgarantin vilket innebär att kortet kan ersättas.</p><p>För att registrera ett resekort väljer du ett namn, till exempel<strong> Annas resekort</strong> och det kortnummer som står på baksidan av kortet.</p><p>För att resekortet ska gå att registrera krävs det att du har den nyare versionens resekort.</p><p>Registrera gärna hela familjens resekort max-antal registrerade resekort är 10 st.</p><p><strong>Har du ett äldre resekort kan du beställa ett nytt i samband med att du köper en biljett.</strong></p>',
				deletetravelcardinformationtext:
					'Om du har aktiva biljetter på ditt resekort när du raderar ditt resekort läggs dina biljetter under fliken "biljetter" och kan då kopplas till ett nytt resekort eller reseapp',
			},
		},
		buyticketpage: {
			rebuy: {
				header: 'Senaste köp [not in use]',
			},
			beareroptions: {
				ticketcount: 'Välj antal biljetter',
				description:
					'Du kan köpa flera biljetter samtidigt och koppla dem till olika Resekort eller reseappar',
				ticketusage: 'Välj vart du vill ha din biljett',
				activationinfo:
					'Biljetten aktiverar du själv under rubriken Mina biljetter, i reseappen eller genom att använda Resekortet ombord på bussen',
				activationnowinfo:
					'Biljetten aktiveras direkt och blir giltig för resa.',
				newtravelcard:
					'Kortet du beställer kommer att registreras på dig. Du hittar det senare under Mina biljetter.',
				hidenewcardbearer: 'False',
			},
			tickettypeoptions: {
				tickettype: 'Välj biljettyp',
				travellertype: 'Välj vem som skall resa',
			},
			cart: {
				multipleactivationinfo:
					'<div>\n<div><span>En 10-dagarsbiljett ger möjlighet till ett fritt resande under 10 valfria resdagar inom en 60-dagars period från första aktiveringen. Tänk på att du måste aktivera varje resdag innan eller i samband med din första resa.</span></div>\n</div>',
			},
		},
		checkoutpage: {
			cart: {
				emailreceiptlabel: 'Skicka kvitto till e-post',
				emailvalidationerror:
					'Fyll i en giltig e-postadress eller lämna fältet tomt',
			},
		},
		loginpage: {
			signinbox: {
				header: 'Du har blivit utloggad',
				description:
					'Logga in på Mitt konto för att få tillgång till samtliga tjänster och funktioner.',
			},
		},
		featuresbanner: {
			features: {
				buyticket:
					'Här kan du köpa biljetter till ditt eller någon annans Resekort eller reseapp.',
				createissue:
					'Här kan du ansöka om ersättning för försenad eller utebliven resa.',
				registerbearer:
					'Om du registrerar ditt Resekort så täcks det av förlustgaranti.',
			},
		},
		issuespage: {
			pageinfo: {
				header: 'Mina ärenden',
				description:
					'Här kan du ansöka om förseningsersättning eller registrera andra typer av ärenden',
			},
			issues: {
				header: 'Pågående och avslutade ärenden',
			},
		},
		registrationpage: {
			confirmationcard: {
				information:
					'Ditt mobilnummer används för den första inloggningen på ditt konto, därefter kan du under Min profil ändra till inloggning med hjälp av e-post och lösenord.',
			},
		},
		createaccount: {
			createaccountinfo: {
				infotext:
					'Skapa ett konto för att kunna köpa och hantera dina biljetter. Genom att skapa ett konto får du bland annat tillgång till vår Tappa-bort-garanti',
			},
		},
		footer: {
			information: {
				thmName: '',
				contactEmail: {
					displayValue: '',
					value: '',
				},
				address: '',
			},
			socialmedia: {
				facebookUrl: '',
				linkedinUrl: '',
				youtubeUrl: '',
				instagramUrl: '',
			},
			links: {
				link1: {
					displayValue: 'Type something',
					value: '',
				},
				link2: {
					displayValue: 'Type something',
					value: '',
				},
				link3: {
					displayValue: 'Type something',
					value: '',
				},
			},
		},
	},
	school: {
		images: {
			startpage: {
				backgroundimage: {
					[IMAGE_SIZES.HOME_PAGE_SMALL.alias]: heroImgSmall,
					[IMAGE_SIZES.HOME_PAGE_MEDIUM.alias]: heroImgMedium,
					[IMAGE_SIZES.HOME_PAGE_LARGE.alias]: heroImgLarge,
				},
			},
			banners: {
				orderpage: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: buyticketpageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: buyticketpageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: buyticketpageBannerImgL,
				},
				grouppage: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: ticketpageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: ticketpageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: ticketpageBannerImgL,
				},
				culturetrippage: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: ticketpageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: ticketpageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: ticketpageBannerImgL,
				},
				orderhistory: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: profilepagepageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: profilepagepageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: profilepagepageBannerImgL,
				},
				adminpage: {
					[IMAGE_SIZES.BANNER_SMALL.alias]: ticketpageBannerImgS,
					[IMAGE_SIZES.BANNER_MEDIUM.alias]: ticketpageBannerImgM,
					[IMAGE_SIZES.BANNER_LARGE.alias]: ticketpageBannerImgL,
				},
			},
			orderpage: {
				orderTravelcardTile: {
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
				},
				orderTicketsTile: {
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
				},
				orderExtraTravelcardsTile: {
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
					[IMAGE_SIZES.TILE.alias]: schoolOrderTileImgS,
				},
			},
		},
		culturetrippage: {
			contactinfo: {
				appdelivery:
					'Observera att kontaktpersonen behöver ha kundappen nedladdad och registrerad på angivet mobilnummer',
			},
			registertrip: {
				successinformation:
					'<p>Ditt ärende är nu skickat och kommer att behandlas inom kort</p><p>Du kan även följa ditt ärende:</p>',
			},
		},
		grouppage: {
			creategroup: {
				informationtext:
					'<ul><li>Krävs för att lägga till elever</li><li>Kan användas för en klass eller en hel skola</li><li>Kan användas för elever med samma biljettyp</li></ul>',
			},
			changegroup: {
				informationtext:
					'<ul><li>Krävs för att lägga till elever</li><li>Kan raderas efter avslutad termin/läsår<li>Kan användas för en klass eller en hel skola</li><li>Kan användas för elever med samma biljettyp</li></ul>',
			},
			importstudents: {
				importinformation:
					'<ul> <li>Ladda ner mall</li> <li>Fyll i mall enligt exempel</li> <li>Ladda upp excel-filen här</li> </ul> <p>Du kan också <strong>uppdatera</strong> en befintlig grupp genom att ladda upp reviderad lista inför terminsstart</p>',
			},
		},
		adminpage: {
			listview: {
				informationtext:
					'Här kan du med behörigheten organisationsadministratör skapa och hantera administratörer',
			},
		},
		studentpopup: {
			deletegroup: {
				deleteinformation:
					'<p>Om en elev bara tillhör en grupp:</p><ul><li>Elev kommer inte längre vara synlig i skolportalen</li><li>Registrerade resekort kommer finnas kvar vid återregistrering</li></ul>',
			},
		},
		ordertravelcard: {
			confirmorder: {
				title: 'Order lagd i varukorg',
				informationtext:
					'<li>Din order har sparats och du kan nu välja att gå vidare till varukorgen eller att lägga till fler ordrar</li><li>Ordrar grupperas på kortlayout för att förenkla utskick av kortordrar</li>',
			},
			cart: {
				informationtext:
					'<li>Resekort tilldelas valda elever och levereras uppmärkta med elevens namn och grupp till vald leveransaddress</li><li>Observera att <b>leveranstiden är 4 veckor</b>. Det går också att tilldela resekort manuellt om du har extra resekort i lager</li>',
			},
			listview: {
				informationtext:
					'Här visas <b>endast</b> elever utan resekort och reseapp. </br> Observera att elever med ev. pågående kortbeställningar visas i listan tills kort tilldelats/levererats',
			},
		},
		orderticketpage: {
			confirmorder: {
				informationtext:
					'<p><em>Priserna beräknas per läsår och redovisas exkl. moms. Summan som faktureras kan justeras beroende på beställningsdatum.</em></p>',
			},
		},
		ordertravelcardbulkpage: {
			orderinfo: {
				informationtext:
					'<p><em>Om beställning av en kartong (250 stycken) resekort/skolkort verkligen inte matchar ert behov, vänligen kontakta kundtjänst</em></p>',
			},
		},
		orderpage: {
			pageinfo: {
				orderinformationheader: 'Så fungerar beställning',
				orderinformationtext:
					'<p><b>Köpvillkor för Skolportalen</b></p><p>Skolbiljetten gäller hela läsåret men debitering görs per termin.</p><p>Fakturering: Fakturering sker i september/oktober av de skolbiljetter som startats fram tills detta datum. I januari/februari görs sedan en korrigering av höstterminen (för elever som inte haft skolbiljetten hela terminen) samt debitering av vårterminens skolbiljetter. Efter läsårets slut görs sedan en slutfaktura av vårterminens ändringar. Debitering görs direkt till beställaren.</p><p>Praktikbiljetter: Praktikbiljetterna faktureras löpande alternativs efter varje termin/läsår</p><p>Priser: Alla priser är exkl moms (6%)</p>',
			},
		},
		registerpage: {
			pageinfo: {
				registerinfo:
					'För att skapa ett skolkonto kontaktar du kundtjänst. Om din skola redan har ett konto kan fler administratörer skapas av en organisationsadministratör.',
			},
		},
		footer: {
			information: {
				thmName: '',
				contactEmail: {
					displayValue: '',
					value: '',
				},
				address: '',
			},
			socialmedia: {
				facebookUrl: '',
				linkedinUrl: '',
				youtubeUrl: '',
				instagramUrl: '',
			},
			links: {
				link1: {
					displayValue: 'Type something',
					value: '',
				},
				link2: {
					displayValue: 'Type something',
					value: '',
				},
				link3: {
					displayValue: 'Type something',
					value: '',
				},
			},
		},
	},
};
