import { css } from 'styled-components';

export const BREAKPOINTS = {
	TABLET_PORTRAIT: 600,
	MEDIUM_TABLET_LANDSCAPE: 1024,
	LARGE_PHONE: 414,
}

export const forZoomedInUsersWithSmallScreens = (...args) => css`
	@media (max-width: 220px) {
			${css(...args)};
		}
`

export const forZoomedInUsers = (...args) => css`
	@media (max-width: 290px) {
			${css(...args)};
		}
`

export const forSmallPhoneOnly = (...args) => css`
	@media (max-width: 335px) {
		${css(...args)};
	}
`;

export const forSmallAndMediumPhone = (...args) => css`
	@media (max-width: 414px) {
		${css(...args)};
	}
`;

export const forLargePhoneUp = (...args) => css`
	@media (min-width: 415px) {
		${css(...args)};
	}
`;

export const forPhoneOnly = (...args) => css`
	@media (max-width: 599px) {
		${css(...args)};
	}
`;

export const forTabletPortraitUp = (...args) => css`
	@media (min-width: 600px) {
		${css(...args)};
	}
`;

export const forTabletPortraitOnly = (...args) => css`
	@media (min-width: 600px) and (max-width: 899px) {
		${css(...args)};
	}
`;

export const forPhoneAndTabletPortrait = (...args) => css`
	@media (max-width: 899px) {
		${css(...args)};
	}
`;

export const forTabletLandscapeUp = (...args) => css`
	@media (min-width: 900px) {
		${css(...args)};
	}
`;

export const forTabletLandscapeOnly = (...args) => css`
	@media (min-width: 900px) and (max-width: 1399px) {
		${css(...args)};
	}
`;

export const forMediumTabletLandscapeUp = (...args) => css`
	@media (min-width: 1024px) {
		${css(...args)};
	}
`;

export const forDesktopUp = (...args) => css`
	@media (min-width: 1400px) {
		${css(...args)};
	}
`;

export const forBigDesktopUp = (...args) => css`
	@media (min-width: 1800px) {
		${css(...args)};
	}
`;
