import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { Main } from '../common/blocks/Main';
import { SCHOOL } from '../../Paths';
import { ReactComponent as GroupIcon } from '../../images/icon-group.svg';
import { AddButton } from '../common/elements/AddButton';
import CreateGroupPopup from './group/CreateGroupPopup';
import { actionCreators as groupActions } from '../../reducer/groups';
import { actionCreators as studentActions } from '../../reducer/students';
import { getAllGroups, isLoadingGroups } from '../../reducer';
import { Loading } from '../common/blocks/Loading';
import { formatDate } from '../../utils/formatting';
import withTextContext from '../../utils/withTextContext';
import { isEmpty } from 'lodash';
import { Banner } from '../common/blocks/Banner';

class GroupPage extends Component {
	state = {
		showPopup: false,
	};

	componentDidMount() {
		const { groups } = this.props;
		if (isEmpty(groups)) {
			this.props.getGroups();
		}
	}

	togglePopup = () => {
		this.setState({ showPopup: !this.state.showPopup });
	};

	showGroupDetails = groupId => e => {
		this.props.push(SCHOOL.GROUPDETAILS.replace(':groupId', groupId));
	};

	render() {
		const { showPopup } = this.state;
		const { groups, isLoading, texts } = this.props;

		let sortedGroups = [];
		var groupList = Object.values(groups);
		if (groupList.length > 0)
			sortedGroups = groupList.sort((a, b) => a.name.localeCompare(b.name));

		if (isLoading) {
			return (
				<>
					<Main>
						<Loading text="Laddar alla grupper..." />
					</Main>
				</>
			);
		}

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.grouppage}
				>
					<h2>Elever &amp; Grupper</h2>
					<p>Här hanteras elevinformation, skolkort och grupper</p>
				</Banner>
				<Main>
					<LinkContainer>
						<Link to={SCHOOL.GROUPS} exact activeClassName="active">
							Grupper
						</Link>
						<Link to={SCHOOL.STUDENTS} exact activeClassName="active">
							Elever
						</Link>
					</LinkContainer>

					<ToolsContainer>
						<AddButton onClick={this.togglePopup}>
							<span>Skapa ny grupp</span>
						</AddButton>
					</ToolsContainer>

					<GroupTable>
						<thead>
							<tr>
								<th />
								<th>Grupp</th>
								<th>Antal elever</th>
								<th>Skapad av</th>
								<th>Skapad datum</th>
							</tr>
						</thead>

						<tbody>
							{sortedGroups.map((group, idx) => (
								<tr key={idx} onClick={this.showGroupDetails(group.groupId)}>
									<td>
										<GroupIcon />
									</td>
									<td>{group.name}</td>
									<td>{group.studentCount}</td>
									<td>{group.createdByAdminName}</td>
									<td className="nowrap">{formatDate(group.createdOn)}</td>
								</tr>
							))}
						</tbody>
					</GroupTable>
				</Main>
				{showPopup && (
					<CreateGroupPopup closePopup={this.togglePopup} texts={texts} />
				)}
			</>
		);
	}
}

export const ToolsContainer = styled.div`
	display: flex;
	margin-bottom: 1em;
	justify-content: center;
`;

export const GroupTable = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin: auto;
	margin-bottom: 5em;

	th {
		background-color: ${props => props.theme.primary_color};
		color: ${props => props.theme.textOnPrimaryColor};
		padding: 10px;
	}
	th:first-child {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
	th:last-child {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	td {
		padding: 15px;
		border-bottom: 1px solid #ccc;
	}
	td:first-child {
		padding-right: 0px;
	}
	tbody > tr:hover {
		cursor: pointer;
		background-color: #f5f5f5;
	}
	.nowrap {
		white-space: nowrap;
	}
`;

const Link = styled(NavLink)`
	color: black;
	text-decoration: none;

	&:hover,
	&:focus,
	&.active {
		color: black;
		text-decoration: none;
		border-bottom: solid 2px;
	}

	& + & {
		margin-left: 2em;
	}
`;

const LinkContainer = styled.div`
	text-align: center;
	padding: 3em;
`;

export default withTextContext(
	connect(
		store => ({
			groups: getAllGroups(store),
			isLoading: isLoadingGroups(store),
		}),
		{ ...groupActions, ...studentActions, push }
	)(GroupPage)
);
