import React from 'react';
import styled from 'styled-components';

export function MobileAppInfo(props) {
	return (
		<Container>
			<h3>{props.header}</h3>
			<p>{props.body}</p>
		</Container>
	);
}

const Container = styled.div`
	h3 {
		margin-top: auto;
		margin-bottom: 1em;
	}
`;
