import { fetchCmsTexts } from '../api';
import { merge } from 'lodash';
import { isLoadingCmsTexts } from '.';
import { apiConfig } from '../config';

export const CMS_ERRORS = {
	GET_CMS_TEXT_STORE: 'GET_CMS_TEXT_STORE',
};

const initialState = {
	data: null,
	isLoading: false,
};

export const IMAGE_SIZES = {
	BANNER_SMALL: {
		width: 450,
		height: 210,
		alias: 'BANNER_SMALL',
	},
	BANNER_MEDIUM: {
		width: 700,
		height: 210,
		alias: 'BANNER_MEDIUM',
	},
	BANNER_LARGE: {
		width: 1500,
		height: 210,
		alias: 'BANNER_LARGE',
	},
	SMALL_SQUARE: {
		width: 300,
		height: 300,
		alias: 'SMALL',
	},
	HOME_PAGE_LARGE: {
		width: 2500,
		height: 700,
		alias: 'HOME_PAGE_LARGE',
	},
	HOME_PAGE_MEDIUM: {
		width: 1500,
		height: 700,
		alias: 'HOME_PAGE_MEDIUM',
	},
	HOME_PAGE_SMALL: {
		width: 600,
		height: 700,
		alias: 'HOME_PAGE_SMALL',
	},
	TILE: {
		width: 600,
		height: 400,
		alias: 'TILE',
	},
};

export const STORE_NAME = 'cms';

export const actionCreators = {
	loadCmsTexts: defaultTexts => async (dispatch, getState) => {
		const state = getState();
		if (isLoadingCmsTexts(state)) {
			dispatch({ type: 'ABORT_GET_CMS_TEXT_STORE_REQUEST' });
			return;
		}

		dispatch({ type: 'GET_CMS_TEXT_STORE_REQUEST' });
		try {
			const cmsTexts = await fetchCmsTexts();

			let mergedTexts = merge(defaultTexts, cmsTexts);
			dispatch({ type: 'GET_CMS_TEXT_STORE_SUCCESS', texts: mergedTexts });
		} catch (error) {
			dispatch({
				type: 'GET_CMS_TEXT_STORE_FAILURE',
				message: CMS_ERRORS.GET_CMS_TEXT_STORE,
				texts: defaultTexts,
			});
		}
	},
};

export default function CmsTextsReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_CMS_TEXT_STORE_REQUEST':
			return {
				...state,
				isLoading: true,
				data: null,
			};
		case 'GET_CMS_TEXT_STORE_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.texts,
				errorMessage: '',
			};
		case 'GET_CMS_TEXT_STORE_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
				data: action.texts,
			};
		default:
			return state;
	}
}

// Private selectors

export function getCmsTexts(state) {
	return state.data;
}

export function hasCmsTexts(state) {
	return state.data !== null && typeof state.data === 'object';
}

export function isLoading(state) {
	return state.isLoading;
}

export function hasError(state) {
	return state.errorMessage === CMS_ERRORS.GET_CMS_TEXT_STORE;
}

export const getImageUrl = (image, size) =>
	typeof image === 'string' && isCmsUrl(image)
		? `${image}&width=${size.width}&height=${size.height}`
		: image[size.alias];

const isCmsUrl = (url = '') =>
	new RegExp(`^${apiConfig.cmsBaseUrl}.*`).test(url);
