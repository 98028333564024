import React from "react";
import styled from "styled-components";
import BussenVit from "../../images/bussen_vit.svg";
import { forPhoneOnly } from "../../utils/mediaqueries";

export function MobileAppImage(props) {
	return (
		<Container {...props}>
			{props.children}
			<InnerContainer>
				<BussenScaled
					src={BussenVit}
					aria-hidden="true"
					focusable="false"
					alt=""
				/>
			</InnerContainer>
			<FakeButton />
		</Container>
	);
}

const Container = styled.div`
	height: 358px;
	width: 186px;
	border-radius: 27px;
	background-color: #ffffff;
	box-shadow: 0 82px 52px -6px rgba(0, 0, 0, 0.22);
	order: -1;

	${forPhoneOnly`
		display: none;
	`}
`;

const InnerContainer = styled.div`
	height: 290px;
	width: 169px;
	border-radius: 7px;
	background-color: rgba(0, 0, 0, 0.75);
	box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.22);
	margin-left: auto;
	margin-right: auto;
	margin-top: 1.5em;
`;

const FakeButton = styled.div`
	box-sizing: border-box;
	height: 24px;
	width: 24px;
	border: 1px solid #d8d8d8;
	border-radius: 24px;
	opacity: 0.52;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1em;
`;

const BussenScaled = styled.img`
	width: 140px;
	height: auto;
	margin-top: 7.5em;
	margin-bottom: 1em;
	margin-left: 1em;
`;
