import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Main } from '../common/blocks/Main';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { SCHOOL } from '../../Paths';
import { GroupTable } from './GroupPage';
import {
	getTicketOrderDetails,
	isLoadingTicketOrderDetails,
	isTicketOrderProcessing,
	actionCreators as orderActions,
} from '../../reducer/school/orders';
import { Loading } from '../common/blocks/Loading';
import { Info } from '../common/elements/Info';
import { formatDate } from '../../utils/formatting';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class TicketOrderHistoryDetails extends Component {
	state = {
		waitingForReload: false,
	}

	componentDidMount() {
		const { match, getOrderDetails } = this.props;
		const { ticketOrderId } = match.params;

		const order = getOrderDetails(match.params.ticketOrderId);

		if (!order) {
			this.props.getTicketOrderDetails(ticketOrderId);
		}

		this.maybeReloadTicketOrder(this.state.waitingForReload);
	}

	componentDidUpdate(prevProps, prevState) {
		this.maybeReloadTicketOrder(prevState.waitingForReload);
	}

	maybeReloadTicketOrder = (prevWaitingForReload) => {
		const { match } = this.props;
		const orderId = match.params.ticketOrderId;

		if (this.props.isTicketOrderProcessing(orderId) && !this.state.waitingForReload) {
			this.setState({ waitingForReload: true });

			setTimeout(() => this.props.getTicketOrderDetails(orderId), '30000');
		} else if (prevWaitingForReload) {
			this.setState({ waitingForReload: false });
		}
	}

	render() {
		const { getOrderDetails, isLoadingOrderDetails, match, texts } = this.props;

		if (isLoadingOrderDetails) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar för biljetter</p>
					</Banner>
					<Main>
						<Loading text="Laddar orderhistorik..." />
					</Main>
				</>
			);
		}

		const order = getOrderDetails(match.params.ticketOrderId);

		if (!order) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar för biljetter</p>
					</Banner>
					<Main>
						<Info>Kan inte hitta aktuell order.</Info>
					</Main>
				</>
			);
		}

		const tickets = order.ticketOrderRows || [];
		const vatDetails = order.vat || [];

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderhistory}
					showOnMobile={true}
				>
					<h2>Orderhistorik</h2>
					<p>Här visas ordrar för biljetter</p>
				</Banner>
				<Main>
					<OrderHeader>
						<h3>Ordernummer: {order.serialNumber}</h3>
						<h4>Status: {order.status}</h4>
					</OrderHeader>
					<ThemedNavLink exact to={SCHOOL.ORDER_HISTORY_TICKETS}>
						Tillbaka
					</ThemedNavLink>
					<OrderInfo>
						<span>Skapat datum: </span>
						<span>{formatDate(order.createdOn)}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Grupp: </span>
						<span>{order.groupName}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Antal biljetter: </span>
						<span>{order.numberOfTickets}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Skapad av: </span>
						<span>{order.createdByName}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Fakturaadress: </span>
						<span>
							{order.invoiceAddress}, {order.invoiceAddressPostalCode}{' '}
							{order.invoiceAddressCity}
						</span>
					</OrderInfo>
					<OrderInfo>
						<span>Fakturareferens: </span>
						<span>{order.invoiceReference}</span>
					</OrderInfo>
					<InfoBox>
						<span>
							Priserna är beräknade per läsår och redovisas exkl. moms. Summan
							som faktureras kan justeras beroende på beställningsdatum.
						</span>
					</InfoBox>
					<PriceContainer>
						<PriceBox>
							<span>Total summa exkl. moms: </span>
							<span>{order.totalAmountExcludingVat}kr</span>
						</PriceBox>
						{vatDetails.map((vat, idx) => (
							<PriceBox key={idx}>
								<span>Moms {vat.vat}% </span>
								<span>{vat.vatSum}kr</span>
							</PriceBox>
						))}
						<PriceBox>
							<span>Total summa inkl. moms: </span>
							<span>{order.totalAmountIncludingVat}kr</span>
						</PriceBox>
					</PriceContainer>
					<TicketsTable>
						<thead>
							<tr>
								<th>Efternamn</th>
								<th>Förnamn</th>
								<th>Biljett</th>
								<th>Pris</th>
							</tr>
						</thead>
						<tbody>
							{tickets.map((ticket, idx) => (
								<tr key={idx}>
									<td>{ticket.lastName}</td>
									<td>{ticket.firstName}</td>
									<td>{ticket.ticketName}</td>
									<td>{ticket.price}kr</td>
								</tr>
							))}
						</tbody>
					</TicketsTable>
				</Main>
			</>
		);
	}
}

const OrderHeader = styled.div`
	text-align: center;
	margin-bottom: 3em;
`;

const OrderInfo = styled.div`
	margin-top: 1em;
	margin-bottom: 0.5em;
	span:first-child {
		font-weight: 600;
	}
`;

const InfoBox = styled.div`
	width: 240px;
	float: left;
	margin-top: 2em;
`;

const PriceBox = styled.div`
	margin-top: 1em;
	span:first-child {
		font-weight: 600;
		margin-right: 1em;
	}
	span:last-child {
		float: right;
	}
`;

const PriceContainer = styled.div`
	float: right;
	margin-bottom: 3em;
`;

const TicketsTable = styled(GroupTable)`
	th {
		background-color: transparent;
		color: black;
	}
	th,
	td {
		:first-of-type {
			padding-left: 0px;
		}
		:last-of-type {
			padding-right: 0px;
			text-align: right;
		}
	}
`;

export default withTextContext(
	connect(
		store => ({
			getOrderDetails: getTicketOrderDetails(store),
			isTicketOrderProcessing: isTicketOrderProcessing(store),
			isLoadingOrderDetails: isLoadingTicketOrderDetails(store),
		}),
		{ ...orderActions }
	)(TicketOrderHistoryDetails)
);
