import React from 'react';
import styled from 'styled-components';
import {forTabletPortraitUp} from "./../../../utils/mediaqueries"

export function SignedInWelcomeText(props) {
	const { name, header, signedin } = props;

	return (
		<>
			<h1>{header}</h1>
			{name && (
				<Wrapper>
					<p>
						{signedin} <span style={{ whiteSpace: 'nowrap' }}>{name}</span>
					</p>
				</Wrapper>
			)}
		</>
	);
}
const Wrapper = styled.div`
	max-width: 100%;
	margin-bottom: 3rem;

	${forTabletPortraitUp`
		max-width: 300px;
		margin-bottom: 0;
	`}
`;
