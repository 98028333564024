import React, { Component } from 'react';
import styled from 'styled-components';
import PaymentIcon from '../../../images/card.svg';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { forPhoneAndTabletPortrait } from '../../../utils/mediaqueries';

export class RegisteredPaymentCard extends Component {
	onRemove = () => {
		const cardId = this.props.paymentCard.id;
		this.props.onRemove(cardId);
	};

	render() {
		const { paymentCard } = this.props;

		if (!paymentCard.registrationMetadata) {
			return null;
		} 
		
		return (
			<PaymentCardContainer>
				<Icon src={PaymentIcon} aria-hidden="true" focusable="false" alt="" />
				<CardInfo>
					<span>{paymentCard.registrationMetadata.issuer}</span>
					<span>{paymentCard.registrationMetadata.subject}</span>
				</CardInfo>
				<DeleteButton onClick={this.onRemove}>Radera
					<span className="sr-only">: Betalkort : {paymentCard.registrationMetadata.issuer} : {paymentCard.registrationMetadata.subject}</span>
				</DeleteButton>
			</PaymentCardContainer>
		);
	}
}

const DeleteButton = styled(SecondaryButton)`
	${forPhoneAndTabletPortrait`
    margin: 0.5em auto;
	`}
`;

const CardInfo = styled.div`
	display: flex;
	flex-direction: column;

	span {
		font-weight: 600;
		margin-top: 0;
	}

	${forPhoneAndTabletPortrait`
    margin-right: 35px;
	`}
`;

const PaymentCardContainer = styled.div`
	width: 100%;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1em;

	${forPhoneAndTabletPortrait`
		flex-wrap: wrap;
    justify-content: space-between;
	`}
`;

const Icon = styled.img`
	height: 25px;
	width: 25px;
`;
