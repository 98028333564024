import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';

const defaultLocale = 'sv-SE'; // use undefined for system preference
const defaultCurrency = 'sek';

export const formatCurrency = (
	value,
	currency = defaultCurrency,
	locale = defaultLocale
) => {
	const options = {
		style: 'currency',
		currency,
	};

	return Number(value).toLocaleString(locale, options);
};

export const formatDate = (value, locale = defaultLocale) => {
	const options = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	};

	return new Date(value).toLocaleDateString(locale, options);
};

export const formatTime = (value, locale = defaultLocale) => {
	const options = {
		hour: '2-digit',
		minute: '2-digit',
	};

	return new Date(value).toLocaleTimeString(locale, options);
};

export const formatInternationalPhoneNumber = value => {
	return String(value)
		.replace(/\s/g, '')
		.replace(/-/g, '')
		.replace(/^0?/, '');
};

export const formatISODate = value =>
	format(value, 'YYYY-MM-DD', { locale: sv });
export const formatISOTime = value => format(value, 'HH:mm', { locale: sv });
export const formatFullISODate = value =>
	format(value, 'YYYY-MM-DD HH:mm:ss:SS', { locale: sv });

export const groupCharacters = (input = '', noOfChars) =>
	input
		.split('')
		.reduce(
			(acc, curr, idx) =>
				(idx + 1) % noOfChars === 0 ? acc.concat(curr, ' ') : acc.concat(curr),
			''
		);

export const groupByFour = input => groupCharacters(input, 4);

export const clearSerialFormatting = (input = '') => {
	return input.replace(/\s/g, '').substr(0, 20);
};
