import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../../utils/formatting';

export function GroupInfo(props) {
	return (
		<div {...props}>
			{props.selectedgroup && !!props.selectedgroup.groupId && props.selectedgroup.groupId !== '-1' && (
				<>
					<Container>
						<span>Elever i gruppen: </span>
					 {props.selectedgroup.studentCount && <span>{props.selectedgroup.studentCount} st</span>}
					</Container>

					<Container>
						<span>Skapad av: </span>
						{props.selectedgroup && <span>{props.selectedgroup.createdByAdminName}</span>}
					</Container>

					<Container>
						<span>Skapad datum: </span>
						{props.selectedgroup && <span>{formatDate(props.selectedgroup.createdOn)}</span>}
					</Container>
				</>
			)}
		</div>
	);
}

const Container = styled.div`
	margin-bottom: 0.5em;
	span:first-child {
		font-weight: 600;
	}
`;
