import React from 'react';
import styled from 'styled-components';

export function CloseCardButton(props) {
	return (
		<Button onClick={props.onClick} type="button" aria-label="Stäng">
			<span aria-hidden="true">&times;</span>
		</Button>
	);
}

const Button = styled.button`
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	padding: 0;
	background: none;
	border: none;
	font-size: 2em;
	line-height: 0.5em;
`;
