import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { actionCreators, geoTags } from '../../reducer/products';
import { getRepurchasableProductSets } from '../../reducer';
import { push } from 'connected-react-router';
import { ProductInfo } from '../common/blocks/ProductInfo';
import { NextButton } from './../common/elements/NextButton';
import { Label } from './../common/elements/Label';
import { ReactComponent as ChevronUp } from '../../images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../images/chevron-down.svg';
import { PRIVATE } from '../../Paths';
import { formatCurrency } from '../../utils/formatting';
import { forPhoneOnly, forTabletPortraitUp, forZoomedInUsers } from '../../utils/mediaqueries';

class RebuyTickets extends Component {
	state = {
		expanded: false,
	};

	componentDidMount() {
		const { repurchasableProductSets } = this.props;

		if (!repurchasableProductSets) this.props.getRepurchasableProductSets();
	}

	onExpandClicked = () =>
		this.setState(state => ({ expanded: !state.expanded }));

	setSelectedProductSet = async productSet => {
		await this.props.setSelectedProductSet(productSet);
		this.props.push(PRIVATE.BUY_TICKET_OPTIONS);
	};

	onRebuyClick = value => () => this.setSelectedProductSet(value);

	screenReader = product => {
		if (product.origin && product.destination) {
			return (
				<span className="sr-only">
					<span>: Avresa: {product.origin.name}</span>
					<span>: Destination: {product.destination.name}</span>
				</span>
			);
		}

		if (product.tags.some(geoTags)) {
			return (
				<span className="sr-only">
					<span>: Biljett: {product.tags.filter(geoTags).map(t => t.title).join(', ')}</span>
				</span>
			);
		}

		return null;
	};

	render() {
		const { repurchasableProductSets } = this.props;
		const { expanded } = this.state;
		if (!repurchasableProductSets || repurchasableProductSets.length === 0)
			return null;

		let productsToShow = !!repurchasableProductSets
			? repurchasableProductSets
			: [];
		productsToShow = expanded ? productsToShow : productsToShow.slice(0, 1);
		return (
			<RebuyContainer>
				<CenteredWrapper>
					{repurchasableProductSets.length > 1 && (
						<ExpandEarlierPurchasesWrapper>
							<EarlierPurchasesText>Tidigare köp:</EarlierPurchasesText>
							<ExpandPurchasesButton onClick={this.onExpandClicked} aria-expanded={this.state.expanded ? true : false} >
								{expanded ? (
									<span>
										Dölj{' '}
										<ChevronUpIcon
											className="svg-icon"
											aria-hidden="true"
											focusable="false"
											alt=""
										/>
									</span>
								) : (
									<span>
										Visa fler{' '}
										<ChevronDownIcon
											className="svg-icon"
											aria-hidden="true"
											focusable="false"
											alt=""
										/>
									</span>
								)}
							</ExpandPurchasesButton>
						</ExpandEarlierPurchasesWrapper>
					)}
					<ProductSetWrapper>
						{productsToShow.map((product, idx) => (
							<ProductInfoWrapper
								key={product.id + idx}
								style={{ display: 'flex' }}
							>
								<div className="innerWrapper">
									<RebuyProductInfo productSet={product} />
									<ProductSetActions>
										<ProductSetCost>
											{formatCurrency(product.amount, product.currency)}
										</ProductSetCost>
										<NextButton onClick={this.onRebuyClick(product)}>
											Omköp
											{this.screenReader(product)}
										</NextButton>
									</ProductSetActions>
								</div>
							</ProductInfoWrapper>
						))}
					</ProductSetWrapper>
				</CenteredWrapper>
			</RebuyContainer>
		);
	}
}

const RebuyProductInfo = styled(ProductInfo)`
	margin-left: 1em;
	max-width: 260px;
	width: 100%;

	${forPhoneOnly`
		align-self: flex-start;
		padding-top: unset;
		margin-left: 0;

		h4 {
			font-size: 16px;
		}
		p {
			font-size: small;
		}
		.zon {
			display: none;
		}
	`}
`;

const RebuyContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	z-index: 110;
	display: flex;
	flex-direction: row;
	width: 100%;

	${forPhoneOnly`
		position: relative;
	`}
`;

const CenteredWrapper = styled.div`
	display: flex;
	flex-direction: row;
	background-color: #fff;
	border-bottom-left-radius: 2em;
	border-bottom-right-radius: 2em;
	width: 100%;
	max-width: 700px;
	padding-bottom: 1em;
	box-shadow: 0 5px 13px 10px rgba(0, 0, 0, 0.23);
	margin-top: -1px; /* Fix for IE for Rebuy card (1 px error) */
	justify-content: space-around;
	align-items: baseline;

	${forPhoneOnly`
		padding-left: 1em;
		padding-right: 1em;
		flex-direction: column;
	`}
`;

const ExpandEarlierPurchasesWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${forPhoneOnly`
		order: 1;
		width: 100%;
	`}
`;

const ProductSetWrapper = styled.div`
	width: 100%;

	${forTabletPortraitUp`
		max-width: 415px;
	`}
`;

const EarlierPurchasesText = styled.p`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 16px;
	line-height: 18px;
	margin-bottom: 3px;

	${forPhoneOnly`
		display: none;
	`}
`;

const ExpandPurchasesButton = styled.button`
	background: none;
	border: none;
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 14px;
	width: min-content;
	white-space: nowrap;
	padding: 0;

	:hover,
	:focus {
		text-decoration: underline;
		text-decoration-color: ${props => props.theme.dark_grey};
	}

	${forPhoneOnly`
		display: flex;
		justify-content: center;
		width: 100%;
	`}
`;

const ChevronUpIcon = styled(ChevronUp)`
	fill: none;
`;
const ChevronDownIcon = styled(ChevronDown)`
	fill: none;
`;

const ProductInfoWrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 900px;
	background-color: #ffffff;
	display: flex;
	border-bottom: 1px solid #e0e0e0;
	padding: 8px 0;

	:first-child {
		padding-top: 0;
	}

	:last-child {
		border-bottom: none;
	}

	.innerWrapper {
		margin-left: auto;
		margin-right: auto;
		margin-top: 6px;
		width: 100%;
		max-width: 614px;
		display: flex;
		flex: 1 0 auto;
		justify-content: space-between;

		div {
			display: flex;
			flex-direction: column;
		}

		${forPhoneOnly`
			max-width: 450px;
		`}

		${forZoomedInUsers`
			flex-direction: column;
			justify-content: center;
			align-items: stretch;
		`}
	}
`;

const ProductSetActions = styled.div`
	text-align: right;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ProductSetCost = styled(Label)`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 16px;
	line-height: 22px;
	font-style: italic;
	margin-bottom: 5px;

	:first-child {
		margin-top: 10px;
	}
`;

function mapStateToProps(store) {
	return {
		repurchasableProductSets: getRepurchasableProductSets(store),
	};
}

export default connect(
	mapStateToProps,
	{
		...actionCreators,
		push,
	}
)(RebuyTickets);
