import React, { Component } from 'react';
import { TextContext } from './';
import { themeConfig } from '../../config';
import texts from '..';

export class TextProvider extends Component {
	render() {
		let defaultTexts = texts[themeConfig.theme];
		return (
			<TextContext.Provider value={defaultTexts}>
				{this.props.children}
			</TextContext.Provider>
		);
	}
}
