import React from "react";
import styled from 'styled-components';
import { BaseButton } from "./BaseButton";

export function PrimaryButton(props) {
	return (
		<Button {...props}>{props.children}</Button>
	);
}

const Button = styled(BaseButton)`
	background-color: ${props => props.theme.primary_color};
`;
