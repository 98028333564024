import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { actionCreators, IDENTITY_ERRORS } from '../../reducer/identity';
import Welcome from './Welcome';
import { SignupContainer } from './blocks/SignupContainer';
import { SignupBox } from './blocks/SignupBox';
import RegisterPrivateCustomerForm from './RegisterPrivateCustomerForm';
import { VerifyMobilePage } from './VerifyMobilePage';
import { idpConfig } from '../../config';
import {
	hasIdentityCreateError,
	hasIdentityVerifyError,
	identityIsLoading,
} from '../../reducer';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import styled from 'styled-components';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { HomePageMain } from '../common/blocks/HomePageMain';
import LogoWrapper from '../common/blocks/LogoWrapper';
import { LinkButton } from '../common/elements/LinkButton';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { getImageUrl, IMAGE_SIZES } from '../../reducer/cms';
import { SkipToMainContent } from '../common/SkipToMainContent';
import { writeCookie } from '../../utils/cookieManager';

export const DISPLAY_MODES = {
	DESKTOP: 'DESKTOP',
	MOBILE: 'MOBILE',
};

class RegisterPage extends Component {
	RENDER_STATES = {
		SIGNUP_FORM: 'SIGNUP_FORM',
		HAS_ERROR: 'HAS_ERROR',
		INVALID_MOBILE_VERIFICATION: 'INVALID_MOBILE_VERIFICATION',
		REGISTRATION_COMPLETE_MOBILE: 'REGISTRATION_COMPLETE_MOBILE',
		REGISTRATION_FINAL: 'REGISTRATION_FINAL',
	};

	state = {
		render: this.RENDER_STATES.SIGNUP_FORM,
		mobileToVerify: '',
		email: '',
		name: '',
	};

	componentDidMount() {
		document.title = "Skapa konto | Mitt konto"
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		
			this.clearErrorMessage();

			this.setState({
				render: this.RENDER_STATES.SIGNUP_FORM,
			});
		}


	clearErrorMessage = async () => {
		await this.props.clearErrorMessage();
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		let screenWidth = window.innerWidth;

		let displayMode = DISPLAY_MODES.MOBILE;

		if (screenWidth >= 768) displayMode = DISPLAY_MODES.DESKTOP;

		this.setState({
			displayMode,
		});
	};


	register = async newIdentity => {
		const identityModel = { nexthop: idpConfig.nexthop };

		if (!isEmpty(newIdentity.mobile))
			identityModel.phone_number = newIdentity.mobile;

		if (!isEmpty(newIdentity.email))
			identityModel.email = newIdentity.email;

		await this.props.createIdentity(identityModel);
		
		if (this.props.hasCreateError) {
			return;
		}

		this.setState({
			render: this.RENDER_STATES.REGISTRATION_COMPLETE_MOBILE,
			mobileToVerify: newIdentity.mobile,
			email: newIdentity.email,
			name: newIdentity.name,
		});
	};

	onVerifyMobile = async (code, nextRenderState) => {
		const { mobileToVerify, email, name } = this.state;

		await this.props.verifyMobile({
			code,
			phone_number: mobileToVerify,
			email,
			name,
		});
		if (!this.props.hasVerifyError) {
			this.setState({ render: nextRenderState });

			if (email !== '' && email != null) {
				writeCookie("VERIFY_EMAIL_BANNER", null, ((1 / 24) / 6));
			}
		}
	};

	hasExistingIdentity = () => {
		const { errorMessage } = this.props.identity;

		return errorMessage === IDENTITY_ERRORS.IDENTITY_CONFLICT;
	};

	redirectToLogin = () => {
		this.props.push('/');
	};

	returnToForm = () => {
		this.clearErrorMessage();
		this.setState({
			render: this.RENDER_STATES.SIGNUP_FORM,
		});
	};

	clearErrorMessage = async () => {
		await this.props.clearErrorMessage();
	};

	renderCreateError = () => {
		return this.renderSignupContainer(
			<>
				<h3>Något gick fel</h3>
				<p>Det gick inte att skapa ett konto</p>
				<p>Om du redan har ett konto så är det bara att logga in</p>

				<SecondaryButton onClick={this.redirectToLogin}>
					Logga in
				</SecondaryButton>
				<LinkButton onClick={this.returnToForm}>&lsaquo; Tillbaka</LinkButton>
			</>
		);
	};

	stateRender = () => {
		let { render, displayMode } = this.state;
		let { isLoading, hasCreateError, hasVerifyError } = this.props;

		if (hasCreateError) {
			return this.renderCreateError();
		}
		if (render === this.RENDER_STATES.REGISTRATION_COMPLETE_MOBILE) {
			return this.renderSignupContainer(
				<VerifyMobilePage
					mobileToVerify={this.state.mobileToVerify}
					verifyMobileCode={this.onVerifyMobile}
					nextRenderState={this.RENDER_STATES.REGISTRATION_FINAL}
					hasValidationError={hasVerifyError}
					isLoading={isLoading}
					displayMode={displayMode}
				/>
			);
		}
		if (render === this.RENDER_STATES.REGISTRATION_FINAL) {
			return (
				<ConfirmationCard
					onClick={this.redirectToLogin}
					title="Ditt konto är nu skapat"
					text={
						<ConfirmationCardText>
							{
								this.props.texts.private.registrationpage.confirmationcard
									.information
							}
						</ConfirmationCardText>
					}
				/>
			);
		}

		return this.renderSignupContainer(
			<RegisterPrivateCustomerForm onRegister={this.register} />
		);
	};

	displayWelcomeText = displayMode => {
		if (displayMode === DISPLAY_MODES.DESKTOP) return true;

		return false;
	};

	renderSignupContainer = RenderedComponent => {
		const { displayMode } = this.state;
		let showWelcome = this.displayWelcomeText(displayMode);

		return (
			<HomePageSignupContainer>
				{showWelcome && <HomePageWelcome />}
				<SignupBox id="customerRegisterAccountHeader">{RenderedComponent}</SignupBox>
			</HomePageSignupContainer>
		);
	};

	render() {
		const { texts } = this.props;
		const backgroundImage = texts.private.images.startpage.backgroundimage;
		const backgroundSmall = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_SMALL
		);
		const backgroundMedium = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_MEDIUM
		);
		const backgroundLarge = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_LARGE
		);

		return (
			<HomePageMain
				backgroundSmall={backgroundSmall}
				backgroundMedium={backgroundMedium}
				backgroundLarge={backgroundLarge}
			>
				<SkipToMainContent currentPath="/register" />
				<LogoContainer>
					<LogoWrapper />
				</LogoContainer>
				{this.stateRender()}
			</HomePageMain>
		);
	}
}

function mapStateToProps(store) {
	return {
		isLoading: identityIsLoading(store),
		hasCreateError: hasIdentityCreateError(store),
		hasVerifyError: hasIdentityVerifyError(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, { ...actionCreators, push })(RegisterPage)
);

const LogoContainer = styled.div`
	margin-left: 2em;
	align-self: center;

	${forTabletPortraitUp`
		align-self: flex-start;
	`}
`;

const HomePageSignupContainer = styled(SignupContainer)`
	max-width: 968px;
	width: 100%;
`;

const HomePageWelcome = styled(Welcome)`
	color: #fff;
`;
const ConfirmationCardText = styled.p`
	padding: 0 20px;
`;
