import { fetchPurseParameters, getTravellerParameters } from '../api';
import {
	getCountryCodes as getCountryCodesFromStore,
	getToken,
	hasCountryCodes as hasCountryCodesInStore,
} from '../reducer';

export const ERRORS = {
	GET_TRAVELLER_PARAMETERS: 'GET_TRAVELLER_PARAMETERS',
	GET_PURSE_PARAMETERS: 'GET_PURSE_PARAMETERS',
};

const STORE_NAME = 'parameters';

const initialState = {
	countryCodes: [],
	purse: undefined,
	isLoading: false,
	isLoadingPurseParameters: false,
	errorMessage: '',
	sellerParam: null,
};

export const actionCreators = {
	getTravellerParameters: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_TRAVELLER_PARAMETERS_REQUEST' });

		try {
			const state = getState();

			if (hasCountryCodesInStore(state)) {
				dispatch({
					type: 'GET_TRAVELLER_PARAMETERS_SUCCESS',
					countryCodes: getCountryCodesFromStore(state),
				});
			} else {
				var parameters = await getTravellerParameters();
				dispatch({
					type: 'GET_TRAVELLER_PARAMETERS_SUCCESS',
					countryCodes: parameters.countryCodes,
				});
			}
		} catch (error) {
			dispatch({
				type: 'GET_TRAVELLER_PARAMETERS_FAILURE',
				message: ERRORS.GET_TRAVELLER_PARAMETERS,
			});
		}
	},
	getPurseParameters: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_PURSE_PARAMETERS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const purseParameters = await fetchPurseParameters(token);
			dispatch({ type: 'GET_PURSE_PARAMETERS_SUCCESS', purseParameters });
		} catch (error) {
			dispatch({
				type: 'GET_PURSE_PARAMETERS_FAILURE',
				message: ERRORS.GET_PURSE_PARAMETERS,
			});
		}
	},
};

export default function reducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_TRAVELLER_PARAMETERS_REQUEST':
			return {
				...state,
				isLoading: true,
			};
		case 'GET_PURSE_PARAMETERS_REQUEST':
			return {
				...state,
				isLoadingPurseParameters: true,
			};
		case 'GET_TRAVELLER_PARAMETERS_SUCCESS':
			return {
				...state,
				isLoading: false,
				countryCodes: action.countryCodes,
			};
		case 'GET_PURSE_PARAMETERS_SUCCESS':
			return {
				...state,
				isLoadingPurseParameters: false,
				purse: action.purseParameters,
			};
		case 'GET_TRAVELLER_PARAMETERS_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_PURSE_PARAMETERS_FAILURE':
			return {
				...state,
				isLoadingPurseParameters: false,
				errorMessage: action.message,
			};
		default:
			return state;
	}
}

// Private selectors

export function getCountryCodes(state) {
	return state.countryCodes;
}

export function hasCountryCodes(state) {
	return state.countryCodes.length > 0;
}

export const getPurseParameters = store => store[STORE_NAME].purse;

export const hasPurseParameters = store =>
	store[STORE_NAME].purse !== undefined;

export const isLoadingPurseParameters = store =>
	store[STORE_NAME].isLoadingPurseParameters;
