import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { CloseCardButton } from '../../common/blocks/CloseCardButton';
import LoginDetailsIcon from '../../../images/profile.svg';
import ConsentIcon from '../../../images/article.svg';
import AddressIcon from '../../../images/email.svg';
import DeregisterAccountIcon from '../../../images/trash.svg';
import HistoryIcon from '../../../images/time.svg';
import PaymentIcon from '../../../images/card.svg';
import { ConfirmationCard } from '../../common/blocks/ConfirmationCard';
import LoginDetails from '../profile/LoginDetails';
import Consent from '../profile/Consent';
import DeliveryAddress from '../profile/DeliveryAddress';
import DeregisterAccount from '../profile/DeregisterAccount';
import { Error } from '../../common/elements/Error';
import History from '../history/History';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { Loading } from '../../common/blocks/Loading';
import PaymentMethods from '../profile/PaymentMethods';
import { forMediumTabletLandscapeUp, forTabletPortraitUp, forZoomedInUsers } from '../../../utils/mediaqueries';

export class ExpandableTileArea extends Component {
	constructor(props) {
		super(props);
		this.componentElement = React.createRef();
	}

	state = {
		open: false,
		chevronPosition: -1,
		chevronPositionStyle: '50%',
		columnCount: -1,
		icon: {},
		showConfirmationCard: false,
		currentCard: '',
		showVerifyActionCard: false,
		verifyActionErrorMessage: null,
		verifyActionData: null,
		verifyActionDescription: '',
		verifyActionCallback: null,
		verifyActionCtaText: '',
		isLoadingVerifyActionCallback: false,
	};

	static propTypes = {
		onClose: PropTypes.func,
		chevronPosition: PropTypes.number,
	};

	componentDidMount() {
		const { cardName } = this.props;

		this.getChevronPosition();

		this.setState({
			currentCard: cardName,
		});
	}

	componentDidUpdate() {
		const { cardName } = this.props;
		const { showConfirmationCard, currentCard } = this.state;

		if (!showConfirmationCard)
			this.componentElement.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});

		this.getChevronPosition();

		if (!currentCard || currentCard !== cardName)
			this.setState({
				currentCard: cardName,
				showConfirmationCard: false,
			});
	}

	getCardIcon() {
		let currentIcon;

		switch (this.props.cardName) {
			case this.props.CARD_NAMES.LOGIN_DETAILS:
				currentIcon = LoginDetailsIcon;
				break;
			case this.props.CARD_NAMES.CONSENT:
				currentIcon = ConsentIcon;
				break;
			case this.props.CARD_NAMES.ADDRESS:
				currentIcon = AddressIcon;
				break;
			case this.props.CARD_NAMES.DEREGISTER_ACCOUNT:
				currentIcon = DeregisterAccountIcon;
				break;
			case this.props.CARD_NAMES.HISTORY:
				currentIcon = HistoryIcon;
				break;
			case this.props.CARD_NAMES.PAYMENTMETHODS:
				currentIcon = PaymentIcon;
				break;
			default:
				currentIcon = LoginDetailsIcon;
				break;
		}

		return currentIcon;
	}

	closeCard = () => {
		this.props.onCardClosed();
	};

	getChevronPosition = () => {
		const { chevronPosition, columnCount } = this.props;
		let chevronPositionStyle;

		if (
			columnCount === this.state.columnCount &&
			chevronPosition === this.state.chevronPosition
		)
			return;

		if (columnCount === 1) {
			chevronPositionStyle = '50%';
		} else if (columnCount === 2) {
			if (chevronPosition === 0) {
				chevronPositionStyle = '22.5%';
			} else {
				chevronPositionStyle = '77.5%';
			}
		} else {
			if (chevronPosition === 0) {
				chevronPositionStyle = '14.5%';
			} else if (chevronPosition === 1) {
				chevronPositionStyle = '50%';
			} else {
				chevronPositionStyle = '85.5%';
			}
		}

		this.setState({
			chevronPositionStyle,
			chevronPosition,
			columnCount,
		});
	};

	getGridStyling = () => {
		let { selectedRow, columnCount } = this.props;

		//IE needs explicit rows for grid
		// ExpandableTileAreas should be rendered on rows between tiles, so we add 1 to the selected row
		let ieGridRow = 2 * (selectedRow + 1);
		let gridRow = selectedRow + 2;

		let ieGridSpanEnd;
		if (columnCount === 1) ieGridSpanEnd = columnCount;
		else if (columnCount === 2) ieGridSpanEnd = columnCount + 1;
		else ieGridSpanEnd = columnCount + 2;

		return {
			ieGridSpanEnd: ieGridSpanEnd,
			gridColumnEnd: columnCount + 1,
			columnCount,
			ieGridRow,
			gridRow,
		};
	};

	toggleCard = () => {
		this.setState(
			prevState => ({ open: !prevState.open }),
			() => {
				const { onOpen } = this.props;

				if (onOpen) {
					this.props.onOpen(this.state);
				}
			}
		);
	};

	getRenderContent = cardName => {
		switch (cardName) {
			case this.props.CARD_NAMES.LOGIN_DETAILS:
				return (
					<LoginDetails renderConfirmationCard={this.renderConfirmationCard} />
				);
			case this.props.CARD_NAMES.CONSENT:
				return <Consent renderConfirmationCard={this.renderConfirmationCard} />;
			case this.props.CARD_NAMES.ADDRESS:
				return (
					<DeliveryAddress
						renderConfirmationCard={this.renderConfirmationCard}
					/>
				);
			case this.props.CARD_NAMES.DEREGISTER_ACCOUNT:
				return (
					<DeregisterAccount
						onShowVerifyAction={this.onShowVerifyAction}
						renderConfirmationCard={this.renderConfirmationCard}
						onCancel={this.closeCard}
					/>
				);
			case this.props.CARD_NAMES.HISTORY:
					return (
						<History
							onShowVerifyAction={this.onShowVerifyAction}
							renderConfirmationCard={this.renderConfirmationCard}
						/>
					);
			case this.props.CARD_NAMES.PAYMENTMETHODS:
				return (
					<PaymentMethods
						onShowVerifyAction={this.onShowVerifyAction}
						renderConfirmationCard={this.renderConfirmationCard}
						onCancel={this.closeCard}
					/>
				);
			default:
				return <TileError />;
		}
	};

	onShowVerifyAction = ({ctaText, description, callback, errorMessage, data}) => {
		this.setState({
			showVerifyActionCard: true,
			verifyActionData: data,
			verifyActionDescription: description,
			verifyActionCallback: callback,
			verifyActionErrorMessage: errorMessage,
			verifyActionCtaText: ctaText,
		});
	};

	renderConfirmationCard = text => {
		this.setState({
			showConfirmationCard: true,
			confirmationCardText: text,
		});
	};

	cancelVerifyAction = () => {
		this.setState({
			showVerifyActionCard: false,
			verifyActionData: null,
			verifyActionErrorMessage: '',
			verifyActionCtaText: '',
			verifyActionDescription: '',
		});
	};

	verifyAction = async () => {
		const { verifyActionCallback, verifyActionData } = this.state;

		this.setState({isLoadingVerifyActionCallback: true});
		
		await verifyActionCallback(verifyActionData);
		
		this.setState({isLoadingVerifyActionCallback: false});
	}

	renderVerifyAction = () => {
		const {
			verifyActionErrorMessage,
			isLoadingVerifyActionCallback,
			verifyActionDescription,
			verifyActionCtaText,
		} = this.state;
		
		return (
			<ContentColumn>
				<Header>Är du säker?</Header>
				<Description>
					{verifyActionDescription}
				</Description>
				<ConfirmButton
					onClick={this.verifyAction}
					disabled={this.state.isLoadingVerifyActionCallback}
				>
					{verifyActionCtaText}
				</ConfirmButton>

				{isLoadingVerifyActionCallback && (
					<Loading/>
				)}

				{verifyActionErrorMessage && (
					<Error>
						{verifyActionErrorMessage}
					</Error>
				)}

				<CancelButton onClick={this.cancelVerifyAction}>Avbryt</CancelButton>
			</ContentColumn>
			)
	}

	render() {
		const { cardName } = this.props;
		var icon = this.getCardIcon();
		let gridStyling = this.getGridStyling();
		let {
			chevronPositionStyle,
			columnCount,
			showConfirmationCard,
			confirmationCardText,
			showVerifyActionCard,
		} = this.state;

		const showIconColumn = columnCount !== 1 ? true : false;
		const showGapFillerColumn = columnCount === 3 ? true : false;

		return (
			<>
				{showConfirmationCard ? (
					<FullWidthRow
						chevronPosition={chevronPositionStyle}
						gridStyling={gridStyling}
					>
						<ConfirmationCardFullWidth
							chevronPosition={chevronPositionStyle}
							gridStyling={gridStyling}
							onClick={this.closeCard}
							text={confirmationCardText}
						/>
					</FullWidthRow>
				) : (
					<ExpandedCard
						ref={this.componentElement}
						chevronPosition={chevronPositionStyle}
						gridStyling={gridStyling}
					>

						<CloseCardButton onClick={this.closeCard} />
						{showIconColumn ? (
							<IconColumn>
								<CardIcon
									src={icon}
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								<CardTitle>{this.props.displayName}</CardTitle>
							</IconColumn>
						) : null}
						{showVerifyActionCard ? (
							this.renderVerifyAction()
						) : (
							<ContentColumn>{this.getRenderContent(cardName)}</ContentColumn>
						)}
						{showGapFillerColumn ? <CardColumn /> : null}
					</ExpandedCard>
				)}
			</>
		);
	}
}

const TileError = styled(Error)`
	margin-top: 0;
`;

const FullWidthRow = styled.div`
	width: 100%;
	height: 250px;
	max-width: none !important;
	justify-content: center;
	align-content: center;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-grid-row: ${props => props.gridStyling.ieGridRow};
	grid-row: ${props => props.gridStyling.gridRow};
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: ${props => props.gridStyling.ieGridSpanEnd};
	grid-column-end: ${props => props.gridStyling.gridColumnEnd};
	margin: 1.25em 0;
`;

const ConfirmationCardFullWidth = styled(ConfirmationCard)`
	width: 100%;
	height: 250px;
	max-width: none !important;
	justify-content: center;
	align-content: center;
	position: relative;
	display: flex;
	flex-direction: column;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0;

	.confirmationCard {
		width: 100%;
		height: 100%;
		justify-content: center;
	}

	::after {
		bottom: 100%;
		left: ${props => props.chevronPosition};
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: ${props => props.theme.primary_color};
		border-width: 8px;
		margin-left: -8px;
		/* Fix confirmation card arrow gap on top on IE and Edge  */
		margin-bottom: -1px;
	}

	/* IE workaround */
	@supports (display: grid) {
		margin: 1.25em 0;
	}
`;

const ExpandedCard = styled.div`
	width: 100%;
	background-color: #ffffff;
	-webkit-box-shadow: 0 25px 40px 2px rgba(0, 0, 0, 0.22);
	box-shadow: 0 25px 40px 2px rgba(0, 0, 0, 0.22);
	border-radius: 7px;
	max-width: none !important;
	position: relative;
	padding: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-grid-row: ${props => props.gridStyling.ieGridRow};
	grid-row: ${props => props.gridStyling.gridRow};
	-ms-grid-column: 1;
	grid-column-start: 1;
	-ms-grid-column-span: ${props => props.gridStyling.ieGridSpanEnd};
	grid-column-end: ${props => props.gridStyling.gridColumnEnd};
	margin: 1.25em 0;
	z-index: 1;

	::after {
		bottom: 100%;
		left: ${props => props.chevronPosition};
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: #ffffff;
		border-width: 8px;
		margin-left: -8px;
	}

	${forTabletPortraitUp`
		padding: 90px 35px 50px 0;
	`}

	${forMediumTabletLandscapeUp`
		padding: 90px 0 50px;
	`}

	${forZoomedInUsers`
		padding: 3rem 1.5rem;
	`}
`;

const CardColumn = styled.div`
	flex-grow: 1;
	width: 25%;
`;

const ContentColumn = styled(CardColumn)`
	width: 50%;

	${forZoomedInUsers`
		padding-top: 1rem;
	`}
`;

const IconColumn = styled(CardColumn)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CardTitle = styled.h1`
	font-size: 25px;
	line-height: 34px;
	font-family: ${props => props.theme.text};
	color: ${props => props.theme.dark_grey};
`;

const CardIcon = styled.img`
	margin: 0 auto;
	height: 100px;
	width: 100px;
`;

const ConfirmButton = styled(SecondaryButton)`
	padding: 0.8em;
	width: 100%;
`;

const CancelButton = styled.button`
	border: 0;
	background: none;
	padding: 0.8em;
	cursor: pointer;
	display: flex;
	justify-content: center;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.03);
	margin-top: 1em;
	width: 100%;

	&:hover {
		box-shadow: 0 0 4px 8px rgba(0, 0, 0, 0.03);
	}

	span {
		width: 100%;
	}
`;

const Description = styled.p`
	font-style: italic;
`;

const Header = styled.h2`
	margin-top: 0;
	margin-bottom: 21px;
	color: #d0021b;
`;
