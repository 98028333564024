export const Validation = {
	mobile: /^\+?[1-9]\d{1,14}$/,
	email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/,
	countryCode: /^\d*$/,
	onlyDigits: /^\d+$/,
	serial: /^[a-zA-ZåäöÅÄÖ 0-9]{0,24}/,
	noDigits: /^\D+/,
	time: /^(([01][0-9]?)|(2[0-3]?)):?([0-5][0-9]?)?$/,
	date: /^(20?[0-9]?[0-9]?)-?((0?[1-9]?)|(1?[0-2]?))?-?(([0-2]?[0-9]?)|(3?[0-1]?))?$/,
	postalCode: /^\d{0,3}\s?\d{0,2}$/,
	city: /^[a-zA-ZäöåÄÖÅ\s-]+$/,
	noNumbers: /^([^0-9]*)$/,
	personalIdentityNumber: /^.+$/,
};

export const regexToInputPattern = regex =>
	regex
		.toString()
		.replace(/^\//, '') // Remove starting /
		.replace(/\/$/, '') // Remove trailing /
		.replace(/"/g, '\\x22') // Replace " for escaped hexadecimal chars
		.replace(/\s/g, ''); // Allow space after third char
