import React from 'react';
import styled from 'styled-components';
import { Label } from '../../common/elements/Label';
import { Validation, regexToInputPattern } from '../../../utils/validation';
import { formatInternationalPhoneNumber } from '../../../utils/formatting';
import { TextInput } from '../../common/elements/TextInput';
import { TYPES } from './LoginDetails';
import { ReactComponent as EmailImg } from '../../../images/email.svg';
import { ReactComponent as MobileImg } from '../../../images/mobile.svg';
import { NextButton } from '../../common/elements/NextButton';
import { LinkButton } from '../../common/elements/LinkButton';
import { forMediumTabletLandscapeUp } from '../../../utils/mediaqueries';

export class LoginDetailsEditableField extends React.Component {
	state = {
		errorMessage: '',
		duplicateDisable: false,
	};

	onChange = e => {
		const { type } = this.props;
		let name = '';

		if (type === TYPES.PHONENUMBER) name = 'newPhoneNumber';
		if (type === TYPES.EMAIL) name = 'newEmail';

		this.setState({ duplicateDisable: false });

		this.props.handleChange({ name, value: e.value.toLowerCase() });
	};

	onChangeCountryCode = e =>
		e.value.length <= 3 && this.props.handleChangeCountryCode(e.value);

	onEnableEdit = () => {
		const { type } = this.props;

		this.props.enableEdit(type);
	};

	onSubmit = async () => {
		const { value, type, currentValue } = this.props;

		if (type === TYPES.PHONENUMBER) {
			const { countryCode } = this.props;
			let validCountryCode = this.isValidCountryCode(countryCode);
			let formattedNumber = formatInternationalPhoneNumber(value);

			if (!formattedNumber.startsWith(countryCode))
				formattedNumber = countryCode + formattedNumber;

			let isSameNumber = formattedNumber === currentValue;

			if (
				Validation.mobile.test(formattedNumber) &&
				validCountryCode &&
				!isSameNumber
			) {
				this.props.handleSubmit({ type, value: formattedNumber });
			} else {
				let errorMessage = '';
				if (!validCountryCode) errorMessage = 'Ej giltig landskod';
				if (!Validation.mobile.test(formattedNumber)) {
					if (!validCountryCode) errorMessage += '\n';
					errorMessage += 'Ej giltigt telefonnummer';
				}

				let duplicateDisable = false;
				if (isSameNumber) {
					errorMessage = 'Numret är redan satt på ditt konto';
					duplicateDisable = true;
				}

				this.setState({ errorMessage, duplicateDisable });
			}
		} else if (type === TYPES.EMAIL) {
			if (Validation.email.test(value)) {
				this.props.handleSubmit({ type, value });
			} else {
				this.setState({ errorMessage: 'Ska innehålla ett @. Format: exempel.exempel@mejl.com' });
			}
		}
	};

	isValidCountryCode = countryCode => {
		const { countryCodes } = this.props;
		if (countryCodes.indexOf(+countryCode) !== -1) return true;
		return false;
	};

	getTypeLabel = () => {
		const { type } = this.props;

		if (type === TYPES.PHONENUMBER) {
			return (
				<>
					<MobileIcon aria-label="Mobil" style={{ marginBottom: '4px' }}/>
					<FieldLabel className="sr-only" htmlFor={type + 'LoginDetailsInput'}>
						Mobil
					</FieldLabel>
				</>
			);
		} else if (type === TYPES.EMAIL) {
			return (
				<>
					<EmailIcon aria-label="Epost" style={{ marginBottom: '4px' }}/>
					<FieldLabel className="sr-only" htmlFor={type + 'LoginDetailsInput'}>
						Epost
					</FieldLabel>
				</>
			);
		}
	};

	onBackClick = () => {
		this.setState({
			errorMessage: '',
			duplicateDisable: false,
		});

		this.props.onBackClick();
	}

	countryCodePattern = regexToInputPattern(Validation.countryCode);

	title = () => {
		const { type } = this.props;
		if(type === TYPES.PHONENUMBER) {
			return (
			<>
				<span>Ändra</span>
				<span className="sr-only">Telefonnummer</span>
			</>
			)
		} else if(type === TYPES.EMAIL) {
			return (
			<>
				<span>Ändra</span>
				<span className="sr-only">Epost</span>
			</>
			)
		}
	}

	render() {
		const {
			currentValue,
			isEditable,
			value,
			type,
			changeDisabled,
			submitDisabled,
			countryCode,
		} = this.props;
		const { errorMessage, duplicateDisable } = this.state;

		return (
			<>
				<RowWrapper hasMargin={isEditable}>
					{this.getTypeLabel()}
					{isEditable ? (
						<>
							<FlexContainer>
								{type === TYPES.PHONENUMBER ? (
									<>
										<ContainerDescription htmlFor={type + 'LoginDetailsInput'}>Mobilnummer</ContainerDescription>
										<CountryCodeField
											type="text"
											value={countryCode}
											handleChange={this.onChangeCountryCode}
											id={type + 'CountryCodeInput'}
											disabled={false}
											maxLength="3"
											minLength="1"
											pattern={this.countryCodePattern}
											autoComplete="tel-country-code"
											style={{minWidth: "5rem"}}
										/>
										<MobileEditableField
											type="text"
											value={value}
											handleChange={this.onChange}
											id={type + 'LoginDetailsInput'}
											disabled={false}
											autoComplete="tel-national"
											errorMessage={errorMessage || "Ej giltigt telefonnummer"}
											isError={Boolean(errorMessage)}
											isFullWidth
											offset="5rem"
											helperText="Anges i följande format: 701234567"
										/>
									</>
								) : (
									<>
									<ContainerDescription htmlFor={type + 'LoginDetailsInput'}>E-post</ContainerDescription>
									<EditableField
										type="text"
										value={value}
										handleChange={this.onChange}
										id={type + 'LoginDetailsInput'}
										disabled={false}
										autoComplete="email"
										errorMessage={this.state.errorMessage || "Ska innehålla ett @. Format: exempel.exempel@mejl.com"}
										isError={Boolean(this.state.errorMessage)}
										isFullWidth
										helperText="exemple.exemple@mejl.com"
									/>
									</>
								)}
							</FlexContainer>
						</>
					) : (
						<CurrentValueField>
							{!!currentValue ? currentValue : 'Ej angiven'}
						</CurrentValueField>
					)}
					<ChangeButton
						onClick={isEditable ? this.onSubmit : this.onEnableEdit}
						disabled={
							(isEditable && submitDisabled) ||
							(!isEditable && changeDisabled) ||
							duplicateDisable
						}
					>
						{this.title()}
					</ChangeButton>
				</RowWrapper>
				{/* Validation errors have been moved to each individual TextInput field - See KOL-2035 for more details */}
				{/* <ValidationError>{errorMessage}</ValidationError> */}
				{isEditable && (
					<BackButton onClick={this.onBackClick}>
						&lsaquo; Tillbaka
					</BackButton>
				)}
			</>
		);
	}
}

const BackButton = styled(LinkButton)`
	margin-bottom: 1em;
	display: block;
`;

const RowWrapper = styled.div`
	flex-direction: column;
	position: relative;
	margin-top: ${props => (props?.hasMargin ? "40px" : '10px')};

	
	
	${forMediumTabletLandscapeUp`
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: baseline;
		`}
`;

const ContainerDescription = styled.label`
    position: absolute;
    z-index: 10;
    top: -4px;
	left: 3rem;
	

	${forMediumTabletLandscapeUp`
		top: -2.2rem;
    	left: 0;
	`}
`

const FlexContainer = styled.div`
	display: flex;
	flex-grow: 1;
	margin-right: 5px;
	overflow-x: hidden;
`;

const FieldLabel = styled(Label)`
	display: flex;
	align-items: center;
`;

const MobileIcon = styled(MobileImg)`
	margin-right: 4px;
	height: 14px;
`;

const EmailIcon = styled(EmailImg)`
	height: 14px;
	margin-right: 4px;
`;

const EditableField = styled(TextInput)`
	margin-bottom: 10px;
	flex: 1;
	margin-right: 5px;
`;

const MobileEditableField = styled(EditableField)`
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: 0;
	flex: 1;
	min-width: 55px;
`;

const CountryCodeField = styled(TextInput)`
	width: 55px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	text-align: center;
	
`;

// const ValidationError = styled.p`
// 	color: #ff0000;
// 	font-size: 14px;
// 	white-space: pre-line;
// `;

const CurrentValueField = styled.p`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 5px;
	flex-grow: 1;
`;

const ChangeButton = styled(NextButton)`
	white-space: nowrap;
	margin-bottom: 10px;
	width: 100%;

	${forMediumTabletLandscapeUp`
		width: auto;
	`}
`;
