import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { productTags, geoTags } from '../../../reducer/products';
import isEqual from 'lodash/isEqual';
import { NextButton } from '../../common/elements/NextButton';
import { forZoomedInUsers } from '../../../utils/mediaqueries';

export const getTicketType = offer => offer.tags.find(productTags);
export const getGeo = offer => offer.tags.find(geoTags);

export const getOffersOfType = (offers, productType) =>
	offers.filter(o => getTicketType(o).groupTag === productType);

export const getOffersWithUniqueGeo = offers =>
	offers.reduce(
		(list, offer) =>
			list.find(o => isEqual(getGeo(o), getGeo(offer)))
				? list
				: [...list, offer],
		[]
	);

export const getOffersByDistinctTicketType = offers =>
	offers.reduce(
		(list, offer) =>
			list.find(o => isEqual(getTicketType(o), getTicketType(offer)))
				? list
				: [...list, offer],
		[]
	);

export class OfferSelector extends Component {
	selectOffer = offer => () => this.props.onSelectOffer(offer);

	isSelected = offer => this.props.selected && (this.props.selected.id === offer.id || this.props.selected.spatialTitle === offer.spatialTitle)


	render() {
		const { offers, productType } = this.props;

		const offersOfType = getOffersOfType(offers, productType);
		const uniqueGeoOffers = getOffersWithUniqueGeo(offersOfType);

		return (
			<Container>
				{uniqueGeoOffers.map((offer, idx) => (
					<Offer
						key={idx}
						className={classNames({ selected: this.isSelected(offer) })}
					>
						<OfferTitle>{offer.spatialTitle}</OfferTitle>
						{!this.isSelected(offer) && (
							<NextButton onClick={this.selectOffer(offer)}>Välj</NextButton>
						)}
					</Offer>
				))}
			</Container>
		);
	}
}

const Container = styled.ul`
	margin: 1em auto;
	padding: 0;
`;

const Offer = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: solid 1px ${props => props.theme.lighter_grey};
	padding: 1em;

	&:last-child {
		border-bottom: solid 1px ${props => props.theme.lighter_grey};
	}

	&.selected {
		border: dashed 2px rgba(0, 0, 0, 0.75);
	}

	&.selected + & {
		border-top: none;
	}

	${forZoomedInUsers`
		flex-direction: column;
		gap: 1rem;
	`}
`;

const OfferTitle = styled.p`
	font-weight: 600;
	margin: 0;
`;
