import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Radiobuttons } from './../common/elements/Radiobuttons';

export class ActivationOptions extends Component {
	setActivation = choice => this.props.onSelect(choice);

	render() {
		const { defaultChoice, activationChoices } = this.props;

		return (
			<Fragment>
				<form onSubmit={e => e.preventDefault()} id="customerTicketActivationForm">
					<fieldset>
						<legend className='sr-only'>Aktiveringsalternativ</legend>
						<Radiobuttons
							name="activationOptions"
							choices={activationChoices}
							defaultChoice={defaultChoice}
							onSelect={this.setActivation}
							renderChoice={(choice, renderRadiobutton) => (
								<>
									<TypeDescription>
										<span><p>{choice.name}</p></span>
										<i><p>{choice.description}</p></i>
									</TypeDescription>
									{renderRadiobutton()}
								</>
							)}
						/>
					</fieldset>
				</form>
			</Fragment>
		);
	}
}

const TypeDescription = styled.div`
	flex-grow: 1;
	text-align: left;
	margin-bottom: 15px;

	span {
		display: block;
	}

	i {
		font-size: 0.8em;
	}
	p {
		display: inherit;
		margin: 0;
	}
`;
