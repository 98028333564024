import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { actionCreators as travellerActions } from '../../reducer/traveller';
import { actionCreators as utilsActions } from '../../reducer/utils';
import { Main } from '../common/blocks/Main';
import { ExpandableTileArea } from './blocks/ExpandableTileArea';
import Tile from './blocks/Tile';
import LoginDetails from './profile/LoginDetails';
import Consent from './profile/Consent';
import DeliveryAddress from './profile/DeliveryAddress';
import DeregisterAccount from './profile/DeregisterAccount';
import History from './history/History';
import PaymentMethods from './profile/PaymentMethods';
import { Error } from './../common/elements/Error';
import { getTraveller, getLocationState, profileTileToNavigateTo } from '../../reducer';
import { BREAKPOINTS, forPhoneOnly } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

export const QUERY_PARAMS = {
	SELECTED_CARD: 'selectedcard',
};

export const DISPLAY_MODES = {
	DESKTOP: 'DESKTOP',
	TABLET: 'TABLET',
	MOBILE: 'MOBILE',
};

export const CARD_NAMES = {
	LOGIN_DETAILS: 'logindetails',
	CONSENT: 'consent',
	ADDRESS: 'address',
	DEREGISTER_ACCOUNT: 'deregisteraccount',
	HISTORY: 'history',
	PAYMENTMETHODS: 'paymentmethods',
};
class ProfilePage extends React.Component {
	state = {
		selectedCard: null,
		expandState: false,
		row: 0,
		column: 0,
		columnCount: 3,
		displayName: '',
	};

	static getDerivedStateFromProps(props, state) {
		let { locationState } = props;

		return locationState && state.selectedCard === null
			? { selectedCard: locationState.selectedCard }
			: null;
	}

	tiles = [
		{
			tileName: CARD_NAMES.LOGIN_DETAILS,
			displayName: 'Inloggning',
			icon: 'profile',
			headerText: 'Inloggning',
			bodyText: this.props.texts.private.profilepage.logindetails.description,
		},
		{
			tileName: CARD_NAMES.ADDRESS,
			displayName: 'Adress',
			icon: 'email',
			headerText: 'Adress',
			bodyText: this.props.texts.private.profilepage.address.description,
		},
		{
			tileName: CARD_NAMES.CONSENT,
			displayName: 'Samtycken',
			icon: 'article',
			headerText: 'Samtycken',
			bodyText: this.props.texts.private.profilepage.consent.description,
		},
		{
			tileName: CARD_NAMES.DEREGISTER_ACCOUNT,
			displayName: 'Avregistrera',
			icon: 'trash',
			headerText: 'Avregistrera konto',
			bodyText: this.props.texts.private.profilepage.deregisteraccount
				.description,
		},
		{
			tileName: CARD_NAMES.HISTORY,
			displayName: 'Köphistorik',
			icon: 'time',
			headerText: 'Historik',
			bodyText: this.props.texts.private.profilepage.history.description,
		},
		{
			tileName: CARD_NAMES.PAYMENTMETHODS,
			displayName: 'Betalmedel',
			icon: 'card',
			headerText: 'Betalmedel',
			bodyText: this.props.texts.private.profilepage.paymentmethods.description,
		},
	];

	componentDidMount() {
		document.title = "Min profil | Mitt konto"
		const { traveller } = this.props;

		if (!traveller) {
			this.props.getTraveller();
		}

		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate(prevProps) {
		if (this.props.profileTile !== '' && Object.values(CARD_NAMES).includes(this.props.profileTile)) {
			this.onOpenTile(this.props.profileTile);

			this.props.profileTileToNavigateTo('');
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		let { selectedCard } = this.state;

		const viewPortWidth = document.documentElement.clientWidth;
		let displayMode = this.getDisplayMode(viewPortWidth);

		let columnCount;
		if (displayMode === DISPLAY_MODES.DESKTOP) {
			columnCount = 3;
		} else if (displayMode === DISPLAY_MODES.TABLET) {
			columnCount = 2;
		} else {
			columnCount = 1;
		}

		const cardContent = this.renderSelectedCard(selectedCard, columnCount);

		this.setState({
			displayMode,
			columnCount,
			...cardContent,
		});
	};

	getTileColumn = (selectedCardIndex, columnCount) => {
		return selectedCardIndex % columnCount;
	};

	getTileRow = (selectedCardIndex, columnCount) => {
		return Math.floor(selectedCardIndex / columnCount);
	};

	getDisplayMode = width => {
		if (width >= BREAKPOINTS.MEDIUM_TABLET_LANDSCAPE)
			return DISPLAY_MODES.DESKTOP;
		else if (
			width < BREAKPOINTS.MEDIUM_TABLET_LANDSCAPE &&
			width >= BREAKPOINTS.TABLET_PORTRAIT
		)
			return DISPLAY_MODES.TABLET;

		return DISPLAY_MODES.MOBILE;
	};

	renderSelectedCard = (selectedCard, columnCount) => {
		let displayName;
		let selectedCardIndex = this.tiles
			.map(e => e.tileName)
			.indexOf(selectedCard);

		switch (selectedCard) {
			case CARD_NAMES.LOGIN_DETAILS:
				displayName = 'Inloggning';
				break;
			case CARD_NAMES.CONSENT:
				displayName = 'Samtycken';
				break;
			case CARD_NAMES.ADDRESS:
				displayName = 'Adress';
				break;
			case CARD_NAMES.DEREGISTER_ACCOUNT:
				displayName = 'Avregistrera';
				break;
			case CARD_NAMES.HISTORY:
				displayName = 'Historik';
				break;
			case CARD_NAMES.PAYMENTMETHODS:
				displayName = 'Betalmedel';
				break;
			default:
				break;
		}

		let row = this.getTileRow(selectedCardIndex, columnCount);
		let column = this.getTileColumn(selectedCardIndex, columnCount);
		let expandState = selectedCard === null ? false : true;

		return {
			selectedCard,
			expandState,
			displayName,
			row,
			column,
		};
	};

	getExpandedTileAreaContent = selectedCard => {
		switch (selectedCard) {
			case CARD_NAMES.LOGIN_DETAILS:
				return <LoginDetails />;
			case CARD_NAMES.CONSENT:
				return <Consent />;
			case CARD_NAMES.ADDRESS:
				return <DeliveryAddress />;
			case CARD_NAMES.DEREGISTER_ACCOUNT:
				return <DeregisterAccount />;
			case CARD_NAMES.HISTORY:
				return <History />;
			case CARD_NAMES.PAYMENTMETHODS:
				return <PaymentMethods />;
			default:
				return <TileError />;
		}
	};

	getCurrentTileColumn = (tileName, columnCount) => {
		let cardIndex = this.tiles
			.map(e => {
				return e.tileName;
			})
			.indexOf(tileName);
		return this.getTileColumn(cardIndex, columnCount);
	};

	getCurrentTileRow = tileName => {
		const { columnCount } = this.state;
		let cardIndex = this.tiles
			.map(e => {
				return e.tileName;
			})
			.indexOf(tileName);
		return this.getTileRow(cardIndex, columnCount);
	};

	getGridStyling = () => {
		const { columnCount, selectedCard } = this.state;

		const gridColumns = new Array(columnCount + 1).join('250px ').trim();
		let ieGridColumns = '';
		for (let index = 0; index < columnCount; index++) {
			ieGridColumns += '250px ';

			if (index !== columnCount - 1) ieGridColumns += '4em ';
		}

		const lastTileIndex = this.tiles.length - 1;

		let rowCount = this.getTileRow(lastTileIndex, columnCount);

		if (selectedCard !== '') rowCount++;

		const gridRows = new Array(rowCount).join('auto ').trim();

		return {
			gridColumns,
			gridRows,
			ieGridColumns: ieGridColumns.trim(),
		};
	};

	onOpenTile = selectedCard => {
		const { columnCount } = this.state;
		const cardState = this.renderSelectedCard(selectedCard, columnCount);

		this.setState({ ...cardState });
	};

	onTileAreaClosed = () => {
		this.setState({
			selectedCard: '',
			expandState: false,
		});
	};

	isTileSelected = tile => this.state.selectedCard === tile.tileName;

	render() {
		let {
			selectedCard,
			displayName,
			column,
			columnCount,
			expandState,
		} = this.state;
		const { texts } = this.props;
		let gridStyling = this.getGridStyling();

		return (
			<>
				<Banner
					bannerImg={texts.private.images.banners.profilepage}
					showOnMobile={true}
				>
					<h1>Min profil</h1>
					<p>{texts.private.profilepage.pageinfo.description}</p>
				</Banner>
				<main>
				<Main id="profilePageMainContainer">
					<>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
							}}
						>
							<AccountWrapper gridStyling={gridStyling}>
								{this.tiles.map((tile, key) => (
									<Fragment key={key}>
										<Tile
											tileName={tile.tileName}
											icon={tile.icon}
											isOpen={this.isTileSelected(tile)}
											onOpen={this.onOpenTile}
											tileHeader={tile.headerText}
											tileBody={tile.bodyText}
											column={this.getCurrentTileColumn(
												tile.tileName,
												columnCount
											)}
											row={this.getCurrentTileRow(tile.tileName)}
											columnCount={columnCount}
										/>
										{expandState && this.isTileSelected(tile) && (
											<ExpandableTileArea
												selectedRow={this.getCurrentTileRow(selectedCard)}
												className="expandableTileArea"
												cardName={selectedCard}
												displayName={displayName}
												chevronPosition={column}
												columnCount={columnCount}
												onCardExpanded={this.renderSelectedCard}
												onCardClosed={this.onTileAreaClosed}
												CARD_NAMES={CARD_NAMES}
											>
												{this.getExpandedTileAreaContent(selectedCard)}
											</ExpandableTileArea>
										)}
									</Fragment>
								))}
							</AccountWrapper>
						</div>
					</>
				</Main>
				</main>
			</>
		);
	}
}

const TileError = styled(Error)`
	margin-top: 0;
`;

const AccountWrapper = styled.div`
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: ${props => props.gridStyling.ieGridColumns};
	grid-template-columns: ${props => props.gridStyling.gridColumns};
	grid-column-gap: 4em;
	-ms-grid-rows: ${props => props.gridStyling.gridRows};

	align-content: center;
	justify-content: center;
	width: 100%;

	& > div {
		max-width: 250px;
		width: 100%;
	}

	${forPhoneOnly`
		grid-template-columns: 95%;

		& > div {
			max-width: 100%;
		}
	`}
`;

function mapStateToProps(store) {
	return {
		traveller: getTraveller(store),
		locationState: getLocationState(store),
		profileTile: profileTileToNavigateTo(store),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			...travellerActions,
			...utilsActions,
		},
		dispatch,
	);
}

export default withTextContext(
	connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
);
