import { isEnabled, OIDC_LOG, OIDC_MONITOR_SESSION } from './featureToggles';
import { PRIVATE, SCHOOL } from './Paths';

const baseOidcConfig = {
	response_type: 'code',
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: false,
	monitorSession: false,
};

const privateOidcConfig = {
	client_id: '#{webOidcClientId}',
	redirect_uri: `#{webBaseUrl}${PRIVATE.CALLBACK}`,
	scope: 'openid email phone',
	authority: '#{webOidcAuthority}',
	post_logout_redirect_uri: null, //'#{webOidcPostLogoutRedirectUri}',
	silent_redirect_uri: '#{webBaseUrl}/silent-renew',
};

const schoolOidcConfig = {
	client_id: '#{webSchoolOidcClientId}',
	redirect_uri: `#{webBaseUrl}${SCHOOL.CALLBACK}`,
	scope: 'openid',
	authority: '#{webSchoolOidcAuthority}',
	post_logout_redirect_uri: '#{webOidcPostLogoutRedirectUri}',
	silent_redirect_uri: '#{webBaseUrl}/silent-renew',
};

export const OidcConfig = {
	private: Object.assign({}, baseOidcConfig, privateOidcConfig),
	school: Object.assign({}, baseOidcConfig, schoolOidcConfig),
};

export const idpConfig = {
	nexthop: '#{webBaseUrl}/',
	schoolIdClaim: '#{webScoolIdClaim}',
	_idpSignoutToggle: '#{idpSignoutToggle}',
	get idpSignoutToggle() {
		return this._idpSignoutToggle === 'true';
	},
};

export const logConfig = {
	middleware: false,
	oidc: false,
};

export const apiConfig = {
	cmsBaseUrl: '#{CmsBaseUrl}',
	cmsApiUrl: '#{CmsBaseUrl}/backoffice/api',
	apiBaseUrl: '#{apiBaseUrl}',
};

export const themeConfig = {
	theme: '#{webSassTheme}',
	get showCulturalTrips() {
		return this.theme === 'kalmar';
	},
};

export const cmsConfig = {
	_loadCmsTexts: '#{loadCmsTexts}',
	get loadCmsTexts() {
		return this._loadCmsTexts === 'true';
	},
};

export const urlConfig = {
	confirmationUrl: `#{webBaseUrl}${PRIVATE.CONFIRM_PURCHASE}`.concat(
		'?tid=${TID}' // eslint-disable-line no-template-curly-in-string
	),
	finalizeUrl: `#{webBaseUrl}${PRIVATE.FINALIZE_PAYMENT_CARD_REGISTRATION}`, // eslint-disable-line no-template-curly-in-string
};

applyFeatureToggles();
setLocalOverrides();

function applyFeatureToggles() {
	if (isEnabled(OIDC_LOG)) {
		logConfig.oidc = true;
	}

	if (isEnabled(OIDC_MONITOR_SESSION)) {
		logConfig.monitorSession = true;
	}
}

function setLocalOverrides() {
	if (process.env.NODE_ENV !== 'production') {
		let localhostUrl = 'http://localhost:61847';
		apiConfig.cmsBaseUrl = 'http://localhost:54571';
		apiConfig.cmsApiUrl = 'http://localhost:54571/backoffice/api';
		apiConfig.apiBaseUrl = `${localhostUrl}/api`;

		OidcConfig.private = {
			...OidcConfig.private,
			client_id: 'sopra',
			authority: 'https://idp.test.bobcat.hlt.se',
			redirect_uri: `${localhostUrl}${PRIVATE.CALLBACK}`,
			post_logout_redirect_uri: null, //`${localhostUrl}/`
			silent_redirect_uri: `${localhostUrl}/silent-renew`,
		};

		OidcConfig.school = {
			...OidcConfig.school,
			client_id: 'Fl5HvFEyNJ',
			authority: 'https://oidc.grandid.com',
			redirect_uri: `${localhostUrl}${SCHOOL.CALLBACK}`,
			post_logout_redirect_uri: `${localhostUrl}/`,
			silent_redirect_uri: `${localhostUrl}/silent-renew`,
		};

		idpConfig.nexthop = `${localhostUrl}/`;
		idpConfig.schoolIdClaim = 'sub';
		idpConfig._idpSignoutToggle = 'true';

		// logConfig.middleware = true;
		// logConfig.oidc = true;

		urlConfig.confirmationUrl = `${localhostUrl}${
			PRIVATE.CONFIRM_PURCHASE
		}`.concat('?tid=${TID}'); // eslint-disable-line no-template-curly-in-string

		urlConfig.finalizeUrl = `${localhostUrl}${
			PRIVATE.FINALIZE_PAYMENT_CARD_REGISTRATION
		}`; // eslint-disable-line no-template-curly-in-string

		// themeConfig.theme = "base";
		themeConfig.theme = 'halland';

		cmsConfig._loadCmsTexts = 'true';
	}
}
