import React from 'react';
import styled, { withTheme } from 'styled-components';
import TicketIcon from '../../images/ticket.svg';
// import ArticleIcon from '../../images/article.svg';
import PencilIcon from '../../images/pencil.svg';
import withTextContext from '../../utils/withTextContext';

const Features = props => {
	const { texts } = props;
	return (
		<Container>
			<div className="container">
				<FeaturesContainerTitle>På Mitt konto kan du:</FeaturesContainerTitle>

				<FeatureContainer>
					<Feature>
						<FeatureIcon icon={TicketIcon} iconHeight="70px" />
						<FeatureTitle>Köpa biljett</FeatureTitle>
						<p>{texts.private.featuresbanner.features.buyticket}</p>
					</Feature>

					{/* <Feature>
							<FeatureIcon icon={ArticleIcon} iconHeight="60px" />
							<FeatureTitle>Skapa ett ärende</FeatureTitle>
							<p>{texts.private.featuresbanner.features.createissue}</p>
						</Feature> */}

					<Feature>
						<FeatureIcon icon={PencilIcon} iconHeight="55px" />
						<FeatureTitle>Registrera resekort</FeatureTitle>
						<p>{texts.private.featuresbanner.features.registerbearer}</p>
					</Feature>
				</FeatureContainer>
			</div>
		</Container>
	);
};

const FeatureIcon = props => {
	const IconContainer = styled.div`
		display: flex;
		height: 100px;
	`;

	const Image = styled.img`
		margin: auto;
		height: ${props.iconHeight};
	`;

	return (
		<IconContainer>
			<Image src={props.icon} alt="" />
		</IconContainer>
	);
};

const Container = styled.div`
	background-color: ${props => props.theme.white};
	flex-shrink: 0;
	box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.22);
	margin-bottom: -1em;
	text-align: center;
	padding: 5em 0;
`;

const FeaturesContainerTitle = styled.h4`
	font-size: ${props => props.theme.h1.font_size};
	margin: 0 auto 1em;
	font-weight: 300;
`;

const FeatureContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const Feature = styled.div`
	width: 28%;
	min-width: 13em;
	margin: 1em;
`;

const FeatureTitle = styled.h5`
	font-size: 18px;
	margin: 1em auto;
`;

export default withTextContext(withTheme(Features), false);
