import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { BearerInfo } from '../common/blocks/BearerInfo';
import { getTagTitles } from '../common/blocks/ProductInfo';
import { travellerTags, addonTags } from '../../reducer/products';
import { TicketStatus } from './blocks/TicketStatus';
import { Card } from '../common/blocks/Card';
import { Error } from '../common/elements/Error';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { forLargePhoneUp } from '../../utils/mediaqueries';
import { ReactComponent as ChevronUp } from '../../images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../images/chevron-down.svg';
import { ReactComponent as Lock } from '../../images/lock.svg';
import withTextContext from '../../utils/withTextContext';
import {
	actionCreators as studentActions,
	getReferredAccount,
	isLoadingTicketAction,
} from '../../reducer/students';
import { TicketFunctions, FUNCTIONS } from './blocks/TicketFunctions';
import { getStudentById } from '../../reducer';
import {
	actionCreators as schoolActions,
	getSchool,
	getSchoolByVendor,
} from '../../reducer/school';

class Ticket extends Component {
	state = {
		showFunctions: false,
		activeFunction: null,
		showMoveFunction: false,
		hasSelectedBearer: false,
		selectedBearer: null,
		showConfirmation: false,
		showError: false,
		formattedBearers: null,
	};

	componentDidMount() {
		const { product, bearers, getSchoolByVendor } = this.props;
		let selectedBearer = null;

		if (bearers) {
			if (product.bearer) {
				selectedBearer = bearers.filter(
					bearer => bearer.id !== product.bearer.id
				)[0];
			} else {
				selectedBearer = bearers[0];
			}
		}
		this.setState({ selectedBearer });

		if (!getSchoolByVendor(product.owner.id))
			this.props.getSchoolByVendorId(product.owner.id);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!prevState.selectedBearer && nextProps.bearers) {
			return { selectedBearer: nextProps.bearers[0] };
		}
		return null;
	}

	onToggleFunctions = () =>
		this.setState(state => ({
			showFunctions: !state.showFunctions,
			showMoveFunction: state.showFunctions,
			activeFunction: null,
			showError: false,
		}));

	onToggleMoveFunction = () =>
		this.setState(state => ({ showMoveFunction: !state.showMoveFunction }));

	moveTicket = async () => {
		const { product, travellerId } = this.props;
		const { selectedBearer } = this.state;
		try {
			await this.props.updateStudentProductBearer(
				product.id,
				selectedBearer.id,
				travellerId
			);
			this.props.onToggleConfirmationCard({
				title: 'Biljett flyttad!',
				closePopupOnConfirmationClose: false,
			});
		} catch {
			this.setState({ showError: true });
		}
	};

	cancelTicket = async () => {
		const { product, travellerId } = this.props;
		const isReferredTicket = product.travellerId !== travellerId;
		try {
			await this.props.cancelStudentTicket(
				travellerId,
				product.id,
				isReferredTicket
			);

			this.props.onToggleConfirmationCard({
				title: 'Biljett avslutad',
				closePopupOnConfirmationClose: false,
			});
		} catch {
			this.setState({ showError: true });
		}
	};

	transferTicketToReferred = async () => {
		const { product, travellerId, getReferredAccount } = this.props;
		const referredAccount = getReferredAccount(travellerId);
		try {
			await this.props.transferTicketToReferredAccount(product, travellerId);
			await this.props.getAllReferredAccountTickets([
				referredAccount.referredAccountTravellerId,
			]);

			this.props.onToggleConfirmationCard({
				title: 'Biljett flyttad',
				closePopupOnConfirmationClose: false,
			});
		} catch {
			this.setState({ showError: true });
		}
	};

	returnTicketFromReferred = async () => {
		const { product, travellerId } = this.props;
		try {
			await this.props.returnTicketFromReferredAccount(product, travellerId);
			await this.props.getTicketsForStudentIds([travellerId]);

			this.props.onToggleConfirmationCard({
				title: 'Biljett returnerad',
				closePopupOnConfirmationClose: false,
			});
		} catch {
			this.setState({ showError: true });
		}
	};

	onSelectFunction = selectedFunction =>
		this.setState({ activeFunction: selectedFunction, showError: false });

	onSelect = item => {
		const { selectedBearer } = this.state;
		if (selectedBearer && selectedBearer.id !== item.id) {
			this.setState({
				selectedBearer: item,
			});
		}
	};

	render() {
		const { activeFunction, showError, showFunctions } = this.state;
		const {
			product,
			travellerId,
			bearers,
			isLoadingTicketAction,
			isReferredTicket,
			getReferredAccount,
			isCancellable,
			school,
			getSchoolByVendor,
		} = this.props;
		const types = [
			...getTagTitles(product.tags.filter(travellerTags)),
			...getTagTitles(product.tags.filter(addonTags)),
		];

		let bearersToShow = bearers;
		if (product.bearer) {
			bearersToShow = bearers.filter(bearer => bearer.id !== product.bearer.id);
		}

		const hasBearersToShow = bearersToShow.length > 0;

		const referredAccount = getReferredAccount(travellerId);

		const productOwnerSchool = getSchoolByVendor(product.owner.id);
		const currentSchoolOwnsTicket =
			productOwnerSchool && productOwnerSchool.schoolId === school.schoolId;

		return (
			<TicketBox>
				<TwoColumnRow style={{ margin: '0 0 10px' }}>
					<TicketHeader>
						{product.productSetTitle},{' '}
						{types.map((type, idx) => (
							<span key={idx}>
								{type}
								{idx !== types.length - 1 && ', '}
							</span>
						))}
					</TicketHeader>
				</TwoColumnRow>
				<TicketStatus product={product} />
				{isReferredTicket && <p>Utlånad till reseapp</p>}
				<BearerInfo bearer={product.bearer} />
				{currentSchoolOwnsTicket && (
					<Functions>
						<ToggleFunctions onClick={this.onToggleFunctions}>
							{!showFunctions && <div>Funktioner</div>}
							{showFunctions ? (
								<ChevronUpIcon
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
							) : (
								<ChevronDownIcon
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
							)}
						</ToggleFunctions>
						{showFunctions && (
							<div>
								{!activeFunction && (
									<>
										{isReferredTicket ? (
											<FunctionButton
												onClick={() =>
													this.onSelectFunction(
														FUNCTIONS.RETURN_REFERRED_TICKET
													)
												}
											>
												Returnera biljett från reseapp
											</FunctionButton>
										) : (
											<>
												{hasBearersToShow && (
													<FunctionButton
														onClick={() =>
															this.onSelectFunction(FUNCTIONS.MOVE_TICKET)
														}
													>
														Flytta biljett
													</FunctionButton>
												)}
												{referredAccount && (
													<FunctionButton
														onClick={() =>
															this.onSelectFunction(
																FUNCTIONS.TRANSFER_REFERRED_TICKET
															)
														}
													>
														Flytta biljett till reseapp
													</FunctionButton>
												)}
											</>
										)}
										{isCancellable && (
											<FunctionButton
												onClick={() =>
													this.onSelectFunction(FUNCTIONS.CANCEL_TICKET)
												}
											>
												Avsluta biljett
											</FunctionButton>
										)}
									</>
								)}
								{activeFunction && (
									<TicketFunctions
										activeFunction={activeFunction}
										onBackClick={() => this.onSelectFunction(null)}
										onSelectTargetBearer={this.onSelect}
										bearers={bearersToShow}
										onMoveTicket={this.moveTicket}
										onCancelTicket={this.cancelTicket}
										onTransferTicketToReferred={this.transferTicketToReferred}
										onReturnTicketFromReferred={this.returnTicketFromReferred}
										isLoading={isLoadingTicketAction}
									/>
								)}
								{showError && (
									<Error>
										Ett fel uppstod när biljetten skulle hanteras. Var god
										försök igen eller kontakta kundtjänst.
									</Error>
								)}
							</div>
						)}
					</Functions>
				)}
				{!currentSchoolOwnsTicket && productOwnerSchool && (
					<TicketSubheader>
						<LockIcon /> {productOwnerSchool.name}
					</TicketSubheader>
				)}
			</TicketBox>
		);
	}
}

const TicketHeader = styled.h5`
	font-weight: 600;
	line-height: 27px;
	margin: 0;

	span {
		font-size: 13px;
		line-height: 18px;
		white-space: nowrap;
		display: inline-block;
	}
`;

const TicketSubheader = styled.h6`
	font-weight: 600;
	line-height: 27px;
	margin: 0;
	color: #757575;
`;

const TicketBox = styled(Card)`
	max-width: 430px;
	text-align: left;
	padding: 1.5em;
	margin-top: 1.5em;
	margin-bottom: 1em;
	box-shadow: 0 6px 10px 6px rgba(0, 0, 0, 0.1);

	:first-child {
		margin-top: 0;
	}

	h4 {
		color: rgba(46, 46, 46, 0.64);
		font-size: 20px;
		line-height: 23px;
	}

	${forLargePhoneUp`
		padding: 20px 36px 20px;
	`}
`;

const Functions = styled.div`
	div {
		display: flex;
		flex-direction: column;
	}
	h5 {
		font-size: 16px;
	}
`;

const ToggleFunctions = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 600;
	margin-top: 10px;
	> span {
		margin-bottom: 10px;
	}
	cursor: pointer;
`;

const ChevronUpIcon = styled(ChevronUp)`
	fill: none;
	margin-bottom: 1em;
`;

const ChevronDownIcon = styled(ChevronDown)`
	fill: none;
	margin-bottom: 1em;
`;

const LockIcon = styled(Lock)`
	height: 1em;
`;

const FunctionButton = styled(SecondaryButton)`
	display: block;
	font-weight: 600;
	background-color: white;
	color: #333;
	margin: 0.5em 0;
`;

export const TwoColumnRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export default withTextContext(
	connect(
		store => ({
			isLoadingTicketAction: isLoadingTicketAction(store),
			getReferredAccount: getReferredAccount(store),
			getStudent: getStudentById(store),
			school: getSchool(store),
			getSchoolByVendor: getSchoolByVendor(store),
		}),
		{ ...studentActions, ...schoolActions }
	)(Ticket)
);
