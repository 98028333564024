import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
	forTabletLandscapeOnly,
	forDesktopUp,
	forPhoneOnly,
	forTabletPortraitUp,
} from '../../../utils/mediaqueries';
import { getImageUrl, IMAGE_SIZES } from '../../../reducer/cms';
import { isSchool } from '../../../utils/helperFunctions';

import ValidateEmail from '../../customer/ValidateEmail';

export class Banner extends Component {
	static propTypes = {
		showOnMobile: PropTypes.bool,
	};

	render() {
		const { bannerImg, children } = this.props;

		const smallImg = getImageUrl(bannerImg, IMAGE_SIZES.BANNER_SMALL);
		const mediumImg = getImageUrl(bannerImg, IMAGE_SIZES.BANNER_MEDIUM);
		const largeImg = getImageUrl(bannerImg, IMAGE_SIZES.BANNER_LARGE);

		return (
			<>
				{!isSchool() && <ValidateEmail />}
				<MobileContainer>{children}</MobileContainer>
				<DesktopContainer>
					<div style={{ width: '50%' }}>
						<BannerImage
							smallImg={smallImg}
							mediumImg={mediumImg}
							largeImg={largeImg}
						/>
					</div>
					<BannerText>{children}</BannerText>
				</DesktopContainer>
			</>
		);
	}
}

const MobileContainer = styled.div`
	padding: 2em 2em 0 2em;
	text-align: center;
	
	> h1 {
		display: none;
	}
	
	${forTabletPortraitUp`
		display: none;
	`}
`;

const BannerImage = styled.div`
	background: url(${props => props.smallImg}) center;
	width: 100%;
	height: 210px;
	border-bottom-right-radius: 168px;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;

	${forTabletLandscapeOnly`
		background: url(${props => props.mediumImg}) center;
	`}

	${forDesktopUp`
		background: url(${props => props.largeImg}) center;
	`}
`;

const DesktopContainer = styled.div`
	background-color: white;
	width: inherit;
	display: flex;
	display: -webkit-inline-box;
	position: relative;
	width: 100%;

	h2 {
		margin-top: 0;
	}

	${forPhoneOnly`
		display: none;
	`}
`;

const BannerText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 50%;
	padding-left: 4em;
`;
