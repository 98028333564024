const DEFAULT_TIMEOUT = 20 * 1000;
const DEFAULT_INTERVAL = 1 * 1000;

export const pollUntil = (
	fn,
	timeout = DEFAULT_TIMEOUT,
	interval = DEFAULT_INTERVAL
) => {
	var endTime = Number(new Date()) + timeout;

	var checkCondition = async function(resolve, reject) {
		// If the condition is met, we're done!
		var result = await fn();
		if (result) {
			resolve(result);
		}
		// If the condition isn't met but the timeout hasn't elapsed, go again
		else if (Number(new Date()) < endTime) {
			setTimeout(checkCondition, interval, resolve, reject);
		}
		// Didn't match and too much time, reject!
		else {
			reject(new Error('Timed out for ' + fn + ': ' + arguments));
		}
	};

	return new Promise(checkCondition);
};
