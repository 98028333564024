import React, { Component } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import { Label } from './../common/elements/Label';
import SmsCode from '../../images/smskod.svg';
import { Loading } from '../common/blocks/Loading';

export class VerifyMobilePage extends Component {
	state = {
		code: '',
	};

	handleChange = ({ target }) => {
		const maxLength = 6;
		const value = target.value;

		if (value.length <= maxLength) {
			this.setState({ [target.id]: target.value });
		}
	};

	enterPressed = e => {
    if(e.key === 'Enter') { 
			this.register();
    } 
	}

	register = () => {
		let code = this.state.code;
		if (code.length > 0)
			this.props.verifyMobileCode(code, this.props.nextRenderState);
	};

	getFormattedMobile = () => {
		let { mobileToVerify } = this.props;
		return '+' + mobileToVerify;
	};

	render() {
		const { isLoading, hasValidationError } = this.props;
		return (
			<div>
				<h3>Skapa konto</h3>

				<NumberToVerify>En kod är nu skickad till</NumberToVerify>
				<NumberToVerify>{this.getFormattedMobile()}</NumberToVerify>

				<SmsImage src={SmsCode} alt="" />

				<div className="form-group">
					<Label htmlFor="code">Ange din verifieringskod</Label>
					<CodeInput
						type="number"
						value={this.state.code}
						onChange={this.handleChange}
						className="form-control"
						id="code"
						onKeyPress={this.enterPressed}
					/>
					<ValidationError
						style={!hasValidationError ? { visibility: 'hidden' } : null}
					>
						Felaktig kod!
					</ValidationError>
				</div>

				<SecondaryButton onClick={this.register}>Skapa konto</SecondaryButton>
				{isLoading && <Loading/>}
			</div>
		);
	}
}

const NumberToVerify = styled.p`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 14px;
	line-height: 19px;
	text-align: center;
`;

const CodeInput = styled.input`
	width: 90px;
	margin: auto;
`;

const SmsImage = styled.img`
	margin: 1em auto;
	height: 60px;
`;
const ValidationError = styled.p`
	color: #ff0000;
	font-size: 14px;
`;
