import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { isLoadingUser } from '../reducer';
import { Loading } from '../components/common/blocks/Loading';

const withAuthentication = redirectPath => WrappedComponent => {
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.userIsAuthenticated = this.userIsAuthenticated.bind(this);
		}

		userIsAuthenticated() {
			const { user } = this.props;

			if (!user || user.expired) {
				return false;
			}
			return true;
		}

		render() {
			const { location, user, isLoadingUser, ...props } = this.props;

			if (isLoadingUser) {
				return <Loading />;
			}

			return this.userIsAuthenticated() ? (
				<WrappedComponent {...props} />
			) : (
				<Redirect
					to={{
						pathname: redirectPath,
						state: { accessedPath: location.pathname },
					}}
				/>
			);
		}
	};
};

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		isLoadingUser: isLoadingUser(state),
	};
}

export default ({ redirectPath }) =>
	compose(
		connect(mapStateToProps),
		withAuthentication(redirectPath),
		withRouter
	);
