import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

export class Pagination extends Component {
	state = {
		currentPage: 1,
		itemCount: this.props.itemCount,
	};

	componentDidUpdate() {
		const { itemCount: currentItemCount } = this.state;
		const { itemCount: newItemCount } = this.props;

		if (currentItemCount !== newItemCount)
			this.setState(
				{ itemCount: newItemCount, currentPage: 1 },
				this.props.onPageChange(1)
			);
	}

	getMaxPageNumber = () =>
		Math.ceil(this.props.itemCount / this.props.itemsPerPage);

	decreasePageNumber = () =>
		this.state.currentPage > 1 &&
		this.setState(
			state => ({ currentPage: state.currentPage - 1 }),
			this.props.onPageChange(this.state.currentPage - 1)
		);

	increasePageNumber = () =>
		this.state.currentPage < this.getMaxPageNumber() &&
		this.setState(
			state => ({ currentPage: state.currentPage + 1 }),
			this.props.onPageChange(this.state.currentPage + 1)
		);

	handlePageClick = newPage =>
		this.setState({ currentPage: newPage }, this.props.onPageChange(newPage));

	getPaginationPageSpan = (currentPage, maxPageNumber) => {
		let returnStartIndex = currentPage - 4;
		let returnEndIndex = currentPage + 4;

		if (currentPage < 5) returnEndIndex = currentPage + (10 - currentPage) - 1;
		if (currentPage > maxPageNumber - 5)
			returnStartIndex = currentPage - (9 - (maxPageNumber - currentPage)) + 1;

		if (returnStartIndex < 1) returnStartIndex = 1;
		if (returnEndIndex > maxPageNumber) returnEndIndex = maxPageNumber;

		return { startIndex: returnStartIndex, endIndex: returnEndIndex };
	};

	getPaginationButtons = () => {
		const { currentPage } = this.state;
		let maxPageNumber = this.getMaxPageNumber();

		let pageButtons = [];
		let pageSpan = this.getPaginationPageSpan(currentPage, maxPageNumber);

		for (let index = pageSpan.startIndex; index <= pageSpan.endIndex; index++) {
			pageButtons.push(
				<PageButton
					key={'pagination-' + index}
					className={classNames({ active: index === currentPage })}
					onClick={() => this.handlePageClick(index)}
				>
					{index}
				</PageButton>
			);
		}

		return pageButtons;
	};

	render() {
		const { itemCount, itemsPerPage } = this.props;
		const { currentPage } = this.state;
		let currentIndex = currentPage - 1;

		const pageButtons = this.getPaginationButtons();
		const maxPageNumber = this.getMaxPageNumber();

		let startPageItem = currentIndex * itemsPerPage + 1;
		let endPageItem = startPageItem + itemsPerPage - 1;
		if (endPageItem > itemCount) endPageItem = itemCount;
		if (itemCount < 1) startPageItem = 0;

		return (
			<PaginationWrapper>
				<PageTextItemCount>
					{startPageItem}-{endPageItem} av {itemCount}
				</PageTextItemCount>
				<PaginationContainer>
					<StepPageButton
						onClick={this.decreasePageNumber}
						className={classNames({ disabled: currentPage === 1 })}
					>
						&lsaquo;
					</StepPageButton>

					{pageButtons}

					<StepPageButton
						onClick={this.increasePageNumber}
						className={classNames({ disabled: currentPage === maxPageNumber })}
					>
						&rsaquo;
					</StepPageButton>
				</PaginationContainer>
			</PaginationWrapper>
		);
	}
}

const PaginationWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const PaginationContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const PageTextItemCount = styled.p`
	text-align: center;
	color: ${props => props.theme.light_grey};
`;

const PageButton = styled.li`
	background-color: transparent;
	color: ${props => props.theme.lighter_grey};
	list-style: none;
	display: inline-block;
	margin: 3.5px;
	padding: 10px 6px;
	font-size: 1em;
	box-sizing: border-box;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	text-align: center;

	:hover {
		cursor: pointer;
	}

	&.active,
	:hover {
		background-color: ${props => props.theme.primary_color};
		color: white;
	}
`;

const StepPageButton = styled(PageButton)`
	font-size: 2em;
	font-weight: 600;
	padding: 3px;
	margin-top: -2px;
	color: ${props => props.theme.light_grey};

	:hover {
		background-color: transparent;
		color: ${props => props.theme.dark_grey};
		cursor: pointer;
	}

	&.disabled {
		color: ${props => props.theme.lighter_grey};
		cursor: default;
	}
`;
