import { getToken } from '..';
import {
	fetchCultureTrips,
	fetchCultureTripDestinations,
	postCultureTripApplication,
	fetchContactPersonHasApp,
} from '../../api';
import { getSchool } from '../school';

const initialState = {
	cultureTrips: undefined,
	createdCultureTrip: undefined,
	cultureTripDestinations: undefined,
	contactHasApp: undefined,
	isLoadingCultureTrips: false,
	isLoadingCultureTripDestinations: false,
	isCreatingCultureTripApplication: false,
	isLoadingAppCheck: false,
	errorMessage: '',
};

export const CULTURE_TRIP_ERRORS = {
	GET_CULTURE_TRIPS: 'GET_CULTURE_TRIPS',
	GET_CULTURE_TRIP_DESTINATIONS: 'GET_CULTURE_TRIP_DESTINATIONS',
	SEND_CULTURE_TRIP_APPLICATION: 'SEND_CULTURE_TRIP_APPLICATION',
	CHECK_CONTACT_APP: 'CHECK_CONTACT_APP',
};

export const STORE_NAME = 'culturetrips';

export const actionCreators = {
	getCultureTrips: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_CULTURE_TRIPS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const cultureTrips = await fetchCultureTrips(token, school.schoolId);

			dispatch({ type: 'GET_CULTURE_TRIPS_SUCCESS', cultureTrips });
		} catch (error) {
			dispatch({
				type: 'GET_CULTURE_TRIPS_FAILURE',
				message: CULTURE_TRIP_ERRORS.GET_CULTURE_TRIPS,
			});
		}
	},
	getCultureTripDestinations: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_CULTURE_TRIP_DESTINATIONS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const cultureTripDestinations = await fetchCultureTripDestinations(
				token,
				school.schoolId
			);

			dispatch({
				type: 'GET_CULTURE_TRIP_DESTINATIONS_SUCCESS',
				cultureTripDestinations,
			});
		} catch (error) {
			dispatch({
				type: 'GET_CULTURE_TRIP_DESTINATIONS_FAILURE',
				message: CULTURE_TRIP_ERRORS.GET_CULTURE_TRIP_DESTINATIONS,
			});
		}
	},
	sendCultureTripApplication: cultureTrip => async (dispatch, getState) => {
		dispatch({ type: 'SEND_CULTURE_TRIP_APPLICATION_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		const cultureTripApplication = {
			...cultureTrip,
			departure: {
				from: cultureTrip.departure.from,
				to: cultureTrip.departure.to,
				date: cultureTrip.departure.date,
				productSetId: cultureTrip.departure.productSetId,
			},
			return: {
				from: cultureTrip.return.from,
				to: cultureTrip.return.to,
				date: cultureTrip.return.date,
				productSetId: cultureTrip.return.productSetId,
			},
			contact: {
				...cultureTrip.contact,
				modeOfDelivery: cultureTrip.contact.modeOfDelivery.id
			},
			schoolId: school.schoolId,
		};
		delete cultureTripApplication.group;
		delete cultureTripApplication.destination;

		try {
			const cultureTrip = await postCultureTripApplication(
				token,
				school.schoolId,
				cultureTripApplication
			);

			dispatch({
				type: 'SEND_CULTURE_TRIP_APPLICATION_SUCCESS',
				cultureTrip,
			});
		} catch (error) {
			dispatch({
				type: 'SEND_CULTURE_TRIP_APPLICATION_FAILURE',
				message: CULTURE_TRIP_ERRORS.SEND_CULTURE_TRIP_APPLICATION,
			});
			throw error;
		}
	},
	checkContactPersonHasApp: mobilePhone => async (dispatch, getState) => {
		dispatch({ type: 'CHECK_CONTACT_APP_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			var contactHasApp = await fetchContactPersonHasApp(
				token,
				school.schoolId,
				mobilePhone
			);
			dispatch({
				type: 'CHECK_CONTACT_APP_SUCCESS',
				contactHasApp,
			});
		} catch (error) {
			dispatch({
				type: 'CHECK_CONTACT_APP_FAILURE',
				message: CULTURE_TRIP_ERRORS.CHECK_CONTACT_APP,
			});
		}
	},
	setTravelerInfo: travelerInfo => async dispatch =>
		dispatch({ type: 'SET_TRAVELER_INFO', travelerInfo }),
	setDepartureJourney: departureJourney => async dispatch =>
		dispatch({ type: 'SET_DEPARTURE_JOURNEY', departureJourney }),
	setReturnJourney: returnJourney => async dispatch =>
		dispatch({ type: 'SET_RETURN_JOURNEY', returnJourney }),
	setContactInfo: contactInfo => async dispatch =>
		dispatch({ type: 'SET_CONTACT_INFO', contactInfo }),
	clearCreatedApplication: () => async dispatch =>
		dispatch({ type: 'CLEAR_CREATED_APPLICATION' }),
	clearAppCheck: () => async dispatch => dispatch({ type: 'CLEAR_CHECK_APP' }),
};

export default function cultureTripsReducer(state = initialState, action) {
	switch (action.type) {
		case 'GET_CULTURE_TRIPS_REQUEST':
			return {
				...state,
				isLoadingCultureTrips: true,
				errorMessage: '',
			};
		case 'GET_CULTURE_TRIP_DESTINATIONS_REQUEST':
			return {
				...state,
				isLoadingCultureTripDestinations: true,
				errorMessage: '',
			};
		case 'SEND_CULTURE_TRIP_APPLICATION_REQUEST':
			return {
				...state,
				isCreatingCultureTripApplication: true,
				errorMessage: '',
			};
		case 'CHECK_CONTACT_APP_REQUEST':
			return {
				...state,
				isLoadingAppCheck: true,
				errorMessage: '',
			};
		case 'GET_CULTURE_TRIPS_SUCCESS':
			return {
				...state,
				cultureTrips: action.cultureTrips,
				isLoadingCultureTrips: false,
			};
		case 'GET_CULTURE_TRIP_DESTINATIONS_SUCCESS':
			return {
				...state,
				cultureTripDestinations: action.cultureTripDestinations,
				isLoadingCultureTripDestinations: false,
			};
		case 'SEND_CULTURE_TRIP_APPLICATION_SUCCESS':
			return {
				...state,
				cultureTrips: [...state.cultureTrips, action.cultureTrip],
				createdCultureTrip: action.cultureTrip,
				isCreatingCultureTripApplication: false,
				contactHasApp: undefined,
			};
		case 'CHECK_CONTACT_APP_SUCCESS':
			return {
				...state,
				contactHasApp: action.contactHasApp,
				isLoadingAppCheck: false,
			};
		case 'GET_CULTURE_TRIPS_FAILURE':
			return {
				...state,
				isLoadingCultureTrips: false,
				errorMessage: action.message,
			};
		case 'GET_CULTURE_TRIP_DESTINATIONS_FAILURE':
			return {
				...state,
				isLoadingCultureTripDestinations: false,
				errorMessage: action.message,
			};
		case 'SEND_CULTURE_TRIP_APPLICATION_FAILURE':
			return {
				...state,
				isCreatingCultureTripApplication: false,
				errorMessage: action.message,
			};
		case 'CHECK_CONTACT_APP_FAILURE':
			return {
				...state,
				isLoadingAppCheck: false,
				errorMessage: action.message,
			};
		case 'SET_TRAVELER_INFO':
			return {
				...state,
				travelerInfo: action.travelerInfo,
			};
		case 'SET_DEPARTURE_JOURNEY':
			return {
				...state,
				departureJourney: action.departureJourney,
			};
		case 'SET_RETURN_JOURNEY':
			return {
				...state,
				returnJourney: action.returnJourney,
			};
		case 'SET_CONTACT_INFO':
			return {
				...state,
				contactInfo: action.contactInfo,
			};
		case 'CLEAR_CREATED_APPLICATION':
			return {
				...state,
				createdCultureTrip: undefined,
			};
		case 'CLEAR_CHECK_APP':
			return { ...state, contactHasApp: undefined };
		case 'CLEAR_SCHOOL_STATE':
			return { ...state, ...initialState };
		default:
			return state;
	}
}

// Selectors

export const getCultureTrips = store => store[STORE_NAME].cultureTrips || [];

export const getCreatedCultureTrip = store =>
	store[STORE_NAME].createdCultureTrip;

export const getCultureTripDestinations = store =>
	store[STORE_NAME].cultureTripDestinations || [];

export const contactHasApp = store => store[STORE_NAME].contactHasApp;

export const isLoadingCultureTrips = store =>
	store[STORE_NAME].isLoadingCultureTrips;

export const isLoadingCultureTripDestinations = store =>
	store[STORE_NAME].isLoadingCultureTripDestinations;

export const isCreatingCultureTripApplication = store =>
	store[STORE_NAME].isCreatingCultureTripApplication;

export const isLoadingAppCheck = store => store[STORE_NAME].isLoadingAppCheck;

export const travelerInfo = store => store[STORE_NAME].travelerInfo;

export const departureJourney = store => store[STORE_NAME].departureJourney;

export const returnJourney = store => store[STORE_NAME].returnJourney;

export const contactInfo = store => store[STORE_NAME].contactInfo;
