import { readCookie } from "./utils/cookieManager";

export const OIDC_LOG = 'OIDC_LOG';
export const OIDC_MONITOR_SESSION = 'OIDC_MONITOR_SESSION';
export const CMS_LOAD_TEXTS = 'CMS_LOAD_TEXTS';

const toggleConfiguration = {
	[OIDC_LOG]: false,
	[OIDC_MONITOR_SESSION]: false,
}

export const isEnabled = toggle => {
	const cookie = readCookie(toggle);
	let isEnabled = toggleConfiguration[toggle];

	if (cookie) {
		isEnabled = cookie === 'true';
	}
	return isEnabled;
};
