import React from 'react';
import { userManager } from '../../utils/userManager';
import { LoadPage } from './LoadPage';
import { OidcConfig, idpConfig } from '../../config';
import { isSchool } from '../../utils/helperFunctions';

export const SignoutPage = () => {
	setTimeout(() => {
		if (idpConfig.idpSignoutToggle) {
			userManager.removeUser();
			userManager.signoutRedirect();
		} else {
			userManager.removeUser();
		}

		let logOutRedirectUri = isSchool()
			? OidcConfig.school.post_logout_redirect_uri
			: OidcConfig.private.post_logout_redirect_uri;

		window.location.replace(logOutRedirectUri);
	}, 1000);

	return <LoadPage text="Loggar ut..." />;
};
