import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { CardWide } from '../common/blocks/CardWide';
import { Main } from '../common/blocks/Main';
import SelectedProduct from './SelectedProduct';
import { Error } from './../common/elements/Error';
import { actionCreators as productActions } from '../../reducer/products';
import { actionCreators as cartActions } from '../../reducer/cart';
import { actionCreators as bearerActions } from '../../reducer/bearer';
import {
	getSelectedProductSet,
	hasCartError,
	getBearers,
	getCartItemCount,
} from '../../reducer';
import { Incrementer } from '../common/elements/Incrementer';
import { Info } from './../common/elements/Info';
import { BearerOptions } from './BearerOptions';
import { PRIVATE } from '../../Paths';
import withTextContext from '../../utils/withTextContext';

class PurchaseOptionsPage extends Component {
	componentDidMount() {
		document.title = "Köpalternativ | Mitt konto"
		this.resetState();
		this.props.getBearers();
		
		this.unlisten = this.props.history.listen((location, action) => {
			if (location.pathname === PRIVATE.BUY_TICKET_OPTIONS) {
				this.resetState();
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	resetState = () =>
		this.props.resetCartItemCount();

	handleChange = value => this.props.setCartItemCount(value);

	setActivation = activation => this.props.setManualActivation(activation);
	setBearer = bearer => this.props.setBearer(bearer);

	render() {
		const {
			hasCartError,
			selectedProductSet,
			bearers,
			cartItemCount,
		} = this.props;

		if (isEmpty(selectedProductSet)) {
			return (
				<Main>
					<FlexWrapper>
						<TicketBox>
							<Info>
								Du måste välja en biljett för att kunna lägga till i varukorgen
							</Info>
						</TicketBox>
					</FlexWrapper>
				</Main>
			);
		}

		return (
			<>
			<main>
				<Main id="ticketBoxContainer">
					<FlexWrapper>
						<TicketBox>
							<TicketPageHeader>
								<H1>
									{this.props.texts.private.buyticketpage.beareroptions.ticketcount}
								</H1>
								<Incrementer
									initialValue={cartItemCount}
									minValue={1}
									onIncrement={this.handleChange}
									onDecrement={this.handleChange}
									onInputChange={this.handleChange}
								/>
							</TicketPageHeader>
							<p style={{ fontStyle: 'italic' }}>
								{this.props.texts.private.buyticketpage.beareroptions.description}
							</p>

							{bearers && (
								<BearerOptions
									bearers={bearers}
									onSetManualActivation={this.setActivation}
									onSelectBearer={this.setBearer}
									texts={this.props.texts}
								/>
							)}

							{hasCartError && <Error />}
						</TicketBox>
					</FlexWrapper>
				</Main>
				</main>
				<SelectedProduct />
			</>
		);
	}
}

const H1 = styled.h1`
	margin: 0;
	flex-grow: 1;
	font-size: 18px;
`;

const TicketPageHeader = styled.div`
	display: flex;
	margin-bottom: 1em;
	align-items: center;
`;

const TicketBox = styled(CardWide)`
	text-align: left;
`;

function mapStateToProps(store) {
	return {
		selectedProductSet: getSelectedProductSet(store),
		hasCartError: hasCartError(store),
		bearers: getBearers(store),
		cartItemCount: getCartItemCount(store),
	};
}

export default withTextContext(
	connect(
		mapStateToProps,
		{ ...productActions, ...cartActions, ...bearerActions, push }
	)(PurchaseOptionsPage)
);
