import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TravelCard } from '../../../images/travel-card.svg';
import { ReactComponent as Mobile } from '../../../images/mobile.svg';
import { groupByFour } from '../../../utils/formatting';
import { isMobileApp } from '../../../reducer/bearer';

export function BearerInfo(props) {
	const { bearer } = props;

	if (!bearer) return null;

	const hasSerial = bearer.serial && bearer.serial.length > 0;

	return isMobileApp(bearer) ? (
		<Container>
			<Mobile className="svg-icon" aria-hidden="true" focusable="false" />
			<BearerWrapper>
				<Name>{bearer.name}</Name>
			</BearerWrapper>
		</Container>
	) : (
		<Container>
			<TravelCard className="svg-icon" aria-hidden="true" focusable="false" />
			<BearerWrapper>
				<Name>{bearer.name}</Name>
				{hasSerial && <Serial>{groupByFour(bearer.serial)}</Serial>}
			</BearerWrapper>
		</Container>
	);
}

const BearerWrapper = styled.div`
	width: 100%;
	min-width: 0; /* needed to get ellipsis to work */
`;

const Container = styled.div`
	display: flex;
	padding-right: 1em;
	min-width: 0; /* needed to get ellipsis to work */
`;

const Name = styled.p`
	margin: 0 0 0 6px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
`;

const Serial = styled.p`
margin: 0 0 0 6px;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
`;
