import { formatTime } from './formatting';

export const unique = array => [...new Set(array)];

export const isSchool = () => /\/school/.test(window.location.pathname);

export const addMinutesToDate = (inputDate, minutesToAdd) => {
	var date = new Date(inputDate);
	var newMinutes = date.getMinutes() + Number(minutesToAdd);
	date.setMinutes(newMinutes);
	return formatTime(date);
};
