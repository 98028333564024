import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose } from 'redux';
import { actionCreators, TRAVELLER_ERRORS } from '../reducer/traveller';
import { ConfirmationCard } from '../components/common/blocks/ConfirmationCard';
import { Main } from '../components/common/blocks/Main';
import { LoadPage } from '../components/common/LoadPage';
import { CollectName } from '../components/customer/CollectName';

export function ensureName(WrappedComponent) {
	return class extends React.Component {
		state = { showConfirmation: false };

		componentDidMount() {
			this.loadDataIfNotAvailable();
		}

		componentDidUpdate() {
			this.loadDataIfNotAvailable();
		}

		loadDataIfNotAvailable = () => {
			const { user, traveller } = this.props;

			if (!user) return;

			if (!traveller.data) {
				this.props.getTraveller();
			}
		};

		hasData = () => {
			return this.props.traveller.data ? true : false;
		};

		isMissingName = () => !this.props.traveller.data.name ? true : false;

		toggleConfirmation = () => {
			this.setState(state => ({ showConfirmation: !state.showConfirmation }));
		};

		continue = e => {
			e.stopPropagation();
			this.toggleConfirmation();
		};

		hasError = () =>
			this.props.traveller.errorMessage === TRAVELLER_ERRORS.GET_TRAVELLER;

		updateName = async name => {
			await this.props.updateTraveller({ name });

			if (
				this.props.traveller.errorMessage !== TRAVELLER_ERRORS.UPDATE_TRAVELLER
			) {
				this.toggleConfirmation();
			}
		};

		render() {
			const { traveller } = this.props;
			if (this.hasError()) throw Error('Error loading traveller');

			if (!this.hasData()) {
				return <LoadPage />;
			}

			if (this.state.showConfirmation) {
				return (
					<Main>
						<Confirmation
							onClick={this.continue}
							text={<p>Ditt namn har uppdaterats</p>}
						/>
					</Main>
				);
			}

			return this.isMissingName() ? (
				<Main>
					<CollectNameCard
						traveller={traveller}
						onSuccess={this.toggleConfirmation}
						onUpdateName={this.updateName}
					/>
				</Main>
			) : (
				<WrappedComponent {...this.props} />
			);
		}
	};
}

const maxCardWith = 'max-width: 350px;';
const Confirmation = styled(ConfirmationCard)`
	${maxCardWith}
`;
const CollectNameCard = styled(CollectName)`
	${maxCardWith}
`;

export default compose(
	connect(
		({ oidc, traveller }) => ({
			user: oidc.user,
			traveller,
		}),
		{ ...actionCreators }
	),
	ensureName
);
