import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Time } from '../../../images/time.svg';

export function ActivationInfo(props) {
	const { manualActivation } = props;

	if (manualActivation === undefined) return null;

	return (
		<Container>
			<TimeIcon className="svg-icon" aria-hidden="true" focusable="false" />
			<Activation><p>{manualActivation ? 'Väntande aktivering' : 'Direktaktivering'}</p></Activation>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
`;

const TimeIcon = styled(Time)`
	margin-right: 0.5em;
`;

const Activation = styled.div`
p {
	margin: 0;
}`;
