import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import withTextContext from '../../utils/withTextContext';
import styled from 'styled-components';
import { AddButton } from '../common/elements/AddButton';
import {
	actionCreators as cultureTripActions,
	getCultureTrips,
	getCreatedCultureTrip,
} from '../../reducer/school/culturetrips';
import isEmpty from 'lodash/isEmpty';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { SCHOOL } from '../../Paths';
import CultureTripWrapper from './culturetripform/CultureTripWrapper';
import { sanitizeCmsHtml } from '../../texts';

class CultureTripPage extends Component {
	componentDidMount() {
		const { cultureTrips } = this.props;
		if (isEmpty(cultureTrips)) this.props.getCultureTrips();
	}

	clearConfirmation = () => this.props.clearCreatedApplication();

	render() {
		const { createdApplication, texts } = this.props;
		if (createdApplication)
			return (
				<CultureTripWrapper>
					<ConfirmationCardContainer
						onClick={this.clearConfirmation}
						title={'Tack!'}
						text={
							<>
								<p>Ärendenummer:</p>
								<h4>{createdApplication.id}</h4>
								<div
									dangerouslySetInnerHTML={sanitizeCmsHtml(
										texts.school.culturetrippage.registertrip.successinformation
									)}
								/>
							</>
						}
					/>
				</CultureTripWrapper>
			);

		return (
			<CultureTripWrapper>
				<ToolsContainer>
					<AddButton
						onClick={() =>
							this.props.push(SCHOOL.CULTURE_TRIP_CHOOSE_TRAVELERS)
						}
					>
						Anmäl kulturresa
					</AddButton>
				</ToolsContainer>
			</CultureTripWrapper>
		);
	}
}

const ConfirmationCardContainer = styled(ConfirmationCard)`
	padding: 0 0.5em;
`;

const ToolsContainer = styled.div`
	display: flex;
	margin-bottom: 1em;
	justify-content: center;
`;

export default withTextContext(
	connect(
		store => ({
			cultureTrips: getCultureTrips(store),
			createdApplication: getCreatedCultureTrip(store),
		}),
		{ ...cultureTripActions, push }
	)(CultureTripPage)
);
