import React from 'react';
import styled from 'styled-components';
import PaymentCards from './PaymentCards';
import Purse from './Purse';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { LinkButton } from '../../common/elements/LinkButton';

export default function PaymentMethods(props) {
	return (
		<>
			<Section>
				<PaymentCards
					onShowVerifyAction={props.onShowVerifyAction}
					renderConfirmationCard={props.renderConfirmationCard}
					onCancel={props.onCancel}
				/>
			</Section>
			<Section>
				<Purse
					onShowVerifyAction={props.onShowVerifyAction}
					renderConfirmationCard={props.renderConfirmationCard}
					onCancel={props.onCancel}
				/>
			</Section>
		</>
	);
}

const Section = styled.div`
	& + & {
		margin-top: 2em;
		padding-top: 2em;
		border-top: solid 1px ${props => props.theme.lighter_grey};
	}
`;

export const CallToActionButton = styled(SecondaryButton)`
	margin-bottom: 1.5em;
`;

export const IconLinkButton = styled(LinkButton)`
	svg {
		stroke: ${props => props.theme.link_color};
	}
`;

export const BackButton = styled(IconLinkButton)`
	display: flex;
`;
