import React from 'react';
import styled from 'styled-components';

export function LinkButton(props) {
	return <Button {...props}>{props.children}</Button>;
}

const Button = styled.a`
	font-family: ${props => props.theme.text};
	font-weight: 600;
	padding: 0;
	background: none;
	border: none;
	color: ${props => props.theme.link_color};

	:hover,
	:focus {
		text-decoration: underline;
		cursor: pointer;
	}
	&:hover  {
		color: ${props => props.theme.link_color};
	}

	&[disabled] {
		color: #464646;
		text-decoration: none;
	}
`;
