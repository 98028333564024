import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { A } from './../common/elements/A';
import { UnstyledList } from './../common/elements/UnstyledList';
import { MobileAppInfo } from './MobileAppInfo';
import { MobileAppImage } from './MobileAppImage';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import TicketNavigation from './blocks/TicketNavigation';
import { PageHeader } from './PageHeader';
import { Main } from '../common/blocks/Main';
import {
	getMobileApps,
	getTraveller,
	isLoadingMtbBearers,
  getBearers,
} from '../../reducer';
import { actionCreators } from '../../reducer/bearer';
import { SignedInWelcomeText } from './blocks/SignedInWelcomeText';
import { Loading } from '../common/blocks/Loading';
import { MovingBus } from './blocks/MovingBus';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

export class MobileAppPage extends Component {
	componentDidMount() {
		document.title = "Reseapp | Mitt konto"
		const { mobileApps } = this.props;

		if (!mobileApps) this.props.getBearers();

		if (this.props.mobileApps) {
			this.setPreferredBearerOnBearerDelete();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.bearers !== prevProps.bearers) {
			this.updatePreferredBearer();
		}
	}

	updatePreferredBearer = async () => {
		await this.props.updatePreferredBearer();
	}

	setPreferredBearerOnBearerDelete = async () => {
		await this.props.setPreferredBearerOnBearerDelete();
	}

	renderDownloadInfo = () => {
		const {
			header: headerText,
			body: bodyText,
			iosAppLink,
			androidAppLink,
		} = this.props.texts.private.ticketpage.mobileapp;

		return (
			<FlexWrapper>
				<InfoCardBox>
					<MobileAppInfo header={headerText} body={bodyText} />
					<UnstyledList>
						{iosAppLink.value && (
							<li>
								<A
									href={iosAppLink.value}
									target="_blank"
									rel="noopener noreferrer"
								>
									{iosAppLink.displayValue} &rsaquo;
								</A>
							</li>
						)}
						{androidAppLink.value && (
							<li>
								<A
									href={androidAppLink.value}
									target="_blank"
									rel="noopener noreferrer"
								>
									{androidAppLink.displayValue} &rsaquo;
								</A>
							</li>
						)}
					</UnstyledList>
				</InfoCardBox>
				<MobileApp />
			</FlexWrapper>
		);
	};

	render() {
		const { mobileApps, traveller, isLoading, texts } = this.props;
		const {
			header: headerText,
			signedin: signedinText,
		} = texts.private.ticketpage.banner;

		return (
			<Fragment>
				<Banner bannerImg={texts.private.images.banners.ticketpage}>
					<SignedInWelcomeText
						name={traveller.name}
						header={headerText}
						signedin={signedinText}
					/>
				</Banner>
				<main>
				<Main id="mobileAppPageMainContainer">
					<PageHeader>
						<H2>Mina Biljetter</H2>
						<TicketNavigation />
					</PageHeader>

					{isLoading ? (
						<FlexWrapper>
							<Loading />
						</FlexWrapper>
					) : (
						<>
							{isEmpty(mobileApps) ? (
								this.renderDownloadInfo()
							) : (
								<FlexWrapper>
									<div>
										<AppHeader>
											{texts.private.ticketpage.mobileapp.header}
										</AppHeader>
										{mobileApps.map((a, idx) => (
											<InfoCardBox key={idx}>
												<h5>{a.name}</h5>
											</InfoCardBox>
										))}
									</div>
									<MobileApp />
								</FlexWrapper>
							)}
						</>
					)}
				</Main>
				</main>
				<MobilePageMovingBus />
			</Fragment>
		);
	}
}

const MobilePageMovingBus = styled(MovingBus)`
	svg {
		margin-left: 72%;
	}
`;

const AppHeader = styled.h3`
	max-width: 270px;
`;

const InfoCardBox = styled.div`
	max-width: 306px;
	margin-top: 2em;
`;

const MobileApp = styled(MobileAppImage)`
	box-shadow: 0 25px 40px 2px rgba(0, 0, 0, 0.22);
	margin-bottom: 1em;
	margin-right: 2em;
	margin-left: 1em;
`;

const H2 = styled.h2`
	margin-top: 0;
	margin-bottom: 1em;
`;

export default withTextContext(
	connect(
		store => ({
			mobileApps: getMobileApps(store),
			traveller: getTraveller(store),
			bearers: getBearers(store),
			isLoading: isLoadingMtbBearers(store),
		}),
		{ push, ...actionCreators }
	)(MobileAppPage)
);
