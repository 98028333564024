import React from 'react';
import { connect } from 'react-redux';
import { Error } from './elements/Error';
import { Main } from '../common/blocks/Main';
import { log } from '../../api';
import { getToken } from '../../reducer';
import { withRouter } from 'react-router';

export class MainErrorBoundary extends React.Component {
	state = { hasError: false };

	constructor(props) {
		super(props);

		const { history } = this.props;
		
		history.listen((location, action) => {
			if (this.state.hasError) {
				this.setState({ hasError: false });
			}
		});
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		log(this.props.token, {
			level: 'error',
			message: error.stack,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<Main>
					<Error className={this.props.className} />
				</Main>
			);
		}
		return this.props.children;
	}
}

export default connect(store => ({ token: getToken(store) }))(
	withRouter(MainErrorBoundary)
);
