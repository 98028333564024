import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { actionCreators as transactionActions, getPurseHistory } from '../../../reducer/transaction';
import {
	hasCancelTransactionError,
	isLoadingCancelTransaction,
	getTransactionErrorMessage,
	isLoadingTransactionHistory,
	getPurchaseHistory,
	getTicketHistory,
	getTravellerId,
} from '../../../reducer';
import { HistoryContainer } from './HistoryContainer';
import TicketHistory from './TicketHistory';
import PurchaseHistory from './PurchaseHistory';
import { Loading } from '../../common/blocks/Loading';
import PurseHistory from './PurseHistory';

export class History extends Component {
	HISTORY_TYPES = {
		PURCHASE: 'purchase',
		TICKET: 'ticket',
	};

	verifyDescription = `Återköpet innebär att hela köpet kommer att ångras. Pengarna återbetalas 
											 till det betalmedel du använde vid köptillfället inom tre bankdagar.`;
	verifyErrorMessage = `Ett fel uppstod när du försökte ångra transaktionen. 
												Var vänlig och kontakta kundtjänst eller försök igen.`;
	verifyCtaText = 'Ja, genomför återköp';

	componentDidMount() {
		this.props.getTransactionHistory();
	}	

	onCancelTransaction = transactionId => {
		this.props.onShowVerifyAction({
			description: this.verifyDescription,
			ctaText: this.verifyCtaText,
			callback: this.cancelTransaction,
			data: { transactionId },
		});
	};

	cancelTransaction = async ({ transactionId }) => {
		const responseOk = await this.props.cancelTransaction(transactionId);
		this.props.getTransactionHistory();

		if (responseOk) {
			this.props.renderConfirmationCard(<p>Du har nu ångrat ditt köp</p>);
		} else {
			this.props.onShowVerifyAction({
				description: this.verifyDescription,
				ctaText: this.verifyCtaText,
				errorMessage: this.verifyErrorMessage,
				callback: this.cancelTransaction,
				data: { transactionId },
			});
		}
	};

	render() {
		const {
			isLoadingHistory,
			purchaseHistory,
			ticketHistory,
			purseHistory,
			travellerId,
		} = this.props;

		if (isLoadingHistory) {
			return <Loading />;
		}

		return (
			<FlexContainer>
				<HistoryContainer
					historyComponent={PurchaseHistory}
					transactions={purchaseHistory}
					title="Köphistorik"
					onCancelTransaction={this.onCancelTransaction}
				/>

				<HistoryContainer
					historyComponent={TicketHistory}
					transactions={ticketHistory}
					title="Biljetthistorik"
					travellerId={travellerId}
				/>

				<HistoryContainer
					historyComponent={PurseHistory}
					transactions={purseHistory}
					title="Kundkassa"
					travellerId={travellerId}
				/>
			</FlexContainer>
		);
	}
}

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1.5em;
`;

function mapStateToProps(store) {
	return {
		hasCancelTransactionError: hasCancelTransactionError(store),
		isLoadingCancelTransaction: isLoadingCancelTransaction(store),
		transactionErrorMessage: getTransactionErrorMessage(store),
		isLoadingHistory: isLoadingTransactionHistory(store),
		purchaseHistory: getPurchaseHistory(store),
		ticketHistory: getTicketHistory(store),
		purseHistory: getPurseHistory(store),
		travellerId: getTravellerId(store),
	};
}

export default connect(
	mapStateToProps,
	{ ...transactionActions, push }
)(History);
