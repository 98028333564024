import React from 'react';
import styled from 'styled-components';
import BussenImage from '../../images/bussen_vit.svg';
import {forPhoneOnly} from "./../../utils/mediaqueries"

function Welcome(props) {	
	return (
		<Container {...props}>
			<div>
				<HomePageTitle>Välkommen till Mitt konto</HomePageTitle>
				<Bussen src={BussenImage} width="100%" alt="" />
			</div>
			<p>
				På Mitt konto kan du köpa resor och periodbiljetter. Du kan också
				hantera och administrera dina biljetter och dina köp.
			</p>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 2em;
	margin-right: 2em;
	color: #fff;

	p {
		margin-bottom: 0;
		max-width: 300px;
		line-height: 1.3em;
		font-size: 16px;
	}
`;

const HomePageTitle = styled.h1`
	font-size: ${props => props.theme.h1.font_size};
	margin-top: 0;
	margin-bottom: 0;
	max-width: 300px;
	font-weight: 400;

	${forPhoneOnly`
		text-align: center;
	`}
`;

const Bussen = styled.img`
	max-width: 260px;
	margin-top: 2em;
	margin-bottom: 2em;
`;

export default Welcome;
