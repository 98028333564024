import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getUserProfile } from '../reducer';
import {
	actionCreators as adminActions,
	getAdmin,
	isLoadingAdminUser,
	hasFetchedAdminUser,
	getCurrentSchool,
} from '../reducer/school/admins';
import { LoadPage } from '../components/common/LoadPage';
import { Redirect, withRouter } from 'react-router';
import { SCHOOL } from '../Paths';
import { idpConfig } from '../config';
import { getSchool } from '../reducer/school';

const withSchoolAuthorization = roles => WrappedComponent => {
	return class extends React.Component {
		componentDidMount() {
			const { hasFetchedAdmin, user } = this.props;
			if (!hasFetchedAdmin) {
				this.props.getAdmin(user[idpConfig.schoolIdClaim]);
			}
		}

		userIsAuthorized = () => {
			const { school, getCurrentSchool } = this.props;
			const adminRole = getCurrentSchool ? getCurrentSchool.roleName : '';
			return school && roles.some(r => adminRole.toLowerCase().includes(r));
		};

		render() {
			const { isLoading, location, hasFetchedAdmin, ...props } = this.props;

			if (isLoading || !hasFetchedAdmin) {
				return <LoadPage />;
			}

			if (this.userIsAuthorized()) {
				return <WrappedComponent {...props} />;
			}

			return (
				<Redirect
					to={{
						pathname: SCHOOL.UNAUTHORIZED,
						state: { accessedPath: location.pathname },
					}}
				/>
			);
		}
	};
};

function mapStateToProps(state) {
	return {
		admin: getAdmin(state),
		school: getSchool(state),
		getCurrentSchool: getCurrentSchool(state),
		user: getUserProfile(state),
		isLoading: isLoadingAdminUser(state),
		hasFetchedAdmin: hasFetchedAdminUser(state),
	};
}

export default roles =>
	compose(
		connect(
			mapStateToProps,
			{ ...adminActions }
		),
		withSchoolAuthorization(roles),
		withRouter
	);
