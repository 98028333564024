import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { userManager } from '../../utils/userManager';
import { ReactComponent as Profile } from '../../images/profile.svg';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import Welcome from './Welcome';
import { SignupContainer } from './blocks/SignupContainer';
import { SignupBox } from './blocks/SignupBox';
import { PRIVATE } from '../../Paths';
import { HomePageMain } from '../common/blocks/HomePageMain';
import LogoWrapper from '../common/blocks/LogoWrapper';
import { forPhoneOnly, forZoomedInUsers } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { getImageUrl, IMAGE_SIZES } from '../../reducer/cms';
import {SkipToMainContent} from "./../common/SkipToMainContent"

class HomePage extends Component {
	componentDidMount() {
		document.title = "Välkommen till Mitt konto"
		this.unlisten = this.props.history.listen((location, action) => {
			if (location.pathname === PRIVATE.HOME) {
				this.navigateToOrderIfSignedIn();
			}
		});
	}

	componentDidUpdate() {
		this.navigateToOrderIfSignedIn();
	}

	navigateToOrderIfSignedIn = () => {
		if (this.props.user) {
			this.props.push(PRIVATE.TICKET);
		}
	};

	componentWillUnmount() {
		this.unlisten();
	}

	login = () => {
		userManager.signinRedirect({ state: { location: PRIVATE.TICKET } });
	};

	register = () => {
		this.props.push(PRIVATE.REGISTER);
	};

	handleSuccessCallback = user => {
		this.props.push(user.state.location);
	};

	render() {
		const { texts } = this.props;
		const backgroundImage = texts.private.images.startpage.backgroundimage;
		const backgroundSmall = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_SMALL
		);
		const backgroundMedium = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_MEDIUM
		);
		const backgroundLarge = getImageUrl(
			backgroundImage,
			IMAGE_SIZES.HOME_PAGE_LARGE
		);

		return (
			<HomePageMain
				backgroundSmall={backgroundSmall}
				backgroundMedium={backgroundMedium}
				backgroundLarge={backgroundLarge}
			>
				<SkipToMainContent currentPath={"/"} />

				<LogoContainer>
					<LogoWrapper />
				</LogoContainer>
				<HomePageSignupContainer>
					<HomePageWelcome />
					<SignupBox id="customerLoginPageSignupBox">
						<Fragment>
							<h3>Logga in</h3>
							<ProfileImage>
								<Profile />
							</ProfileImage>
							<SignInText>
								Logga in på Mitt konto för att få tillgång till samtliga
								tjänster och funktioner.
							</SignInText>

							<SecondaryButton onClick={this.login}>Logga in</SecondaryButton>
							<SecondaryButton onClick={this.register}>
								Skapa konto
							</SecondaryButton>
						</Fragment>
					</SignupBox>
				</HomePageSignupContainer>
			</HomePageMain>
		);
	}
}

const LogoContainer = styled.div`
	margin-left: 2em;
	align-self: flex-start;

	${forPhoneOnly`
		align-self: center;
	`}
`;

const HomePageSignupContainer = styled(SignupContainer)`
	max-width: 968px;
	width: 100%;
	flex-direction: row-reverse;
`;

const HomePageWelcome = styled(Welcome)`
	color: #fff;
`;
const SignInText = styled.p`
	width: 220px;
	margin: 0 auto;
	display: flex;
	font-size: 15px;

	${forZoomedInUsers`
		max-width: 100%;
	`}
`;

const ProfileImage = styled.div`
	background-color: ${props => props.theme.primary_color};
	width: 86px;
	height: 86px;
	border-radius: 86px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1em auto;

	svg {
		width: 30px;
		height: 30px;
	}

	path {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

export default withTextContext(
	connect(
		state => ({
			user: state.oidc.user,
		}),
		{ push }
	)(HomePage)
);
