import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';
import { actionCreators as travellerActions } from '../reducer/traveller';
import { actionCreators as legalActions } from '../reducer/legal';
import { actionCreators as consentActions } from '../reducer/consent';
import AcceptTerms from '../components/customer/AcceptTerms';
import { ConfirmationCard } from '../components/common/blocks/ConfirmationCard';
import { Main } from '../components/common/blocks/Main';
import { LoadPage } from '../components/common/LoadPage';
import { readCookie, writeCookie } from './cookieManager';
import {
	getLegal,
	getConsent,
	hasConsentLoadingError,
	hasLegalLoadingError,
	isLoadingLegal,
	getTraveller,
	hasGetTravellerError,
	isLoadingTraveller,
	hasUpdateTravellerError,
} from '../reducer';

export function ensureAcceptedTerms(WrappedComponent) {
	return class extends React.Component {
		state = { showConfirmation: false, termsChecked: false };

		CONSENT_TYPES = {
			NEWSLETTER: 100000000,
		};

		componentDidMount() {
			this.loadDataIfNotAvailable();
		}

		loadDataIfNotAvailable = async () => {
			const { user, traveller, legal } = this.props;

			// user must have logged in before we can get traveller or legal
			if (!user) return;

			if (!traveller) {
				await this.props.getTraveller();
			}

			if (!legal) {
				await this.props.getLatestLegal();
			}

			let cookieLegal = this.getLegalCookie();
			if (this.isLegalAcceptedAndNotSet(cookieLegal)) {
				this.updateTerms();
			}
			
			if (!this.hasOutdatedTerms()) {
				this.setState({ termsChecked: true });
			}
			
			if (cookieLegal.newsletterConsent) this.setNewsletterConsent();
		};

		hasData = () => {
			const {
				traveller,
				legal,
				isLoadingLegal,
				isLoadingTraveller,
			} = this.props;
			return traveller && legal && !isLoadingTraveller && !isLoadingLegal;
		};

		getLegalCookie = () => {
			let cookieLegalJson = readCookie('LEGAL_CONSENT');
			let cookieLegal;
			try {
				cookieLegal = JSON.parse(cookieLegalJson);
			} catch (error) {
				cookieLegal = { terms: '', privacy: '', newsletterConsent: false };
			}

			writeCookie('LEGAL_CONSENT', '', -1);
			return cookieLegal;
		};

		isLegalAcceptedAndNotSet = cookieLegal => {
			const { traveller, legal } = this.props;

			if (
				(!traveller.legalAccepted.terms || !traveller.legalAccepted.privacy) &&
				(cookieLegal.terms === legal.terms &&
					cookieLegal.privacy === legal.privacy)
			) {
				return true;
			}

			writeCookie('LEGAL_CONSENT', '', -1);
			return false;
		};

		setNewsletterConsent = async () => {
			if (!this.props.consent) await this.props.getConsent();

			if (!this.props.hasConsentLoadingError) {
				let newsLetterConsents = this.props.consent.find(
					this.isNewsletterConsent
				);

				if (!!newsLetterConsents) {
					let consentModel = newsLetterConsents.consents.map(consent => ({
						...consent,
						active: true,
					}));

					this.props.updateConsent(consentModel);
				}
			}
		};

		isNewsletterConsent = consent =>
			consent.type === this.CONSENT_TYPES.NEWSLETTER;

		hasOutdatedTerms = () => {
			const { traveller, legal } = this.props;

			const newTerms = traveller.legalAccepted.terms !== legal.terms;
			const newPrivacy = traveller.legalAccepted.privacy !== legal.privacy;

			return newTerms || newPrivacy;
		};

		updateTerms = async () => {
			const { terms, privacy } = this.props.legal;
			const latestTerms = { terms, privacy };

			await this.props.updateTraveller({ legalAccepted: latestTerms });

			if (!this.props.hasUpdateTravellerError)
				this.setState({ termsChecked: true });
		};

		toggleConfirmation = () => {
			this.setState(state => ({ showConfirmation: !state.showConfirmation }));
		};

		continue = e => {
			e.stopPropagation();
			this.setState({ showConfirmation: false, termsChecked: true });
		};

		hasError = () => {
			const { hasLegalLoadingError, hasGetTravellerError } = this.props;

			return hasGetTravellerError || hasLegalLoadingError;
		};

		render() {
			const { termsChecked } = this.state;

			if (termsChecked) return <WrappedComponent {...this.props} />;

			if (this.state.showConfirmation) {
				return (
					<Main>
						<Confirmation
							onClick={this.continue}
							text={<p>Dina inställningar har sparats</p>}
						/>
					</Main>
				);
			}

			if (this.hasError())
				throw Error('Error loading traveller or latest legal');

			if (!this.hasData()) {
				return <LoadPage />;
			}

			return this.hasOutdatedTerms() ? (
				<Main>
					<AcceptTermsCard onSuccess={this.toggleConfirmation} />
				</Main>
			) : (
				<WrappedComponent {...this.props} />
			);
		}
	};
}

const maxCardWith = 'max-width: 350px;';
const Confirmation = styled(ConfirmationCard)`
	${maxCardWith}
`;
const AcceptTermsCard = styled(AcceptTerms)`
	${maxCardWith}
`;

function mapStateToProps(store) {
	return {
		user: store.oidc.user,
		traveller: getTraveller(store),
		hasGetTravellerError: hasGetTravellerError(store),
		hasUpdateTravellerError: hasUpdateTravellerError(store),
		isLoadingTraveller: isLoadingTraveller(store),
		legal: getLegal(store),
		isLoadingLegal: isLoadingLegal(store),
		hasLegalLoadingError: hasLegalLoadingError(store),
		consent: getConsent(store),
		hasConsentLoadingError: hasConsentLoadingError(store),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			...travellerActions,
			...legalActions,
			...consentActions,
		},
		dispatch
	);
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	ensureAcceptedTerms
);
