import React from 'react';
import styled from 'styled-components';

export const ProductGeography = props => {
	const { origin, destination, spatialTitle } = props.product;
	const displayToFrom = !!origin && !!destination;

	return (
		<div>
			<ProductInfoText>
				{displayToFrom && `${origin.name} - ${destination.name}`}
			</ProductInfoText>
			<ProductInfoText>{spatialTitle}</ProductInfoText>
		</div>
	);
};

const ProductInfoText = styled.p`
	margin: 0;
	max-width: 275px;
`;
