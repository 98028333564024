import styled from 'styled-components';

export const A = styled.a`
	color: ${props => props.theme.link_color};
	text-decoration: underline;

	&:hover {
		color: ${props => props.theme.link_hover_color};
	}
`;
