import React from 'react';
import styled from 'styled-components';

export const ProfileCardHeader = props => {
	const { children, className, style } = props;

	return (
		<Header className={className} style={style}>
			{children}
		</Header>
	);
};

const Header = styled.h2`
	font-size: 18px;
	line-height: 24px;
	margin: 0px;
`;
