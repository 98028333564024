import React from 'react';
import { useEffect, useRef, useCallback } from 'react';

/**
 * @abstract Custom component that traps focus inside the children of the component.
 * @param children - The children wrapped inside the component.
 * @param  isNavOpen - boolean to toggle the focus trap.
 */

export default function FocusTrap({ children, isNavOpen }) {
	const childElementRefs = useRef([]);

	const handleKeyDown = useCallback(evt => {
		if (!isNavOpen) return;

		if (evt.key === 'Tab') {
			evt.preventDefault();

			const currentlyfocusedElement =
				childElementRefs.current.find(el =>
					el.contains(document.activeElement)
				) || childElementRefs.current[0];

			const currentlyFocusedElementIdx = childElementRefs.current.indexOf(currentlyfocusedElement);

			const conditions = {
				isLastDomElementInFocus: currentlyFocusedElementIdx === childElementRefs.current.length - 1,
				isFirstDomElementInFocus: currentlyFocusedElementIdx === 0,
				lastDomNode: childElementRefs.current[childElementRefs.current.length - 1],
				firstDomNode: childElementRefs.current[0],
				prevDomNode: childElementRefs.current[currentlyFocusedElementIdx - 1],
				nextDomNode: childElementRefs.current[currentlyFocusedElementIdx + 1],
			}
			
			if (evt.shiftKey) {
				// When the user presses "shift + tab" to focus on the previous element
				const nextFocus =
					conditions.isFirstDomElementInFocus 
							? conditions.lastDomNode
							: conditions.prevDomNode;

				nextFocus && nextFocus.children[0].focus();

			} else {
				// When the user presses tab to focus on the next element
				const nextFocus =
					conditions.isLastDomElementInFocus
							? conditions.firstDomNode
							: conditions.nextDomNode

				nextFocus && nextFocus.children[0].focus();
			}
		}
	}, [isNavOpen]);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

        // Cleanup
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [handleKeyDown, isNavOpen]);

	return (
		<>
			{React.Children.map(children, (child, index) =>
				React.cloneElement(child, {
					ref: ref => (childElementRefs.current[index] = ref),
				})
			)}
		</>
	);
}