import { getToken, getStudents } from '.';
import { getAdmin } from './school/admins';
import { getSchool } from './school';
import {
	fetchGroups,
	deleteStudentFromGroup,
	createGroup,
	removeGroup,
	updateGroup,
} from '../api';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	data: null,
	groups: undefined,
	isLoading: false,
	isLoadingDeleteStudent: false,
	errorMessage: '',
	isLoadingRemoveGroup: false,
};

export const GROUP_ERRORS = {
	GET_GROUPS: 'GET_GROUPS',
	CREATE_GROUP: 'CREATE_GROUP',
	REMOVE_STUDENT: 'REMOVE_STUDENT',
	REMOVE_GROUP: 'REMOVE_GROUP',
	UPDATE_GROUP: 'UPDATE_GROUP',
};

export const STORE_NAME = 'groups';

export const actionCreators = {
	getGroups: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_GROUPS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const students = getStudents(state);
		const school = getSchool(state);

		try {
			const groups = await fetchGroups(token, school.schoolId);
			dispatch({ type: 'GET_GROUPS_SUCCESS', groups, students });
		} catch (error) {
			dispatch({
				type: 'GET_GROUPS_FAILURE',
				message: GROUP_ERRORS.GET_GROUPS,
			});
		}
	},
	createGroup: group => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_GROUPS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const admin = getAdmin(state);
		const school = getSchool(state);

		try {
			const createdGroup = await createGroup(
				token,
				school.schoolId,
				admin.id,
				group
			);

			dispatch({ type: 'CREATE_GROUPS_SUCCESS', createdGroup });
		} catch (error) {
			dispatch({
				type: 'CREATE_GROUPS_FAILURE',
				message: GROUP_ERRORS.CREATE_GROUP,
			});
			throw error;
		}
	},
	updateGroup: (groupId, group) => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_GROUP_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const admin = getAdmin(state);

		try {
			const updatedGroup = await updateGroup(
				token,
				admin.schoolId,
				admin.id,
				groupId,
				group
			);

			let studentIdsInGroup = getStudentIdsInGroup(state[STORE_NAME])(groupId);
			dispatch({
				type: 'UPDATE_GROUP_SUCCESS',
				updatedGroup,
				studentIdsInGroup,
			});
		} catch (error) {
			let message = GROUP_ERRORS.UPDATE_TRAVELLER;

			dispatch({ type: 'UPDATE_GROUP_FAILURE', message });
			throw error;
		}
	},
	removeStudentFromGroup: (groupId, travellerId) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'REMOVE_STUDENT_FROM_GROUP_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const group = await deleteStudentFromGroup(
				token,
				school.schoolId,
				groupId,
				travellerId
			);
			dispatch({
				type: 'REMOVE_STUDENT_FROM_GROUP_SUCCESS',
				group,
				travellerId,
			});
			dispatch({ type: 'REMOVE_GROUP_FROM_STUDENT', groupId, travellerId });
		} catch (error) {
			dispatch({
				type: 'REMOVE_STUDENT_FROM_GROUP_FAILURE',
				message: GROUP_ERRORS.REMOVE_STUDENT,
			});
		}
	},
	removeGroup: groupId => async (dispatch, getState) => {
		dispatch({ type: 'REMOVE_GROUP_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const orgAdmin = getAdmin(state);

		try {
			await removeGroup(token, orgAdmin, groupId);

			let studentIdsInGroup = getStudentIdsInGroup(state[STORE_NAME])(groupId);
			dispatch({ type: 'REMOVE_GROUP_SUCCESS', groupId, studentIdsInGroup });
		} catch (error) {
			dispatch({
				type: 'REMOVE_GROUP_FAILURE',
				message: GROUP_ERRORS.REMOVE_GROUP,
			});
			throw error;
		}
	},
};

export default function groupsReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_GROUPS_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'CREATE_GROUPS_REQUEST':
			return {
				...state,
				isLoadingCreateGroup: true,
				errorMessage: '',
			};
		case 'REMOVE_GROUP_REQUEST':
			return {
				...state,
				isLoadingRemoveGroup: true,
				errorMessage: '',
			};
		case 'UPDATE_GROUP_REQUEST':
			return {
				...state,
				isLoadingUpdateGroup: true,
				errorMessage: '',
			};
		case 'REMOVE_STUDENT_FROM_GROUP_REQUEST':
			return {
				...state,
				isLoadingDeleteStudent: true,
				errorMessage: '',
			};
		case 'GET_GROUPS_SUCCESS':
			return {
				...state,
				isLoading: false,
				groups: {
					...state.groups,
					...action.groups.reduce((o, group) => {
						group.students = Object.values(action.students)
							.filter(s =>
								s.groupInformation.some(g => g.groupId === group.groupId)
							)
							.map(s => s.travellerId);
						return { ...o, [group.groupId]: group };
					}, {}),
				},
			};
		case 'UPDATE_STUDENTS_IN_GROUP':
			return {
				...state,
				groups: {
					...state.groups,
					[action.groupId]: {
						...state.groups[action.groupId],
						students: action.students.map(s => s.travellerId),
					},
				},
			};
		case 'UPDATE_GROUP_WITH_CREATED_STUDENT':
			const group = cloneDeep(state.groups[action.groupId]);
			group.studentCount += 1;
			group.students.push(action.studentId);

			return {
				...state,
				groups: {
					...state.groups,
					[action.groupId]: group,
				},
			};
		case 'CREATE_GROUPS_SUCCESS':
			return {
				...state,
				isLoadingCreateGroup: false,
				groups: {
					...state.groups,
					[action.createdGroup.groupId]: action.createdGroup,
				},
			};
		case 'UPDATE_GROUP_SUCCESS':
			return {
				...state,
				isLoadingUpdateGroup: false,
				groups: {
					...state.groups,
					[action.updatedGroup.groupId]: {
						...action.updatedGroup,
						students: state.groups[action.updatedGroup.groupId].students,
					},
				},
				errorMessage: '',
			};
		case 'REMOVE_STUDENT_FROM_GROUP_SUCCESS':
			return {
				...state,
				groups: {
					...state.groups,
					[action.group.groupId]: {
						...action.group,
						students: state.groups[action.group.groupId].students.filter(
							s => s !== action.travellerId
						),
					},
				},
				isLoadingDeleteStudent: false,
			};
		case 'REMOVE_GROUP_SUCCESS':
			let groups = cloneDeep(state.groups);
			delete groups[action.groupId];

			return {
				...state,
				groups,
				isLoadingRemoveGroup: false,
			};
		case 'GET_GROUPS_FAILURE':
		case 'REMOVE_STUDENT_FROM_GROUP_FAILURE':
			return {
				...state,
				isLoading: false,
				isLoadingDeleteStudent: false,
				errorMessage: action.message,
			};
		case 'CREATE_GROUPS_FAILURE':
			return {
				...state,
				isLoadingCreateGroup: false,
				isLoadingDeleteStudent: false,
				errorMessage: action.message,
			};
		case 'REMOVE_GROUP_FAILURE':
			return {
				...state,
				isLoadingRemoveGroup: false,
				errorMessage: action.message,
			};
		case 'UPDATE_GROUP_FAILURE':
			return {
				...state,
				isLoadingUpdateGroup: false,
				errorMessage: action.message,
			};
		case 'CLEAR_SCHOOL_STATE':
			return { ...state, ...initialState };
		default:
			return state;
	}
}

export function getGroups(state) {
	return state.groups || [];
}

export const getStudentIdsInGroup = state => groupId =>
	state.groups && state.groups[groupId] ? state.groups[groupId].students : [];

export const hasStudentsForGroup = state => groupId =>
	state.groups &&
	state.groups[groupId] &&
	!isEmpty(state.groups[groupId].students);

export const getGroupById = state => groupId =>
	state[STORE_NAME].groups[groupId];

export function hasError(store) {
	return store.errorMessage !== '';
}

export function hasCreateError(store) {
	return store.errorMessage === GROUP_ERRORS.CREATE_GROUP;
}

export function hasDeleteStudentError(store) {
	return store.errorMessage === GROUP_ERRORS.REMOVE_STUDENT;
}

export const isLoading = store => store.isLoading;
export const isLoadingDeleteStudent = store => store.isLoadingDeleteStudent;
export const isLoadingUpdateGroup = store => store.isLoadingUpdateGroup;
