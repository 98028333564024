import { getConsent, updateConsent } from '../api';
import { getToken } from '../reducer';

const initialState = {
	data: null,
	isLoading: false,
	errorMessage: '',
};

export const CONSENT_ERRORS = {
	GET_CONSENT: 'Error while getting consents',
	UPDATE_CONSENT: 'Error while updating consents',
}

export const actionCreators = {
	getConsent: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_CONSENT_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = state.traveller.data.id;
		let consent = null;
		try {
			consent = await getConsent(token, travellerId);
			dispatch({ type: 'GET_CONSENT_SUCCESS', consent });
		} catch (error) {
			dispatch({ type: 'GET_CONSENT_FAILURE', message: CONSENT_ERRORS.GET_CONSENT });
		}
	},
	updateConsent: updatedConsent => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_CONSENT_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = state.traveller.data.id;
		const consentList = Object.values(updatedConsent);
		let consent = null;

		try {
			await updateConsent(token, travellerId, consentList);			
			consent = await getConsent(token, travellerId);
			dispatch({ type: 'UPDATE_CONSENT_SUCCESS', consent });
		} catch (error) {
			dispatch({ type: 'UPDATE_CONSENT_FAILURE', message: CONSENT_ERRORS.UPDATE_CONSENT });
		}
	},
};

export default function reducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_CONSENT_REQUEST':
		case 'UPDATE_CONSENT_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_CONSENT_SUCCESS':
		case 'UPDATE_CONSENT_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.consent,
			};
		case 'GET_CONSENT_FAILURE':
		case 'UPDATE_CONSENT_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		default:
			return state;
	}
}

export function isLoading(state) {	
	return state.isLoading;
}

export function hasLoadingError(state) {	
	return state.errorMessage === CONSENT_ERRORS.GET_CONSENT;
}

export function hasUpdateError(state) {	
	return state.errorMessage === CONSENT_ERRORS.UPDATE_CONSENT;
}

export function getConsentData(state) {	
	return state.data;
}
