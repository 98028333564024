import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { ReactComponent as BusWhite } from '../../images/bussen_vit.svg';
import { SignupContainer } from './blocks/SignupContainer';
import { SignupBox } from './blocks/SignupBox';
import { SCHOOL } from '../../Paths';
import { HomePageMain } from './blocks/HomePageMain';
import LogoWrapper from '../common/blocks/LogoWrapper';
import { forPhoneOnly } from '../../utils/mediaqueries';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import withTextContext from '../../utils/withTextContext';
import { sanitizeCmsHtml } from '../../texts';

class RegisterPage extends Component {
	componentDidMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			if (location.pathname === SCHOOL.HOME) {
				this.navigateToTicketIfSignedIn();
			}
		});
	}

	componentDidUpdate() {
		this.navigateToTicketIfSignedIn();
	}

	navigateToTicketIfSignedIn = () => {
		if (this.props.user) {
			this.props.push(SCHOOL.ORDER);
		}
	};

	componentWillUnmount() {
		this.unlisten();
	}

	handleSuccessCallback = user => {
		this.props.push(user.state.location);
	};

	render() {
		const { texts } = this.props;

		return (
			<HomePageMain>
				<LogoContainer>
					<LogoWrapper />
				</LogoContainer>
				<HomePageSignupContainer>
					<SignupBox>
						<Fragment>
							<h1>Skapa konto</h1>
							<PageImage>
								<BusWhite />
							</PageImage>
							<div
								dangerouslySetInnerHTML={sanitizeCmsHtml(
									texts.school.registerpage.pageinfo.registerinfo
								)}
							/>
							<HomePageLink exact to={SCHOOL.HOME}>
								Tillbaka
							</HomePageLink>
						</Fragment>
					</SignupBox>
				</HomePageSignupContainer>
			</HomePageMain>
		);
	}
}

const LogoContainer = styled.div`
	margin-left: 2em;
	align-self: flex-start;

	${forPhoneOnly`
		align-self: center;
	`}
`;

const HomePageLink = styled(ThemedNavLink)`
	color: black;
	padding: 2em;
`;

const HomePageSignupContainer = styled(SignupContainer)`
	max-width: 968px;
	width: 100%;
`;

const PageImage = styled.div`
	background-color: ${props => props.theme.primary_color};
	width: 86px;
	height: 86px;
	border-radius: 86px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1em auto 2em;

	svg {
		width: 100px;
		height: 100px;
	}

	path {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

export default connect(
	state => ({ user: state.oidc.user }),
	{ push }
)(withTextContext(RegisterPage));
