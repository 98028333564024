import React from 'react';
import styled from 'styled-components';
import { Validation } from '../../utils/validation';
import { formatInternationalPhoneNumber } from '../../utils/formatting';
import { TextInput } from '../common/elements/TextInput';
import { NextButton } from '../common/elements/NextButton';
import { LinkButton } from '../common/elements/LinkButton';
import { ReactComponent as TrashImg } from '../../images/trash.svg';
import { forMediumTabletLandscapeUp } from '../../utils/mediaqueries';
import { TYPES } from './StudentPopup';

export class StudentDetailsEditableField extends React.Component {
	state = {
		errorMessage: '',
		duplicateDisable: false,
	};

	onChange = e => {
		const { type } = this.props;
		const value = e.value;
		let name = '';

		if (type === TYPES.REFERREDACCOUNT) name = 'newReferredAccount';
		if (type === TYPES.PERSONALIDENTITYNUMBER) name = 'newPersonalIdentityNumber';
		if (type === TYPES.NAME) name = 'newName';

		this.setState({ duplicateDisable: false });

		this.props.handleChange({ name, value });
	};

	onEnableEdit = () => {
		this.props.enableEdit(this.props.type);
	};

	onSubmit = async () => {
		const {
			value,
			type,
			currentValue,
		} = this.props;

		if (type === TYPES.REFERREDACCOUNT) {
			let formattedNumber = formatInternationalPhoneNumber(value);

			let isSameNumber = formattedNumber === currentValue;

			if (
				Validation.mobile.test(formattedNumber) &&
				!isSameNumber
			) {
				this.props.handleSubmit({ type, value: formattedNumber });
				this.props.onBackClick();
			} else {
				let errorMessage = '';
				if (!Validation.mobile.test(formattedNumber)) {
					errorMessage += 'Ej giltigt telefonnummer';
				}

				let duplicateDisable = false;
				if (isSameNumber) {
					errorMessage = 'Numret är redan satt på ditt konto';
					duplicateDisable = true;
				}

				this.setState({ errorMessage, duplicateDisable });
			}
		} else if (type === TYPES.NAME) {
			this.props.handleSubmit({ type, value });
			this.props.onBackClick();
		} else if (type === TYPES.PERSONALIDENTITYNUMBER) {
			if (Validation.personalIdentityNumber.test(value)) {
				this.props.handleSubmit({ type, value });
				this.props.onBackClick();
			} else {
				this.setState({ errorMessage: 'Kontrollera personnumret.' });
			}
		}
	};

	onBackClick = () => {
		this.setState({
			errorMessage: '',
			duplicateDisable: false,
		});

		this.props.onBackClick();
	}

	renderTitle = () => {
		let srOnly = '';

		switch (this.props.type) {
			case TYPES.NAME:
				srOnly = 'Namn';
				break;
			case TYPES.PERSONALIDENTITYNUMBER:
				srOnly = 'Personnummer';
				break;
			case TYPES.REFERREDACCOUNT:
				srOnly = 'Reseapp';
				break;
			default:
				srOnly = '';
				break;
		}

		return (
			<>
				<span>Ändra</span>
				<span className="sr-only">{srOnly}</span>
			</>
		);
	}

	renderValue = (value) => {
		let className = {};
		let formattedValue = value;

		switch (this.props.type) {
			case TYPES.REFERREDACCOUNT:
				className = {
					marginLeft: 'auto',
				};
				formattedValue = `+${formattedValue}`
				break;
			case TYPES.NAME:
				formattedValue = <h2>{formattedValue}</h2>
				break;
			default:
				className = {};
				break;
		}

		return (
			<CurrentValueField style={className}>
				{formattedValue}
			</CurrentValueField>
		);
	}

	render() {
		const {
			currentValue,
			isEditable,
			type,
			changeDisabled,
			submitDisabled,
			label,
		} = this.props;

		const {
			errorMessage,
			duplicateDisable,
		} = this.state;

		return (
			<>
				<RowWrapper hasMargin={isEditable}>
					{isEditable ? (
						<>
							<FlexContainer>
								{type === TYPES.NAME && (
									<InputEditableField
										type="text"
										value={currentValue}
										handleChange={this.onChange}
										id={type + 'LoginDetailsInput'}
										disabled={false}
										errorMessage={errorMessage || "Ej giltigt namn"}
										isError={Boolean(errorMessage)}
										isFullWidth
										helperText="Ange förnamn och efternamn"
									/>
								)}

								{type === TYPES.PERSONALIDENTITYNUMBER && (
									<InputEditableField
										type="text"
										value={currentValue}
										handleChange={this.onChange}
										id={type + 'LoginDetailsInput'}
										disabled={false}
										errorMessage={errorMessage || "Ej giltigt personnummer"}
										isError={Boolean(errorMessage)}
										isFullWidth
										hasFloatingLabel={false}
									/>
								)}

								{type === TYPES.REFERREDACCOUNT && (
									<InputEditableField
										type="text"
										value={currentValue}
										handleChange={this.onChange}
										id={type + 'LoginDetailsInput'}
										disabled={false}
										errorMessage={errorMessage || "Ej giltigt telefonnummer"}
										isError={Boolean(errorMessage)}
										isFullWidth
										helperText="Format: 46712345678"
									/>
								)}
							</FlexContainer>
						</>
					) : (
						<NotEditableWrapper>
							{label && <span><strong>{label}</strong></span>}

							{this.renderValue(currentValue)}

							{type === TYPES.REFERREDACCOUNT && (
								<TrashIcon
									className="svg-icon"
									role="button"
										onClick={this.props.handleShowRemove}
								/>
							)}
						</NotEditableWrapper>
					)}
					<ChangeButton
						onClick={isEditable ? this.onSubmit : this.onEnableEdit}
						disabled={
							(isEditable && submitDisabled) ||
							(!isEditable && changeDisabled) ||
							duplicateDisable
						}
					>
						{this.renderTitle()}
					</ChangeButton>
				</RowWrapper>

				{isEditable && (
					<BackButton onClick={this.onBackClick}>
						&lsaquo; Tillbaka
					</BackButton>
				)}
			</>
		);
	}
}

const BackButton = styled(LinkButton)`
	margin-bottom: 1em;
	display: block;
`;

const RowWrapper = styled.div`
	flex-direction: column;
	position: relative;
	
	${forMediumTabletLandscapeUp`
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: baseline;
		`}
`;

const NotEditableWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

const FlexContainer = styled.div`
	display: flex;
	flex-grow: 1;
`;

const EditableField = styled(TextInput)`
	margin-bottom: 10px;
	flex: 1;
	margin-right: 5px;
`;

const InputEditableField = styled(EditableField)`
	margin-bottom: 1rem;
	flex: 1;
	min-width: 55px;
	width: 100%;
`;

const CurrentValueField = styled.span`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 5px;

	h2 {
		margin: 0;
	}
`;

const ChangeButton = styled(NextButton)`
	white-space: nowrap;
	margin-bottom: 0;
	padding-inline: 0.8em;
	width: 100%;

	${forMediumTabletLandscapeUp`
		margin-left: 0.8em;
		width: auto;
	`}
`;

const TrashIcon = styled(TrashImg)`
	margin-left: 10px;

	&:hover {
		cursor: pointer;
	}
`;
