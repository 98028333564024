import React, { Component } from 'react';
import styled from 'styled-components';
import { CloseCardButton } from '../../common/blocks/CloseCardButton';
import { TextInput } from '../../common/elements/TextInput';
import { sanitizeCmsHtml } from '../../../texts';
import { PopUpBox, PopupContainer } from '../../common/elements/PopUpBox';
import { NextButton } from '../../common/elements/NextButton';
import { ConfirmationCard } from '../../common/blocks/ConfirmationCard';
import { Error } from '../../common/elements/Error';
import { connect } from 'react-redux';
import { actionCreators as groupActions } from '../../../reducer/groups';

class CreateGroupPopup extends Component {
	state = {
		groupName: '',
		confirmation: false,
		hasError: false,
	};

	createGroup = async () => {
		const { groupName } = this.state;

		try {
			await this.props.createGroup(groupName);
			this.setState({ confirmation: true });
		} catch {
			this.setState({ hasError: true });
		}
	};

	handleChange = e => {
		const groupName = e.value;
		this.setState({ groupName });
	};

	render() {
		const { groupName, confirmation, hasError } = this.state;
		const { closePopup } = this.props;

		if (confirmation) {
			return (
				<PopupContainer>
					<ConfirmationCard
						onClick={closePopup}
						title="Klart!"
						text="En ny grupp är nu skapad"
					/>
				</PopupContainer>
			);
		}

		return (
			<PopUpBox>
				<CloseCardButton onClick={closePopup} />
				<h2>Skapa ny grupp</h2>
				<InputWrapper>
					<InputHeader>Gruppnamn</InputHeader>
					<TextInput
						type="text"
						value={groupName}
						handleChange={this.handleChange}
						maxLength={20}
						placeholder="T.ex. VT / HT 2020"
					></TextInput>
				</InputWrapper>
				<InfoWrapper>
					<h5>Grupp</h5>
					<div
						dangerouslySetInnerHTML={sanitizeCmsHtml(
							this.props.texts.school.grouppage.creategroup.informationtext
						)}
					></div>
				</InfoWrapper>
				<ButtonWrapper>
					<CreateGroupButton onClick={this.createGroup} disabled={!groupName}>
						Spara
					</CreateGroupButton>
				</ButtonWrapper>
				{hasError && <Error />}
			</PopUpBox>
		);
	}
}

const InputWrapper = styled.div`
	span::after {
		content: '*';
		color: #d30808;
	}
	input {
		margin-left: 10px;
	}
`;

const InfoWrapper = styled.div`
	text-align: left;

	h5 {
		padding-left: 20px;
	}
	ul {
		list-style-type: disc;
	}
`;

const CreateGroupButton = styled(NextButton)`
	max-width: 250px;
	width: 100%;
	margin-top: 1em;
	margin-bottom: 1em;
`;
const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

const InputHeader = styled.span`
	margin-block: 1.25rem;
	display: block;
`

export default connect(store => ({}), { ...groupActions })(CreateGroupPopup);
