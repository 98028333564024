import React from 'react';
import styled from 'styled-components';
import { ThemedNavLink } from './ThemedNavLink';
import { ReactComponent as ChevronRight } from '../../../images/chevron-right.svg';
import { PRIVATE } from '../../../Paths';

export const RegisterTravelCardLink = props => {
	return (
		<ThemedNavLink exact to={PRIVATE.REGISTER_CARD} {...props}>
			Registrera resekort{' '}
			<ChevronRightIcon
				className="svg-icon"
				aria-hidden="true"
				focusable="false"
			/>
		</ThemedNavLink>
	);
};

const ChevronRightIcon = styled(ChevronRight)`
	fill: none;
	stroke: ${props => props.theme.link_color};
`;
