import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ThemedNavLink = props => {
	return (
		<Link {...props}>
			{props.children}
		</Link>
	);
};


const Link = styled(NavLink)`
	color: ${props => props.theme.link_color};

	:hover {
		color: ${props => props.theme.link_hover_color};
	}
`
