import React from 'react';
import { Dropdown } from '../elements/Dropdown';
import styled from 'styled-components';

export class TicketTypeSelector extends React.Component {
	state = { ticketType: '' };

	selectType = event => {
		const { target } = event;
		this.setState({ ticketType: target.value });
	};

	onSelect = items => item => {
		const previousSelectedItem = items.find(i => i.selected);
		if (previousSelectedItem) {
			previousSelectedItem.selected = false;
		}
		items.find(i => i.id === item.id).selected = true;

		this.props.onSelectTicket(item);
	};

	render() {
		const { types = [], texts } = this.props;

		if (types.length === 0) {
			return (
				<>
					<H2>{texts.private.buyticketpage.tickettypeoptions.tickettype}</H2>

					<Dropdown
						id="TicketTypeDropdown"
						disabled={true}
						onSelect={() => {}}
						items={[]}
						renderSelectedItem={() => (
							<p>Välj resa för att se biljettyper</p>
						)}
						renderItem={() => {}}
					/>
				</>
			);
		}

		return (
			<>
				<H2>
					{this.props.texts.private.buyticketpage.tickettypeoptions.tickettype}
				</H2>

				<Dropdown
					id="TicketTypeDropdown"
					onSelect={this.onSelect(types)}
					items={types}
					renderItem={item => (
						<>
							<p style={{ margin: '0' }}>{item.title}</p>
							<div style={{ fontSize: '.8em' }}>
								<i>{item.description}</i>
							</div>
						</>
					)}
				/>
			</>
		);
	}
}

export const H2 = styled.h2`
	font-size: 24px;
`;

