import React from 'react';
import styled from 'styled-components';
import { forSmallPhoneOnly } from '../../../utils/mediaqueries';

export function CardWide(props) {
	return <CardContainer {...props}>{props.children}</CardContainer>;
}

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1em 2em;
	border-radius: 8px;
	background-color: ${props => props.theme.white};
	box-shadow: 0 27px 36px 12px rgba(0, 0, 0, 0.22);
	text-align: center;
	position: relative; /* in order to position close button */
	max-width: 570px;
	width: 100%;
	padding: 2em;
	margin: 0;

	${forSmallPhoneOnly`
		box-shadow: none;
		border: none;
		background: none;
		padding: 0;
	`}
`;
