import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducer/cms';
import {
	getCmsTexts,
	hasCmsTexts,
	isLoadingCmsTexts,
	hasCmsLoadingError,
} from '../reducer';
import { TextContext } from '../texts/context';
import { cmsConfig } from '../config';
import { LoadPage } from '../components/common/LoadPage';

function withTextContext(WrappedComponent, showLoadPage = true) {
	return class extends React.Component {
		render() {
			return (
				<TextContext.Consumer>
					{defaultTexts => (
						<LoadCmsTexts
							defaultTexts={defaultTexts}
							{...this.props}
							WrappedComponent={WrappedComponent}
							showLoadPage={showLoadPage}
						/>
					)}
				</TextContext.Consumer>
			);
		}
	};
}

class LoadCmsTexts extends React.Component {
	cmsTextsLoaded = () => {
		const { hasCmsTexts, isLoadingCmsTexts, defaultTexts } = this.props;
		
		if (!hasCmsTexts) {
			if (!isLoadingCmsTexts) this.props.loadCmsTexts(defaultTexts);
			return false;
		}
		return true;
	};

	render() {
		const {
			cmsTexts,
			defaultTexts,
			WrappedComponent,
			hasCmsTexts,
			showLoadPage,
		} = this.props;

		const texts = hasCmsTexts ? cmsTexts : defaultTexts;		
		
		if (!cmsConfig.loadCmsTexts)
				return (
					<TextContext.Consumer>
						{defaultTexts => (
							<WrappedComponent {...this.props} texts={defaultTexts} />
						)}
					</TextContext.Consumer>
				);

		if (!this.cmsTextsLoaded()) {
			return showLoadPage ? <LoadPage /> : null;
		}

		return <WrappedComponent {...this.props} texts={texts} />;
	}
}

function mapStateToProps(store) {
	return {
		cmsTexts: getCmsTexts(store),
		hasCmsTexts: hasCmsTexts(store),
		isLoadingCmsTexts: isLoadingCmsTexts(store),
		hasCmsLoadingError: hasCmsLoadingError(store),
	};
}

export default compose(
	connect(
		mapStateToProps,
		{ ...actionCreators }
	),
	withTextContext
);
