import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { SCHOOL } from '../../Paths';
import { Main } from '../common/blocks/Main';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { push } from 'connected-react-router';
import {
	actionCreators as studentActions,
	getStudentTicketText,
	isLoadingStudentTickets,
	getStudentBearers,
	getReferredAccount,
	referredAccountsLoaded,
	isLoadingReferredAccounts,
	getAllTicketVendorIds,
} from '../../reducer/students';
import { actionCreators as groupActions } from '../../reducer/groups';
import {
	getStudents,
	getAllGroups,
	isLoadingStudents,
	isLoadingStudentBearers,
} from '../../reducer';
import isEmpty from 'lodash/isEmpty';
import StudentPopup from './StudentPopup';
import { GroupTable } from './GroupPage';
import { displayTravelCardInList } from './GroupDetailsPage';
import { Loading } from '../common/blocks/Loading';
import { TextInput } from '../common/elements/TextInput';
import { ReactComponent as Search } from '../../images/search.svg';
import debounce from 'lodash/debounce';
import { TYPES } from '../../reducer/bearer';
import { Pagination } from '../common/elements/Pagination';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class StudentPage extends Component {
	state = {
		showPopup: false,
		popupStudentData: null,
		searchTerm: '',
		studentBearers: {},
		currentPage: 0,
		pageSize: 30,
	};

	componentDidMount() {
		this.loadData();
	}

	loadData = async () => {
		const { groups, hasReferredAccounts, students } = this.props;
		let bearers;

		if (isEmpty(students)) {
			await this.props.getStudents();
			const studentIds = Object.keys(this.props.students);
			this.props.getBearersForAllStudents();
			await this.props.getTicketsForStudentIds(studentIds);
		}

		if (!hasReferredAccounts) {
			await this.props.getAllReferredAccounts();
			await this.props.getAllReferredAccountTickets();
		}

		if (isEmpty(groups)) {
			await this.props.getGroups();
		}
		this.setState({ studentBearers: bearers });

		const vendorIds = this.props.getAllTicketVendorIds || [];
		vendorIds.forEach(vendorId => this.props.getSchoolByVendorId(vendorId));
	};

	showStudentDetails = student => () =>
		this.setState(state => ({
			showPopup: !state.showPopup,
			popupStudentData: student.travellerId,
		}));

	closePopup = () =>
		this.setState(state => ({
			showPopup: !state.showPopup,
			popupStudentData: null,
		}));

	displayGroupInList = groups => {
		const names = (groups || []).map(g => g.groupName);

		if (names.length > 0) {
			return `${names[0]} ${names.length > 1 ? '+' : ''}`;
		}

		return '';
	};

	handleChange = debounce(target => {
		let searchValue = target.value.trim();
		this.setState({ searchTerm: searchValue });
	}, 400);

	byNamePidAndSerial = student => {
		const { searchTerm } = this.state;
		const query = searchTerm.toLowerCase();
		const bearers = this.props.selectStudentBearers(student.travellerId);

		return (
			student.firstName.toLowerCase().includes(query) ||
			student.lastName.toLowerCase().includes(query) ||
			student.personalIdentityNumber.toLowerCase().includes(query) ||
			student.homeCounty.toLowerCase().includes(query) ||
			bearers.some(
				b =>
					b.type !== TYPES.SMARTPHONE && b.serial.toLowerCase().includes(query)
			)
		);
	};

	filterStudents = students => {
		const { searchTerm } = this.state;

		if (searchTerm.length > 1) {
			return students.filter(this.byNamePidAndSerial);
		} else {
			return students;
		}
	};

	handlePageChange = newPage => this.setState({ currentPage: newPage - 1 });

	render() {
		const {
			students,
			isLoadingStudents,
			isLoadingStudentBearers,
			isLoadingStudentTickets,
			selectStudentBearers,
			studentTicketText,
			getReferredAccount,
			isLoadingReferredAccounts,
			texts,
		} = this.props;
		const { showPopup, popupStudentData, currentPage, pageSize } = this.state;

		let sortedStudents = [];
		const studentList = Object.values(students);
		if (studentList.length > 0)
			sortedStudents = studentList.sort((a, b) =>
				a.lastName.localeCompare(b.lastName)
			);

		let filteredStudents = this.filterStudents(sortedStudents);
		let displayStudents = filteredStudents.slice(
			pageSize * currentPage,
			pageSize * currentPage + pageSize
		);
		return (
			<>
				<Banner bannerImg={texts.school.images.banners.grouppage}>
					<h2>Elever &amp; Grupper</h2>
					<p>Här hanteras elevinformation, skolkort och grupper</p>
				</Banner>
				<Main>
					<LinkContainer>
						<Link to={SCHOOL.GROUPS} exact activeClassName="active">
							Grupper
						</Link>
						<Link to={SCHOOL.STUDENTS} exact activeClassName="active">
							Elever
						</Link>
					</LinkContainer>

					{isLoadingStudents && (
						<Loading text="Laddar alla elever. Detta kan dröja några sekunder..." />
					)}
					{(isLoadingStudentBearers ||
						isLoadingStudentTickets ||
						isLoadingReferredAccounts) && (
						<Loading text="Laddar biljetter, resekort och kopplade konton..." />
					)}

					<StudentCountContainer>
						<SearchFieldContainer>
							<SearchIcon aria-hidden="true" focusable="false" />
							<SearchStudent
								type="text"
								value={this.state.searchTerm}
								handleChange={this.handleChange}
								placeholder="Namn, personnummer, kortnummer eller hemkommun"
							/>
						</SearchFieldContainer>
						<br />
						<strong>Antal elever: </strong>
						{filteredStudents.length !== studentList.length ? (
							<span>
								{filteredStudents.length} av {studentList.length} st
							</span>
						) : (
							<span>{studentList.length} st</span>
						)}
					</StudentCountContainer>
					<GroupTable>
						<thead>
							<tr>
								<th />
								<th>Efternamn</th>
								<th>Förnamn</th>
								<th>Personnummer</th>
								<th>Hemkommun</th>
								<th>Biljetter</th>
								<th>Reseapp</th>
								<th>Resekort</th>
								<th>Grupp</th>
							</tr>
						</thead>

						<tbody>
							{displayStudents.map((student, idx) => {
								let referredAccount = getReferredAccount(student.travellerId);
								return (
									<tr key={idx} onClick={this.showStudentDetails(student)}>
										<td>
											<ProfileIcon aria-hidden="true" focusable="false" />
										</td>
										<td>{student.lastName}</td>
										<td>{student.firstName}</td>
										<td>{student.personalIdentityNumber}</td>
										<td>{student.homeCounty ? student.homeCounty : '-'}</td>
										<td>{studentTicketText(student.travellerId)}</td>
										<td>
											{referredAccount
												? referredAccount.referredAccountPhoneNumber
													? `+${referredAccount.referredAccountPhoneNumber}`
													: 'Ja'
												: 'Nej'}
										</td>
										<td className="nowrap">
											{displayTravelCardInList(
												selectStudentBearers(student.travellerId)
											)}
										</td>
										<td>{this.displayGroupInList(student.groupInformation)}</td>
									</tr>
								);
							})}
						</tbody>
					</GroupTable>
					<Pagination
						itemCount={filteredStudents.length}
						itemsPerPage={pageSize}
						onPageChange={this.handlePageChange}
					/>
				</Main>
				{showPopup && (
					<StudentPopup
						closePopup={this.closePopup}
						travellerId={popupStudentData}
					/>
				)}
			</>
		);
	}
}

const SearchFieldContainer = styled.div`
	position: relative;
	display: inline-block;
`;

const SearchIcon = styled(Search)`
	position: absolute;
	top: 0.5em;
	left: 1em;
	stroke: ${props => props.theme.light_grey};
`;

const SearchStudent = styled(TextInput)`
	width: 405px;
	margin-bottom: 1em;
	font-size: 14px;
	font-style: italic;
	padding-left: 3.5em;
`;

const Link = styled(NavLink)`
	color: black;
	text-decoration: none;

	&:hover,
	&:focus,
	&.active {
		color: black;
		text-decoration: none;
		border-bottom: solid 2px;
	}

	& + & {
		margin-left: 2em;
	}
`;

const LinkContainer = styled.div`
	text-align: center;
	padding: 2em;
`;

const StudentCountContainer = styled.div`
	text-align: center;
	padding-bottom: 1em;
`;

export default withTextContext(
	connect(
		store => ({
			students: getStudents(store),
			groups: getAllGroups(store),
			isLoadingStudents: isLoadingStudents(store),
			selectStudentBearers: getStudentBearers(store),
			isLoadingStudentBearers: isLoadingStudentBearers(store),
			isLoadingStudentTickets: isLoadingStudentTickets(store),
			studentTicketText: getStudentTicketText(store),
			getReferredAccount: getReferredAccount(store),
			hasReferredAccounts: referredAccountsLoaded(store),
			isLoadingReferredAccounts: isLoadingReferredAccounts(store),
			getAllTicketVendorIds: getAllTicketVendorIds(store),
		}),
		{ ...studentActions, ...groupActions, push }
	)(StudentPage)
);
