import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../../../images/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../../images/chevron-left.svg';
import { ReactComponent as Time } from '../../../images/time.svg';
import { ReactComponent as CalendarIcon } from '../../../images/calendar.svg';
import getDaysInMonth from 'date-fns/get_days_in_month';
import {
	addMonths,
	subMonths,
	setDate,
	getDay,
	endOfMonth,
	setHours,
	setMinutes,
	setYear,
	setMonth,
	isSameDay,
	isSameMonth,
} from 'date-fns';
import { regexToInputPattern, Validation } from '../../../utils/validation';
import { isDeviceWidth } from '../../../utils/isDeviceWidth';
import { formatISODate, formatISOTime } from '../../../utils/formatting';
import { BREAKPOINTS } from '../../../utils/mediaqueries';

export class Calendar extends Component {
	state = {
		expanded: false,
		manualDateInput: formatISODate(new Date()),
		timeInput: formatISOTime(new Date()),
		calendarDate: new Date(),
	};

	weekDays = ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön'];

	monthNames = [
		'Januari',
		'Februari',
		'Mars',
		'April',
		'Maj',
		'Juni',
		'Juli',
		'Augusti',
		'September',
		'Oktober',
		'November',
		'December',
	];

	componentDidUpdate(prevProps) {
		if (this.props.isOpen !== prevProps.isOpen) {
			this.setState({ expanded: this.props.isOpen });
		}
	}

	calendarToggle = () => {
		this.setState(
			state => ({
				expanded: !state.expanded,
			}),
			() => this.props.onToggle(this.state.expanded)
		);
	};

	handleChange = ({ target }) => {
		if (!target.checkValidity()) return;

		this.setState({ [target.id]: target.value });
	};

	handleTimeChange = ({ target }) => {
		if (!target.checkValidity()) return;

		this.setState({ [target.id]: target.value });
	};

	setInputTime = ({ closeCalendar = false }) => ({ target }) => {
		const { calendarDate } = this.state;

		let newTime = target.value || formatISOTime(calendarDate);

		const { hours, minutes } = this.parseTime(newTime);

		let newDate = setHours(calendarDate, parseInt(hours, 10));
		newDate = setMinutes(newDate, parseInt(minutes, 10));

		this.setDateState({
			calendarDate: newDate,
			timeInput: formatISOTime(newDate),
			...(closeCalendar && { expanded: false }),
		});
	};

	setDateState = state => {
		this.setState(state, () => this.props.onSelectDate(state.calendarDate));
	};

	parseTime = input => {
		const result = Validation.time.exec(input);
		let hours = result[1];
		let minutes = result[4] || '00';

		if (minutes.length === 1) {
			minutes += '0';
		}

		return {
			hours,
			minutes,
		};
	};

	setInputDate = ({ closeCalendar = false }) => ({ target }) => {
		let { calendarDate } = this.state;

		let newDate = target.value || formatISODate(calendarDate);

		const { year, month, day } = this.parseDate(newDate);

		let newCalendarDate = setYear(calendarDate, parseInt(year, 10));
		newCalendarDate = setMonth(newCalendarDate, parseInt(month, 10) - 1);
		newCalendarDate = setDate(newCalendarDate, parseInt(day, 10));

		this.setDateState({
			calendarDate: newCalendarDate,
			manualDateInput: formatISODate(newCalendarDate),
			...(closeCalendar && { expanded: false }),
		});
	};

	parseDate = input => {
		const result = Validation.date.exec(input);
		let year = result[1].padEnd(4, '0');
		let month = result[2] || 1;
		let day = result[5] || 1;

		return {
			year,
			month,
			day,
		};
	};

	renderWeekdays = () => {
		return this.weekDays.map(day => {
			return <WeekDayNames key={day}>{day}</WeekDayNames>;
		});
	};

	setNextMonth = () => {
		let { calendarDate } = this.state;

		const newDate = addMonths(calendarDate, 1);
		this.setDateState({ calendarDate: newDate });
	};

	setPreviousMonth = () => {
		const { calendarDate } = this.state;

		const newDate = subMonths(calendarDate, 1);
		this.setDateState({ calendarDate: newDate });
	};

	renderDays = () => {
		const { calendarDate } = this.state;
		let daysInMonth = getDaysInMonth(calendarDate);

		let calendarDays = [];
		for (let d = 1; d <= daysInMonth; d++) {
			calendarDays.push(this.renderDay(setDate(calendarDate, d)));
		}
		return calendarDays;
	};

	renderDay = date => {
		const { calendarDate } = this.state;
		const day = date.getDate();

		if (isSameDay(date, calendarDate)) {
			return (
				<SelectedDay
					aria-pressed={true}
					key={day}
					onClick={this.selectDate(date)}
				>
					{day}
				</SelectedDay>
			);
		}

		if (isSameMonth(date, calendarDate)) {
			return (
				<Day aria-pressed={false} key={day} onClick={this.selectDate(date)}>
					{day}
				</Day>
			);
		}

		return (
			<DayFromPreviousAndNextMonth
				aria-pressed={false}
				key={day}
				onClick={this.selectDate(date)}
			>
				{day}
			</DayFromPreviousAndNextMonth>
		);
	};

	selectDate = date => e => {
		this.setDateState({
			manualDateInput: formatISODate(date),
			calendarDate: date,
			expanded: false,
		});
	};

	renderSortedDays = () => {
		let renderLastDayOfPrevMonth = this.renderLastDaysPrevMonth();
		let renderFirstDaysNextMonth = this.renderFirstDaysNextMonth();
		let renderCurrentDaysInMont = this.renderDays();

		let rows = [];
		let cells = [];

		let totalSlots = [
			...renderLastDayOfPrevMonth,
			...renderCurrentDaysInMont,
			...renderFirstDaysNextMonth,
		];

		totalSlots.forEach((row, i) => {
			if (i % 7 !== 0) {
				cells.push(row);
			} else {
				let insertRow = cells.slice();
				rows.push(insertRow);
				cells = [];
				cells.push(row);
			}
			if (i === totalSlots.length - 1) {
				let insertRow = cells.slice();
				rows.push(insertRow);
			}
		});

		return rows.map((d, i) => <Week key={i}>{d}</Week>);
	};

	getDay = date => {
		let dayOfWeek = getDay(date) - 1;

		if (dayOfWeek < 0) dayOfWeek = 6;

		return dayOfWeek;
	};

	getMonthName = () => this.monthNames[this.state.calendarDate.getMonth()];

	renderLastDaysPrevMonth = () => {
		let { calendarDate } = this.state;

		const noOfDaysToShow = this.getDay(setDate(calendarDate, 1));
		const prevMonth = subMonths(calendarDate, 1);
		const daysInPrevMonth = getDaysInMonth(prevMonth);
		const start = daysInPrevMonth - (noOfDaysToShow - 1);

		const days = Array.from({ length: noOfDaysToShow }, (x, i) => i + start); // -> [29,30,31]

		return days.map(d => this.renderDay(setDate(prevMonth, d)));
	};

	renderFirstDaysNextMonth = () => {
		let { calendarDate } = this.state;

		let noOfDaysToShow = 6 - this.getDay(endOfMonth(calendarDate));
		const nextMonth = addMonths(calendarDate, 1);
		const start = 1;

		const days = Array.from({ length: noOfDaysToShow }, (x, i) => i + start); // -> [1,2,3]

		return days.map(d => this.renderDay(setDate(nextMonth, d)));
	};

	getDateInputType = () =>
		isDeviceWidth(BREAKPOINTS.TABLET_PORTRAIT) ? 'date' : 'text';
	getTimeInputType = () =>
		isDeviceWidth(BREAKPOINTS.TABLET_PORTRAIT) ? 'time' : 'text';

	handleKeyDownDate = e => {
		if (e.key === 'Enter') {
			this.setInputDate({ closeCalendar: true })(e);
			if (this.props.onSubmitForm) {
				setTimeout(() => this.props.onSubmitForm(), 300);
			}
		}
	};

	handleKeyDownTime = e => {
		if (e.key === 'Enter') {
			this.setInputTime({ closeCalendar: true })(e);
			if (this.props.onSubmitForm) {
				setTimeout(() => this.props.onSubmitForm(), 300);
			}
		}
	};

	render() {
		const { expanded, manualDateInput, timeInput, calendarDate } = this.state;
		const chosenDateInSwedish = this.state?.calendarDate && this.state.calendarDate.toLocaleDateString("sv-SE", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })


		return (
			<>
			<CalanderHelperText>Välj datum och klockslag</CalanderHelperText>
			<Container>
				<span className="sr-only" aria-live="assertive" aria-atomic="true">Datum: {chosenDateInSwedish}</span>
				<DatePickerHeader aria-expanded={this.props.isOpen ? true : false}>
					<ToggleButton onClick={this.calendarToggle}>
						<CalendarSvg />
					</ToggleButton>
					<DateInput
						onKeyPress={this.handleKeyDownDate}
						type={this.getDateInputType()}
						value={manualDateInput}
						onChange={this.handleChange}
						onBlur={this.setInputDate({ closeCalendar: false })}
						onClick={this.calendarToggle}
						id="manualDateInput"
						pattern={regexToInputPattern(Validation.date)}
					/>
					<Border />
					<TimeIcon />
					<TimeInput
						onKeyPress={this.handleKeyDownTime}
						type={this.getTimeInputType()}
						value={timeInput}
						onChange={this.handleTimeChange}
						onBlur={this.setInputTime({ closeCalendar: false })}
						id="timeInput"
						pattern={regexToInputPattern(Validation.time)}
					/>
				</DatePickerHeader>

				{expanded && !isDeviceWidth(BREAKPOINTS.TABLET_PORTRAIT) && (
					<>
						<CalendarHeader>
							<ChangeMonthButton onClick={this.setPreviousMonth}>
								<ChevronLeft />
							</ChangeMonthButton>
							<FullYear>
								{this.getMonthName()} {calendarDate.getFullYear()}
							</FullYear>
							<ChangeMonthButton onClick={this.setNextMonth}>
								<ChevronRight />
							</ChangeMonthButton>
						</CalendarHeader>
						<WeekDaysContainer>{this.renderWeekdays()}</WeekDaysContainer>
						<CalenderDays>{this.renderSortedDays()}</CalenderDays>
					</>
				)}
			</Container>
			</>
		);
	}
}

const CalendarSvg = styled(CalendarIcon)`
	width: 16px;
	height: 16px;
`;

const ToggleButton = styled.button`
	border: none;
	background: none;
	padding: 0;
	height: 17px;
	margin-right: 0.5em;
`;

const Week = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
`;

const Day = styled.button`
	padding: 10px;
	width: 100%;
	text-align: center;
	background: none;
	border: none;

	&:hover {
		background-color: ${props => props.theme.primary_color};
		color: ${props => props.theme.textOnPrimaryColor};
	}
`;

const SelectedDay = styled(Day)`
	background-color: ${props => props.theme.primary_color};
	color: ${props => props.theme.textOnPrimaryColor};
`;

const DayFromPreviousAndNextMonth = styled(Day)`
	color: darkgrey;
`;

const CalenderDays = styled.div`
	display: block;
`;

const CalendarHeader = styled.h5`
	margin: 0;
	display: flex;
	align-items: center;
`;

const ChangeMonthButton = styled.button`
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0.5em 1em;
`;

const FullYear = styled.div`
	width: 100%;
	text-align: center;
	font-weight: 600;
`;

const WeekDayNames = styled.div`
	padding-bottom: 10px;
	padding-top: 10px;
	width: 100%;
	text-align: center;
`;

const WeekDaysContainer = styled.div`
	display: flex;
	justify-content: space-around;
`;

const DatePickerHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 0.75em 1em;
`;

const DateInput = styled.input`
	border: none;
	background: transparent;
	width: 100%;
	font-size: 16px;
`;

const TimeIcon = styled(Time)`
	width: 15px;
	flex-shrink: 0;
	margin: 0 0.5em 0 0.625em;
`;

const TimeInput = styled.input`
	border: none;
	width: 55px;
	font-size: 16px;
	text-align: center;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;

	&[type='time'] {
		width: 155px;
	}
`;

const Border = styled.span`
	height: 20px;
	border-left: 2px solid #dbdbd7;
`;

const Container = styled.div`
	border: solid 1px #aaa;
	margin: 0.8em 0;
	border-radius: 5px;
	background-color: white;
`;

const CalanderHelperText = styled.span`
	display: flex;
`