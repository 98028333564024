import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CloseCardButton } from '../common/blocks/CloseCardButton';
import { Label } from '../common/elements/Label';
import { TextInput } from '../common/elements/TextInput';
import { Dropdown } from '../common/elements/Dropdown';
import { Validation } from '../../utils/validation';
import { PopUpBox, PopupContainer } from '../common/elements/PopUpBox';
import { NextButton } from '../common/elements/NextButton';
import {
	actionCreators as adminActions,
	isLoadingCreateAdmin,
} from '../../reducer/school/admins';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { Loading } from '../common/blocks/Loading';
import { isEmpty } from 'lodash';
import { ErrorMessageHandler } from '../common/blocks/ErrorMessageHandler';

class CreateAdminPopup extends Component {
	state = {
		name: '',
		email: '',
		phoneNumber: '',
		selectedRole: null,
		personalIdentityNumber: '',
		showConfirmation: false,
		showError: false,
		errorMessage: '',
	};

	createAdmin = async () => {
		const {
			name,
			email,
			phoneNumber,
			selectedRole,
			personalIdentityNumber,
		} = this.state;

		try {
			await this.props.createAdmin({
				name,
				email,
				phoneNumber,
				personalIdentityNumber,
				Schools:[{
					SchoolId: null,
					SchoolName: null,
					RoleId: selectedRole.id.toString(),
					RoleName: selectedRole.name,
				}]
			});
			this.setState({ showConfirmation: true });
		} catch (error) {
			this.setState({ showError: true, errorMessage: error.message });
		}
	};

	onSelect = item => this.setState({ selectedRole: item });

	handleChange = target => {
		this.setState({
			[target.id]: target.value,
			showError: false,
			[`${target.id}Valid`]: this.isFieldValid(target.id, target.value),
		});
	};

	handleBlur = ({ target }) =>
		this.setState({
			[`${target.id}Valid`]: this.isFieldValid(target.id, target.value),
		});

	validators = {
		email: value => Validation.email.test(value),
		name: value => value.length > 1,
		phoneNumber: value =>
			value.length > 1 &&
			value.length <= 15 &&
			Validation.onlyDigits.test(value),
		personalIdentityNumber: value =>
			value.length === 12 && Validation.onlyDigits.test(value),
	};

	isFieldValid = (field, value) => this.validators[field](value);

	render() {
		const {
			nameValid,
			phoneNumberValid,
			emailValid,
			personalIdentityNumberValid,
			showConfirmation,
			showError,
			errorMessage,
			email,
			name,
			personalIdentityNumber,
			phoneNumber,
		} = this.state;
		const { adminRoles, closePopup, isLoading } = this.props;

		if (showConfirmation) {
			return (
				<PopupContainer>
					<ConfirmationCard
						onClick={closePopup}
						title="Klart!"
						text="En ny administratör är nu skapad"
					/>
				</PopupContainer>
			);
		}

		return (
			<PopUpBox>
				<CloseCardButton onClick={closePopup} />
				<h2>Skapa ny administratör</h2>
				<div className="form-group" style={{ textAlign: 'left' }}>
					<Label htmlFor="name">För- och efternamn*</Label>
					<TextInputField
						type="text"
						value={name}
						handleChange={this.handleChange}
						className="form-control"
						id="name"
						placeholder="Förnamn Efternamn"
					/>
					{typeof nameValid !== 'undefined' && !nameValid && (
						<ErrorMessage>
							För- och efternamnet är inte rätt ifyllt
						</ErrorMessage>
					)}
				</div>
				<div className="form-group" style={{ textAlign: 'left' }}>
					<Label htmlFor="email">E-post </Label>{' '}
					<TextInputField
						type="email"
						value={email}
						handleChange={this.handleChange}
						className="form-control"
						id="email"
						placeholder="epost@exempel.com"
						onBlur={this.handleBlur}
					/>
					{!isEmpty(email) &&
						typeof emailValid !== 'undefined' &&
						!emailValid && <ErrorMessage>Ange korrekt e-post</ErrorMessage>}
				</div>
				<div className="form-group" style={{ textAlign: 'left' }}>
					<Label htmlFor="personalIdentityNumber">Personnummer* </Label>{' '}
					<TextInputField
						type="number"
						value={personalIdentityNumber}
						handleChange={this.handleChange}
						className="form-control"
						id="personalIdentityNumber"
						placeholder="ÅÅÅÅMMDDXXXX"
						onBlur={this.handleBlur}
					/>
					{typeof personalIdentityNumberValid !== 'undefined' &&
						!personalIdentityNumberValid && (
							<ErrorMessage>Ange korrekt personnummer</ErrorMessage>
						)}
				</div>
				<div className="form-group" style={{ textAlign: 'left' }}>
					<Label htmlFor="phoneNumber">Telefonnummer*</Label>
					<TextInputField
						type="tel"
						value={phoneNumber}
						handleChange={this.handleChange}
						className="form-control"
						id="phoneNumber"
						placeholder="070XXXXXXX"
					/>
					{typeof phoneNumberValid !== 'undefined' && !phoneNumberValid && (
						<ErrorMessage>Telefonnummer är inte rätt ifyllt</ErrorMessage>
					)}
				</div>
				<div className="form-group" style={{ textAlign: 'left' }}>
					<Label htmlFor="phoneNumber">Typ av admin</Label>
					<Dropdown
						id="adminType"
						items={adminRoles.map(r => ({ ...r, title: r.name }))}
						onSelect={this.onSelect}
					/>
				</div>
				{this.state.selectedRole &&
				this.state.selectedRole.name === 'Verksamhetsadministratör' ? (
					<ListContainer>
						<ul>
							<li>Administrera elever</li>
							<li>Köpa kort/biljetter</li>
						</ul>
					</ListContainer>
				) : (
					<ListContainer>
						<ul>
							<li>Administrera elever</li>
							<li>Köpa kort/biljetter</li>
							<li>Administrera användare</li>
						</ul>
					</ListContainer>
				)}

				{showError && ErrorMessageHandler(errorMessage)}
				{isLoading && <Loading />}

				<CreateAdminButton
					onClick={this.createAdmin}
					disabled={
						!nameValid ||
						!phoneNumberValid ||
						!personalIdentityNumberValid ||
						(!isEmpty(email) && !emailValid)
					}
				>
					Spara
				</CreateAdminButton>
			</PopUpBox>
		);
	}
}

const TextInputField = styled(TextInput)`
	width: 250px;
`;
const ListContainer = styled.div`
	width: 290px;
	text-align: left;
`;
const CreateAdminButton = styled(NextButton)`
	max-width: 250px;
	width: 100%;
	margin-top: 1em;
	margin-bottom: 1em;
`;

const ErrorMessage = styled.p`
	margin-top: 0.5em;
	color: crimson;
`;

export default connect(
	store => ({ isLoading: isLoadingCreateAdmin(store) }),
	{
		...adminActions,
	}
)(CreateAdminPopup);
