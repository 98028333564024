import React from 'react';
import styled from 'styled-components';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import { Label } from './../common/elements/Label';
import { Loading } from '../common/blocks/Loading';
import { Error } from './../common/elements/Error';
import { Dropdown } from '../common/elements/Dropdown';

export class RegisterCardForm extends React.Component {
	state = {
		name: '',
		serial: '',
		participantId: undefined,
	};

	handleChange = ({ target }) => this.setState({ [target.id]: target.value });

	registerBearer = () => {
		const { name, serial, participantId } = this.state;
		let formattedSerial = serial.replace(/\s/g, '');

		const newBearer = {
			name,
			serial: formattedSerial,
			pid: participantId,
		};
		this.props.registerBearer(newBearer);
	};

	hasNoData = () => this.state.name.length < 1 || this.state.serial.length < 1;

	onSelect = item => this.setState({	participantId: item.pid });

	render() {
		const { isLoading, showError, participants } = this.props;
		const { name, serial } = this.state;

		if (!participants) return null;
		
		return (
			<FieldContainer>
				<div className="form-group">
					<Label htmlFor="name">Välj namn på kortet*</Label>
					<RegisterInput
						type="text"
						value={name}
						onChange={this.handleChange}
						className="form-control"
						id="name"
						maxLength="30"
						placeholder="Välj namn på kortet"
					/>
				</div>

				<Label htmlFor="participant">Välj kortutgivare*</Label>
				<ParticipantDropdown
					id="participant"
					items={participants}
					onSelect={this.onSelect}
					renderSelectedItem={item => <span>{item.title}</span>}
					renderItem={item => <span>{item.title}</span>}
				/>

				<div className="form-group">
					<Label htmlFor="serial">Ange ditt kortnummer*</Label>
					<RegisterInput
						type="number"
						value={serial}
						onChange={this.handleChange}
						className="form-control"
						id="serial"
						maxLength="30"
						placeholder="Ange ditt kortnummer"
						aria-describedby="cardHelperText"
					/>
					<CardHelperText id="cardHelperText">Kortnumret ska innehålla 16 siffror</CardHelperText>
				</div>
				<SecondaryButton
					disabled={isLoading || this.hasNoData()}
					onClick={this.registerBearer}
				>
					Registrera
				</SecondaryButton>
				{isLoading && <Loading/>}
				{showError && <Error />}
			</FieldContainer>
		);
	}
}

const ParticipantDropdown = styled(Dropdown)`
	margin-bottom: 1em;
`;

const RegisterInput = styled.input`
	padding: 10px 10px;
	font-size: 16px;
	height: unset;
	border: 1px solid #aaa;
`;

const FieldContainer = styled.div`
	text-align: left;

	.form-control::placeholder {
		color: #000;
	}
`;

const CardHelperText = styled.span`
	font-size: 1.05rem;
`