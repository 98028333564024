import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { Main } from '../common/blocks/Main';
import { GroupTable } from './GroupPage';
import {
	actionCreators as orderActions,
	getBulkCardOrders,
	isLoadingBulkCardOrders,
	hasGetBulkCardOrderError,
	getBulkSpecificCardOrders,
	hasProcessingBulkCardOrders,
} from '../../reducer/school/orders';
import { formatDate } from '../../utils/formatting';
import { ReactComponent as TravelcardImg } from '../../images/card.svg';

import { Loading } from '../common/blocks/Loading';
import OrderHistoryNavigation from './blocks/OrderHistoryNavigation';
import { Error } from '../common/elements/Error';
import { SCHOOL } from '../../Paths';
import { Pagination } from '../common/elements/Pagination';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class OrderTravelcardHistoryPage extends Component {
	state = {
		currentPage: 0,
		pageSize: 30,
		waitingForReload: false,
	};

	BULK_ORDER_TYPES = {
		BULK: 'bulk',
		BULK_SPECIFIC: 'bulkSpecific',
	};

	componentDidMount() {
		this.props.getBulkCardOrders();
		this.maybeReloadTravelCardOrders(this.state.waitingForReload);
	}

	componentDidUpdate(prevProps, prevState) {
		this.maybeReloadTravelCardOrders(prevState.waitingForReload);
	}

	maybeReloadTravelCardOrders = (prevWaitingForReload) => {
		if (this.props.hasProcessingBulkCardOrders && !this.state.waitingForReload) {
			this.setState({ waitingForReload: true });

			setTimeout(() => this.props.getBulkCardOrders(), 10 * 60000);
		} else if (prevWaitingForReload) {
			this.setState({ waitingForReload: false });
		}
	}

	showOrderDetails = (orderId, orderType) => e => {
		if (orderType === this.BULK_ORDER_TYPES.BULK_SPECIFIC) {
			this.props.push(
				SCHOOL.ORDER_HISTORY_TRAVELCARD_DETAILS.replace(
					':travelcardOrderId',
					orderId
				)
			);
		} else if (orderType === this.BULK_ORDER_TYPES.BULK) {
			this.props.push(
				SCHOOL.ORDER_HISTORY_BULK_TRAVELCARD_DETAILS.replace(
					':orderId',
					orderId
				)
			);
		}
	};

	handlePageChange = newPage => this.setState({ currentPage: newPage - 1 });

	render() {
		const {
			bulkOrders,
			bulkSpecificOrders,
			isLoadingOrders,
			hasLoadingError,
			texts,
		} = this.props;

		const { currentPage, pageSize } = this.state;
		const bulkOrdersWithTravelCardRows = (bulkOrders || []).filter(
			o => o.cardOrderRows
		);
		const bulkSpecificOrdersWithTravelCardRows = (
			bulkSpecificOrders || []
		).filter(o => o.numberOfCardOrderRows);

		const ordersWithTravelCardRows = [
			...bulkOrdersWithTravelCardRows,
			...bulkSpecificOrdersWithTravelCardRows,
		];

		const sortedOrders = (ordersWithTravelCardRows || []).sort((a, b) =>
			b.createdDate.localeCompare(a.createdDate)
		);
		let displayOrders = sortedOrders.slice(
			pageSize * currentPage,
			pageSize * currentPage + pageSize
		);

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderhistory}
					showOnMobile={true}
				>
					<h2>Orderhistorik</h2>
					<p>Här visas ordrar på både resekort och biljetter</p>
				</Banner>
				<OrderHistoryNavigation />
				{isLoadingOrders && <Loading text="Laddar orderhistorik..." />}
				<Main>
					{hasLoadingError && <Error />}
					<Table>
						<thead>
							<tr>
								<th />
								<th>Skapad datum</th>
								<th>Antal resekort</th>
								<th>Skapad av</th>
								<th>Ordernummer</th>
								<th>Status</th>
							</tr>
						</thead>

						<tbody>
							{displayOrders.map(order => (
								<tr
									onClick={this.showOrderDetails(
										order.cardOrderId,
										order.orderType
									)}
									key={order.cardOrderId}
								>
									<td>
										<TravelcardIcon />
									</td>
									<td className="nowrap">{formatDate(order.createdDate)}</td>
									{order.orderType === this.BULK_ORDER_TYPES.BULK ? (
										<td>{order.numberOfCards}</td>
									) : (
										<td>{order.numberOfCardOrderRows}</td>
									)}
									<td>{order.createdBy}</td>
									<td className="nowrap">{order.orderNumber}</td>
									<td className="nowrap">{order.status}</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Pagination
						itemCount={ordersWithTravelCardRows.length}
						itemsPerPage={pageSize}
						onPageChange={this.handlePageChange}
					/>
				</Main>
			</>
		);
	}
}

const Table = styled(GroupTable)`
	th:first-of-type {
		min-width: 32px;
	}

	th:last-of-type {
		&:hover {
			cursor: pointer;
		}
	}

	th:last-of-type,
	td:last-of-type {
		text-align: center;
		label {
			float: none;
			margin: 0;
		}
	}
	.nowrap {
		white-space: nowrap;
	}
`;

const TravelcardIcon = styled(TravelcardImg)`
	width: 24px;
	height: 24px;
`;

export default withTextContext(
	connect(
		store => ({
			bulkOrders: getBulkCardOrders(store),
			bulkSpecificOrders: getBulkSpecificCardOrders(store),
			hasProcessingBulkCardOrders: hasProcessingBulkCardOrders(store),
			isLoadingOrders: isLoadingBulkCardOrders(store),
			hasLoadingError: hasGetBulkCardOrderError(store),
		}),
		{
			...orderActions,
			push,
		}
	)(OrderTravelcardHistoryPage)
);
