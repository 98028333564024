import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Main } from '../common/blocks/Main';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { ReactComponent as ExclamationCircle } from '../../images/exclamation-circle.svg';
import { ReactComponent as GroupIconSvg } from '../../images/icon-group.svg';
import { push } from 'connected-react-router';
import { actionCreators as groupActions } from '../../reducer/groups';
import {
	actionCreators as studentActions,
	getStudentTicketText,
	isLoadingStudentTickets,
	hasCreateStudentError,
	hasStudentBearers,
	getStudentBearers,
	isTravelCard,
	getReferredAccount,
	referredAccountsLoaded,
	isLoadingReferredAccounts,
	getAllTicketVendorIds,
} from '../../reducer/students';
import {
	getAllGroups,
	isLoadingGroups,
	getStudentsByIds,
	hasStudentsForGroup,
	isLoadingStudentBearers,
	isLoadingStudents,
	isImportingStudents,
	hasImportError,
	getImportErrorText,
	getImportedStudents,
	getStudentIdsInGroup,
} from '../../reducer';
import { actionCreators as schoolActions } from '../../reducer/school';
import { Loading } from '../common/blocks/Loading';
import { Info } from '../common/elements/Info';
import withTextContext from '../../utils/withTextContext';
import { PrimaryButton } from '../common/elements/PrimaryButton';
import { AddButton } from '../common/elements/AddButton';
import { RemoveButton } from '../common/elements/RemoveButton';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { SCHOOL } from '../../Paths';
import { formatDate, groupByFour } from '../../utils/formatting';
import StudentPopup from './StudentPopup';
import { GroupTable, ToolsContainer } from './GroupPage';
import { ImportStudentsPopup } from './group/ImportStudentsPopup';
import AddStudentPopup from './group/AddStudentPopup';
import AddTravelCardPopup from './group/AddTravelCardPopup';
import UpdateGroupNamePopup from './group/UpdateGroupNamePopup';
import RemoveGroupPopup from './group/RemoveGroupPopup';

export const displayTravelCardInList = bearers => {
	const serials = (bearers || [])
		.filter(isTravelCard)
		.map(b => groupByFour(b.serial));

	if (serials.length > 0) {
		return `${serials[0]} ${serials.length > 1 ? '+' : ''}`;
	}

	return '-';
};

class GroupDetailsPage extends Component {
	state = {
		showPopup: false,
		popupStudentData: null,
		showImportPopup: false,
		showAddStudentPopup: false,
		showAddBearerPopup: false,
		showEditNamePopup: false,
		showRemoveGroupPopup: false,
		showImportConfirmation: false,
		postImportStudents: [],
	};

	componentDidMount() {
		this.props.clearImportStudents();
		this.getGroupData();
	}

	getGroupData = async (reloadData = false) => {
		const {
			groups,
			match,
			hasStudentsForGroup,
			hasReferredAccounts,
		} = this.props;
		const { groupId } = match.params;

		if (isEmpty(groups) || reloadData) {
			await this.props.getGroups();
		}

		if (!hasStudentsForGroup(groupId) || reloadData) {
			const showLoadingAllStudents = false;
			await this.props.loadStudentsInGroup(groupId);
			this.props.getStudents(showLoadingAllStudents);
		}

		const studentIds = this.props.studentIdsInGroup(groupId);
		await this.props.getTicketsForStudentIds(studentIds);

		if (!hasReferredAccounts) {
			await this.props.getGroupReferredAccounts(groupId);
			await this.props.getGroupReferredAccountTickets(groupId);
		}

		this.props.getBearersForGroup(groupId);

		const vendorIds = this.props.getAllTicketVendorIds || [];
		vendorIds.forEach(vendorId => this.props.getSchoolByVendorId(vendorId));
	};

	showStudentDetails = student => () =>
		this.setState(state => ({
			showPopup: !state.showPopup,
			popupStudentData: student.travellerId,
		}));

	closePopup = () =>
		this.setState(state => ({
			showPopup: !state.showPopup,
			popupStudentData: null,
		}));

	toggleImportPopup = async () =>
		this.setState(
			{
				showImportPopup: !this.state.showImportPopup,
				showImportConfirmation: false,
			},
			await this.clearImportError()
		);

	toggleAddStudentPopup = () =>
		this.setState(state => ({
			showAddStudentPopup: !state.showAddStudentPopup,
		}));

	toggleAddBearerPopup = () =>
		this.setState(state => ({
			showAddBearerPopup: !state.showAddBearerPopup,
		}));

	toggleEditNamePopup = () =>
		this.setState(state => ({
			showEditNamePopup: !state.showEditNamePopup,
		}));

	toggleRemoveGroupPopup = () =>
		this.setState(state => ({
			showRemoveGroupPopup: !state.showRemoveGroupPopup,
		}));

	closeConfirmationCard = () =>
		this.setState({ showImportPopup: false, showImportConfirmation: false });

	clearImportError = async () => await this.props.clearImportError();

	importStudents = async file => {
		const { groupId } = this.props.match.params;
		await this.props.importStudents(groupId, file);

		if (!this.props.hasImportError) {
			const postImportStudents = this.props.getImportedStudents;

			const importStudentIds = (postImportStudents || []).map(s => s.travellerId);

			this.setState({ showImportConfirmation: true, postImportStudents });
			this.props.getTicketsForStudents(importStudentIds);
			this.props.clearReferredAccounts();
			this.getGroupData(true);
		}
	};

	importedStudentNotDeleted = student =>
		student &&
		student.removedAtImport !== undefined &&
		!student.removedAtImport;

	getGroupStudents = () => {
		const { getStudentsByIds, studentIdsInGroup, match } = this.props;
		const { postImportStudents } = this.state;
		const { groupId } = match.params;

		const studentIdsInGroupList = studentIdsInGroup(groupId);

		let studentsInGroup = getStudentsByIds(studentIdsInGroupList);

		let groupStudents = [];
		if (postImportStudents.length === 0) {
			if (studentsInGroup.length > 0)
				groupStudents = studentsInGroup.sort((a, b) =>
					a.lastName.localeCompare(b.lastName)
				);
		} else {
			let deletedStudents = postImportStudents.filter(
				s => s.removedAtImport !== undefined && !s.removedAtImport
			);

			let students = postImportStudents.filter(
				s =>
					s.removedAtImport === undefined ||
					(s.removedAtImport && s.importedSuccessfully)
			);

			if (deletedStudents.length > 0)
				deletedStudents.sort((a, b) => a.lastName.localeCompare(b.lastName));

			if (students.length > 0)
				students.sort((a, b) => a.lastName.localeCompare(b.lastName));

			groupStudents = [...deletedStudents, ...students];
		}
		return groupStudents;
	};

	redirectToOrderStudentTicket = () =>
		this.props.push(SCHOOL.ORDER_TICKETS, {
			group: this.props.groups[this.props.match.params.groupId],
		});

	redirectToOrderStudentBearer = () =>
		this.props.push(SCHOOL.ORDER_TRAVEL_CARD, {
			group: this.props.groups[this.props.match.params.groupId],
		});

	render() {
		const {
			isLoadingGroups,
			match,
			groups = [],
			selectStudentBearers,
			isLoadingStudentBearers,
			isLoadingStudentTickets,
			isLoadingStudents,
			texts,
			isImportingStudents,
			hasImportError,
			importErrorText,
			studentTicketText,
			getReferredAccount,
			isLoadingReferredAccounts,
		} = this.props;
		const {
			showPopup,
			popupStudentData,
			showImportPopup,
			showImportConfirmation,
			postImportStudents,
			showAddStudentPopup,
			showAddBearerPopup,
			showEditNamePopup,
			showRemoveGroupPopup,
		} = this.state;
		const { groupId } = match.params;

		const selectedGroup = groups[groupId];
		const isLoading =
			isLoadingStudentBearers || isLoadingStudents || isLoadingGroups;

		let groupStudents = this.getGroupStudents();
		let studentWithoutTravelCard = groupStudents.filter(student => {
			return !this.props.hasStudentBearers(student.travellerId) && student;
		});

		if (isLoadingGroups && !showImportConfirmation) {
			return (
				<Main>
					<Loading text="Laddar grupp..." />
				</Main>
			);
		}

		if (isEmpty(selectedGroup)) {
			return (
				<Main>
					<Info>Kan inte hitta vald grupp.</Info>
				</Main>
			);
		}

		return (
			<Main>
				<TopContainer>
					<TextWrapper>
						<ThemedNavLink exact to={SCHOOL.GROUPS}>
							Tillbaka
						</ThemedNavLink>
						<TeamName>
							<GroupIcon />
							{selectedGroup && <h3>{selectedGroup.name}</h3>}
						</TeamName>
						<GroupInfo>
							<span>Elever: </span>
							{selectedGroup && <span>{selectedGroup.studentCount} st</span>}
						</GroupInfo>
						<GroupInfo>
							<span>Skapad av: </span>
							{selectedGroup && <span>{selectedGroup.createdByAdminName}</span>}
						</GroupInfo>
						<GroupInfo>
							<span>Skapad datum: </span>
							{selectedGroup && (
								<span>{formatDate(selectedGroup.createdOn)}</span>
							)}
						</GroupInfo>
					</TextWrapper>
					<ButtonWrapper>
						<OrderButton
							onClick={this.redirectToOrderStudentTicket}
							disabled={isLoading || selectedGroup.studentCount < 1}
						>
							Beställ biljetter
						</OrderButton>
						<OrderButton
							disabled={isLoading || selectedGroup.studentCount < 1}
							onClick={this.redirectToOrderStudentBearer}
						>
							Beställ resekort
						</OrderButton>
					</ButtonWrapper>
				</TopContainer>
				<GroupName>
					{selectedGroup && <h3>Elever i {selectedGroup.name}</h3>}
				</GroupName>
				{(isLoadingStudentBearers ||
					isLoadingStudentTickets ||
					isLoadingReferredAccounts) && (
					<Loading text="Laddar biljetter, resekort och kopplade konton..." />
				)}
				{!isLoadingStudents && !isLoadingStudentBearers && (
					<ToolsContainer>
						<AddButton onClick={this.toggleImportPopup}>
							<AddButtontext>Importera elever från lista</AddButtontext>
						</AddButton>
						<AddButton onClick={this.toggleAddStudentPopup}>
							<AddButtontext>Lägg till elev</AddButtontext>
						</AddButton>
						<AddButton onClick={this.toggleAddBearerPopup}>
							<AddButtontext>Tilldela resekort manuellt</AddButtontext>
						</AddButton>
						<AddButton onClick={this.toggleEditNamePopup}>
							<AddButtontext>Ändra gruppnamn</AddButtontext>
						</AddButton>
						<RemoveButton onClick={this.toggleRemoveGroupPopup}>
							<AddButtontext>Radera grupp</AddButtontext>
						</RemoveButton>
					</ToolsContainer>
				)}
				<Table>
					<thead>
						<tr>
							<th />
							<th>Efternamn</th>
							<th>Förnamn</th>
							<th>Personnummer</th>
							<th>Hemkommun</th>
							<th>Biljetter</th>
							<th>Reseapp</th>
							<th>Resekort</th>
						</tr>
					</thead>

					<tbody>
						{groupStudents.map((student, idx) => {
							let referredAccount = getReferredAccount(student.travellerId);
							return (
								<tr
									key={idx}
									onClick={this.showStudentDetails(student)}
									className={
										this.importedStudentNotDeleted(student)
											? 'not-deleted'
											: null
									}
								>
									<td>
										{this.importedStudentNotDeleted(student) ? (
											<ExclamationCircle />
										) : (
											<ProfileIcon />
										)}
									</td>
									<td>{student.lastName}</td>
									<td>{student.firstName}</td>
									<td>{student.personalIdentityNumber}</td>
									<td>{student.homeCounty ? student.homeCounty : '-'}</td>
									<td>{studentTicketText(student.travellerId)}</td>
									<td>
										{referredAccount
											? referredAccount.referredAccountPhoneNumber
												? `+${referredAccount.referredAccountPhoneNumber}`
												: 'Ja'
											: 'Nej'}
									</td>
									<td className="nowrap">
										{displayTravelCardInList(
											selectStudentBearers(student.travellerId)
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				{showPopup && (
					<StudentPopup
						key={popupStudentData}
						closePopup={this.closePopup}
						travellerId={popupStudentData}
					/>
				)}
				{showImportPopup && (
					<ImportStudentsPopup
						closePopup={this.toggleImportPopup}
						texts={texts}
						isImportingStudents={isImportingStudents}
						hasImportError={hasImportError}
						importErrorText={importErrorText}
						onImportStudents={this.importStudents}
						onClearImportError={this.clearImportError}
						showImportConfirmation={showImportConfirmation}
						postImportStudents={postImportStudents}
					/>
				)}
				{showAddStudentPopup && (
					<AddStudentPopup
						closePopup={this.toggleAddStudentPopup}
						travellerId={popupStudentData}
						groupId={groupId}
					/>
				)}
				{showAddBearerPopup && (
					<AddTravelCardPopup
						closePopup={this.toggleAddBearerPopup}
						studentWithoutTravelCard={studentWithoutTravelCard}
					/>
				)}
				{showEditNamePopup && (
					<UpdateGroupNamePopup
						closePopup={this.toggleEditNamePopup}
						groupName={selectedGroup.name}
						groupId={groupId}
					/>
				)}
				{showRemoveGroupPopup && (
					<RemoveGroupPopup
						closePopup={this.toggleRemoveGroupPopup}
						groupStudents={groupStudents}
						group={selectedGroup}
					/>
				)}
			</Main>
		);
	}
}

const AddButtontext = styled.div`
	margin-right: 1em;
`;

const OrderButton = styled(PrimaryButton)`
	display: block;
	width: 200px;
	margin-bottom: 1em;
`;

const GroupIcon = styled(GroupIconSvg)`
	height: 30px;
	width: 30px;
	margin-top: 10px;
	margin-right: 10px;
`;

const TeamName = styled.div`
	align-items: center;
	display: flex;
	margin-top: -15px;
`;

const TopContainer = styled.div`
	display: flex;
`;

const ButtonWrapper = styled.div`
	float: left;
	margin-top: 1em;
	margin-left: 2em;
`;
const TextWrapper = styled.div`
	float: left;
`;

const GroupInfo = styled.div`
	margin-bottom: 0.5em;
	span:first-child {
		font-weight: 600;
	}
`;

const GroupName = styled.div`
	text-align: center;
	margin-bottom: 2em;
`;

const Table = styled(GroupTable)`
	.not-deleted {
		color: rgb(225, 0, 0, 1);
		font-style: italic;
	}
`;

export default withTextContext(
	connect(
		store => ({
			groups: getAllGroups(store),
			studentIdsInGroup: getStudentIdsInGroup(store),
			getStudentsByIds: getStudentsByIds(store),
			getImportedStudents: getImportedStudents(store),
			isLoadingGroups: isLoadingGroups(store),
			isLoading: isLoadingGroups(store),
			isImportingStudents: isImportingStudents(store),
			hasImportError: hasImportError(store),
			hasStudentsForGroup: hasStudentsForGroup(store),
			importErrorText: getImportErrorText(store),
			selectStudentBearers: getStudentBearers(store),
			isLoadingStudentBearers: isLoadingStudentBearers(store),
			isLoadingStudentTickets: isLoadingStudentTickets(store),
			isLoadingStudents: isLoadingStudents(store),
			studentTicketText: getStudentTicketText(store),
			hasCreateError: hasCreateStudentError(store),
			hasStudentBearers: hasStudentBearers(store),
			getReferredAccount: getReferredAccount(store),
			hasReferredAccounts: referredAccountsLoaded(store),
			isLoadingReferredAccounts: isLoadingReferredAccounts(store),
			getAllTicketVendorIds: getAllTicketVendorIds(store),
		}),
		{
			...studentActions,
			...groupActions,
			...schoolActions,
			push,
		}
	)(GroupDetailsPage)
);
