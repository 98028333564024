import React from 'react';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { TextInput } from '../elements/TextInput';

export class DataList extends React.Component {
	state = {
		value: '',
		suggestions: [],
	};

	getSuggestionValue = suggestion => suggestion.name;

	renderSuggestion = suggestion => {
		switch (suggestion.type) {
			case 'Address':
				return (
					<span>
						<span className="glyphicon glyphicon-home"></span> {suggestion.name}
					</span>
				);
			case 'PointOfInterest':
				return (
					<span>
						<span className="glyphicon glyphicon-map-marker"></span>{' '}
						{suggestion.name}
					</span>
				);
			case 'Coordinate':
				return (
					<span>
						<span className="glyphicon glyphicon-screenshot"></span>{' '}
						{suggestion.name}
					</span>
				);
			case 'StopArea':
			default:
				return (
					<span>
						<span className="glyphicon glyphicon-record"></span>{' '}
						{suggestion.name}
					</span>
				);
		}
	};

	loadSuggestions = value => {
		if (this.hasValue(value)) {
			this.props.loadSuggestions(value).then(suggestions => {
				this.setState({ suggestions });
			});
		}
	};

	hasValue = value => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength > 1;
	};

	onChange = (event, { newValue }) => {
		this.setState({
			value: newValue,
		});

		const matchingSuggestion = this.state.suggestions.find(
			s => s.name === newValue
		);

		if (matchingSuggestion) {
			this.props.onChange(matchingSuggestion);
		}
	};

	onSuggestionsFetchRequested = debounce(({ value }) => {
		this.loadSuggestions(value);
	}, 400);

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		});
	};

	onKeyPress = e => {
		let search = e.key === 'Enter';

		if (search && this.hasValue(e.target.value)) {
			this.props.onEnterPress(e.target.value);
		}
	};

	onBlur = e => {
		if (!e?.target) return
		if (this.hasValue(e.target.value)) {
			this.props.onBlur(e.target.value);
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({ value: this.props.value });
		}
	}

	render() {
		const { suggestions, value } = this.state;
		const { placeholder, id } = this.props;

		const inputProps = {
			placeholder,
			value,
			onChange: this.onChange,
			onKeyPress: this.onKeyPress,
			onBlur: this.onBlur,
		};

		const renderInputs = ({value, onChange, ref, ...rest}) => {
			return (
				<TextInput
					type="text"
					handleChange={onChange}
					value={value}
					validateOnChange={false}
					isRenderdAsAutosuggest={true}
					id={id}
					refCallback={ref}
					{...rest}
				/>
			);
		};

		return (
			<AutoComplete>
				<span className="sr-only" aria-live="polite" aria-atomic="true">
					{suggestions.length > 0 && `Lista med ${suggestions.length} förslag`}
				</span>
				<Autosuggest
					suggestions={suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					getSuggestionValue={this.getSuggestionValue}
					renderSuggestion={this.renderSuggestion}
					inputProps={inputProps}
					focusInputOnSuggestionClick={true}
					renderInputComponent={props => renderInputs(props)}
					
				/>
			</AutoComplete>
		);
	}
}

// TODO: Replace temporary styles with ones that follow the design
const AutoComplete = styled.div`
	.react-autosuggest__container {
		position: relative;
	}

	.react-autosuggest__input {
		width: 100%;
		margin-top: 1em;
		padding: 10px 20px;
		font-size: 16px;
		border: 1px solid #000;
		border-radius: 4px;
		
		&:focus {
			border: 2px solid #000;
			outline: 0;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
				0 0 8px rgba(102, 175, 233, 0.6);
		}
	}

	.react-autosuggest__input--open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.react-autosuggest__suggestions-container {
		display: none;
		max-height: 40vh;
		overflow: auto;
	}

	.react-autosuggest__suggestions-container--open {
		display: inherit;
		position: absolute;
		text-align: left;
		margin-top: -3rem; //to compensate for the 3rem margin-botton of the custom input component
		width: 100%;
		border: 1px solid #aaa;
		background-color: #fff;
		font-size: 16px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 2;
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 10px 20px;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: #ddd;
	}
`;
