import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themeConfig } from '../../../config';

// string concatenation necessary for webpack's static analysis
const logoPromise = import('../../../themes/' + themeConfig.theme + '/Logo');

export class Logo extends Component {
	_isMounted = false;
	state = { Logo: undefined };

	static propTypes = {
		type: PropTypes.oneOf(['SecondaryLogo', 'LogoInverted']).isRequired,
	};

	componentDidMount() {
		const { type } = this.props;
		this._isMounted = true;

		logoPromise.then(m => {
			if (this._isMounted) {
				this.setState({ Logo: m[type] });
			}
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		const { className } = this.props;
		const { Logo } = this.state;

		return Logo ? <Logo alt="Sidans logotyp." className={className} aria-describedby={this.props.isClickable ? "customerLogoDescription" : null}  /> : null;
	}
}

Logo.defaultProps = {
	isClickable: false
}