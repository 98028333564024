import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { RootContainer } from '../../common/blocks/RootContainer';
import { Header } from '../../common/blocks/Header';
import { SCHOOL } from '../../../Paths';
import { isDeviceWidth } from '../../../utils/isDeviceWidth';
import Footer from '../../school/Footer';
import { Logo } from '../../common/blocks/Logo';
import { BREAKPOINTS } from '../../../utils/mediaqueries';

class UnauthenticatedRoot extends Component {
	render() {
		return (
			<RootContainer>
				{isDeviceWidth(BREAKPOINTS.LARGE_PHONE) && (
					<UnauthenticatedHeader>
						<div>
							<NavLink exact to={SCHOOL.HOME} tabIndex="1">
								<Logo type="SecondaryLogo" />
							</NavLink>
						</div>
					</UnauthenticatedHeader>
				)}
				{this.props.children}
				<Footer />
			</RootContainer>
		);
	}
}

const UnauthenticatedHeader = styled(Header)`
	display: flex;
	justify-content: center;
	padding-bottom: 1em;
`;

export default UnauthenticatedRoot;
