import React from 'react';
import { Error } from '../elements/Error';

export const ErrorMessageHandler = error => {
	let message = 'Ett fel uppstod';

	switch (error) {
		case 'TRAVELER_APP_NOT_FOUND':
			message = 'Eleven har ingen koppling till reseapp.';
			break;
		case 'TRAVELER_WITH_PHONE_NUMBER_NOT_FOUND':
			message = 'Ingen elev med det angivna telefonnummret hittades.';
			break;
		case 'PHONE_NUMBER_ALREADY_CONNECTED':
			message = 'Det angivna telefonnummret är redan kopplat till en annan elev.';
			break;
		case 'NO_GROUP_WITH_GROUPID_FOUND':
			message = 'Den valda gruppen hittades ej. Vänligen försök igen.';
			break;
		case 'ADMIN_NOT_FOUND':
			message = 'Administratörs-ID hittades ej.';
			break;
		case 'GROUPID_NOT_FOUND':
			message = 'Grupp-ID hittades ej.';
			break;
		case 'CARDLAYOUTID_NOT_FOUND':
			message = 'Kortlayout-ID hittades ej.';
			break;
		case 'CARDLAYOUTID_IS_INVALID':
			message = 'Kortlayout-ID är ogiltigt.';
			break;
		case 'DUPLICATE_ADMIN_PERSONAL_IDENTITY_NUMBER':
			message = 'En administratör med det angivna personnumret finns redan.';
			break;
		case 'DUPLICATE_PERSONAL_IDENTITY_NUMBER':
			message = 'En elev med det angivna personnumret finns redan i gruppen.';
			break;
		case 'GROUP_NOT_FOUND':
			message = 'Den valda gruppen hittades ej. Vänligen försök igen.';
			break;
		case 'NAME_NOT_COMPLETE':
			message = 'Ange för- och efternamn.';
			break;
		default:
			return <Error />;
	}
	return <Error>{message}</Error>;
};
