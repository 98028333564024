import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { CardWide } from '../common/blocks/CardWide';
import { Main } from '../common/blocks/Main';
import { ThemedNavLink } from './../common/elements/ThemedNavLink';
import {
	getCart,
	hasPurchaseCartError,
	hasDeleteCartItemError,
	isLoadingPaymentMethods,
	cartHasExpired,
	getPurse,
	getBearers,
	hasPurseError,
	getTraveller,
	getCardOrders,
} from '../../reducer';
import { ProductInfo } from '../common/blocks/ProductInfo';
import { Label } from './../common/elements/Label';
import { Checkbox } from './../common/elements/Checkbox';
import {
	actionCreators as walletActions,
	PAYMENT_PROVIDERS,
	getPurchasablePaymentMethods,
} from '../../reducer/wallet';
import { actionCreators as cartActions } from '../../reducer/cart';
import { actionCreators as bearerActions } from '../../reducer/bearer';
import { actionCreators as travellerActions } from '../../reducer/traveller';
import { Error } from './../common/elements/Error';
import { ReactComponent as TrashIcon } from '../../images/trash.svg';
import { formatCurrency } from '../../utils/formatting';
import { BearerInfo } from '../common/blocks/BearerInfo';
import { ActivationInfo } from './blocks/ActivationInfo';
import { Info } from './../common/elements/Info';
import { Loading } from '../common/blocks/Loading';
import { Validation } from '../../utils/validation';
import { PRIVATE } from '../../Paths';
import { ReactComponent as Plus } from '../../images/plus.svg';
import { CARD_NAMES } from './ProfilePage';
import { CardOrder } from './purchase/CardOrder';
import withTextContext from '../../utils/withTextContext';
import { PaymentMethodsDropdown } from './purchase/PaymentMethodsDropdown';
import ReceiptEmail from '../customer/purchase/ReceiptEmail';
import { isMultipleActivationTicket } from '../../reducer/products';
import { sanitizeCmsHtml } from '../../texts';

class CheckoutPage extends Component {
	state = {
		selectedPaymentMethod: undefined,
		usePurse: false,
		havePurseFunds: false,
		isAwaitingRedirect: false,
		errorMessage: undefined,
		travellerReceiptEmail: '',
	};

	componentDidMount() {
		document.title = "Varukorgen | Mitt konto"
		this.props.clearPaymentCards();
		this.props.getPaymentMethods();

		if (!this.props.bearers) {
			this.props.getBearers();
		}

		if (!this.props.purse) {
			this.props.getPurse();
		}

		this.deleteCartIfExpired();

		if (this.props.registrationMetadata) {
			this.renderPaymentMethods();
		}

		if (this.props.traveller && this.props.traveller.receiptEmail) {
			this.setState({
				travellerReceiptEmail: this.props.traveller.receiptEmail,
			});
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { cart, purse } = props;

		if (state.havePurseFunds) return null;

		const havePurseFunds = purse && cart && purse.totalBalance > 0;

		return { havePurseFunds, usePurse: havePurseFunds };
	}

	deleteCartIfExpired = () => {
		if (this.props.cartHasExpired()) {
			this.props.deleteCart();
		}
	};

	isInvalidEmail = () =>
		this.state.travellerReceiptEmail.length &&
		!Validation.email.test(this.state.travellerReceiptEmail);

	setReceiptEmail = async () => {
		const { travellerReceiptEmail } = this.state;
		await this.props.updateTravellerReceiptEmail({
			receiptEmail: travellerReceiptEmail.length ? travellerReceiptEmail : null,
		});
	};

	purchaseRequest = async () => {
		const { selectedPaymentMethod, usePurse } = this.state;
		const isSwishPayment =
			selectedPaymentMethod.provider === PAYMENT_PROVIDERS.SWISH;

		if (this.isInvalidEmail()) {
			this.setState({
				errorMessage: this.props.texts.private.checkoutpage.cart
					.emailvalidationerror,
			});
			return;
		}

		await this.setReceiptEmail();

		const { webviewURL, transactionId } = await this.props.purchaseCart({
			...(selectedPaymentMethod && {
				paymentMethodId: selectedPaymentMethod.id,
			}),
			...(isSwishPayment && { mobile: true }),
			usePurse,
		});

		if (this.props.hasPurchaseCartError) return;

		this.setState({ isAwaitingRedirect: true });

		if (usePurse) this.props.getPurse();

		if (isSwishPayment) {
			this.props.push(PRIVATE.CONFIRM_SWISH_PURCHASE, {
				transactionId,
				webviewURL,
			});
		} else if (webviewURL) {
			window.location.href = webviewURL;
		} else {
			this.props.push(`${PRIVATE.CONFIRM_PURCHASE}?tid=${transactionId}`);
		}
	};

	onPaymentMethodSelect = item => {
		const { paymentMethods } = this.props;

		const selectedPaymentMethod = paymentMethods.find(
			m => m.name === item.title
		);

		this.setState({ selectedPaymentMethod: { ...selectedPaymentMethod } });
	};

	deleteFromCart = async itemId => {
		await this.props.deleteItemFromCart(itemId);
	};

	handleCheckboxClick = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { travellerReceiptEmail, errorMessage } = this.state;
		const {
			cart,
			paymentMethods,
			hasDeleteCartItemError,
			hasPurchaseCartError,
			isLoadingPaymentMethods,
			purse,
			bearers,
			hasPurseError,
			cardOrders,
		} = this.props;

		const { havePurseFunds, usePurse, isAwaitingRedirect } = this.state;
		let [toPayFromPurse, toPay, restFunds] = [0, 0, 0];
		if (havePurseFunds && !!cart) {
			const purseFunds = purse.totalBalance;
			const totalAmount = cart.totalAmount;
			toPayFromPurse = Math.min(purseFunds, totalAmount);
			toPay = totalAmount - toPayFromPurse;
			restFunds = purseFunds - toPayFromPurse;
		}

		if (isAwaitingRedirect) {
			return (
				<main>
				<Main id="checkoutPageMainContainer">
					<FlexWrapper>
						<CartBox>
							<Loading text="Skickar dig vidare..." />
						</CartBox>
					</FlexWrapper>
				</Main>
				</main>
			);
		}

		if (!cart || cart.items.length === 0) {
			return (
				<main>
				<Main id="checkoutPageMainContainer">
					<FlexWrapper>
						<CartBox>
							<CartPageHeader>
								<H1>Din varukorg är tom</H1>
							</CartPageHeader>
							<AddMoreTickets exact to="/purchase" activeClassName="active">
								+ Lägg till biljetter
							</AddMoreTickets>
						</CartBox>
					</FlexWrapper>
				</Main>
				</main>
			);
		}

		return (
			<main>
			<Main id="checkoutPageMainContainer">
				<FlexWrapper>
					<CartBox>
						<CartPageHeader>
							<H1>Varukorg</H1>
						</CartPageHeader>
						<AddMoreTickets exact to="/purchase" activeClassName="active">
							+ Lägg till fler biljetter
						</AddMoreTickets>

						{cart.items.map((i, idx) => {
							return (
								<CartItem key={idx}>
									<ItemInfo>
										<ProductInfo
											productSet={i.productSet}
											style={{ marginBottom: '1em' }}
										/>
										<ActivationInfo manualActivation={i.manualActivation} />
										<BearerInfo
											bearer={(bearers || []).find(b => b.id === i.mtbBearerId)}
										/>
										<CardOrder
											order={cardOrders.find(o => o.cartItemId === i.id)}
										/>
									</ItemInfo>
									{i.count > 1 && <Count>{i.count} st</Count>}
									<Price>
										<TrashButton
											onClick={() => this.deleteFromCart(i.id)}
											aria-label="Ta bort"
											type="button"
										>
											<TrashIcon
												className="svg-icon"
												aria-hidden="true"
												focusable="false"
											/>
										</TrashButton>

										<P>{formatCurrency(i.amount, cart.currency)}</P>

										{i.productSet.discountAmount > 0 && (
											<Discount>
												Rabatt:{' '}
												{formatCurrency(
													i.productSet.discountAmount,
													cart.currency
												)}
											</Discount>
										)}
									</Price>
								</CartItem>
							);
						})}

						{cart.items.some(i => isMultipleActivationTicket(i.productSet)) && (
							<CartItem
								dangerouslySetInnerHTML={sanitizeCmsHtml(
									this.props.texts.private.buyticketpage.cart
										.multipleactivationinfo
								)}
							/>
						)}

						{hasDeleteCartItemError && (
							<Error>
								<span
									className="glyphicon glyphicon-exclamation-sign"
									aria-hidden="true"
								/>
								&nbsp;Det gick inte att ta bort produkten. Var god försök igen
								senare och kontakta kundtjänst ifall problemet kvarstår.
							</Error>
						)}

						<CartSummary>
							<Total>Total:</Total>
							<Price><P>{formatCurrency(cart.totalAmount, cart.currency)}</P></Price>
						</CartSummary>

						{havePurseFunds && (
							<>
								<CartSummary>
									<Total>Kundkassa:</Total>
									<Price>
										<PurseFunds>
											{formatCurrency(
												usePurse ? -toPayFromPurse : 0,
												cart.currency
											)}
										</PurseFunds>
									</Price>
								</CartSummary>
								<CartSummary>
									<Total>Att betala:</Total>
									<Price>
										{formatCurrency(
											usePurse ? toPay : cart.totalAmount,
											cart.currency
										)}
									</Price>
								</CartSummary>
							</>
						)}

						<SecondaryNavLink
							exact
							to={{
								pathname: PRIVATE.PROFILE,
								state: { selectedCard: CARD_NAMES.PAYMENTMETHODS },
							}}
						>
							<Plus className="svg-icon" aria-hidden="true" focusable="false" />
							Lägg till nytt betalkort
						</SecondaryNavLink>

						<h2>Välj betalmedel</h2>

						{havePurseFunds && (
							<PurseController>
								<Label>
									<Checkbox
										id="usePurse"
										name="usePurse"
										checked={this.state.usePurse}
										onCheck={this.handleCheckboxClick}
									/>{' '}
									Använd kundkassa:{' '}
									<PurseFunds>
										{formatCurrency(purse.totalBalance, cart.currency)}
									</PurseFunds>{' '}
									{this.state.usePurse && (
										<>
											(kvar efter köp:{' '}
											<PurseFunds>
												{formatCurrency(restFunds, cart.currency)}
											</PurseFunds>
											)
										</>
									)}
								</Label>
							</PurseController>
						)}

						{hasPurseError && (
							<Info>Kundkassa är inte tillgängligt för tillfället.</Info>
						)}

						<PaymentMethodsDropdown
							paymentMethods={paymentMethods}
							isLoadingPaymentMethods={isLoadingPaymentMethods}
							onSelectPaymentMethod={this.onPaymentMethodSelect}
						/>

						<EmailReceipt>
							{this.props.texts.private.checkoutpage.cart.emailreceiptlabel}
						</EmailReceipt>
						<ReceiptEmail
							setTravellerReceiptEmail={travellerReceiptEmail =>
								this.setState({ travellerReceiptEmail })
							}
							travellerEmail={travellerReceiptEmail}
							isError={errorMessage !== undefined}
							errorMessage={errorMessage}
						/>
						{/* {errorMessage !== undefined && (
							<ValidationError>{errorMessage}</ValidationError>
						)} */}
						{/* Validation errors have been moved to each individual TextInput field - See KOL-2035 for more details */}
						<CheckoutButton onClick={this.purchaseRequest}>
							Slutför
						</CheckoutButton>

						{hasPurchaseCartError && (
							<Error>
								<span
									className="glyphicon glyphicon-exclamation-sign"
									aria-hidden="true"
								/>
								&nbsp;Det gick inte att slutföra köpet. Var god försök igen
								senare och kontakta kundtjänst ifall problemet kvarstår.
							</Error>
						)}
					</CartBox>
				</FlexWrapper>
			</Main>
			</main>
		);
	}
}

const SecondaryNavLink = styled(ThemedNavLink)`
	background: none;
	border: none;
	padding: 0;
	font-size: 16px;
	display: flex;
	margin-top: 1em;
	svg {
		stroke: ${props => props.theme.link_color};
		margin: 1px 5px 0 0;
	}
`;


const H1 = styled.h1`
	font-size: 30px;
`;


// const ValidationError = styled.p`
// 	color: #ff0000;
// 	font-size: 14px;
// 	white-space: pre-line;
// 	margin: 5px 2px 4px;
// `;

const EmailReceipt = styled.span`
	margin: 2em 0 5px;
	font-size: 15px;
	font-weight: 600;
	padding-bottom: 1.4rem;
`;

const PurseController = styled.div`
	margin: 1em 0;
	font-size: 16px;
`;

const PurseFunds = styled.span`
	color: green;
`;

const TrashButton = styled.button`
	background-color: transparent;
	border: 0;
	cursor: pointer;
	margin-bottom: 1em;
`;

const Discount = styled.span`
	font-weight: normal;
`;

const CheckoutButton = styled(SecondaryButton)`
	margin: 1em auto;
	display: block;
`;

const ItemInfo = styled.div`
	width: 65%;

	h4 {
		margin-top: 0;
	}
`;

const Price = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	font-weight: 600;
`;

const CartSummary = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1em;

	${Price} {
		font-size: 21px;
	}
`;

const Total = styled.p`
	font-weight: 600;
	margin: 0;
`;

const CartItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #ededed;
	padding: 1em 0;
`;

const Count = styled.div`
	margin-top: 1em;
`;

const AddMoreTickets = styled(ThemedNavLink)`
	display: block;
	margin-top: -1em;
	margin-bottom: 1em;
`;

const CartPageHeader = styled.div`
	display: flex;
	margin-bottom: 1em;

	h2 {
		width: 100%;
	}
`;

const CartBox = styled(CardWide)`
	text-align: left;
`;

const P = styled.p`
	margin: 0;
`;

function mapStateToProps(store) {
	return {
		cart: getCart(store),
		paymentMethods: getPurchasablePaymentMethods(store),
		isLoadingPaymentMethods: isLoadingPaymentMethods(store),
		hasPurchaseCartError: hasPurchaseCartError(store),
		hasDeleteCartItemError: hasDeleteCartItemError(store),
		cartHasExpired: cartHasExpired(store),
		purse: getPurse(store),
		bearers: getBearers(store),
		hasPurseError: hasPurseError(store),
		traveller: getTraveller(store),
		cardOrders: getCardOrders(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...walletActions,
		...cartActions,
		...bearerActions,
		...travellerActions,
		push,
	})(CheckoutPage)
);
