import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export class Radiobuttons extends Component {
	static propTypes = {
		onSelect: PropTypes.func.isRequired,
		choices: PropTypes.array.isRequired,
		defaultChoice: PropTypes.string.isRequired,
		renderChoice: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			selectedChoice: props.defaultChoice,
		};
	}

	componentDidUpdate() {
		this.setStateFromProps();
	}

	setStateFromProps = () => {
		const { choices } = this.props;
		const { selectedChoice } = this.state;
		const newSelectedChoice = choices.find(c => c.selected);

		if (newSelectedChoice && newSelectedChoice.id !== selectedChoice) {
			this.setState({ selectedChoice: newSelectedChoice.id });
		}
	}

	handleChange = e => {
		const { value } = e.target;

		this.setState({ selectedChoice: value }, () =>
			this.props.onSelect(this.state.selectedChoice)
		);
	};

	renderRadiobutton = (choice) => () => {
		const { selectedChoice } = this.state;

		return 	<StyledRadiobutton>
							{selectedChoice === choice.id && <SelectedIcon />}
						</StyledRadiobutton>
	}

	render() {
		const { selectedChoice } = this.state;
		const { choices, name, renderChoice } = this.props;
		return (
			<RadiobuttonUl>
				{choices.map(choice => (
					<li key={choice.id}>
					<RadiobuttonWrapper key={choice.id} htmlFor={choice.id}>

						<HiddenRadiobutton
							name={name}
							value={choice.id}
							checked={selectedChoice === choice.id}
							disabled={choice.disabled}
							onChange={this.handleChange}
							id={choice.id}
							key={choice.id}
						/>
						
						{renderChoice ? renderChoice(choice, this.renderRadiobutton(choice)) : (
							<>
								{this.renderRadiobutton(choice)()}
								<span style={{marginLeft: '1em', marginBottom: '1em'}}>{choice.label}</span>
							</>
						)}
						
					</RadiobuttonWrapper>
					</li>
				))}
			</RadiobuttonUl>
		);
	}
}

export const HiddenRadiobutton = styled.input.attrs({ type: 'radio' })`
	// Hide checkbox visually but remain accessible to screen readers.
	// Source: https://polished.js.org/docs/#hidevisually
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const RadiobuttonWrapper = styled.label`
	display: flex;
	flex-direction: row;
	margin: 2px;
	font-weight: normal;
	cursor: pointer;
	gap: 0.6rem;
`;

const StyledRadiobutton = styled.div`
	display: inline-block;
	min-height: 20px;
	min-width: 20px;
	max-height: 20px;
	max-width: 20px;
	border: 2px solid ${props => props.theme.themeName === "kalmar" ? "#000" : props.theme.primary_color};
	border-radius: 50%;
	padding: 2px;
	margin: 0 4px 0 0;

	input[type="radio"][disabled] ~ & {
		border-color: grey;
	}

	input[type="radio"]:focus ~ & {
		box-shadow: 0 0 0 2px #000;
	}
`;

const SelectedIcon = styled.div`
	min-height: 12px;
	min-width: 12px;
	max-height: 12px;
	max-width: 12px;
	border: 2px solid ${props => props.theme.primary_color};
	border-radius: 50%;
	background-color: ${props => props.theme.primary_color};

	input[type="radio"][disabled] ~ div > & {
		border-color: grey;
		background-color: grey;
	}
`;

const RadiobuttonUl = styled.ul`
display: flex;
flex-wrap: wrap;
list-style-type: none;
padding: 0;
margin: 0;
`;