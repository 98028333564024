import React, { Component } from 'react';
import { ServiceWorkerNotification } from '../components/common/blocks/ServiceWorkerNotification';
import MainErrorBoundary from '../components/common/MainErrorBoundary';

export const withLayout = Layout => WrappedComponent => {
	return class extends Component {
		render() {
			return (
				<Layout>
					<ServiceWorkerNotification />
					<MainErrorBoundary>
						<WrappedComponent {...this.props} />
					</MainErrorBoundary>
				</Layout>
			);
		}
	};
};
