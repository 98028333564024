import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import { formatCurrency } from '../../utils/formatting';
import {
	getCart,
	getSelectedProductSet,
	cartIsLoading,
	cartHasExpired,
	getNewCartItem,
	hasCartError,
} from '../../reducer';
import { actionCreators, isNewBearer } from '../../reducer/cart';
import { ProductInfo } from '../common/blocks/ProductInfo';
import { PRIVATE } from '../../Paths';
import { NextButton } from './../common/elements/NextButton';
import { forTabletPortraitUp, forPhoneOnly, forZoomedInUsers } from '../../utils/mediaqueries';

export class SelectedProduct extends Component {
	constructor(props) {
		super(props);
		this.addToCartRef = React.createRef()
	}


	componentDidUpdate() {
	if (window.location.href.includes("offer")) {
	this.addToCartRef.current && this.addToCartRef.current.focus();
}
	}

	nextActions = {
		'/purchase': async () => {
			this.props.push(PRIVATE.BUY_TICKET_OPTIONS);
		},
		'/purchase/offer': async () => {
			this.props.push(PRIVATE.BUY_TICKET_OPTIONS);
		},
		'/purchase/options': async () => {
			const { newCartItem	} = this.props;

			if (isNewBearer(newCartItem.mtbBearerId) && !newCartItem.orderAddress.isValid) {
				this.props.setInvalidOrderAddress(true);
				return;
			}

			const cartItemId = await this.props.addItemToCart(newCartItem);

			if (this.props.hasCartError) return;

			if (isNewBearer(newCartItem.mtbBearerId)) {
				this.props.addCardOrder(cartItemId, newCartItem.orderAddress);
			}
			
			this.props.resetNewCartItem();
			this.props.push(PRIVATE.CHECKOUT);
		},
	};
	
	continue = async () => {
		const { pathname } = this.props;
		
		if (!this.props.cart || this.props.cartHasExpired()) {
			await this.props.createCart();
		}
		
		const next = this.nextActions[pathname];
		await next();
	};

	render() {
		const { selectedProductSet, newCartItem, cartIsLoading } = this.props;
		const amount = selectedProductSet.amount * newCartItem.count;
		
		if (isEmpty(selectedProductSet)) {
			return null;
		}

		return (
			<MainWrapper>
				<SelectedProductWrapper>
					<div className="innerWrapper">
						<SelectedProductInfo
							productSet={selectedProductSet}
							numberOfProducts={newCartItem.count}
						/>
						<GridRight>
							<Price>
								<strong>
									{formatCurrency(amount, selectedProductSet.currency)}
								</strong>
							</Price>

							<ActionButton onClick={this.continue} disabled={cartIsLoading} ref={this.addToCartRef}>
								Gå vidare
							</ActionButton>
						</GridRight>
					</div>
				</SelectedProductWrapper>
			</MainWrapper>
		);
	}
}

const MainWrapper = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1;
`;

const ActionButton = styled(NextButton)`
	${forTabletPortraitUp`
		margin-left: 2em;
	`}
	&:focus {
    border: 3px solid black;
    }
`;

const GridRight = styled.div`
	display: flex;
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: 0.5em;
	align-self: flex-end;

	${forPhoneOnly`
		padding-right: 1em;
		display: block;
	`}
`;

const Price = styled.h3`
	${forPhoneOnly`
		text-align: right;
		font-size: 18px;
	`}
`;

const SelectedProductWrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 900px;
	background-color: #ffffff;
	box-shadow: 0 -5px 13px 10px rgba(0, 0, 0, 0.05);
	display: flex;
	border-top-left-radius: 2em;
	border-top-right-radius: 2em;

	.innerWrapper {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 614px;
		display: flex;
		flex: 1 0 auto;

		${forZoomedInUsers`
			flex-direction: column; 
		`}
	}
`;

const SelectedProductInfo = styled(ProductInfo)`
	margin-left: auto;
	margin-right: auto;
	width: 40%;
	padding-top: 0.5em;
	padding-bottom: 0.5em;

	${forPhoneOnly`
		padding-left: 1em;
		align-self: flex-start;
		padding-top: unset;

		h4 {
			font-size: 16px;
			margin-top: 1.3em;
		}
		p {
			font-size: small;
		}
		.zon {
			display: none;
		}
	`}

	${forZoomedInUsers`
		width: 100%;
	`}
`;

export default connect(
	state => ({
		selectedProductSet: getSelectedProductSet(state),
		pathname: state.router.location.pathname,
		cart: getCart(state),
		cartIsLoading: cartIsLoading(state),
		cartHasExpired: cartHasExpired(state),
		newCartItem: getNewCartItem(state),
		hasCartError: hasCartError(state),
	}),
	{ push, ...actionCreators }
)(SelectedProduct);
