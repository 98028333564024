import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Main } from '../common/blocks/Main';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { ReactComponent as Ticket } from '../../images/ticket.svg';
import { ReactComponent as Card } from '../../images/travel-card.svg';
import { forPhoneOnly, forDesktopUp } from '../../utils/mediaqueries';
import { SCHOOL } from '../../Paths';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import withTextContext from '../../utils/withTextContext';
import { sanitizeCmsHtml } from '../../texts';
import { IMAGE_SIZES, getImageUrl } from '../../reducer/cms';
import { Banner } from '../common/blocks/Banner';

class OrderPage extends Component {
	redirectToOrderStudentBearer = () =>
		this.props.push(SCHOOL.ORDER_TRAVEL_CARD);
	redirectToOrderStudentTicket = () => this.props.push(SCHOOL.ORDER_TICKETS);

	redirectToOrderStudentBearerBulk = () =>
		this.props.push(SCHOOL.ORDER_TRAVEL_CARD_BULK);

	render() {
		const { texts } = this.props;
		const {
			orderTravelcardTile,
			orderTicketsTile,
			orderExtraTravelcardsTile,
		} = texts.school.images.orderpage;

		const orderTravelcardImg = getImageUrl(
			orderTravelcardTile,
			IMAGE_SIZES.TILE
		);
		const orderTicketsImg = getImageUrl(orderTicketsTile, IMAGE_SIZES.TILE);
		const orderExtraTravelcardsImg = getImageUrl(
			orderExtraTravelcardsTile,
			IMAGE_SIZES.TILE
		);

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderpage}
				>
					<h2>Beställ</h2>
					<p>Här beställs resekort och biljetter</p>
				</Banner>
				<Main>
					<OrderBoxContainer>
						<OrderBox>
							<FlexImage img={orderTravelcardImg} />
							<OrderBoxBody>
								<OrderBoxHeader>Beställ resekort till elever</OrderBoxHeader>
								<ul>
									<li>Beställ namngivna resekort utan biljett</li>
									<li>Levereras till skolan</li>
								</ul>
								<OrderBoxButton onClick={this.redirectToOrderStudentBearer}>
									<CardIcon
										className="svg-icon"
										aria-hidden="true"
										focusable="false"
									/>{' '}
									Beställ resekort
								</OrderBoxButton>
							</OrderBoxBody>
						</OrderBox>

						<OrderBox>
							<FlexImage img={orderTicketsImg} />
							<OrderBoxBody>
								<OrderBoxHeader>Beställ biljetter</OrderBoxHeader>
								<ul>
									<li>Välj / skapa grupp</li>
									<li>Tilldela biljetter till elever</li>
								</ul>
								<OrderBoxButton onClick={this.redirectToOrderStudentTicket}>
									<TicketIcon
										className="svg-icon"
										aria-hidden="true"
										focusable="false"
									/>{' '}
									Beställ biljetter
								</OrderBoxButton>
							</OrderBoxBody>
						</OrderBox>

						<OrderBox>
							<FlexImage img={orderExtraTravelcardsImg} />
							<OrderBoxBody>
								<OrderBoxHeader>Beställ extra resekort</OrderBoxHeader>
								<ul>
									<li>Beställ resekort utan biljett</li>
									<li>Levereras till skolan</li>
								</ul>
								<OrderBoxButton onClick={this.redirectToOrderStudentBearerBulk}>
									<CardIcon
										className="svg-icon"
										aria-hidden="true"
										focusable="false"
									/>{' '}
									Beställ extra resekort
								</OrderBoxButton>
							</OrderBoxBody>
						</OrderBox>
					</OrderBoxContainer>
					<div>
						<h3
							dangerouslySetInnerHTML={sanitizeCmsHtml(
								texts.school.orderpage.pageinfo.orderinformationheader
							)}
						/>
						<div
							dangerouslySetInnerHTML={sanitizeCmsHtml(
								texts.school.orderpage.pageinfo.orderinformationtext
							)}
						/>
					</div>
				</Main>
			</>
		);
	}
}

const orderIcon = css`
	margin-right: 0.3em;
	g {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

const TicketIcon = styled(Ticket)`
	${orderIcon}
`;

const CardIcon = styled(Card)`
	${orderIcon}
`;

const OrderBoxContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const OrderBox = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 250px;
	width: 100%;
	border: 2.5px solid rgba(48, 48, 48, 0.09);
	border-radius: 6px;
	margin-bottom: 1em;
	box-shadow: 0 8px 25px -6px black;

	${forPhoneOnly`
		max-width: unset;
	`}

	${forDesktopUp`
		max-width: 350px;
	`}
`;

const OrderBoxBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	padding: 1em;

	${forDesktopUp`
		padding: 2em;
	`}
`;

const FlexImage = styled.div`
	background-size: cover;
	background-color: ${props => props.theme.dark_grey};
	background: url(${props => props.img}) center 40% no-repeat;
	height: 150px;
`;

const OrderBoxHeader = styled.h4`
	margin-top: 0;
`;

const OrderBoxButton = styled(SecondaryButton)`
	padding-left: 1em;
	padding-right: 1em;
`;

export default withTextContext(connect(null, { push })(OrderPage));
