import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { CloseCardButton } from '../../common/blocks/CloseCardButton';
import { TextInput } from '../../common/elements/TextInput';
import { PopUpBox, PopupContainer } from '../../common/elements/PopUpBox';
import { Dropdown } from '../../common/elements/Dropdown';
import isEmpty from 'lodash/isEmpty';
import { getAllCardLayouts } from '../../../reducer';
import {
	actionCreators as studentActions,
	hasCardLayouts,
} from '../../../reducer/students';
import {
	actionCreators as schoolActions,
	getTicketTypes,
	hasTicketTypes,
} from '../../../reducer/school';
import { NextButton } from '../../common/elements/NextButton';
import { ConfirmationCard } from '../../common/blocks/ConfirmationCard';
import { Validation } from '../../../utils/validation';
import { ErrorMessageHandler } from '../../common/blocks/ErrorMessageHandler';

const NO_TICKET_TYPE = 'Ingen biljettyp';
class AddStudentPopup extends Component {
	state = {
		personalIdentityNumber: '',
		lastName: '',
		firstName: '',
		homeCounty: '',
		confirmation: false,
		cardType: undefined,
		ticketType: undefined,
		hasError: false,
		errorMessage: '',
	};

	componentDidMount() {
		const { hasTicketTypes, hasCardLayouts } = this.props;

		if (!hasCardLayouts) this.props.getCardLayouts();

		if (!hasTicketTypes) this.props.getTicketTypes();
	}

	handleChange = target => {
		this.setState({
			[target.id]: target.value,
			hasError: false,
		});
	};

	handleChangeValidCheck = target => {
		this.setState({
			[target.id]: target.value,
			[`${target.id}Valid`]: this.isFieldValid(target.id, target.value),
			hasError: false,
		});
	};

	isFieldValid = (field, value) => this.validators[field](value);

	validators = {
		lastName: value => value.length > 1 && Validation.noNumbers.test(value),
		firstName: value => value.length > 1 && Validation.noNumbers.test(value),
		personalIdentityNumber: value => Validation.personalIdentityNumber.test(value),
	};

	onSelectTicketType = item => {
		if (item.title === NO_TICKET_TYPE) {
			this.setState({ ticketType: '' });
			return;
		}
		this.setState({ ticketType: item.title });
	};

	onSelectCardLayout = item => {
		this.setState({ cardType: item.title });
	};

	addStudent = async () => {
		const { groupId } = this.props;

		const {
			personalIdentityNumber,
			lastName,
			firstName,
			homeCounty,
			cardType,
			ticketType,
		} = this.state;

		const student = {
			personalIdentityNumber,
			lastName,
			firstName,
			homeCounty,
			cardType,
			ticketType,
		};

		try {
			await this.props.createStudent(student, groupId);
			this.setState({ confirmation: true });
		} catch (error) {
			this.setState({ hasError: true, errorMessage: error.message });
		}
	};

	render() {
		const {
			personalIdentityNumber,
			lastName,
			firstName,
			homeCounty,
			lastNameValid,
			firstNameValid,
			confirmation,
			hasError,
			errorMessage,
			personalIdentityNumberValid,
		} = this.state;
		const { closePopup, cardLayouts, ticketTypes } = this.props;

		let sortedCardLayouts = Object.values(cardLayouts || {});
		sortedCardLayouts = sortedCardLayouts.sort((a, b) =>
			a.name.localeCompare(b.name)
		);

		let sortedTicketTypes = Object.values(ticketTypes || {});
		sortedTicketTypes.unshift({ name: NO_TICKET_TYPE });

		if (confirmation) {
			return (
				<PopupContainer>
					<ConfirmationCard
						onClick={closePopup}
						title="Klart!"
						text="En ny elev är nu skapad"
					/>
				</PopupContainer>
			);
		}

		return (
			<PopUpBox>
				<CloseCardButton onClick={closePopup} />
				<h2>Lägg till ny elev</h2>
				<InnerContainer>
					<FormRow>
						<FormTextRequired>Personnummer</FormTextRequired>
						<Input
							type="text"
							value={personalIdentityNumber}
							handleChange={this.handleChangeValidCheck}
							placeholder="ÅÅÅÅMMDDXXXX"
							id="personalIdentityNumber"
							hasFloatingLabel={false}
						/>
					</FormRow>
					<FormRow>
						<FormTextRequired>Efternamn</FormTextRequired>
						<Input
							type="text"
							value={lastName}
							handleChange={this.handleChangeValidCheck}
							placeholder="Efternamn"
							id="lastName"
							errorMessage={"Efternamnet är inte rätt ifyllt"}
							isError={typeof lastNameValid !== 'undefined' && !lastNameValid}
							hasFloatingLabel={false}
						/>
					</FormRow>
					<FormRow>
						<FormTextRequired>Förnamn</FormTextRequired>
						<Input
							type="text"
							value={firstName}
							handleChange={this.handleChangeValidCheck}
							placeholder="Förnamn"
							id="firstName"
							errorMessage={"Förnamnet är inte rätt ifyllt"}
							isError={typeof firstNameValid !== 'undefined' && !firstNameValid}
							hasFloatingLabel={false}
						/>
					</FormRow>
					<FormRow>
						<FormText>Hemkommun</FormText>
						<Input
							type="text"
							value={homeCounty}
							handleChange={this.handleChange}
							placeholder="Hemkommun"
							id="homeCounty"
							hasFloatingLabel={false}
						/>
					</FormRow>
					<FormRow>
						<FormTextRequired>Kortlayout</FormTextRequired>
						<DropdownContainer>
							{isEmpty(sortedCardLayouts) ? (
								<Dropdown
									id="cardType"
									disabled={true}
									onSelect={() => {}}
									items={[]}
									renderSelectedItem={() => <div>Kortlayouter saknas</div>}
									renderItem={() => {}}
								/>
							) : (
								<Dropdown
									id="cardType"
									items={sortedCardLayouts.map(l => ({
										...l,
										title: l.name,
									}))}
									onSelect={this.onSelectCardLayout}
								/>
							)}
						</DropdownContainer>
					</FormRow>
					<FormRow>
						<FormText>Biljettyp</FormText>
						<DropdownContainer>
							{isEmpty(sortedTicketTypes) ? (
								<Dropdown
									id="ticketType"
									disabled={true}
									onSelect={() => {}}
									items={[]}
									renderSelectedItem={() => <div>Biljettyp saknas</div>}
									renderItem={() => {}}
								/>
							) : (
								<Dropdown
									id="ticketType"
									items={sortedTicketTypes.map(l => ({
										...l,
										title: l.name,
									}))}
									onSelect={this.onSelectTicketType}
								/>
							)}
						</DropdownContainer>
					</FormRow>
					<ButtonWrapper>
						<CreateAdminButton
							onClick={this.addStudent}
							disabled={
								!lastNameValid ||
								!firstNameValid ||
								!personalIdentityNumberValid
							}
						>
							Spara
						</CreateAdminButton>
					</ButtonWrapper>
					{hasError && ErrorMessageHandler(errorMessage)}
				</InnerContainer>
			</PopUpBox>
		);
	}
}

const DropdownContainer = styled.div`
	width: 235px;
	float: right;
	margin-bottom: 1em;
	text-align: left;
`;

const Input = styled(TextInput)`
	float: right;
	margin-bottom: 1em;
	width: 235px;
`;

const FormRow = styled.div`
	clear: both;
	display: flex;
	justify-content: space-between;
`;

const InnerContainer = styled.div`
	width: 400px;
	display: flex;
    flex-direction: column;
    gap: 1.2rem;

	& > ${FormRow}:first-child {
		margin-top: 3rem;
	}
	
`;

const FormTextRequired = styled.div`
	float: left;
	margin-top: 10px;
	:after {
		content: ' *';
		color: #d30808;
	}
`;

const FormText = styled.div`
	float: left;
	margin-top: 10px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

const CreateAdminButton = styled(NextButton)`
	max-width: 250px;
	width: 100%;
	margin-top: 1em;
	margin-bottom: 1em;
`;

// const ErrorMessage = styled.p`
// 	margin-top: -0.5em;
// 	color: crimson;
// 	float: right;
// 	margin-right: 42px;
// `;

export default connect(
	store => ({
		cardLayouts: getAllCardLayouts(store),
		ticketTypes: getTicketTypes(store),
		hasTicketTypes: hasTicketTypes(store),
		hasCardLayouts: hasCardLayouts(store),
	}),
	{ ...studentActions, ...schoolActions, push }
)(AddStudentPopup);
