import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { isMultipleActivationTicket, productHasTag } from '../../../reducer/products';

export const TicketStatus = props => {
	const { product } = props;

	const isSchoolTicket = productHasTag(product, 'pc_school', (tagName) => tagName.toLowerCase().trim());

	if (isMultipleActivationTicket(product)) {
		if (!product.active) {
			let daysLeft = Math.floor(
				(Date.parse(product.expire) - Date.now()) / (1000 * 3600 * 24)
			);
			return product.expire ? (
				<>
					<p>
						<TicketStatusInactive>
							{isSchoolTicket ? 'Skolbiljett giltig till' : 'Aktiveras senast'}
						</TicketStatusInactive>{' '}
						{formatExpireDateTime(product.expire)}
					</p>
					<p>
						Antal aktiverade resor:{' '}
						<span>
							{product.numberOfActivations - product.numActivationsLeft} av{' '}
							{product.numberOfActivations}
						</span>
					</p>
					<p>
						{product.numActivationsLeft} {product.numActivationsLeft === 1 ? 'resa' : 'resor'} kvar att nyttja inom{' '}
						{daysLeft} dagar
					</p>
					{product.startOfActivationPeriod && (
						<p>
							Aktiverades första gången{' '}
							{formatExpireDateTime(product.startOfActivationPeriod)}
						</p>
					)}
				</>
			) : (
				<p />
			); // product doesn't have latest activation date (<p> for margin)
		} else {
			let daysLeft = Math.floor(
				(Date.parse(product.endOfActivationPeriod) - Date.now()) /
					(1000 * 3600 * 24)
			);
			return (
				<>
					<p>
						<TicketStatusActive>
							Resa {product.numberOfActivations - product.numActivationsLeft}
						</TicketStatusActive>{' '}
						är giltig till {formatExpireDateTime(product.expire)}
					</p>
					<p>Aktiverades {formatExpireDateTime(product.activation)}</p>
					<p>
						Antal aktiverade resor:{' '}
						<span>
							{product.numberOfActivations - product.numActivationsLeft} av{' '}
							{product.numberOfActivations}
						</span>
					</p>
					<p>
						{product.numActivationsLeft} {product.numActivationsLeft === 1 ? 'resa' : 'resor'} kvar att nyttja inom{' '}
						{daysLeft} dagar
					</p>
					{product.startOfActivationPeriod && (
						<p>
							Aktiverades första gången{' '}
							{formatExpireDateTime(product.startOfActivationPeriod)}
						</p>
					)}
				</>
			);
		}
	}

	if (!product.active) {
		return product.expire ? (
			<p>
				<TicketStatusInactive>
					{isSchoolTicket ? 'Skolbiljett giltig till' : 'Aktiveras senast'}
				</TicketStatusInactive>{' '}
				{formatExpireDateTime(product.expire)}
			</p>
		) : (
			<p />
		); //(<p> for margin)
	}

	return (
		<p>
			<TicketStatusActive>Giltig till</TicketStatusActive>{' '}
			{formatExpireDateTime(product.loanExpire)}
		</p>
	);
};

const formatExpireDateTime = date => format(new Date(date), 'YYYY-MM-DD HH:mm');

const StatusSpan = styled.span`
	font-weight: bold;
`;

const TicketStatusActive = styled(StatusSpan)`
	color: #417505;
`;

const TicketStatusInactive = styled(StatusSpan)`
	color: #a46400;
`;
