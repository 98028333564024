import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { customerRoutes } from './CustomerRoutes';
import { schoolRoutes } from './SchoolRoutes';
import { isSchool } from '../utils/helperFunctions';

let routeConfig = isSchool() ? schoolRoutes : customerRoutes;

// TODO: Employ code splitting and load routes on demand
// <AsyncRoute path="/pedal/:id" getComponent={() => import(/* webpackChunkName: "PedalDetail" */ "./components/PedalDetail/PedalDetail").then(module => module.default)}/>
export const Routes = connect(({ router }) => ({ location: router.location }))(
	props => {
		return (
			// Set location as prop in order to avoid update blocking in router
			<Switch location={props.location}>
				{routeConfig.map((props, idx) => (
					<Route key={idx} {...props} />
				))}
			</Switch>
		);
	}
);
