import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TravelCard } from '../../../images/travel-card.svg';

export function CardOrder(props) {
	const { order } = props;

	if (!order) return null;

	return (
		<Container>
			<TravelCard className="svg-icon" aria-hidden="true" focusable="false" />
			<OrderWrapper>
				<p>Nytt resekort</p>

				<p>
					<strong>Leveransadress</strong><br />
					{order.deliveryAddress.name && <>{order.deliveryAddress.name}<br /></>}
					{order.deliveryAddress.careOf && <>c/o {order.deliveryAddress.careOf}<br/></>}
					{order.deliveryAddress.streetAddress}<br/>
					{order.deliveryAddress.postalCode} {order.deliveryAddress.locality}
				</p>
			</OrderWrapper>
		</Container>
	);
}

const OrderWrapper = styled.div`
	margin-left: 6px;
	width: 100%;
`;

const Container = styled.div`
	display: flex;
	padding-right: 1em;
`;
