import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { Checkbox } from '../../common/elements/Checkbox';
import { Error } from '../../common/elements/Error';
import { Loading } from '../../common/blocks/Loading';
import { A } from '../../common/elements/A';
import { ProfileCardHeader } from './ProfileCardHeader';

export class ConsentForm extends React.Component {
	state = { consents: {}, updatedConsents: {} };

	static propTypes = {
		onUpdate: PropTypes.func.isRequired,
		list: PropTypes.array,
		legal: PropTypes.object,
		hasLoadingError: PropTypes.bool,
		hasUpdateError: PropTypes.bool,
		isLoading: PropTypes.bool,
	};

	componentDidMount() {
		this.setStateFromProps();
	}

	setStateFromProps = () => {
		const { list } = this.props;

		if (!list || this.hasState()) return;

		this.setState(() => {
			const consents = {};
			list
				.reduce((acc, current) => acc.concat(current.consents), [])
				.forEach(c => (consents[c.templateId] = { ...c }));
			return { consents };
		});
	};

	getConsentIdByTemplateId = templateId => {
		let { consents } = this.state;

		let returnConsent = consents[templateId];

		if (!!returnConsent) return returnConsent.id;
		return null;
	};

	handleChange = (templateId, value) => {
		this.setState((state, props) => {
			const consents = { ...state.consents };
			const updatedConsents = { ...state.updatedConsents };
			const loadedConsents = props.list.reduce(
				(acc, current) => acc.concat(current.consents),
				[]
			);

			const newConsent = {
				templateId,
				active: value,
				id: this.getConsentIdByTemplateId(templateId),
			};

			if (
				this.isUpdated(
					newConsent,
					loadedConsents.filter(c => c.templateId === templateId)[0]
				)
			) {
				updatedConsents[templateId] = { ...newConsent };
			} else {
				delete updatedConsents[templateId];
			}

			consents[templateId] = newConsent;

			return { consents, updatedConsents };
		});
	};

	updateConsent = () => {
		if (this.hasUpdatedConsents()) {
			this.props.onUpdate(this.state.updatedConsents);
		}
	};

	hasState = () => !this.isEmpty(this.state.consents);

	hasUpdatedConsents = () => !this.isEmpty(this.state.updatedConsents);

	isUpdated = (current, previous) => current.active !== previous.active;

	isEmpty = obj => Object.keys(obj).length === 0;

	render() {
		const consentState = this.state.consents;
		const {
			list,
			legal,
			hasLoadingError,
			hasUpdateError,
			isLoading,
		} = this.props;

		return (
			<>
				{(list || []).map((x, i) => (
					<form className="form-group" key={'x' + i + 1} id="minaSamtyckenForm" onSubmit={e => e.preventDefault()}>
							<ProfileCardHeader>Mina samtycken</ProfileCardHeader>
							<fieldset>
								<FormSubHeader key={'x' + i + 2}><p>{x.description}</p></FormSubHeader>
									{x.consents.map((c, j) => (
										<div key={'c' + j + 1}>
											<label htmlFor={'c' + j + 3}>
												<Checkbox
													id={'c' + j + 3}
													data-template={c.templateId}
													name={c.templateId}
													checked={
														consentState[c.templateId]
															? consentState[c.templateId].active
															: false
													}
													onCheck={this.handleChange}
												/>{' '}
												{c.name}
											</label>
										</div>
									))}
							</fieldset>
					</form>
				))}
				<SecondaryButton
					style={hasLoadingError ? { display: 'none' } : null}
					onClick={this.updateConsent}
					disabled={!this.hasUpdatedConsents() || isLoading}
				>
					Spara
				</SecondaryButton>
				{hasLoadingError && (
					<Error>
						Det gick ej att hämta samtycken. Var god försök igen senare och
						kontakta kundtjänst ifall problemet kvarstår.
					</Error>
				)}
				{hasUpdateError && (
					<Error>
						Det gick ej att uppdatera samtycken. Var god försök igen senare och
						kontakta kundtjänst ifall problemet kvarstår.
					</Error>
				)}
				{isLoading && <Loading />}
				{!!legal && (
					<LegalText>
						Du har redan godkänt{' '}
						<A href={legal.terms} target="_blank" rel="noopener noreferrer">
							användarvillkoren
						</A>{' '}
						och{' '}
						<A href={legal.privacy} target="_blank" rel="noopener noreferrer">
							integritetspolicyn
						</A>{' '}
						för tjänsten Mitt konto
					</LegalText>
				)}
			</>
		);
	}
}

const FormSubHeader = styled.legend`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 12px;
	line-height: 17px;
	margin: 8px 0 30px;
	border: 0;

	p {
		margin: 0;
		display: inherit;
	}
`;

const LegalText = styled.p`
	margin: 45px 0 0;
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 10px;
	line-height: 14px;
`;
