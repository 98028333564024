import { compose } from 'redux';
import { SCHOOL } from '../Paths';
import { ADMIN_ROLE } from '../reducer/school/admins';
import withAuthentication from '../utils/withAuthentication';
import HomePageSchool from '../components/school/HomePage';
import RegisterPage from '../components/school/RegisterPage';
import OrderPage from '../components/school/OrderPage';
import GroupPage from '../components/school/GroupPage';
import StudentPage from '../components/school/StudentPage';
import CardOrderConfirmationPage from '../components/school/CardOrderConfirmationPage';
import GroupDetailsPage from '../components/school/GroupDetailsPage';
import AdminPage from '../components/school/AdminPage';
import OrderTravelCardPage from '../components/school/OrderTravelCardPage';
import OrderTravelCardBulkPage from '../components/school/OrderTravelCardBulkPage';
import withSchoolAuthorization from '../utils/withSchoolAuthorization';
import OrderTicketPage from '../components/school/OrderTicketPage';
import ConfirmTicketOrder from '../components/school/ConfirmTicketOrder';
import TicketOrderConfirmationPage from '../components/school/TicketOrderConfirmationPage';
import TicketOrderHistoryDetailsPage from '../components/school/TicketOrderHistoryDetailsPage';
import SchoolRoot from '../components/school/Root';
import { withLayout } from '../utils/withLayout';
import UnauthenticatedRoot from '../components/school/blocks/UnauthenticatedRoot';
import NotAuthorizedPage from '../components/common/NotAuthorizedPage';
import LoginPage from '../components/common/LoginPage';
import CallbackPage from '../components/school/CallbackPage';
import { SignoutPage } from '../components/common/SignoutPage';
import ConfirmationPage from '../components/school/ConfirmationPage';
import NotFoundPage from '../components/common/NotFoundPage';
import OrderTicketHistoryPage from '../components/school/OrderTicketHistoryPage';
import OrderTravelcardHistoryPage from '../components/school/OrderTravelcardHistoryPage';
import TravelcardOrderHistoryDetailsPage from '../components/school/TravelcardOrderHistoryDetailsPage';
import BulkTravelcardOrderHistoryDetailsPage from '../components/school/BulkTravelcardOrderHistoryDetailsPage';
import FinishedTicketsHistoryPage from '../components/school/FinishedTicketsHistoryPage';
import FinishedTicketsHistoryDetailsPage from '../components/school/FinishedTicketsHistoryDetailsPage';
import AdminSchoolChange from '../components/school/AdminSchoolChange';
import ensureSchoolSelected from '../utils/ensureSchoolSelected';
// import ExternalFormConnectAccount from '../components/school/ExternalFormConnectAccount';
import CultureTripPage from '../components/school/CultureTripPage';
import ChooseTravelers from '../components/school/culturetripform/ChooseTravelers';
import JourneyForward from '../components/school/culturetripform/JourneyForward';
import JourneyBack from '../components/school/culturetripform/JourneyBack';
import ContactInfo from '../components/school/culturetripform/ContactInfo';
import Summary from '../components/school/culturetripform/Summary';
import OrderTravelCardCart from '../components/school/OrderTravelCardCart';

const authenticatedSchoolAdmin = compose(
	withLayout(SchoolRoot),
	withAuthentication({ redirectPath: SCHOOL.LOGIN }),
	ensureSchoolSelected,
	withSchoolAuthorization([ADMIN_ROLE.SCHOOL_ADMIN, ADMIN_ROLE.SCHOOL_ORG_ADMIN]),
);

const authenticatedOrgAdmin = compose(
	withLayout(SchoolRoot),
	withAuthentication({ redirectPath: SCHOOL.LOGIN }),
	ensureSchoolSelected,
	withSchoolAuthorization([ADMIN_ROLE.SCHOOL_ORG_ADMIN]),
);

const withUnauthenticatedLayout = component =>
	withLayout(UnauthenticatedRoot)(component);

export const schoolRoutes = [
	{ path: SCHOOL.HOME, exact: true, component: withUnauthenticatedLayout(HomePageSchool) },
	{ path: SCHOOL.REGISTER, exact: true, component: withUnauthenticatedLayout(RegisterPage) },
	{ path: SCHOOL.ORDER, exact: true, component: authenticatedSchoolAdmin(OrderPage) },
	{ path: SCHOOL.CULTURE_TRIP, exact: true, component: authenticatedSchoolAdmin(CultureTripPage) },
	{ path: SCHOOL.CULTURE_TRIP_CHOOSE_TRAVELERS, exact: true, component: authenticatedSchoolAdmin(ChooseTravelers) },
	{ path: SCHOOL.CULTURE_TRIP_DEPARTURE, exact: true, component: authenticatedSchoolAdmin(JourneyForward) },
	{ path: SCHOOL.CULTURE_TRIP_RETURN, exact: true, component: authenticatedSchoolAdmin(JourneyBack) },
	{ path: SCHOOL.CULTURE_TRIP_CONTACT_INFO, exact: true, component: authenticatedSchoolAdmin(ContactInfo) },
	{ path: SCHOOL.CULTURE_TRIP_SUMMARY, exact: true, component: authenticatedSchoolAdmin(Summary) },
	{ path: SCHOOL.GROUPS, exact: true, component: authenticatedSchoolAdmin(GroupPage) },
	{ path: SCHOOL.STUDENTS, exact: true, component: authenticatedSchoolAdmin(StudentPage) },
	{ path: SCHOOL.GROUPDETAILS, exact: true, component: authenticatedSchoolAdmin(GroupDetailsPage) },
	{ path: SCHOOL.ADMINS, exact: true, component: authenticatedOrgAdmin(AdminPage) },
	{ path: SCHOOL.ORDER_TRAVEL_CARD, exact: true, component: authenticatedSchoolAdmin(OrderTravelCardPage) },
	{ path: SCHOOL.CARD_ORDER_CART, exact: true, component: authenticatedSchoolAdmin(OrderTravelCardCart) },
	{ path: SCHOOL.ORDER_TRAVEL_CARD_BULK, exact: true, component: authenticatedSchoolAdmin(OrderTravelCardBulkPage) },
	{ path: SCHOOL.ORDER_TICKETS, exact: true, component: authenticatedSchoolAdmin(OrderTicketPage) },
	{ path: SCHOOL.CARD_ORDER_CONFIRMATION, exact: true, component: authenticatedSchoolAdmin(CardOrderConfirmationPage) },
	{ path: SCHOOL.CONFIRM_TICKET_ORDER, exact: true, component: authenticatedSchoolAdmin(ConfirmTicketOrder) },
	{ path: SCHOOL.TICKET_ORDER_CONFIRMATION, exact: true, component: authenticatedSchoolAdmin(TicketOrderConfirmationPage) },
	{ path: SCHOOL.ORDER_HISTORY_TICKETS, exact: true, component: authenticatedSchoolAdmin(OrderTicketHistoryPage) },
	{ path: SCHOOL.ORDER_HISTORY_TRAVELCARD, exact: true, component: authenticatedSchoolAdmin(OrderTravelcardHistoryPage) },
	{ path: SCHOOL.ORDER_HISTORY_FINISHED_TICKETS, exact: true, component: authenticatedSchoolAdmin(FinishedTicketsHistoryPage) },
	{ path: SCHOOL.ORDER_HISTORY_FINISHED_TICKETS_DETAILS, exact: true, component: authenticatedSchoolAdmin(FinishedTicketsHistoryDetailsPage) },
	{ path: SCHOOL.ORDER_HISTORY_TICKETS_DETAILS, exact: true, component: authenticatedSchoolAdmin(TicketOrderHistoryDetailsPage) },
	{ path: SCHOOL.ORDER_HISTORY_TRAVELCARD_DETAILS, exact: true, component: authenticatedSchoolAdmin(TravelcardOrderHistoryDetailsPage) },
	{ path: SCHOOL.ORDER_HISTORY_BULK_TRAVELCARD_DETAILS, exact: true, component: authenticatedSchoolAdmin(BulkTravelcardOrderHistoryDetailsPage) },
	{ path: SCHOOL.CONFIRMATION, exact: true, component: authenticatedSchoolAdmin(ConfirmationPage) },
	{ path: SCHOOL.ADMIN_SCHOOL_CHANGE, exact: true, component: authenticatedSchoolAdmin(AdminSchoolChange) },
	// { path: SCHOOL.CONNECT, exact: true, component: ExternalFormConnectAccount },
	{ path: SCHOOL.UNAUTHORIZED, exact: true, component: withUnauthenticatedLayout(NotAuthorizedPage) },
	{ path: SCHOOL.LOGIN, exact: true, component: withUnauthenticatedLayout(LoginPage) },
	{ path: SCHOOL.CALLBACK, exact: true, component: withUnauthenticatedLayout(CallbackPage) },
	{ path: SCHOOL.SIGNOUT, component: withUnauthenticatedLayout(SignoutPage) },
	{ path: `${SCHOOL.HOME}/*`, component: withUnauthenticatedLayout(NotFoundPage) },
];
