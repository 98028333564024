import React from 'react';
import { Dropdown } from '../../common/elements/Dropdown';

const renderPaymentMethods = item => {
	if (!!item.registrationMetadata)
		return (
			<span>
				{item.registrationMetadata.issuer} ({item.registrationMetadata.subject})
			</span>
		);

	return <span>{item.title}</span>;
};

export const PaymentMethodsDropdown = props => {
	const {
		paymentMethods,
		onSelectPaymentMethod,
		isLoadingPaymentMethods = false,
		style,
		className,
	} = props;

	return (
		<Dropdown
			id="paymentMethods"
			items={(paymentMethods || []).map(pm => ({
				title: pm.name,
				id: pm.id,
				registrationMetadata: pm.registrationMetadata || null,
			}))}
			renderSelectedItem={
				isLoadingPaymentMethods
					? () => <div>Laddar betalningsmetoder...</div>
					: renderPaymentMethods
			}
			renderItem={renderPaymentMethods}
			onSelect={onSelectPaymentMethod}
			style={style}
			className={className}
		/>
	);
};
