import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import styled from 'styled-components';
import { userManager } from '../../utils/userManager';
import { LoadPage } from '../common/LoadPage';
import { Card } from '../common/blocks/Card';
import { HomePageMain } from '../common/blocks/HomePageMain';
import LogoWrapper from '../common/blocks/LogoWrapper';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { SCHOOL } from '../../Paths';
import { forTabletPortraitUp } from '../../utils/mediaqueries';

class CallbackPage extends React.Component {
	state = { loginError: false };

	handleSuccessCallback = user => {
		this.props.notifySuccessfulSignIn();
		this.props.push(user.state.location);
	};

	handleErrorCallback = error => {
		console.log(error);
		this.setState({ loginError: true });
	};

	onLoginButtonClick = () => this.props.push(SCHOOL.HOME);

	render() {
		const { loginError } = this.state;

		if (loginError) {
			return (
				<HomePageMain>
					<LogoContainer>
						<LogoWrapper />
					</LogoContainer>
					<CardBox>
						<h4 style={{ marginTop: '0', marginBottom: '1em' }}>
							Vi lyckades inte logga in dig
						</h4>
						<p>
							Det uppstod ett problem vid inloggningen. Gå tillbaka till
							inloggningssidan.
						</p>
						<SecondaryButton
							style={{ marginTop: '1em' }}
							onClick={this.onLoginButtonClick}
						>
							Logga in
						</SecondaryButton>
					</CardBox>
				</HomePageMain>
			);
		}

		return (
			<CallbackComponent
				userManager={userManager}
				successCallback={this.handleSuccessCallback}
				errorCallback={this.handleErrorCallback}
			>
				<LoadPage text="Skickar vidare till vald sida..." />
			</CallbackComponent>
		);
	}
}

export default connect(
	null,
	{
		push,
		notifySuccessfulSignIn: () => dispatch => dispatch({ type: 'USER_SIGNED_IN' })
	},
)(CallbackPage);

const LogoContainer = styled.div`
	margin-left: 2em;
	align-self: center;

	${forTabletPortraitUp`
		align-self: flex-start;
	`}
`;

const CardBox = styled(Card)`
	align-items: center;
	${forTabletPortraitUp`
		padding: 3em;
		margin: 6.5em 0 9.5em 0;
	`}
`;
