import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { UnstyledList } from '../common/elements/UnstyledList';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { ReactComponent as TicketIcon } from '../../images/ticket.svg';
import { ReactComponent as ArticleIcon } from '../../images/article.svg';
import { ReactComponent as ClockIcon } from '../../images/time.svg';
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg';
import { SCHOOL } from '../../Paths';
import { connect } from 'react-redux';
import { themeConfig as regionConfig } from '../../config';

class SchoolMobileNavigation extends Component {
	static propTypes = {
		onNavigation: PropTypes.func,
	};

	navHandler = () => {
		this.props.onNavigation();
	};

	render() {
		const showCulturalTrips = regionConfig.showCulturalTrips;

		return (
			<Fragment>
				<MenuHeader>Meny</MenuHeader>
				<MenuList>
					<li>
						<NavLink
							to={SCHOOL.ORDER}
							activeClassName="active"
							onClick={this.navHandler}
						>
							<TicketIconMenu
								className="svg-icon"
								aria-hidden="true"
								focusable="false"
							/>
							<span>Beställ</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to={SCHOOL.GROUPS}
							activeClassName="active"
							onClick={this.navHandler}
						>
							<ArticleIconMenu
								className="svg-icon"
								aria-hidden="true"
								focusable="false"
							/>
							<span>Elever &amp; Grupper</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to={SCHOOL.ORDER_HISTORY_TICKETS}
							activeClassName="active"
							onClick={this.navHandler}
						>
							<ClockIconMenu
								className="svg-icon"
								aria-hidden="true"
								focusable="false"
								alt=""
							/>
							<span>Orderhistorik</span>
						</NavLink>
					</li>
					{showCulturalTrips && (
						<li>
							<NavLink
								to={SCHOOL.CULTURE_TRIP}
								activeClassName="active"
								onClick={this.navHandler}
							>
								<CalendarIconMenu
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								<span>Kulturresor</span>
							</NavLink>
						</li>
					)}
					{this.props.isOrgAdmin && (
						<li>
							<NavLink
								to={SCHOOL.ADMINS}
								activeClassName="active"
								onClick={this.navHandler}
							>
								<ProfileIconMenu
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								<span>Administratörer</span>
							</NavLink>
						</li>
					)}
				</MenuList>
			</Fragment>
		);
	}
}

const TicketIconMenu = styled(TicketIcon)`
	fill: ${props => props.theme.textOnPrimaryColor};
`;
const ArticleIconMenu = styled(ArticleIcon)`
	fill: ${props => props.theme.textOnPrimaryColor};
`;
const ProfileIconMenu = styled(ProfileIcon)`
	fill: ${props => props.theme.textOnPrimaryColor};
`;
const ClockIconMenu = styled(ClockIcon)`
	fill: ${props => props.theme.textOnPrimaryColor};
`;
const CalendarIconMenu = styled(CalendarIcon)`
	stroke: ${props => props.theme.textOnPrimaryColor};
	fill: ${props => props.theme.primary_color};
`;

const MenuList = styled(UnstyledList)`
	a {
		color: ${props => props.theme.textOnPrimaryColor};
		text-decoration: none;
		padding: 3px 0;
		display: inline-block;
		margin: 5px auto;
	}

	a.active span {
		border-bottom: solid 2px ${props => props.theme.textOnPrimaryColor};
		padding-bottom: 2px;
	}

	.svg-icon {
		margin-right: 0.5em;
	}

	.svg-icon path {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

const MenuHeader = styled.h2`
	color: ${props => props.theme.textOnPrimaryColor};
	border-bottom: solid 2px ${props => props.theme.textOnPrimaryColor};
	padding-bottom: 10px;
	display: inline-block;
	margin-bottom: 1em;
`;

//Connect is needed for navlink to update after redirect from /callback
export default connect(({ router }) => ({ location: router.location }))(
	SchoolMobileNavigation
);
