import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ChevronUp } from '../../../images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../images/chevron-down.svg';
import { forSmallAndMediumPhone } from '../../../utils/mediaqueries';

export class HistoryContainer extends Component {
	static propTypes = {
		historyComponent: PropTypes.func.isRequired,
		transactions: PropTypes.array,
		title: PropTypes.string.isRequired,
		travellerId: PropTypes.string,
	};

	defaultNoOfTransactionsToShow = 3;

	state = {
		expanded: false,
		transactionsToShow: [],
		displayShowMoreButton: false,
	};

	componentDidMount() {
		const { transactions } = this.props;

		const transactionsToShow = transactions.slice(0, this.defaultNoOfTransactionsToShow);
		const displayShowMoreButton = transactionsToShow.length < transactions.length;

		this.setState({
			transactionsToShow,
			displayShowMoreButton
		})
	}

	toggleContainer = () => {
		this.setState(state => ({
			expanded: !state.expanded,
		}));
	};

	renderChevron = () => {
		if (this.state.expanded) {
			return (
				<ChevronUpIcon
					className="svg-icon"
					aria-hidden="true"
					focusable="false"
					alt=""
				/>
			);
		} else {
			return (
				<ChevronDownIcon
					className="svg-icon"
					aria-hidden="true"
					focusable="false"
					alt=""
				/>
			);
		}
	};

	cancelTransaction = transactionId => {
		this.props.onCancelTransaction(transactionId);
	}

	showMoreTransactions = () => {
		const { transactions } = this.props;
		const { transactionsToShow: currentlyShownTransactions } = this.state;

		const end = currentlyShownTransactions.length + this.defaultNoOfTransactionsToShow;

		const transactionsToShow = transactions.slice(0, end);
		const displayShowMoreButton = transactionsToShow.length < transactions.length;

		this.setState({
			transactionsToShow,
			displayShowMoreButton,
		});
	}

	render() {
		const {
			transactions,
			historyComponent: HistoryComponent,
			title,
			travellerId,
		} = this.props;
		const { expanded, displayShowMoreButton, transactionsToShow } = this.state;

		return (
			<ExpandedHistoryContainer>
				<ExpandedHistoryButton onClick={this.toggleContainer}>
					{title}
					{this.renderChevron()}
				</ExpandedHistoryButton>

				{expanded && (
					<DetailedWrapper>
						{transactionsToShow.length === 0 && (
							<NoPurchaseHistory>
								Det finns ingen historik.
							</NoPurchaseHistory>
						)}

						{transactionsToShow.map((x, idx) => (
							<HistoryComponent
								key={idx}
								transaction={x}
								onCancelTransaction={this.cancelTransaction}
								travellerId={travellerId}
							/>
						))}

						{transactionsToShow.length < transactions.length && (
							<ViewMoreContainer>
									<ShowEarlierHistory>
										<Count>
											{transactionsToShow.length} av {transactions.length}
										</Count>
										{displayShowMoreButton && (
											<ShowEarlierButton onClick={this.showMoreTransactions}>
												Visa tidigare
											</ShowEarlierButton>
										)}
									</ShowEarlierHistory>
							</ViewMoreContainer>
						)}
					</DetailedWrapper>
				)}
			</ExpandedHistoryContainer>
		);
	}
}

export const ChevronDownIcon = styled(ChevronDown)`
	fill: none;
`;

export const ChevronUpIcon = styled(ChevronUp)`
	fill: none;
`;

const ShowEarlierButton = styled.button`
	color: ${props => props.theme.link_color};
	border: 0;
	background: none;
	font-style: italic;
`;

const Count = styled.span`
	font-style: italic;
`;

const DetailedWrapper = styled.div`
	padding-left: 2em;
	padding-right: 2em;
	padding-bottom: 0.8em;

	${forSmallAndMediumPhone`
		padding: 0;
	`}
`;
const NoPurchaseHistory = styled.p`
	color: #d30808;
`;

const ViewMoreContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ShowEarlierHistory = styled.div`
	margin-top: 0.8em;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ExpandedHistoryContainer = styled.div`
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.03);
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;

	${forSmallAndMediumPhone`
		box-shadow: none;
	`}
`;

export const ClosedInfo = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 0.5em;
`;

export const ExpandDetailed = styled.button`
	border: 0;
	background: none;
	width: 100%;
	margin-top: 0.5em;
`;

export const ProductInfoWrapper = styled.div`
	font-size: smaller;
`;

export const Container = styled.div`
	border-bottom: 1px solid #000;
`;

export const ExpandedHistoryButton = styled.button`
	border: 0;
	background: none;
	padding: 0.8em;
	cursor: pointer;
	display: flex;
	justify-content: center;

	span {
		width: 100%;
	}
`;
