export const PRIVATE = {
	HOME: '/',
	BUY_TICKET: '/purchase',
	PURCHASE_PURSE_FUNDS: '/purchase/purse',
	PURCHASE_OFFER: '/purchase/offer',
	BUY_TICKET_OPTIONS: '/purchase/options',
	CONFIRM_PURCHASE: '/purchase/confirm',
	CONFIRM_SWISH_PURCHASE: '/purchase/swish/confirm',
	CHECKOUT: '/purchase/checkout',
	TICKET: '/tickets',
	TICKET_CONFIRMATION: '/tickets/confirmation',
	TRAVEL_CARD: '/tickets/travelcard',
	REGISTER_CARD: '/tickets/register-card',
	MOBILE_INFO: '/tickets/mobile',
	PROFILE: '/profile',
	FINALIZE_PAYMENT_CARD_REGISTRATION: '/profile/payment-card/finalize',
	REGISTER: '/register',
	LOGIN: '/login',
	UNAUTHORIZED: '/unauthorized',
	// CONFIRMATION: '/confirmation',
	// ISSUES: '/issues',
	// ISSUES_CONFIRMATION: '/issues/confirmation',
	// DELAY_JOURNEY: '/issues/delay/journey',
	// DELAY_REGISTRATION: '/issues/delay/registration',
	// DELAY_CONTACT: '/issues/delay/contact',
	// DELAY_SUMMARY: '/issues/delay/summary',
	// RECLAMATION: '/issues/reclamation',
	CALLBACK: '/callback',
	SIGNOUT: '/signout',
};

export const SCHOOL = {
	HOME: '/school',
	REGISTER: '/school/register',
	ORDER: '/school/order',
	ORDER_TRAVEL_CARD: '/school/order/travel-card',
	CARD_ORDER_CONFIRMATION: '/school/order/travel-card/confirmation',
	CARD_ORDER_CART: '/school/order/travel-card/cart',
	ORDER_TRAVEL_CARD_BULK: '/school/order/travel-card-bulk',
	GROUPS: '/school/groups/',
	STUDENTS: '/school/groups/students',
	GROUPDETAILS: '/school/groups/:groupId',
	ADMINS: '/school/admins/',
	ORDER_TICKETS: '/school/order/tickets',
	CONFIRM_TICKET_ORDER: '/school/order/tickets/confirm',
	TICKET_ORDER_CONFIRMATION: '/school/order/tickets/confirmation',
	ORDER_HISTORY: '/school/history',
	ORDER_HISTORY_TICKETS: '/school/history/tickets',
	ORDER_HISTORY_TICKETS_DETAILS: '/school/history/tickets/:ticketOrderId',
	ORDER_HISTORY_TRAVELCARD: '/school/history/travelcards',
	ORDER_HISTORY_TRAVELCARD_DETAILS:
		'/school/history/travelcards/:travelcardOrderId',
	ORDER_HISTORY_BULK_TRAVELCARD_DETAILS:
		'/school/history/bulktravelcards/:orderId',
	ORDER_HISTORY_FINISHED_TICKETS: '/school/history/finishedtickets',
	ORDER_HISTORY_FINISHED_TICKETS_DETAILS:
		'/school/history/finishedtickets/:finishedTicketSerialNumber',
	UNAUTHORIZED: '/school/unauthorized',
	LOGIN: '/school/login',
	CALLBACK: '/school/callback',
	SIGNOUT: '/school/signout',
	CONFIRMATION: '/school/confirmation',
	ADMIN_SCHOOL_CHANGE: '/school/admin-school-change',
	CULTURE_TRIP: '/school/culturetrip',
	CULTURE_TRIP_CHOOSE_TRAVELERS: '/school/culturetrip/choose-traveler',
	CULTURE_TRIP_DEPARTURE: '/school/culturetrip/departure',
	CULTURE_TRIP_RETURN: '/school/culturetrip/return',
	CULTURE_TRIP_CONTACT_INFO: '/school/culturetrip/contact-info',
	CULTURE_TRIP_SUMMARY: '/school/culturetrip/summary',
	// CONNECT: '/school/connect'
};
