import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { Dropdown } from '../elements/Dropdown';
import { Radiobuttons } from '../elements/Radiobuttons';
import isEqual from 'lodash/isEqual';
import {H2} from './TicketTypeSelector';

const getSelectedItemTitle = selectedType => 
	`${selectedType.count} ${selectedType.title}`;

export class TravellerTypeSelector extends React.Component {
	state = { selectedType: {}, title: '', lastTypes: undefined };

	componentDidMount() {
		this.selectTraveller();
	}

	componentDidUpdate() {
		this.selectTraveller();
	}

	selectTraveller = () => {
		const { selectedType } = this.state;
		this.props.onSelectTraveller([selectedType]);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { types, currentTravellerType } = nextProps;

		if (isEqual(types, prevState.lastTypes)) return null;

		if (isEmpty(types)) return { selectedType: {}, title: '', lastTypes: undefined };

		// select 1 of first type as default
		let selectedType = { ...types[0], count: 1 };
		// if currentTravellerType is set and match exist, use that type instead
		if (
			currentTravellerType &&
			types.find(t => t.name === currentTravellerType.name)
		) {
			selectedType = { ...currentTravellerType, count: 1 };
		}

		return {
			selectedType,
			title: getSelectedItemTitle(selectedType),
			lastTypes: types,
		};
	}

	selectType = name => {
		const { types } = this.props;
		const propType = types.find(t => t.name === name);
		const selectedType = { ...propType, count: 1 };

		this.setState({
			selectedType,
			title: getSelectedItemTitle(selectedType),
		});
	};

	getTitle = () => this.state.title;

	render() {
		const { types = [] } = this.props;
		const { selectedType } = this.state;

		if (types.length === 0 || isEmpty(selectedType)) {
			return (
				<>
					<H2>{this.props.texts.private.buyticketpage.tickettypeoptions.travellertype}</H2>

					<Dropdown
						id="TravellerTypeDropdown"
						disabled={true}
						onSelect={() => {}}
						items={[]}
						renderSelectedItem={() => (
							<p>Välj resa för att se resenärstyper</p>
						)}
						renderItem={() => {}}
					/>
				</>
			);
		}

		return (
			<>
				<H2>{this.props.texts.private.buyticketpage.tickettypeoptions.travellertype}</H2>

				<Dropdown
					id="TravellerTypeDropdown"
					onSelect={item => {
						return true; // do not close dropdown on click
					}}
					items={[{ title: types[0].title }]}
					renderSelectedItem={() => <div>{this.getTitle()}</div>}
					renderItem={() => (
						<Container>
							<Radiobuttons
								key={this.getTitle()}
								choices={types.map(t => ({ id: t.name, label: t.title }))}
								defaultChoice={selectedType.name}
								onSelect={type => this.selectType(type)}
								renderChoice={(choice, renderRadiobutton) => (
									<>
										<TypeDescription>
											<span>{choice.label}</span>
											<i>{types.find(t => t.name === choice.id).description}</i>
										</TypeDescription>

										{renderRadiobutton()}
									</>
								)}
							/>
						</Container>
					)}
				/>
			</>
		);
	}
}

const TypeDescription = styled.div`
	flex-grow: 1;
	text-align: left;
	margin-bottom: 15px;

	span {
		display: block;
	}
	h4 {
		margin: 0;
		font-weight: 600;
	}
	i {
		font-size: 0.8em;
	}
`;

const Container = styled.div`
	margin-top: 15px;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
`;
