import styled from 'styled-components';
import {forZoomedInUsers} from "./../../../utils/mediaqueries"

export const Header = styled.header.attrs(() => ({
}))`
	background-color: white;
	box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.22);
	padding: 1.5em;
	flex-shrink: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	z-index: 120;

	${forZoomedInUsers`
		flex-direction: column;
		gap: 2rem;
		padding: 1.5em 0.9em;
	`}
`;
