import React, { Component } from 'react';
import styled from 'styled-components';
import { Card } from '../../common/blocks/Card';
import { connect } from 'react-redux';
import withTextContext from '../../../utils/withTextContext';
import { StepVisualizer } from '../../common/blocks/StepVisualizer';
import { forSmallPhoneOnly } from '../../../utils/mediaqueries';
import { LinkButton } from '../../common/elements/LinkButton';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { getRoutesInfo } from '../../../reducer/journey';
import JourneySearch from '../../customer/journey/JourneySearch';
import { actionCreators as journeyActions } from '../../../reducer/journey';
import {
	actionCreators as productActions,
	isLoadingProducts,
} from '../../../reducer/products';
import {
	actionCreators as cultureTripActions,
	departureJourney,
	travelerInfo,
} from '../../../reducer/school/culturetrips';
import { SCHOOL } from '../../../Paths';
import { push } from 'connected-react-router';
import CultureTripWrapper from './CultureTripWrapper';
import { getProductSets, getSelectedProductSet } from '../../../reducer';
import { Loading } from '../../common/blocks/Loading';

// Step 3 in form
class JourneyBack extends Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef();
		this.nextStepButton = React.createRef();
	}

	state = {
		routes: undefined,
		journey: undefined,
	};

	componentDidMount() {
		if (!this.props.departureJourney)
			this.props.push(SCHOOL.CULTURE_TRIP_DEPARTURE);

		this.props.removeJourneys();

		this.containerRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}

	saveAndContinue = e => {
		e.preventDefault();

		const { journey, productSet } = this.state;
		const departureJourney = {
			...journey,
			date: journey.departure,
			productSetId: productSet.id,
		};

		this.props.setReturnJourney(departureJourney);
		this.props.push(SCHOOL.CULTURE_TRIP_CONTACT_INFO);
	};

	back = e => {
		e.preventDefault();
		this.props.push(SCHOOL.CULTURE_TRIP_DEPARTURE);
	};

	selectJourney = async journey => {
		await this.props.getProducts(journey.resultKey, journey.sequenceNumber);
		const { productSets } = this.props;

		let singleProductSets = productSets.filter(ps =>
			ps.tags.some(t => t === 'pc_single')
		);
		let productSet = undefined;
		if (singleProductSets)
			productSet = await this.getProductSet(singleProductSets[0]);

		const routes = getRoutesInfo(journey.routes);

		this.setState(
			{ routes, journey, productSet },
			this.nextStepButton.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			})
		);
	};

	getProductSet = async product => {
		const { travelerInfo } = this.props;

		let travellers = [];
		if (travelerInfo.adultTravellerCount > 0)
			travellers.push(`tc_a*${travelerInfo.adultTravellerCount}`);
		if (
			travelerInfo.childTravellerCount > 0 ||
			travelerInfo.youthTravellerCount > 0
		)
			travellers.push(
				`tc_h*${travelerInfo.childTravellerCount +
					travelerInfo.youthTravellerCount}`
			);

		const setId = product.id;
		const productSetConfiguration = {
			travellers: travellers,
		};

		await this.props.createProductSet(setId, productSetConfiguration);
		return this.props.getSelectedProductSet;
	};

	render() {
		const { isLoadingProductSets } = this.props;
		const { journey } = this.state;
		// The markup for the Step 3 UI
		return (
			<CultureTripWrapper>
				<Container ref={this.containerRef}>
					<CardBox>
						<Header>Ansökan om kulturresor</Header>
						<SubHeader>Återresa</SubHeader>
						<JourneySearch onSelectJourney={this.selectJourney} showGeographyInfo={false} />
						{isLoadingProductSets && <Loading />}
						<StepVisualizer activeStep={3} steps={5} />
						<div ref={this.nextStepButton}>
							<Button onClick={this.saveAndContinue} disabled={!journey}>
								Nästa
							</Button>
						</div>
						<LinkButton onClick={this.back}>&lsaquo; Tillbaka</LinkButton>
					</CardBox>
				</Container>
			</CultureTripWrapper>
		);
	}
}

const Header = styled.h3`
	margin-top: 0;
	margin-bottom: 1em;
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
`;

const SubHeader = styled.h4`
	max-width: 100%;
	text-align: center;
	margin: auto;
`;

const CardBox = styled(Card)`
	max-width: 570px;
	width: 100%;
	padding: 3em;

	${forSmallPhoneOnly`
		box-shadow: none;
		border: none;
		background: none;
		padding: 0;
	`}
`;

const Button = styled(SecondaryButton)`
	width: 100%;
	max-width: 300px;
	margin: 1em auto;
`;

export default withTextContext(
	connect(
		store => ({
			travelerInfo: travelerInfo(store),
			departureJourney: departureJourney(store),
			productSets: getProductSets(store),
			getSelectedProductSet: getSelectedProductSet(store),
			isLoadingProductSets: isLoadingProducts(store),
		}),
		{ ...journeyActions, ...cultureTripActions, ...productActions, push }
	)(JourneyBack)
);
