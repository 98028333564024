import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { Main } from '../common/blocks/Main';
import { forSmallPhoneOnly } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import {
	actionCreators as studentActions,
	getCardLayoutById,
	getCreatedOrders,
} from '../../reducer/students';
import { formatDate } from '../../utils/formatting';
import { getLocationState } from '../../reducer';
import { SCHOOL } from '../../Paths';
import { isEmpty } from 'lodash';
import { CardWide } from '../common/blocks/CardWide';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import {
	actionCreators as cartActions,
	getSchoolCart,
} from '../../reducer/school/cart';
import { getGroupById } from '../../reducer/groups';
import { ReactComponent as ExclamationIcon } from '../../images/exclamation-circle.svg';
import { Error } from '../common/elements/Error';
import classNames from 'classnames';

class CardOrderConfirmationPage extends Component {
	componentWillUnmount() {
		this.props.clearSchoolCart();
	}

	getCartItemsNotOrdered = () =>
		Object.keys(this.props.cart).filter(
			cardLayoutId =>
				!this.props.cardOrders.find(
					order => order.cardLayoutId === cardLayoutId
				)
		);

	render() {
		const { cardOrders, cart, getCardLayoutById, getGroupById } = this.props;

		if (isEmpty(cardOrders)) {
			return (
				<Main>
					<FlexWrapper>
						<NoOrdersBox>
							<OrderBoxHeader>
								<h2>Ingen ny beställning hittades</h2>
							</OrderBoxHeader>
							<AddMoreTickets
								exact
								to={SCHOOL.ORDER_TRAVEL_CARD}
								activeClassName="active"
							>
								+ Lägg ny beställning
							</AddMoreTickets>
						</NoOrdersBox>
					</FlexWrapper>
				</Main>
			);
		}

		const cardLayoutsNotOrdered = this.getCartItemsNotOrdered();
		const orderedCardLayouts = Object.entries(cart).filter(
			order => !cardLayoutsNotOrdered.includes(order[0])
		);

		const {
			companyName,
			attention,
			addressLine1,
			postalCode,
			city,
			createdDate,
			createdBy,
		} = cardOrders[0];

		return (
			cardOrders && (
				<>
					<Main>
						<FlexWrapper>
							<OrderBox>
								<OrderBoxHeader>
									<h1>Tack för din beställning</h1>
								</OrderBoxHeader>

								{!isEmpty(cardLayoutsNotOrdered) && (
									<Error>
										En eller flera ordrar gick ej att beställa. Var god försök igen senare och
										kontakta kundtjänst ifall problemet kvarstår.
									</Error>
								)}

								<div style={{ textAlign: 'center', marginBottom: '3em' }}>
									<h3>Ordernummer:</h3>
									{cardOrders.map(co => (
										<h4 key={co.orderNumber}>{co.orderNumber}</h4>
									))}
								</div>

								<div style={{ marginBottom: '3em' }}>
									<p>Skapad datum: {formatDate(createdDate)}</p>
									<p>Skapad av: {createdBy}</p>
								</div>
								<div>
									<h4>Leveransadress</h4>
									<div>
										<span>Skola</span>
										<p>{companyName}</p>
									</div>
									<div style={{ marginBottom: '2em' }}>
										<span>Attention</span>
										<p>{attention}</p>
									</div>
									<div>
										<span>Gatuadress</span>
										<p>{addressLine1}</p>
									</div>
									<TwoColumns>
										<div>
											<span>Postnummer</span>
											<p>{postalCode}</p>
										</div>
										<div>
											<span>Postort</span>
											<p>{city}</p>
										</div>
									</TwoColumns>
								</div>

								<div>
									<h3>Ordrar</h3>
									{orderedCardLayouts.map((order, idxOrder) => {
										const cardLayoutId = order[0];
										const cardOrder = cardOrders.find(
											c => c.cardLayoutId === cardLayoutId
										);
										const cardLayout = getCardLayoutById(cardLayoutId);
										const groups = order[1];
										return (
											<OrderCardItem key={idxOrder}>
												<ItemInfo>
													<h3>{cardOrder.orderNumber}</h3>
													<h4>{cardLayout.name}</h4>

													{Object.entries(groups).map((group, idxGroup) => {
														const groupId = group[0];
														const groupName = getGroupById(groupId).name;
														const students = Object.values(group[1]);
														return (
															<OrderGroupItem key={idxGroup}>
																<ItemInfo>
																	<h5>
																		<strong>{groupName}</strong> -{' '}
																		{students.length} resekort
																	</h5>
																</ItemInfo>
															</OrderGroupItem>
														);
													})}
												</ItemInfo>
											</OrderCardItem>
										);
									})}
								</div>
								{!isEmpty(cardLayoutsNotOrdered) && (
									<div>
										<FailedOrdersHeader>
											Misslyckade ordrar <ErrorIcon />
										</FailedOrdersHeader>

										{cardLayoutsNotOrdered.map((cardLayoutId, idxOrder) => {
											const cardLayout = getCardLayoutById(cardLayoutId);
											const groups = cart[cardLayoutId];

											return (
												<OrderCardItem
													key={idxOrder}
													className={classNames('failedOrder')}
												>
													<ItemInfo>
														<h4>{cardLayout.name}</h4>

														{Object.entries(groups).map((group, idxGroup) => {
															const groupId = group[0];
															const groupName = getGroupById(groupId).name;
															const students = Object.values(group[1]);
															return (
																<OrderGroupItem key={idxGroup}>
																	<ItemInfo>
																		<h5>
																			<strong>{groupName}</strong> -{' '}
																			{students.length} resekort
																		</h5>
																	</ItemInfo>
																</OrderGroupItem>
															);
														})}
													</ItemInfo>
												</OrderCardItem>
											);
										})}
									</div>
								)}
							</OrderBox>
						</FlexWrapper>
					</Main>
				</>
			)
		);
	}
}

const OrderBoxHeader = styled.div`
	display: flex;
	margin-bottom: 3em;
	justify-content: center;
	flex-direction: row;
	h1 {
		font-weight: 600;
	}
`;

const OrderBox = styled.div`
	width: 100%;
	max-width: 570px;
	text-align: left;
	font-size: 16px;

	h4 {
		font-weight: 600;
	}

	span {
		font-size: 12px;
		font-weight: 600;
	}

	${forSmallPhoneOnly`
		border: none;
		background: none;
		padding: 0;
	`}
`;

const TwoColumns = styled.div`
	display: flex;
	margin-bottom: 2em;
	flex-direction: row;
	width: 226px;
	justify-content: space-between;
`;

const AddMoreTickets = styled(ThemedNavLink)`
	display: block;
	margin-top: -1em;
	margin-bottom: 1em;
`;

const NoOrdersBox = styled(CardWide)`
	text-align: left;
`;

const OrderItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em 0;

	:last-of-type,
	:only-child {
		border-bottom: none;
	}

	&.failedOrder {
		background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
		border: 1px solid #dca7a7;
		padding: 2em 1em;
		margin: 0.75em 0;
	}
`;

const OrderCardItem = styled(OrderItem)`
	border-bottom: 1px solid #bdbdbd;
	:last-of-type,
	:only-child {
		border-bottom: none;
	}
`;

const OrderGroupItem = styled(OrderItem)`
	border-bottom: 1px solid #ededed;
	padding-left: 1em;
`;

const ItemInfo = styled.div`
	width: 100%;

	h4 {
		margin-top: 0;
	}
`;

const FailedOrdersHeader = styled.h3`
	display: flex;
`;

const ErrorIcon = styled(ExclamationIcon)`
	color: #d30808;
	align-self: center;
	margin: 0.25em;
`;

function mapStateToProps(store) {
	return {
		state: getLocationState(store),
		cardOrders: getCreatedOrders(store),
		cart: getSchoolCart(store),
		getCardLayoutById: getCardLayoutById(store),
		getGroupById: getGroupById(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...studentActions,
		...cartActions,
		push,
	})(CardOrderConfirmationPage)
);
