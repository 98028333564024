import styled from 'styled-components';

export const RootContainer = styled.div`
	font-family: ${props => props.theme.text};
	font-display: swap;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`;
