import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ReactComponent as TicketImg } from '../../../images/ticket.svg';
import { ReactComponent as PeriodTicketImg } from '../../../images/period-ticket.svg';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { HiddenRadiobutton } from '../../common/elements/Radiobuttons';
import {
	PRODUCT_TYPES,
	isPeriodProductType,
	isSingleProductType,
} from '../../../reducer/products';
import { forZoomedInUsers } from '../../../utils/mediaqueries';

export class ProductTypeSelector extends Component {
	state = { productType: PRODUCT_TYPES.SINGLE };

	selectProductType = productType => e =>
		this.setState({ productType }, () =>
			this.props.onSelectProductType(this.state.productType)
		);

	handleProductChange = e => {
		this.setState({ productType: e.target.value });
	};

	render() {
		const { productType } = this.state;
		const currentProduct = productType === "single" ? "Enkelbiljett" : "Periodbiljett"

		return (
			<>
				<span className="sr-only" aria-live="polite" aria-atomic="true">{`${currentProduct} vald`}</span>
				<ProductTypeLabel htmlFor="singleProduct">
					<TicketButton
						onClick={this.selectProductType(PRODUCT_TYPES.SINGLE)}
						aria-hidden="false"
						className={classNames({ active: isSingleProductType(productType) })}
					>
						<TicketIcon
							className="svg-icon"
							aria-hidden="true"
							focusable="false"
						/>
						Enkelbiljett
					</TicketButton>
					<HiddenRadiobutton
						id="singleProduct"
						value="single"
						checked={isSingleProductType(productType)}
						onChange={this.handleProductChange}
						tabIndex="-1"
					/>
				</ProductTypeLabel>
				<ProductTypeLabel htmlFor="periodProduct">
					<TicketButton
						onClick={this.selectProductType(PRODUCT_TYPES.PERIOD)}
						aria-hidden="false"
						className={classNames({ active: isPeriodProductType(productType) })}
					>
						<PeriodTicketIcon
							className="svg-icon"
							aria-hidden="true"
							focusable="false"
						/>
						Periodbiljett
					</TicketButton>
					<HiddenRadiobutton
						id="periodProduct"
						value="period"
						checked={isPeriodProductType(productType)}
						onChange={this.handleProductChange}
						tabIndex="-1"
					/>
				</ProductTypeLabel>
			</>
		);
	}
}

const ProductTypeLabel = styled.label`
	& + & {
		margin-left: 2em;

		${forZoomedInUsers`
			margin-left: 0;
		`}
	}
`;

const TicketIcon = styled(TicketImg)`
	width: auto;
	height: 40px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0.5em;
	display: block;
`;

const PeriodTicketIcon = styled(PeriodTicketImg)`
	width: auto;
	height: 40px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0.5em;
	display: block;
`;

const TicketButton = styled(SecondaryButton)`
	display: inline-block;
	background-color: white;
	box-shadow: 0 0 41px 47px rgba(0, 0, 0, 0.03);
	padding: 1em;
	border-radius: 5px;
	color: black;
	max-width: 110px; /* Fix for IE 11 */

	&.active {
		background-color: ${props => props.theme.primary_color};
		color: ${props => props.theme.textOnPrimaryColor};

		path {
			fill: ${props => props.theme.textOnPrimaryColor};
		}
	}

	& > * {
		pointer-events: none;
	}
`;
