import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Main } from '../common/blocks/Main';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { SCHOOL } from '../../Paths';
import {
	actionCreators as orderActions,
	getFinishedTicket,
	isLoadingFinishedTickets,
	hasFinishedTickets,
} from '../../reducer/school/orders';
import { Loading } from '../common/blocks/Loading';
import { formatCurrency, formatDate } from '../../utils/formatting';
import { Info } from '../common/elements/Info';
import { GroupTable } from './GroupPage';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class FinishedTicketsHistoryDetailsPage extends Component {
	componentDidMount() {
		const { hasFinishedTickets } = this.props;

		if (!hasFinishedTickets) {
			this.props.getFinishedTickets();
		}
	}

	render() {
		const {
			getFinishedTicket,
			isLoadingFinishedTickets,
			match,
			texts,
		} = this.props;
		const { finishedTicketSerialNumber } = match.params;

		const ticket = getFinishedTicket(finishedTicketSerialNumber);

		if (isLoadingFinishedTickets) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar för biljetter</p>
					</Banner>
					<Main>
						<Loading text="Laddar orderhistorik..." />
					</Main>
				</>
			);
		}

		if (!ticket) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar för biljetter</p>
					</Banner>
					<Main>
						<Info>Kan inte hitta aktuell biljett.</Info>
					</Main>
				</>
			);
		}

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderhistory}
					showOnMobile={true}
				>
					<h2>Orderhistorik</h2>
					<p>Här visas ordrar för biljetter</p>
				</Banner>
				<Main>
					<OrderHeader>
						<h3>Avslutad biljett</h3>
					</OrderHeader>
					<ThemedNavLink exact to={SCHOOL.ORDER_HISTORY_FINISHED_TICKETS}>
						Tillbaka
					</ThemedNavLink>
					<OrderInfo>
						<span>Biljett: </span>
						<span>{ticket.ticketName}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Avslutad datum: </span>
						<span>{formatDate(ticket.cancelledOn)}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Avslutad av: </span>
						<span>{ticket.cancelledBy}</span>
					</OrderInfo>
					<TicketsTable>
						<thead>
							<tr>
								<th>Elev</th>
								<th>Ordinarie pris</th>
								<th>Kredit</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{ticket.firstName} {ticket.lastName}
								</td>
								<td>{formatCurrency(ticket.price)}</td>
								<td>{formatCurrency(-ticket.credit)}</td>
							</tr>
						</tbody>
					</TicketsTable>
					<InfoBox>
						<span>
							Priserna är beräknade per läsår och redovisas exkl. moms. Summan
							som faktureras kan justeras beroende på beställningsdatum.
						</span>
					</InfoBox>
				</Main>
			</>
		);
	}
}

const OrderHeader = styled.div`
	display: flex;
	margin-bottom: 3em;
	justify-content: center;
	flex-direction: row;
`;

const OrderInfo = styled.div`
	margin-top: 1em;
	margin-bottom: 0.5em;
	display: flex;
	flex-direction: row;
	span:first-child {
		font-weight: 600;
		margin-right: 5px;
	}
`;

const InfoBox = styled.div`
	max-width: 400px;
	float: left;
	margin-top: 2em;
`;

const TicketsTable = styled(GroupTable)`
	th {
		background-color: transparent;
		color: black;
	}
	th,
	td {
		:first-of-type {
			padding-left: 0px;
		}
		:last-of-type {
			padding-right: 0px;
			text-align: right;
		}
	}
`;

export default withTextContext(
	connect(
		store => ({
			getFinishedTicket: getFinishedTicket(store),
			hasFinishedTickets: hasFinishedTickets(store),
			isLoadingFinishedTickets: isLoadingFinishedTickets(store),
		}),
		{ ...orderActions }
	)(FinishedTicketsHistoryDetailsPage)
);
