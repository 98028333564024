import React from "react";
import styled from 'styled-components';
import { ReactComponent as Trash } from '../../../images/trash-new.svg';

export function RemoveButton(props) {
	return (
		<Button {...props}>
		<TrashIcon
			className="svg-icon"
			aria-hidden="true"
			focusable="false"
			alt=""
		/>
		{props.children}
	</Button>
	);
}

const Button = styled.button`
  display: flex;
	font-family: ${props => props.theme.text};
	font-weight: 600;
	padding: 0;
	background: none;
	border: none;
	color: ${props => props.theme.black};

	svg {
		margin-right: 4px;
	}
`

const TrashIcon = styled(Trash)`
	width: 18px;
	height: 18px;
	background-color: ${props => props.theme.primary_color};
	stroke: ${props => props.theme.textOnPrimaryColor};
	border-radius: 10px;
	margin-right: 19px;
	padding: 2px;
`;
