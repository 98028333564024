import './polyfills'; //Only serve to legacy browsers?
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'typeface-open-sans';
import './themes/main.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Oidc from 'oidc-client';
import { processSilentRenew } from 'redux-oidc';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { logConfig } from './config';

if (logConfig.oidc) {
	Oidc.Log.logger = console;
	Oidc.Log.level = Oidc.Log.DEBUG;
}

if (/\/silent-renew/.test(window.location.pathname)) {
	processSilentRenew();
} else {
	ReactDOM.render(<App />, document.getElementById('root'));
	registerServiceWorker();
}
