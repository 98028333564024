import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import startsWith from 'lodash/startsWith';
import { CardWide } from '../common/blocks/CardWide';
import { Main } from '../common/blocks/Main';
import {
	actionCreators as productActions,
	TAG_PREFIXES,
	PRODUCT_TYPES,
	getOffers,
	hasLoadedOffers,
	hasGetOffersError,
	isLoadingOffers,
	travellerTags,
	isProductGroupable,
} from '../../reducer/products';
import { actionCreators as cartActions } from '../../reducer/cart';
import { TicketTypeSelector } from '../common/blocks/TicketTypeSelector';
import { TravellerTypeSelector } from '../common/blocks/TravellerTypeSelector';
import { MultipleTravellerTypeSelector } from '../common/blocks/MultipleTravellerTypeSelector';
import SelectedProduct from './SelectedProduct';
import { Info } from '../common/elements/Info';
import { Error } from '../common/elements/Error';
import RebuyTickets from './RebuyTickets';
import withTextContext from '../../utils/withTextContext';
import PurchaseNavigation from './purchase/PurchaseNavigation';
import { ProductTypeSelector } from './purchase/ProductTypeSelector';
import { Loading } from '../common/blocks/Loading';
import {
	OfferSelector,
	getOffersOfType,
	getTicketType,
	getGeo,
	getOffersByDistinctTicketType,
} from './purchase/OfferSelector';
import { PRIVATE } from '../../Paths';
import { Banner } from '../common/blocks/Banner';

export class PurchaseOfferPage extends Component {
	state = {
		productType: PRODUCT_TYPES.SINGLE,
		ticketType: {},
		travellerTypes: [],
		selectedOffer: undefined,
	};

	componentDidMount() {
		document.title = "Köp ny biljett | Mitt konto"
		this.getOffers({ forceLoadOffers: false });
		this.resetState();

		this.unlisten = this.props.history.listen((location, action) => {
			if (location.pathname === PRIVATE.PURCHASE_OFFER) {
				this.resetState();
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	resetState = cb => {
		this.props.removeProducts();
		this.props.resetCartError();
		this.resetComponentState(cb);
	};

	resetComponentState = cb =>
		this.setState(
			{
				productType: PRODUCT_TYPES.SINGLE,
				ticketType: {},
				travellerTypes: [],
				selectedOffer: undefined,
			},
			cb
		);

	getOffers = async ({ forceLoadOffers = false }) => {
		const { productType } = this.state;
		const { hasLoadedOffers } = this.props;

		if (forceLoadOffers || !hasLoadedOffers) {
			await this.props.getOffers(productType);

			if (!this.props.hasGetOffersError) {
				this.updateSelectedProductSet();
			}
		}
	};

	onSelectTicket = selectedTicketType => {
		const { ticketType } = this.state;
		if (isEqual(selectedTicketType, ticketType)) return

		const newState = { ticketType: selectedTicketType };

		/**
		 * New configuration for the Zone tickets in Halland - only applicable for period tickets
		 * With this new configuration, the ticket that is chosen from the "Välj biljettyp" is a completely different offer
		 * from the currently selected offer - see KOL-2079 for more details
		 */

		if (this.state.productType === PRODUCT_TYPES.PERIOD && Boolean(selectedTicketType)) {
			const offersWithSameName = this.props.offers?.filter(offer => offer.spatialTitle === this.state.selectedOffer?.spatialTitle);
			const updatedOffer = offersWithSameName.find(offer => offer.tags.find(tag => tag.name === selectedTicketType?.name))

			if (updatedOffer) newState.selectedOffer = updatedOffer
		}

		this.setState(newState, () => this.updateSelectedProductSet());
	};

	onSelectTraveller = selectedTravellerTypes => {
		const { ticketType, travellerTypes } = this.state;

		if (isEqual(selectedTravellerTypes, travellerTypes)) {
			return;
		}

		if (!ticketType) {
			return;
		}

		this.setState({ travellerTypes: selectedTravellerTypes }, () =>
			this.updateSelectedProductSet()
		);
	};

	updateSelectedProductSet = () => {
		const { ticketType, travellerTypes, selectedOffer } = this.state;

		if (isEmpty(ticketType) || isEmpty(travellerTypes)) return;

		const setId = selectedOffer.productSetId;
		const productSetConfiguration = {
			product: ticketType.name,
			travellers: this.getTagsWithCount(travellerTypes, TAG_PREFIXES.TRAVELLER),
			addonTags: this.getTagsWithCount(travellerTypes, TAG_PREFIXES.ADDON),
		};

		this.props.createProductSet(setId, productSetConfiguration);
	};

	getTagsWithCount = (travellerTypes, prefix) =>
		travellerTypes
			.filter(t => startsWith(t.name, prefix))
			.map(t => `${t.name}*${t.count}`);

	setProductType = productType =>
		this.setState(
			{
				productType,
				selectedOffer: undefined,
				ticketType: {},
				travellerTypes: [],
			},
			() => this.getOffers({ forceLoadOffers: true })
		);

	filterOffersBySelectedOffer = () => {
		const { selectedOffer, productType } = this.state;
		const { offers } = this.props;

		if (!selectedOffer) return [];

		const offersOfType = getOffersOfType(offers, productType);
		return offersOfType.filter(o => isEqual(getGeo(o), getGeo(selectedOffer)));
	};

	getFilteredTicketTypes = offers =>
		getOffersByDistinctTicketType(offers).map(o => getTicketType(o));

	getTagsBySelectedTicketType = offers => {
		const { ticketType } = this.state;
		return offers.filter(o => isEqual(getTicketType(o).name, ticketType.name));
	};

	getTravellerTags = offers => {
		const ticketTypeOffers = this.getTagsBySelectedTicketType(offers);
		const tags = ticketTypeOffers.reduce(
			(list, o) => [...list, ...o.tags.filter(travellerTags)],
			[]
		);
		return tags.sort((a, b) => a.name.localeCompare(b.name));
	};

	getAddonTags = offers => {
		const ticketTypeOffers = this.getTagsBySelectedTicketType(offers);
		const tags = ticketTypeOffers.reduce(
			(list, o) =>
				o.availableAddonTags ? [...list, ...o.availableAddonTags] : list,
			[]
		);
		return uniqBy(tags, 'name');
	};

	handleSelectOffer = selectedOffer => {
		const currentlySelectedOffer = this.state.selectedOffer || null;
		const offersWithSameNameAsNewSelection = this.props.offers?.filter(
		  (offer) => offer.spatialTitle === selectedOffer.spatialTitle
		);
	  
		const updatedState = {
		  selectedOffer: null,
		};

		if (this.state.productType === PRODUCT_TYPES.PERIOD && Boolean(currentlySelectedOffer) && offersWithSameNameAsNewSelection?.length > 0) {
  	  
		/**
		* New configuration for the Zone tickets in Halland - only applicable for period tickets
		* With this new configuration, the ticket that is chosen from the "Välj biljettyp" is a completely different offer
		* from the currently selected offer - see KOL-2079 for more details
		*/ 
		
		  const currentTravelerType = this.state.travellerTypes?.[0]?.name;
		  const offersWithCurrentTravelerType = offersWithSameNameAsNewSelection?.filter((offer) => offer.tags?.find((tag) => tag.name === currentTravelerType));
	  
		  if (offersWithCurrentTravelerType?.length === 0) {
			updatedState.selectedOffer = offersWithCurrentTravelerType[0] || selectedOffer;
		  } else {
			const newSelectedOffer = offersWithSameNameAsNewSelection?.find(
			  (offer) => {
				return (
				  offer.validityPeriod === currentlySelectedOffer.validityPeriod &&
				  offer.tags.find((tag) => tag.name === currentTravelerType)
				);
			  }
			);
	  
			updatedState.selectedOffer = newSelectedOffer || selectedOffer;
		  }
		} else {
		  updatedState.selectedOffer = selectedOffer;
		}
	  
		this.setState(updatedState, () => this.updateSelectedProductSet());
	  };
	  
		

	render() {
		const { productType, selectedOffer } = this.state;
		const {
			offers,
			hasGetOffersError,
			isLoadingOffers,
			hasLoadedOffers,
			texts,
		} = this.props;

		const filteredOffers = this.filterOffersBySelectedOffer();
		const ticketTypes = this.getFilteredTicketTypes(filteredOffers);
		const travellerTypes = this.getTravellerTags(filteredOffers);
		const addonTypes = this.getAddonTags(filteredOffers);

		const allowMultipleTravellers =
			selectedOffer && isProductGroupable(selectedOffer);

		return (
			<BuyTicketContainer>
				<RebuyTickets />
				<Banner bannerImg={texts.private.images.banners.buyticketpage} />
				<main>
				<Main>
					<>
						<PurchaseNavigation />
						<TicketBox id="buyTicketContainerOffer">
							<H1>Köp ny biljett</H1>
							<ProductTypeSelector onSelectProductType={this.setProductType} />

							{isLoadingOffers && <Loading text="Laddar biljetter..." />}

							{hasLoadedOffers && isEmpty(offers) && (
								<Info>Det finns inga tillgängliga produkter.</Info>
							)}

							{hasGetOffersError && <Error />}

							<OfferSelector
								offers={offers}
								selected={selectedOffer}
								productType={productType}
								onSelectOffer={this.handleSelectOffer}
							/>

							<TicketTypeSelector
								types={ticketTypes}
								onSelectTicket={this.onSelectTicket}
								texts={texts}
							/>

							{allowMultipleTravellers ? (
								<MultipleTravellerTypeSelector
									types={[...travellerTypes, ...addonTypes]}
									onSelectTraveller={this.onSelectTraveller}
									texts={texts}
								/>
							) : (
								<TravellerTypeSelector
									types={travellerTypes}
									onSelectTraveller={this.onSelectTraveller}
									texts={texts}
								/>
							)}
						</TicketBox>
					</>
				</Main>
				</main>
				<SelectedProduct />
			</BuyTicketContainer>
		);
	}
}

const BuyTicketContainer = styled.main`
	position: relative;
`;

const H1 = styled.h1`
	font-size: 30px;
	margin-bottom: 1em;
`;

const TicketBox = styled(CardWide)`
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

export default withTextContext(
	connect(
		store => ({
			offers: getOffers(store),
			hasLoadedOffers: hasLoadedOffers(store),
			hasGetOffersError: hasGetOffersError(store),
			isLoadingOffers: isLoadingOffers(store),
		}),
		{
			...productActions,
			...cartActions,
		}
	)(withRouter(PurchaseOfferPage))
);
