import {
	getProducts,
	createProductSet,
	getRepurchasableProductSets,
	fetchOffers,
} from '../api';
import { getToken, getRawXmlJourneyResult, getTravellerId } from '../reducer';
import isEmpty from 'lodash/isEmpty';

const initialState = {
	data: {},
	isLoading: false,
	errorMessage: '',
	selectedProductSet: {},
	repurchasableProductSets: null,
	offers: undefined,
};

export const TAG_PREFIXES = {
	TRAVELLER: 'tc_',
	ZONE: 'zone_',
	PRODUCT: 'pc_',
	ADDON: 'addon_',
};

export const TAGS = {
	TRAVELLER: 'traveller',
	ZONE: 'zone',
	PRODUCT: 'product',
	GEOGRAPHY: 'geo',
	PROPERTY: 'property',
	GROUPABLE: 'groupable',
};

export const PRODUCT_TYPES = {
	SINGLE: 'single',
	PERIOD: 'period',
};

export const isPeriodProductType = type => type === PRODUCT_TYPES.PERIOD;
export const isSingleProductType = type => type === PRODUCT_TYPES.SINGLE;

export const geoTags = t => t.type === TAGS.GEOGRAPHY;
export const travellerTags = t => t.type === TAGS.TRAVELLER;
export const zoneTags = t => t.type === TAGS.ZONE;
export const productTags = t => t.type === TAGS.PRODUCT;
export const addonTags = t => t.type === TAGS.PROPERTY;

// Sorts addons with number valueType before boolean
export const compareAddonsByValueType = (a, b) => {
	if (a.valueType === 'number' && b.valueType === 'boolean') return -1;
	if (a.valueType === 'boolean' && b.valueType === 'number') return 1;
	return 0;
};

export const ERRORS = {
	EMPTY_PRODUCT_RESPONSE: 'EMPTY_PRODUCT_RESPONSE',
	GET_REPURCHASABLE_PRODUCT_SETS: 'GET_REPURCHASABLE_PRODUCT_SETS',
	CREATE_PRODUCT_SET: 'CREATE_PRODUCT_SET',
	CREATE_PRODUCT_SET_FROM_TICKET: 'CREATE_PRODUCT_SET_FROM_TICKET',
	GET_PRODUCTS: 'GET_PRODUCTS',
};

export const STORE_NAME = 'products';

const REPURCHABLE_FETCH_COUNT = 3;

export const actionCreators = {
	getProducts: (journeyResultKey, sequenceNo) => async (dispatch, getState) => {
		dispatch({ type: 'GET_PRODUCTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const journeySequence = {
			resultKey: journeyResultKey,
			number: sequenceNo,
			rawXml: getRawXmlJourneyResult(state, journeyResultKey),
		};

		try {
			const plannerResponse = await getProducts(journeySequence, token);

			if (hasProducts(plannerResponse.products)) {
				dispatch({
					type: 'EMPTY_PRODUCT_RESPONSE',
					message: ERRORS.EMPTY_PRODUCT_RESPONSE,
				});
				return;
			}

			dispatch({ type: 'GET_PRODUCTS_SUCCESS', plannerResponse });
		} catch (error) {
			dispatch({ type: 'GET_PRODUCTS_FAILURE', message: ERRORS.GET_PRODUCTS });
		}
	},
	removeProducts: () => dispatch => {
		dispatch({ type: 'REMOVE_PRODUCTS' });
	},
	removeSelectedProductSet: () => dispatch => {
		dispatch({ type: 'REMOVE_SELECTED_PRODUCT_SET' });
	},
	createProductSet: (setId, productSetConfiguration) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'CREATE_PRODUCT_SET_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const productSet = await createProductSet(
				setId,
				productSetConfiguration,
				token
			);
			dispatch({ type: 'CREATE_PRODUCT_SET_SUCCESS', productSet });
		} catch (error) {
			dispatch({
				type: 'CREATE_PRODUCT_SET_FAILURE',
				message: ERRORS.CREATE_PRODUCT_SET,
			});
		}
	},
	createProductSetFromTicket: product => async (dispatch, getState) => {
		const setId = product.productSetId;
		const productSetConfiguration = {
			product: product.tags.find(productTags).name,
			travellers: product.tags.filter(travellerTags).map(t => t.name),
			addonTags: product.tags.filter(addonTags).map(t => t.name),
		};
		dispatch({ type: 'CREATE_PRODUCT_SET_FROM_TICKET_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const productSet = await createProductSet(
				setId,
				productSetConfiguration,
				token
			);
			dispatch({ type: 'CREATE_PRODUCT_SET_FROM_TICKET_SUCCESS', productSet });
		} catch (error) {
			dispatch({
				type: 'CREATE_PRODUCT_SET_FROM_TICKET_FAILURE',
				message: ERRORS.CREATE_PRODUCT_SET_FROM_TICKET,
			});
			throw error;
		}
	},
	getRepurchasableProductSets: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_REPURCHASABLE_PRODUCT_SETS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = getTravellerId(state);

		try {
			const repurchasableProductSets = await getRepurchasableProductSets(
				token,
				travellerId,
				REPURCHABLE_FETCH_COUNT
			);
			dispatch({
				type: 'GET_REPURCHASABLE_PRODUCT_SETS_SUCCESS',
				repurchasableProductSets,
			});
		} catch (error) {
			dispatch({
				type: 'GET_REPURCHASABLE_PRODUCT_SETS_FAILURE',
				message: ERRORS.GET_REPURCHASABLE_PRODUCT_SETS,
			});
		}
	},
	setSelectedProductSet: selectedProductSet => dispatch => {
		dispatch({
			type: 'SET_SELECTED_PRODUCT_SET',
			productSet: selectedProductSet,
		});
	},
	getOffers: productType => async (dispatch, getState) => {
		dispatch({ type: 'GET_OFFERS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const offers = await fetchOffers(token, productType);
			dispatch({ type: 'GET_OFFERS_SUCCESS', offers });
		} catch (error) {
			dispatch({ type: 'GET_OFFERS_FAILURE', message: ERRORS.GET_OFFERS });
		}
	},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case 'GET_PRODUCTS_REQUEST':
		case 'CREATE_PRODUCT_SET_REQUEST':
		case 'CREATE_PRODUCT_SET_FROM_TICKET_REQUEST':
		case 'GET_REPURCHASABLE_PRODUCT_SETS_REQUEST':
		case 'GET_OFFERS_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_PRODUCTS_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.plannerResponse,
			};
		case 'GET_REPURCHASABLE_PRODUCT_SETS_SUCCESS':
			return {
				...state,
				isLoading: false,
				repurchasableProductSets: action.repurchasableProductSets,
			};
		case 'GET_PRODUCTS_FAILURE':
		case 'CREATE_PRODUCT_SET_FAILURE':
		case 'CREATE_PRODUCT_SET_FROM_TICKET_FAILURE':
		case 'GET_REPURCHASABLE_PRODUCT_SETS_FAILURE':
		case 'GET_OFFERS_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'REMOVE_PRODUCTS':
			return {
				...state,
				data: initialState.data,
			};
		case 'REMOVE_SELECTED_PRODUCT_SET':
			return {
				...state,
				selectedProductSet: initialState.selectedProductSet,
			};
		case 'CREATE_PRODUCT_SET_SUCCESS':
		case 'CREATE_PRODUCT_SET_FROM_TICKET_SUCCESS':
		case 'SET_SELECTED_PRODUCT_SET':
			return {
				...state,
				isLoading: false,
				selectedProductSet: action.productSet,
			};
		case 'EMPTY_PRODUCT_RESPONSE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_OFFERS_SUCCESS':
			return {
				...state,
				isLoading: false,
				offers: action.offers,
			};
		default:
			return state;
	}
}

const hasProducts = products => isEmpty(products);

// Private selectors

export const isLoadingProducts = store => store[STORE_NAME].isLoading;

export function getSelectedProductSet(state) {
	return state.selectedProductSet;
}

export const getProductSets = state => (state.data ? state.data.products : []);

export const getProductSetTags = store => store[STORE_NAME].data.tags;

export const getTagByNamePrefix = (productSet, prefix) =>
	(productSet.tags || []).find(ps => ps.startsWith(prefix));

export function hasEmptyProductResponse(state) {
	return state.errorMessage === ERRORS.EMPTY_PRODUCT_RESPONSE;
}

export function getRepurchaseProductSets(state) {
	return state.repurchasableProductSets;
}

export const hasProductError = state =>
	state.errorMessage === ERRORS.CREATE_PRODUCT_SET ||
	state.errorMessage === ERRORS.CREATE_PRODUCT_SET_FROM_TICKET;

export const hasGetProductsError = state =>
	state.errorMessage === ERRORS.GET_PRODUCTS;

export const isProductAwaitingActivation = product =>
	!product.active && !product.activation && !!product.expire;

export const isProductLoanedOut = (product, currentTravellerId) =>
	!!product.travellerId &&
	product.travellerId !== currentTravellerId &&
	product.owner.id === currentTravellerId;

export const isProductLoanedToUser = (product, currentTravellerId) =>
	product.travellerId === currentTravellerId &&
	product.owner.id !== currentTravellerId;

export const productCanBeLoaned = (product, currentTravellerId) =>
	!product.travellerId &&
	product.owner.id === currentTravellerId &&
	product.numLoansLeft > 0;

export const productCanBeTransferred = (product, currentTravellerId) =>
	!product.travellerId &&
	product.owner.id === currentTravellerId &&
	product.numTransfersLeft > 0;

export const productCanBeMoved = product => product.numAssignmentsLeft > 0;

export const getOffers = store => store[STORE_NAME].offers || [];

export const isLoadingOffers = store => store[STORE_NAME].isLoading;

export const hasLoadedOffers = store => store[STORE_NAME].offers !== undefined;

export const productHasNoBearer = product => !product.bearer;

export const hasGetOffersError = store =>
	store[STORE_NAME].errorMessage === ERRORS.GET_OFFERS;

export const isMultipleActivationTicket = productSet =>
	productSet.activationPeriod &&
	productSet.activationPeriod !== productSet.validityPeriod;

export const productHasTag = (product, productTag, formatter = (tagName) => tagName) => {
	return (product.tags || []).some(
		t => formatter(t.name) === productTag
	);
}

export const productHasTags = (product, productTags = [], formatter = (tagName) => tagName) => {
	return (product.tags || []).some(
		t => productTags.includes(formatter(t.name))
	);
}

export const isProductGroupable = product =>
	(product.tags || []).some(
		t => t === TAGS.GROUPABLE || t.name === TAGS.GROUPABLE
	);
