import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { Main } from '../common/blocks/Main';
import {
	actionCreators as transactionActions,
	isTransactionDeposited,
} from '../../reducer/transaction';
import { actionCreators as cartActions } from '../../reducer/cart';
import { actionCreators as walletActions } from '../../reducer/wallet';
import { Loading } from '../common/blocks/Loading';
import { Error } from './../common/elements/Error';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { push } from 'connected-react-router';
import {
	isTransactionPurchased,
	hasFinalizeTransactionError,
	getTravellerProducts,
	getLocationState,
	isTransactionPendingFinalize,
	getQrCodeSource,
	isLoadingQrCode,
} from '../../reducer';
import { PRIVATE } from '../../Paths';
import { pollUntil } from '../../utils/polling';
import { getUrlParameter } from '../../utils/parsing';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import { Card } from '../common/blocks/Card';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { Banner } from '../common/blocks/Banner';
import withTextContext from '../../utils/withTextContext';
import { isIosDevice } from '../../utils/isIosDevice';


class ConfirmSwishTransactionPage extends Component {
	state = {
		confirmation: false,
		isLoading: true,
		hasError: false,
	};

	TIMEOUT = 2 * 60 * 1000;

	async componentDidMount() {
		// async componentDidMount will swallow any error
		// so wrap whole body in try-catch clause
		document.title = "Swish transaktion | Mitt konto"
		try {
			const { transactionId, webviewURL } = this.props.locationState;

			const token = this.getSwishToken(webviewURL);
			await this.props.getSwishQrCode(token);

			try {
				await pollUntil(this.isTransactionPendingFinalize, this.TIMEOUT);
			} finally {
				await this.props.finalizeTransaction(transactionId);
				if (this.props.hasFinalizeTransactionError) throw new Error();
			}

			await pollUntil(this.isTransactionComplete);

			this.setSuccessState();
		} catch (error) {
			console.error(error);
			this.setErrorState();
		}
	}

	getSwishToken = url => getUrlParameter(url, 'token');

	setSuccessState = () =>
		this.setState({ confirmation: true, isLoading: false }, async () => {
			if (this.props.isTransactionDeposited) {
				this.props.getPurse();
			} else {
				await this.props.placeCardOrders();

				this.props.deleteCart();
				this.props.getTravellerProducts();
			}
		});

	setErrorState = () => this.setState({ hasError: true, isLoading: false });

	isTransactionPendingFinalize = async () => {
		const { transactionId } = this.props.locationState;
		await this.props.verifyTransaction(transactionId);

		return this.props.isTransactionPendingFinalize;
	};

	isTransactionComplete = async () => {
		const { transactionId } = this.props.locationState;
		await this.props.verifyTransaction(transactionId);

		return (
			this.props.isTransactionPurchased || this.props.isTransactionDeposited
		);
	};

	continue = () => this.props.push(PRIVATE.TICKET);

	render() {
		const { confirmation, isLoading, hasError } = this.state;
		const { transactionId, webviewURL } = this.props.locationState;
		const { qrCodeSource, texts } = this.props;

		if (confirmation) {
			return (
				<>
					<Banner bannerImg={texts.private.images.banners.buyticketpage} />
					<Main>
						<CheckoutFlexWrapper>
							<ConfirmationCard
								onClick={this.continue}
								title="Tack för ditt köp!"
							/>
						</CheckoutFlexWrapper>
					</Main>
				</>
			);
		}

		if (hasError) {
			return (
				<>
					<Banner bannerImg={texts.private.images.banners.buyticketpage} />
					<Main>
						<CheckoutFlexWrapper>
							<CancelledTransactionCard>
								<h4 style={{ marginTop: '0' }}>Kan ej bekräfta köpet</h4>
								<p style={{ maxWidth: '225px' }}>
									Vi kunde inte bekräfta att köpet genomförts. Gå till{' '}
									<ThemedNavLink exact to={PRIVATE.CHECKOUT}>
										varukorgen
									</ThemedNavLink>{' '}
									för att slutföra ditt köp på nytt. Om felet kvarstår, var god
									kontakta kundtjänst och ange ditt transaktionsnummer{' '}
									<HighlightedId>{transactionId}</HighlightedId>.
								</p>
							</CancelledTransactionCard>
						</CheckoutFlexWrapper>
					</Main>
				</>
			);
		}

		return (
			<>
				<Banner bannerImg={texts.private.images.banners.buyticketpage} />
				<Main>
					<CheckoutFlexWrapper id="confirmSwishInnerContainer">
						{qrCodeSource && (
							<>
								<p>
									Skanna QR-koden med din mobil för att genomföra
									Swish-betalningen.
								</p>
								<img src={qrCodeSource} alt="Swish QR-kod" />
							</>
						)}

						{isLoading && (
							<Loading text="Inväntar genomförd Swish-betalning..." />
						)}

						{webviewURL && (
							<>
								<OpenSwishAppLink href={webviewURL}>
									Om du vill betala med Swish på den här enheten, tryck här. {isIosDevice() && <span>Kom tillbaka till denna sida när betalningen är genomförd</span>}
								</OpenSwishAppLink>
							</>
						)}
					</CheckoutFlexWrapper>
				</Main>
			</>
		);
	}
}

const CancelledTransactionCard = styled(Card)`
	align-items: center;
	${forTabletPortraitUp`
		padding: 3em;
	`}
`;

const HighlightedId = styled.span`
	font-weight: 600;
	font-family: monospace;
	letter-spacing: 0.1em;
	padding: 0.2em 0.5em;
	border: solid 1px #999;
	background-color: #ddd;
	color: #555;
	width: auto;
	border-radius: 5px;
`;

const CheckoutFlexWrapper = styled(FlexWrapper)`
	margin-top: 2em;
	flex-direction: column;
	align-items: center;
`;

const OpenSwishAppLink = styled.a`
	margin-top: 0.25rem;
	text-align: center;
`

function mapStateToProps(store) {
	return {
		locationState: getLocationState(store),
		isTransactionPurchased: isTransactionPurchased(store),
		isTransactionPendingFinalize: isTransactionPendingFinalize(store),
		isTransactionDeposited: isTransactionDeposited(store),
		hasFinalizeTransactionError: hasFinalizeTransactionError(store),
		travellerProducts: getTravellerProducts(store),
		qrCodeSource: getQrCodeSource(store),
		isLoadingQrCode: isLoadingQrCode(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...transactionActions,
		...cartActions,
		...walletActions,
		push,
	})(ConfirmSwishTransactionPage),
	false
);
