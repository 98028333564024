import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactComponent as Plus } from '../../../images/plus.svg';
import { ReactComponent as Minus } from '../../../images/minus.svg';
import { Validation, regexToInputPattern } from '../../../utils/validation';
import { Label } from '../elements/Label';
import { Checkbox } from '../elements/Checkbox';
import { SecondaryButton } from '../elements/SecondaryButton';
import { forZoomedInUsersWithSmallScreens } from '../../../utils/mediaqueries';

export class TravellerTypeControl extends Component {
	render() {
		const { type, onDecrement, onIncrement, onInputChange, onCheckboxClick, count } = this.props;
		const isBooleanType = type.valueType === 'boolean';
		let defaultValue = Number(type.default) || 0;
		let control = (
			<>
				<RemoveButton onClick={onDecrement(type.name)} aria-label="Ta bort en biljett">
					<MinusIcon className="svg-icon" aria-hidden="true" />
				</RemoveButton>
				<label htmlFor={type.name} className='sr-only'>Antal biljetter</label>
				<NumberInput
					id={type.name}
					type="number"
					value={count ? count : defaultValue}
					min="0"
					max="100"
					onChange={onInputChange}
					pattern={regexToInputPattern(Validation.onlyDigits)}
					required
					aria-live='polite'
					aria-atomic="true"
				/>
				<AddButton onClick={onIncrement(type.name)} aria-label="Lägg till en biljett" >
					<PlusIcon className="svg-icon" aria-hidden="true" />
				</AddButton>
			</>
		);

		if (isBooleanType) {
			defaultValue = type.default === 'true';
			const checked = count === undefined ? defaultValue : !!count;
			control = (
				<Checkbox
					id={type.name}
					name={type.name}
					checked={checked}
					onCheck={onCheckboxClick}
					style={{ marginRight: '1em' }}
				/>
			);
		}

		return (
			<TypeGroup>
				{isBooleanType ? (
					<TypeDescription htmlFor={type.name} style={{ display: 'flex' }}>
						{control}
						<div style={{ flexGrow: '1' }}>
							<h4>{type.title}</h4>
							<i>{type.description}</i>
						</div>
					</TypeDescription>
				) : (
					<>
						<TypeDescription htmlFor={type.name}>
							<h4>{type.title}</h4>
							<i>{type.description}</i>
						</TypeDescription>

						{control}
					</>
				)}
			</TypeGroup>
		);
	}
}

const TypeDescription = styled(Label)`
	margin-bottom: 1em;
	white-space: normal;

	h4 {
		margin: 0;
		font-weight: 600;
	}
	i {
		font-size: 0.8em;
	}
`;

const TypeGroup = styled.div`
	display: flex;
	justify-content: center;

	label {
		flex-grow: 1;
		text-align: left;

		${forZoomedInUsersWithSmallScreens`
			grid-area: label;
			text-align: center;
		`}
	}

	${forZoomedInUsersWithSmallScreens`
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-template-areas: "label label label" "minus input plus";
		justify-items: center;
	`}
`;

const PlusIcon = styled(Plus)`
	stroke: ${props => props.theme.textOnPrimaryColor};
	stroke-width: 2px;
`;

const MinusIcon = styled(Minus)`
	stroke: black;
	stroke-width: 2px;
`;

const NumberInput = styled.input`
	border: none;
	font-weight: 600;
	text-align: center;
	width: 30px;
	height: 30px;
	min-width: 30px;
	padding: 1px 0;
`;

const IncrementButton = styled(SecondaryButton)`
	padding: 0;
	width: 30px;
	height: 30px;
	min-width: 30px;
`;

const AddButton = styled(IncrementButton)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`;

const RemoveButton = styled(IncrementButton)`
	background-color: #f0f0f0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`;
