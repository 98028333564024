import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import isEmpty from 'lodash/isEmpty';
import { actionCreators as schoolActions } from '../../reducer/school';
import { getAdmin, getSchools } from '../../reducer/school/admins';
import { Card } from '../common/blocks/Card';
import { Main } from '../common/blocks/Main';
import { Dropdown } from '../common/elements/Dropdown';
import { PrimaryButton } from '../common/elements/PrimaryButton';
import { SCHOOL } from '../../Paths';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class AdminSchoolChange extends Component {
	state = {
		selectedSchoolId: undefined,
	};

	selectSchool = async () => {
		const { selectedSchoolId } = this.state;

		await this.props.clearSchoolState();
		await this.props.getSchoolById(selectedSchoolId);

		this.props.push(SCHOOL.GROUPS);
	};

	onSelect = ({ schoolId }) => this.setState({ selectedSchoolId: schoolId });

	render() {
		const { admin, schools, texts } = this.props;

		return (
			<>
				<Banner bannerImg={texts.school.images.banners.adminpage}>
					<h2>Välj skola att administrera</h2>
				</Banner>
				<Main>
					<CardBox>
						<h4>Här väljer du skola att administrera</h4>
						<Container>
							{isEmpty(admin) || isEmpty(schools) ? (
								<SchoolDropdown
									id="schools"
									items={[]}
									onSelect={this.onSelect}
									renderSelectedItem={() => <div>Skolor saknas</div>}
								/>
							) : (
								<SchoolDropdown
									id="schools"
									items={schools.map(s => ({ ...s, title: s.schoolName }))}
									onSelect={this.onSelect}
								/>
							)}
							<SelectSchoolButton onClick={this.selectSchool}>
								Välj skola
							</SelectSchoolButton>
						</Container>
					</CardBox>
				</Main>{' '}
			</>
		);
	}
}

const CardBox = styled(Card)`
	align-items: center;
	${forTabletPortraitUp`
		padding: 3em;
		margin: 6.5em 0 9.5em 0;
		margin-left: auto;
		margin-right: auto;
	`}
`;

const Container = styled.div`
	margin-top: 1em;
	display: flex;
`;

const SelectSchoolButton = styled(PrimaryButton)`
	max-height: 3em;
`;

const SchoolDropdown = styled(Dropdown)`
	margin-right: 1em;
`;

export default withTextContext(
	connect(
		store => ({
			admin: getAdmin(store),
			schools: getSchools(store),
		}),
		{ ...schoolActions, push }
	)(AdminSchoolChange)
);
