import {
	createBearer,
	getBearers as fetchBearers,
	fetchParticipants,
	updateTraveller,
	deleteBearer
} from '../api';
import {
	getToken,
} from '../reducer';
import { TRAVELLER_ERRORS } from '../reducer/traveller';

const initialState = {
	data: null,
	isLoading: false,
	isLoadingParticipants: false,
	errorMessage: '',
	participants: null,
};

export const BEARER_ERRORS = {
	CREATE_BEARER: 'CREATE_BEARER',
	GET_BEARERS: 'GET_BEARERS',
	GET_PARTICIPANTS: 'GET_PARTICIPANTS',
	DELETE_BEARER: 'DELETE_BEARER',
};

export const TYPES = {
	SMARTCARD: 'smartcard',
	SMARTPHONE: 'smartphone',
	PAPER: 'paper',
};

export const actionCreators = {
	createBearer: newBearer => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_BEARER_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const bearer = await createBearer(token, newBearer);
			dispatch({ type: 'CREATE_BEARER_SUCCESS', bearer });

			setBearerAsPreferredIfOnlyOne(state, dispatch);

		} catch (error) {
			dispatch({
				type: 'CREATE_BEARER_FAILURE',
				message: BEARER_ERRORS.CREATE_BEARER,
			});
		}
	},
	deleteBearer: bearerId => async (dispatch, getState) => {
		dispatch({ type: 'DELETE_BEARER_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			await deleteBearer(token, bearerId);
			dispatch({ type: 'DELETE_BEARER_SUCCESS' });
			deletePreferredBearer(state, dispatch);
		} catch (error) {
			dispatch({
				type: 'DELETE_BEARER_FAILURE',
				message: BEARER_ERRORS.DELETE_BEARER,
			});
		}
	},
	getBearers: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_BEARERS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const bearers = await fetchBearers(token);

			dispatch({ type: 'GET_BEARERS_SUCCESS', bearers });

			setBearerAsPreferredIfOnlyOne(state, dispatch);
		} catch (error) {
			dispatch({
				type: 'GET_BEARERS_FAILURE',
				message: BEARER_ERRORS.GET_BEARERS,
			});
		}
	},
	clearBearer: () => async dispatch => {
		dispatch({ type: 'CLEAR_BEARER_STATE' });
	},
	getParticipants: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_PARTICIPANTS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const participants = await fetchParticipants(token);
			dispatch({ type: 'GET_PARTICIPANTS_SUCCESS', participants });
		} catch (error) {
			dispatch({
				type: 'GET_PARTICIPANTS_FAILURE',
				message: BEARER_ERRORS.GET_PARTICIPANTS,
			});
		}
	},
};

const deletePreferredBearer = async (state, dispatch) => {

	const token = getToken(state);
	try {
		dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });
		const traveller = await updateTraveller(token, {
			preferredMtbBearerId: null,
		});
		dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
	} catch (error) {
		dispatch({
			type: 'UPDATE_TRAVELLER_FAILURE',
			message: TRAVELLER_ERRORS.UPDATE_TRAVELLER,
		});
	}
}

const setBearerAsPreferredIfOnlyOne = async (state, dispatch) => {

	const token = getToken(state);
	const bearers = await fetchBearers(token);

	if (bearers.length === 1 && bearers[0].id !== state.traveller.data.preferredMtbBearerId) {
		const bearerId = bearers[0].id;

		try {
			dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });
			const traveller = await updateTraveller(token, {
				preferredMtbBearerId: bearerId,
			});
			dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
		} catch (error) {
			dispatch({
				type: 'UPDATE_TRAVELLER_FAILURE',
				message: TRAVELLER_ERRORS.UPDATE_TRAVELLER,
			});
		}
	}
};


export default function bearerReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'CREATE_BEARER_REQUEST':
		case 'GET_BEARERS_REQUEST':
		case 'DELETE_BEARER_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_PARTICIPANTS_REQUEST':
			return {
				...state,
				isLoadingParticipants: true,
				errorMessage: '',
			};
		case 'CREATE_BEARER_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: state.data ? state.data.push(action.bearer) : [action.bearer],
			};
		case 'DELETE_BEARER_SUCCESS':
			return {
				...state,
				isLoading: false,
			};
		case 'GET_PARTICIPANTS_SUCCESS':
			return {
				...state,
				isLoadingParticipants: false,
				participants: action.participants,
			};
		case 'GET_BEARERS_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.bearers,
			};
		case 'GET_PARTICIPANTS_FAILURE':
			return {
				...state,
				isLoadingParticipants: false,
				errorMessage: action.message,
			};
		case 'CREATE_BEARER_FAILURE':
		case 'GET_BEARERS_FAILURE':
		case 'DELETE_BEARER_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'CLEAR_BEARER_STATE':
			return {
				...state,
				data: null,
			};
		default:
			return state;
	}
}

// Private selector

export function getBearers(state) {
	return state.data;
}

export function getTravelCards(state) {
	return state.data ? state.data.filter(b => b.type === TYPES.SMARTCARD) : [];
}

export function getMobileApps(state) {
	return state.data ? state.data.filter(isMobileApp) : null;
}

export function isLoading(state) {
	return state.isLoading;
}

export function hasGetBearerError(state) {
	return state.errorMessage === BEARER_ERRORS.GET_BEARERS;
}

export function hasCreateBearerError(state) {
	return state.errorMessage === BEARER_ERRORS.CREATE_BEARER;
}

export function hasDeleteBearerError(state) {
	return state.errorMessage === BEARER_ERRORS.DELETE_BEARER;
}

export function getParticipants(state) {
	return state.participants;
}

export function hasParticipantsError(state) {
	return (
		state.errorMessage === BEARER_ERRORS.GET_PARTICIPANTS ||
		(state.participants && state.participants.length === 0)
	);
}

export function isLoadingParticipants(state) {
	return state.isLoadingParticipants;
}

export function hasPreferredBearer(state) {

	if (!state.data) return false;

	return !!Array.from(state.data).find(b => b.preferredMtbBearer);
}

export function hasOnlyOneBearer(state) {
	if (!state.data) return false;

	return state.data.length === 1;
}

export const isMobileApp = bearer => bearer.type === TYPES.SMARTPHONE;
