import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import omit from 'lodash/omit';
import ChevronUp from '../../../images/chevron-up.svg';
import ChevronDown from '../../../images/chevron-down.svg';

const selectedItemCustomizer = (selectedItem, item) =>
	isEqual(item, omit(selectedItem, 'selected'));

export class Dropdown extends React.Component {
	state = {
		showItems: false,
		selectedItem: {},
	};

	static propTypes = {
		items: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				id: PropTypes.string,
				selected: PropTypes.bool,
				disabled: PropTypes.bool,
			})
		).isRequired,
		onSelect: PropTypes.func.isRequired,
		renderItem: PropTypes.func,
		renderSelectedItem: PropTypes.func,
		diabled: PropTypes.bool,
		id: PropTypes.string,
	};

	componentDidMount() {
		this.setStateFromProps();
	}

	componentDidUpdate() {
		this.setStateFromProps();
	}

	setStateFromProps = () => {
		const { items, defaultItem } = this.props;
		const { selectedItem } = this.state;

		if (isEmpty(items) && isEmpty(selectedItem)) return;

		if (isEmpty(items)) {
			this.setDefaultItem(defaultItem || {});
			return;
		}

		if (!isEmpty(selectedItem)) {
			const item = items.find(i => i.title === selectedItem.title);
			if (item && !isEqualWith(selectedItem, item, selectedItemCustomizer)) {
				this.setDefaultItem(item);
				return;
			}
		}

		if (
			!isEmpty(selectedItem) &&
			items.find(i => i.title === selectedItem.title)
		) {
			return;
		}

		const firstItem = { ...items[0], selected: true };
		this.setDefaultItem(defaultItem || firstItem);
	};

	onItemClick = item => () => {
		if (!item.disabled) this.selectItem(item);
	};

	selectItem = selectedItem => {
		const closeDropdown = this.props.onSelect(selectedItem);

		if (closeDropdown instanceof Promise) {
			closeDropdown.then(close =>
				this.updateSelectedState(selectedItem, close)
			);
		} else {
			this.updateSelectedState(selectedItem, closeDropdown);
		}
	};

	updateSelectedState = (selectedItem, close) =>
		this.setState({ showItems: !!close, selectedItem });

	setDefaultItem = newSelectedItem => {
		this.setState(
			({ selectedItem }) => {
				if (isEqual(selectedItem, newSelectedItem)) return null;
				return { selectedItem: newSelectedItem };
			},
			() => this.props.onSelect(this.state.selectedItem)
		);
	};

	toggleItems = () => {
		if (this.props.disabled) {
			return;
		}

		this.setState(state => ({ showItems: !state.showItems }));
	};

	onSelectedItemKeyPress = e => {
		if (e.key === 'Enter') {
			this.toggleItems();
		}
	};

	onItemKeyPress = item => e => {
		if (e.key === 'Enter') {
			this.selectItem(item);
		}
	};

	render() {
		const { showItems, selectedItem } = this.state;
		const {
			items = [],
			renderSelectedItem,
			renderItem,
			disabled,
			className,
			id,
			style,
		} = this.props;
		const selectedItemClass = classNames({
			open: showItems,
			disabled: disabled,
		});
		const moreThanOneItems = items.length > 1;

		return (
			<div id={id} className={className} style={style}>
				<SelectedItem
					onClick={this.toggleItems}
					className={selectedItemClass}
					onKeyPress={this.onSelectedItemKeyPress}
					role="button"
					tabIndex="0"
				>
					{renderSelectedItem ? (
						renderSelectedItem(selectedItem)
					) : (
						<ItemTitle>{selectedItem.title}</ItemTitle>
					)}
				</SelectedItem>
				{showItems && (
					<div role="list">
						{items.map((i, idx) => (
							<Item
								key={idx}
								className={classNames({
									active: i.selected && moreThanOneItems,
									hoverable: moreThanOneItems,
									disabled: i.disabled,
								})}
								onClick={this.onItemClick(i)}
								onKeyPress={this.onItemKeyPress(i)}
								tabIndex="0"
								role="option"
							>
								{renderItem ? renderItem(i) : <ItemTitle>{i.title}</ItemTitle>}
							</Item>
						))}
					</div>
				)}
			</div>
		);
	}
}

const ItemTitle = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
`;

const Item = styled.div`
	padding: 0.5em;
	border: solid 1px #000;
	border-top: none;
	font-size: 1.2em;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;

	&.hoverable:hover,
	&.active {
		background-color: #f0f0f0;
	}

	&.disabled,
	&.disabled:hover {
		background-color: #d9d9d9;
		cursor: default;
	}
`;

const SelectedItem = styled(Item)`
	border: solid 1px #000;
	position: relative;
	background: no-repeat center right 0.5em url(${ChevronDown});
	border-radius: 5px;
	padding-right: 2em;

	&:hover {
		background-color: white;
	}

	&.open {
		background-image: url(${ChevronUp});
	}

	&.disabled,
	&.disabled:hover {
		cursor: default;
		font-style: italic;
		background: none;
	}
`;
