import React from "react";
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../../../images/chevron-right.svg';
import { BaseButton } from "./BaseButton";

export const NextButton = React.forwardRef((props, ref) => {
	return (
		<Button {...props} ref={ref || null}>
			{props.children}
			<ChevronRightIcon
				className="svg-icon"
				aria-hidden="true"
				focusable="false"
				alt=""
			/>
		</Button>
	);
})

const ChevronRightIcon = styled(ChevronRight)`
	fill: none;
	stroke: ${props => props.theme.textOnPrimaryColor};
	margin-left: 4px;
`;

const Button = styled(BaseButton)`
	padding: 0.5em 2em;
	white-space: nowrap;
	background-color: ${props => props.theme.secondary_color};

	&:disabled {
		svg {
			stroke: white;
		}
	}
`;
