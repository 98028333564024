import React from 'react';
import styled from 'styled-components';

export function TravelCardInfo(props) {
	return (
		<Container>
			<h3>Registrera ditt resekort</h3>
			<p>{props.text}</p>
		</Container>
	);
}

const Container = styled.div`
	h3 {
		margin-top: auto;
		margin-bottom: 1em;
		font-size: 22px;
	}
`;
