import { cloneDeep, flattenDeep, isEmpty, merge } from 'lodash';

const initialState = {
	cart: {},
};

export const STORE_NAME = 'cart';

export const actionCreators = {
	addToSchoolCart: ordersToAdd => async dispatch => {
		dispatch({ type: 'ADD_ORDER_TO_SCHOOL_CART', ordersToAdd });
	},
	deleteOrderFromCart: (cardLayout, groupId) => async dispatch => {
		dispatch({ type: 'REMOVE_ORDER_FROM_SCHOOL_CART', cardLayout, groupId });
	},
	clearSchoolCart: () => async dispatch => {
		dispatch({ type: 'CLEAR_SCHOOL_CART' });
	},
};

export default function schoolCartReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'ADD_ORDER_TO_SCHOOL_CART':
			const cart = merge(state.cart, action.ordersToAdd);
			return { ...state, cart };
		case 'REMOVE_ORDER_FROM_SCHOOL_CART':
			const currentCart = cloneDeep(state.cart);

			delete currentCart[action.cardLayout][action.groupId];
			if (isEmpty(Object.keys(currentCart[action.cardLayout])))
				delete currentCart[action.cardLayout];

			return { ...state, cart: currentCart };
		case 'CLEAR_SCHOOL_CART':
		case 'CLEAR_SCHOOL_STATE':
			return { ...state, cart: {} };
		default:
			return state;
	}
}

export const getSchoolCart = store => store[STORE_NAME].cart;

export const getAllStudentsInCart = store =>
	flattenDeep(
		Object.values(store[STORE_NAME].cart).map(group =>
			Object.values(group).map(students =>
				Object.values(students).map(s => s.travellerId)
			)
		)
	);
