import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Validation, regexToInputPattern } from '../../../utils/validation';
import { TextInput } from '../../common/elements/TextInput';
import { Error } from '../../common/elements/Error';
import { actionCreators as customerActions } from '../../../reducer/customer';
import { actionCreators as cartActions } from '../../../reducer/cart';
import { actionCreators as travellerActions } from '../../../reducer/traveller';
import {
	getCustomer,
	getShowInvalidOrderAddressMessage,
	getTraveller,
} from '../../../reducer';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import merge from 'lodash/merge';

const isValidPostalCode = postalCode =>
	Validation.postalCode.test(postalCode) && regexToInputPattern(postalCode).length === 5;

const isValidAddress = ({
	name = '',
	streetAddress = '',
	postalCode = '',
	locality = '',
}) => {
	return (
		name.length > 0 &&
		streetAddress.length > 0 &&
		postalCode.length > 0 &&
		locality.length > 0 &&
		isValidPostalCode(postalCode)
	);
};

export class AddressForm extends Component {
	state = {
		name: this.props.name,
		careOf: this.props.careOf,
		streetAddress: this.props.streetAddress,
		postalCode: this.props.postalCode,
		locality: this.props.locality,
		isValid: false,
	};

	componentDidMount() {
		if (!this.props.customer) {
			this.props.getCustomer();
		}
		if (!this.props.traveller) {
			this.props.getTraveller();
		}
		this.setOrderAddress();
	}

	componentDidUpdate() {
		this.setOrderAddress();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const address = pick(nextProps.customer, [
			'careOf',
			'streetAddress',
			'postalCode',
			'locality',
		]);
		const travellerName = pick(nextProps.traveller, ['name']);
		const customerAddress = merge(address, travellerName);

		if (!isEqual(customerAddress, prevState.customerAddress)) {
			return {
				...customerAddress,
				customerAddress,
				isValid: isValidAddress(customerAddress),
			};
		}

		return null;
	}

	setOrderAddress = () => {
		const address = pick(this.state, [
			'name',
			'careOf',
			'streetAddress',
			'postalCode',
			'locality',
			'isValid',
		]);
		this.props.setOrderAddress(address);
	};

	handleChange = target => {
		this.setState(
			{
				[target.id]: target.value,
				isValid: this.isValid({ [target.id]: target.value }),
			},
			() => {
				if (this.props.showInvalidMessage) {
					this.props.setInvalidOrderAddress(false);
				}
			}
		);
	};

	isValid = newState => isValidAddress({ ...this.state, ...newState });

	render() {
		const { careOf, streetAddress, postalCode, locality, name } = this.state;
		const { style, showInvalidMessage } = this.props;

		return (
			<div style={style}>
				<div className="form-group">
					<FullWidthTextInput
						type="text"
						value={name}
						onChange={this.handleChange}
						id="name"
						placeholder="Namn*"
						handleChange={this.handleChange}
						autoComplete="name"
						errorMessage="Vänligen se till att skriva ett korrekt namn."
					/>
				</div>
				<div className="form-group">
					<FullWidthTextInput
						type="text"
						value={careOf}
						onChange={this.handleChange}
						id="careOf"
						placeholder="Namn c/o"
						handleChange={this.handleChange}
						autoComplete="on"
					/>
				</div>
				<div className="form-group">

					<FullWidthTextInput
						type="text"
						value={streetAddress}
						onChange={this.handleChange}
						id="streetAddress"
						placeholder="Adress*"
						handleChange={this.handleChange}
						autoComplete="street-address"
						errorMessage="Vänligen se till att skriva en korrekt adress."
					/>
				</div>
				<div className="form-group" style={{ display: 'flex', flexWrap: "wrap" }}>
					<PostalCodeInput
						type="number"
						value={postalCode}
						onChange={this.handleChange}
						id="postalCode"
						placeholder="Postnr.*"
						handleChange={this.handleChange}
						pattern={regexToInputPattern(Validation.postalCode)}
						validateOnChange={true}
						autoComplete="postal-code"
						errorMessage="Ogiltigt postnummer."
						style={{width: "15rem"}}
					/>
					<FullWidthTextInput
						type="text"
						value={locality}
						onChange={this.handleChange}
						id="locality"
						placeholder="Ort*"
						handleChange={this.handleChange}
						pattern={regexToInputPattern(Validation.city)}
						validateOnChange={true}
						autoComplete="locality"
						errorMessage="Ogiltigt ort."
					/>
				</div>

				{showInvalidMessage && <Error role="alert">Adressen är inte korrekt ifylld.</Error>}
			</div>
		);
	}
}

const PostalCodeInput = styled(TextInput)`
	width: 90px;
	margin-right: 1em;
`;

const FullWidthTextInput = styled(TextInput)`
	width: 100%;
`;

export default connect(
	store => ({
		customer: getCustomer(store),
		traveller: getTraveller(store),
		showInvalidMessage: getShowInvalidOrderAddressMessage(store),
	}),
	{ ...customerActions, ...cartActions, ...travellerActions }
)(AddressForm);
