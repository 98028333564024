import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { Main } from '../common/blocks/Main';
import { forSmallPhoneOnly } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { SCHOOL } from '../../Paths';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import {
	actionCreators as schoolActions,
	getTicketOrderProductById,
	isLoadingCreateTicketOrder,
	getSchool
} from '../../reducer/school';
import { actionCreators as groupActions } from '../../reducer/groups';
import {
	getLocationState,
	getAllGroups,
	hasTicketOrderProducts,
	isLoadingSchool,
} from '../../reducer';
import { TextInput } from '../common/elements/TextInput';
import { Loading } from '../common/blocks/Loading';
import TicketOrderRowSummary from './blocks/TicketOrderRowSummary';
import { ErrorMessageHandler } from '../common/blocks/ErrorMessageHandler';

class ConfirmTicketOrder extends Component {
	state = {
		orderInfo: null,
		invoiceReference: '',
		hasError: false,
		errorMessage: '',
	};

	VAT_PERCENTAGE = 6;

	componentDidMount() {
		const { state } = this.props;

		if (!state) return;

		const { selectedGroup, ticketOrderRows } = state;
		this.prepareData(selectedGroup, ticketOrderRows);
	}

	prepareData = async (group, ticketOrderRows) => {
		this.setState({
			group,
			ticketOrderRows,
			invoiceReference: this.props.school.invoiceReference,
		});
	};

	createOrder = async () => {
		const { ticketOrderRows, invoiceReference, group } = this.state;

		try {
			await this.props.createTicketOrder({
				ticketOrderRows,
				invoiceReference,
				groupId: group.groupId,
			});
			this.props.push(SCHOOL.TICKET_ORDER_CONFIRMATION);
		} catch (error) {
			this.setState({ hasError: true, errorMessage: error.message });
		}
	};

	onHandleChange = target => this.setState({ [target.id]: target.value });

	render() {
		const {
			isLoadingSchool,
			isCreatingTicketOrder,
		} = this.props;
		const {
			group,
			ticketOrderRows,
			invoiceReference,
			hasError,
			errorMessage,
		} = this.state;

		if (isLoadingSchool) return <Loading />;

		if (!ticketOrderRows) return null;

		return (
			<>
				<Main>
					<FlexWrapper>
						<OrderBox>
							<OrderBoxHeader>
								<h1>Bekräfta order</h1>
							</OrderBoxHeader>
							<Column>
								<p>
									Grupp: <span>{group.name}</span>
								</p>
							</Column>
							<Column>
								<p>
									<b>Fakturareferens</b>
								</p>

								<TextInput
									id="invoiceReference"
									value={invoiceReference}
									type="text"
									handleChange={this.onHandleChange}
									disabled={isCreatingTicketOrder}
								/>
							</Column>
							<TicketOrderRowSummary ticketOrderRows={ticketOrderRows} />
							{hasError && ErrorMessageHandler(errorMessage)}
							<ButtonWrapper>
								{isCreatingTicketOrder && (
									<Loading text="Skapar biljettorder..." />
								)}
								<ConfirmButton
									onClick={this.createOrder}
									disabled={isCreatingTicketOrder}
								>
									Bekräfta order
								</ConfirmButton>
								<ThemedNavLink exact to={SCHOOL.ORDER_TICKETS}>
									Avbryt
								</ThemedNavLink>
							</ButtonWrapper>
						</OrderBox>
					</FlexWrapper>
				</Main>
			</>
		);
	}
}

const OrderBox = styled.div`
	width: 100%;
	max-width: 570px;
	text-align: left;

	${forSmallPhoneOnly`
		border: none;
		background: none;
		padding: 0;
		`}
`;

const OrderBoxHeader = styled.div`
	display: flex;
	margin-bottom: 3em;
	justify-content: center;
	flex-direction: row;
	h1 {
		font-weight: 600;
	}
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	span {
		margin-bottom: 20px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 25px;
`;

const ConfirmButton = styled(SecondaryButton)`
	display: block;
	width: 100%;
	height: 50px;
	margin-bottom: 1em;
`;

export default withTextContext(
	connect(
		store => ({
			state: getLocationState(store),
			groups: getAllGroups(store),
			ticketOrderProduct: getTicketOrderProductById(store),
			hasTicketOrderProducts: hasTicketOrderProducts(store),
			school: getSchool(store),
			isLoadingSchool: isLoadingSchool(store),
			isCreatingTicketOrder: isLoadingCreateTicketOrder(store),
		}),
		{
			...schoolActions,
			...groupActions,
			push,
		}
	)(ConfirmTicketOrder)
);
