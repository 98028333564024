import {
	getToken,
	getStudents as getStudentsFromStore,
	getStudentIdsInGroup as getStudentIdsInGroupFromStore,
} from '.';
import { getAdmin } from './school/admins';
import { getSchool, getSchoolVendorId } from './school';
import {
	fetchStudents,
	fetchStudentsInGroup,
	fetchCardLayouts,
	getBearersFromStudent,
	fetchBearersForGroup,
	fetchBearersForMultipleStudents,
	updateStudentProductBearer,
	importStudents,
	createStudentBearer,
	updateTravellerById,
	getTravellerById,
	deleteStudentBearer,
	createBulkCardOrders,
	getStudentTickets,
	fetchTicketsForMultipleStudents,
	createStudent,
	cancelStudentTicket,
	referStudent,
	fetchAllReferredAccounts,
	fetchGroupReferredAccounts,
	deleteReferredAccount,
	transferTicketToReferredAccount,
	returnTicketFromReferredAccount,
	updateCrmStudent,
	updateReferredAccount,
} from '../api';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import { unique } from '../utils/helperFunctions';
import { TYPES as BEARER_TYPES } from './bearer';
import { clearSerialFormatting } from '../utils/formatting';

const initialState = {
	data: null,
	isLoading: false,
	isLoadingImport: false,
	importErrorReason: '',
	errorMessage: '',
	students: {},
	bearers: {},
	tickets: {},
	studentBearers: {},
	studentTickets: {},
	referredAccounts: {},
	referredAccountTickets: {},
	cardOrders: {},
	referredAccountsLoaded: false,
	isLoadingStudentBearers: false,
	isLoadingStudentTickets: false,
	isLoadingTicketAction: false,
	isLoadingReferredAccounts: false,
	isLoadingBulkSpecificCardOrders: false,
	hasCreateStudentBearerError: '',
};

export const STUDENT_ERRORS = {
	GET_STUDENTS: 'GET_STUDENTS',
	GET_STUDENT_BEARERS: 'GET_STUDENT_BEARERS',
	GET_IMPORT_TEMPLATE: 'GET_IMPORT_TEMPLATE',
	IMPORT_STUDENTS: 'IMPORT_STUDENTS',
	CREATE_BEARER: 'CREATE_BEARER',
	GET_TICKETS: 'GET_TICKETS',
	GET_CARD_LAYOUTS: 'GET_CARD_LAYOUTS',
	CREATE_BULK_CARD_ORDERS: 'CREATE_BULK_CARD_ORDERS',
	GET_MULTIPLE_TICKETS: 'GET_MULTIPLE_TICKETS',
	UPDATE_TRAVELLER: 'UPDATE_TRAVELLER',
	GET_TRAVELLER: 'UPDATE_TRAVELLER',
	SET_PREFERED_BEARER: 'SET_PREFERED_BEARER',
	CREATE_STUDENT: 'CREATE_STUDENT',
	UPDATE_PRODUCT_BEARER: 'UPDATE_PRODUCT_BEARER',
	DELETE_BEARER: 'DELETE_BEARER',
	GET_REFERRED_ACCOUNTS: 'GET_REFERRED_ACCOUNTS',
	GET_REFERRED_ACCOUNT_TICKETS: 'GET_REFERRED_ACCOUNT_TICKETS',
	DELETE_REFERRED_ACCOUNT: 'DELETE_REFERRED_ACCOUNT',
	TRANSFER_REFERRED_ACCOUNT: 'TRANSFER_REFERRED_ACCOUNT',
	RETURN_REFERRED_ACCOUNT: 'RETURN_REFERRED_ACCOUNT',
	CONNECT_ACCOUNT: 'CONNECT_ACCOUNT',
};

export const IMPORT_ERRORS = {
	FILE_MISSING: 'Fil saknas',
	INVALID_FILETYPE: 'Ej giltig filtyp',
	FILE_EMPTY: 'Ingen data kunde läsas',
	MISSING_REQUIRED_FIELD:
		'Import misslyckades - Ett obligatoriskt fält var tomt för en eller flera elever (Förnamn, Efternamn, Personnummer, Kortlayout)',
};

export const STORE_NAME = 'students';

export const actionCreators = {
	createBearer: (name, serial, travellerId) => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_STUDENT_BEARER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		const newBearer = {
			name,
			serial: clearSerialFormatting(serial),
			owner: {
				id: travellerId,
				type: 'traveller',
			},
		};

		try {
			const createdBearer = await createStudentBearer(
				token,
				newBearer,
				school.schoolId,
				travellerId
			);
			dispatch({ type: 'CREATE_STUDENT_BEARER_SUCCESS', createdBearer });
			return createdBearer;
		} catch (error) {
			dispatch({
				type: 'CREATE_STUDENT_BEARER_FAILURE',
				message: STUDENT_ERRORS.CREATE_BEARER,
			});
			throw error;
		}
	},
	deleteStudentBearer: (bearerId, travellerId) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'DELETE_BEARER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			await deleteStudentBearer(token, school.schoolId, bearerId, travellerId);
			dispatch({ type: 'DELETE_BEARER_SUCCESS', bearerId, travellerId });
		} catch (error) {
			dispatch({
				type: 'DELETE_BEARER_FAILURE',
				message: STUDENT_ERRORS.DELETE_BEARER,
			});
			throw error;
		}
	},
	connectAccount: (contactId, phoneNumber) => async dispatch => {
		dispatch({ type: 'CONNECT_ACCOUNT_REQUEST' });

		try {
			const referredAccount = await referStudent(contactId, phoneNumber);
			dispatch({
				type: 'CONNECT_ACCOUNT_SUCCESS',
				referredAccount,
			});
		} catch (error) {
			dispatch({
				type: 'CONNECT_ACCOUNT_FAILURE',
				message: STUDENT_ERRORS.CONNECT_ACCOUNT,
			});
			throw error;
		}
	},
	updateReferredAccount: (contactId, phoneNumber) => async dispatch => {
		dispatch({ type: 'UPDATE_REFERREDACCOUNT_REQUEST' });

		try {
			const traveller = await updateReferredAccount(contactId, phoneNumber);
			dispatch({
				type: 'UPDATE_REFERREDACCOUNT_SUCCESS',
				traveller,
			});
			return traveller;
		} catch (error) {
			dispatch({
				type: 'UPDATE_REFERREDACCOUNT_FAILURE',
				message: STUDENT_ERRORS.CONNECT_ACCOUNT,
			});
			throw error;
		}
	},
	updateReferredAccountTickets: (referredAccountTravellerId) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'GET_REFERRED_ACCOUNT_TICKETS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const referredAccountTickets = await getStudentTickets(
				token,
				school.schoolId,
				referredAccountTravellerId
			);
			dispatch({
				type: 'GET_REFERRED_ACCOUNT_TICKETS_SUCCESS',
				referredAccountTickets,
				referredAccountTravellerId,
			});
		} catch (error) {
			dispatch({
				type: 'GET_REFERRED_ACCOUNT_TICKETS_FAILURE',
				message: STUDENT_ERRORS.GET_REFERRED_ACCOUNT_TICKETS,
			});
		}
	},
	createBulkCardOrder: orders => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_BULK_CARD_ORDERS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const cardOrders = await createBulkCardOrders(
				token,
				school.schoolId,
				orders
			);

			const orderedCardLayoutIds = cardOrders.map(c => c.cardLayoutId);
			const orderedStudentIds = orderedCardLayoutIds.reduce(
				(array, cardLayoutId) => {
					const order = orders.find(o => o.cardLayoutId === cardLayoutId);
					return [...array, ...order.cardOrderRows.map(r => r.contactId)];
				},
				[]
			);

			dispatch({
				type: 'CREATE_BULK_CARD_ORDERS_SUCCESS',
				cardOrders,
				orderedStudentIds,
			});
		} catch (error) {
			dispatch({
				type: 'CREATE_BULK_CARD_ORDERS_FAILURE',
				message: STUDENT_ERRORS.CREATE_BULK_CARD_ORDERS,
			});
			throw error;
		}
	},
	updateStudentProductBearer: (productId, bearerId, traveller) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'UPDATE_PRODUCT_BEARER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const product = await updateStudentProductBearer(
				token,
				school.schoolId,
				productId,
				bearerId,
				traveller
			);

			dispatch({ type: 'UPDATE_PRODUCT_BEARER_SUCCESS', product });
		} catch (error) {
			dispatch({
				type: 'UPDATE_PRODUCT_BEARER_FAILURE',
				message: STUDENT_ERRORS.UPDATE_PRODUCT_BEARER,
			});
			throw error;
		}
	},
	getStudents: (showLoading = true) => async (dispatch, getState) => {
		dispatch({ type: 'GET_STUDENTS_REQUEST', showLoading });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const students = await fetchStudents(token, school.schoolId);

			dispatch({ type: 'GET_STUDENTS_SUCCESS', students });
		} catch (error) {
			dispatch({
				type: 'GET_STUDENTS_FAILURE',
				message: STUDENT_ERRORS.GET_STUDENTS,
			});
		}
	},
	getCardLayouts: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_CARD_LAYOUTS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const cardLayouts = await fetchCardLayouts(token);

			dispatch({ type: 'GET_CARD_LAYOUTS_SUCCESS', cardLayouts });
		} catch (error) {
			dispatch({
				type: 'GET_CARD_LAYOUTS_FAILURE',
				message: STUDENT_ERRORS.GET_CARD_LAYOUTS,
			});
		}
	},

	loadStudentsInGroup: groupId => async (dispatch, getState) => {
		dispatch({ type: 'GET_STUDENTS_IN_GROUP_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const students = await fetchStudentsInGroup(
				token,
				school.schoolId,
				groupId
			);
			dispatch({ type: 'GET_STUDENTS_IN_GROUP_SUCCESS', students, groupId });
			dispatch({ type: 'UPDATE_STUDENTS_IN_GROUP', students, groupId });
		} catch (error) {
			dispatch({
				type: 'GET_STUDENTS_IN_GROUP_FAILURE',
				message: STUDENT_ERRORS.GET_STUDENTS,
			});
		}
	},
	loadStudentBearers: studentId => async (dispatch, getState) => {
		dispatch({ type: 'GET_STUDENT_BEARERS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const bearers = await getBearersFromStudent(
				token,
				school.schoolId,
				studentId
			);
			dispatch({ type: 'GET_STUDENT_BEARERS_SUCCESS', bearers, studentId });
		} catch (error) {
			dispatch({
				type: 'GET_STUDENT_BEARERS_FAILURE',
				message: STUDENT_ERRORS.GET_STUDENT_BEARERS,
			});
		}
	},
	getBearersForGroup: groupId => async (dispatch, getState) => {
		dispatch({ type: 'GET_BEARERS_FOR_GROUP_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);
		const studentIds = getStudentIdsInGroupFromStore(state)(groupId);

		try {
			const bearers = await fetchBearersForGroup(
				token,
				school.schoolId,
				groupId,
				studentIds
			);

			dispatch({ type: 'GET_BEARERS_FOR_GROUP_SUCCESS', bearers, studentIds });
		} catch (error) {
			dispatch({
				type: 'GET_BEARERS_FOR_GROUP_FAILURE',
				message: error.message,
			});
		}
	},
	getBearersForAllStudents: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_BEARERS_FOR_ALL_STUDENTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);
		const students = getStudentsFromStore(state);
		const studentIds = Object.keys(students);

		try {
			const bearers = await fetchBearersForMultipleStudents(
				token,
				school.schoolId,
				studentIds
			);

			dispatch({
				type: 'GET_BEARERS_FOR_ALL_STUDENTS_SUCCESS',
				bearers,
				studentIds,
			});
		} catch (error) {
			dispatch({
				type: 'GET_BEARERS_FOR_ALL_STUDENTS_FAILURE',
				message: error.message,
			});
		}
	},
	updateTravellerById: updatedTraveller => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const traveller = await updateTravellerById(
				token,
				school.schoolId,
				updatedTraveller.travellerId,
				updatedTraveller
			);
			dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
			return traveller;
		} catch (error) {
			dispatch({
				type: 'UPDATE_TRAVELLER_FAILURE',
				message: STUDENT_ERRORS.UPDATE_TRAVELLER,
			});
			throw error;
		}
	},
	getTravellerById: travellerId => async (dispatch, getState) => {
		const state = getState();

		if (isLoadingStudentTraveller(state)) {
			dispatch({ type: 'ABORT_GET_TRAVELLER_REQUEST' });
			return;
		}

		dispatch({ type: 'GET_TRAVELLER_REQUEST' });

		const token = getToken(state);
		const school = getSchool(state);

		try {
			const studentTraveller = await getTravellerById(
				token,
				school.schoolId,
				travellerId
			);
			dispatch({ type: 'GET_TRAVELLER_SUCCESS', studentTraveller });
		} catch (error) {
			dispatch({
				type: 'GET_TRAVELLER_FAILURE',
				message: STUDENT_ERRORS.GET_TRAVELLER,
			});
		}
	},
	importStudents: (groupId, file) => async (dispatch, getState) => {
		dispatch({ type: 'IMPORT_STUDENTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const admin = getAdmin(state);
		const school = getSchool(state);

		try {
			const importedStudents = await importStudents(
				token,
				school.schoolId,
				admin.id,
				groupId,
				file
			);

			dispatch({
				type: 'IMPORT_STUDENTS_SUCCESS',
				importedStudents,
				groupId,
			});
		} catch (error) {
			dispatch({
				type: 'IMPORT_STUDENTS_FAILURE',
				message: STUDENT_ERRORS.IMPORT_STUDENTS,
				reason: error.message,
			});
		}
	},
	clearImportError: () => async dispatch => {
		dispatch({ type: 'CLEAR_IMPORT_STUDENTS_ERROR' });
	},
	clearImportStudents: () => async dispatch => {
		dispatch({ type: 'CLEAR_IMPORT_STUDENTS' });
	},
	getStudentTickets: travellerId => async (dispatch, getState) => {
		dispatch({ type: 'GET_STUDENT_PRODUCTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const tickets = await getStudentTickets(
				token,
				school.schoolId,
				travellerId
			);
			dispatch({ type: 'GET_STUDENT_PRODUCTS_SUCCESS', travellerId, tickets });
		} catch (error) {
			dispatch({
				type: 'GET_STUDENT_PRODUCTS_FAILURE',
				message: STUDENT_ERRORS.GET_TICKETS,
			});
		}
	},
	getTicketsForStudentIds: studentIds => async (dispatch, getState) => {
		dispatch({ type: 'GET_MULTIPLE_STUDENT_PRODUCTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const tickets = await fetchTicketsForMultipleStudents(
				token,
				school.schoolId,
				studentIds
			);

			dispatch({ type: 'GET_MULTIPLE_STUDENT_PRODUCTS_SUCCESS', tickets });
		} catch (error) {
			dispatch({
				type: 'GET_MULTIPLE_STUDENT_PRODUCTS_FAILURE',
				message: STUDENT_ERRORS.GET_MULTIPLE_TICKETS,
			});
		}
	},
	getTicketsForStudents: studentIds => async (dispatch, getState) => {
		dispatch({ type: 'GET_STUDENTS_PRODUCTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const tickets = await fetchTicketsForMultipleStudents(
				token,
				school.schoolId,
				studentIds
			);

			dispatch({ type: 'GET_STUDENTS_PRODUCTS_SUCCESS', tickets });
		} catch (error) {
			dispatch({
				type: 'GET_STUDENTS_PRODUCTS_FAILURE',
				message: STUDENT_ERRORS.GET_MULTIPLE_TICKETS,
			});
		}
	},
	cancelStudentTicket: (
		studentId,
		ticketId,
		isReferredTicket = false
	) => async (dispatch, getState) => {
		dispatch({ type: 'CANCEL_STUDENT_TICKET_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const admin = getAdmin(state);

		try {
			await cancelStudentTicket(token, admin.schoolId, ticketId, admin.id);

			dispatch({ type: 'CANCEL_STUDENT_TICKET_SUCCESS', studentId, ticketId });
			if (isReferredTicket) {
				const referredAccount = getReferredAccount(state)(studentId);
				dispatch({
					type: 'CLEAR_REFERRED_TICKET',
					travellerId: referredAccount.referredAccountTravellerId,
					ticketId,
				});
			}
		} catch (error) {
			dispatch({
				type: 'CANCEL_STUDENT_TICKET_FAILURE',
				message: STUDENT_ERRORS.GET_MULTIPLE_TICKETS,
			});
			throw error;
		}
	},
	setPreferredBearer: (newBearer, createdBearer) => async (
		dispatch,
		getState
	) => {
		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);
		const travellerId = newBearer.owner.id;

		dispatch({ type: 'SET_PREFERED_BEARER_REQUEST' });
		try {
			const traveller = await updateTravellerById(
				token,
				school.schoolId,
				travellerId,
				{ preferredMtbBearerId: createdBearer.id }
			);
			dispatch({ type: 'SET_PREFERED_BEARER_SUCCESS', traveller });
		} catch (error) {
			dispatch({
				type: 'SET_PREFERED_BEARER_FAILURE',
				message: STUDENT_ERRORS.SET_PREFERED_BEARER,
			});
		}
	},
	createStudent: (student, groupId) => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_STUDENT_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const admin = getAdmin(state);

		try {
			const createdStudent = await createStudent(
				token,
				admin,
				groupId,
				student
			);

			dispatch({ type: 'CREATE_STUDENT_SUCCESS', createdStudent });
			dispatch({
				type: 'UPDATE_GROUP_WITH_CREATED_STUDENT',
				studentId: createdStudent.travellerId,
				groupId,
			});
		} catch (error) {
			dispatch({
				type: 'CREATE_STUDENT_FAILURE',
				message: STUDENT_ERRORS.CREATE_STUDENT,
			});
			throw error;
		}
	},
	getAllReferredAccounts: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_ALL_REFFERED_ACCOUNTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const referredAccounts = await fetchAllReferredAccounts(
				token,
				school.schoolId
			);

			dispatch({ type: 'GET_ALL_REFFERED_ACCOUNTS_SUCCESS', referredAccounts });
		} catch (error) {
			dispatch({
				type: 'GET_ALL_REFFERED_ACCOUNTS_FAILURE',
				message: STUDENT_ERRORS.GET_REFERRED_ACCOUNTS,
			});
			throw error;
		}
	},
	getAllReferredAccountTickets: (referredAccountTravellerIds = []) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'GET_ALL_REFFERED_ACCOUNTS_TICKETS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);
		const connectedAccountIds = isEmpty(referredAccountTravellerIds)
			? getAllReferredAccountIds(state)
			: referredAccountTravellerIds;

		try {
			const referredAccountTickets = await fetchTicketsForMultipleStudents(
				token,
				school.schoolId,
				connectedAccountIds
			);
			dispatch({
				type: 'GET_ALL_REFFERED_ACCOUNTS_TICKETS_SUCCESS',
				referredAccountTickets,
				connectedAccountIds,
			});
		} catch (error) {
			dispatch({
				type: 'GET_ALL_REFFERED_ACCOUNTS_TICKETS_FAILURE',
				message: STUDENT_ERRORS.GET_REFERRED_ACCOUNT_TICKETS,
			});
		}
	},
	deleteReferredAccounts: (contactId, travellerId) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'DELETE_REFERRED_ACCOUNT_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			await deleteReferredAccount(token, school.schoolId, contactId);
			dispatch({ type: 'DELETE_REFERRED_ACCOUNT_SUCCESS', travellerId });
		} catch (error) {
			dispatch({
				type: 'DELETE_REFERRED_ACCOUNT_FAILURE',
				message: STUDENT_ERRORS.DELETE_REFERRED_ACCOUNT,
			});
			throw error;
		}
	},
	clearReferredAccounts: () => (
		dispatch
	) => {
		dispatch({ type: 'CLEAR_REFERRED_ACCOUNTS_SUCCESS' });
	},
	transferTicketToReferredAccount: (product, travellerId) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'TRANSFER_TICKET_REFERRED_ACCOUNT_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);
		const { referredAccountTravellerId } = getReferredAccount(state)(
			travellerId
		);

		try {
			await transferTicketToReferredAccount(token, school.schoolId, product.id);
			dispatch({
				type: 'TRANSFER_TICKET_REFERRED_ACCOUNT_SUCCESS',
				product,
				travellerId,
				referredAccountTravellerId,
			});
		} catch (error) {
			dispatch({
				type: 'TRANSFER_TICKET_REFERRED_ACCOUNT_FAILURE',
				message: STUDENT_ERRORS.TRANSFER_REFERRED_ACCOUNT,
			});
			throw error;
		}
	},
	returnTicketFromReferredAccount: (product, travellerId) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'RETURN_TICKET_REFERRED_ACCOUNT_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);
		const { referredAccountTravellerId } = getReferredAccount(state)(
			travellerId
		);

		try {
			await returnTicketFromReferredAccount(token, school.schoolId, product.id);
			dispatch({
				type: 'RETURN_TICKET_REFERRED_ACCOUNT_SUCCESS',
				product,
				travellerId,
				referredAccountTravellerId,
			});
		} catch (error) {
			dispatch({
				type: 'RETURN_TICKET_REFERRED_ACCOUNT_FAILURE',
				message: STUDENT_ERRORS.RETURN_REFERRED_ACCOUNT,
			});
			throw error;
		}
	},
	getGroupReferredAccounts: groupId => async (dispatch, getState) => {
		dispatch({ type: 'GET_GROUP_REFERRED_ACCOUNTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const referredAccounts = await fetchGroupReferredAccounts(token, school.schoolId, groupId);

			dispatch({ type: 'GET_GROUP_REFFERED_ACCOUNTS_SUCCESS', groupId, referredAccounts });
		} catch (error) {
			dispatch({
				type: 'GET_GROUP_REFERRED_ACCOUNTS_FAILURE',
				message: STUDENT_ERRORS.GET_REFERRED_ACCOUNTS,
			});

			throw error;
		}
	},
	getGroupReferredAccountTickets: (groupId) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'GET_GROUP_REFFERED_ACCOUNTS_TICKETS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		const connectedAccountIds = getGroupReferredAccountIds(state)(groupId);

		try {
			const referredAccountTickets = await fetchTicketsForMultipleStudents(
				token,
				school.schoolId,
				connectedAccountIds,
			);

			dispatch({
				type: 'GET_GROUP_REFFERED_ACCOUNTS_TICKETS_SUCCESS',
				referredAccountTickets,
				connectedAccountIds,
			});
		} catch (error) {
			dispatch({
				type: 'GET_GROUP_REFFERED_ACCOUNTS_TICKETS_FAILURE',
				message: STUDENT_ERRORS.GET_REFERRED_ACCOUNT_TICKETS,
			});
		}
	},
	updateCustomerByTravellerId: (travellerId, updatedStudent) => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_STUDENT_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const student = await updateCrmStudent(token, school.schoolId, travellerId, updatedStudent);
			dispatch({ type: 'UPDATE_STUDENT_SUCCESS', student });
		} catch (error) {
			dispatch({ type: 'UPDATE_STUDENT_FAILURE', message: '' });
			throw error;
		}

	},
};

export default function studentsReducer(state, action) {
	state = state || initialState;
	switch (action.type) {
		case 'GET_STUDENTS_REQUEST':
		case 'GET_CARD_LAYOUTS_REQUEST':
		case 'GET_STUDENTS_IN_GROUP_REQUEST':
			const isLoading =
				action.showLoading !== undefined ? action.showLoading : true;
			return {
				...state,
				isLoading,
				errorMessage: '',
			};
		case 'CREATE_BULK_CARD_ORDERS_REQUEST':
			return {
				...state,
				isLoadingBulkSpecificCardOrders: true,
				errorMessage: '',
			};
		case 'CREATE_STUDENT_BEARER_REQUEST':
			return {
				...state,
				isLoadingCreateStudentBearer: true,
				errorMessage: '',
			};
		case 'UPDATE_TRAVELLER_REQUEST':
			return {
				...state,
				isLoadingUpdateTraveller: true,
				errorMessage: '',
			};
		case 'GET_TRAVELLER_REQUEST':
			return {
				...state,
				isLoadingTraveller: true,
				errorMessage: '',
			};
		case 'GET_STUDENT_PRODUCTS_REQUEST':
		case 'GET_MULTIPLE_STUDENT_PRODUCTS_REQUEST':
		case 'GET_STUDENTS_PRODUCTS_REQUEST':
			return {
				...state,
				errorMessage: '',
				isLoadingStudentTickets: true,
			};
		case 'UPDATE_PRODUCT_BEARER_REQUEST':
		case 'CANCEL_STUDENT_TICKET_REQUEST':
		case 'TRANSFER_TICKET_REFERRED_ACCOUNT_REQUEST':
		case 'RETURN_TICKET_REFERRED_ACCOUNT_REQUEST':
			return {
				...state,
				isLoadingTicketAction: true,
				errorMessage: '',
			};
		case 'IMPORT_STUDENTS_REQUEST':
			return {
				...state,
				isLoadingImport: true,
				errorMessage: '',
				importErrorReason: '',
			};
		case 'CREATE_STUDENT_REQUEST':
		case 'DELETE_BEARER_REQUEST':
		case 'CONNECT_ACCOUNT_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'DELETE_REFERRED_ACCOUNT_REQUEST':
		case 'GET_ALL_REFFERED_ACCOUNTS_REQUEST':
		case 'GET_GROUP_REFERRED_ACCOUNTS_REQUEST':
		case 'GET_ALL_REFFERED_ACCOUNTS_TICKETS_REQUEST':
		case 'GET_GROUP_REFFERED_ACCOUNTS_TICKETS_REQUEST':
			return {
				...state,
				isLoadingReferredAccounts: true,
				errorMessage: '',
			};
		case 'GET_CARD_LAYOUTS_SUCCESS':
			return {
				...state,
				isLoading: false,
				cardLayouts: action.cardLayouts,
			};
		case 'GET_STUDENTS_SUCCESS':
			return {
				...state,
				isLoading: false,
				students: {
					...action.students.reduce(
						(o, student) => ({ ...o, [student.travellerId]: student }),
						{}
					),
				},
			};
		case 'DELETE_BEARER_SUCCESS':
			const bearers = cloneDeep(state.bearers);
			const studentBearers = cloneDeep(state.studentBearers);

			delete bearers[action.bearerId];
			delete studentBearers[action.travellerId][action.bearerId];

			return {
				...state,
				bearers,
				studentBearers,
			};
		case 'CREATE_STUDENT_SUCCESS':
			return {
				...state,
				isLoading: false,
				students: {
					...state.students,
					[action.createdStudent.travellerId]: action.createdStudent,
				},
			};
		case 'GET_STUDENTS_IN_GROUP_SUCCESS':
			return {
				...state,
				isLoading: false,
				students: {
					...state.students,
					...action.students.reduce(
						(o, student) => ({ ...o, [student.travellerId]: student }),
						{}
					),
				},
			};
		case 'CREATE_STUDENT_BEARER_SUCCESS':
			let ownerId = action.createdBearer.owner.id;
			return {
				...state,
				isLoadingCreateStudentBearer: false,
				bearers: {
					...state.bearers,
					[action.createdBearer.id]: action.createdBearer,
				},
				studentBearers: {
					...state.studentBearers,
					[ownerId]: state.studentBearers[ownerId]
						? unique([
							...state.studentBearers[ownerId],
							action.createdBearer.id,
						])
						: [action.createdBearer.id],
				},
			};
		case 'CREATE_BULK_CARD_ORDERS_SUCCESS':
			return {
				...state,
				isLoadingBulkSpecificCardOrders: false,
				cardOrders: action.cardOrders,
			};
		case 'IMPORT_STUDENTS_SUCCESS':
			return {
				...state,
				isLoadingImport: false,
				importedStudents: action.importedStudents,
			};
		case 'GET_STUDENT_BEARERS_REQUEST':
			return {
				...state,
				isLoadingStudentBearers: false,
				errorMessage: '',
			};
		case 'GET_STUDENT_BEARERS_SUCCESS':
			return {
				...state,
				isLoadingStudentBearers: false,
				bearers: {
					...state.bearers,
					...action.bearers.reduce(
						(o, bearer) => ({ ...o, [bearer.id]: bearer }),
						{}
					),
				},
				studentBearers: {
					...state.studentBearers,
					[action.studentId]: action.bearers.map(b => b.id),
				},
			};
		case 'UPDATE_TRAVELLER_SUCCESS':
			return {
				...state,
				isLoadingUpdateTraveller: false,
				data: action.traveller,
				errorMessage: '',
			};
		case 'UPDATE_PRODUCT_BEARER_SUCCESS':
			return {
				...state,
				isLoadingTicketAction: false,
				tickets: { ...state.tickets, [action.product.id]: action.product },
				errorMessage: '',
			};
		case 'CONNECT_ACCOUNT_SUCCESS':
			return {
				...state,
				isLoading: false,
				referredAccounts: {
					...state.referredAccounts,
					[action.referredAccount.travellerId]: action.referredAccount,
				},
			};
		case 'GET_TRAVELLER_SUCCESS':
			return {
				...state,
				isLoadingTraveller: false,
				studentTraveller: action.studentTraveller,
				errorMessage: '',
			};
		case 'GET_STUDENT_PRODUCTS_SUCCESS': {
			return {
				...state,
				isLoadingStudentTickets: false,
				tickets: {
					...state.tickets,
					...action.tickets.reduce(
						(o, ticket) => ({ ...o, [ticket.id]: ticket }),
						{}
					),
				},
				studentTickets: {
					...state.studentTickets,
					[action.travellerId]: action.tickets.map(t => t.id),
				},
			};
		}
		case 'GET_MULTIPLE_STUDENT_PRODUCTS_SUCCESS': {
			const flatList = Object.values(action.tickets).reduce(
				(list, tickets) => list.concat(tickets),
				[]
			);
			return {
				...state,
				isLoadingStudentTickets: false,
				tickets: {
					...state.tickets,
					...flatList.reduce(
						(o, ticket) => ({ ...o, [ticket.id]: ticket }),
						{}
					),
				},
				studentTickets: {
					...state.studentTickets,
					...Object.keys(action.tickets).reduce((o, studentId) => {
						const previous = state.studentTickets[studentId] || [];
						const toAdd = action.tickets[studentId].map(t => t.id);
						const ticketIds = unique([...previous, ...toAdd]);
						return { ...o, [studentId]: ticketIds };
					}, {}),
				},
			};
		}
		case 'GET_STUDENTS_PRODUCTS_SUCCESS': {
			const flatList = Object.values(action.tickets).reduce(
				(list, tickets) => list.concat(tickets),
				[]
			);
			return {
				...state,
				isLoadingStudentTickets: false,
				tickets: {
					...state.tickets,
					...flatList.reduce(
						(o, ticket) => ({ ...o, [ticket.id]: ticket }),
						{}
					),
				},
				studentTickets: {
					...state.studentTickets,
					...Object.keys(action.tickets).reduce((o, studentId) => {
						return { ...o, [studentId]: action.tickets[studentId].map(t => t.id) };
					}, {}),
				},
			};
		}
		case 'CANCEL_STUDENT_TICKET_SUCCESS': {
			let tickets = cloneDeep(state.tickets);
			delete tickets[action.ticketId];
			return {
				...state,
				isLoadingTicketAction: false,
				studentTickets: {
					...state.studentTickets,
					[action.studentId]: state.studentTickets[action.studentId].filter(
						studentTicket => studentTicket !== action.ticketId
					),
				},
				tickets,
			};
		}
		case 'CLEAR_REFERRED_TICKET': {
			console.log(action);
			return {
				...state,
				referredAccountTickets: {
					...state.referredAccountTickets,
					[action.travellerId]: state.referredAccountTickets[
						action.travellerId
					].filter(ticket => ticket.id !== action.ticketId),
				},
			};
		}
		case 'GET_ALL_REFFERED_ACCOUNTS_SUCCESS': {
			return {
				...state,
				isLoadingReferredAccounts: false,
				referredAccountsLoaded: true,
				referredAccounts: {
					...state.referredAccounts,
					...action.referredAccounts.reduce(
						(o, referredAccount) => ({
							...o,
							[referredAccount.travellerId]: referredAccount,
						}),
						{}
					),
				},
			};
		}
		case 'GET_GROUP_REFFERED_ACCOUNTS_SUCCESS': {
			return {
				...state,
				isLoadingReferredAccounts: false,
				referredAccounts: {
					...state.referredAccounts,
					...action.referredAccounts.reduce(
						(o, referredAccount) => ({
							...o,
							[referredAccount.travellerId]: referredAccount,
						}),
						{}
					),
				},
			};
		}
		case 'GET_GROUP_REFFERED_ACCOUNTS_TICKETS_SUCCESS':
		case 'GET_ALL_REFFERED_ACCOUNTS_TICKETS_SUCCESS': {
			return {
				...state,
				isLoadingReferredAccounts: false,
				referredAccountTickets: {
					...state.referredAccountTickets,
					...action.connectedAccountIds.reduce(
						(o, travellerId) => ({
							...o,
							[travellerId]: action.referredAccountTickets[travellerId],
						}),
						{}
					),
				},
			};
		}
		case 'GET_REFERRED_ACCOUNT_TICKETS_SUCCESS': {
			return {
				...state,
				isLoadingReferredAccounts: false,
				referredAccountTickets: {
					...state.referredAccountTickets,
					[action.referredAccountTravellerId]: action.referredAccountTickets,
				},
			};
		}
		case 'DELETE_REFERRED_ACCOUNT_SUCCESS': {
			let referredAccounts = cloneDeep(state.referredAccounts);
			let referredAccountTickets = cloneDeep(state.referredAccountTickets);
			delete referredAccounts[action.travellerId];
			delete referredAccountTickets[action.travellerId];
			return {
				...state,
				isLoadingReferredAccounts: false,
				referredAccounts,
				referredAccountTickets,
			};
		}
		case 'CLEAR_REFERRED_ACCOUNTS_SUCCESS': {
			return {
				...state,
				referredAccounts: {},
				referredAccountTickets: {},
			};
		}
		case 'TRANSFER_TICKET_REFERRED_ACCOUNT_SUCCESS': {
			let tickets = cloneDeep(state.tickets);
			let studentTickets = cloneDeep(state.studentTickets);

			delete tickets[action.product.id];

			let studentTicketIds = studentTickets[action.travellerId];
			studentTicketIds = studentTicketIds.filter(
				id => id !== action.product.id
			);
			studentTickets[action.travellerId] = studentTicketIds;

			return {
				...state,
				isLoadingTicketAction: false,
				tickets,
				studentTickets,
			};
		}
		case 'RETURN_TICKET_REFERRED_ACCOUNT_SUCCESS': {
			let referredAccountTickets = cloneDeep(state.referredAccountTickets);
			let referredAccountTravellerTickets =
				referredAccountTickets[action.referredAccountTravellerId];
			referredAccountTravellerTickets = referredAccountTravellerTickets.filter(
				ticket => ticket.id !== action.product.id
			);
			if (referredAccountTravellerTickets.length > 0)
				referredAccountTickets[
					action.referredAccountTravellerId
				] = referredAccountTravellerTickets;
			else delete referredAccountTickets[action.referredAccountTravellerId];

			return {
				...state,
				isLoadingTicketAction: false,
				referredAccountTickets,
			};
		}
		case 'GET_CARD_LAYOUT_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'CREATE_BULK_CARD_ORDERS_FAILURE':
			return {
				...state,
				isLoadingBulkSpecificCardOrders: false,
				errorMessage: action.message,
			};
		case 'GET_STUDENT_BEARERS_FAILURE':
			return {
				...state,
				isLoadingStudentBearers: false,
				errorMessage: action.message,
			};
		case 'UPDATE_TRAVELLER_FAILURE':
			return {
				...state,
				isLoadingUpdateTraveller: false,
				errorMessage: action.message,
			};
		case 'CONNECT_ACCOUNT_FAILURE':
		case 'CREATE_STUDENT_FAILURE':
		case 'GET_STUDENTS_FAILURE':
		case 'GET_STUDENTS_IN_GROUP_FAILURE':
		case 'DELETE_BEARER_FAILURE':
			return {
				...state,
				errorMessage: action.message,
				isLoading: false,
			};
		case 'GET_TRAVELLER_FAILURE':
			return {
				...state,
				errorMessage: action.message,
				isLoadingTraveller: false,
			};
		case 'GET_STUDENT_PRODUCTS_FAILURE':
		case 'GET_MULTIPLE_STUDENT_PRODUCTS_FAILURE':
		case 'GET_STUDENTS_PRODUCTS_FAILURE':
			return {
				...state,
				errorMessage: action.message,
				isLoadingStudentTickets: false,
			};
		case 'CANCEL_STUDENT_TICKET_FAILURE':
		case 'UPDATE_PRODUCT_BEARER_FAILURE':
		case 'TRANSFER_TICKET_REFERRED_ACCOUNT_FAILURE':
		case 'RETURN_TICKET_REFERRED_ACCOUNT_FAILURE':
			return {
				...state,
				errorMessage: action.message,
				isLoadingTicketAction: false,
			};
		case 'CREATE_STUDENT_BEARER_FAILURE':
			return {
				...state,
				isLoadingCreateStudentBearer: false,
				errorCreateMessage: action.message,
			};
		case 'IMPORT_STUDENTS_FAILURE':
			return {
				...state,
				isLoadingImport: false,
				errorMessage: action.message,
				importErrorReason: action.reason,
			};
		case 'GET_BEARERS_FOR_GROUP_REQUEST':
		case 'GET_BEARERS_FOR_ALL_STUDENTS_REQUEST':
			return {
				...state,
				isLoadingStudentBearers: true,
				errorMessage: '',
			};
		case 'GET_BEARERS_FOR_GROUP_SUCCESS':
		case 'GET_BEARERS_FOR_ALL_STUDENTS_SUCCESS':
			return {
				...state,
				isLoadingStudentBearers: false,
				bearers: {
					...state.bearers,
					...action.bearers.reduce(
						(o, bearer) => ({ ...o, [bearer.id]: bearer }),
						{}
					),
				},
				studentBearers: {
					...state.studentBearers,
					...action.studentIds.reduce((o, studentId) => {
						const previous = state.studentBearers[studentId] || [];
						const toAdd = action.bearers
							.filter(b => b.owner.id === studentId)
							.map(b => b.id);
						const bearerIds = unique([...previous, ...toAdd]);
						return { ...o, [studentId]: bearerIds };
					}, {}),
				},
			};
		case 'GET_BEARERS_FOR_GROUP_FAILURE':
		case 'GET_BEARERS_FOR_ALL_STUDENTS_FAILURE':
			return {
				...state,
				isLoadingStudentBearers: false,
				errorMessage: action.message,
			};
		case 'DELETE_REFERRED_ACCOUNT_FAILURE':
		case 'GET_ALL_REFFERED_ACCOUNTS_FAILURE':
		case 'GET_GROUP_REFFERED_ACCOUNTS_FAILURE':
		case 'GET_ALL_REFFERED_ACCOUNTS_TICKETS_FAILURE':
		case 'GET_GROUP_REFFERED_ACCOUNTS_TICKETS_FAILURE':
			return {
				...state,
				isLoadingReferredAccounts: false,
				errorMessage: action.message,
			};
		case 'CLEAR_IMPORT_STUDENTS_ERROR':
			return {
				...state,
				errorMessage: '',
				importErrorReason: '',
			};
		case 'CLEAR_IMPORT_STUDENTS':
			return {
				...state,
				importedStudents: [],
			};
		case 'REMOVE_GROUP_FROM_STUDENT':
			const students = cloneDeep(state.students);

			const newGroupInformation = students[
				action.travellerId
			].groupInformation.filter(g => g.groupId !== action.groupId);

			if (isEmpty(newGroupInformation)) {
				delete students[action.travellerId];
			} else {
				students[action.travellerId].groupInformation = newGroupInformation;
			}
			return {
				...state,
				students,
			};
		case 'REMOVE_GROUP_SUCCESS': {
			let studentsToUpdate = getStudentsByIds(state)(action.studentIdsInGroup);

			let updatedStudents = studentsToUpdate.reduce((acc, student) => {
				student.groupInformation = student.groupInformation.filter(
					g => g.groupId !== action.groupId
				);
				acc[student.travellerId] = student;
				return acc;
			}, {});

			return {
				...state,
				students: { ...state.students, ...updatedStudents },
			};
		}
		case 'UPDATE_GROUP_SUCCESS': {
			let studentsToUpdate = getStudentsByIds(state)(action.studentIdsInGroup);

			let newGroupInformation = {
				groupName: action.updatedGroup.name,
				groupId: action.updatedGroup.groupId,
			};

			let updatedStudents = studentsToUpdate.reduce((acc, student) => {
				let groupIndex = student.groupInformation.findIndex(
					g => g.groupId === action.updatedGroup.groupId
				);
				student.groupInformation[groupIndex] = newGroupInformation;
				acc[student.travellerId] = student;
				return acc;
			}, {});

			return {
				...state,
				students: { ...state.students, ...updatedStudents },
			};
		}
		case 'CLEAR_SCHOOL_STATE':
			return { ...state, ...initialState };
		case 'CLEAR_SCHOOL_CART':
			return { ...state, cardOrders: initialState.cardOrders };
		case 'UPDATE_STUDENT_REQUEST':
		case 'UPDATE_REFERREDACCOUNT_REQUEST':
			return {
				...state,
				isUpdatingStudent: true,
			}
		case 'UPDATE_STUDENT_SUCCESS':
			return {
				...state,
				isUpdatingStudent: false,
				students: {
					...state.students,
					[action.student.travellerId]: {
						...state.students[action.student.travellerId],
						...action.student,
					},
				},
			}
		case 'UPDATE_STUDENT_FAILURE':
			return {
				...state,
				isUpdatingStudent: false,
			}
		case 'UPDATE_REFERREDACCOUNT_SUCCESS':
			return {
				...state,
				isUpdatingStudent: false,
				referredAccounts: {
					...state.referredAccounts,
					[action.traveller.travellerId]: action.traveller,
				},
			};
		case 'UPDATE_REFERREDACCOUNT_FAILURE':
			return {
				...state,
				isUpdatingStudent: false,
			}
		default:
			return state;
	}
}

export function getStudents(state) {
	return state.students || [];
}

export const getCreatedOrders = store => store[STORE_NAME].cardOrders || [];

export const isLoadingBulkSpecificOrders = store =>
	store[STORE_NAME].isLoadingBulkSpecificCardOrders;

export function getCardLayouts(state) {
	return state.cardLayouts || [];
}

export const getCardLayoutById = store => id =>
	store[STORE_NAME].cardLayouts.find(cl => cl.id === id);

export const hasStudentBearers = store => studentId =>
	!isEmpty(store[STORE_NAME].studentBearers[studentId]);

export const getStudentBearers = store => studentId => {
	const state = store[STORE_NAME];
	return (state.studentBearers[studentId] || []).reduce((bearers, id) => {
		if (state.bearers[id]) {
			bearers.push(state.bearers[id]);
		}
		return bearers;
	}, []);
};

export const referredAccountsLoaded = store =>
	store[STORE_NAME].referredAccountsLoaded;

export const isLoadingReferredAccounts = store =>
	store[STORE_NAME].isLoadingReferredAccounts;

export const getReferredAccount = store => studentId => {
	if (
		store[STORE_NAME].referredAccounts &&
		store[STORE_NAME].referredAccounts[studentId]
	)
		return store[STORE_NAME].referredAccounts[studentId].referredAccount;
};

export const getAllReferredAccountIds = store =>
	(Object.values(store[STORE_NAME].referredAccounts) || []).reduce(
		(list, account) => {
			if (account.referredAccount) {
				list.push(account.referredAccount.referredAccountTravellerId);
			}
			return list;
		},
		[]
	);

export const getReferredAccountTickets = store => travellerId => 
	store[STORE_NAME].referredAccountTickets[travellerId]
		? store[STORE_NAME].referredAccountTickets[travellerId]
		: [];

export const getGroupReferredAccountIds = store => groupId => {
	const students = getStudentIdsInGroupFromStore(store)(groupId);

	return students.reduce((result, studentId) => {
		const referredAccount = getReferredAccount(store)(studentId);

		if (referredAccount != null) {
			result.push(referredAccount.referredAccountTravellerId);
		}
		return result;
	}, []);
}

export const isTravelCardSmartPhone = bearer =>
	bearer.type === BEARER_TYPES.SMARTPHONE;

export const isTravelCard = bearer => bearer.type === BEARER_TYPES.SMARTCARD;

const isLoadingStudentTraveller = store => store[STORE_NAME].isLoadingTraveller;

export const getStudentTraveller = store => travellerId => {
	if (
		store[STORE_NAME].studentTraveller &&
		store[STORE_NAME].studentTraveller.id === travellerId
	) {
		return store[STORE_NAME].studentTraveller;
	}
};

export const hasLoadedStudentBearers = store => travellerId =>
	store[STORE_NAME].studentBearers[travellerId] !== undefined;

export const getStudentsByIds = state => (travellerIds = []) =>
	travellerIds.map(travellerId => state.students[travellerId]);

export const getStudentById = state => travellerId =>
	state.students[travellerId];

export const getImportedStudents = state => state.importedStudents || [];

export const studentHasTickets = store => travellerId => {
	const referredAccount = getReferredAccount(store)(travellerId);
	return (
		(store[STORE_NAME].studentTickets[travellerId] &&
			!isEmpty(store[STORE_NAME].studentTickets[travellerId])) ||
		(referredAccount &&
			store[STORE_NAME].referredAccountTickets[
			referredAccount.referredAccountTravellerId
			] &&
			!isEmpty(
				store[STORE_NAME].referredAccountTickets[
				referredAccount.referredAccountTravellerId
				]
			))
	);
};

export const hasLoadedStudentTickets = store => travellerId =>
	store[STORE_NAME].studentTickets[travellerId] !== undefined;

export const hasLoadedTicketsForGroup = store => travellerIds =>
	!isEmpty(travellerIds) &&
	travellerIds.every(
		travellerId => store[STORE_NAME].studentTickets[travellerId] !== undefined
	);

export const selectStudentTickets = store => travellerId => {
	const state = store[STORE_NAME];
	return (state.studentTickets[travellerId] || []).reduce(
		(tickets, id) =>
			state.tickets[id] ? [...tickets, state.tickets[id]] : tickets,
		[]
	);
};

export const getStudentTicketText = store => travellerId => {
	const referredAccount = getReferredAccount(store)(travellerId);
	const referredAccountTickets = referredAccount
		? getReferredAccountTickets(store)(
			referredAccount.referredAccountTravellerId
		)
		: [];
	if (!studentHasTickets(store)(travellerId) && isEmpty(referredAccountTickets))
		return '-';

	const studentTickets = selectStudentTickets(store)(travellerId);

	let returnText = !isEmpty(studentTickets)
		? studentTickets[0].productSetTitle
		: referredAccountTickets[0].productSetTitle;

	if (studentTickets.length + referredAccountTickets.length > 1)
		returnText += ' +';

	return returnText;
};

export function hasError(store) {
	return store.errorMessage !== '';
}

export function hasCreateBulkOrderError(state) {
	return state.errorMessage === STUDENT_ERRORS.CREATE_BULK_CARD_ORDERS;
}

export function hasCreateStudentBearerError(store) {
	return store.errorCreateMessage;
}

export const hasImportError = store =>
	store.errorMessage === STUDENT_ERRORS.IMPORT_STUDENTS;

export function hasCreateStudentError(state) {
	return state.errorMessage === STUDENT_ERRORS.CREATE_STUDENT;
}

export const hasCardLayouts = store => !isEmpty(store[STORE_NAME].cardLayouts);

export const getImportErrorText = store =>
	IMPORT_ERRORS[store.importErrorReason];

export const isLoading = store => store.isLoading;

export const isLoadingStudentBearers = store => store.isLoadingStudentBearers;

export const isLoadingImport = store => store.isLoadingImport;

export const isLoadingStudentTickets = store =>
	store[STORE_NAME].isLoadingStudentTickets;

export const isUpdatingStudent = store =>
	store[STORE_NAME].isUpdatingStudent;

export const isLoadingTicketAction = store =>
	store[STORE_NAME].isLoadingTicketAction;

export const studentHasTicketType = store => (travellerId, type) =>
	store[STORE_NAME].students[travellerId].ticketType === type;

export const studentHasNoTicketType = store => travellerId =>
	store[STORE_NAME].students[travellerId].ticketType === '';

export const canDeleteGroup = store => students =>
	(students || []).every(student =>
		hasNoActiveTicketsOrBelongsToMultipleGroups(store, student)
	);

const hasNoActiveTicketsOrBelongsToMultipleGroups = (store, student) => {
	const currentSchool = getSchool(store);
	const currentSchoolVendor = getSchoolVendorId(store)(currentSchool.schoolId);

	const studentTickets = selectStudentTickets(store)(student.travellerId);
	const referredAccount = getReferredAccount(store)(student.travellerId);
	const referredAccountTickets = referredAccount
		? getReferredAccountTickets(store)(
			referredAccount.referredAccountTravellerId
		)
		: [];

	const tickets = [...studentTickets, ...referredAccountTickets].filter(
		t => t.owner.id === currentSchoolVendor.vendorId
	);

	if (
		ticketsAreOrWillBeActive(tickets) &&
		student.groupInformation.length === 1
	) {
		return false;
	}
	return true;
};

const ticketsAreOrWillBeActive = tickets =>
	tickets.some(ticket => !ticket.finalState);

export const getAllTicketVendorIds = store =>
	unique([
		...Object.values(store[STORE_NAME].tickets || []).map(t => t.owner.id),
		...flatten(
			Object.values(store[STORE_NAME].referredAccountTickets || [])
		).map(t => t.owner.id),
	]);
