import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { persistStore } from '../../../store';
import {
	actionCreators as walletActions,
	isLoadingSharedPaymentMethods,
	isLoadingRegisteredPaymentCards,
	getRegisteredCards,
	getRegistrationPaymentMethods,
} from '../../../reducer/wallet';
import { Error } from '../../common/elements/Error';
import { RegisteredPaymentCard } from './RegisteredPaymentCard';
import { ReactComponent as ChevronLeft } from '../../../images/chevron-left.svg';
import { ReactComponent as Plus } from '../../../images/plus.svg';
import { formatFullISODate } from '../../../utils/formatting';
import isEmpty from 'lodash/isEmpty';
import { Loading } from '../../common/blocks/Loading';
import { ProfileCardHeader } from './ProfileCardHeader';
import {
	BackButton,
	CallToActionButton,
	IconLinkButton,
} from './PaymentMethods';
import { PaymentMethodsDropdown } from '../purchase/PaymentMethodsDropdown';

class PaymentCards extends Component {
	state = {
		selectedPaymentMethod: undefined,
		selectedName: undefined,
		errorMessage: false,
		expand: false,
		isLoading: false,
	};

	verifyDescription =
		'Om du tar bort ett betalkort försvinner den från din profilsida.';
	verifyErrorMessage =
		'Ett fel uppstod när du försökte ta bort ditt betalkort. Var vänlig och kontakta kundtjänst eller försök igen.';
	verifyCtaText = 'Ja, ta bort';

	componentDidMount() {
		const { sharedPaymentMethods, registerPaymentCards } = this.props;

		if (isEmpty(sharedPaymentMethods) || isEmpty(registerPaymentCards))
			this.props.getPaymentMethods();
	}


	onPaymentMethodSelect = item => {
		const { sharedPaymentMethods } = this.props;

		const selectedPaymentMethod = sharedPaymentMethods.find(
			m => m.id === item.id
		);

		this.setState({ selectedPaymentMethod: selectedPaymentMethod.id });
	};

	handleRegisterClick = e => {
		this.setState({ isLoading: true }, this.registerPaymentMethod);
	};

	registerPaymentMethod = async () => {
		const name = 'Payment_Card_' + formatFullISODate(new Date());

		const response = await this.props.registerPaymentMethod(
			this.state.selectedPaymentMethod,
			name
		);

		if (!response) {
			this.setState({ errorMessage: true, isLoading: false });
		}

		persistStore();
		if (response.webviewURL) {
			window.location.href = response.webviewURL;
		}
	};

	onRemove = async paymentId => {
		this.props.onShowVerifyAction({
			description: this.verifyDescription,
			ctaText: this.verifyCtaText,
			callback: this.removePaymentCard,
			data: { paymentId },
		});
	};

	removePaymentCard = async ({ paymentId }) => {
		const responseOk = await this.props.deletePaymentCard(paymentId);

		if (responseOk) {
			this.props.renderConfirmationCard(
				<p>Du har nu tagit bort ditt betalkort</p>
			);
			this.props.clearPaymentCards();
		} else {
			this.props.onShowVerifyAction({
				description: this.verifyDescription,
				ctaText: this.verifyCtaText,
				errorMessage: this.verifyErrorMessage,
				callback: this.removePaymentCard,
				data: { paymentId },
			});
		}
	};

	toggleView = () =>
		this.setState(state => ({ expand: !state.expand, errorMessage: false }));

	render() {
		const {
			sharedPaymentMethods,
			registerPaymentCards,
			isLoadingSharedPaymentMethods,
			isLoadingRegisteredPaymentCards,
		} = this.props;
		const { errorMessage, expand, isLoading } = this.state;

		if (!expand) {
			return (
				<>
					<ProfileCardHeader>Sparade betalkort</ProfileCardHeader>

					{isLoadingRegisteredPaymentCards && <Loading />}

					{!isEmpty(registerPaymentCards) &&
						registerPaymentCards.map((x, idx) => (
							<RegisteredPaymentCard
								key={idx}
								paymentCard={x}
								onRemove={this.onRemove}
							/>
						))}

					{isEmpty(registerPaymentCards) && (
						<p>Du har inga registrerade betalkort</p>
					)}

					<IconLinkButton onClick={this.toggleView}>
						<Plus
							style={{ margin: '2px 10px 0 0' }}
							className="svg-icon"
							aria-hidden="true"
							focusable="false"
						/>
						Lägg till ett nytt betalkort
					</IconLinkButton>
				</>
			);
		}

		return (
			<>
				<ProfileCardHeader>Lägg till nytt betalkort</ProfileCardHeader>

				<PaymentMethodsDropdown
					paymentMethods={sharedPaymentMethods}
					className="form-group"
					isLoadingSharedPaymentMethods={isLoadingSharedPaymentMethods}
					onSelectPaymentMethod={this.onPaymentMethodSelect}
				/>

				{errorMessage && (
					<Error>
						Det uppstod ett fel när du försökte registrera ett nytt betalkort.
						Vänligen försök igen eller kontakta kundservice.
					</Error>
				)}

				<CallToActionButton
					onClick={this.handleRegisterClick}
					disabled={isLoading}
				>
					Registrera nytt betalkort
				</CallToActionButton>

				{isLoadingSharedPaymentMethods && <Loading />}

				<BackButton onClick={this.toggleView}>
					<ChevronLeft />
					Tillbaka
				</BackButton>
			</>
		);
	}
}

function mapStateToProps(store) {
	return {
		sharedPaymentMethods: getRegistrationPaymentMethods(store),
		registerPaymentCards: getRegisteredCards(store),
		isLoadingSharedPaymentMethods: isLoadingSharedPaymentMethods(store),
		isLoadingRegisteredPaymentCards: isLoadingRegisteredPaymentCards(store),
	};
}

export default connect(
	mapStateToProps,
	{ ...walletActions, push }
)(PaymentCards);
