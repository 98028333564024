import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '../../../images/back.svg';
import {
	SpanRow,
	ActionButton,
	ConfirmationText,
	CallToActionButton,
	ConfirmationFormRow,
	BackButton,
} from './Ticket';
import { Dropdown } from '../../common/elements/Dropdown';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { PRIVATE } from '../../../Paths';
import { ThemedNavLink } from '../../common/elements/ThemedNavLink';

export class MoveTicketForm extends Component {
	state = {
		selectedBearer: null,
		disableButton: true,
		showConfirmation: false,
	};

	toggleConfirmation = () => {
		this.setState({ showConfirmation: true });
	};

	cancel = () => {
		this.setState({ showConfirmation: false }, this.props.onBackClick);
	};

	onSetBearer = () =>
		this.props.onSetBearer(this.props.product.id, this.state.selectedBearer.id);

	onSelect = item => this.setState({ selectedBearer: item });

	render() {
		const { showConfirmation, selectedBearer } = this.state;
		const { bearers, product, productMissingBearer } = this.props;
		let bearersToShow = bearers;

		if (!!product.bearer) {
			bearersToShow = bearers.filter(bearer => bearer.id !== product.bearer.id);
		}

		if (bearersToShow.length === 0) {
			return (
				<>
					<SpanRow>
						<Arrow aria-hidden="true" focusable="false" alt="" />
						<span>
							{productMissingBearer
								? 'Välj vart vill du ha din biljett'
								: 'Flytta biljett'}
						</span>
					</SpanRow>
					<Paragraph>
						{productMissingBearer
							? this.props.texts.private.ticketpage.tickets
									.moveticketinformationnobearer
							: this.props.texts.private.ticketpage.tickets
									.moveticketinformationwithbearer}
					</Paragraph>
					<ItalicLink exact to={PRIVATE.REGISTER_CARD}>
						Registrera nytt resekort
					</ItalicLink>
					<BackButton onClick={this.cancel}>&lsaquo; Tillbaka</BackButton>
				</>
			);
		}

		if (showConfirmation) {
			return (
				<>
					<SpanRow>
						<Arrow aria-hidden="true" focusable="false" alt="" />
						<span>
							{productMissingBearer
								? 'Välj vart vill du ha din biljett'
								: 'Flytta biljett'}
						</span>
					</SpanRow>
					<ConfirmationText>
						{productMissingBearer
							? 'Är du säker på att du vill ha din biljett på '
							: 'Är du säker på att du vill flytta din biljett till '}
						{selectedBearer.title}?
					</ConfirmationText>
					<ConfirmationFormRow>
						<CallToActionButton onClick={this.onSetBearer}>
							JA
						</CallToActionButton>
						<ActionButton onClick={this.cancel}>Avbryt</ActionButton>
					</ConfirmationFormRow>
					<BackButton onClick={this.cancel}>&lsaquo; Tillbaka</BackButton>
				</>
			);
		}

		return (
			<>
				<SpanRow>
					<Arrow aria-hidden="true" focusable="false" alt="" />
					<span>
						{productMissingBearer
							? 'Välj vart vill du ha din biljett'
							: 'Flytta biljett'}
					</span>
				</SpanRow>
				<Paragraph>
					{!productMissingBearer && (
						<span>
							{
								this.props.texts.private.ticketpage.tickets
									.moveticketinformation
							}
						</span>
					)}
					<ItalicLink exact to={PRIVATE.REGISTER_CARD}>
						Registrera nytt resekort
					</ItalicLink>
				</Paragraph>

				<ChooseBearerRow>
					<Dropdown
						id="bearers"
						items={bearersToShow}
						onSelect={this.onSelect}
					/>
					<MoveButton onClick={this.toggleConfirmation}>
						{productMissingBearer ? 'Koppla biljett' : 'Flytta biljett'}
					</MoveButton>
				</ChooseBearerRow>
				<BackButton onClick={this.cancel}>&lsaquo; Tillbaka</BackButton>
			</>
		);
	}
}

const ItalicLink = styled(ThemedNavLink)`
	display: block;
	font-style: italic;
`;

const MoveButton = styled(SecondaryButton)`
	margin: 1em auto 0;
`;

const ChooseBearerRow = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1em;
`;

const Paragraph = styled.p`
	margin-bottom: 1.5em;
`;
