import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CardWide } from '../common/blocks/CardWide';
import { Main } from '../common/blocks/Main';
import {
	actionCreators as walletActions,
	canReplenish,
	PAYMENT_PROVIDERS,
	hasReplenishError,
	hasReplenishValues,
	getReplenishValues,
	isLoadingReplenishValues,
	hasLoadingReplenishValuesError,
	getReplenishResponse,
	getPurchasablePaymentMethods,
} from '../../reducer/wallet';
import SelectedProduct from './SelectedProduct';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { isLoadingPaymentMethods, getTraveller, getPurse } from '../../reducer';
import { Info } from '../common/elements/Info';
import RebuyTickets from './RebuyTickets';
import withTextContext from '../../utils/withTextContext';
import { Loading } from '../common/blocks/Loading';
import { Dropdown } from '../common/elements/Dropdown';
import { TextInput } from '../common/elements/TextInput';
import { PaymentMethodsDropdown } from './purchase/PaymentMethodsDropdown';
import { regexToInputPattern, Validation } from '../../utils/validation';
import PurchaseNavigation from './purchase/PurchaseNavigation';
import { PRIVATE } from '../../Paths';
import { formatCurrency } from '../../utils/formatting';
import { push } from 'connected-react-router';
import { Error } from '../common/elements/Error';
import { isEmpty } from 'lodash';
import ReceiptEmail from '../customer/purchase/ReceiptEmail';
import {
	actionCreators as purseActions,
	getPurseParameters,
	hasPurseParameters,
} from '../../reducer/parameters';
import { Label } from '../common/elements/Label';
import { Banner } from '../common/blocks/Banner';

export class PurchasePurseFundsPage extends Component {
	state = {
		customAmount: '',
		amount: undefined,
		isAwaitingRedirect: false,
		paymentMethodId: undefined,
		showConfirm: false,
		showError: false,
		errorMessage: undefined,
		travellerReceiptEmail: '',
	};

	componentDidMount() {
		document.title = "Ladda din kundkassa | Mitt konto"
		const {
			paymentMethods,
			hasReplenishValues,
			hasPurseParameters,
			purse,
		} = this.props;

		if (isEmpty(paymentMethods)) this.props.getPaymentMethods();

		if (!hasReplenishValues) this.props.getPurseReplenishValues();

		if (!purse) this.props.getPurse();
		if (!hasPurseParameters) this.props.getPurseParameters();

		if (this.props.traveller && this.props.traveller.receiptEmail) {
			this.setState({
				travellerReceiptEmail: this.props.traveller.receiptEmail,
			});
		}
	}

	handleCustomAmountChange = target => {
		const maxAllowedReplenishAmount = this.maxAllowedReplenishAmount();
		let customAmount = +target.value;
		let showInfoReplenishBalanceMax = false

		if (customAmount > maxAllowedReplenishAmount) {
			showInfoReplenishBalanceMax = true;
		}
		customAmount =
			customAmount > maxAllowedReplenishAmount
				? maxAllowedReplenishAmount
				: customAmount;
		this.setState({
			customAmount,
			showInfoReplenishBalanceMax
		});
	};

	onSelectAmount = item => this.setState({ amount: item.value });

	onSelectPaymentMethod = item => this.setState({ paymentMethodId: item.id });

	onToggleShowConfirmation = () => {
		if (this.isInvalidEmail()) {
			this.setState({
				errorMessage: this.props.texts.private.checkoutpage.cart
					.emailvalidationerror,
			});
			return;
		} else {
			this.setState({
				errorMessage: undefined,
			});
		}
		this.setState(state => ({ showConfirm: !state.showConfirm }));
	};

	isInvalidEmail = () =>
		this.state.travellerReceiptEmail.length &&
		!Validation.email.test(this.state.travellerReceiptEmail);

	setReceiptEmail = async () => {
		const { travellerReceiptEmail } = this.state;
		await this.props.updateTravellerReceiptEmail({
			receiptEmail: travellerReceiptEmail.length ? travellerReceiptEmail : null,
		});
	};

	replenishPurse = async () => {
		const { amount, customAmount, paymentMethodId } = this.state;
		const { paymentMethods } = this.props;

		const selectedAmount = amount === -1 ? customAmount : amount;
		const paymentMethod = paymentMethods.find(m => m.id === paymentMethodId);
		const isSwishPayment = paymentMethod.provider === PAYMENT_PROVIDERS.SWISH;

		const replenishResult = await this.props.replenishPurse(
			selectedAmount,
			paymentMethodId,
			isSwishPayment
		);

		if (!replenishResult || this.props.hasReplenishError) {
			this.setState({ showError: true });
			this.onToggleShowConfirmation();
			return;
		}

		const { webviewURL, transactionId } = this.props.replenishResult;

		this.setState({ isAwaitingRedirect: true, showError: false });

		if (isSwishPayment) {
			this.props.push(PRIVATE.CONFIRM_SWISH_PURCHASE, {
				transactionId,
				webviewURL,
			});
		} else if (webviewURL) {
			window.location.href = webviewURL;
		} else {
			this.props.push(`${PRIVATE.CONFIRM_PURCHASE}?tid=${transactionId}`);
		}

		await this.setReceiptEmail();

	};

	purseIsMaxBalance = () =>
		this.props.purse &&
		this.props.purseParameters &&
		this.maxAllowedReplenishAmount() <= 0;

	maxAllowedReplenishAmount = () =>
		this.props.purseParameters && this.props.purse
			? this.props.purseParameters.replenishBalanceMax -
			Math.ceil(this.props.purse.totalBalance)
			: 0;

	renderPurseBalance = () =>
		this.props.purse ? (
			<PurseBalanceContainer>
				<Label>
					Kundkassans saldo:{' '}
					<PurseFunds>
						{formatCurrency(this.props.purse.totalBalance)}
					</PurseFunds>{' '}
				</Label>
			</PurseBalanceContainer>
		) : null;

	render() {
		const {
			amount,
			isAwaitingRedirect,
			showConfirm,
			showError,
			travellerReceiptEmail,
		} = this.state;
		const {
			canReplenish,
			isLoading,
			paymentMethods,
			replenishValues,
			purseParameters,
			purse,
			texts
		} = this.props;

		let amountOptions = (replenishValues || []).map(v => ({
			title: formatCurrency(v),
			value: v,
		}));

		if (purse && purseParameters)
			amountOptions = amountOptions.filter(
				ao => ao.value <= this.maxAllowedReplenishAmount()
			);

		amountOptions.push({
			title: 'Eget val',
			value: -1,
		});

		const showCustomAmount = amount === -1;

		if (isAwaitingRedirect) {
			return (
				<BuyTicketContainer>
					<RebuyTickets />
					<Banner bannerImg={texts.private.images.banners.buyticketpage} />
					<Main>
						<TicketBox>
							<Loading text="Skickar dig vidare..." />
						</TicketBox>
					</Main>
				</BuyTicketContainer>
			);
		}

		if (isLoading) {
			return (
				<BuyTicketContainer>
					<RebuyTickets />
					<Banner bannerImg={texts.private.images.banners.buyticketpage} />
					<Main>
						<TicketBox>
							<H2>Ladda din kundkassa</H2>
							<Loading />
						</TicketBox>
					</Main>
				</BuyTicketContainer>
			);
		}

		if (showConfirm)
			return (
				<BuyTicketContainer>
					<RebuyTickets />
					<Banner bannerImg={texts.private.images.banners.buyticketpage} />
					<Main>
						<TicketBox>
							<H2>Är du säker?</H2>
							<CancelPurchaseButton
								style={{ margin: '0 10px' }}
								onClick={this.onToggleShowConfirmation}
							>
								Avbryt
							</CancelPurchaseButton>
							<SecondaryButton
								style={{ margin: '0 10px' }}
								onClick={this.replenishPurse}
							>
								Fyll på kundkassa
							</SecondaryButton>
						</TicketBox>
					</Main>
				</BuyTicketContainer>
			);

		return (
			<BuyTicketContainer>
				<RebuyTickets />
				<Banner bannerImg={texts.private.images.banners.buyticketpage} />
				<Main>
					<>
						<PurchaseNavigation />
						<TicketBox id="customerPurchaseFundsPageTicketBox">
							<H2>Ladda din kundkassa</H2>
							{this.renderPurseBalance()}
							{this.purseIsMaxBalance() ? (
								<Info>
									Din kundkassa är redan full och kan ej fyllas på mer
								</Info>
							) : canReplenish ? (
								<>
									<Dropdown
										id="amount"
										items={amountOptions}
										onSelect={this.onSelectAmount}
										style={{ textAlign: 'left', marginBottom: '1em' }}
									/>

									{showCustomAmount && (
										<PurchasePurseFundsPageFormGroup className="form-group">
											<label htmlFor="customAmount">Ange annat belopp:</label>{' '}
											<TextInput
												// type="number" //caused problem for screen readers, especially Microsoft Narrator
												type="text"
												value={this.state.customAmount}
												id="customAmount"
												handleChange={this.handleCustomAmountChange}
												maxLength={`${this.maxAllowedReplenishAmount()}`.length}
												style={{ width: '5em' }}
												pattern={regexToInputPattern(Validation.onlyDigits)}
												validateOnChange={true}
											/>{' '}
											<span>kr</span>
										</PurchasePurseFundsPageFormGroup>
									)}

									{this.state.showInfoReplenishBalanceMax && (
										<Info>
												Laddning kan ej genomföras då summan överstiger maxbeloppet per laddning eller saldo på kundkassa
										</Info>
									)}

									<h3>Välj betalmedel</h3>

									<PaymentMethodsDropdown
										paymentMethods={paymentMethods.filter(m => m.canReplenish)}
										onSelectPaymentMethod={this.onSelectPaymentMethod}
										style={{ textAlign: 'left', marginBottom: '1em' }}
									/>

									<h3>
										{
											this.props.texts.private.checkoutpage.cart
												.emailreceiptlabel
										}
									</h3>
									<ReceiptEmail
										setTravellerReceiptEmail={travellerReceiptEmail =>
											this.setState({ travellerReceiptEmail })
										}
										travellerEmail={travellerReceiptEmail}
										isError={this.state.errorMessage === this.props.texts.private.checkoutpage.cart.emailvalidationerror}
										errorMessage={this.props.texts.private.checkoutpage.cart.emailvalidationerror}

									/>
									{/* Validation errors have been moved to each individual TextInput field - See KOL-2035 for more details */}
									{/* {errorMessage !== undefined && (
										<ValidationError>{errorMessage}</ValidationError>
									)} */}

									<SecondaryButton onClick={this.onToggleShowConfirmation} style={{marginTop: "1.5rem"}}>
										Ladda
									</SecondaryButton>
								</>
							) : (
								<Info>
									Det går inte att ladda kundkassan för tillfället. Var god och
									försök igen senare eller kontakta kundtjänst.
								</Info>
							)}
							{showError && (
								<Error>
									Det går inte att ladda kundkassan för tillfället. Var god och
									försök igen senare eller kontakta kundtjänst.
								</Error>
							)}
						</TicketBox>
					</>
				</Main>
				<SelectedProduct />
			</BuyTicketContainer>
		);
	}
}

// const ValidationError = styled.p`
// 	color: #ff0000;
// 	font-size: 14px;
// 	white-space: pre-line;
// 	margin: 0px 2px 1em;
// `;

const BuyTicketContainer = styled.div`
	position: relative;
`;

const H2 = styled.h2`
	margin-bottom: 1em;
`;

const TicketBox = styled(CardWide)`
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

const CancelPurchaseButton = styled(SecondaryButton)`
	background-color: white;
	border: 1px solid #645b5b;
	color: #645b5b;
`;

const PurseBalanceContainer = styled.div`
	font-size: 16px;
	text-align: left;
`;

const PurseFunds = styled.span`
	color: green;
`;

const PurchasePurseFundsPageFormGroup = styled.div`
	text-align: left;
	display: flex; 
	justify-content: flex-start; 
	align-items: baseline; 
	gap: 0.7rem;
`

export default withTextContext(
	connect(
		store => ({
			paymentMethods: getPurchasablePaymentMethods(store),
			canReplenish: canReplenish(store),
			isLoading: isLoadingPaymentMethods(store),
			hasReplenishError: hasReplenishError(store),
			hasReplenishValues: hasReplenishValues(store),
			replenishValues: getReplenishValues(store),
			isLoadingReplenishValues: isLoadingReplenishValues(store),
			hasLoadingReplenishValuesError: hasLoadingReplenishValuesError(store),
			replenishResult: getReplenishResponse(store),
			traveller: getTraveller(store),
			hasPurseParameters: hasPurseParameters(store),
			purseParameters: getPurseParameters(store),
			purse: getPurse(store),
		}),
		{ ...walletActions, ...purseActions, push }
	)(withRouter(PurchasePurseFundsPage))
);
