import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { CloseCardButton } from '../../common/blocks/CloseCardButton';
import { PopUpBox, PopupContainer } from '../../common/elements/PopUpBox';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import withTextContext from '../../../utils/withTextContext';
import { TextInput } from '../../common/elements/TextInput';
import { Error } from '../../common/elements/Error';
import { actionCreators as studentActions } from '../../../reducer/students';
import {
	actionCreators as groupActions,
	isLoadingUpdateGroup,
} from '../../../reducer/groups';
import { sanitizeCmsHtml } from '../../../texts';
import { ConfirmationCard } from '../../common/blocks/ConfirmationCard';

class UpdateGroupNamePopup extends Component {
	state = {
		bearers: {},
		hasError: false,
		confirmation: false,
	};

	componentDidMount() {
		const { groupName } = this.props;
		this.setState({ groupName });
	}

	updateGroupName = async () => {
		const { groupName } = this.state;
		const { groupId } = this.props;
		try {
			await this.props.updateGroup(groupId, { name: groupName });
			this.setState({ confirmation: true });
		} catch {
			this.setState({ hasError: true });
		}
	};

	handleChange = target => this.setState({ groupName: target.value });

	render() {
		const { closePopup, groupName, texts } = this.props;
		const { confirmation, hasError } = this.state;

		if (confirmation) {
			return (
				<PopupContainer>
					<ConfirmationCard onClick={closePopup} title="Namn ändrat" />
				</PopupContainer>
			);
		}

		return (
			<Container>
				<CloseCardButton onClick={closePopup} />
				<h2>Byt namn på grupp</h2>
				<GroupNameInput>
					<label>Gruppnamn</label>
					<span>*</span>
					<TextInput
						type="text"
						value={groupName}
						handleChange={this.handleChange}
						placeholder="Ange gruppnamn"
					/>
				</GroupNameInput>
				<InformationTextContainer>
					<b>Grupp</b>
					<div
						dangerouslySetInnerHTML={sanitizeCmsHtml(
							texts.school.grouppage.changegroup.informationtext
						)}
					/>
				</InformationTextContainer>
				<SecondaryButton
					onClick={this.updateGroupName}
					disabled={!this.state.groupName}
				>
					Spara
				</SecondaryButton>
				{hasError && (
					<Error>
						Det gick inte att redigera gruppnamnet. Vänligen försök igen.
					</Error>
				)}
			</Container>
		);
	}
}

const Container = styled(PopUpBox)`
	& > div {
		padding: 3em 6em;
		max-width: 500px;
	}
`;

const GroupNameInput = styled.div`
	display: flex;
	margin: 20px 0px;
	justify-content: space-between;

	& > span {
		padding-right: 30px;
		color: #d30808;
	}
`;

const InformationTextContainer = styled.div`
	align-self: baseline;
	text-align: left;
`;

export default withTextContext(
	connect(
		store => ({ isLoading: isLoadingUpdateGroup(store) }),
		{ ...studentActions, ...groupActions, push }
	)(UpdateGroupNamePopup)
);
