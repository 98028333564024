import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { Main } from '../common/blocks/Main';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { getLocationState } from '../../reducer';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class ConfirmationPage extends Component {
	continue = () => {
		this.props.push(this.props.state.nextRoute);
	};

	render() {
		const { state = {} } = this.props;
		const { title, text } = state;

		const content = text && <p>{text}</p>;
		const header = title;

		return (
			<>
				<Banner
					bannerImg={this.props.texts.school.images.banners.adminpage}
				/>
				<ConfirmationPageMain>
					<FlexWrapper>
						<ConfirmationCard
							onClick={this.continue}
							title={header}
							text={content}
						/>
					</FlexWrapper>
				</ConfirmationPageMain>
			</>
		);
	}
}

const ConfirmationPageMain = styled(Main)`
	padding: 1em 1em 3em;

	${forTabletPortraitUp`
		padding: 3em 1em;
	`}
`;

export default withTextContext(
	connect(
		store => ({
			state: getLocationState(store),
		}),
		{ push }
	)(ConfirmationPage)
);
