export function getUrlParameter(url, name) {
	name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	var results = regex.exec(url);
	return results === null
		? ''
		: decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getLastUrlSegment(url) {
	const path = stripQueryAndFragmentFromUrl(url);
	const segments = path.split('/');
	return segments[segments.length - 1];
}

export const stripQueryFromUrl = url => url.split('?')[0];
export const stripFragmentFromUrl = url => url.split('#')[0];
export const stripQueryAndFragmentFromUrl = url =>
	stripFragmentFromUrl(stripQueryFromUrl(url));
