import React from 'react';
import { Card } from './blocks/Card';
import styled from 'styled-components';
import { HomePageMain } from './blocks/HomePageMain';
import LogoWrapper from './blocks/LogoWrapper';
import { SecondaryButton } from './elements/SecondaryButton';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { forTabletPortraitUp } from '../../utils/mediaqueries';

class PageNotFound extends React.Component {

	render() {
		return (
			<HomePageMain>
				<LogoContainer>
					<LogoWrapper />
				</LogoContainer>
				<CardBox>
					<h4 style={{ marginTop: '0', marginBottom: '1em' }}>
						404 - Sidan kunde ej hittas
					</h4>
					<SecondaryButton
						style={{ marginTop: '1em' }}
						onClick={() => {window.history.back()}}
					>
						Gå tillbaka
					</SecondaryButton>
				</CardBox>
			</HomePageMain>
		);
	}
}

export default connect(
	null,
	{ push }
)(PageNotFound);

const LogoContainer = styled.div`
	margin-left: 2em;
	align-self: center;
	
	${forTabletPortraitUp`
		align-self: flex-start;
	`}
`;

const CardBox = styled(Card)`
	align-items: center;

	${forTabletPortraitUp`
		padding: 3em;
		margin: 6.5em 0 9.5em 0;
	`}
`;
