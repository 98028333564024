import {
	fetchtDelayIntervals,
	registerDelayIncident,
	getRegisteredIncidents,
} from '../api';
import { getToken, getTraveller } from '.';

export const ERRORS = {
	GET_DELAY_INTERVALS: 'GET_DELAY_INTERVALS',
	REGISTER_DELAY_INCIDENT: 'REGISTER_DELAY_INCIDENT',
	GET_INCIDENTS: 'GET_INCIDENTS',
};

const ISSUE_STATUS = {
	NEW: 'Nytt',
	IN_PROGRESS: 'Under utredning',
	AWAITING_ANSWER: 'Inväntar svar',
	ANSWER_RECIEVED: 'Svar inkommet',
	CLOSED_MOVED: 'Flyttat - avslutat',
	CLOSED_REJECTED: 'Avslag - avslutat',
	CLOSED: 'Avslutat',
	CLOSED_REDIRECTED: 'Avslutat - hänvisat',
	CLOSED_REVOKED: 'Annullerat',
	CONNECTED: 'Kopplat',
};

const ISSUE_TYPE = {
	DELAY: 'Forseningsersattning',
	RECLAMATION: 'Reklamation',
	OTHER: 'Ovrigt',
};

export const getStatusLabel = status => {
	switch (status) {
		case ISSUE_STATUS.CLOSED_REVOKED:
		case ISSUE_STATUS.CLOSED_REJECTED:
			return ISSUE_STATUS.CLOSED;
		case ISSUE_STATUS.NEW:
		case ISSUE_STATUS.IN_PROGRESS:
		case ISSUE_STATUS.CONNECTED:
		case ISSUE_STATUS.ANSWER_RECIEVED:
			return 'Pågående';
		default:
			return status;
	}
};

export const isClosed = status => {
	switch (status) {
		case ISSUE_STATUS.CLOSED:
		case ISSUE_STATUS.CLOSED_MOVED:
		case ISSUE_STATUS.CLOSED_REDIRECTED:
		case ISSUE_STATUS.CLOSED_REJECTED:
		case ISSUE_STATUS.CLOSED_REVOKED:
			return true;
		default:
			return false;
	}
};

export const getTypeLabel = type => {
	switch (type) {
		case ISSUE_TYPE.DELAY:
			return 'Förseningsersättning';
		case ISSUE_TYPE.RECLAMATION:
			return 'Reklamation';
		default:
			return 'Övrigt';
	}
};

const initialState = {
	delayIntervals: null,
	isLoading: false,
	errorMessage: '',
	delayJourney: null,
	delayInterval: null,
	delayRegistration: null,
	delayContact: null,
	registeredIncidents: null,
	isLoadingIncidents: false,
};

export const actionCreators = {
	getDelays: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_DELAY_INTERVALS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const delayIntervals = await fetchtDelayIntervals(token);
			dispatch({ type: 'GET_DELAY_INTERVALS_SUCCESS', delayIntervals });
		} catch (error) {
			dispatch({
				type: 'GET_DELAY_INTERVALS_FAILURE',
				message: ERRORS.GET_DELAY_INTERVALS,
			});
		}
	},
	registerIncident: incidentModel => async (dispatch, getState) => {
		dispatch({ type: 'REGISTER_DELAY_INCIDENT_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const incident = await registerDelayIncident(token, incidentModel);
			dispatch({ type: 'REGISTER_DELAY_INCIDENT_SUCCESS', incident });
			return incident;
		} catch (error) {
			dispatch({
				type: 'REGISTER_DELAY_INCIDENT_FAILURE',
				message: ERRORS.REGISTER_DELAY_INCIDENT,
			});
		}
	},
	setDelayJourney: delayJourney => async dispatch => {
		dispatch({ type: 'SET_DELAY_JOURNEY', delayJourney });
	},
	setSelectedDelayInterval: delayInterval => async dispatch => {
		dispatch({ type: 'SET_DELAY_INTERVAL', delayInterval });
	},
	setDelayRegistration: delayRegistration => async dispatch => {
		dispatch({ type: 'SET_DELAY_REGISTRATION', delayRegistration });
	},
	setContactInformation: contactInformation => async dispatch => {
		dispatch({ type: 'SET_DELAY_CONTACT', contactInformation });
	},
	getRegisteredIncidents: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_INCIDENTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const { id, email } = getTraveller(state);

		try {
			const incidents = await getRegisteredIncidents(token, id, email);
			dispatch({ type: 'GET_INCIDENTS_SUCCESS', incidents });
		} catch (error) {
			dispatch({
				type: 'GET_INCIDENTS_FAILURE',
				message: ERRORS.GET_INCIDENTS,
			});
		}
	},
};

export default function reducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_DELAY_INTERVALS_REQUEST':
		case 'REGISTER_DELAY_INCIDENT_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_INCIDENTS_REQUEST':
			return {
				...state,
				isLoadingIncidents: true,
				errorMessage: '',
			};
		case 'GET_DELAY_INTERVALS_SUCCESS':
			return {
				...state,
				isLoading: false,
				delayIntervals: action.delayIntervals,
				errorMessage: '',
			};
		case 'GET_DELAY_INTERVALS_FAILURE':
		case 'REGISTER_DELAY_INCIDENT_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_INCIDENTS_FAILURE':
			return {
				...state,
				isLoadingIncidents: false,
				errorMessage: action.message,
			};
		case 'REGISTER_DELAY_INCIDENT_SUCCESS':
			return {
				...state,
				isLoading: false,
				registeredIncidents: [...state.registeredIncidents, action.incident],
				delayJourney: null,
				delayInterval: null,
				delayRegistration: null,
				delayContact: null,
				errorMessage: '',
			};
		case 'SET_DELAY_JOURNEY':
			return {
				...state,
				delayJourney: action.delayJourney,
			};
		case 'SET_DELAY_INTERVAL':
			return {
				...state,
				delayInterval: action.delayInterval,
			};
		case 'SET_DELAY_REGISTRATION':
			return {
				...state,
				delayRegistration: action.delayRegistration,
			};
		case 'SET_DELAY_CONTACT':
			return {
				...state,
				delayContact: action.contactInformation,
			};
		case 'GET_INCIDENTS_SUCCESS':
			return {
				...state,
				isLoadingIncidents: false,
				registeredIncidents: action.incidents,
				errorMessage: '',
			};
		default:
			return state;
	}
}

// Private selectors

export function getDelayIntervals(state) {
	return state.delayIntervals || [];
}

export function isLoading(state) {
	return state.isLoading;
}

export function hasRegisterIncidentError(state) {
	return state.errorMessage === ERRORS.REGISTER_DELAY_INCIDENT;
}

export function getDelayJourney(state) {
	return state.delayJourney;
}

export function getDelayInterval(state) {
	return state.delayInterval;
}

export function getDelayRegistration(state) {
	return state.delayRegistration;
}

export function getDelayContact(state) {
	return state.delayContact;
}

export const getIncidents = state => state.registeredIncidents;

export const isLoadingIncidents = state => state.isLoadingIncidents;
