import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ConsentForm } from './ConsentForm';
import { Loading } from '../../common/blocks/Loading';
import { actionCreators as consentActionCreators } from '../../../reducer/consent';
import { actionCreators as legalActionCreators } from '../../../reducer/legal';
import {
	getLegal,
	getConsent,
	hasConsentLoadingError,
	hasConsentUpdateError,
	hasLegalLoadingError,
	isLoadingLegal,
	isLoadingConsent,
} from '../../../reducer';
import { PRIVATE } from '../../../Paths';

class Consent extends React.Component {
	componentDidMount() {
		this.loadConsent({ open: true });
		this.loadLatestLegal({ open: true });
	}

	loadConsent = async ({ open }) => {
		const { consent } = this.props;

		if (!consent && open) {
			await this.props.getConsent();
		}
	};

	loadLatestLegal = async ({ open }) => {
		const { legal } = this.props;

		if (!legal && open) {
			await this.props.getLatestLegal();
		}
	};

	updateConsent = async consent => {
		await this.props.updateConsent(consent);
		if (!this.props.hasConsentUpdateError) {
			this.props.renderConfirmationCard(<p>Samtycken sparade</p>);
		}
	};

	continue = () => this.props.push(PRIVATE.PROFILE);

	render() {
		const {
			isLoadingLegal,
			isLoadingConsent,
			legal,
			consent,
			hasLegalLoadingError,
			hasConsentLoadingError,
			hasConsentUpdateError,
		} = this.props;

		return (!consent && isLoadingConsent) || (!legal && isLoadingLegal) ? (
			<Loading/>
		) : (
			<ConsentForm
				onUpdate={this.updateConsent}
				list={consent}
				legal={legal}
				hasLoadingError={hasConsentLoadingError || hasLegalLoadingError}
				hasUpdateError={hasConsentUpdateError}
				isLoading={isLoadingLegal || isLoadingConsent}
			/>
		);
	}
}

function mapStateToProps(store) {
	return {
		isLoadingLegal: isLoadingLegal(store),
		isLoadingConsent: isLoadingConsent(store),
		hasConsentLoadingError: hasConsentLoadingError(store),
		hasConsentUpdateError: hasConsentUpdateError(store),
		hasLoadingError: hasLegalLoadingError(store),
		consent: getConsent(store),
		legal: getLegal(store),
	};
}

export default connect(
	mapStateToProps,
	{ ...consentActionCreators, ...legalActionCreators, push }
)(Consent);
