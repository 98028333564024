import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { actionCreators as journeyActions } from '../../../reducer/journey';
import { actionCreators as productActions } from '../../../reducer/products';
import { DataList } from '../../common/blocks/DataList';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import JourneyList from './JourneyList';
import { Loading } from '../../common/blocks/Loading';
import { Calendar } from '../../common/blocks/Calendar';
import { getJourneyPoints } from '../../../api';
import {
	getToken,
	isLoadingJourneys,
	getJourneys,
} from '../../../reducer';

class JourneySearch extends Component {
	state = {
		start: null,
		stop: null,
		selectedSequence: undefined,
		startSearchText: '',
		stopSearchText: '',
		searchDate: new Date(),
		isCalendarOpen: false,
	};

	static propTypes = {
		onSelectJourney: PropTypes.func,
		showGeographyInfo: PropTypes.bool,
	};

	static defaultProps = {
		showGeographyInfo: true,
	};

	getJourneyPoint = stateName => value => {
		this.setState({ [stateName]: value });
	};

	getStartPoint = this.getJourneyPoint('start');
	getStopPoint = this.getJourneyPoint('stop');

	getJourneys = async () => {
		const { start, stop, searchDate } = this.state;

		this.props.removeProducts();
		this.props.removeSelectedProductSet();

		if (this.hasStartAndStop()) {
			await this.props.getJourneys({
				fromPointId: start.id,
				fromPointType: start.type,
				toPointId: stop.id,
				toPointType: stop.type,
				date: searchDate,
			});
		}
	};

	hasStartAndStop = () => {
		const { start, stop } = this.state;
		return start && start.id && stop && stop.id;
	};

	selectJourney = currentJourney => {
		this.props.onSelectJourney(currentJourney);
		this.setState({
			selectedSequence: {
				key: currentJourney.resultKey,
				number: currentJourney.sequenceNumber,
			},
		});
	};

	searchForPoint = (stateName, cb) => async value => {
		const suggestions = await getJourneyPoints(this.props.token, value);
		const firstSuggestion = suggestions[0];
		const callbackIfSet = typeof cb === 'function' ? cb : () => {};

		if (firstSuggestion) {
			this.setState(
				{
					[stateName]: firstSuggestion,
					[`${stateName}SearchText`]: firstSuggestion.name,
				},
				callbackIfSet
			);
		}
	};

	searchForStartPoint = this.searchForPoint('start');
	searchForStopPoint = this.searchForPoint('stop');
	searchForStartPointAndJourney = this.searchForPoint(
		'start',
		this.getJourneys
	);
	searchForStopPointAndJourney = this.searchForPoint('stop', this.getJourneys);

	loadSuggestions = value => getJourneyPoints(this.props.token, value);

	setDate = date => {
		this.setState({ searchDate: date });
	};

	showNextJourneys = async () => {
		const { start, stop } = this.state;

		await this.props.getLaterJourneys({
			fromPointId: start.id,
			fromPointType: start.type,
			toPointId: stop.id,
			toPointType: stop.type,
		});
	};

	showPreviousJourneys = async () => {
		const { start, stop } = this.state;

		await this.props.getEarlierJourneys({
			fromPointId: start.id,
			fromPointType: start.type,
			toPointId: stop.id,
			toPointType: stop.type,
		});
	};

	handleSearchButtonClick = e => {
		this.setState({ isCalendarOpen: false }, this.getJourneys);
	};

	onToggleCalendar = isOpen => {
		this.setState({ isCalendarOpen: isOpen });
	};

	render() {
		const {
			selectedSequence,
			startSearchText,
			stopSearchText,
			isCalendarOpen,
		} = this.state;
		const {
			isLoadingJourneys,
			journeys,
			showGeographyInfo,
			journeyInfo,
		} = this.props;
		
		return (
			<>
				<SearchBoxesContainer>
					<DataList
						id="from"
						placeholder="Från"
						value={startSearchText}
						onChange={this.getStartPoint}
						onEnterPress={this.searchForStartPointAndJourney}
						onBlur={this.searchForStartPoint}
						loadSuggestions={this.loadSuggestions}
						aria-expanded={this.getStartPoint ? true : false} 
					/>
					<DataList
						id="to"
						placeholder="Till"
						value={stopSearchText}
						onChange={this.getStopPoint}
						onEnterPress={this.searchForStopPointAndJourney}
						onBlur={this.searchForStopPoint}
						loadSuggestions={this.loadSuggestions}
						aria-expanded={this.getStopPoint ? true : false}
					/>
				</SearchBoxesContainer>

				<Calendar
					journeyInfo={journeyInfo}
					onSelectDate={this.setDate}
					onSubmitForm={this.getJourneys}
					onToggle={this.onToggleCalendar}
					isOpen={isCalendarOpen}
				/>

				<SearchButton onClick={this.handleSearchButtonClick}>Sök</SearchButton>

				{isLoadingJourneys && <JourneyLoading />}

				<JourneyList
					journeys={journeys}
					selectedSequence={selectedSequence}
					onSelectJourney={this.selectJourney}
					onShowPreviousJourneys={this.showPreviousJourneys}
					onShowNextJourneys={this.showNextJourneys}
					showGeographyInfo={showGeographyInfo}
				/>
			</>
		);
	}
}

const SearchBoxesContainer = styled.div`
	margin-top: 3rem;
	font-size: 16px;
`

const JourneyLoading = styled(Loading)`
	margin-top: 1em;
`;
const SearchButton = styled(SecondaryButton)`
	margin-top: 1em;
	align-self: center;
`;

export default connect(
	state => ({
		journeys: getJourneys(state),
		isLoadingJourneys: isLoadingJourneys(state),
		token: getToken(state),
	}),
	{ ...journeyActions, ...productActions }
)(JourneySearch);
