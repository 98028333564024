import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Main } from '../common/blocks/Main';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { SCHOOL } from '../../Paths';
import { GroupTable } from './GroupPage';
import {
	actionCreators as orderActions,
	isLoadingBulkCardOrders,
	getBulkSpecificCardOrder,
	isCardOrderProcessing,
} from '../../reducer/school/orders';
import { Loading } from '../common/blocks/Loading';
import { Info } from '../common/elements/Info';
import { formatDate, groupByFour } from '../../utils/formatting';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class TravelcardOrderHistoryDetails extends Component {
	state = {
		waitingForReload: false,
	}

	componentDidMount() {
		const { match, getBulkSpecificCardOrderById } = this.props;
		const { travelcardOrderId } = match.params;

		const order = getBulkSpecificCardOrderById(travelcardOrderId);

		if (!order) {
			this.props.getBulkSpecificCardOrderById(travelcardOrderId);
		}

		this.maybeReloadTravelCardOrder(this.state.waitingForReload);
	}

	componentDidUpdate(prevProps, prevState) {
		this.maybeReloadTravelCardOrder(prevState.waitingForReload);
	}

	maybeReloadTravelCardOrder = (prevWaitingForReload) => {
		const { match } = this.props;
		const orderId = match.params.travelcardOrderId;

		if (this.props.isCardOrderProcessing(orderId) && !this.state.waitingForReload) {
			this.setState({ waitingForReload: true });
			
			setTimeout(() => this.props.getBulkSpecificCardOrderById(orderId), 10 * 60000);
		} else if (prevWaitingForReload) {
			this.setState({ waitingForReload: false });
		}
	}

	render() {
		const { getOrderDetails, isLoadingOrderDetails, match, texts } = this.props;

		if (isLoadingOrderDetails) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar på både resekort och biljetter</p>
					</Banner>
					<Main>
						<Loading text="Laddar order..." />
					</Main>
				</>
			);
		}

		const order = getOrderDetails(match.params.travelcardOrderId);

		if (!order) {
			return (
				<>
					<Banner
						bannerImg={texts.school.images.banners.orderhistory}
						showOnMobile={true}
					>
						<h2>Orderhistorik</h2>
						<p>Här visas ordrar på både resekort och biljetter</p>
					</Banner>
					<Main>
						<Info>Kan inte hitta aktuell order.</Info>
					</Main>
				</>
			);
		}

		const travelCards = order.bulkSpecificCardOrderRows || [];

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderhistory}
					showOnMobile={true}
				>
					<h2>Orderhistorik</h2>
					<p>Här visas ordrar på både resekort och biljetter</p>
				</Banner>
				<Main>
					<OrderHeader>
						<h3>Ordernummer: {order.orderNumber}</h3>
						<h4>Status: {order.status}</h4>
					</OrderHeader>
					<ThemedNavLink exact to={SCHOOL.ORDER_HISTORY_TRAVELCARD}>
						Tillbaka
					</ThemedNavLink>
					<OrderInfo>
						<span>Skapat datum: </span>
						<span>{formatDate(order.createdDate)}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Antal resekort: </span>
						<span>{order.numberOfCardOrderRows}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Kortlayout: </span>
						<span>{order.cardLayoutName}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Skapad av: </span>
						<span>{order.createdBy}</span>
					</OrderInfo>
					<OrderInfo>
						<span>Leveransadress: </span>
						<span>
							{order.attention}, {order.addressLine1}, {order.postalCode}{' '}
							{order.city}
						</span>
					</OrderInfo>
					<TravelcardTable>
						<thead>
							<tr>
								<th>Efternamn</th>
								<th>Förnamn</th>
								<th>Grupp</th>
								<th>Kortnummer</th>
							</tr>
						</thead>
						<tbody>
							{travelCards.map((ticket, idx) => (
								<tr key={idx}>
									<td>{ticket.lastName}</td>
									<td>{ticket.firstName}</td>
									<td>{ticket.groupName}</td>
									<td>{groupByFour(ticket.cardSerialNr)}</td>
								</tr>
							))}
						</tbody>
					</TravelcardTable>
					<OrderTotal>
						<h4>Totalt antal</h4>
						<h3>{order.numberOfCardOrderRows} st</h3>
					</OrderTotal>
				</Main>
			</>
		);
	}
}

const OrderHeader = styled.div`
	text-align: center;
	margin-bottom: 3em;
`;

const OrderInfo = styled.div`
	margin-top: 1em;
	margin-bottom: 0.5em;
	span:first-child {
		font-weight: 600;
	}
`;

const TravelcardTable = styled(GroupTable)`
	th {
		background-color: transparent;
		color: black;
	}
	th,
	td {
		:first-of-type {
			padding-left: 0px;
		}
		:last-of-type {
			padding-right: 0px;
			text-align: right;
		}
	}

	tbody > tr:hover {
		cursor: default;
		background-color: transparent;
	}
`;

const OrderTotal = styled.div`
	text-align: right;
`;

export default withTextContext(
	connect(
		store => ({
			getOrderDetails: getBulkSpecificCardOrder(store),
			isCardOrderProcessing: isCardOrderProcessing(store),
			isLoadingOrderDetails: isLoadingBulkCardOrders(store),
		}),
		{ ...orderActions }
	)(TravelcardOrderHistoryDetails)
);
