import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { Routes } from './router';
import { store } from './store';
import { history } from './store/history';
import { userManager } from './utils/userManager';
import { themeConfig } from './config';
import { themes } from './themes/themes';
import ScrollBehavior from './components/customer/ScrollBehavior';
import { TextProvider } from './texts/context/provider';

loadUser(store, userManager);

let theme = themes[themeConfig.theme];

export default () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<OidcProvider store={store} userManager={userManager}>
				<ThemeProvider theme={theme}>
					<TextProvider>
						<Router history={history}>
							<ScrollBehavior>
								<Routes />
							</ScrollBehavior>
						</Router>
					</TextProvider>
				</ThemeProvider>
			</OidcProvider>
		</ConnectedRouter>
	</Provider>
);
