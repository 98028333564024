import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SecondaryButton } from './SecondaryButton';
import { ReactComponent as Plus } from '../../../images/plus.svg';
import { ReactComponent as Minus } from '../../../images/minus.svg';
import { Validation, regexToInputPattern } from '../../../utils/validation';

export class Incrementer extends Component {
	state = { value: 0, maxValue: 100, minValue: 0 };

	static propTypes = {
		initialValue: PropTypes.number,
		minValue: PropTypes.number,
		maxValue: PropTypes.number,
		onIncrement: PropTypes.func.isRequired,
		onDecrement: PropTypes.func.isRequired,
		onInputChange: PropTypes.func.isRequired,
	};

	componentDidMount() {
		const { initialValue, minValue, maxValue } = this.props;

		const newState = {};

		if (initialValue) {
			newState.value = initialValue;
		}

		if (minValue) {
			newState.minValue = minValue;
		}

		if (maxValue) {
			newState.maxValue = maxValue;
		}

		if (Object.keys(newState).length > 0) {
			this.setState(newState);
		}
	}

	inputChange = e => {
		const curr = parseInt(e.target.value, 10);

		if (!e.target.checkValidity()) {
			return;
		}

		this.setState({ value: curr }, () =>
			this.props.onInputChange(this.state.value)
		);
	};

	decrement = () => {
		this.setState(
			({ value, minValue }) => {
				if (value === minValue) {
					return { value };
				}
				return { value: --value };
			},
			() => this.props.onDecrement(this.state.value)
		);
	};

	increment = () => {
		this.setState(
			({ value, maxValue }) => {
				if (value === maxValue) {
					return { value };
				}
				return { value: ++value };
			},
			() => this.props.onIncrement(this.state.value)
		);
	};

	render() {
		const { value, minValue, maxValue } = this.state;

		return (
			<>
				<RemoveButton id="decrement" onClick={this.decrement} aria-label="Ta bort en biljett" >
					<MinusIcon className="svg-icon" aria-hidden="true" />
				</RemoveButton>
				<label htmlFor='field' className='sr-only'>Antal biljetter</label>
				<NumberInput
					id="field"
					aria-live='polite'
					aria-atomic="true"
					type="number"
					value={value}
					min={minValue}
					max={maxValue}
					onChange={this.inputChange}
					pattern={regexToInputPattern(Validation.onlyDigits)}
					required
				/>
				<AddButton id="increment" onClick={this.increment} aria-label="Lägg till en biljett">
					<PlusIcon className="svg-icon" aria-hidden="true" />
				</AddButton>
			</>
		);
	}
}

const PlusIcon = styled(Plus)`
	stroke: ${props => props.theme.textOnPrimaryColor};
	stroke-width: 2px;
`;

const MinusIcon = styled(Minus)`
	stroke: black;
	stroke-width: 2px;
`;

const NumberInput = styled.input`
	border: none;
	font-weight: bold;
	text-align: center;
	width: 30px;
	height: 30px;
	padding: 1px 0;
`;

const IncrementButton = styled(SecondaryButton)`
	padding: 0;
	width: 30px;
	height: 30px;
`;

const AddButton = styled(IncrementButton)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`;

const RemoveButton = styled(IncrementButton)`
	background-color: #f0f0f0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`;
