import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { JourneyLineList } from './JourneyLineList';
import { UnstyledList } from '../../common/elements/UnstyledList';
import { Info } from '../../common/elements/Info';
import { formatDate, formatTime } from '../../../utils/formatting';
import { ReactComponent as ChevronUp } from '../../../images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../images/chevron-down.svg';
import Arrow from '../../../images/pil.png';
import { NextButton } from '../../common/elements/NextButton';
import { isSameDay } from 'date-fns';
import {
	forMediumTabletLandscapeUp,
	forTabletPortraitUp,
	forZoomedInUsers
} from '../../../utils/mediaqueries';
import { connect } from 'react-redux';
import {
	isLoadingEarlierJourneys,
	isLoadingLaterJourneys,
	hasEarlierJourneysError,
	hasLaterJourneysError,
} from '../../../reducer/journey';
import { Loading } from '../../common/blocks/Loading';
import { Error } from '../../common/elements/Error';
import { addMinutesToDate } from '../../../utils/helperFunctions';

export class JourneyList extends React.Component {
	static propTypes = {
		onSelectJourney: PropTypes.func,
		onShowNextJourneys: PropTypes.func,
		onShowPreviousJourneys: PropTypes.func,
		journeys: PropTypes.array,
		showGeographyInfo: PropTypes.bool,
	};

	static defaultProps = {
		showGeographyInfo: true,
	};

	state = {
		lineToggle: null,
	};

	geographyInfoRef = null;
	zonesRef = null;
	containerRef = null;
	geographyInfoOriginalPosition = null;

	constructor(props) {
		super(props);

		this.geographyInfoRef = React.createRef();
		this.zonesRef = React.createRef();
		this.containerRef = React.createRef();
	}

	componentDidUpdate() {
		const { journeys, showGeographyInfo } = this.props;

		const shouldPositionGeoInfo =
			showGeographyInfo &&
			journeys &&
			journeys.length > 0 &&
			this.isLargeScreenDevice();

		if (shouldPositionGeoInfo) {
			this.positionGeographyInfo();
		}
	}

	isLargeScreenDevice = () =>
		window.matchMedia &&
		window.matchMedia('only screen and (min-width: 985px)').matches;

	positionGeographyInfo = () => {
		const geoElem = this.geographyInfoRef.current;
		const y = window.scrollY || window.pageYOffset;
		const zoneRect = this.zonesRef.current.getBoundingClientRect();
		const containerRect = this.containerRef.current.getBoundingClientRect();

		// get position relative to document
		const zoneTop = zoneRect.top + y;
		const containerTop = containerRect.top + y;

		// subtract 75px from offset to position arrow correctly
		const offset = zoneTop - containerTop - 75;

		geoElem.style.top = `${offset}px`;
	};

	transformMinutesToHours = n => {
		var hours = n / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return { hours: rhours, minutes: rminutes };
	};

	selectJourney = journey => () => this.props.onSelectJourney(journey);

	toggleDetailed = (journey, expanded) => () => {
		let lineToggle = {
			sequenceNumber: journey.sequenceNumber,
			resultKey: journey.resultKey,
		};

		if (expanded) {
			lineToggle = null;
		}

		this.setState({ lineToggle });
	};

	isSequenceSelected = journey => {
		const { selectedSequence = {} } = this.props;

		const isCorrectJourneyKey = selectedSequence.key === journey.resultKey;
		const isSameSequenceNumber =
			selectedSequence.number === journey.sequenceNumber;

		return isCorrectJourneyKey && isSameSequenceNumber;
	};

	isLineToggled = journey => {
		const { lineToggle } = this.state;
		return (
			lineToggle &&
			lineToggle.resultKey === journey.resultKey &&
			lineToggle.sequenceNumber === journey.sequenceNumber
		);
	};

	screenReader = journey => {
		const departureTime = formatTime(journey.departure);
		const arrivalTime = formatTime(journey.arrival);

		return (
			<span className="sr-only">
				<span>
					: Avresa: {journey.from} : {departureTime} :
				</span>
				<span>
					: Destination: {journey.to} : {arrivalTime}{' '}
				</span>
			</span>
		);
	};

	render() {
		const {
			journeys,
			onShowNextJourneys,
			onShowPreviousJourneys,
			showGeographyInfo,
			isLoadingLaterJourneys,
			isLoadingEarlierJourneys,
			hasEarlierJourneysError,
			hasLaterJourneysError,
		} = this.props;

		if (!journeys) return null;

		if (journeys.length > 0) {
			let lastRenderedDate = null;

			return (
				<Container ref={this.containerRef}>
					{showGeographyInfo && (
						<GeographyInfo ref={this.geographyInfoRef}>
							<P>
								Biljetten blir giltig i de zoner som visas oavsett avgångstid
							</P>
							<img
								src={Arrow}
								aria-hidden="true"
								focusable="false"
								width="144px"
								alt=""
							/>
						</GeographyInfo>
					)}
					<ShowPreviousButton onClick={onShowPreviousJourneys}>
						<ChevronUpIcon
							className="svg-icon"
							aria-hidden="true"
							focusable="false"
							alt=""
						/>
						Tidigare avgångar
					</ShowPreviousButton>

					{isLoadingEarlierJourneys && <Loading />}
					{hasEarlierJourneysError && (
						<Error>Kunde inte hämta tidigare avgångar</Error>
					)}

					<List>
						{journeys.map((j, idx) => {
							const routes = j.routes;
							const departureRoute = routes[0];
							const arrivalIndex = routes.length - 1;
							const arrivalRoute = routes[arrivalIndex];

							const departureDeviation =
								departureRoute.realTime &&
								departureRoute.realTime.realTimeInfoField &&
								departureRoute.realTime.realTimeInfoField[0] &&
								departureRoute.realTime.realTimeInfoField[0]
									.depTimeDeviationField
									? departureRoute.realTime.realTimeInfoField[0]
											.depTimeDeviationField
									: null;
							const arrivalDeviation =
								arrivalRoute.realTime &&
								arrivalRoute.realTime.realTimeInfoField &&
								arrivalRoute.realTime.realTimeInfoField[0] &&
								arrivalRoute.realTime.realTimeInfoField[0].arrTimeDeviationField
									? arrivalRoute.realTime.realTimeInfoField[0]
											.arrTimeDeviationField
									: null;

							let journeyTravelTime = Number(j.travelTime);

							const depTime = addMinutesToDate(
								departureRoute.departure,
								departureDeviation ?? 0
							);
							const arrTime = addMinutesToDate(
								arrivalRoute.arrival,
								arrivalDeviation ?? 0
							);

							if (departureDeviation)
								journeyTravelTime -= Number(departureDeviation);
							if (arrivalDeviation)
								journeyTravelTime += Number(arrivalDeviation);

							const travelTime = this.transformMinutesToHours(
								journeyTravelTime
							);

							const isSelected = this.isSequenceSelected(j);
							const isExpanded = this.isLineToggled(j);
							const journeyClass = classNames({ selected: isSelected });
							const refProp = {
								...(idx === 0 && { ref: this.zonesRef }),
							};

							return (
								<Fragment key={`${idx}`}>
									{(() => {
										if (!isSameDay(lastRenderedDate, j.departure)) {
											lastRenderedDate = j.departure;
											return (
												<DateSection>
													<P>{formatDate(j.departure)}</P>
												</DateSection>
											);
										}
										return null;
									})()}
									<Journey className={journeyClass}>
										<TimeContainer>
											<Time
												className={classNames({
													hasDeviation: departureDeviation || arrivalDeviation,
												})}
											>
												<P>
													{formatTime(j.departure)} - {formatTime(j.arrival)}
												</P>
											</Time>
											<TravelTime>
												<P>
													{travelTime.hours
														? `${travelTime.hours} h ${travelTime.minutes} min`
														: `${travelTime.minutes} min`}
												</P>
											</TravelTime>
											{(departureDeviation || arrivalDeviation) && (
												<DeviatingTime>
													<P>
													{depTime ? depTime : formatTime(j.departure)} -{' '}
													{arrTime ? arrTime : formatTime(j.arrival)}
													</P>
												</DeviatingTime>
											)}
										</TimeContainer>
										<JourneyLineList
											routes={j.routes}
											isSelected={isSelected}
											expanded={isExpanded}
										/>
										<SelectButtonContainer>
											<Zones className="journey-list__zones" {...refProp}>
												<P>Giltighetsområde:</P>
												<P>Zon: {j.zones.join(', ')}</P>
											</Zones>
											{!isSelected && (
												<SelectButton onClick={this.selectJourney(j)}>
													Välj
													{this.screenReader(j)}
												</SelectButton>
											)}
										</SelectButtonContainer>

										<ButtonContainer>
											<ButtonExpanded
												onClick={this.toggleDetailed(j, isExpanded)} aria-expanded={this.state.lineToggle ? true : false}
											>
												{isExpanded ? (
													<>
														<ChevronUpIcon
															className="svg-icon"
															aria-hidden="true"
															focusable="false"
															alt=""
														/>
														<span className="sr-only">Minimera</span>
													</>
												) : (
													<>
														<ChevronDownIcon
															className="svg-icon"
															aria-hidden="true"
															focusable="false"
															alt=""
														/>
														<span className="sr-only">Expandera</span>
													</>
												)}
											</ButtonExpanded>
										</ButtonContainer>
									</Journey>
								</Fragment>
							);
						})}
					</List>

					{isLoadingLaterJourneys && <Loading />}

					<ShowNextButton onClick={onShowNextJourneys}>
						Senare avgångar
						<ChevronDownIcon
							className="svg-icon"
							aria-hidden="true"
							focusable="false"
							alt=""
						/>
					</ShowNextButton>

					{hasLaterJourneysError && (
						<Error>Kunde inte hämta senare avgångar</Error>
					)}
				</Container>
			);
		} else {
			return <Info>Det finns inga resor som matchar din sökning.</Info>;
		}
	}
}

const Container = styled.div`
	position: relative;
	margin-top: 1em;
`;

const GeographyInfo = styled.div`
	background: white;
	border: 1px solid #d0021b;
	border-radius: 4px;
	padding: 1em;
	margin-bottom: 1em;

	img {
		display: none;
	}

	${forMediumTabletLandscapeUp`
		background: transparent;
		border: none;
		position: absolute;
		top: 0;
		left: -220px;
		width: 160px;
		padding: 0;
		font-weight: 600;
		font-size: 14px;
		font-style: italic;
		transform: rotate(-5deg);

		img {
			display: inherit;
			margin-left: 50%;
			margin-top: 0.5em;
		}
	`}
`;

const DateSection = styled.div`
	background: #f0f0f0;
	padding: 1em;
	font-weight: bold;
	text-align: center;
`;

const Journey = styled.li`
	display: block;
	justify-items: start;
	align-items: start;
	padding: 1em;
	border-bottom: 2px solid #f0f0f0;

	&:last-child {
		/* border: none; * /*diskutera för att ta ett beslut*/
	}

	&.selected {
		border: dashed 2px rgba(0, 0, 0, 0.75);
	}
`;

const TimeContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	.hasDeviation {
		text-decoration: line-through;
		margin-right: 0.5em;
		color: ${props => props.theme.light_grey};
	}
`;

const Time = styled.span`
	font-weight: bold;
	flex-grow: 1;
`;

const DeviatingTime = styled.span`
	font-weight: bold;
	width: 100%;
`;

const TravelTime = styled.span`
	font-weight: 600;
	color: ${props => props.theme.light_grey};
`;

const List = styled(UnstyledList)`
	text-align: left;
	margin: 0;
`;

const SelectButtonContainer = styled.div`
	display: flex;
	margin-top: 8px;

	${forZoomedInUsers`
		flex-direction: column;
    	gap: 1.5rem;
	`}
`;

const Zones = styled.div`
	flex-grow: 1;
	font-style: italic;
	font-size: 14px;
`;

const SelectButton = styled(NextButton)`
	margin-left: 5px;
	white-space: nowrap;
	align-self: flex-end;
	padding: 0.5em 1.5em;

	${forTabletPortraitUp`
		padding: 0.5em 2em;
	`}

	${forZoomedInUsers`
		align-self: flex-start;
	`}
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	height: 2em;
	display: flex;
	width: 100%;
	justify-content: center;
`;

const ButtonExpanded = styled(Button)``;

const ChevronDownIcon = styled(ChevronDown)`
	fill: none;
`;

const ChevronUpIcon = styled(ChevronUp)`
	fill: none;
`;

const ShowNextButton = styled.button`
	background-color: white;
	border: 0;
	margin-top: 1em;

	${ChevronDownIcon} {
		fill: none;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	${ChevronUpIcon} {
		fill: none;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
`;

const ShowPreviousButton = styled(ShowNextButton)`
	margin-top: 0;
	margin-bottom: 1em;
`;

const P = styled.p`
	margin: 0;
`;

export default connect(store => ({
	isLoadingEarlierJourneys: isLoadingEarlierJourneys(store),
	isLoadingLaterJourneys: isLoadingLaterJourneys(store),
	hasEarlierJourneysError: hasEarlierJourneysError(store),
	hasLaterJourneysError: hasLaterJourneysError(store),
}))(JourneyList);
