import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { CloseCardButton } from '../../common/blocks/CloseCardButton';
import { PopUpBox } from '../../common/elements/PopUpBox';
import { GroupTable } from '../GroupPage';
import { ReactComponent as ProfileIcon } from '../../../images/profile.svg';
import { TextInput } from '../../common/elements/TextInput';
import { NextButton } from '../../common/elements/NextButton';
import isEmpty from 'lodash/isEmpty';
import { Validation, regexToInputPattern } from '../../../utils/validation';
import { actionCreators as studentActions } from '../../../reducer/students';

class AddTravelCardPopup extends Component {
	state = {
		bearers: {},
		hasError: false,
	};

	componentDidMount() {}

	addTravelCard = (travellerId, firstName, lastName) => async () => {
		let name = firstName + ' ' + lastName;
		let serial = this.state.bearers[travellerId];

		try {
			await this.props.createBearer(name, serial, travellerId);
			this.setState({
				hasError: false,
			});
		} catch (error) {
			this.setState({ hasError: true });
		}
	};

	handleChange = target => {
		this.setState(state => ({
			bearers: { ...state.bearers, [target.id]: target.value },
		}));
	};

	isInvalidBearer = travellerId =>
		isEmpty(this.state.bearers[travellerId]) ||
		!Validation.serial.test(this.state.bearers[travellerId]);

	render() {
		const { closePopup, studentWithoutTravelCard } = this.props;

		return (
			<Container>
				<CloseCardButton onClick={closePopup} />
				<h2>Tilldela resekort manuellt</h2>
				<p>
					Här visas <strong>endast</strong> elever utan resekort och utan
					reseapp
				</p>
				<p>
					Observera att elever med ev. pågående kortbeställningar visas i listan
					tills kort tilldelats/levererats
				</p>
				<p>
					Det krävs att du har extra resekort i lager för att tilldela resekort
					manuellt
				</p>

				{this.state.hasError && (
					<ErrorMessage>
						Det gick inte att registrera resekortet. Vänligen försök igen.
					</ErrorMessage>
				)}

				<Table>
					<thead>
						<tr>
							<th />
							<th>Namn</th>
							<th>Personnummer</th>
							<th>Biljetter</th>
							<th>Tilldela resekort</th>
							<th />
						</tr>
					</thead>

					<tbody>
						{studentWithoutTravelCard.map((student) => (
							<TableRow key={student.travellerId}>
								<td>
									<ProfileIcon />
								</td>
								<td>
									{student.firstName} {student.lastName}
								</td>
								<td>{student.personalIdentityNumber}</td>
								<td>{student.ticketType}</td>
								<td>
									<TextInput
										type="text"
										value={this.state.bearers[student.travellerId]}
										handleChange={this.handleChange}
										placeholder="Ange resekortsnummer"
										pattern={regexToInputPattern(Validation.serial)}
										validateOnChange={true}
										id={student.travellerId}
										hasFloatingLabel={false}
										style={{width: "85%"}}
									/>
								</td>
								<td>
									<NextButton
										onClick={this.addTravelCard(
											student.travellerId,
											student.firstName,
											student.lastName
										)}
										disabled={this.isInvalidBearer(student.travellerId)}
									>
										Spara
									</NextButton>
								</td>
							</TableRow>
						))}
					</tbody>
				</Table>
			</Container>
		);
	}
}

const Container = styled(PopUpBox)`
	& > div {
		max-width: 1000px;
	}
`;

const Table = styled(GroupTable)`
	td {
		padding-left: 10px;
	}
`;

const ErrorMessage = styled.p`
	margin-top: 0.5em;
	color: crimson;
`;

const TableRow = styled.tr`
	vertical-align: baseline;
	td > div {
		margin-bottom: 0;
	}
`

export default connect(
	() => ({}),
	{ ...studentActions, push }
)(AddTravelCardPopup);
