import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getCountryCodes, getLegal, identityIsLoading } from '../../reducer';
import { actionCreators as parameterActionCreators } from '../../reducer/parameters';
import { actionCreators as legalActionCreators } from '../../reducer/legal';
import styled from 'styled-components';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import { Label } from './../common/elements/Label';
import { A } from './../common/elements/A';
import { Validation, regexToInputPattern } from '../../utils/validation';
import { TextInput } from './../common/elements/TextInput';
import { Checkbox } from './../common/elements/Checkbox';
import { writeCookie } from '../../utils/cookieManager';
import { Loading } from '../common/blocks/Loading';
import { formatInternationalPhoneNumber } from '../../utils/formatting';
import withTextContext from '../../utils/withTextContext';

class RegisterPrivateCustomerForm extends React.Component {
	state = {
		// personId: '',
		mobile: '',
		countryCodes: [46],
		countryCode: '46',
		mobileValid: true,
		countryCodeValid: true,
		consent: false,
		terms: false,
		name: '',
		nameValid: true,
		captcha: '',
		captchaValid: true,
		email: '',
		emailValid: true,
	};

	handleChange = target => {
		this.setState({
			[target.id]: target.value,
			mobileValid: true,
			countryCodeValid: true,
			nameValid: true,
			captchaValid: true,
			emailValid: true,
		});
	};

	onSubmit = () => {
		let validMobile = false;
		let validCountryCode = false;
		let validName = false;
		let validCaptcha = false;
		let validEmail = false;

		let { /*personId, */ mobile, countryCodes, countryCode, name, captcha, email } = this.state;

		if (mobile.length === 0) {
			validMobile = false;
		}
		if (name.length > 0) {
			validName = true;
		}

		if (countryCodes.indexOf(+countryCode) !== -1) {
			validCountryCode = true;
		}

		validCaptcha = this.isCaptchaValid(captcha);

		if (mobile.length > 0) {
			mobile = formatInternationalPhoneNumber(mobile);
			validMobile = Validation.mobile.test(countryCode + mobile);
		} else {
			validMobile = false;
		}

		if (email.length > 0) {
			validEmail = Validation.email.test(email);
		} else {
			validEmail = true;
		}

		if (validMobile && validCountryCode && validName && validCaptcha && validEmail) {
			const newIdentity = {
				mobile: mobile.length > 0 ? countryCode + mobile : mobile,
				email: email,
				name,
			};
			this.setLegalCookie();
			this.props.onRegister(newIdentity);
		} else {
			this.setState({
				mobileValid: validMobile,
				countryCodeValid: validCountryCode,
				nameValid: validName,
				captchaValid: validCaptcha,
				emailValid: validEmail,
			});
		}
	};

	setLegalCookie = () => {
		let legalCookieValue = {
			terms: this.props.legal.terms,
			privacy: this.props.legal.privacy,
			newsletterConsent: this.state.consent,
		};
		writeCookie('LEGAL_CONSENT', JSON.stringify(legalCookieValue), 365);
	};

	handleCheckboxClick = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	componentDidMount() {
		this.loadCountryCodes();
		this.loadLatestLegal();
	}

	loadCountryCodes = async () => {
		if (isEmpty(this.props.countryCodes)) {
			await this.props.getTravellerParameters();
		}
		const countryCodes = [...this.props.countryCodes];
		this.setState({ countryCodes });
	};

	loadLatestLegal = async () => {
		if (!this.props.legal) {
			await this.props.getLatestLegal();
		}
	};

	countryCodePattern = regexToInputPattern(Validation.countryCode);
	mobilePattern = regexToInputPattern(Validation.mobile);

	isNotValid = () =>
		!this.state.terms ||
		!this.state.mobileValid ||
		!this.state.mobile ||
		!this.state.name ||
		!this.isCaptchaValid(this.state.captcha);

	isCaptchaValid = (captcha) => {
		return captcha.trim().toLowerCase() === 'mars';
	}

	render() {
		const { terms, privacy } = !!this.props.legal ? this.props.legal : '#';
		const { identityIsLoading, texts } = this.props;
		const { nameValid, emailValid, captcha } = this.state;
		const infoText = texts.private.createaccount.createaccountinfo.infotext;
		const isCaptchaValid = this.isCaptchaValid(captcha);

		return (
			<>
				<h3>Skapa konto</h3>
				<p>{infoText}</p>
				<FieldContainer>
					<div className="form-group">
						<Label htmlFor="mobile">Mobilnummer*</Label>
						<PhoneInputGroup className="input-group">
							<TextInputCountryCode
								type="number"
								value={this.state.countryCode}
								handleChange={this.handleChange}
								id="countryCode"
								maxLength="3"
								minLength="1"
								pattern={this.countryCodePattern}
								validateOnChange={true}
								disabled={identityIsLoading}
								autoComplete="tel-country-code"
								isError={!this.state.countryCodeValid}
								errorMessage={this.state.countryCodeValid ? null : "Ej tillåten landskod!."}
								style={{minWidth: "5rem"}}
							/>
							<TextInputPhone
								type="tel"
								value={this.state.mobile}
								handleChange={this.handleChange}
								id="mobile"
								disabled={identityIsLoading}
								autoComplete="tel-national"
								isError={!this.state.mobileValid}
								errorMessage={!this.state.mobileValid ? "Felaktigt format!" : "Vänligen skriv ett korrekt telefonnummer."}
								isFullWidth
								offset="5rem"
								helperText="Anges i följande format: 701234567"
							/>
						</PhoneInputGroup>
						{/* Validation errors have been moved to each individual TextInput field - See KOL-2035 for more details */}
						{/* {this.state.countryCodeValid ? null : (
							<ValidationError>Ej tillåten landskod!</ValidationError>
						)}
						{this.state.mobileValid ? null : (
							<ValidationError>Felaktigt format!</ValidationError>
						)} */}
					</div>

					<div className="form-group">
						<Label htmlFor="email">E-post</Label>
						<TextInputEmail
							type="email"
							value={this.state.email}
							handleChange={this.handleChange}
							id="email"
							disabled={identityIsLoading}
							autoComplete="email"
							isError={!emailValid}
							errorMessage={"Ange e-post"}
							helperText="Ska innehålla ett @. Format: exempel.exempel@mejl.com"
						/>
					</div>

					<div className="form-group">
						<Label htmlFor="name">Förnamn och Efternamn*</Label>
						<TextInputName
							type="text"
							value={this.state.name}
							handleChange={this.handleChange}
							id="name"
							disabled={identityIsLoading}
							autoComplete="name"
							isError={!nameValid}
							errorMessage={"Ange ett namn"}
							helperText="Förnamn och Efternamn: Anders Andersson"

						/>
					</div>

						<div className="form-group">
							<Label htmlFor="captcha">Vilken månad kommer efter februari*</Label>
							<TextInputName
								type="text"
								value={this.state.captcha}
								handleChange={this.handleChange}
								id="captcha"
								disabled={identityIsLoading}
								autoComplete="captcha"
								isError={captcha && !isCaptchaValid}
								errorMessage={"Lös frågan"}
							/>
						{/* Validation errors have been moved to each individual TextInput field - See KOL-2035 for more details */}
						{/* {!nameValid && <ValidationError>Ange ett namn</ValidationError>} */}
					</div>
				</FieldContainer>

				<div className="checkbox">
					<Label>
						<Checkbox
							id="consent"
							name="consent"
							checked={this.state.consent}
							onCheck={this.handleCheckboxClick}
							disabled={identityIsLoading}
						/>{' '}
						Ja tack! jag vill gärna ta del av kampanjer och nyheter
					</Label>
				</div>

				<div className="checkbox">
					<Label>
						<Checkbox
							id="terms"
							name="terms"
							checked={this.state.terms}
							onCheck={this.handleCheckboxClick}
							disabled={identityIsLoading}
						/>{' '}
						Jag godkänner{' '}
						<A href={terms} target="_blank" rel="noopener noreferrer">
							avtal
						</A>{' '}
						och{' '}
						<A href={privacy} target="_blank" rel="noopener noreferrer">
							integritetspolicy
						</A>
						*
					</Label>
				</div>
				<SecondaryButton
					disabled={identityIsLoading || this.isNotValid()}
					onClick={this.onSubmit}
				>
					Skapa konto
				</SecondaryButton>
				{identityIsLoading && <Loading text="Ditt konto skapas..." />}
			</>
		);
	}
}

const FieldContainer = styled.div`
	text-align: left;
`;

const PhoneInputGroup = styled.div`
	display: flex;
`;

const TextInputName = styled(TextInput)`
	width: 100%;
	min-width: 55px;
`;

const TextInputEmail = styled(TextInput)`
	width: 100%;
	min-width: 55px;
`;

const TextInputCountryCode = styled(TextInput)`
	width: 55px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	text-align: center;
`;

const TextInputPhone = styled(TextInput)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: 0;
	flex: 1;
	min-width: 55px;
`;

//Validation errors have been moved to each individual TextInput field - See KOL-2035 for more details 
// const ValidationError = styled.p`
// 	color: #ff0000;
// 	font-size: 14px;
// `;

function mapStateToProps(store) {
	return {
		countryCodes: getCountryCodes(store),
		legal: getLegal(store),
		identityIsLoading: identityIsLoading(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...parameterActionCreators,
		...legalActionCreators,
	})(RegisterPrivateCustomerForm)
);
