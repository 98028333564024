import React from 'react';
import styled from 'styled-components';
import { Main } from './blocks/Main';
import { Loading } from './blocks/Loading';

export function LoadPage({ type = 'info'}) {
		return (
			<LoadWrapper>
				<Loading type={type} />
			</LoadWrapper>
		);
}

const LoadWrapper = styled(Main)`
	justify-content: center;
	margin-top: 1em;
`
