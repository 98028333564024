import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Main } from '../common/blocks/Main';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import { Dropdown } from '../common/elements/Dropdown';
import { GroupInfo } from './blocks/GroupInfo';
import { Checkbox } from '../common/elements/Checkbox';
import { ConfirmBox } from '../common/elements/ConfirmBox';
import { SelectedProductWrapper } from './blocks/SelectedProductWrapper';
import { Label } from './../common/elements/Label';
import { getLocationState } from '../../reducer';
import { actionCreators as groupActions } from '../../reducer/groups';
import {
	actionCreators as studentActions,
	hasStudentBearers,
	getStudentTicketText,
	getReferredAccount,
	referredAccountsLoaded,
} from '../../reducer/students';
import {
	getAllGroups,
	getAllCardLayouts,
	isLoadingGroups,
	getStudentsByIds,
	getStudentById,
	hasStudentsForGroup,
	isLoadingStudentBearers,
	isLoadingStudents,
	getStudentIdsInGroup,
} from '../../reducer';
import { Loading } from '../common/blocks/Loading';
import withTextContext from '../../utils/withTextContext';
import { SCHOOL } from '../../Paths';
import { GroupTable } from './GroupPage';
import { sanitizeCmsHtml } from '../../texts';
import NavLink from 'react-router-dom/NavLink';
import {
	actionCreators as cartActions,
	getAllStudentsInCart,
	getSchoolCart,
} from '../../reducer/school/cart';
import {
	actionCreators as orderActions,
	getPendingBulkSpecificStudentIds,
} from '../../reducer/school/orders';
import { SecondaryButton } from '../common/elements/SecondaryButton';

class OrderTravelCardPage extends Component {
	state = {
		checkAll: false,
		selectedGroup: { name: 'Välj grupp', groupId: '-1' },
		selectedCardLayout: null,
		showConfirmBox: false,
		selectedStudents: {},
		sortedStudents: undefined,
	};

	redirectToCart = () => this.props.push(SCHOOL.CARD_ORDER_CART);

	componentDidMount() {
		const {
			groups,
			pendingBulkSpecificOrders,
			hasReferredAccounts,
		} = this.props;
		if (isEmpty(groups)) {
			this.props.getGroups();
		}

		if (!hasReferredAccounts) {
			this.props.getAllReferredAccounts();
		}

		if (isEmpty(pendingBulkSpecificOrders)) this.props.getPendingCardOrders();
	}

	addToCart = async () => {
		const { getStudentById, cart } = this.props;
		const { selectedCardLayout, selectedGroup } = this.state;
		const selectedStudents = this.getSelectedStudentIds().map(getStudentById);

		const previouslyAddedStudentsInCart =
			cart[selectedCardLayout.id] &&
			cart[selectedCardLayout.id][selectedGroup.groupId]
				? cart[selectedCardLayout.id][selectedGroup.groupId]
				: [];

		await this.props.addToSchoolCart({
			[selectedCardLayout.id]: {
				[selectedGroup.groupId]: [
					...previouslyAddedStudentsInCart,
					...selectedStudents,
				],
			},
		});
		this.setState({ showConfirmBox: true });

		this.onSelectGroup(selectedGroup, false);
		this.onSelectCardLayout(selectedCardLayout);
	};

	onCloseBox = () => {
		this.setState({
			showConfirmBox: false,
		});
	};

	getStudentsforGroup = async studentsInGroup => {
		const {
			cardLayouts,
			studentsInCart,
			pendingOrdersStudentIds,
			getReferredAccount,
		} = this.props;
		if (isEmpty(cardLayouts)) {
			await this.props.getCardLayouts();
		}

		const studentsNotAlreadyInCart = studentsInGroup.filter(
			s => !studentsInCart.includes(s.travellerId)
		);

		let onlyStudentsWithoutTravelCard = studentsNotAlreadyInCart.filter(
			student =>
				student &&
				!this.props.hasStudentBearers(student.travellerId) &&
				!pendingOrdersStudentIds.includes(student.contactId) &&
				!getReferredAccount(student.travellerId)
		);

		const cardTypes = onlyStudentsWithoutTravelCard.map(s => s.cardType);
		const availableCardLayouts = this.props.cardLayouts.filter(l =>
			cardTypes.includes(l.name)
		);

		let sortedStudents = onlyStudentsWithoutTravelCard.sort((a, b) =>
			a.lastName.localeCompare(b.lastName)
		);
		this.setState(() => ({
			sortedStudents: sortedStudents,
			cardLayouts: availableCardLayouts,
		}));
	};

	onSelectGroup = (item, clearCardLayout = true) => {
		this.setState(
			state => ({
				selectedGroup: item,
				cardLayouts: clearCardLayout ? null : state.cardLayouts,
			}),
			async () => {
				const { groupId } = this.state.selectedGroup;

				if (groupId && !this.props.hasStudentsForGroup(groupId)) {
					await this.props.loadStudentsInGroup(groupId);
					await this.props.getBearersForGroup(groupId);
				}

				let studentIds = this.props.getStudentIdsInGroup(groupId);
				let studentsInGroup = this.props.getStudentsByIds(studentIds);

				this.getStudentsforGroup(studentsInGroup);
			}
		);
	};

	onSelectCardLayout = item => {
		const { sortedStudents } = this.state;
		if (sortedStudents) {
			const checkAll = true;
			const studentsWithCardLayout = sortedStudents.filter(
				student => student.cardType === item.name
			);
			const selectedStudents = studentsWithCardLayout.reduce(
				(o, student) => ({ ...o, [student.travellerId]: checkAll }),
				{}
			);
			this.setState({
				selectedCardLayout: item,
				studentsWithCardLayout,
				selectedStudents,
				checkAll,
			});
		}
	};

	confirmContinue = () => this.setState({ showConfirmBox: true });

	handleCheckboxClick = (name, value) => {
		const { selectedStudents } = this.state;
		const updatedSelectStudents = { ...selectedStudents, [name]: value };
		const checkAll = Object.keys(updatedSelectStudents).every(
			id => updatedSelectStudents[id]
		);

		this.setState({
			selectedStudents: updatedSelectStudents,
			checkAll,
		});
	};

	onCheckAllBoxes = () => {
		const { studentsWithCardLayout, checkAll } = this.state;
		const selectedStudents = studentsWithCardLayout.reduce(
			(o, student) => ({ ...o, [student.travellerId]: !checkAll }),
			{}
		);
		this.setState({
			checkAll: !checkAll,
			selectedStudents,
		});
	};

	setDefault = () => {
		const { state } = this.props;

		if (state && state.group) {
			return { ...state.group, title: state.group.name };
		}
	};

	goBack = () => {
		if (this.props.history) {
			this.props.history.goBack();
		} else this.props.push(SCHOOL.ORDER);
	};

	getSelectedStudentIds = () => {
		const { selectedStudents } = this.state;
		return Object.keys(selectedStudents).reduce(
			(list, studentId) =>
				selectedStudents[studentId] ? [...list, studentId] : list,
			[]
		);
	};

	getNoOfSelectedStudents = () => this.getSelectedStudentIds().length;

	render() {
		const {
			isLoadingGroups,
			groups,
			isLoadingStudents,
			studentTicketText,
			cart,
			studentsInCart,
		} = this.props;
		const {
			checkAll,
			selectedGroup,
			selectedCardLayout,
			cardLayouts,
			sortedStudents,
			studentsWithCardLayout,
		} = this.state;

		let students = isEmpty(studentsWithCardLayout)
			? sortedStudents
			: studentsWithCardLayout;

		if (isLoadingGroups) {
			return (
				<Main>
					<Loading text="Laddar grupp..." />
				</Main>
			);
		}

		const noOfSelectedStudents = this.getNoOfSelectedStudents();

		let sortedGroups = Object.values(groups || {});
		sortedGroups = sortedGroups.sort((a, b) => a.name.localeCompare(b.name));
		sortedGroups.unshift({ name: 'Välj grupp' });

		let sortedCardLayouts = Object.values(cardLayouts || {});
		sortedCardLayouts = sortedCardLayouts.sort((a, b) =>
			a.name.localeCompare(b.name)
		);
		sortedCardLayouts.unshift({ name: 'Välj kortlayout' });

		let hasSelectedCardLayout = selectedCardLayout && selectedCardLayout.id;
		let hasSelectedGroup = selectedGroup && !selectedGroup.groupId;
		let boxTitle = this.props.texts.school.ordertravelcard.confirmorder.title;
		let boxInfo = (
			<InfoContainer
				dangerouslySetInnerHTML={sanitizeCmsHtml(
					this.props.texts.school.ordertravelcard.confirmorder.informationtext
				)}
			/>
		);

		return (
			<>
				<Main>
					<TopContainer>
						<TextWrapper>
							<GoBackButton onClick={() => this.goBack()}>Avbryt</GoBackButton>
							{isEmpty(sortedGroups) ? (
								<GroupDropdown
									id="groups"
									disabled={true}
									onSelect={() => {}}
									items={[]}
									renderSelectedItem={() => <div>Grupper saknas</div>}
									renderItem={() => {}}
								/>
							) : (
								<GroupDropdown
									id="groups"
									items={sortedGroups.map(g => ({ ...g, title: g.name }))}
									onSelect={this.onSelectGroup}
									defaultItem={this.setDefault()}
									renderSelectedItem={item => <span>{item.title}</span>}
									renderItem={item => <span>{item.title}</span>}
								/>
							)}
							{isEmpty(sortedGroups) ? (
								<GroupDropdown
									id="cardLayouts"
									disabled={true}
									onSelect={() => {}}
									items={[]}
									renderSelectedItem={() => <div>Kortlayouter saknas</div>}
									renderItem={() => {}}
								/>
							) : (
								<GroupDropdown
									id="cardLayouts"
									items={sortedCardLayouts.map(l => ({ ...l, title: l.name }))}
									onSelect={this.onSelectCardLayout}
									renderSelectedItem={item => <span>{item.title}</span>}
									renderItem={item => <span>{item.title}</span>}
								/>
							)}
							<DropDownInfo>
								Nya grupper skapas på sidan{' '}
								<NavLink to={SCHOOL.GROUPS} exact>
									Elever &amp; Grupper
								</NavLink>
							</DropDownInfo>
							<GroupInfo selectedgroup={selectedGroup} />
						</TextWrapper>
					</TopContainer>
					<Info>
						<h1>Beställ resekort till elever</h1>
						<div
							dangerouslySetInnerHTML={sanitizeCmsHtml(
								this.props.texts.school.ordertravelcard.listview.informationtext
							)}
						/>
					</Info>
					<Info>
						<AddOrderButton
							onClick={this.addToCart}
							disabled={noOfSelectedStudents === 0}
						>
							Lägg till beställning
						</AddOrderButton>
					</Info>

					{isLoadingStudents && <Loading text="Laddar elever..." />}

					<Table>
						<thead>
							<tr>
								<th>Efternamn</th>
								<th>Förnamn</th>
								<th>Personnummer</th>
								<th>Hemkommun</th>
								<th>Biljett</th>
								{hasSelectedCardLayout && (
									<th id="all" onClick={this.onCheckAllBoxes}>
										{checkAll ? 'Avmarkera alla' : 'Markera alla'}
									</th>
								)}
							</tr>
						</thead>

						<tbody>
							{!!students &&
								students.map((student, idx) => (
									<tr key={idx}>
										<td>{student.lastName}</td>
										<td>{student.firstName}</td>
										<td>{student.personalIdentityNumber}</td>
										<td>{student.homeCounty ? student.homeCounty : '-'}</td>
										<td>{studentTicketText(student.travellerId)}</td>
										{hasSelectedCardLayout && (
											<td>
												<StyledLabel>
													<Checkbox
														key={student.travellerId}
														id={student.travellerId}
														onCheck={this.handleCheckboxClick}
														name={student.travellerId}
														checked={
															this.state.selectedStudents[student.travellerId]
														}
													/>
												</StyledLabel>
											</td>
										)}
									</tr>
								))}
						</tbody>
					</Table>
					{hasSelectedGroup && (
						<NoGroup>
							<div>Välj grupp och kortlayout för att starta</div>
						</NoGroup>
					)}
				</Main>

				{!isEmpty(cart) ? (
					<SelectedProductWrapper
						redirectToCart={this.redirectToCart}
						totalCart={studentsInCart.length}
					/>
				) : (
					''
				)}
				{this.state.showConfirmBox && (
					<ConfirmBox
						title={boxTitle}
						info={boxInfo}
						onContinue={this.redirectToCart}
						onClose={this.onCloseBox}
						onBack={this.onCloseBox}
						confirmButtonText="Gå till varukorg"
						cancelButtonText="Lägg till fler ordrar"
					/>
				)}
			</>
		);
	}
}

const NoGroup = styled.div`
	display: flex;
	justify-content: center;
	margin-top: -30px;
	div {
		font-style: italic;
	}
`;

const DropDownInfo = styled.div`
	font-size: 12px;
	margin-bottom: 10px;
	width: 200px;
`;

const TopContainer = styled.div`
	display: flex;
`;

const TextWrapper = styled.div`
	float: left;
`;

const GoBackButton = styled.div`
	background-color: transparent;
	border: none;
	color: ${props => props.theme.link_color};

	:hover {
		color: ${props => props.theme.link_hover_color};
		text-decoration: underline;
		cursor: pointer;
	}
`;

const Info = styled.div`
	text-align: center;
	margin-bottom: 2em;
	font-size: 16px;
`;

const InfoContainer = styled.div`
	word-break: break-word;
	width: 100%;
	text-align: left;
`;

const StyledLabel = styled(Label)`
	float: right;
	margin-right: 1em;

	&:hover {
		cursor: pointer;
	}
`;

const Table = styled(GroupTable)`
	.not-deleted {
		color: rgb(225, 0, 0, 1);
		font-style: italic;
	}
	#all {
		text-align: right;
		padding-right: 10px;
		white-space: nowrap;

		&:hover {
			cursor: pointer;
		}
	}
`;

const AddOrderButton = styled(SecondaryButton)`
	position: relative;
`;

const GroupDropdown = styled(Dropdown)`
	margin: 10px 0px;
`;

export default withTextContext(
	connect(
		store => ({
			state: getLocationState(store),
			groups: getAllGroups(store),
			cardLayouts: getAllCardLayouts(store),
			getStudentIdsInGroup: getStudentIdsInGroup(store),
			getStudentsByIds: getStudentsByIds(store),
			getStudentById: getStudentById(store),
			isLoadingGroups: isLoadingGroups(store),
			isLoading: isLoadingGroups(store),
			hasStudentBearers: hasStudentBearers(store),
			hasStudentsForGroup: hasStudentsForGroup(store),
			isLoadingStudentBearers: isLoadingStudentBearers(store),
			isLoadingStudents: isLoadingStudents(store),
			studentTicketText: getStudentTicketText(store),
			cart: getSchoolCart(store),
			studentsInCart: getAllStudentsInCart(store),
			pendingOrdersStudentIds: getPendingBulkSpecificStudentIds(store),
			getReferredAccount: getReferredAccount(store),
			hasReferredAccounts: referredAccountsLoaded(store),
		}),
		{
			...studentActions,
			...groupActions,
			...cartActions,
			...orderActions,
			push,
		}
	)(OrderTravelCardPage)
);
