import { getToken } from '.';
import { getAdmin } from './school/admins';
import {
	fetchSchoolById,
	getTicketOrderProducts,
	fetchTicketTypes,
	createTicketOrder,
	fetchHomeCountys,
	fetchSchoolByVendorId,
} from '../api';
import isEmpty from 'lodash/isEmpty';

const initialState = {
	data: null,
	school: undefined,
	isLoading: false,
	errorMessage: '',
	isLoadingTicketOrderProducts: false,
	isLoadingCreateTicketOrder: false,
	ticketTypes: [],
	createdTicketOrder: null,
	isLoadingTicketTypes: false,
	hasFetchedSchool: false,
	schoolVendors: {},
	isLoadingSchoolByVendorId: false,
};

export const SCHOOL_ERRORS = {
	GET_SCHOOL: 'GET_SCHOOL',
	GET_PRODUCTS: 'GET_PRODUCTS',
	GET_TICKET_TYPES: 'GET_TICKET_TYPES',
	CREATE_TICKET_ORDER: 'CREATE_TICKET_ORDER',
	GET_HOME_COUNTYS: 'GET_HOME_COUNTYS',
	GET_SCHOOL_BY_VENDOR: 'GET_SCHOOL_BY_VENDOR',
};

export const STORE_NAME = 'school';

export const actionCreators = {
	getSchoolById: schoolId => async (dispatch, getState) => {
		dispatch({ type: 'GET_SCHOOL_REQUEST' });
		const state = getState();
		const token = getToken(state);

		try {
			const school = await fetchSchoolById(token, schoolId);
			dispatch({ type: 'GET_SCHOOL_SUCCESS', school });
		} catch (error) {
			dispatch({
				type: 'GET_SCHOOL_FAILURE',
				message: SCHOOL_ERRORS.GET_SCHOOL,
			});
		}
	},
	getSchoolByVendorId: vendorId => async (dispatch, getState) => {
		dispatch({ type: 'GET_SCHOOL_BY_VENDOR_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const school = await fetchSchoolByVendorId(token, vendorId);
			dispatch({ type: 'GET_SCHOOL_BY_VENDOR_SUCCESS', vendorId, school });
		} catch (error) {
			dispatch({
				type: 'GET_SCHOOL_BY_VENDOR_FAILURE',
				message: SCHOOL_ERRORS.GET_SCHOOL_BY_VENDOR,
			});
		}
	},
	getTicketOrderProducts: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_PRODUCTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const school = getSchool(state);

		try {
			const products = await getTicketOrderProducts(token, school.schoolId);

			dispatch({ type: 'GET_PRODUCTS_SUCCESS', products });
		} catch (error) {
			dispatch({
				type: 'GET_PRODUCTS_FAILURE',
				message: SCHOOL_ERRORS.GET_PRODUCTS,
			});
		}
	},
	getTicketTypes: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_TICKET_TYPES_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const ticketTypes = await fetchTicketTypes(token);

			dispatch({ type: 'GET_TICKET_TYPES_SUCCESS', ticketTypes });
		} catch (error) {
			dispatch({
				type: 'GET_TICKET_TYPES_FAILURE',
				message: SCHOOL_ERRORS.GET_TICKET_TYPES,
			});
		}
	},
	createTicketOrder: ticketOrder => async (dispatch, getState) => {
		dispatch({ type: 'CREATE_TICKET_ORDER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const admin = getAdmin(state);
		const school = getSchool(state);

		ticketOrder = {
			...ticketOrder,
			administratorId: admin.id,
			accountId: school.schoolId,
		};

		try {
			const createdTicketOrder = await createTicketOrder(token, ticketOrder);

			dispatch({ type: 'CREATE_TICKET_ORDER_SUCCESS', createdTicketOrder });
		} catch (error) {
			dispatch({
				type: 'CREATE_TICKET_ORDER_FAILURE',
				message: SCHOOL_ERRORS.CREATE_TICKET_ORDER,
			});
			throw error;
		}
	},
	getHomeCountys: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_HOME_COUNTYS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const homeCountys = await fetchHomeCountys(token);

			dispatch({ type: 'GET_HOME_COUNTYS_SUCCESS', homeCountys });
		} catch (error) {
			dispatch({
				type: 'GET_HOME_COUNTYS_FAILURE',
				message: SCHOOL_ERRORS.GET_HOME_COUNTYS,
			});
		}
	},
	clearCreatedTicketOrder: () => async dispatch =>
		dispatch({ type: 'CLEAR_CREATED_TICKET_ORDER' }),
	clearSchoolState: () => async dispatch =>
		dispatch({ type: 'CLEAR_SCHOOL_STATE' }),
};

export default function schoolReducer(state, action) {
	state = state ? { ...initialState, ...state } : initialState;

	switch (action.type) {
		case 'GET_HOME_COUNTYS_REQUEST':
		case 'GET_SCHOOL_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_SCHOOL_BY_VENDOR_REQUEST':
			return {
				...state,
				isLoadingSchoolByVendorId: true,
				errorMessage: '',
			};
		case 'GET_TICKET_TYPES_REQUEST':
			return {
				...state,
				isLoadingTicketTypes: true,
				errorMessage: '',
			};
		case 'GET_PRODUCTS_REQUEST':
			return {
				...state,
				isLoadingTicketOrderProducts: true,
				errorMessage: '',
			};
		case 'CREATE_TICKET_ORDER_REQUEST':
			return {
				...state,
				isLoadingCreateTicketOrder: true,
				errorMessage: '',
			};
		case 'GET_SCHOOL_SUCCESS':
			return {
				...state,
				isLoading: false,
				school: action.school,
				hasFetchedSchool: true,
				schoolVendors: {
					...state.schoolVendors,
					[action.school.vendorId]: {
						schoolId: action.school.schoolId,
						vendorId: action.school.vendorId,
						name: action.school.name,
					},
				},
			};
		case 'GET_SCHOOL_BY_VENDOR_SUCCESS':
			return {
				...state,
				isLoadingSchoolByVendorId: false,
				schoolVendors: {
					...state.schoolVendors,
					[action.vendorId]: {
						schoolId: action.school.schoolId,
						vendorId: action.vendorId,
						name: action.school.name,
					},
				},
				hasFetchedSchool: true,
			};
		case 'GET_TICKET_TYPES_SUCCESS':
			return {
				...state,
				isLoadingTicketTypes: false,
				ticketTypes: action.ticketTypes,
			};
		case 'GET_HOME_COUNTYS_SUCCESS':
			return {
				...state,
				isLoading: false,
				homeCountys: action.homeCountys,
			};
		case 'GET_PRODUCTS_SUCCESS':
			return {
				...state,
				products: action.products,
				isLoadingTicketOrderProducts: false,
			};
		case 'CREATE_TICKET_ORDER_SUCCESS':
			return {
				...state,
				createdTicketOrder: action.createdTicketOrder,
				isLoadingCreateTicketOrder: false,
			};
		case 'GET_SCHOOL_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_SCHOOL_BY_VENDOR_FAILURE':
			return {
				...state,
				isLoadingSchoolByVendorId: false,
				errorMessage: action.message,
			};
		case 'GET_TICKET_TYPES_FAILURE':
			return {
				...state,
				isLoadingTicketTypes: false,
				errorMessage: action.message,
			};
		case 'GET_HOME_COUNTYS_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_PRODUCTS_FAILURE':
			return {
				...state,
				isLoadingTicketOrderProducts: false,
				errorMessage: action.message,
			};
		case 'CREATE_TICKET_ORDER_FAILURE':
			return {
				...state,
				isLoadingCreateTicketOrder: false,
				errorMessage: action.message,
			};
		case 'CLEAR_CREATED_TICKET_ORDER':
			return {
				...state,
				createdTicketOrder: null,
			};
		case 'CLEAR_SCHOOL_STATE':
			return { ...state, ...initialState };
		default:
			return state;
	}
}

export const getSchool = store => store[STORE_NAME].school;

export const hasSchool = store => store[STORE_NAME].school !== undefined;

export const getTicketTypes = store => store[STORE_NAME].ticketTypes;

export const hasTicketTypes = store => !isEmpty(store[STORE_NAME].ticketTypes);

export const hasHomeCounty = store => !isEmpty(store[STORE_NAME].homeCountys);

export const getAllTicketOrderProducts = state => state.products || [];

export const getHomeCountys = store => store[STORE_NAME].homeCountys;

export const getOrderTicketDetails = store =>
	store[STORE_NAME].ticketDetails || [];

export const getTicketOrderProductById = store => productId =>
	store[STORE_NAME].products.find(p => p.productId === productId);

export const getCreatedTicketOrder = store =>
	store[STORE_NAME].createdTicketOrder;

export const hasTicketOrderProducts = state => !isEmpty(state.products);

export function hasLoadTicketOrderProductsError(store) {
	return store.errorMessage === SCHOOL_ERRORS.GET_PRODUCTS;
}

export const isLoading = store => store.isLoading;

export const isLoadingTicketOrderProducts = store =>
	store.isLoadingTicketOrderProducts;

export const isLoadingCreateTicketOrder = store =>
	store[STORE_NAME].isLoadingCreateTicketOrder;

export const isLoadingTicketTypes = store =>
	store[STORE_NAME].isLoadingTicketTypes;

export const hasFetchedSchool = store => store[STORE_NAME].hasFetchedSchool;

export const getSchoolByVendor = store => vendorId =>
	store[STORE_NAME].schoolVendors[vendorId];

export const getSchoolVendorId = store => schoolId =>
	Object.values(store[STORE_NAME].schoolVendors).find(
		s => s.schoolId === schoolId
	);
