import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SchoolMobileNavigation from './SchoolMobileNavigation';
import MenuIcon from '../../images/menu.svg';
import SignOutIcon from '../../images/sign_out.svg';
import ProfileIcon from '../../images/profile.svg';
import { SCHOOL } from '../../Paths';
import {
	forMediumTabletLandscapeUp,
	forPhoneOnly,
} from '../../utils/mediaqueries';
import SchoolDesktopNavigation from './blocks/SchoolDesktopNavigation';
import {
	isOrgAdmin,
	getAdmin,
	getCurrentSchool,
} from '../../reducer/school/admins';
import { RootContainer } from '../common/blocks/RootContainer';
import { Header } from '../common/blocks/Header';
import Footer from '../school/Footer';
import { Logo } from '../common/blocks/Logo';

class Root extends Component {
	constructor(props) {
		super(props);
		this.globalNav = React.createRef();
	}

	toggleGlobalNav = () => {
		// TODO: Don't manipulate body directly, publish an event or something
		if (document.body.classList.contains('active')) {
			document.body.classList.remove('active');
			this.disableNavLinks();
		} else {
			document.body.classList.add('active');
			this.enableNavLinks();
			this.focusNavLink();
		}
	};

	disableNavLinks = () => {
		this.globalNav.current.setAttribute('aria-hidden', true);
		const links = this.globalNav.current.querySelectorAll('a');
		Array.prototype.forEach.call(links, a => {
			a.setAttribute('tabIndex', '-1');
		});
	};

	enableNavLinks = () => {
		this.globalNav.current.removeAttribute('aria-hidden');
		const links = this.globalNav.current.querySelectorAll('a');
		Array.prototype.forEach.call(links, a => {
			a.removeAttribute('tabIndex');
		});
	};

	focusNavLink = () => {
		this.globalNav.current.removeAttribute('aria-hidden');
		const a = this.globalNav.current.querySelector('a');
		a.focus();
	};

	signout = () =>
		this.props.push(SCHOOL.SIGNOUT, { redirectPath: SCHOOL.HOME });

	selectSchoolAdmin = () => this.props.push(SCHOOL.ADMIN_SCHOOL_CHANGE);

	componentDidMount() {
		this.disableNavLinks();
	}

	render() {
		const { school, admin, isOrgAdmin } = this.props;
		return (
			<Fragment>
				<SlideInMenu id="global-nav" ref={this.globalNav}>
					<Fragment>
						<CloseMenuButton
							onClick={this.toggleGlobalNav}
							type="button"
							className="close"
							aria-label="Stäng"
							tabIndex="-1"
						>
							<span aria-hidden="true">&times;</span>
						</CloseMenuButton>
						<SchoolMobileNavigation
							isOrgAdmin={isOrgAdmin}
							onNavigation={this.toggleGlobalNav}
						/>
					</Fragment>
				</SlideInMenu>
				<Container>
					<Header>
						<div>
							<NavLink exact to={SCHOOL.ORDER} tabIndex="1">
								<Logo type="SecondaryLogo" />
							</NavLink>
						</div>
						<Menu aria-label="Meny">
							<SchoolDesktopNavigation isOrgAdmin={isOrgAdmin} />
							<UserContainer>
								{school && (
									<>
										<span>{school.schoolName}</span>
										<span>{admin.name}</span>
										<span>{school.roleName}</span>
									</>
								)}
							</UserContainer>
							<MenuButton onClick={this.selectSchoolAdmin}>
								<img
									src={ProfileIcon}
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								Byt skola
							</MenuButton>
							<MenuButton onClick={this.signout}>
								<img
									src={SignOutIcon}
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								Logga ut
							</MenuButton>
							<MenuButtonMobile
								onClick={this.toggleGlobalNav}
								aria-controls="global-nav"
								aria-haspopup="true"
							>
								<img
									src={MenuIcon}
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								Meny
							</MenuButtonMobile>
						</Menu>
					</Header>
					{this.props.children}
					<Footer />
				</Container>
			</Fragment>
		);
	}
}

const UserContainer = styled.div`
	span {
		display: block;
		margin-right: 2em;
		font-size: 12px;
		color: #000;
	}
	span:first-child {
		font-size: 14px;
		color: black;
	}

	${forPhoneOnly`
		display: none;
	`}
`;

const Container = styled(RootContainer)`
	position: relative;
	top: 0;
	right: 0;
	-moz-transition: all 300ms;
	-o-transition: all 300ms;
	-webkit-transition: all 300ms;
	transition: all 300ms;

	/* Important detail for mobile:
  container must be positioned on top of nav */
	z-index: 1;

	body.active & {
		transform: translateX(-220px);
	}
`;

const Menu = styled.nav`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-grow: 1;
`;

const SlideInMenu = styled.div`
	background-image: ${props =>
		`linear-gradient(90deg, ${props.theme.background1} 0%, ${
			props.theme.background2
		} 100%)`};
	padding: 1em 2em;
	bottom: 0;
	max-width: 220px;
	overflow: auto;
	position: fixed;
	top: 0;
	right: -220px;
	width: 100%;
	z-index: 0;
	transition: all 300ms;

	body.active & {
		transform: translateX(-220px);
	}
`;

const CloseMenuButton = styled.button`
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	font-size: 2em;
	color: ${props => props.theme.textOnPrimaryColor};
	text-shadow: none;
	opacity: 1;

	&:hover,
	&:focus {
		color: ${props => props.theme.textOnPrimaryColor};
	}
`;

const MenuButton = styled.button`
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: block;
	line-height: 1;
	padding: 0;
	text-align: center;
	min-width: 4em;
	font-size: 0.857em;
	position: relative;
	line-height: 1.5em;

	svg,
	img {
		width: auto;
		height: 18px;
		display: block;
		margin: 0 auto 8px;
	}

	& + & {
		margin-left: 10px;
	}
`;

const MenuButtonMobile = styled(MenuButton)`
	${forMediumTabletLandscapeUp`
		display: none;
	`}
`;

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		isOrgAdmin: isOrgAdmin(state),
		school: getCurrentSchool(state),
		admin: getAdmin(state),
	};
}

export default connect(
	mapStateToProps,
	{ push }
)(Root);
