import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { Main } from '../common/blocks/Main';
import {
	actionCreators as transactionActions,
	isTransactionDeposited,
} from '../../reducer/transaction';
import { actionCreators as cartActions } from '../../reducer/cart';
import { actionCreators as walletActions } from '../../reducer/wallet';
import { Loading } from '../common/blocks/Loading';
import { Error } from './../common/elements/Error';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { push } from 'connected-react-router';
import {
	isTransactionPurchased,
	hasFinalizeTransactionError,
	getTravellerProducts,
	getPurse,
} from '../../reducer';
import { PRIVATE } from '../../Paths';
import { pollUntil } from '../../utils/polling';
import { getUrlParameter } from '../../utils/parsing';
import { Card } from '../common/blocks/Card';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { Banner } from '../common/blocks/Banner';
import withTextContext from '../../utils/withTextContext';

class ConfirmTransactionPage extends Component {
	state = {
		confirmation: false,
		isLoading: true,
		hasError: false,
		transactionId: '',
	};

	async componentDidMount() {
		document.title = "Transaktionssida | Mitt konto"
		let tid;
		// async componentDidMount will swallow any error
		// so wrap whole body in try-catch clause
		try {
			if (this.isTransactionCancelled()) return;

			tid = this.getTidFromLocation();

			await this.props.finalizeTransaction(tid);

			if (this.props.hasFinalizeTransactionError) throw new Error();

			await pollUntil(this.isTransactionComplete(tid));

			if (this.props.purse) {
				this.props.getPurse();
			}

			this.setSuccessState();
		} catch (error) {
			console.error(error);
			this.setErrorState(tid);
		}
	}

	setSuccessState = () => {
		this.setState({ confirmation: true, isLoading: false }, async () => {
			if (this.props.isTransactionDeposited) {
				this.props.getPurse();
			} else if (this.props.isTransactionPurchased) {
				await this.props.placeCardOrders();
				this.props.deleteCart();
				this.props.getTravellerProducts();
			}
		});
	};

	setErrorState = tid =>
		this.setState({ hasError: true, isLoading: false, transactionId: tid });

	isTransactionComplete = transactionId => async () => {
		await this.props.verifyTransaction(transactionId);

		return (
			this.props.isTransactionPurchased || this.props.isTransactionDeposited
		);
	};

	getTidFromLocation = () => getUrlParameter(this.props.search, 'tid');
	isTransactionCancelled = () =>
		getUrlParameter(this.props.search, 'responseCode') === 'Cancel';

	continue = () => {
		this.props.push(PRIVATE.TICKET);
	};

	render() {
		const { confirmation, isLoading, hasError, transactionId } = this.state;
		const { texts } = this.props;

		if (this.isTransactionCancelled()) {
			return (
				<>
					<Banner bannerImg={texts.private.images.banners.buyticketpage} />
					<Main>
						<CheckoutFlexWrapper>
							<CancelledTransactionCard>
								<h4 style={{ marginTop: '0' }}>Köp avbrutet</h4>
								<p style={{ maxWidth: '225px' }}>
									Du har avbrutit köpet. Gå till{' '}
									<ThemedNavLink exact to={PRIVATE.CHECKOUT}>
										varukorgen
									</ThemedNavLink>{' '}
									för att slutföra ditt köp.
								</p>
							</CancelledTransactionCard>
						</CheckoutFlexWrapper>
					</Main>
				</>
			);
		}

		if (hasError) {
			return (
				<>
					<Banner bannerImg={texts.private.images.banners.buyticketpage} />
					<Main>
						<CheckoutFlexWrapper>
							<Error>
								Vi kunde inte bekräfta att köpet genomförts. Var god kontakta
								kundtjänst och ange ditt transaktionsnummer{' '}
								<HighlightedId>{transactionId}</HighlightedId>.
							</Error>
						</CheckoutFlexWrapper>
					</Main>
				</>
			);
		}

		return (
			<>
				<Banner bannerImg={texts.private.images.banners.buyticketpage} />
				<Main>
					<CheckoutFlexWrapper id="confirmTransactionPageWrapper">
						{isLoading && <Loading text="Bekräftar genomfört köp..." />}
						{confirmation && (
							<ConfirmationCard
								onClick={this.continue}
								title="Tack för ditt köp!"
							/>
						)}
					</CheckoutFlexWrapper>
				</Main>
			</>
		);
	}
}

const CancelledTransactionCard = styled(Card)`
	align-items: center;
	${forTabletPortraitUp`
		padding: 3em;
	`}
`;

const HighlightedId = styled.span`
	font-weight: 600;
	font-family: monospace;
	letter-spacing: 0.1em;
	padding: 0.2em 0.5em;
	border: solid 1px #999;
	background-color: #ddd;
	color: #555;
	width: auto;
	border-radius: 5px;
`;

const CheckoutFlexWrapper = styled(FlexWrapper)`
	margin-top: 2em;
`;

function mapStateToProps(store) {
	return {
		search: store.router.location.search,
		isTransactionPurchased: isTransactionPurchased(store),
		isTransactionDeposited: isTransactionDeposited(store),
		hasFinalizeTransactionError: hasFinalizeTransactionError(store),
		travellerProducts: getTravellerProducts(store),
		purse: getPurse(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...transactionActions,
		...cartActions,
		...walletActions,
		push,
	})(ConfirmTransactionPage),
	false
);
