import React from 'react';
import styled from 'styled-components';

export function FlexWrapper(props) {
	return <Container {...props}>{props.children}</Container>;
}

const Container = styled.ul`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: ${props => props.justifyContent || 'center'};
	align-items: flex-start;
	list-style-type: none;
	margin: 0;
	padding: 0;	
`;
