import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '../../common/blocks/Loading';
import { actionCreators as customerActions } from '../../../reducer/customer';
import styled from 'styled-components';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { Dropdown } from '../../common/elements/Dropdown';
import { TextCounter } from '../blocks/TextCounter';
import { Label } from '../../common/elements/Label';
import { getDeregistrationCauses, customerIsLoading } from '../../../reducer';
import isEmpty from 'lodash/isEmpty';
import { forTabletPortraitUp } from '../../../utils/mediaqueries';
import withTextContext from '../../../utils/withTextContext';

class DeregisterAccount extends Component {
	state = {
		selectedCause: this.defaultValue,
		reasonText: '',
	};
	defaultValue = 'Anledning';

	verifyDescription = `Att avregistrera kontot innebär att alla dina sparade resekort
											 avregistreras.`;
	verifyErrorMessage = `Ett fel uppstod när du försökte avregistrera ditt konto.
	 											Var vänlig och kontakta kundtjänst eller försök igen.`;
	verifyCtaText = 'Ja, avregistrera mig';

	componentDidMount() {
		this.getDeregistrationCauses();
	}

	getDeregistrationCauses = () => {
		const { causes } = this.props;

		if (isEmpty(causes)) {
			this.props.getDeregistrationCauses();
		}
	};

	onDropdownSelect = item => {
		const selectedCause = item.title;

		const newState = { selectedCause };

		if (!this.showExplanationField(selectedCause)) {
			newState.reasonText = '';
		}

		this.setState(newState);
	};

	handleReason = ({ target }) => this.setState({ [target.id]: target.value });

	hasNoData = () => this.state.reasonText.length < 1;

	onDeregister = () => {
		const { selectedCause, reasonText } = this.state;

		this.props.onShowVerifyAction({
			description: this.verifyDescription,
			ctaText: this.verifyCtaText,
			callback: this.sendDeregisterRequest,
			data: { selectedCause, reasonText },
		});
	};

	sendDeregisterRequest = async () => {
		const { selectedCause, reasonText } = this.state;

		const responseOk = await this.props.deregisterCustomer({
			deregistrationCause: selectedCause,
			otherCause: reasonText,
		});

		if (responseOk) {
			this.props.renderConfirmationCard(
				<p>Din ansökan om avregistrering är skickad</p>
			);
		} else {
			this.props.onShowVerifyAction({
				description: this.verifyDescription,
				ctaText: this.verifyCtaText,
				errorMessage: this.verifyErrorMessage,
				callback: this.sendDeregisterRequest,
				data: { selectedCause, reasonText },
			});
		}
	};

	otherCause = 'Annat';
	showExplanationField = (selectedCause = this.state.selectedCause) =>
		selectedCause === this.otherCause;

	renderDropDownItem = item => <DropDownItem>{item.title}</DropDownItem>;

	render() {
		const { selectedCause, reasonText } = this.state;
		const { causes, isLoading } = this.props;

		const dropdownItems = [this.defaultValue, ...causes];

		const informationText = this.props.texts.private.profilepage
			.deregisteraccount.informationtext;

		const resonText = this.props.texts.private.profilepage
			.deregisteraccount.resontext;

		return isLoading ? (
			<Loading />
		) : (
			<DeregistrationCauses>
				<BodyTextTopMargin>
					{informationText}
				</BodyTextTopMargin>

				<Label style={{ marginBottom: '20px' }} htmlFor="causes">
					{resonText}
				</Label>

				<Dropdown
					id="causes"
					items={(dropdownItems || []).map(c => ({ title: c }))}
					renderSelectedItem={this.renderDropDownItem}
					renderItem={this.renderDropDownItem}
					onSelect={this.onDropdownSelect}
				/>

				{this.showExplanationField() && (
					<div className="form-group">
						<label className="sr-only" htmlFor="reasonText">
							Anledning
						</label>
						<ReasonCounter text={reasonText} maxCount="500" />
						<TextArea
							className="form-input"
							value={reasonText}
							id="reasonText"
							onChange={this.handleReason}
							maxLength="500"
							placeholder="Beskriv kort varför du vill avregistrera ditt konto"
						/>
					</div>
				)}

				<DeregisterBtn
					disabled={
						selectedCause === this.defaultValue ||
						(this.showExplanationField() && this.hasNoData())
					}
					onClick={this.onDeregister}
				>
					Avregistrera
				</DeregisterBtn>
			</DeregistrationCauses>
		);
	}
}

const DropDownItem = styled.span`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	line-height: 17px;
	text-align: center;
`;

const ReasonCounter = styled(TextCounter)`
	margin-top: 1em;
	text-align: left;
	display: block;
`;

const TextArea = styled.textarea`
	min-height: 150px;
	width: 100%;
	height: 5em;
	font-style: italic;
	color: ${props => props.theme.dark_grey};
	padding: 17px 14px;
`;

const BodyText = styled.p`
	font-style: italic;
	margin-bottom: 22px;
`;

const BodyTextTopMargin = styled(BodyText)`
	margin-top: 20px;

	${forTabletPortraitUp`
		margin-top: 0;
	`}
`;

const DeregistrationCauses = styled.div`
	.mainText {
		display: none;
	}
	.optionList {
		width: 100%;
	}
`;

const DeregisterBtn = styled(SecondaryButton)`
	padding: 0.8em;
	margin-top: 1em;
	width: 100%;
`;

function mapStateToProps(store) {
	return {
		causes: getDeregistrationCauses(store),
		isLoading: customerIsLoading(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, { ...customerActions }, null)(DeregisterAccount)
);
