import React, { Component } from 'react';
import styled from 'styled-components';
import { travellerTags, addonTags, TAGS } from '../../../reducer/products';
import { ProductGeography } from './ProductGeography';

export function getTagTitles(tags) {
	return tags
		.map(t => {
			if (t.valueType === 'boolean' || t.type === TAGS.PRODUCT) {
				return t.title;
			}
			let nameComponents = t.name.split('*');
			let count = nameComponents.length > 1 ? nameComponents[1] : 1;
			return `${count} ${t.title}`;
		});
}

export class ProductInfo extends Component {
	render() {
		const { productSet, className, numberOfProducts, style } = this.props;
		const types = [
			...getTagTitles(productSet.tags.filter(travellerTags)),
			...getTagTitles(productSet.tags.filter(addonTags))
		];
		
		return (
			<div className={className} style={style}>
				<ProductInfoTitle>
					{numberOfProducts > 1 && <>{numberOfProducts}x </>}
					<strong>{productSet.title}, </strong>
					{types.map((type, idx) => (
						<Type key={idx}>
							{type}
							{idx !== types.length-1 && ', '}
						</Type>
					))}
				</ProductInfoTitle>
				<ProductGeography product={productSet} />
			</div>
		);
	}
}

const ProductInfoTitle = styled.h2`
	margin-bottom: 3px;
	font-size: 18px;
`;

const Type = styled.p`
	white-space: nowrap;
	display: inline-block;
	font-size: 16px;
`
