import { base } from './base';
import { halland } from './halland';
import { jonkoping } from './jonkoping';
import { kalmar } from './kalmar';
import { kronoberg } from './kronoberg';
import DOMPurify from 'dompurify';

export default { base, halland, jonkoping, kalmar, kronoberg };

export const sanitizeCmsHtml = text => ({
	__html: replaceNonBreakingSpace(DOMPurify.sanitize(text)),
});

const replaceNonBreakingSpace = text => text.split('&nbsp;').join(' ');
