import React from 'react';
import styled from 'styled-components';
import { Card } from '../../common/blocks/Card';
import { forPhoneAndTabletPortrait, forPhoneOnly } from '../../../utils/mediaqueries';

export function SignupBox(props) {
	return <Box {...props}>{props.children}</Box>;
}

const Box = styled(Card)`
	order: -1;
	padding: 2em;
	margin-right: 2em;
	margin-left: 2em;

	button {
		display: block;
		margin: 2em auto;
		width: 100%;
		max-width: 180px;
	}

	button + button {
		margin-top: 0;
	}

	${forPhoneAndTabletPortrait`
		margin-inline: 0;
	`}

	${forPhoneOnly`
		margin-block: 6rem 1rem;
	`}
`;
