import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Card } from '../common/blocks/Card';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { Loading } from '../common/blocks/Loading';
import { Error } from '../common/elements/Error';
import { isEmpty } from 'lodash';

export class CollectName extends React.Component {
	state = {
		name: '',
		nameIsEmptyValidation: false,
		isLoading: false,
		hasError: false,
	};

	handleChange = e =>
		this.setState({
			name: e.target.value,
			nameIsEmptyValidation: isEmpty(e.target.value),
		});

	updateName = () => {
		const { name } = this.state;
		if (isEmpty(name)) {
			this.setState({ nameIsEmptyValidation: true });
		} else {
			this.props.onUpdateName(name);
		}
	};

	componentDidUpdate() {
		const { hasError } = this.state;
		const { errorMessage } = this.props.traveller;

		if (hasError) return;

		if (errorMessage) {
			this.setState({ hasError: true, isLoading: false });
		}
	}

	getAriaProp = () => {
		const { hasError, nameIsEmptyValidation } = this.state;

		if (nameIsEmptyValidation) {
			return { 'aria-describedby': 'nameIsEmptyError' };
		} else if (hasError) {
			return { 'aria-describedby': 'nameUpdateError' };
		}

		return {};
	};

	render() {
		const { className } = this.props;
		const { isLoading, hasError, nameIsEmptyValidation, name } = this.state;
		const ariaProp = this.getAriaProp();

		return (
			<NameCard className={className}>
				<h2>Ange ditt namn</h2>

				<p>För att använda Mitt konto behöver vi ditt namn.</p>

				<div
					className={classNames('form-group', {
						'has-error has-feedback': hasError || nameIsEmptyValidation,
					})}
				>
					<label className="sr-only" htmlFor="nameInput">
						Namn
					</label>
					<input
						type="text"
						value={this.state.name}
						onChange={this.handleChange}
						className="form-control"
						id="nameInput"
						placeholder="Namn"
						required
						{...ariaProp}
					/>

					{(() => {
						if (nameIsEmptyValidation) {
							return (
								<>
									<span
										className="glyphicon glyphicon-remove form-control-feedback"
										aria-hidden="true"
									/>
									<p id="nameUpdateError" className="help-block">
										Du måste ange ett namn i fältet.
									</p>
								</>
							);
						} else if (hasError) {
							return (
								<>
									<span
										className="glyphicon glyphicon-remove form-control-feedback"
										aria-hidden="true"
									/>
									<p id="nameUpdateError" className="help-block">
										Det gick ej att spara namnet. Var god kontakta kundtjänst.
									</p>
								</>
							);
						}
					})()}
				</div>
				<SecondaryButton
					onClick={this.updateName}
					disabled={isLoading || isEmpty(name)}
				>
					Spara
				</SecondaryButton>
				{hasError && <Error />}

				{isLoading ? <Loading /> : null}
			</NameCard>
		);
	}
}

const NameCard = styled(Card)`
	margin-left: auto;
	margin-right: auto;
`;
