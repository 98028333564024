import React, { Component } from 'react';
import styled from 'styled-components';
import { NextButton } from '../../common/elements/NextButton';
import { ReactComponent as ChevronUp } from '../../../images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../images/chevron-down.svg';
import { ReactComponent as TravelCard } from '../../../images/travel-card.svg';
import { BearerInfo } from '../../common/blocks/BearerInfo';
import { getTagTitles } from '../../common/blocks/ProductInfo';
import { EXPAND_TYPES, TicketBox } from '../TicketPage';
import { Error } from '../../common/elements/Error';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { ProductGeography } from '../../common/blocks/ProductGeography';
import {
	travellerTags,
	productCanBeTransferred,
	productCanBeLoaned,
	productCanBeMoved,
	addonTags,
	productHasNoBearer,
	isMultipleActivationTicket,
} from '../../../reducer/products';
import { TextInput } from '../../common/elements/TextInput';
import { TicketStatus } from '../../common/blocks/TicketStatus';
import { isProductLoanedOut } from '../../../reducer';
import { TransferTicketForm } from './TransferTicketForm';
import { LendTicketForm } from './LendTicketForm';
import { MoveTicketForm } from './MoveTicketForm';
import { LinkButton } from '../../common/elements/LinkButton';
import {
	forPhoneOnly,
	forSmallAndMediumPhone,
	forLargePhoneUp,
	forZoomedInUsers
} from '../../../utils/mediaqueries';
import { ActionInfoText } from './ActionInfoText';

export class Ticket extends Component {
	constructor(props) {
		super(props);
		this.componentElement = React.createRef();
		this.missingBearer = React.createRef();
	}

	state = {
		expandedAction: null,
		errorMessage: '',
	};

	ACTIONS = {
		TRANSFER: 'TRANSFER',
		TRANSFERCONFIRMATION: 'TRANSFERCONFIRMATION',
		LOAN: 'LOAN',
		LOANCONFIRMATION: 'LOANCONFIRMATION',
		MOVE: 'MOVE',
		MOVECONFIRMATION: 'MOVECONFIRMATION',
		ACTIVATE: 'ACTIVATE',
	};

	showTransferButton = () => {
		const { texts } = this.props;
		const { enabletransfertickets } = texts.private.ticketpage.tickets;

		return productCanBeTransferred(this.props.product, this.props.travellerId) && enabletransfertickets === 'True';
	}
		

	showLoanButton = () => {
		const { texts } = this.props;
		const { enableloantickets } = texts.private.ticketpage.tickets;

		return productCanBeLoaned(this.props.product, this.props.travellerId) && enableloantickets === 'True';
	}
	

	showMoveButton = () => productCanBeMoved(this.props.product);

	productMissingBearer = () => productHasNoBearer(this.props.product);

	showActionsMenu = () => {
		const { product } = this.props;
		if (this.componentElement.current) {
			this.componentElement.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}

		return (
			<ShowActionsMenu>
				{this.showLoanButton() && (
					<TicketActionButton
						onClick={() => this.setExpandedAction(this.ACTIONS.LOAN)}
						data-test="loan-button"
					>
						Låna ut biljett
					</TicketActionButton>
				)}
				{this.showTransferButton() && (
					<TicketActionButton
						onClick={() => this.setExpandedAction(this.ACTIONS.TRANSFER)}
						data-test="transfer-button"
					>
						Överlåt biljett
					</TicketActionButton>
				)}
				{product.numAssignmentsLeft > 0 && this.showMoveButton() && (
					<TicketActionButton
						onClick={() => this.setExpandedAction(this.ACTIONS.MOVE)}
						data-test="move-button"
					>
						{this.productMissingBearer() ? 'Koppla Biljett' : 'Flytta biljett'}
					</TicketActionButton>
				)}
			</ShowActionsMenu>
		);
	};

	setExpandedAction = action => {
		let { expandedAction } = this.state;

		if (this.missingBearer.current) {
			this.missingBearer.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}

		if (action === expandedAction) action = null;
		this.setState({ expandedAction: action });
	};

	renderExpandedAction = () => {
		const { product, bearers } = this.props;
		let { expandedAction } = this.state;

		switch (expandedAction) {
			case this.ACTIONS.LOAN:
				return (
					<LendTicketForm
						product={product}
						onLendTicket={this.lendTicket}
						onBackClick={this.onBackClick}
						texts={this.props.texts}
					/>
				);
			case this.ACTIONS.TRANSFER:
				return (
					<TransferTicketForm
						onTransferTicket={this.transferTicket}
						product={product}
						onBackClick={this.onBackClick}
						texts={this.props.texts}
					/>
				);
			case this.ACTIONS.MOVE:
				return (
					<MoveTicketForm
						bearers={bearers}
						onSetBearer={this.setBearer}
						product={product}
						onBackClick={this.onBackClick}
						texts={this.props.texts}
						productMissingBearer={this.productMissingBearer()}
					/>
				);
			case this.ACTIONS.ACTIVATE:
				return this.showActivate();
			default:
				return <Error />;
		}
	};

	transferTicket = (transferModel, productId) =>
		this.props.onTransferTicket(transferModel, productId);
	lendTicket = (lendModel, productId) =>
		this.props.onLendTicket(lendModel, productId);
	setBearer = (productId, selectedBearer) =>
		this.props.onSetBearer(productId, selectedBearer);

	showActivate = () => {
		const { product, texts } = this.props;

		const defaultActivationText =
			texts.private.ticketpage.tickets.activatebutton;
		return (
			<>
				<h3>Aktivera</h3>
				<ActionInfoText texts={this.props.texts} />
				<ActivateButton onClick={() => this.props.onActivateTicket(product.id)}>
					{isMultipleActivationTicket(product)
						? `Aktivera resdag ${product.numberOfActivations -
								product.numActivationsLeft +
								1}`
						: defaultActivationText}
				</ActivateButton>
			</>
		);
	};

	expandCard = expandType => {
		const { product } = this.props;

		this.props.toggleExpandCard(product, expandType);
	};

	isMissingBearerExpanded = (expandCard, product) =>
		!!expandCard &&
		expandCard.id === product.id &&
		expandCard.type === EXPAND_TYPES.MISSING_BEARER;

	isShowFunctionsExpanded = (expandCard, product) =>
		!!expandCard &&
		expandCard.id === product.id &&
		expandCard.type === EXPAND_TYPES.SHOW_ACTIONS;

	enableShowFunctionsButton = () => {
		const { product, expandCard, travellerId } = this.props;

		if (
			!productCanBeTransferred(this.props.product, this.props.travellerId) &&
			!productCanBeLoaned(this.props.product, this.props.travellerId) &&
			!productCanBeMoved(this.props.product)
		) {
			return false;
		}

		return (
			!isProductLoanedOut(product, travellerId) &&
			(!expandCard ||
				expandCard.id !== product.id ||
				(expandCard.id === product.id &&
					expandCard.type !== EXPAND_TYPES.MISSING_BEARER))
		);
	};

	onBackClick = () =>
		this.setState({
			expandedAction: null,
		});

	onRepurchaseTicket = () => {
		const { product } = this.props;

		this.props.onRepurchase(product);
	};

	screenReader = () => {
		const { origin, destination } = this.props.product;
		const { product } = this.props;
		const travellerTypes = getTagTitles(product.tags.filter(travellerTags));
		const addonTypes = getTagTitles(product.tags.filter(addonTags));

		if (origin && destination) {
			return (
				<span className="sr-only">
					<span> : {[...travellerTypes, ...addonTypes]} : </span>
					<span>Avresa: {origin.name} :</span>
					<span>Destination: {destination.name}</span>
				</span>
			);
		}

		return null;
	};

	render() {
		const { expandedAction } = this.state;
		const { product, expandCard, showError, travellerId } = this.props;
		const types = [
			...getTagTitles(product.tags.filter(travellerTags)),
			...getTagTitles(product.tags.filter(addonTags)),
		];

		return (
			<TicketList>
			<TicketBox>
				<TwoColumnRow style={{ margin: '0 0 10px' }}>
					<TicketHeader className="h4">
						{product.productSetTitle},{' '}
						{types.map((type, idx) => (
							<p key={idx}>
								{type}
								{idx !== types.length - 1 && ', '}
							</p>
						))}
					</TicketHeader>
					{product.canBeRepurchased && (
						<RepurchaseButton onClick={this.onRepurchaseTicket}>
							Omköp
							{this.screenReader()}
						</RepurchaseButton>
					)}
				</TwoColumnRow>
				<TwoColumnRow>
					<ProductGeography product={product} />
					{!product.active && !!product.bearer && (
						<ShowActivateButton
							onClick={() => this.setExpandedAction(this.ACTIONS.ACTIVATE)}
						>
							{this.props.texts.private.ticketpage.tickets.activatetickettext}{' '}
							{!!expandedAction && expandedAction === this.ACTIONS.ACTIVATE ? (
								<ChevronUpIcon
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
							) : (
								<ChevronDownIcon
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
							)}
						</ShowActivateButton>
					)}
				</TwoColumnRow>

				<TicketStatus product={product} travellerId={travellerId} />

				{!isProductLoanedOut(product, travellerId) && (
					<>
						<BearerInfo bearer={product.bearer} />

						{!product.bearer && product.numAssignmentsLeft > 0 && (
							<div ref={this.missingBearer}>
								<MissingBearerButton
									onClick={() => this.setExpandedAction(this.ACTIONS.MOVE)}
								>
									<TravelCardIcon
										className="svg-icon"
										aria-hidden="true"
										focusable="false"
										alt=""
									/>
									{this.props.texts.private.ticketpage.tickets.missingbearer}{' '}
									{expandedAction !== null &&
									expandedAction === this.ACTIONS.MOVE ? (
										<RedChevronUpIcon
											className="svg-icon"
											aria-hidden="true"
											focusable="true"
											alt=""
										/>
									) : (
										<RedChevronDownIcon
											className="svg-icon"
											aria-hidden="true"
											focusable="true"
											alt=""
										/>
									)}
								</MissingBearerButton>
							</div>
						)}
					</>
				)}
				{showError && showError.id === product.id && (
					<Error>{showError.message}</Error>
				)}
				{expandedAction !== null
					? this.renderExpandedAction()
					: this.enableShowFunctionsButton() && (
							<ShowFunctionsButton
								ref={this.componentElement}
								onClick={() => this.expandCard(EXPAND_TYPES.SHOW_ACTIONS)}
								data-test="functions-button"
							>
								{this.isShowFunctionsExpanded(expandCard, product) ? (
									<p>
										<ChevronUpIcon
											className="svg-icon"
											aria-hidden="true"
											focusable="true"
											alt=""
										/>
									</p>
								) : (
									<>
										<p>{this.props.texts.private.ticketpage.tickets.expand}</p>
										<p>
											<ChevronDownIcon
												className="svg-icon"
												aria-hidden="true"
												focusable="true"
												alt=""
											/>
										</p>
									</>
								)}
							</ShowFunctionsButton>
					  )}
				{expandedAction === null &&
					this.isShowFunctionsExpanded(expandCard, product) &&
					this.showActionsMenu(product.id)}
			</TicketBox>
			</TicketList>
		);
	}
}

const TicketHeader = styled.h3`
	font-weight: 600;
	line-height: 27px;
	margin: 0;

	p {
		font-size: 13px;
		line-height: 18px;
		white-space: nowrap;
		display: inline-block;
	}
`;

const RepurchaseButton = styled(NextButton)`
	padding: 2px 14px;
	max-height: 27px;
	min-width: 90px;
	margin-left: 8px;
`;

const ShowActivateButton = styled(LinkButton)`
	white-space: nowrap;
	align-self: flex-start;

	svg {
		stroke: ${props => props.theme.link_color};
	}
`;

export const BackButton = styled(LinkButton)`
	/* padding-top: 5rem; */
	align-self: center;
	${props => props?.whiteSpace && "margin-top: 2.5rem;"}
`;

const ActivateButton = styled(SecondaryButton)`
	margin: 0 auto;

	${forPhoneOnly`
		margin: 0;
	`}
`;

const ChevronUpIcon = styled(ChevronUp)`
	fill: none;
`;
const ChevronDownIcon = styled(ChevronDown)`
	fill: none;
`;
const RedChevronUpIcon = styled(ChevronUpIcon)`
	stroke: ${props => props.theme.red};
`;
const RedChevronDownIcon = styled(ChevronDownIcon)`
	stroke: ${props => props.theme.red};
`;

const TravelCardIcon = styled(TravelCard)`
	margin-right: 0.5em;

	g {
		g {
			fill: ${props => props.theme.red};
		}
	}
`;

const MissingBearerButton = styled(LinkButton)`
	align-self: flex-start;
	color: ${props => props.theme.red};
`;

export const InfoSpan = styled.span`
	font-size: 11px;
	font-style: italic;
	margin: 4px 2px;
	font-weight: 700;

	span:last-child {
		color: ${props => props.theme.red};
	}
`;

const ShowFunctionsButton = styled.button`
	background: none;
	border: none;
	margin: 15px auto 0;
	display: block;
	font-family: ${props => props.theme.text};
	font-size: 12px;
	font-weight: 600;
	line-height: 17px;
	p {
		margin: 0;
	}
`;

export const ConfirmationText = styled.p`
	font-weight: 600;
	text-align: center;
	margin-top: 15px;
`;

export const SpanRow = styled.div`
	flex-direction: row;
	display: flex;
	align-items: center;
	margin: 12px 0 10px;

	svg:first-child {
		margin-right: 5px;
		transform: rotate(180deg);
		width: 25px;
	}
	span:last-child {
		font-size: 18px;
	}
`;

export const CallToActionButton = styled(SecondaryButton)`
	white-space: nowrap;

	${forSmallAndMediumPhone`
		width: 100%;
	`}
`;

export const TransferTicketCallToActionButton = styled(CallToActionButton)`
	padding: 0.7em 3em;
`

export const ActionButton = styled(SecondaryButton)`
	background-color: #ffffff;
	color: black;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.06);
	padding: 0.5em 4em;

	${forSmallAndMediumPhone`
		margin-top: 1em;
		width: 100%;
	`}
`;

export const EmailOrPhoneInput = styled(TextInput)`
	min-width: 55px;
	margin-right: 0.5em;

	${forSmallAndMediumPhone`
		width: 100%;
		margin: 0 0 1em;
	`}
`;

export const TwoColumnRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${forZoomedInUsers`
		flex-direction: column;
    	text-align: center;
    	padding-bottom: 1rem;
	`}
`;

export const FormRow = styled(TwoColumnRow)`
	margin-top: 1em;
	margin-bottom: 1em;

	${forSmallAndMediumPhone`
		flex-direction: column;
	`}
`;

export const TransferTicketFormRow = styled(FormRow)`
	align-items: flex-start;
`

export const ConfirmationFormRow = styled(FormRow)`
	${forLargePhoneUp`
		flex-direction: row-reverse;
	`}
`;

const ShowActionsMenu = styled.div`
	display: flex;
	flex-direction: column;
`;

const TicketActionButton = styled.button`
	border: none;
	background-color: #ffffff;
	padding: 8px 41px 8px;
	color: #2e2e2e;
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-weight: 600;
	margin-top: 10px;

	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.06);

	&:hover {
		box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.15);
	}
`;

export const ValidationError = styled.p`
	color: #ff0000;
	font-size: 14px;
	white-space: pre-line;
    margin: 15px 2px 10px;
`;

const TicketList = styled.li`
padding: 1em 2em;
text-align: center;
position: relative;
max-width: 500px;
width: 100%;
margin: 0;

	${forZoomedInUsers`
		padding: 1em 0.7em;
	`}
`;
