import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { Loading } from '../../common/blocks/Loading';
import { actionCreators as travellerActionCreators } from '../../../reducer/traveller';
import { actionCreators as parameterActionCreators } from '../../../reducer/parameters';
import { ConfirmationCard } from '../../common/blocks/ConfirmationCard';
import { LoginDetailsEditableField } from './LoginDetailsEditableField';
import { LoginDetailsVerifyField } from './LoginDetailsVerifyField';
import {
	getTraveller,
	isLoadingTraveller,
	hasUpdateTravellerError,
	hasVerifyPropertyError,
	getCountryCodes,
	hasCountryCodes,
} from '../../../reducer';
import { Error } from '../../common/elements/Error';
import { OidcConfig } from '../../../config';
import { A } from '../../common/elements/A';
import { formatInternationalPhoneNumber } from '../../../utils/formatting';
import { ProfileCardHeader } from './ProfileCardHeader';
import withTextContext from '../../../utils/withTextContext';

export const TYPES = {
	PHONENUMBER: 'phoneNumber',
	EMAIL: 'email',
};

class LoginDetails extends React.Component {
	state = {
		showConfirmation: false,
		showVerification: false,
		showEditMobileField: false,
		newPhoneNumber: '',
		newEmail: '',
		verificationCode: '',
		phoneNumberEditable: false,
		emailEditable: false,
		countryCode: '46',
		countryCodes: [46],
	};

	componentDidMount() {
		if (!this.props.hasCountryCodes) this.loadCountryCodes();
	}

	loadCountryCodes = async () => {
		await this.props.getTravellerParameters();
		const countryCodes = [...this.props.countryCodes];
		this.setState({ countryCodes });
	};

	expandCard = () => {
		this.props.onCardExpanded('loginDetails');
	};

	handleInputChange = changedInput => {
		this.setState({ [changedInput.name]: changedInput.value });
	};

	handleChangeCountryCode = newCountryCode =>
		this.setState({ countryCode: newCountryCode });

	handleVerificationCodeChange = value => {
		this.setState({ verificationCode: value });
	};

	onSubmit = async submittedData => {
		await this.props.updateTraveller({
			[submittedData.type]: submittedData.value,
		});

		if (!this.props.hasUpdateTravellerError) {
			this.setState({ showVerification: true });
		}
	};

	onSubmitVerificationCode = async () => {
		const { verificationCode: code } = this.state;

		var verificationData = this.getVerificationData();

		await this.props.verifyField({
			field: verificationData.type,
			value: verificationData.value,
			code,
		});

		if (!this.props.hasVerifyPropertyError) {
			this.props.renderConfirmationCard(
				<p>Dina inloggningsuppgifter är uppdaterade</p>
			);
		}
	};

	enableEditing = type => {
		let phoneNumberEditable = false;
		let emailEditable = false;

		if (type === TYPES.PHONENUMBER) phoneNumberEditable = true;
		if (type === TYPES.EMAIL) emailEditable = true;

		this.setState({
			phoneNumberEditable,
			emailEditable,
		});
	};

	disableEditing = () => {
		this.setState({
			phoneNumberEditable: false,
			emailEditable: false,
			newPhoneNumber: '',
			newEmail: '',
		});
	};

	getVerificationData = () => {
		const {
			phoneNumberEditable,
			emailEditable,
			newPhoneNumber,
			newEmail,
			countryCode,
		} = this.state;
		let type;
		let value;

		if (phoneNumberEditable) {
			type = TYPES.PHONENUMBER;

			let internationalFormatPhonenNumber = formatInternationalPhoneNumber(
				newPhoneNumber
			);
			value = internationalFormatPhonenNumber.startsWith(countryCode)
				? internationalFormatPhonenNumber
				: countryCode + internationalFormatPhonenNumber;
		} else if (emailEditable) {
			type = TYPES.EMAIL;
			value = newEmail;
		}

		return { type, value };
	};

	disableSaveButton = (oldValue, newValue) =>
		!newValue || newValue.length === 0 || oldValue === newValue;

	render() {
		const {
			showConfirmation,
			newPhoneNumber,
			countryCode,
			newEmail,
			phoneNumberEditable,
			emailEditable,
			showVerification,
			verificationCode,
			countryCodes,
		} = this.state;
		const {
			isLoadingTraveller,
			traveller,
			hasUpdateTravellerError,
			hasVerifyPropertyError,
		} = this.props;

		const verificationData = this.getVerificationData();

		const contactInformationText = this.props.texts.private.profilepage
			.logindetails.informationtext;

		if (showConfirmation) {
			return (
				<ConfirmationCard
					onClick={this.continue}
					text={<p>Dina inloggningsuppgifter är uppdaterade</p>}
				/>
			);
		}

		return isLoadingTraveller ? (
			<Loading />
		) : showVerification ? (
			<>
				<LoginDetailsVerifyField
					verificationCode={verificationCode}
					verificationData={verificationData}
					handleVerificationCodeChange={this.handleVerificationCodeChange}
					submitVerificationCode={this.onSubmitVerificationCode}
					type={newPhoneNumber.length > 0 ? TYPES.PHONENUMBER : TYPES.EMAIL}
				/>
				{hasVerifyPropertyError && <Error>Felaktig kod</Error>}
			</>
		) : (
			<>
				<ProfileCardHeader>Inloggningsuppgifter</ProfileCardHeader>
				<LoginDetailsEditableField
					type={TYPES.PHONENUMBER}
					currentValue={traveller.phoneNumber}
					value={newPhoneNumber}
					isEditable={phoneNumberEditable}
					handleChange={this.handleInputChange}
					enableEdit={this.enableEditing}
					changeDisabled={emailEditable}
					submitDisabled={
						countryCode.length === 0 ||
						newPhoneNumber.length === 0 ||
						this.disableSaveButton(
							traveller.phoneNumber,
							countryCode + newPhoneNumber
						)
					}
					handleSubmit={this.onSubmit}
					onBackClick={this.disableEditing}
					countryCodes={countryCodes}
					countryCode={countryCode}
					handleChangeCountryCode={this.handleChangeCountryCode}
				/>
				<LoginDetailsEditableField
					role="alert"
					type={TYPES.EMAIL}
					currentValue={traveller.email}
					value={newEmail}
					isEditable={emailEditable}
					handleChange={this.handleInputChange}
					enableEdit={this.enableEditing}
					changeDisabled={phoneNumberEditable}
					submitDisabled={this.disableSaveButton(traveller.email, newEmail)}
					handleSubmit={this.onSubmit}
					onBackClick={this.disableEditing}
					countryCodes={null}
				/>
				<ChangePasswordLink
					href={OidcConfig.private.authority + '/reset/email'}
					target="_blank"
				>
					Ändra lösenord &rsaquo;
				</ChangePasswordLink>
				{hasUpdateTravellerError && <Error />}
				<InformationText>{contactInformationText}</InformationText>
			</>
		);
	}
}

const ChangePasswordLink = styled(A)`
	font-family: ${props => props.theme.text};
	font-size: 14px;
	font-weight: 600;
	line-height: 19px;
	display: block;
	margin: 12 0 22px 0;
	padding: 0;
	padding-top: 2.5rem;
`;

const InformationText = styled.p`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 12px;
	font-weight: 300;
	line-height: 17px;
	margin: 0;
`;

function mapStateToProps(state) {
	return {
		traveller: getTraveller(state),
		hasUpdateTravellerError: hasUpdateTravellerError(state),
		isLoadingTraveller: isLoadingTraveller(state),
		hasVerifyPropertyError: hasVerifyPropertyError(state),
		countryCodes: getCountryCodes(state),
		hasCountryCodes: hasCountryCodes(state),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...travellerActionCreators,
		...parameterActionCreators,
		push,
	})(LoginDetails)
);