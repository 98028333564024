import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as oidcReducer, SESSION_TERMINATED } from 'redux-oidc';
import customerReducer, * as Customer from './customer';
import utilsReducer, * as Utils from './utils';
import travellerReducer, * as Traveller from './traveller';
import consentReducer, * as Consent from './consent';
import legalReducer, * as Legal from './legal';
import bearerReducer, * as Bearer from './bearer';
import journeyReducer, * as Journey from './journey';
import productsReducer, * as Products from './products';
import indentityReducer, * as Identity from './identity';
import parametersReducer, * as Parameters from './parameters';
import cartReducer, * as Cart from './cart';
import walletReducer, * as Wallet from './wallet';
import transactionReducer, * as Transaction from './transaction';
import issueReducer, * as Issue from './issue';
import groupsReducer, * as Groups from './groups';
import studentsReducer, * as Students from './students';
import schoolReducer, * as School from './school';
import cmsReducer, * as Cms from './cms';
import adminsReducer, * as Admins from './school/admins';
import ordersReducer, * as Orders from './school/orders';
import cultureTripsReducer, * as CultureTrips from './school/culturetrips';
import schoolCartReducer, * as SchoolCart from './school/cart';
import { isSchool } from '../utils/helperFunctions';

const createPrivateReducer = history => {
	const endSessionReducer = (state = {}, action) => {
		if (
			!isSchool() &&
			(action.type === SESSION_TERMINATED || action.type === 'USER_SIGNED_IN')
		) {
			delete state.cart;
			delete state.wallet;
		}
		return state;
	};

	return combineReducers({
		session: endSessionReducer,
		router: connectRouter(history),
		oidc: oidcReducer,
		customer: customerReducer,
		traveller: travellerReducer,
		consent: consentReducer,
		legal: legalReducer,
		bearer: bearerReducer,
		journey: journeyReducer,
		[Products.STORE_NAME]: productsReducer,
		identity: indentityReducer,
		parameters: parametersReducer,
		cart: cartReducer,
		[Wallet.STORE_NAME]: walletReducer,
		[Transaction.STORE_NAME]: transactionReducer,
		issue: issueReducer,
		cms: cmsReducer,
		utils: utilsReducer,
	});
};

const createSchoolReducer = history =>
	combineReducers({
		router: connectRouter(history),
		oidc: oidcReducer,
		parameters: parametersReducer,
		[Students.STORE_NAME]: studentsReducer,
		groups: groupsReducer,
		students: studentsReducer,
		cms: cmsReducer,
		school: schoolReducer,
		[Admins.STORE_NAME]: adminsReducer,
		[Orders.STORE_NAME]: ordersReducer,
		journey: journeyReducer,
		[CultureTrips.STORE_NAME]: cultureTripsReducer,
		[Products.STORE_NAME]: productsReducer,
		[SchoolCart.STORE_NAME]: schoolCartReducer,
	});

export const createRootReducer = history => {
	const appReducer = isSchool()
		? createSchoolReducer(history)
		: createPrivateReducer(history);

	return (state, action) => appReducer(state, action);
};

// Public selectors

export const hasError = (store, name) => store[name].errorMessage !== '';

export function getToken(store) {
	return store.oidc.user ? store.oidc.user.access_token : null;
}

export const userIsAuthenticated = store =>
	store.oidc.user && !store.oidc.user.expired;

export const isLoadingUser = store => store.oidc.isLoadingUser;

export function getUrlParameter(store) {
	return store.router.location.search;
}

export function getLocationState(store) {
	return store.router.location.state;
}

export const getPathName = store => store.router.location.pathname;

export const getUserProfile = store => store.oidc.user.profile;

export const getAllGroups = store => Groups.getGroups(store.groups);

export const getAllCardLayouts = store =>
	Students.getCardLayouts(store.students);

export const hasGroupError = store => Groups.hasError(store.groups);
export const hasCreateGroupError = store => Groups.hasCreateError(store.groups);
export const hasDeleteGroupStudentError = store =>
	Groups.hasDeleteStudentError(store.groups);
export const isLoadingGroups = store => Groups.isLoading(store.groups);
export const isLoadingGroupDeleteStudent = store =>
	Groups.isLoadingDeleteStudent(store.groups);

export const getStudentIdsInGroup = store =>
	Groups.getStudentIdsInGroup(store.groups);

export const hasStudentsForGroup = store =>
	Groups.hasStudentsForGroup(store.groups);

export const getStudents = store => Students.getStudents(store.students);

export const hasStudentsError = store => Students.hasError(store.students);

export const getStudentsByIds = store =>
	Students.getStudentsByIds(store.students);

export const getStudentById = store => Students.getStudentById(store.students);

export const getImportedStudents = store =>
	Students.getImportedStudents(store.students);
export const isLoadingStudents = store => Students.isLoading(store.students);

export const isLoadingStudentBearers = store =>
	Students.isLoadingStudentBearers(store.students);

export const getAllTicketOrderProducts = store =>
	School.getAllTicketOrderProducts(store.school);

export const hasTicketOrderProducts = store =>
	School.hasTicketOrderProducts(store.school);

export const isLoadingSchool = store => School.isLoading(store.school);

export const hasLoadTicketOrderProductsError = store =>
	School.hasLoadTicketOrderProductsError(store.school);

export function hasCountryCodes(store) {
	return Parameters.hasCountryCodes(store.parameters);
}

export function getCountryCodes(store) {
	return Parameters.getCountryCodes(store.parameters);
}

export function getDelayIntervals(store) {
	return Issue.getDelayIntervals(store.issue);
}

export function getDelayJourney(store) {
	return Issue.getDelayJourney(store.issue);
}

export function getDelayInterval(store) {
	return Issue.getDelayInterval(store.issue);
}

export function getDelayRegistration(store) {
	return Issue.getDelayRegistration(store.issue);
}

export function getDelayContact(store) {
	return Issue.getDelayContact(store.issue);
}

export function hasRegisterIncidentError(store) {
	return Issue.hasRegisterIncidentError(store.issue);
}

export function isLoadingIntervals(store) {
	return Issue.isLoading(store.issue);
}

export const getIncidents = store => Issue.getIncidents(store.issue);

export const isLoadingIncidents = store =>
	Issue.isLoadingIncidents(store.issue);

export function getSelectedProductSet(store) {
	return Products.getSelectedProductSet(store.products);
}

export function getProductSets(store) {
	return Products.getProductSets(store.products);
}

export function getRepurchasableProductSets(store) {
	return Products.getRepurchaseProductSets(store.products);
}

export const isProductLoanedOut = (product, currentTravellerId) =>
	Products.isProductLoanedOut(product, currentTravellerId);

export const isProductLoanedToUser = (product, currentTravellerId) =>
	Products.isProductLoanedToUser(product, currentTravellerId);

export const isProductAwaitingActivation = (product, currentTravellerId) =>
	Products.isProductAwaitingActivation(product, currentTravellerId);

export const hasGetProductsError = store =>
	Products.hasGetProductsError(store.products);

export function getTravellerId(store) {
	return Traveller.getTravellerId(store.traveller);
}

export function getWalletId(store) {
	return Traveller.getWalletId(store.traveller);
}

export function getTraveller(store) {
	return Traveller.getCurrentTraveller(store.traveller);
}

export function hasGetTravellerError(store) {
	return Traveller.hasGetTravellerError(store.traveller);
}

export function hasGetTravellerProductsError(store) {
	return Traveller.hasGetTravellerProductsError(store.traveller);
}

export function hasUpdateTravellerError(store) {
	return Traveller.hasUpdateTravellerError(store.traveller);
}

export function hasUpdateProductBearerError(store) {
	return Traveller.hasUpdateProductBearerError(store.traveller);
}

export function hasTransferProductError(store) {
	return Traveller.hasTransferProductError(store.traveller);
}

export function hasLoanProductError(store) {
	return Traveller.hasLoanProductError(store.traveller);
}

export function hasVerifyPropertyError(store) {
	return Traveller.hasVerifyError(store.traveller);
}

export function isLoadingTraveller(store) {
	return Traveller.isLoadingTraveller(store.traveller);
}

export function isLoadingTravellerProducts(store) {
	return Traveller.isLoadingProducts(store.traveller);
}

export function getTravellerProducts(store) {
	return Traveller.getProducts(store.traveller);
}

export function getCartId(store) {
	return Cart.getCartId(store.cart);
}

export function hasCartError(store) {
	return Cart.hasError(store.cart);
}

export function hasDeleteCartItemError(store) {
	return Cart.hasDeleteCartItemError(store.cart);
}

export function hasPurchaseCartError(store) {
	return Cart.hasPurchaseCartError(store.cart);
}

export function getCart(store) {
	return Cart.getCart(store.cart);
}

export function getNumberOfCartItems(store) {
	return Cart.getNumberOfItems(store.cart);
}

export function cartIsLoading(store) {
	return Cart.isLoading(store.cart);
}

export function getManualActivation(store) {
	return Cart.getManualActivation(store.cart);
}

export function getBearerId(store) {
	return Cart.getBearerId(store.cart);
}

export function cartHasExpired(store) {
	return Cart.cartHasExpired(store.cart);
}

export const getNewCartItem = store => Cart.getNewCartItem(store.cart);

export const getShowInvalidOrderAddressMessage = store =>
	Cart.getShowInvalidOrderAddressMessage(store.cart);

export const getCardOrders = store => Cart.getCardOrders(store.cart);
export const getCartItemCount = store => Cart.getCartItemCount(store.cart);

export function isLoadingLegal(store) {
	return Legal.isLoading(store.legal);
}

export function hasLegalLoadingError(store) {
	return Legal.hasLoadingError(store.legal);
}

export function getLegal(store) {
	return Legal.getLegal(store.legal);
}

export function isLoadingConsent(store) {
	return Consent.isLoading(store.consent);
}

export function hasConsentLoadingError(store) {
	return Consent.hasLoadingError(store.consent);
}

export function hasConsentUpdateError(store) {
	return Consent.hasUpdateError(store.consent);
}

export function getConsent(store) {
	return Consent.getConsentData(store.consent);
}

export function hasEmptyProductResponse(store) {
	return Products.hasEmptyProductResponse(store.products);
}

export function hasProductError(store) {
	return Products.hasProductError(store.products);
}

export function getRegisteredPaymentMethodId(store) {
	return Wallet.getRegisteredPaymentMethodId(store.wallet);
}

export function isLoadingPaymentMethods(store) {
	return Wallet.isLoadingPaymentMethods(store.wallet);
}

export function getPurse(store) {
	return Wallet.getPurse(store.wallet);
}

export function hasPurseError(store) {
	return Wallet.hasPurseError(store.wallet);
}

export function hasFinalizeRegistrationError(store) {
	return Wallet.hasFinalizeRegistrationError(store.wallet);
}

export function isTransactionPurchased(store) {
	return Transaction.isTransactionPuchased(store.transaction);
}

export const isTransactionPendingFinalize = store =>
	Transaction.isTransactionPendingFinalize(store.transaction);

export function hasFinalizeTransactionError(store) {
	return Transaction.hasFinalizeError(store.transaction);
}

export function hasCancelTransactionError(store) {
	return Transaction.hasCancelTransactionError(store.transaction);
}

export function getTransactionErrorMessage(store) {
	return Transaction.getErrorMessage(store.transaction);
}

export function isLoadingCancelTransaction(store) {
	return Transaction.isLoadingCancelTransaction(store.transaction);
}

export const isLoadingQrCode = store =>
	Transaction.isLoadingQrCode(store.transaction);

export const getQrCodeSource = store =>
	Transaction.getQrCodeSource(store.transaction);

export const isLoadingTransactionHistory = store =>
	Transaction.isLoadingHistory(store.transaction);

export const getPurchaseHistory = store =>
	Transaction.getPurchaseHistory(store.transaction);

export const getTicketHistory = store =>
	Transaction.getTicketHistory(store.transaction);

export const getPurchaseReceipt = store =>
	Transaction.getPurchaseReceipt(store.transaction);

export function hasIdentityCreateError(store) {
	return Identity.hasCreateError(store.identity);
}

export function hasIdentityVerifyError(store) {
	return Identity.hasVerifyError(store.identity);
}

export function identityIsLoading(store) {
	return Identity.isLoading(store.identity);
}

export function getIdentity(store) {
	return Identity.getIdentity(store.identity);
}

export function getCustomer(store) {
	return Customer.getCustomer(store.customer);
}

export function customerIsLoading(store) {
	return Customer.isLoading(store.customer);
}

export function getDeregistrationCauses(store) {
	return Customer.getCauses(store.customer);
}

export function hasCustomerError(store) {
	return Customer.hasError(store.customer);
}

export function getBearers(store) {
	return Bearer.getBearers(store.bearer);
}

export function getParticipants(store) {
	return Bearer.getParticipants(store.bearer);
}

export function getTravelCards(store) {
	return Bearer.getTravelCards(store.bearer);
}

export function getMobileApps(store) {
	return Bearer.getMobileApps(store.bearer);
}

export function isLoadingMtbBearers(store) {
	return Bearer.isLoading(store.bearer);
}

export function isLoadingParticipants(store) {
	return Bearer.isLoadingParticipants(store.bearer);
}

export function hasGetBearerError(store) {
	return Bearer.hasGetBearerError(store.bearer);
}

export function hasDeleteBearerError(store) {
	return Bearer.hasDeleteBearerError(store.bearer);
}

export function hasPreferredBearer(store) {
	return Bearer.hasPreferredBearer(store.bearer);
}

export function hasOnlyOneBearer(store) {
	return Bearer.hasOnlyOneBearer(store.bearer);
}

export function travellerHasPreferredBearer(store) {
	return Bearer.hasPreferredBearer(store.traveller);
}

export function hasParticipantsError(store) {
	return Bearer.hasParticipantsError(store.bearer);
}

export function hasCreateBearerError(store) {
	return Bearer.hasCreateBearerError(store.bearer);
}

export function hasCreateBulkOrderError(store) {
	return Students.hasCreateBulkOrderError(store.students);
}

export function getCreatedOrders(store) {
	return Students.getCreatedOrders(store.students);
}

export function getJourneys(store) {
	return Journey.getJourneys(store.journey);
}

export function isLoadingJourneys(store) {
	return Journey.isLoadingJourneys(store.journey);
}

export function getFirstDepartureDate(store) {
	return Journey.getFirstDepartureDate(store.journey);
}

export function getLastDepartureDate(store) {
	return Journey.getLastDepartureDate(store.journey);
}

export const getRawXmlJourneyResult = (store, key) =>
	Journey.getRawXmlJourneyResult(store.journey, key);

export function getCmsTexts(store) {
	return Cms.getCmsTexts(store.cms);
}

export function hasCmsTexts(store) {
	return Cms.hasCmsTexts(store.cms);
}

export function isLoadingCmsTexts(store) {
	return Cms.isLoading(store.cms);
}

export function hasCmsLoadingError(store) {
	return Cms.hasError(store.cms);
}

export const isImportingStudents = store =>
	Students.isLoadingImport(store.students);

export const hasImportError = store => Students.hasImportError(store.students);

export const hasCreateStudentBearerError = store =>
	Students.hasCreateStudentBearerError(store.students);

export const getImportErrorText = store =>
	Students.getImportErrorText(store.students);

export function profileTileToNavigateTo(store) {
	return Utils.profileTileToNavigateTo(store.utils);
}
