import React from 'react';
import styled from 'styled-components';
import { CardWide } from '../blocks/CardWide';

export function PopUpBox(props) {
	return (
		<PopupContainer {...props}>
			<PopUpCard className="Card">{props.children}</PopUpCard>
		</PopupContainer>
	);
}

export const PopupContainer = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1em;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10000;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`;

const PopUpCard = styled(CardWide)`
	align-items: center;
	max-height: 100%;
	overflow: auto;
	text-align: initial;
	padding: 3em;

	@media screen and (max-width: 375px) {
		padding: 1em;
	}
`;
