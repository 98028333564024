import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import {forPhoneOnly} from "./../../../utils/mediaqueries"

export class TextInput extends Component {
	static propTypes = {
		value: PropTypes.any.isRequired,
		type: PropTypes.string.isRequired,
		validateOnChange: PropTypes.bool,
		handleChange: PropTypes.func.isRequired,
		errorMessage: PropTypes.string, //optional error message that will show under the text field
		isError: PropTypes.bool, //optional, passed down from parent after doing form validation logic
		isFullWidth: PropTypes.bool,
		offset: PropTypes.string, //optional, in case we want to offset the width of the InputContainer
		isRenderdAsAutosuggest: PropTypes.bool, //only true on Köp page where TextInput is used inside the Autosuggest component
		refCallback: PropTypes.func,
		helperText: PropTypes.string,
		hasFloatingLabel: PropTypes.bool,
	};

	static defaultProps = {
		value: '',
		type: 'text',
		className: '',
		id: '',
		maxLength: '',
		validateOnChange: false,
		pattern: undefined,
		errorMessage: "",
		isError: false,
		isFullWidth: false,
		offset: "0px",
		isRenderdAsAutosuggest: false,
		refCallback: null,
		helperText: null,
		hasFloatingLabel: true
	};

	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
			type: props.type,
			isError: false
		};
	}

	handleChange = (event) => {
		const {target} = event
		const { validateOnChange } = this.props;

		if (validateOnChange && !target.checkValidity()) return;
		this.props.handleChange(this.props.isRenderdAsAutosuggest ? event : target);
		this.setState({ value: target.value });
	};

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({ value: this.props.value });
		}
	}

	componentWillReceiveProps(nextProps) {
		//Triggers error message when isError is passed from parent with validation logic
		if (nextProps.isError) {
			this.setState(current => {
				return {
					...current,
					isError: nextProps.isError
				};
			});
		}
	}

	render() {
		const { value } = this.state;
		const shouldShowErrorMessage = this.state.isError === true && Boolean(this.props.errorMessage)
		//Triggers error message if the fext field is left empty onBlur (focus out)
		const checkError = () => {
			this.setState(current => {
				return {
					...current,
					isError: this.props.isError || value?.length === 0,
				};
			});
		};

		return (
			<InputContainer isFullWidth={this.props.isFullWidth} offset={this.props.offset} hasFloatingLabel={this.props.hasFloatingLabel}>
				<TextInputField
					{...this.props}
					placeholder={this.props.hasFloatingLabel ? null : this.props.placeholder} //the TextInputFieldLabel component acts as both a label and placeholder if the hasFloatingLabel is true
					value={value}
					onChange={this.handleChange}
					onBlur={checkError}
					aria-describedby={shouldShowErrorMessage ? `errorMessageFor_${this.props.id}` : null}
					ref={this.props.refCallback || null}
				/>
				{this.props.hasFloatingLabel && 
					<TextInputFieldLabel filled={Boolean(value?.length)} htmlFor={this.props.id}>
						{this.props.placeholder}{shouldShowErrorMessage && <span role="alert" className="sr-only">{this.props.errorMessage}</span>}
					</TextInputFieldLabel>
				}
				{shouldShowErrorMessage && (<ErrorMessage visible={this.state.isError === true} id={`errorMessageFor_${this.props.id}`} role={shouldShowErrorMessage ? "alertdialog" : null}>{this.props.errorMessage}</ErrorMessage>)}
				{!shouldShowErrorMessage && this.props.helperText && <HelperText>{this.props.helperText}</HelperText>}
			</InputContainer>
		);
	}
}

const InputContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: ${props => props.hasFloatingLabel ? "3rem" : "1.3rem"};
	${props => props.isFullWidth && `width: calc(100% - ${props.offset});`}

	&:focus-within label {
		transform: translate(0, 12px) scale(0.8) translateY(0%);
		color: #66afe9;
	}
`;

const TextInputField = styled.input`
	font-family: ${props => props.theme.text};
	font-size: 16px;
	line-height: 14px;
	border: 1px solid #000;
	border-radius: 4px;
	height: auto;
	padding: 8px 12px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	margin: 0 !important;

	:focus {
		border: 2px solid #000
		border-radius: 4px;
		outline: 0;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 8px rgba(102, 175, 233, 0.6);
	}
`;

const TextInputFieldLabel = styled.label`
	position: absolute;
	pointer-events: none;
	transform: ${props =>
		props.filled
			? 'translate(0, 12px) scale(0.8) translateY(0%)'
			: 'translate(0, 23px) scale(1) translateY(50%)'};
	transform-origin: top left;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	padding: 0 4px;
	background: white;
	color: #000;
	font-size: inherit;
	line-height: 1;
	left: 10px;
	top: -45%;
	font-weight: inherit;
`;

const slideInAnimation = keyframes`
	0% {
		visibility: hidden;
		transform: translateY(-10px);
		opacity: 0;
	}
  	100% {
		visibility: visible;
		transform: translateY(0);
		opacity: 1;
	}
`;

const ErrorMessage = styled.span`
	font-size: 1.05rem;
	color: #dc2626;
	opacity: 0;
	animation: ${props => props.visible && css`${slideInAnimation} 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`};
	transition: opacity 350ms ease;
	position: absolute;
    bottom: -45%;
	max-height: 1.5rem;
	text-align: start;	

	${forPhoneOnly`
		font-size: 0.95rem;
	`}
`
const HelperText = styled(ErrorMessage)`
	color: inherit;
	opacity: 1;
`