import React, { Component } from 'react';
import styled from 'styled-components';
import { CloseCardButton } from './CloseCardButton';

export class ServiceWorkerNotification extends Component {
	state = { show: false };

	componentDidMount() {
		window.addEventListener('kollo.newContentAvailable', () => {
			this.setState({ show: true });
		});
	}

	removeNotification = () => this.setState({ show: false });

	render() {
		if (!this.state.show) return null;

		return (
			<Notification>
				En ny version av denna sida finns tillgänglig. Stäng webbläsarfönstret
				och öppna upp sidan på nytt för att ta del av innehållet.
				<CloseCardButton onClick={this.removeNotification} />
			</Notification>
		);
	}
}

const Notification = styled.div`
	position: fixed;
	padding: 1em 3em 1em 1em;
	width: 100%;
	background-color: black;
	color: white;
	text-align: center;
	z-index: 10000;
	box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.22);
`;
