import { getLatestLegal } from '../api';

export const LEGAL_ERRORS = {
	GET_LATEST_LEGAL: 'Error while getting latest terms',
}

const initialState = {
	data: null,
	isLoading: false,
	errorMessage: '',
};

export const actionCreators = {
	getLatestLegal: () => async (dispatch, getState) => {
		const state = getState();

		if (state.legal.isLoading) {
			dispatch({ type: 'ABORT_GET_LEGAL_REQUEST' });
			return;
		}

		dispatch({ type: 'LATEST_LEGAL_REQUEST' });

		try {
			const legal = await getLatestLegal();
			dispatch({ type: 'LATEST_LEGAL_SUCCESS', legal });
		} catch (error) {
			dispatch({ type: 'LATEST_LEGAL_FAILURE', message: LEGAL_ERRORS.GET_LATEST_LEGAL });
		}
	},
};

export default function reducer (state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'LATEST_LEGAL_REQUEST':
			return {
				...state,
				isLoading: true,
			};
		case 'LATEST_LEGAL_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.legal,
			};
		case 'LATEST_LEGAL_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		default:
			return state;
	}
};

export function isLoading(state) {	
	return state.isLoading;
}

export function hasLoadingError(state) {
	return state.errorMessage === LEGAL_ERRORS.GET_LATEST_LEGAL;
}

export function getLegal(state){
	return state.data;
}
