import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { isProductLoanedOut, isProductLoanedToUser } from '../../../reducer';
import { isMultipleActivationTicket, productHasTags } from '../../../reducer/products';

export const TicketStatus = props => {
	const { product, travellerId } = props;

	const isSchoolTicket = productHasTags(product, ['pc_school', 'school'], (tagName) => tagName.toLowerCase().trim());

	if (isMultipleActivationTicket(product)) {
		if (isProductLoanedOut(product, travellerId)) {
			let daysLeft = Math.floor(
				(Date.parse(product.endOfActivationPeriod) - Date.now()) /
					(1000 * 3600 * 24)
			);
			return (
				<>
					<p>
						<TicketStatusInactive>Utlånad</TicketStatusInactive> - Åter:{' '}
						{formatExpireDateTime(product.loanExpire)}
					</p>
					<p>
						Resdag {product.numberOfActivations - product.numActivationsLeft} är
						giltig till {formatExpireDateTime(product.expire)}
					</p>
					<p>Aktiverades {formatExpireDateTime(product.activation)}</p>
					<p>
						Antal aktiverade {isSchoolTicket ? 'resor' : 'resdagar'}:{' '}
						<span>
							{product.numberOfActivations - product.numActivationsLeft} av{' '}
							{product.numberOfActivations}
						</span>
					</p>
					<p>
						{product.numActivationsLeft} {isSchoolTicket ? (product.numActivationsLeft === 1 ? 'resa' : 'resor') : 'resdagar'} kvar att nyttja inom{' '}
						{daysLeft} dagar
					</p>
					{product.startOfActivationPeriod && (
						<p>
							Aktiverades första gången{' '}
							{formatExpireDateTime(product.startOfActivationPeriod)}
						</p>
					)}
				</>
			);
		} else if (isProductLoanedToUser(product, travellerId)) {
			let daysLeft = Math.floor(
				(Date.parse(product.endOfActivationPeriod) - Date.now()) /
					(1000 * 3600 * 24)
			);
			return (
				<>
					<p>
						<TicketStatusActive>Lånad</TicketStatusActive> - t.o.m.{' '}
						{formatExpireDateTime(product.loanExpire)}
					</p>
					<p>
						Resdag {product.numberOfActivations - product.numActivationsLeft} är
						giltig till {formatExpireDateTime(product.expire)}
					</p>
					<p>Aktiverades {formatExpireDateTime(product.activation)}</p>
					<p>
						Antal aktiverade {isSchoolTicket ? 'resor' : 'resdagar'}:{' '}
						<span>
							{product.numberOfActivations - product.numActivationsLeft} av{' '}
							{product.numberOfActivations}
						</span>
					</p>
					<p>
						{product.numActivationsLeft} {isSchoolTicket ? (product.numActivationsLeft === 1 ? 'resa' : 'resor') : 'resdagar'} kvar att nyttja inom{' '}
						{daysLeft} dagar
					</p>
					{product.startOfActivationPeriod && (
						<p>
							Aktiverades första gången{' '}
							{formatExpireDateTime(product.startOfActivationPeriod)}
						</p>
					)}
				</>
			);
		} else if (!product.active) {
			let daysLeft = Math.floor(
				(Date.parse(product.expire) - Date.now()) / (1000 * 3600 * 24)
			);
			return product.expire ? (
				<>
					<p>
						<TicketStatusInactive>
							{isSchoolTicket ? 'Skolbiljett giltig till' : 'Aktiveras senast'}
						</TicketStatusInactive>{' '}
						{formatExpireDateTime(product.expire)}
					</p>
					<p>
						Antal aktiverade {isSchoolTicket ? 'resor' : 'resdagar'}:{' '}
						<span>
							{product.numberOfActivations - product.numActivationsLeft} av{' '}
							{product.numberOfActivations}
						</span>
					</p>
					<p>
						{product.numActivationsLeft} {isSchoolTicket ? (product.numActivationsLeft === 1 ? 'resa' : 'resor') : 'resdagar'} kvar att nyttja inom{' '}
						{daysLeft} dagar
					</p>
					{product.startOfActivationPeriod && (
						<p>
							Aktiverades första gången{' '}
							{formatExpireDateTime(product.startOfActivationPeriod)}
						</p>
					)}
				</>
			) : (
				<p />
			); // product doesn't have latest activation date (<p> for margin)
		}

		let daysLeft = Math.floor(
			(Date.parse(product.endOfActivationPeriod) - Date.now()) /
				(1000 * 3600 * 24)
		);
		return (
			<>
				<p>
					<TicketStatusActive>
						Resdag {product.numberOfActivations - product.numActivationsLeft}
					</TicketStatusActive>{' '}
					är giltig till {formatExpireDateTime(product.expire)}
				</p>
				<p>Aktiverades {formatExpireDateTime(product.activation)}</p>
				<p>
					Antal aktiverade {isSchoolTicket ? 'resor' : 'resdagar'}:{' '}
					<span>
						{product.numberOfActivations - product.numActivationsLeft} av{' '}
						{product.numberOfActivations}
					</span>
				</p>
				<p>
					{product.numActivationsLeft} {isSchoolTicket ? (product.numActivationsLeft === 1 ? 'resa' : 'resor') : 'resdagar'} kvar att nyttja inom {daysLeft}{' '}
					dagar
				</p>
				{product.startOfActivationPeriod && (
					<p>
						Aktiverades första gången{' '}
						{formatExpireDateTime(product.startOfActivationPeriod)}
					</p>
				)}
			</>
		);
	}

	if (isProductLoanedOut(product, travellerId))
		return (
			<p>
				<TicketStatusInactive>Utlånad</TicketStatusInactive> - Åter:{' '}
				{formatExpireDateTime(product.loanExpire)}
			</p>
		);

	if (isProductLoanedToUser(product, travellerId))
		return (
			<p>
				<TicketStatusActive>Lånad</TicketStatusActive> - t.o.m.{' '}
				{formatExpireDateTime(product.loanExpire)}
			</p>
		);

	if (!product.active) {
		return product.expire ? (
			<p>
				<TicketStatusInactive>
					{isSchoolTicket ? 'Skolbiljett giltig till' : 'Aktiveras senast'}
				</TicketStatusInactive>{' '}
				{formatExpireDateTime(product.expire)}
			</p>
		) : (
			<p />
		); // product doesn't have latest activation date (<p> for margin)
	}

	return (
		<p>
			<TicketStatusActive>Giltig till</TicketStatusActive>{' '}
			{formatExpireDateTime(product.expire)}
		</p>
	);
};

const formatExpireDateTime = date => format(new Date(date), 'YYYY-MM-DD HH:mm');

const StatusSpan = styled.span`
	font-weight: bold;
`;

const TicketStatusActive = styled(StatusSpan)`
	color: #417505;
`;

const TicketStatusInactive = styled(StatusSpan)`
	color: #a46400;
`;
