import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { Main } from '../common/blocks/Main';
import { GroupTable } from './GroupPage';
import {
	getTicketOrders,
	isLoadingTicketOrders,
	hasProcessingTicketOrders,
	actionCreators as orderActions,
} from '../../reducer/school/orders';
import { formatDate } from '../../utils/formatting';
import { ReactComponent as TicketImg } from '../../images/ticket.svg';
import { Loading } from '../common/blocks/Loading';
import { SCHOOL } from '../../Paths';
import OrderHistoryNavigation from './blocks/OrderHistoryNavigation';
import { Pagination } from '../common/elements/Pagination';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class OrderTicketHistory extends Component {
	state = {
		currentPage: 0,
		pageSize: 30,
		waitingForReload: false,
	};

	componentDidMount() {
		this.props.getTicketOrders();
		this.maybeReloadTicketOrders(this.state.waitingForReload);
	}

	componentDidUpdate(prevProps, prevState) {
		this.maybeReloadTicketOrders(prevState.waitingForReload);
	}

	maybeReloadTicketOrders = (prevWaitingForReload) => {
		if (this.props.hasProcessingTicketOrders && !this.state.waitingForReload) {
			this.setState({ waitingForReload: true });

			setTimeout(() => this.props.getTicketOrders(), '30000');
		} else if (prevWaitingForReload) {
			this.setState({ waitingForReload: false });
		}
	}


	showOrderDetails = ticketOrderId => e => {
		this.props.push(
			SCHOOL.ORDER_HISTORY_TICKETS_DETAILS.replace(
				':ticketOrderId',
				ticketOrderId
			)
		);
	};

	handlePageChange = newPage => this.setState({ currentPage: newPage - 1 });

	render() {
		const { orders, isLoadingTicketOrders, texts } = this.props;
		const { currentPage, pageSize } = this.state;

		const sortedOrders = orders.sort((a, b) =>
			b.createdOn.localeCompare(a.createdOn)
		);

		let displayOrders = sortedOrders.slice(
			pageSize * currentPage,
			pageSize * currentPage + pageSize
		);

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderhistory}
					showOnMobile={true}
				>
					<h2>Orderhistorik</h2>
					<p>Här visas ordrar på både resekort och biljetter</p>
				</Banner>
				<OrderHistoryNavigation />
				{isLoadingTicketOrders && <Loading text="Laddar orderhistorik..." />}
				<Main>
					<Table>
						<thead>
							<tr>
								<th />
								<th>Skapad datum</th>
								<th>Grupp</th>
								<th>Antal biljetter</th>
								<th>Skapad av</th>
								<th>Ordernummer</th>
								<th>Summa</th>
								<th>Status</th>
							</tr>
						</thead>

						<tbody>
							{displayOrders.map(order => (
								<tr
									key={order.ticketOrderId}
									onClick={this.showOrderDetails(order.ticketOrderId)}
								>
									<td>
										<TicketIcon />
									</td>
									<td className="nowrap">{formatDate(order.createdOn)}</td>
									<td>{order.groupName}</td>
									<td>{order.numberOfTickets}</td>
									<td>{order.createdByName}</td>
									<td className="nowrap">{order.serialNumber}</td>
									<td>{order.totalAmount} kr</td>
									<td>{order.status}</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Pagination
						itemCount={orders.length}
						itemsPerPage={pageSize}
						onPageChange={this.handlePageChange}
					/>
				</Main>
			</>
		);
	}
}

const Table = styled(GroupTable)`
	th:first-of-type {
		min-width: 32px;
	}

	th:last-of-type {
		&:hover {
			cursor: pointer;
		}
	}

	th:last-of-type,
	td:last-of-type {
		text-align: center;
		label {
			float: none;
			margin: 0;
		}
	}
	.nowrap {
		white-space: nowrap;
	}
`;

const TicketIcon = styled(TicketImg)`
	width: 24px;
	height: 24px;
`;

export default withTextContext(
	connect(
		store => ({
			orders: getTicketOrders(store),
			hasProcessingTicketOrders: hasProcessingTicketOrders(store),
			isLoadingOrders: isLoadingTicketOrders(store),
		}),
		{
			...orderActions,
			push,
		}
	)(OrderTicketHistory)
);
