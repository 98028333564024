import React, { Component } from 'react';
import styled from 'styled-components';
import { Card } from '../../common/blocks/Card';
import { connect } from 'react-redux';
import withTextContext from '../../../utils/withTextContext';
import { StepVisualizer } from '../../common/blocks/StepVisualizer';
import { forSmallPhoneOnly } from '../../../utils/mediaqueries';
import { LinkButton } from '../../common/elements/LinkButton';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import {
	formatTime,
	formatInternationalPhoneNumber,
} from '../../../utils/formatting';
import {
	actionCreators as cultureTripActions,
	isCreatingCultureTripApplication,
	travelerInfo,
	departureJourney,
	returnJourney,
	contactInfo,
} from '../../../reducer/school/culturetrips';
import { Loading } from '../../common/blocks/Loading';
import { SCHOOL } from '../../../Paths';
import { push } from 'connected-react-router';
import CultureTripWrapper from './CultureTripWrapper';

// Step 5 in form
class Summary extends Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef();
	}

	componentDidMount = () => {
		if (!this.props.contactInfo)
			this.props.push(SCHOOL.CULTURE_TRIP_CONTACT_INFO);
		else
			this.containerRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
	};

	sendApplication = async () => {
		const {
			travelerInfo,
			departureJourney,
			returnJourney,
			contactInfo,
		} = this.props;
		try {
			const cultureTripApplication = {
				...travelerInfo,
				visitingDestination: travelerInfo.destination.id,
				groupId: travelerInfo.group.groupId,
				departure: departureJourney,
				return: returnJourney,
				contact: contactInfo,
			};

			await this.props.sendCultureTripApplication(cultureTripApplication);

			this.props.push(SCHOOL.CULTURE_TRIP);
		} catch (error) {
			this.setState({ errorText: error });
		}
	};

	back = e => {
		e.preventDefault();
		this.props.push(SCHOOL.CULTURE_TRIP_CONTACT_INFO);
	};

	render() {
		const {
			travelerInfo,
			departureJourney,
			returnJourney,
			contactInfo,
			isCreatingCultureTripApplication,
		} = this.props;
		// The markup for the Step 5 UI

		if (!travelerInfo || !departureJourney || !returnJourney || !contactInfo)
			return <Loading />;

		return (
			<CultureTripWrapper>
				<Container ref={this.containerRef}>
					<CardBox>
						<Header>Ansökan om kulturresor</Header>
						<SubHeader>Kontrollera uppgifter</SubHeader>
						<FormContainer>
							<div style={{ marginBottom: '3em' }}>
								<p>
									Besöksmål: <b>{travelerInfo.destination.name}</b>
								</p>
								<p>
									Klass / avdelning: <b>{travelerInfo.group.name}</b>
								</p>
								{travelerInfo.adultTravellerCount > 0 && (
									<p>
										Vuxna (fr.o.m. 20 år):{' '}
										<b>{travelerInfo.adultTravellerCount} st</b>
									</p>
								)}
								{travelerInfo.youthTravellerCount > 0 && (
									<p>
										Ungdom (7-19 år):{' '}
										<b>{travelerInfo.youthTravellerCount} st</b>
									</p>
								)}
								{travelerInfo.childTravellerCount > 0 && (
									<p>
										Barn (under 7 år):{' '}
										<b>{travelerInfo.childTravellerCount} st</b>
									</p>
								)}
								<p>
									Framresa:{' '}
									<b>
										{departureJourney.from} - {departureJourney.to}{' '}
										{formatTime(departureJourney.departure)}
									</b>
								</p>
								<p>
									Återresa:{' '}
									<b>
										{returnJourney.from} - {returnJourney.to}{' '}
										{formatTime(returnJourney.departure)}
									</b>
								</p>
							</div>
							<div style={{ marginBottom: '3em' }}>
								<p>Kontaktperson</p>
								<p>
									Namn: <b>{contactInfo.name}</b>
								</p>
								<p>
									Telefonnummer:{' '}
									<b>
										{formatInternationalPhoneNumber(contactInfo.phoneNumber)}
									</b>
								</p>
								<p>
									E-post: <b>{contactInfo.email}</b>
								</p>
							</div>

							<div>
								<p>
									Biljettleverans: <b>{contactInfo.modeOfDelivery.label}</b>
								</p>
							</div>
						</FormContainer>
						{isCreatingCultureTripApplication && <Loading />}
						<Button
							onClick={this.sendApplication}
							disabled={isCreatingCultureTripApplication}
						>
							Skicka förfrågan
						</Button>
						<LinkButton
							onClick={this.back}
							disabled={isCreatingCultureTripApplication}
						>
							&lsaquo; Tillbaka
						</LinkButton>
						<StepVisualizer activeStep={5} steps={5} />
					</CardBox>
				</Container>
			</CultureTripWrapper>
		);
	}
}

const Header = styled.h3`
	margin-top: 0;
	margin-bottom: 1em;
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
`;

const SubHeader = styled.h4`
	max-width: 295px;
	text-align: center;
	margin: auto;
`;

const CardBox = styled(Card)`
	max-width: 570px;
	width: 100%;
	padding: 3em;

	${forSmallPhoneOnly`
		box-shadow: none;
		border: none;
		background: none;
		padding: 0;
	`}
`;

const FormContainer = styled.div`
	margin: 60px 30px 0 30px;
	text-align: left;
`;

const Button = styled(SecondaryButton)`
	width: 100%;
	max-width: 300px;
	margin: 1em auto;
`;

export default withTextContext(
	connect(
		store => ({
			isCreatingCultureTripApplication: isCreatingCultureTripApplication(store),
			travelerInfo: travelerInfo(store),
			departureJourney: departureJourney(store),
			returnJourney: returnJourney(store),
			contactInfo: contactInfo(store),
		}),
		{ ...cultureTripActions, push }
	)(Summary),
	false
);
