import React from 'react';
import styled from 'styled-components';

/**
 * @abstract reusable component that renders a navigation link that's only visible for users who navigate using a keyboard
 * @param currentPath: the current URL of the page 
 * @returns SkipToMainContent component if the route is defined in applicationPaths, otherwise null for dynamic routes with payment information etc. 
 */

export const SkipToMainContent = ({ currentPath }) => {
	const applicationPaths = {
		'/': 'customerLoginPageSignupBox',
		'/register': 'customerRegisterAccountHeader',

		//Ticket routes
		'/tickets': 'ticketsPageMain',
		'/tickets/travelcard': 'ticketsTravelCardContainer',
		'/tickets/mobile': 'mobileAppPageMainContainer',

		//Purchase routes
		'/purchase': 'buyTicketContainer',
		'/purchase/offer': 'buyTicketContainerOffer',
		'/purchase/options': 'ticketBoxContainer',
		'/purchase/checkout': 'checkoutPageMainContainer',
		'/purchase/purse': "customerPurchaseFundsPageTicketBox",

		//Profile routes
		'/profile': 'profilePageMainContainer',

		getNavLinkByCurrentPath(path) {
			if (!path || !this[path]) return null;
			return `${currentPath}#${this[path]}`;
		},
	};

	const navigateTo = applicationPaths.getNavLinkByCurrentPath(currentPath);

	//So that navigation works with both the Enter key (by default) and space bar
	const handleSpacebarPress = (e) => {
		if (e.key === " ") {
			window.location = navigateTo
		}
	}


	return navigateTo ? (
		<StyledLink href={navigateTo} tabIndex="0" onKeyDown={handleSpacebarPress}>
			Hoppa till huvudinnehållet
		</StyledLink>
	) : null;
};

const StyledLink = styled.a`
	position: absolute;
	top: 4px;
	left: 4px;
	transform: translateX(-200%) scale(0.7);
	padding: 1.3rem 2.8rem;
	border-radius: 7px;
	z-index: 1000;
	background: white;
	box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
	font-size: 1.5rem;
	font-weight: 400;
	color: black;
	transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	outline: 1px solid #3b82f6;
	outline-offset: 1px !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus {
		transform: translateX(0) scale(1);
		color: inherit;
		outline: 1px solid #3b82f6;
		outline-offset: 1px !important;
	}
	&:hover {
		color: inherit;
		box-shadow: 0 25px 50px -12px rgba(59, 130, 246, 0.25);
		transform: translateX(0) scale(1.02);
		outline: 1px solid #3b82f6;
		outline-offset: 1px !important;
	}
`;
