import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PRIVATE } from '../../../Paths';
import classnames from 'classnames';
import {
	actionCreators,
	canReplenish,
	getPurchasablePaymentMethods,
} from '../../../reducer/wallet';
import { getPathName, isLoadingPaymentMethods } from '../../../reducer';
import { Loading } from '../../common/blocks/Loading';
import {
	forTabletPortraitUp,
	forLargePhoneUp,
	forSmallPhoneOnly,
	forZoomedInUsers
} from '../../../utils/mediaqueries';
import { isEmpty } from 'lodash';

const isOfferPage = path => path === PRIVATE.PURCHASE_OFFER;
const isBuyTicketPage = path => path === PRIVATE.BUY_TICKET;
const isPurseFundsPage = path => path === PRIVATE.PURCHASE_PURSE_FUNDS;

export class PurchaseNavigation extends Component {
	componentDidMount() {
		const { paymentMethods } = this.props;

		if (isEmpty(paymentMethods)) {
			this.props.getPaymentMethods();
		}
	}

	render() {
		const { path, paymentMethods, isLoading, canReplenish } = this.props;

		if (!paymentMethods || isLoading) return <Loading />;

		return (
			<Container>
				<ButtonRow>
					<NavButton
						className={classnames({ active: isOfferPage(path) })}
						to={PRIVATE.PURCHASE_OFFER}
						exact
					>
						Köp
					</NavButton>
					{canReplenish && (
						<NavButton
							className={classnames({ active: isPurseFundsPage(path) })}
							to={PRIVATE.PURCHASE_PURSE_FUNDS}
							exact
						>
							Ladda
						</NavButton>
					)}
					<NavButton
						className={classnames({ active: isBuyTicketPage(path) })}
						to={PRIVATE.BUY_TICKET}
						exact
					>
						Sök
					</NavButton>
				</ButtonRow>
			</Container>
		);
	}
}

const rowHeight = 70;
const em = 14;

const Container = styled.div`
	position: relative;
	margin-top: -1em; /* correct for padding */
	margin-left: auto;
	margin-right: auto;
	max-width: 570px;
	width: 100%;
	height: ${rowHeight - 1 * em}px;

	${forSmallPhoneOnly`
		height: auto;
	`}

	${forLargePhoneUp`
		height: ${rowHeight - 1 * em}px;
	`}

	${forTabletPortraitUp`
		height: ${rowHeight}px;
	`}
`;

const ButtonRow = styled.div`
	position: absolute;
	padding-left: 1em;
	padding-top: 1em; /* pad for shadow */
	z-index: 100;
	overflow: hidden;
	width: 100%;
	height: 100%;

	${forSmallPhoneOnly`
		position: static;
		overflow: unset;
	`}

	${forZoomedInUsers`
		padding-bottom: 2.5rem;
		padding-left: 0;
    	display: flex;
    	flex-direction: column;
	`}
`;

const NavButton = styled(NavLink)`
	display: inline-block;
	background-color: white;
	color: inherit;
	font-size: 18px;
	font-weight: 600;
	padding: 0.5em 1em;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	border-bottom: solid 2px rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.22);
	height: ${rowHeight - 2 * em}px;

	${forLargePhoneUp`
		font-size: 20px;
		height: ${rowHeight - 2 * em}px;
	`}

	${forTabletPortraitUp`
		font-size: 24px;
		height: ${rowHeight - 1 * em}px;
	`}

	& + & {
		margin-left: 0.5em;
	}

	&:hover {
		text-decoration: none;
		color: inherit;
	}

	&.active {
		background-color: ${props => props.theme.primary_color};
		color: ${props => props.theme.textOnPrimaryColor};
		border-bottom-color: ${props => props.theme.primary_color};
	}

	${forZoomedInUsers`
		margin-left: 0 !important;
    	text-align: center;
	`}
`;

export default connect(
	store => ({
		path: getPathName(store),
		paymentMethods: getPurchasablePaymentMethods(store),
		canReplenish: canReplenish(store),
		isLoading: isLoadingPaymentMethods(store),
	}),
	{ ...actionCreators }
)(PurchaseNavigation);
