import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../utils/formatting';
import { sanitizeCmsHtml } from '../../../texts';
import withTextContext from '../../../utils/withTextContext';
import { connect } from 'react-redux';
import {
	actionCreators as schoolActions,
	getTicketOrderProductById,
} from '../../../reducer/school';
import { actionCreators as groupActions } from '../../../reducer/groups';
import { hasTicketOrderProducts } from '../../../reducer';
import { unique } from '../../../utils/helperFunctions';

class TicketOrderRowSummary extends React.Component {
	state = {
		groupedTicketOrders: null,
		totalAmount: 0,
		totalSum: 0,
		totalVat: 0,
		totalSumInclVat: 0,
	};

	componentDidMount() {
		this.loadData();
	}

	loadData = async () => {
		const { hasTicketOrderProducts, ticketOrderRows } = this.props;
		if (!hasTicketOrderProducts) await this.props.getTicketOrderProducts();
		if (ticketOrderRows) this.setOrderSummaryData();
	};

	setOrderSummaryData = async () => {
		const { ticketOrderRows } = this.props;

		const productIds = unique(ticketOrderRows.map(t => t.productId));

		const groupedTicketOrders = productIds.reduce((rows, t) => {
			var productSetTicketOrders = ticketOrderRows.filter(
				orderRow => orderRow.productId === t
			);

			let ticketOrderProduct = this.props.ticketOrderProduct(t);
			if (!ticketOrderProduct) return rows;

			var row = {
				productId: t,
				name: ticketOrderProduct.name,
				price: ticketOrderProduct.pricePerYear,
				amount: productSetTicketOrders.length,
				sum: ticketOrderProduct.pricePerYear * productSetTicketOrders.length,
				sumInclVat:
					ticketOrderProduct.pricePerYearIncludingVat *
					productSetTicketOrders.length,
				vat: ticketOrderProduct.vat.vat,
				vatSum: ticketOrderProduct.vat.vatSum * productSetTicketOrders.length,
			};
			return [...rows, row];
		}, []);

		const totalAmount = groupedTicketOrders.reduce((total, t) => {
			return (total += t.amount);
		}, 0);

		const totalSum = groupedTicketOrders.reduce((total, t) => {
			return (total += t.sum);
		}, 0);

		const totalSumInclVat = groupedTicketOrders.reduce((total, t) => {
			return (total += t.sumInclVat);
		}, 0);

		const totalVatSum = groupedTicketOrders.reduce((total, t) => {
			return (total += t.vatSum);
		}, 0);

		const orderVat = groupedTicketOrders[0].vat;

		this.setState({
			groupedTicketOrders,
			totalAmount,
			totalSum,
			totalVatSum,
			orderVat,
			totalSumInclVat,
		});
	};

	render() {
		const { texts } = this.props;

		const {
			groupedTicketOrders,
			totalAmount,
			totalSum,
			totalVatSum,
			orderVat,
			totalSumInclVat,
		} = this.state;

		if (!groupedTicketOrders) return null;

		return (
			<>
				<OrderRowTable>
					<thead>
						<tr>
							<th>Biljett</th>
							<th>Pris</th>
							<th className="column-right-align">Antal</th>
							<th className="column-right-align">Summa</th>
						</tr>
					</thead>
					<tbody>
						{groupedTicketOrders.map(t => (
							<tr key={t.productId}>
								<td>{t.name}</td>
								<td>{formatCurrency(t.price)}</td>
								<td className="column-right-align">{t.amount} st</td>
								<td className="column-right-align">{formatCurrency(t.sum)}</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td>Totalt</td>
							<td />
							<td className="column-right-align">{totalAmount} st</td>
							<td className="column-right-align">{formatCurrency(totalSum)}</td>
						</tr>
					</tfoot>
				</OrderRowTable>
				<OrderTotalContainer>
					<PriceInfo
						dangerouslySetInnerHTML={sanitizeCmsHtml(
							texts.school.orderticketpage.confirmorder.informationtext
						)}
					/>
					<OrderTotalInfoTable>
						<tbody>
							<tr>
								<td>Total summa exkl. moms</td>
								<td>{formatCurrency(totalSum)}</td>
							</tr>
							<tr>
								<td>Moms ({orderVat}%)</td>
								<td>{formatCurrency(totalVatSum)}</td>
							</tr>
							<tr>
								<td>Total summa inkl. moms</td>
								<td>{formatCurrency(totalSumInclVat)}</td>
							</tr>
						</tbody>
					</OrderTotalInfoTable>
				</OrderTotalContainer>
			</>
		);
	}
}

const OrderRowTable = styled.table`
	width: 100%;

	tbody td {
		color: #666;
	}

	.column-right-align {
		text-align: right;
	}

	thead tr:last-of-type th,
	tbody tr:last-of-type td {
		padding-bottom: 20px;
	}
	tfoot {
		border-top: 1px solid #ebebeb;
		tr td {
			padding-top: 20px;
		}
	}
`;

const OrderTotalContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 40px;

	& > * {
		width: 50%;
	}
`;

const PriceInfo = styled.p`
	width: 40%;
`;

const OrderTotalInfoTable = styled.table`
	margin-left: auto;

	td:first-of-type {
		text-align: left;
	}
	td:last-of-type {
		text-align: right;
	}
`;

export default withTextContext(
	connect(
		store => ({
			ticketOrderProduct: getTicketOrderProductById(store),
			hasTicketOrderProducts: hasTicketOrderProducts(store),
		}),
		{
			...schoolActions,
			...groupActions,
		}
	)(TicketOrderRowSummary)
);
