import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { Main } from '../common/blocks/Main';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { getLocationState } from '../../reducer';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import { Banner } from '../common/blocks/Banner';
import withTextContext from '../../utils/withTextContext';

class ConfirmationPage extends Component {
	
	componentDidMount() {
		document.title = "Bekräftelse | Mitt konto"
	}

	continue = () => {
		this.props.push(this.props.state.nextRoute);
	};

	render() {
		const { state = {}, texts } = this.props;
		const { title, text } = state;

		const content = text && <p>{text}</p>;

		return (
			<>
				<Banner bannerImg={texts.private.images.banners.ticketpage}>
					<h2>{title}</h2>
				</Banner>
				<ConfirmationPageMain>
					<FlexWrapper>
						<Header>
							<h2>{title}</h2>
						</Header>

						<ConfirmationCard onClick={this.continue} text={content} />
					</FlexWrapper>
				</ConfirmationPageMain>
			</>
		);
	}
}

const Header = styled.div`
	margin-bottom: 1em;
	text-align: center;
	display: block;

	${forTabletPortraitUp`
		display: none;
	`}
`;

const ConfirmationPageMain = styled(Main)`
	padding: 1em 1em 3em;

	${forTabletPortraitUp`
		padding: 3em 1em;
	`}
`;

export default withTextContext(
	connect(
		store => ({
			state: getLocationState(store),
		}),
		{ push }
	)(ConfirmationPage),
	false
);
