import React from 'react';
import { connect } from 'react-redux';
import { DeliveryAddressForm } from './DeliveryAddressForm';
import { Loading } from '../../common/blocks/Loading';
import { actionCreators as customerActions } from '../../../reducer/customer';
import { actionCreators as travellerActions } from '../../../reducer/traveller';
import {
	getCustomer,
	customerIsLoading,
	hasCustomerError,
	getTraveller,
	hasUpdateTravellerError,
	isLoadingTraveller,
} from '../../../reducer';
import withTextContext from '../../../utils/withTextContext';

class DeliveryAddress extends React.Component {
	componentDidMount() {
		this.getAddress({ open: true });
	}

	getAddress = ({ open }) => {
		const { customer, traveller } = this.props;

		if (!customer && open) {
			this.props.getCustomer();
		}

		if (!traveller && open) {
			this.props.getTraveller();
		}
	};

	updateAddress = async address => {
		await Promise.all([
			this.props.updateTraveller({ name: address.name }),
			this.props.updateDeliveryAddress(address),
		]);

		if (!this.hasError()) {
			this.props.renderConfirmationCard(
				<p>Dina adressuppgifter har sparats</p>
			);
		}
	};

	hasError = () =>
		this.props.hasCustomerError || this.props.hasUpdateTravellerError;
	isLoading = () =>
		this.props.customerIsLoading || this.props.travellerIsLoading;

	render() {
		const { customer, traveller } = this.props;

		return this.isLoading() ? (
			<Loading />
		) : (
			<DeliveryAddressForm
				onUpdate={this.updateAddress}
				address={customer}
				name={traveller.name}
				showError={this.hasError()}
				informationText={
					this.props.texts.private.profilepage.address
						.informationtext
				}
			/>
		);
	}
}

function mapStateToProps(store) {
	return {
		customer: getCustomer(store),
		customerIsLoading: customerIsLoading(store),
		hasCustomerError: hasCustomerError(store),
		traveller: getTraveller(store),
		hasUpdateTravellerError: hasUpdateTravellerError(store),
		travellerIsLoading: isLoadingTraveller(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, { ...customerActions, ...travellerActions })(
		DeliveryAddress
	)
);
