import React from 'react';
import styled from 'styled-components';
import { userManager } from '../../utils/userManager';
import { SecondaryButton } from './elements/SecondaryButton';
import { Card } from './blocks/Card';
import { HomePageMain } from './blocks/HomePageMain';
import LogoWrapper from './blocks/LogoWrapper';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { getLocationState } from '../../reducer';
import { connect } from 'react-redux';

class LoginPage extends React.Component {
	onLoginButtonClick = event => {
		const { locationState } = this.props;
		event.preventDefault();
		userManager.signinRedirect({
			state: { location: locationState.accessedPath },
		});
	};

	render() {
		return (
			<HomePageMain>
				<LogoContainer>
					<LogoWrapper />
				</LogoContainer>
				<CardBox>
					<h4 style={{ marginTop: '0', marginBottom: '1em' }}>
						{this.props.texts.private.loginpage.signinbox.header}
					</h4>
					<p>{this.props.texts.private.loginpage.signinbox.description}</p>
					<SecondaryButton
						style={{ marginTop: '1em' }}
						onClick={this.onLoginButtonClick}
					>
						Logga in
					</SecondaryButton>
				</CardBox>
			</HomePageMain>
		);
	}
}

const LogoContainer = styled.div`
	margin-left: 2em;
	align-self: center;

	${forTabletPortraitUp`
		align-self: flex-start;
	`}
`;

const CardBox = styled(Card)`
	align-items: center;

	${forTabletPortraitUp`
		padding: 3em;
		margin: 6.5em 0 9.5em 0;
	`}
`;

export default connect(store => ({ locationState: getLocationState(store) }))(
	withTextContext(LoginPage)
);
