import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

export function Loading(props) {
	const { type, className, style, text } = props;

	const classes = classNames(className, { [`alert alert-${type}`]: !!type });

	return (
		<div className={classes} style={style}>
			{text ? (
				<>
					<Wrapper>
						<Spinner /> <span>{text}</span>
					</Wrapper>
				</>
			) : (
				<>
					<Wrapper>
						<Spinner /> <SPAN>Laddar...</SPAN>
					</Wrapper>
				</>
			)}
		</div>
	);
}

const SPAN = styled.span`
color: #000;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 1em;
`

const Spinner = styled.div`
		display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
		margin-right: 5px;

		@keyframes spinner-border {
			to { transform: rotate(360deg); }
		}
`
