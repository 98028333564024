import styled from 'styled-components';
import { Main } from '../blocks/Main';
import { forTabletLandscapeOnly, forDesktopUp, forTabletPortraitUp } from '../../../utils/mediaqueries';

export const HomePageMain = styled(Main)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 1em;
	padding-bottom: 1em;
	background-color: ${props => props.theme.dark_grey};
	background: url(${props => props.backgroundSmall}) top;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;

	${forTabletPortraitUp`
		padding-top: 3em;
		padding-bottom: 5em;
	`}

	${forTabletLandscapeOnly`
		background: url(${props => props.backgroundMedium}) top;
	`}
	
	${forDesktopUp`
		background: url(${props => props.backgroundLarge}) top;
	`}
`;
