import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { Main } from '../common/blocks/Main';
import CreateAdminPopup from './CreateAdminPopup';
import {
	actionCreators as adminActions,
	getAdminRoles,
	getAllAdmins,
	isLoadingAdminRoles,
	isLoadingAdmins,
} from '../../reducer/school/admins';
import { Loading } from '../common/blocks/Loading';
import withTextContext from '../../utils/withTextContext';
import { AddButton } from '../common/elements/AddButton';
import { GroupTable, ToolsContainer } from './GroupPage';
import RemoveAdminPopup from './RemoveAdminPopup';
import { Banner } from '../common/blocks/Banner';

class AdminPage extends Component {
	state = {
		createAdmin: false,
		removeAdmin: false,
		adminToRemove: undefined,
	};

	componentDidMount() {
		const { admins, adminRoles } = this.props;
		if (isEmpty(admins)) {
			this.props.getAllAdmins();
		}
		if (isEmpty(adminRoles)) {
			this.props.getAdminRoles();
		}
	}

	toggleCreateAdmin = () => {
		this.setState(state => ({ createAdmin: !state.createAdmin }));
	};

	toggleRemoveAdmin = admin => () => {
		this.setState(state => ({
			removeAdmin: !state.removeAdmin,
			adminToRemove: admin,
		}));
	};

	render() {
		const { createAdmin, adminToRemove, removeAdmin } = this.state;
		const { admins, isLoading, texts, adminRoles } = this.props;

		let sortedAdmins = [];
		if (admins.length > 0)
			sortedAdmins = admins.sort((a, b) => a.name.localeCompare(b.name));

		if (isLoading) {
			return (
				<Main>
					<Loading text="Laddar alla administratörer..." />
				</Main>
			);
		}

		return (
			<>
				<Banner bannerImg={texts.school.images.banners.adminpage}>
					<h2>Administratörer</h2>
					<p>{texts.school.adminpage.listview.informationtext}</p>
				</Banner>
				<Main>
					<ToolsContainer>
						<AddButton onClick={this.toggleCreateAdmin}>
							<span>Skapa ny administratör</span>
						</AddButton>
					</ToolsContainer>

					<GroupTable>
						<thead>
							<tr>
								<th>Namn</th>
								<th>E-post</th>
								<th>Personnummer</th>
								<th>Typ av administratör</th>
								<th>Telefonnummer</th>
							</tr>
						</thead>
						<tbody>
							{sortedAdmins.map((admin, idx) => (
								<tr key={idx} onClick={this.toggleRemoveAdmin(admin)}>
									<td>{admin.name}</td>
									<td>{admin.email}</td>
									<td>{admin.personalIdentityNumber}</td>
									<td>{admin.roleName}</td>
									<td>{admin.phoneNumber}</td>
								</tr>
							))}
						</tbody>
					</GroupTable>
				</Main>
				{createAdmin && (
					<CreateAdminPopup
						closePopup={this.toggleCreateAdmin}
						texts={texts}
						onCreateAdmin={this.createAdmin}
						adminRoles={adminRoles}
					/>
				)}
				{removeAdmin && (
					<RemoveAdminPopup
						onClosePopup={this.toggleRemoveAdmin()}
						admin={adminToRemove}
					/>
				)}
			</>
		);
	}
}

export default withTextContext(
	connect(
		store => ({
			admins: getAllAdmins(store),
			adminRoles: getAdminRoles(store),
			isLoadingRoles: isLoadingAdminRoles(store),
			isLoading: isLoadingAdmins(store),
		}),
		{ ...adminActions, push }
	)(AdminPage)
);
