import React from 'react';
import styled from 'styled-components';
import { NextButton } from '../../common/elements/NextButton';
import { isEmpty } from 'lodash';
// import { ReactComponent as ShoppingCart } from '../../../images/cart_black.svg';
import { ReactComponent as Trash } from '../../../images/trash.svg';
import ShoppingCart from '../../../images/cart_black.svg';
import { formatCurrency } from '../../../utils/formatting';
import { unique } from '../../../utils/helperFunctions';

export function SelectedTicketWrapper(props) {
	if (isEmpty(props.ticketOrderRows)) return null;

	const uniqueProductIds = unique(props.ticketOrderRows.map(t => t.productId));
	
	const totalSum = props.ticketOrderRows.reduce((sum, t) => {
		return (sum += props.ticketOrderProducts.find(
			o => o.productId === t.productId
		).pricePerYear);
	}, 0);

	return (
		<MainWrapper>
			<Wrapper>
				<SummaryHeader>
					<h2>
						{/* <ShoppingCartIcon /> */}
						<img
							src={ShoppingCart}
							aria-hidden="true"
							focusable="false"
							alt=""
						/>{' '}
						Varukorg
					</h2>
					<HideButton onClick={props.onHideClick}>
						{props.expandTicketSummary ? 'Dölj' : 'Visa'}
					</HideButton>
				</SummaryHeader>

				{props.expandTicketSummary && (
					<SummaryContents>
						<GridLeft>
							<SummaryTable>
								<thead>
									<tr>
										<th>Biljett</th>
										<th>Antal</th>
										<th>Pris</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{uniqueProductIds.map(id => {
										let offer = props.ticketOrderProducts.find(
											o => o.productId === id
										);
										let offerCount = props.ticketOrderRows.filter(
											x => x.productId === id
										);

										return (
											<tr key={id}>
												<td>{offer.name}</td>
												<td>{offerCount.length} st</td>
												<td>{formatCurrency(offer.pricePerYear)}</td>
												<td>
													<TrashIcon
														onClick={() => props.onRemoveTicketOrderRow(id)}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
							</SummaryTable>
						</GridLeft>

						<GridRight>
							<SummaryTotal>Summa: {formatCurrency(totalSum)}</SummaryTotal>
							<ActionButton onClick={props.onConfirmOrder}>Gå vidare</ActionButton>
						</GridRight>
					</SummaryContents>
				)}
			</Wrapper>
		</MainWrapper>
	);
}

const Wrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 900px;
	background-color: #ffffff;
	box-shadow: 0 -5px 13px 10px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	border-top-left-radius: 2em;
	border-top-right-radius: 2em;
`;

const MainWrapper = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1;
`;

const SummaryHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	padding: 18px 0 9px;

	h2 {
		color: ${props => props.theme.text_color};
		font-size: 16px;
		font-weight: 600;
		margin: 0 0 0 100px;
	}
`;

const HideButton = styled.button`
	background-color: transparent;
	border: none;
	color: ${props => props.theme.link_color};
	font-size: 16px;

	:hover {
		color: ${props => props.theme.link_hover_color};
		text-decoration: underline;
		cursor: pointer;
	}
`;

// const ShoppingCartIcon = styled(ShoppingCart)`
// 	color: ${props => props.theme.text_color};
// `;

const SummaryContents = styled.div`
	display: flex;
	flex-direction: row;
	padding-bottom: 20px;
`;

const SummaryTable = styled.table`
	th,
	td {
		padding: 0 40px;

		:first-of-type {
			width: 250px;
		}
	}
	th {
		color: ${props => props.theme.text_color};
		font-size: 14px;
		font-weight: 400;
	}
`;

const TrashIcon = styled(Trash)`
	color: ${props => props.theme.text_color};
	height: 14px;

	:hover {
		cursor: pointer;
	}
`;

const GridRight = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto auto 0.5em auto;
	align-self: flex-end;
`;

const SummaryTotal = styled.h2`
	margin: 5px 0;
	color: ${props => props.theme.text_color};
	font-size: 18px;
	font-weight: 600;
`;

const GridLeft = styled.div`
	margin: auto;
`;

const ActionButton = styled(NextButton)`
	width: 200px;
	height: 40px;
`;
