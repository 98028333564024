import React from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { TextInput } from '../common/elements/TextInput';
import { Label } from '../common/elements/Label';
import EmailCode from '../../images/emailkod.svg';

export class RegistrationVerifyEmail extends React.Component {
	onChange = e => {
		this.props.handleChange(e.value);
	};

	onSubmit = async () => {
		this.props.submitVerificationCode();
	};

	handleVerificationCodeChange = changedVerificationCode => {
		this.props.handleVerificationCodeChange(changedVerificationCode.value);
	};

	formatVerificationDataValue = () => {
		let { verificationData } = this.props;

		return verificationData.value;
	};

	render() {
		const { verificationCode } = this.props;

		return (
			<>
				<h3>Bekräfta e-post</h3>

				<p>En kod har skickats till din e-post</p>
				<VerificationIcon
					src={EmailCode}
					alt=""
				/>

				<div className="form-group">
					<Label htmlFor="code">Ange kod</Label>
					<TextInput
						type="text"
						value={verificationCode}
						handleChange={this.handleVerificationCodeChange}
						className="form-control"
						id="code"
						maxLength="6"
					/>
				</div>

				<FullWidthSecondaryButton onClick={this.onSubmit} disabled={false}>
					Bekräfta
				</FullWidthSecondaryButton>
			</>
		);
	}
}

const VerificationIcon = styled.img`
	margin: 1em auto;
	display: block;
	height: 60px;
`;

const FullWidthSecondaryButton = styled(SecondaryButton)`
	width: 100%;
`;
