import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Card } from '../common/blocks/Card';
import { SecondaryButton } from './../common/elements/SecondaryButton';
import { A } from './../common/elements/A';
import { actionCreators, TRAVELLER_ERRORS } from '../../reducer/traveller';
import { Error } from './../common/elements/Error';
import { Loading } from '../common/blocks/Loading';

class AcceptTerms extends React.Component {
	state = { acceptedTerms: false };

	handleChange = ({ target }) => {
		this.setState({ [target.id]: target.checked });
	};

	updateTerms = async () => {
		const { terms, privacy } = this.props.legal.data;
		const latestTerms = { terms, privacy };

		await this.props.updateTraveller({ legalAccepted: latestTerms });

		if (!this.hasError()) {
			this.props.onSuccess();
		}
	};

	hasError = () =>
		this.props.traveller.errorMessage === TRAVELLER_ERRORS.UPDATE_TRAVELLER;

	isLoading = () =>
		this.props.traveller.isLoading || this.props.legal.isLoading;

	render() {
		const { className } = this.props;
		const { terms, privacy } = this.props.legal.data;
		const hasError = this.hasError();
		const isLoading = this.isLoading();

		return (
			<TermsCard className={className}>
				<h2>Godkänn avtal</h2>

				<p>
					Du har inte godkänt de senaste versionerna av vårt{' '}
					<A href={terms} target="_blank" rel="noopener noreferrer">
						avtal{' '}
						<span
							className="glyphicon glyphicon-new-window"
							aria-hidden="true"
						/>
					</A>{' '}
					och vår{' '}
					<A href={privacy} target="_blank" rel="noopener noreferrer">
						integritetspolicy{' '}
						<span
							className="glyphicon glyphicon-new-window"
							aria-hidden="true"
						/>
					</A>
					.
				</p>

				<div className="form-group">
					<div className="radio-inline">
						<label>
							<input
								type="checkbox"
								id="acceptedTerms"
								checked={this.state.acceptedTerms}
								onChange={this.handleChange}
							/>{' '}
							Jag har läst och godkänner avtal och integritetspolicy
						</label>
					</div>
				</div>
				<SecondaryButton
					onClick={this.updateTerms}
					disabled={!this.state.acceptedTerms}
				>
					Spara
				</SecondaryButton>
				{isLoading && <Loading/>}
				{hasError && <Error />}
			</TermsCard>
		);
	}
}

const TermsCard = styled(Card)`
	margin-left: auto;
	margin-right: auto;
`;

export default connect(
	({ traveller, legal }) => ({ traveller, legal }),
	{ updateTraveller: actionCreators.updateTraveller }
)(AcceptTerms);
