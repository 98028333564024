import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { SCHOOL } from '../../Paths';
import withTextContext from '../../utils/withTextContext';
import { Main } from '../common/blocks/Main';
import { Incrementer } from '../common/elements/Incrementer';
import { Card } from '../common/blocks/Card';
import { ReactComponent as TravelCard } from '../../images/travel-card.svg';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { PopupContainer } from '../common/elements/PopUpBox';
import AddressForm from './blocks/AddressForm';
import isEmpty from 'lodash/isEmpty';
import { sanitizeCmsHtml } from '../../texts';
import {
	actionCreators as orderActions,
	bulkProducts,
	isLoadingBulkProducts,
	getBulkMultipleCardOrderResponse,
} from '../../reducer/school/orders';
import { Loading } from '../common/blocks/Loading';
import { Error } from '../common/elements/Error';
import { forPhoneOnly } from '../../utils/mediaqueries';
import { Banner } from '../common/blocks/Banner';

class OrderTravelCardBulkPage extends Component {
	state = {
		schoolName: '',
		attention: '',
		address: '',
		postalCode: '',
		city: '',
		confirmation: false,
		selectedTravelCardProducts: {},
		error: false,
	};

	componentDidMount() {
		const { bulkProducts } = this.props;

		if (isEmpty(bulkProducts)) {
			this.props.getBulkProducts();
		}
	}

	handleChangeTravelCards = (product, newValue) => {
		this.setState({
			selectedTravelCardProducts: {
				...this.state.selectedTravelCardProducts,
				[product.productId]: { ...product, travelCardCount: newValue },
			},
		});
	};

	totalAmountTravelCards = listTravelCardProducts =>
		listTravelCardProducts.reduce((tot, product) => {
			return tot + product.amount * product.travelCardCount;
		}, 0);

	closeConfirmation = () => {
		this.setState({
			confirmation: false,
		});
		this.props.push(SCHOOL.ORDER);
	};

	getConfirmationCardText = () => {
		const orderNumber =
			this.props.getBulkMultipleCardOrderResponse.orderNumber || '';

		return (
			<>
				<span>Ordernummer</span>
				<h3>{orderNumber}</h3>
			</>
		);
	};

	showConfirmation = () => {
		return (
			<PopupContainer>
				<ConfirmationCard
					onClick={this.closeConfirmation}
					title="Tack för din beställning"
					text={this.getConfirmationCardText()}
				/>
			</PopupContainer>
		);
	};

	createOrder = async () => {
		const {
			schoolName,
			attention,
			address,
			postalCode,
			city,
			selectedTravelCardProducts,
		} = this.state;

		const listTravelCardProducts = Object.values(selectedTravelCardProducts);

		let cardOrderRows = [];

		listTravelCardProducts.forEach(product => {
			for (let index = 0; index < product.travelCardCount; index++) {
				cardOrderRows.push({ productId: product.productId });
			}
		});

		const cardOrder = {
			accountId: this.props.school.schoolId,
			administratorId: this.props.admin.id,
			companyName: schoolName,
			attention: attention,
			addressLine1: address,
			postalCode: postalCode,
			city: city,
			cardOrderRows,
		};

		try {
			await this.props.createBulkMultipleCardOrder(cardOrder);
			this.setState({ confirmation: true });
		} catch (error) {
			this.setState({
				error: true,
			});
		}
	};

	render() {
		const { texts, bulkProducts } = this.props;
		const {
			confirmation,
			validButton,
			selectedTravelCardProducts,
			error,
		} = this.state;

		const listTravelCardProducts = Object.values(selectedTravelCardProducts);

		return (
			<>
				<Banner bannerImg={texts.school.images.banners.orderpage}>
					<BannerHeader>Beställ resekort</BannerHeader>
				</Banner>
				<MainOrderTravelCard>
					<IncrementerCard>
						{!isLoadingBulkProducts ? (
							<ProductWrapper>
								<Loading />
							</ProductWrapper>
						) : (
							<>
								{!!bulkProducts && bulkProducts.length > 0 ? (
									bulkProducts.map((x, idx) => (
										<ProductWrapper key={idx}>
											<TravelCardIcon />
											<TravelCardInfo>
												<TravelCardInfoText>
													{x.amount} st {x.name}
												</TravelCardInfoText>
												<TravelCardInfoText>
													{x.cardLayoutName}, {x.description}
												</TravelCardInfoText>
											</TravelCardInfo>
											<IncrementerWrapper>
												<Incrementer
													initialValue={0}
													minValue={0}
													onIncrement={newValue =>
														this.handleChangeTravelCards(x, newValue)
													}
													onDecrement={newValue =>
														this.handleChangeTravelCards(x, newValue)
													}
													onInputChange={newValue =>
														this.handleChangeTravelCards(x, newValue)
													}
												/>
											</IncrementerWrapper>
										</ProductWrapper>
									))
								) : (
									<h4>Du har inga produkter att visa</h4>
								)}
							</>
						)}
					</IncrementerCard>
					<ProductInfoText
						dangerouslySetInnerHTML={sanitizeCmsHtml(
							texts.school.ordertravelcardbulkpage.orderinfo.informationtext
						)}
					/>
					<>
						<ProductInfo>
							<Top>
								<Bold>Produkt</Bold>
								<Bold>Antal</Bold>
							</Top>
						</ProductInfo>
						{this.totalAmountTravelCards(listTravelCardProducts) > 0 ? (
							listTravelCardProducts.map((x, idx) => (
								<ProductInfo key={idx}>
									<Bottom>
										<Bold>
											{x.amount} st {x.name}
										</Bold>
										<span>{x.travelCardCount} st</span>
									</Bottom>
								</ProductInfo>
							))
						) : (
							<></>
						)}
					</>
					<Summary>
						<Address>
							<AddressForm
								getAddressInfo={schoolState =>
									this.setState({ ...schoolState })
								}
							/>
						</Address>
						<TotalCards>
							<p>Totalt antal kort</p>
							<h3>{this.totalAmountTravelCards(listTravelCardProducts)} st</h3>
						</TotalCards>
					</Summary>
					{error && <Error />}
					<ConfirmButton
						onClick={this.createOrder}
						disabled={
							!validButton ||
							!this.totalAmountTravelCards(listTravelCardProducts) > 0
						}
					>
						Slutför beställning
					</ConfirmButton>
				</MainOrderTravelCard>
				{confirmation && this.showConfirmation()}
			</>
		);
	}
}

const MainOrderTravelCard = styled(Main)`
	max-width: 650px;
`;

const BannerHeader = styled.h2`
	width: 225px;
`;

const IncrementerCard = styled(Card)`
	margin: 0 auto;
	max-width: 650px;
`;

const ProductWrapper = styled.div`
	display: flex;
	padding: 0.5em 0;
`;

const TravelCardIcon = styled(TravelCard)`
	width: 50px;
	height: 40px;
	margin-right: 30px;

	${forPhoneOnly`
		width: 30px;
	`}
`;

const TravelCardInfo = styled.div`
	text-align: left;

	${forPhoneOnly`
			max-width: 130px;
	`}
`;

const TravelCardInfoText = styled.p`
	margin-bottom: 2px;
	max-width: 300px;
	:last-child {
		font-style: italic;
		color: #464646;
	}
`;

const IncrementerWrapper = styled.div`
	margin-left: auto;
	margin-top: 5px;
`;

const ProductInfo = styled.div`
	padding: 0 25px;
	margin-bottom: 1.5em;
`;

const Top = styled.div`
	margin-bottom: 20px;
	span:last-child {
		float: right;
	}
`;

const Bottom = styled.div`
	margin-bottom: 20px;
	span:last-child {
		float: right;
	}
`;

const Bold = styled.span`
	font-weight: 600;
`;

const Summary = styled.div`
	display: flex;
	position: relative;
	border-top: solid 1px;
	border-color: #f0f0f0;
`;

const Address = styled.div`
	padding-left: 25px;
	width: 400px;
`;

const TotalCards = styled.div`
	margin-left: auto;
	text-align: right;
	font-weight: 600;
	position: absolute;
	right: 25px;
	bottom: 5px;

	${forPhoneOnly`
		top: 100%;
	`}
`;

const ConfirmButton = styled(SecondaryButton)`
	display: block;
	width: 100%;
	height: 50px;
	margin: 2em 0 1em;

	${forPhoneOnly`
			margin: 6em 0 1em;
	`}
`;

const ProductInfoText = styled.p`
	width: 100%;
	margin: 4em 0 2em;
	padding: 0 25px;
`;

export default withTextContext(
	connect(
		store => ({
			bulkProducts: bulkProducts(store),
			isLoadingBulkProducts: isLoadingBulkProducts(store),
			getBulkMultipleCardOrderResponse: getBulkMultipleCardOrderResponse(store),
		}),
		{
			...orderActions,
			push,
		}
	)(OrderTravelCardBulkPage)
);
