import React, { Component } from 'react';
import styled from 'styled-components';
import { NextButton } from '../../common/elements/NextButton';
import { formatCurrency, formatISODate } from '../../../utils/formatting';
import { TRANSACTION_TYPE, TRANSACTION_STATES } from '../../../reducer/transaction';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	Container,
	ClosedInfo,
	ProductInfoWrapper,
	ExpandDetailed,
} from './HistoryContainer';

export default class PurchaseHistory extends Component {
	state = {
		expandDetails: false,
	};

	chevronIcon = selected => {
		if (selected) {
			return (
				<ChevronUpIcon
					className="svg-icon"
					aria-hidden="true"
					focusable="false"
					alt=""
				/>
			);
		} else {
			return (
				<ChevronDownIcon
					className="svg-icon"
					aria-hidden="true"
					focusable="false"
					alt=""
				/>
			);
		}
	};

	expandDetailedInfo = () => {
		this.setState(state => ({
			expandDetails: !state.expandDetails,
		}));
	};

	cancelTransaction = () => {
		const { transaction } = this.props;

		this.props.onCancelTransaction(transaction.id);
	};

	getTotalAmount = () => {
		const { transaction } = this.props;
		const totalAmount = formatCurrency(transaction.totalAmount);
		return transaction.type === TRANSACTION_TYPE.CANCEL ? '-' + totalAmount : totalAmount;
	}

	render() {
		const { expandDetails } = this.state;
		const { transaction } = this.props;

		if (!transaction) return null;

		const timeStamp = formatISODate(transaction.timestamp);
		const percentageVat = transaction.vat.map(p => p.percentage);
		const amountVat = formatCurrency(transaction.vat.map(a => a.amount));
		const isTypeCancel = transaction.type === TRANSACTION_TYPE.CANCEL;
		const isCancelled = transaction.state === TRANSACTION_STATES.CANCELLED;
		
		return (
			<Container>
				<ClosedInfo>
				  <p>{timeStamp}</p>
					{!expandDetails && <em><p>{this.getTotalAmount()}</p></em>}
				</ClosedInfo>

				{isTypeCancel && <Cancelled>Återköp</Cancelled>}

				{expandDetails && (
					<ProductInfoWrapper>
						{transaction.items.map((i, idx) => (
							<Info key={idx}>
								<span>
									{i.productSetTitle}, {i.count} st
								</span>
								<em>{formatCurrency(i.amount)}</em>
							</Info>
						))}

						<TotalInfo>
							<Total>Total:</Total>
							<TotalAmount>{this.getTotalAmount()}</TotalAmount>
						</TotalInfo>

						<VatInfo>
							<span>Varav moms {percentageVat}%</span>
							<span>{amountVat}</span>
						</VatInfo>

						{transaction.cancellable && (
							<NextButtonContainer>
								<NextButton onClick={this.cancelTransaction}>
									Ångra köp
								</NextButton>
							</NextButtonContainer>
						)}
						
			    	{isCancelled && !isTypeCancel && <ItalicRed>Återköpt</ItalicRed>}
					</ProductInfoWrapper>
				)}
				<ExpandDetailed onClick={this.expandDetailedInfo}>
					{this.chevronIcon(expandDetails)}
				</ExpandDetailed>
			</Container>
		);
	}
}

const Cancelled = styled.span`
	font-weight: 600;
	color: #d30808;
`;

const ItalicRed = styled.div`
	font-style: italic;
	color:  #d30808;
	float: right;
`;

const VatInfo = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 2em;
	font-weight: 400;
	font-size: 11px;
	font-style: italic;
	line-height: 0;
`;

const NextButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 1em;
`;

const TotalInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1em;
	font-weight: 600;
`;

const Total = styled.span`
	font-size: 14px;
`;

const TotalAmount = styled.span`
	font-size: 21px;
`;

const Info = styled.div`
	display: flex;
	justify-content: space-between;
`;
