import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { CardWide } from '../common/blocks/CardWide';
import { Main } from '../common/blocks/Main';
import { ThemedNavLink } from '../common/elements/ThemedNavLink';
import { Error } from '../common/elements/Error';
import withTextContext from '../../utils/withTextContext';
import { SCHOOL } from '../../Paths';
import {
	actionCreators as cartActions,
	getAllStudentsInCart,
	getSchoolCart,
} from '../../reducer/school/cart';
import { actionCreators as orderActions } from '../../reducer/school/orders';
import { ReactComponent as TrashIcon } from '../../images/trash.svg';
import { getGroupById } from '../../reducer/groups';
import AddressForm from './blocks/AddressForm';
import {
	actionCreators as studentActions,
	getCardLayoutById,
	isLoadingBulkSpecificOrders,
} from '../../reducer/students';
import { getSchool } from '../../reducer/school';
import { getAdmin } from '../../reducer/school/admins';
import { Loading } from '../common/blocks/Loading';

class OrderTravelCardCart extends Component {
	state = {
		addressInfo: {
			schoolName: '',
			attention: '',
			address: '',
			postalCode: '',
			city: '',
		},
		hasError: false,
	};

	deleteFromCart = async (cardLayoutId, groupId) => {
		await this.props.deleteOrderFromCart(cardLayoutId, groupId);
	};

	orderCart = async () => {
		const { addressInfo } = this.state;
		const { cart, school, admin } = this.props;

		const orderDeliveryAddress = {
			accountId: school.schoolId,
			administratorId: admin.id,
			companyName: addressInfo.schoolName,
			attention: addressInfo.attention,
			addressLine1: addressInfo.address,
			postalCode: addressInfo.postalCode,
			city: addressInfo.city,
		};

		const orders = Object.entries(cart).reduce((array, item) => {
			const cardLayoutId = item[0];
			const orderInfo = Object.entries(item[1]);

			const cardOrderRows = orderInfo.reduce((groupOrders, group) => {
				const groupId = group[0];
				const students = Object.values(group[1]);
				return [
					...groupOrders,
					...students.map(student => ({
						contactId: student.contactId,
						groupId,
					})),
				];
			}, []);

			return [
				...array,
				{ cardLayoutId, ...orderDeliveryAddress, cardOrderRows },
			];
		}, []);

		try {
			await this.props.createBulkCardOrder(orders);
			this.props.push(SCHOOL.CARD_ORDER_CONFIRMATION);
		} catch (error) {
			this.setState({ hasError: true });
		}
	};

	handleCheckboxClick = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	render() {
		const { hasError, errorMessage } = this.state;
		const {
			cart,
			studentsInCart,
			getGroupById,
			getCardLayoutById,
			isLoadingBulkSpecificOrders,
		} = this.props;

		if (!cart || Object.entries(cart).length === 0) {
			return (
				<Main>
					<FlexWrapper>
						<CartBox>
							<CartPageHeader>
								<h2>Din varukorg är tom</h2>
							</CartPageHeader>
							<AddMoreTickets
								exact
								to={SCHOOL.ORDER_TRAVEL_CARD}
								activeClassName="active"
							>
								+ Lägg till order
							</AddMoreTickets>
						</CartBox>
					</FlexWrapper>
				</Main>
			);
		}

		return (
			<Main>
				<FlexWrapper>
					<CartBox>
						<CartPageHeader>
							<h2>Varukorg</h2>
						</CartPageHeader>
						<AddMoreTickets
							exact
							to={SCHOOL.ORDER_TRAVEL_CARD}
							activeClassName="active"
						>
							+ Lägg till fler ordrar
						</AddMoreTickets>

						<h2>Ordrar</h2>
						<div>
							{Object.entries(cart).map((order, idxOrder) => {
								const cardLayoutId = order[0];
								const cardLayout = getCardLayoutById(cardLayoutId);
								const groups = order[1];
								return (
									<CartOrderItem key={idxOrder}>
										<ItemInfo>
											<h3>{cardLayout.name}</h3>

											{Object.entries(groups).map((group, idxGroup) => {
												const groupId = group[0];
												const groupName = getGroupById(groupId).name;
												const students = Object.values(group[1]);
												return (
													<CartGroupItem key={idxGroup}>
														<ItemInfo>
															<h4>{groupName}</h4>
															<h5>{students.length} resekort</h5>
														</ItemInfo>
														<TrashButton
															onClick={() =>
																this.deleteFromCart(cardLayoutId, groupId)
															}
															aria-label="Ta bort"
															type="button"
														>
															<TrashIcon
																className="svg-icon"
																aria-hidden="true"
																focusable="false"
															/>
														</TrashButton>
													</CartGroupItem>
												);
											})}
										</ItemInfo>
									</CartOrderItem>
								);
							})}
						</div>

						<CartSummary>
							<Total>Total:</Total>
							<Price>{studentsInCart.length} resekort</Price>
						</CartSummary>

						<AddressForm
							getAddressInfo={addressInfo =>
								this.setState({ addressInfo: { ...addressInfo } })
							}
						/>

						{errorMessage !== undefined && (
							<ValidationError>{errorMessage}</ValidationError>
						)}

						<CheckoutButton
							disabled={isLoadingBulkSpecificOrders}
							onClick={this.orderCart}
						>
							Beställ
						</CheckoutButton>
						{isLoadingBulkSpecificOrders && <Loading />}

						{hasError && (
							<Error>
								<span
									className="glyphicon glyphicon-exclamation-sign"
									aria-hidden="true"
								/>
								&nbsp;Det gick inte att slutföra beställningen. Var god försök
								igen senare och kontakta kundtjänst ifall problemet kvarstår.
							</Error>
						)}
					</CartBox>
				</FlexWrapper>
			</Main>
		);
	}
}

const ValidationError = styled.p`
	color: #ff0000;
	font-size: 14px;
	white-space: pre-line;
	margin: 5px 2px 4px;
`;

const TrashButton = styled.button`
	background-color: transparent;
	border: 0;
	cursor: pointer;
	margin-bottom: 1em;
`;

const CheckoutButton = styled(SecondaryButton)`
	margin: 1em auto;
	display: block;
`;

const ItemInfo = styled.div`
	width: 100%;

	h4 {
		margin-top: 0;
	}
`;

const CartItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em 0;

	:last-of-type,
	:only-child {
		border-bottom: none;
	}
`;

const CartOrderItem = styled(CartItem)`
	border-bottom: 1px solid #bdbdbd;
	:last-of-type,
	:only-child {
		border-bottom: none;
	}
`;

const CartGroupItem = styled(CartItem)`
	border-bottom: 1px solid #ededed;
	padding-left: 1em;
`;

const Price = styled.div`
	font-weight: 600;
`;

const Total = styled.h3`
	font-weight: 600;
`;

const CartSummary = styled.div`
	display: flex;
	flex-direction: column;
	border-top: 1px solid #bdbdbd;
`;

const AddMoreTickets = styled(ThemedNavLink)`
	display: block;
	margin-top: -1em;
	margin-bottom: 1em;
`;

const CartPageHeader = styled.div`
	display: flex;
	margin-bottom: 1em;

	h2 {
		width: 100%;
	}
`;

const CartBox = styled(CardWide)`
	text-align: left;
`;

function mapStateToProps(store) {
	return {
		school: getSchool(store),
		admin: getAdmin(store),
		cart: getSchoolCart(store),
		studentsInCart: getAllStudentsInCart(store),
		getGroupById: getGroupById(store),
		getCardLayoutById: getCardLayoutById(store),
		isLoadingBulkSpecificOrders: isLoadingBulkSpecificOrders(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...cartActions,
		...orderActions,
		...studentActions,
		push,
	})(OrderTravelCardCart)
);
