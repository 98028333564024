import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Card } from '../common/blocks/Card';
import { push } from 'connected-react-router';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import TicketNavigation from './blocks/TicketNavigation';
import { PageHeader } from './PageHeader';
import { Main } from '../common/blocks/Main';
import {
	actionCreators as travellerActions,
	hasActivateProductError,
} from '../../reducer/traveller';
import { actionCreators as bearerActions } from '../../reducer/bearer';
import { actionCreators as productActions } from '../../reducer/products';

import {
	getTravellerProducts,
	hasGetTravellerProductsError,
	isLoadingTravellerProducts,
	isLoadingMtbBearers,
	getBearers,
	getTraveller,
	hasProductError,
	hasUpdateProductBearerError,
	hasTransferProductError,
	hasLoanProductError,
} from '../../reducer';
import { Loading } from '../common/blocks/Loading';
import { Error } from '../common/elements/Error';
import { Ticket } from '../customer/ticket/Ticket';
import { PRIVATE } from '../../Paths';
import { RegisterTravelCardLink } from '../common/elements/RegisterTravelCardLink';
import { SignedInWelcomeText } from '../customer/blocks/SignedInWelcomeText';
import { MovingBus } from './blocks/MovingBus';
import { forLargePhoneUp } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

export const EXPAND_TYPES = {
	MISSING_BEARER: 'MISSING_BEARER',
	SHOW_ACTIONS: 'SHOW_ACTIONS',
};

export class TicketPage extends Component {
	state = {
		expandZone: null,
		expandCard: null,
		showConfirmationCard: null,
		showError: null,
	};

	componentDidMount() {
		document.title = "Mina Biljetter | Mitt konto"
		const { travellerProducts, bearers } = this.props;
		if (!travellerProducts) this.loadProducts();
		if (!bearers) this.loadBearers();
		if (this.props.bearers) {
			this.setPreferredBearerOnBearerDelete();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.bearers !== prevProps.bearers) {
			this.updatePreferredBearer();
		}
	}

	updatePreferredBearer = async () => {
		await this.props.updatePreferredBearer();
	}

	setPreferredBearerOnBearerDelete = async () => {
		await this.props.setPreferredBearerOnBearerDelete();
	}

	loadProducts = async () => {
		await this.props.getTravellerProducts();
	};

	loadBearers = async () => {
		this.props.getBearers();
	};

	buyTicket = () => {
		this.props.push(PRIVATE.PURCHASE_OFFER);
	};

	hasData = (travellerProducts, bearers) => {
		return !!travellerProducts && travellerProducts.length > 0 && !!bearers;
	};

	toggleExpandCard = (product, expandType) => {
		const { expandCard } = this.state;

		if (
			!!expandCard &&
			product.id === expandCard.id &&
			expandType === expandCard.type
		) {
			this.setState({
				expandCard: null,
			});
			return;
		}

		this.setState({
			expandCard: {
				id: product.id,
				type: expandType,
			},
		});
	};

	showTravellerProducts = () => {
		this.setState({ showConfirmationCard: null }, () => this.loadProducts());
	};

	showErrorMessage = (id, message) => {
		this.setState({
			expandCard: null,
			showError: { id, message },
		});
	};

	redirectToConfirmationPage = ({ title, text }) =>
		this.props.push(PRIVATE.TICKET_CONFIRMATION, {
			nextRoute: PRIVATE.TICKET,
			title,
			text,
		});

	formattedBearers = bearers =>
		(bearers || []).map(b => ({ title: b.name, id: b.id }));

	setBearer = async (productId, bearerId) => {
		await this.props.updateProductBearer(productId, bearerId);

		if (!this.props.hasUpdateProductBearerError) {
			this.loadProducts();

			var newBearer = this.props.bearers.find(x => x.id === bearerId);

			this.redirectToConfirmationPage({
				title: this.props.texts.private.ticketpage.moveticket.header,
				text:
					this.props.texts.private.ticketpage.moveticket.confirmationtext +
					' ' +
					newBearer.name,
			});
		} else {
			this.showErrorMessage(
				productId,
				'Ett fel uppstod när biljetten skulle hanteras. Var god försök igen eller kontakta kundtjänst.'
			);
		}
	};

	redirectToPurchaseOptions = () => this.props.push(PRIVATE.BUY_TICKET_OPTIONS);

	selectProductSetFromTicket = product => {
		if (!product.repurchaseProductset) return;
		this.props.setSelectedProductSet(product.repurchaseProductset);
		this.redirectToPurchaseOptions();
	};

	onActivateTicket = async ticketId => {
		await this.props.activateProduct(ticketId);

		if (!this.props.hasActivateError) {
			this.loadProducts();
			this.redirectToConfirmationPage({
				title: 'Aktiverad',
				text: 'Biljetten är nu aktiverad!',
			});
		} else {
			this.showErrorMessage(
				ticketId,
				'Ett fel uppstod när biljetten skulle aktiveras. Var god försök igen eller kontakta kundtjänst.'
			);
		}
	};

	transferTicket = async (emailOrPhone, productId) => {
		await this.props.transferProduct(emailOrPhone, productId);

		if (!this.props.hasTransferError) {
			this.loadProducts();
			this.redirectToConfirmationPage({
				title: 'Överlåt biljett',
				text: 'Biljetten är nu överlåten!',
			});
		} else {
			this.showErrorMessage(
				productId,
				'Ett fel uppstod när biljetten skulle överlåtas. Var god försök igen eller kontakta kundtjänst.'
			);
		}
	};

	lendTicket = async (lendModel, productId) => {
		await this.props.loanProduct(lendModel, productId);

		if (!this.props.hasLoanError) {
			this.loadProducts();
			this.redirectToConfirmationPage({
				title: 'Låna ut biljett',
				text: 'Biljetten är nu utlånad!',
			});
		} else {
			this.showErrorMessage(
				productId,
				'Ett fel uppstod när biljetten skulle utlånas. Var god försök igen eller kontakta kundtjänst.'
			);
		}
	};

	render() {
		const { expandCard, showError } = this.state;
		const {
			traveller,
			travellerProducts,
			isLoadingProducts,
			isLoadingBearers,
			hasError,
			bearers,
			texts
		} = this.props;

		const {
			header: headerText,
			signedin: signedinText,
		} = texts.private.ticketpage.banner;

		let formattedBearers = this.formattedBearers(bearers);
		return (
			<>
				<Banner bannerImg={texts.private.images.banners.ticketpage}>
					<SignedInWelcomeText
						name={traveller.name}
						header={headerText}
						signedin={signedinText}
					/>
				</Banner>
				<main>
				<Main id="ticketsPageMain">
					<PageHeader>
						<H2>Mina Biljetter</H2>
						<TicketNavigation />
					</PageHeader>
					<FlexWrapperColumn>
						{isLoadingProducts || isLoadingBearers ? (
							<TicketBox>
								<ul>
								<Loading />
								</ul>
							</TicketBox>
						) : (
							<>
								{!!travellerProducts && travellerProducts.length > 0 ? (
									travellerProducts.map(x => {
										if (!x?.id) return null
										return (<Ticket
											key={x.id}
											product={x}
											bearers={formattedBearers}
											expandCard={expandCard}
											showError={showError}
											showTravellerProducts={this.showTravellerProducts}
											toggleExpandCard={this.toggleExpandCard}
											travellerId={traveller.id}
											onSetBearer={this.setBearer}
											onRepurchase={this.selectProductSetFromTicket}
											onActivateTicket={this.onActivateTicket}
											onTransferTicket={this.transferTicket}
											onLendTicket={this.lendTicket}
											texts={this.props.texts}
										/>)
									})
								) : (
									<TicketBoxEmpty>
										<H3>Du har inga biljetter att visa</H3>
										<RegisterCardLink />
										<BuyTicketButton onClick={this.buyTicket}>
											Köp biljett &gt;
										</BuyTicketButton>
									</TicketBoxEmpty>
								)}
							</>
						)}
						{hasError && <Error />}
					</FlexWrapperColumn>
				</Main>
				</main>
				<TicketPageMovingBus />
			</>
		);
	}
}

const TicketPageMovingBus = styled(MovingBus)`
	svg {
		margin-left: 23%;
	}
`;

const BuyTicketButton = styled(SecondaryButton)`
	align-self: center;
`;

const RegisterCardLink = styled(RegisterTravelCardLink)`
	display: block;
	margin: 1em 0;
`;

const FlexWrapperColumn = styled(FlexWrapper)`
	flex-direction: column;
	align-items: center;
`;

const H2 = styled.h2`
	margin-top: 0;
	margin-bottom: 1em;
`;

const H3 = styled.h3`
	color: #464646;
`

export const TicketBox = styled(Card)`
	max-width: 430px;
	text-align: left;
	padding: 1.5em;
	margin-top: 2.5em;

	:first-child {
		margin-top: 0;
	}

	h4 {
		color: rgba(46, 46, 46, 0.64);
		font-size: 20px;
		line-height: 23px;
	}

	${forLargePhoneUp`
		padding: 35px 36px 20px;
	`}
`;

const TicketBoxEmpty = styled(TicketBox)`
	text-align: center;
`;

export const ErrorCardBox = styled(TicketBox)`
	padding: 0;
`;

function mapStateToProps(state) {
	return {
		traveller: getTraveller(state),
		travellerProducts: getTravellerProducts(state),
		isLoadingProducts: isLoadingTravellerProducts(state),
		hasError: hasGetTravellerProductsError(state),
		bearers: getBearers(state),
		isLoadingBearers: isLoadingMtbBearers(state),
		hasProductError: hasProductError(state),
		hasUpdateProductBearerError: hasUpdateProductBearerError(state),
		hasTransferError: hasTransferProductError(state),
		hasLoanError: hasLoanProductError(state),
		hasActivateError: hasActivateProductError(state),
	};
}

export default withTextContext(
	connect(mapStateToProps, {
		...travellerActions,
		...bearerActions,
		...productActions,
		push,
	})(TicketPage)
);
