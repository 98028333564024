import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { Main } from '../common/blocks/Main';
import { GroupTable } from './GroupPage';
import {
	actionCreators as orderActions,
	isLoadingFinishedTickets,
	getFinishedTickets,
} from '../../reducer/school/orders';
import { formatDate } from '../../utils/formatting';
import { ReactComponent as TicketImg } from '../../images/ticket.svg';
import { Loading } from '../common/blocks/Loading';
import { SCHOOL } from '../../Paths';
import OrderHistoryNavigation from './blocks/OrderHistoryNavigation';
import { Pagination } from '../common/elements/Pagination';
import withTextContext from '../../utils/withTextContext';
import { Banner } from '../common/blocks/Banner';

class FinishedTicketsHistoryPage extends Component {
	state = {
		currentPage: 0,
		pageSize: 30,
	};

	componentDidMount() {
		this.props.getFinishedTickets();
	}

	showOrderDetails = serialNumber => () => {
		this.props.push(
			SCHOOL.ORDER_HISTORY_FINISHED_TICKETS_DETAILS.replace(
				':finishedTicketSerialNumber',
				serialNumber
			)
		);
	};

	handlePageChange = newPage => this.setState({ currentPage: newPage - 1 });

	render() {
		const { finishedTickets, isLoadingFinishedTickets, texts } = this.props;
		const { currentPage, pageSize } = this.state;

		const sortedTickets = finishedTickets.sort((a, b) =>
			b.cancelledOn.localeCompare(a.cancelledOn)
		);

		let displayTickets = sortedTickets.slice(
			pageSize * currentPage,
			pageSize * currentPage + pageSize
		);

		return (
			<>
				<Banner
					bannerImg={texts.school.images.banners.orderhistory}
					showOnMobile={true}
				>
					<h2>Orderhistorik</h2>
					<p>Här visas ordrar på både resekort och biljetter</p>
				</Banner>
				<OrderHistoryNavigation />
				{isLoadingFinishedTickets && <Loading text="Laddar orderhistorik..." />}
				<Main>
					<Table>
						<thead>
							<tr>
								<th />
								<th>Elev</th>
								<th>Biljett</th>
								<th>Avslutad datum</th>
								<th>Avslutad av</th>
							</tr>
						</thead>

						<tbody>
							{displayTickets.map(ticket => (
								<tr
									key={ticket.serialNumber}
									onClick={this.showOrderDetails(ticket.serialNumber)}
								>
									<td>
										<TicketIcon />
									</td>
									<td className="nowrap">
										{ticket.firstName} {ticket.lastName}
									</td>
									<td>{ticket.ticketName}</td>
									<td>{formatDate(ticket.cancelledOn)}</td>
									<td>{ticket.cancelledBy}</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Pagination
						itemCount={finishedTickets.length}
						itemsPerPage={pageSize}
						onPageChange={this.handlePageChange}
					/>
				</Main>
			</>
		);
	}
}

const Table = styled(GroupTable)`
	th:first-of-type {
		min-width: 32px;
	}

	th:last-of-type {
		&:hover {
			cursor: pointer;
		}
	}

	th:last-of-type,
	td:last-of-type {
		text-align: center;
		label {
			float: none;
			margin: 0;
		}
	}
	.nowrap {
		white-space: nowrap;
	}
`;

const TicketIcon = styled(TicketImg)`
	width: 24px;
	height: 24px;
`;

export default withTextContext(
	connect(
		store => ({
			finishedTickets: getFinishedTickets(store),
			isLoadingFinishedTickets: isLoadingFinishedTickets(store),
		}),
		{
			...orderActions,
			push,
		}
	)(FinishedTicketsHistoryPage)
);
