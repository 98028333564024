import React from 'react';
import styled from 'styled-components';
import { Card } from '../../common/blocks/Card';

export function SignupBox(props) {
	return <Box {...props}>{props.children}</Box>;
}

const Box = styled(Card)`
	padding: 2em;
	margin-right: 2em;
	margin-left: 2em;
	margin: 0 auto;

	button {
		display: block;
		margin: 2em auto;
		width: 100%;
		max-width: 295px;
	}

	button + button {
		margin-top: 0;
	}
`;
