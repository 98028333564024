import React from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../elements/SecondaryButton';
import { CloseCardButton } from '../blocks/CloseCardButton';
import { PopUpBox } from '../elements/PopUpBox';
import { Error } from './Error';

export const ConfirmBox = props => {
	return (
		<PopUpBox>
			<CloseCardButton onClick={props.onClose} />
			<Title>{props.title}</Title>
			<InfoBox>{props.info}</InfoBox>
			<ButtonWrapper>
				<CancelButton onClick={props.onBack}>
					{props.cancelButtonText ? props.cancelButtonText : 'Tillbaka'}
				</CancelButton>
				<Button onClick={props.onContinue}>
					{props.confirmButtonText ? props.confirmButtonText : 'Gå vidare'}
				</Button>
			</ButtonWrapper>
			{props.hasError && <Error />}
		</PopUpBox>
	);
};

const Button = styled(SecondaryButton)`
	margin: 1em 1em;
`;
const CancelButton = styled(SecondaryButton)`
	margin: 1em 1em;
	background-color: white;
	color: gray;
	border: 1px solid gray;
`;
const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

const InfoBox = styled.div`
	display: flex;
	width: 100%;
	padding: 1em 0;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

const Title = styled.h4`
	font-weight: 600;
`;
