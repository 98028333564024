import React from 'react';
import styled from 'styled-components';
import { NextButton } from './../../common/elements/NextButton';

export function SelectedProductWrapper(props) {
	return (
		<MainWrapper {...props}>
			<Wrapper>
				<div className="innerWrapper">
					<GridLeft>
						<b>Varukorg</b>
						<div>{props.totalCart} st nya resekort</div>
					</GridLeft>

					<GridRight>
						<CartButton
							onClick={props.redirectToCart}
							disabled={props.totalCart === 0}
						>
							Gå till varukorg
						</CartButton>
					</GridRight>
				</div>
			</Wrapper>
		</MainWrapper>
	);
}

const Wrapper = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 900px;
	background-color: #ffffff;
	box-shadow: 0 -5px 13px 10px rgba(0, 0, 0, 0.05);
	display: flex;
	border-top-left-radius: 2em;
	border-top-right-radius: 2em;

	.innerWrapper {
		width: 100%;
		display: flex;
		align-items: center;
	}
`;

const MainWrapper = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1;
`;

const GridRight = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;
	align-self: flex-end;
	padding: 0.5em;
`;

const GridLeft = styled.div`
	margin-left: auto;
	margin-right: auto;
	div {
		margin: 0.25em auto;
	}
`;

const CartButton = styled(NextButton)`
	width: 300px;
	height: 56px;
	margin: 0.25em;
`;
