import {
	getTraveller,
	updateTraveller,
	updateTravellerReceiptEmail,
	verifyField,
	isExistingTraveller,
	getTravellerProducts,
	updateProductBearer,
	activateProduct,
	transferProduct,
	loanProduct,
	tryCreateProductSets,
	getBearers,
} from '../api';
import {
	getToken,
	hasPreferredBearer as hasPreferredBearerInStore,
	getBearers as getBearersFromStore,
} from '../reducer';
import cloneDeep from 'lodash/cloneDeep';
import { addonTags, productTags, travellerTags } from './products';
import { TYPES as BEARER_TYPES } from './bearer';

export const TRAVELLER_ERRORS = {
	UNDER_16: 'UNDER_16',
	GET_TRAVELLER: 'GET_TRAVELLER',
	UPDATE_TRAVELLER: 'UPDATE_TRAVELLER',
	VERIFY_PROPERTY: 'VERIFY_PROPERTY',
	GET_TRAVELLER_PRODUCTS: 'GET_TRAVELLER_PRODUCTS',
	UPDATE_PRODUCT_BEARER: 'UPDATE_PRODUCT_BEARER',
	ACTIVATE_PRODUCT: 'ACTIVATE_PRODUCT',
	TRANSFER_PRODUCT: 'TRANSFER_PRODUCT',
	LOAN_PRODUCT: 'LOAN_PRODUCT',
	CHECK_CREATE_PRODUCT_SET: 'CHECK_CREATE_PRODUCT_SET',
};

const initialState = {
	data: null,
	isLoading: false,
	errorMessage: '',
};

export const STORE_NAME = 'traveller';

export const actionCreators = {
	getTraveller: () => async (dispatch, getState) => {
		const state = getState();

		if (state.traveller.isLoading) {
			dispatch({ type: 'ABORT_GET_TRAVELLER_REQUEST' });
			return;
		}

		dispatch({ type: 'GET_TRAVELLER_REQUEST' });

		const token = getToken(state);

		try {
			const traveller = await getTraveller(token);
			dispatch({ type: 'GET_TRAVELLER_SUCCESS', traveller });
		} catch (error) {
			dispatch({
				type: 'GET_TRAVELLER_FAILURE',
				message: TRAVELLER_ERRORS.GET_TRAVELLER,
			});
		}
	},
	updateTraveller: updatedTraveller => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const traveller = await updateTraveller(token, updatedTraveller);
			dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
		} catch (error) {
			let message = TRAVELLER_ERRORS.UPDATE_TRAVELLER;

			if (error.message.match(/Resenären är under 16 år/)) {
				message = TRAVELLER_ERRORS.UNDER_16;
			}

			dispatch({ type: 'UPDATE_TRAVELLER_FAILURE', message });
		}
	},
	updateTravellerReceiptEmail: updatedTraveller => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			const traveller = await updateTravellerReceiptEmail(token, updatedTraveller);
			dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
		} catch (error) {
			const message = TRAVELLER_ERRORS.UPDATE_TRAVELLER;
			dispatch({ type: 'UPDATE_TRAVELLER_FAILURE', message });
		}
	},
	verifyField: verificationModel => async (dispatch, getState) => {
		dispatch({ type: 'VERIFY_PROPERTY_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = getTravellerId(state.traveller);

		try {
			let traveller = await verifyField(travellerId, verificationModel, token);
			dispatch({ type: 'VERIFY_PROPERTY_SUCCESS', traveller });
		} catch (error) {
			dispatch({
				type: 'VERIFY_PROPERTY_FAILURE',
				message: TRAVELLER_ERRORS.VERIFY_PROPERTY,
			});
		}
	},
	isExistingTraveller: personId => async dispatch => {
		dispatch({ type: 'GET_EXISTING_TRAVELLER_REQUEST' });

		let travellerExists = null;
		try {
			travellerExists = await isExistingTraveller(personId);
			dispatch({ type: 'GET_EXISTING_TRAVELLER_SUCCESS', travellerExists });
		} catch (error) {
			dispatch({
				type: 'GET_EXISTING_TRAVELLER_FAILURE',
				message: error.message,
			});
		}
	},
	getTravellerProducts: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_TRAVELLER_PRODUCTS_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const travellerId = getTravellerId(state.traveller);

		let products = null;
		try {
			products = await getTravellerProducts(token, travellerId);
			dispatch({ type: 'GET_TRAVELLER_PRODUCTS_SUCCESS', products });
			actionCreators.checkRepurchasableProductsets(products)(
				dispatch,
				getState
			);
		} catch (error) {
			dispatch({
				type: 'GET_TRAVELLER_PRODUCTS_FAILURE',
				message: TRAVELLER_ERRORS.GET_TRAVELLER_PRODUCTS,
			});
		}
	},
	checkRepurchasableProductsets: productSets => async (dispatch, getState) => {
		dispatch({ type: 'CHECK_CREATE_PRODUCT_SET_REQUEST' });

		const state = getState();
		const token = getToken(state);

		const uniqueProductSetConfigurations = [];
		const addedIds = new Map();
		for (const productSet of productSets) {
			if (!addedIds.has(productSet.productSetId)) {
				addedIds.set(productSet.productSetId, true);

				uniqueProductSetConfigurations.push({
					setId: productSet.productSetId,
					productSetConfiguration: {
						product: productSet.tags.find(productTags).name,
						travellers: productSet.tags.filter(travellerTags).map(t => t.name),
						addonTags: productSet.tags.filter(addonTags).map(t => t.name),
					},
				});
			}
		}

		try {
			const productSets = await tryCreateProductSets(
				token,
				uniqueProductSetConfigurations
			);
			dispatch({ type: 'CHECK_CREATE_PRODUCT_SET_SUCCESS', productSets });
		} catch (error) {
			dispatch({
				type: 'CHECK_CREATE_PRODUCT_SET_FAILURE',
				message: TRAVELLER_ERRORS.CHECK_CREATE_PRODUCT_SET,
			});
			throw error;
		}
	},
	updateProductBearer: (productId, bearerId) => async (dispatch, getState) => {
		dispatch({ type: 'UPDATE_PRODUCT_BEARER_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			await updateProductBearer(token, productId, bearerId);

			dispatch({ type: 'UPDATE_PRODUCT_BEARER_SUCCESS' });
		} catch (error) {
			dispatch({
				type: 'UPDATE_PRODUCT_BEARER_FAILURE',
				message: TRAVELLER_ERRORS.UPDATE_PRODUCT_BEARER,
			});
		}
	},
	activateProduct: productId => async (dispatch, getState) => {
		dispatch({ type: 'ACTIVATE_PRODUCT_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			await activateProduct(token, productId);
			dispatch({ type: 'ACTIVATE_PRODUCT_SUCCESS' });
		} catch (error) {
			dispatch({
				type: 'ACTIVATE_PRODUCT_FAILURE',
				message: TRAVELLER_ERRORS.ACTIVATE_PRODUCT,
			});
		}
	},
	transferProduct: (transferModel, productId) => async (dispatch, getState) => {
		dispatch({ type: 'TRANSFER_PRODUCT_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			await transferProduct(token, transferModel, productId);
			dispatch({ type: 'TRANSFER_PRODUCT_SUCCESS' });
		} catch (error) {
			dispatch({
				type: 'TRANSFER_PRODUCT_FAILURE',
				message: TRAVELLER_ERRORS.TRANSFER_PRODUCT,
			});
		}
	},
	loanProduct: (lendModel, productId) => async (dispatch, getState) => {
		dispatch({ type: 'LOAN_PRODUCT_REQUEST' });

		const state = getState();
		const token = getToken(state);

		try {
			await loanProduct(token, lendModel, productId);
			dispatch({ type: 'LOAN_PRODUCT_SUCCESS' });
		} catch (error) {
			dispatch({
				type: 'LOAN_PRODUCT_FAILURE',
				message: TRAVELLER_ERRORS.LOAN_PRODUCT,
			});
		}
	},
	updatePreferredBearer: () => async (dispatch, getState) => {
		const state = getState();

		setPreferredBearerToMobileAppIfExists(state, dispatch);
		setPreferredBearerIfMoreThanOneAndNoApp(state, dispatch)
	},
	setPreferredBearerOnBearerDelete: () => async (dispatch, getState) => {
		const state = getState();
		const token = getToken(state);

		const traveller = await getTraveller(token);
		const bearers = await getBearers(token);
		const hasAppBearer = (bearers || []).some((bearer) => bearer.type === BEARER_TYPES.SMARTPHONE);
		let appId = null;

		if (hasAppBearer) {
			const object = bearers.find(obj => obj.type === BEARER_TYPES.SMARTPHONE)
			appId = object.id;
		}

		const preferredBearerExistsInList = (bearers || []).some((bearer) => bearer.id === traveller.preferredMtbBearerId);

		if ((traveller.preferredMtbBearerId === null && bearers.length > 0) || (hasAppBearer && appId !== traveller.preferredMtbBearerId) || !preferredBearerExistsInList) {
			updatePreferredBearerOnBearerDelete(state, dispatch)
		}
	},
};

const setPreferredBearerToMobileAppIfExists = async (state, dispatch) => {

	const bearers = getBearersFromStore(state);

	if (bearers === null) return;

	const hasMobileApp = (bearers || []).some((bearer) => bearer.type === BEARER_TYPES.SMARTPHONE);
	if (!hasMobileApp) return;

	const object = bearers.find(obj => obj.type === BEARER_TYPES.SMARTPHONE)
	const preferredBearerId = state.traveller.data.preferredMtbBearerId;

	if (object === null || (preferredBearerId !== null && preferredBearerId === object.id)) return;

	const bearerId = object.id;
	const token = getToken(state);

	try {
		dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });
		const traveller = await updateTraveller(token, {
			preferredMtbBearerId: bearerId,
		});
		dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
	} catch (error) {
		dispatch({
			type: 'UPDATE_TRAVELLER_FAILURE',
			message: TRAVELLER_ERRORS.UPDATE_TRAVELLER,
		});
	}
};

const setPreferredBearerIfMoreThanOneAndNoApp = async (state, dispatch) => {

	if (hasPreferredBearerInStore(state)) return;

	if (!state) return;

	const preferredBearerExists = !!state.traveller.data.preferredMtbBearerId;

	if (preferredBearerExists) return;

	const bearers = getBearersFromStore(state);

	if (bearers.length < 2) return;

	const bearerId = bearers[0].id;
	const token = getToken(state);

	try {
		dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });
		const traveller = await updateTraveller(token, {
			preferredMtbBearerId: bearerId,
		});
		dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
	} catch (error) {
		dispatch({
			type: 'UPDATE_TRAVELLER_FAILURE',
			message: TRAVELLER_ERRORS.UPDATE_TRAVELLER,
		});
	}
};

const updatePreferredBearerOnBearerDelete = async (state, dispatch) => {

	const token = getToken(state);
	const bearers = await getBearers(token);

	if (bearers.length === 0) return;

	const hasAppBearer = (bearers || []).some((bearer) => bearer.type === BEARER_TYPES.SMARTPHONE);
	let bearerId = null;

	if (hasAppBearer) {
		const object = bearers.find(obj => obj.type === BEARER_TYPES.SMARTPHONE)
		console.log(object);
		bearerId = object.id;
	}
	else {
		bearerId = bearers[0].id;
	}
	
	try {
		dispatch({ type: 'UPDATE_TRAVELLER_REQUEST' });
		const traveller = await updateTraveller(token, {
			preferredMtbBearerId: bearerId,
		});
		dispatch({ type: 'UPDATE_TRAVELLER_SUCCESS', traveller });
	} catch (error) {
		dispatch({
			type: 'UPDATE_TRAVELLER_FAILURE',
			message: TRAVELLER_ERRORS.UPDATE_TRAVELLER,
		});
	}
}

export default function travellerReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_TRAVELLER_REQUEST':
		case 'UPDATE_TRAVELLER_REQUEST':
		case 'GET_EXISTING_TRAVELLER_REQUEST':
		case 'GET_EMAIL_TAKEN_REQUEST':
		case 'CREATE_EMAIL_TAKEN_REQUEST':
		case 'VERIFY_PROPERTY_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_TRAVELLER_PRODUCTS_REQUEST':
		case 'UPDATE_PRODUCT_BEARER_REQUEST':
		case 'ACTIVATE_PRODUCT_REQUEST':
			return {
				...state,
				isLoadingProducts: true,
				errorMessage: '',
			};
		case 'TRANSFER_PRODUCT_REQUEST':
		case 'LOAN_PRODUCT_REQUEST':
			return {
				...state,
				isLoadingProducts: true,
				errorMessage: '',
			};
		case 'GET_TRAVELLER_SUCCESS':
		case 'UPDATE_TRAVELLER_SUCCESS':
		case 'VERIFY_PROPERTY_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.traveller,
				errorMessage: '',
			};
		case 'GET_TRAVELLER_PRODUCTS_SUCCESS':
			return {
				...state,
				isLoadingProducts: false,
				products: action.products.map(p => ({
					...p,
					canBeRepurchased: null,
					repurchaseProductset: null,
				})),
				errorMessage: '',
			};
		case 'CHECK_CREATE_PRODUCT_SET_SUCCESS':
			const productsWithProductSets = cloneDeep(state.products).map(p =>
				action.productSets[p.productSetId]
					? {
							...p,
							canBeRepurchased: true,
							repurchaseProductset: action.productSets[p.productSetId],
					  }
					: { ...p, canBeRepurchased: false }
			);

			return {
				...state,
				isLoadingProducts: false,
				products: productsWithProductSets,
				errorMessage: '',
			};
		case 'UPDATE_PRODUCT_BEARER_SUCCESS':
		case 'ACTIVATE_PRODUCT_SUCCESS':
			return {
				...state,
				isLoadingProducts: false,
				products: action.products,
				errorMessage: '',
			};
		case 'REMOVE_CANCELED_TRAVELLER_PRODUCT':
			let products = cloneDeep(state.products);
			for (let i = 0; i < products.length; i++) {
				action.cancelledTransaction.items.forEach(item => {
					if (item?.mtbProductIds?.find(id => id === products[i]?.id)) {
						delete products[i];
					}
				});
			}
			return {
				...state,
				products,
			};
		case 'TRANSFER_PRODUCT_SUCCESS':
		case 'LOAN_PRODUCT_SUCCESS':
			return {
				...state,
				isLoadingProducts: false,
				errorMessage: '',
			};
		case 'GET_TRAVELLER_FAILURE':
		case 'UPDATE_TRAVELLER_FAILURE':
		case 'GET_EXISTING_TRAVELLER_FAILURE':
		case 'GET_EMAIL_TAKEN_FAILURE':
		case 'VERIFY_PROPERTY_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_TRAVELLER_PRODUCTS_FAILURE':
		case 'UPDATE_PRODUCT_BEARER_FAILURE':
		case 'ACTIVATE_PRODUCT_FAILURE':
			return {
				...state,
				isLoadingProducts: false,
				errorMessage: action.message,
			};
		case 'CHECK_CREATE_PRODUCT_SET_FAILURE':
			return { ...state, errorMessage: action.message };
		case 'TRANSFER_PRODUCT_FAILURE':
		case 'LOAN_PRODUCT_FAILURE':
			return {
				...state,
				isLoadingProducts: false,
				errorMessage: action.message,
			};
		default:
			return state;
	}
}

// Private selectors

export function getTravellerId(state) {
	return state.data.id;
}

export function getWalletId(state) {
	return state.data.walletId;
}

export function getCurrentTraveller(state) {
	return state.data;
}

export function hasGetTravellerError(state) {
	return state.errorMessage === TRAVELLER_ERRORS.GET_TRAVELLER;
}

export function hasGetTravellerProductsError(state) {
	return state.errorMessage === TRAVELLER_ERRORS.GET_TRAVELLER_PRODUCTS;
}

export function hasUpdateProductBearerError(state) {
	return state.errorMessage === TRAVELLER_ERRORS.UPDATE_PRODUCT_BEARER;
}

export function hasUpdateTravellerError(state) {
	return state.errorMessage === TRAVELLER_ERRORS.UPDATE_TRAVELLER;
}

export function hasTransferProductError(state) {
	return state.errorMessage === TRAVELLER_ERRORS.TRANSFER_PRODUCT;
}

export function hasLoanProductError(state) {
	return state.errorMessage === TRAVELLER_ERRORS.LOAN_PRODUCT;
}

export const hasActivateProductError = store =>
	store[STORE_NAME].errorMessage === TRAVELLER_ERRORS.ACTIVATE_PRODUCT;

export function hasVerifyError(state) {
	return state.errorMessage === TRAVELLER_ERRORS.VERIFY_PROPERTY;
}

export function isLoadingTraveller(state) {
	return state.isLoading;
}

export function isLoadingProducts(state) {
	return state.isLoadingProducts;
}

export function getProducts(state) {
	return state.products;
}
