import React from "react";
import styled from 'styled-components';

export const BaseButton = React.forwardRef((props, ref) => {
	return (
		<Button {...props} ref={ref || null}>{props.children}</Button>
	);
})

const Button = styled.button`
	color: ${props => props.theme.textOnPrimaryColor};
	font-weight: bold;
	border: 0;
	padding: 0.5em 3em;
	box-shadow: 0 0 6px 3px rgba(0,0,0,0.03);
	border-radius: 5px;

	&:hover {
		box-shadow: 0 0 12px 3px rgba(0,0,0,0.15);
	}

	&[disabled] {
		color: white;
		background-color: #464646;
		box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.15);
	}

	&[disabled]:hover {
		box-shadow: 0 0 6px 3px rgba(0,0,0,0.03);
	}
`
