import { getJourneys as fetchJourneys } from '../api';
import {
	getToken,
	getFirstDepartureDate as getFirstDepartureDateFromStore,
	getLastDepartureDate as getLastDepartureDateFromStore,
} from '../reducer';
import { isEqual, parse, addMinutes } from 'date-fns';

const initialState = {
	data: null,
	isLoading: false,
	errorMessage: '',
	rawXml: {},
	isLoadingEarlierJourneys: false,
	isLoadingLaterJourneys: false,
};

export const JOURNEY_LINE_TYPES = {
	WALK: 0,
	TRAIN: 4,
	BUS: 1,
	BUS_CITY: 2,
};

export const ERRORS = {
	GET_EARLIER_JOURNEYS: 'GET_EARLIER_JOURNEYS',
	GET_LATER_JOURNEYS: 'GET_LATER_JOURNEYS',
};

export const STORE_NAME = 'journey';

const NUMBER_OF_JOURNEYS = 4;

export const actionCreators = {
	getJourneys: searchModel => async (dispatch, getState) => {
		dispatch({ type: 'GET_JOURNEYS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		searchModel.journeysBefore = 0;
		searchModel.journeysAfter = NUMBER_OF_JOURNEYS;

		try {
			const journeyResult = await fetchJourneys(token, searchModel);
			dispatch({ type: 'GET_JOURNEYS_SUCCESS', journeyResult });
		} catch (error) {
			dispatch({ type: 'GET_JOURNEYS_FAILURE', message: error.message });
		}
	},
	getLaterJourneys: searchModel => async (dispatch, getState) => {
		dispatch({ type: 'GET_LATER_JOURNEYS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		searchModel.journeysBefore = 0;
		searchModel.journeysAfter = NUMBER_OF_JOURNEYS;
		searchModel.date = getLastDepartureDateFromStore(state);

		try {
			const journeyResult = await fetchJourneys(token, searchModel);
			dispatch({ type: 'GET_LATER_JOURNEYS_SUCCESS', journeyResult });
		} catch (error) {
			dispatch({
				type: 'GET_LATER_JOURNEYS_FAILURE',
				message: ERRORS.GET_LATER_JOURNEYS,
			});
		}
	},
	getEarlierJourneys: searchModel => async (dispatch, getState) => {
		dispatch({ type: 'GET_EARLIER_JOURNEYS_REQUEST' });

		const state = getState();
		const token = getToken(state);

		searchModel.journeysBefore = NUMBER_OF_JOURNEYS;
		searchModel.journeysAfter = 0;
		searchModel.date = getFirstDepartureDateFromStore(state);

		try {
			const journeyResult = await fetchJourneys(token, searchModel);
			dispatch({ type: 'GET_EARLIER_JOURNEYS_SUCCESS', journeyResult });
		} catch (error) {
			dispatch({
				type: 'GET_EARLIER_JOURNEYS_FAILURE',
				message: ERRORS.GET_EARLIER_JOURNEYS,
			});
		}
	},
	removeJourneys: () => dispatch => {
		dispatch({ type: 'REMOVE_JOURNEYS' });
	},
};

export default function journeyReducer(state = initialState, action) {
	switch (action.type) {
		case 'GET_JOURNEYS_REQUEST':
			return {
				...state,
				isLoading: true,
			};
		case 'GET_LATER_JOURNEYS_REQUEST':
			return {
				...state,
				isLoadingLaterJourneys: true,
			};
		case 'GET_EARLIER_JOURNEYS_REQUEST':
			return {
				...state,
				isLoadingEarlierJourneys: true,
			};
		case 'GET_JOURNEYS_SUCCESS':
			return {
				...state,
				isLoading: false,
				data: action.journeyResult.journeys,
				rawXml: {
					...state.rawXml,
					[action.journeyResult.journeyResultKey]: action.journeyResult.rawXml,
				},
				rawJson: {
					...state.rawJson,
					[action.journeyResult.journeyResultKey]: JSON.parse(
						action.journeyResult.rawJson
					),
				},
			};
		case 'GET_LATER_JOURNEYS_SUCCESS':
			let isSameAsLastResult = isSameLastResult(
				state,
				action.journeyResult.journeys
			);

			if (isSameAsLastResult) {
				return { ...state, isLoading: false };
			}

			return {
				...state,
				isLoadingLaterJourneys: false,
				data: [...state.data, ...action.journeyResult.journeys],
				rawXml: {
					...state.rawXml,
					[action.journeyResult.journeyResultKey]: action.journeyResult.rawXml,
				},
				rawJson: {
					...state.rawJson,
					[action.journeyResult.journeyResultKey]: JSON.parse(
						action.journeyResult.rawJson
					),
				},
			};
		case 'GET_EARLIER_JOURNEYS_SUCCESS':
			let isSameAsFirstResult = isSameFirstResult(
				state,
				action.journeyResult.journeys
			);

			if (isSameAsFirstResult) {
				return { ...state, isLoading: false };
			}

			return {
				...state,
				isLoadingEarlierJourneys: false,
				data: [...action.journeyResult.journeys, ...state.data],
				rawXml: {
					...state.rawXml,
					[action.journeyResult.journeyResultKey]: action.journeyResult.rawXml,
				},
				rawJson: {
					...state.rawJson,
					[action.journeyResult.journeyResultKey]: JSON.parse(
						action.journeyResult.rawJson
					),
				},
			};
		case 'GET_JOURNEYS_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_LATER_JOURNEYS_FAILURE':
			return {
				...state,
				isLoadingLaterJourneys: false,
				errorMessage: action.message,
			};
		case 'GET_EARLIER_JOURNEYS_FAILURE':
			return {
				...state,
				isLoadingEarlierJourneys: false,
				errorMessage: action.message,
			};
		case 'REMOVE_JOURNEYS':
			return {
				...initialState,
			};
		default:
			return state;
	}
}

// Private selectors

export function getJourneys(state) {
	return state.data;
}

export function isLoadingJourneys(state) {
	return state.isLoading;
}

export const isLoadingLaterJourneys = store =>
	store[STORE_NAME].isLoadingLaterJourneys;

export const isLoadingEarlierJourneys = store =>
	store[STORE_NAME].isLoadingEarlierJourneys;

export const hasLaterJourneysError = store =>
	store[STORE_NAME].errorMessage === ERRORS.GET_LATER_JOURNEYS;

export const hasEarlierJourneysError = store =>
	store[STORE_NAME].errorMessage === ERRORS.GET_EARLIER_JOURNEYS;

export const isSameFirstResult = (state, result) => {
	const firstJourney = state.data[0];
	const firstResult = result[0];
	return (
		isEqual(firstJourney.departure, firstResult.departure) &&
		isEqual(firstJourney.arrival, firstResult.arrival)
	);
};

export const isSameLastResult = (state, result) => {
	const lastJourney = state.data[state.data.length - 1];
	const lastResult = result[result.length - 1];
	return (
		isEqual(lastJourney.departure, lastResult.departure) &&
		isEqual(lastJourney.arrival, lastResult.arrival)
	);
};

export const getRoutesInfo = routes => {
	return routes
		.filter(r => r.typeId !== JOURNEY_LINE_TYPES.WALK)
		.map(r => ({
			arrivalTime: r.arrival,
			departureTime: r.departure,
			departureName: r.from,
			destinationName: r.to,
			operator: {
				operatorId: r.line.operatorId.toString(),
				operatorName: r.line.operatorName,
			},
			line: {
				lineName: r.line.name,
				lineNo: r.line.number.toString(),
				type: r.line.typeName,
				typeId: r.line.typeId.toString(),
			},
			run: {
				run: r.line.runNo.toString(),
			},
		}));
};

export const getFirstDepartureDate = state =>
	parse(state.data[0].departure).toISOString();

export const getLastDepartureDate = state => {
	const lastDeparture = parse(state.data[state.data.length - 1].departure);
	return addMinutes(lastDeparture, 1).toISOString(); // add 1 minute to get next departure
};

export const getRawXmlJourneyResult = (state, key) => state.rawXml[key];
