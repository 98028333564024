import { isSchool } from '../utils/helperFunctions';

export const STORAGE_KEY = isSchool()
	? 'KOLLO_WEB_SCHOOL_STATE'
	: 'KOLLO_WEB_STATE';

export const loadState = () => {
	try {
		const serializedState = sessionStorage.getItem(STORAGE_KEY);

		if (serializedState === null) {
			// No state found
			return undefined;
		}

		return JSON.parse(serializedState);
	} catch (error) {
		// Session storage not available (privacy mode, legacy browsers)
		return undefined;
	}
};

export const saveState = state => {
	try {
		const serializedState = JSON.stringify(state);
		sessionStorage.setItem(STORAGE_KEY, serializedState);
	} catch (error) {
		// Ignore write errors
	}
};

export const clearState = () => {
	try {
		sessionStorage.removeItem(STORAGE_KEY);
	} catch (error) {
		// Ignore
	}
};
