import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { Error } from '../../common/elements/Error';
import { TextInput } from '../../common/elements/TextInput';
import { Validation, regexToInputPattern } from '../../../utils/validation';
import { ProfileCardHeader } from './ProfileCardHeader';

export class DeliveryAddressForm extends React.Component {
	static propTypes = {
		onUpdate: PropTypes.func.isRequired,
		address: PropTypes.shape({
			careOf: PropTypes.string,
			streetAddress: PropTypes.string,
			postalCode: PropTypes.string,
			locality: PropTypes.string,
		}),
		name: PropTypes.string,
		showError: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		const { address, name } = props;

		this.state = {
			careOf: address && address.careOf ? address.careOf : '',
			streetAddress:
				address && address.streetAddress ? address.streetAddress : '',
			postalCode: address && address.postalCode ? address.postalCode : '',
			locality: address && address.locality ? address.locality : '',
			name: name ? name : '',
		};
	}

	handleChange = target => {
		this.setState({ [target.id]: target.value });
	};

	updateAddress = () => {
		this.props.onUpdate(this.state);
	};

	saveButtonDisabled = () => {
		const { careOf, streetAddress, postalCode, locality, name } = this.state;

		if (!this.props.address) return true;

		let {
			careOf: oldCareOf = '',
			streetAddress: oldStreetAddress = '',
			postalCode: oldPostalCode = '',
			locality: oldLocality = '',
		} = this.props.address;
		let oldName = this.props.name;

		if (name.length === 0) return true;

		if (
			careOf === oldCareOf &&
			streetAddress === oldStreetAddress &&
			postalCode === oldPostalCode &&
			locality === oldLocality &&
			name === oldName
		) {
			return true;
		}

		if (postalCode.length > 0 && !this.isValidPostalCode(postalCode))
			return true;

		return false;
	};

	setUndefinedAsEmptyString = value => {
		if (value === undefined) return '';
		return value;
	};

	isValidPostalCode = postalCode =>
		Validation.postalCode.test(postalCode) &&
		regexToInputPattern(postalCode).length === 5;

	render() {
		const { showError, informationText } = this.props;

		return (
			<div className="form-group">
				<ProfileCardHeader>Mina adressuppgifter</ProfileCardHeader>
				<FormSubHeader className="help-block">
					{informationText}
				</FormSubHeader>
				<div className="form-group">
					<FullWidthTextInput
						type="text"
						value={this.state.name}
						onChange={this.handleChange}
						id="name"
						placeholder="Förnamn och Efternamn*"
						handleChange={this.handleChange}
						autoComplete="name"
						errorMessage="Vänligen se till att skriva ett korrekt namn."
					/>
				</div>
				<div className="form-group">
					<FullWidthTextInput
						type="text"
						value={this.state.careOf}
						onChange={this.handleChange}
						id="careOf"
						placeholder="C/o"
						handleChange={this.handleChange}
						autoComplete="on"
					/>
				</div>
				<div className="form-group">
					<FullWidthTextInput
						type="text"
						value={this.state.streetAddress}
						onChange={this.handleChange}
						id="streetAddress"
						placeholder="Adress"
						handleChange={this.handleChange}
						autoComplete="street-address"
					/>
				</div>
				<div className="form-group">
					<FullWidthTextInput
						type="number"
						value={this.state.postalCode}
						onChange={this.handleChange}
						id="postalCode"
						placeholder="Postnummer"
						handleChange={this.handleChange}
						pattern={regexToInputPattern(Validation.postalCode)}
						validateOnChange={true}
						autoComplete="postal-code"
					/>
				</div>
				<div className="form-group">
					<FullWidthTextInput
						type="text"
						value={this.state.locality}
						onChange={this.handleChange}
						id="locality"
						placeholder="Ort"
						handleChange={this.handleChange}
						pattern={regexToInputPattern(Validation.city)}
						validateOnChange={true}
						autoComplete="locality"
					/>
				</div>
				<SecondaryButton
					disabled={this.saveButtonDisabled()}
					onClick={this.updateAddress}
				>
					Spara
				</SecondaryButton>
				{showError && <Error />}
			</div>
		);
	}
}

const FormSubHeader = styled.h3`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 12px;
	line-height: 17px;
	font-weight: bold;
	margin: 8px 0 30px;
`;

const FullWidthTextInput = styled(TextInput)`
	width: 100%;
`;
