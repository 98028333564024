import React, { Component } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../../../images/chevron-right.svg';
import { LinkButton } from '../elements/LinkButton';
import { groupByFour } from '../../../utils/formatting';

export class TravelCard extends Component {
	confirmDeleteBearer = () => {
		const { bearer } = this.props;
		this.props.onConfirmDeleteBearer(bearer);
	};

	render() {
		const { bearer, onConfirmDeleteBearer } = this.props;

		const hasSerial = bearer.serial && bearer.serial.length > 0;

		return (
			<TravelCardBox>
				<BearerName>{bearer.name}</BearerName>
				{hasSerial && <BearerSerial>{groupByFour(bearer.serial)}</BearerSerial>}

				{onConfirmDeleteBearer && (
					<DeregisterLinkButton onClick={this.confirmDeleteBearer}>
						Radera resekort{' '}
						<ChevronRightIcon
							className="svg-icon"
							aria-hidden="true"
							focusable="true"
							alt=""
						/>
					</DeregisterLinkButton>
				)}
			</TravelCardBox>
		);
	}
}

const DeregisterLinkButton = styled(LinkButton)`
	align-self: center;
	font-weight: 400;
	color: ${props => props.theme.textOnPrimaryColor};
`;

const ChevronRightIcon = styled(ChevronRight)`
	fill: none;
	stroke: ${props => props.theme.textOnPrimaryColor};
`;

const TravelCardBox = styled.div`
	height: 100%;
	border-radius: 7px;
	background-color: ${props => props.theme.primary_color};
	box-shadow: 0 25px 40px 2px rgba(0, 0, 0, 0.22);
	text-align: center;
	padding: 1em;
	display: flex;
	flex-direction: column;
	justify-content: center;

	h4 {
		color: ${props => props.theme.textOnPrimaryColor};
	}
`;

const BearerName = styled.h4`
	color: ${props => props.theme.textOnPrimaryColor};
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
	margin-top: 0;
`;

const BearerSerial = styled.p`
	color: ${props => props.theme.textOnPrimaryColor};
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
`;
