import React, { Component } from 'react';
import { ReactComponent as Arrow } from '../../../images/back.svg';
import {
	SpanRow,
	InfoSpan,
	ValidationError,
	EmailOrPhoneInput,
	ActionButton,
	ConfirmationText,
	CallToActionButton,
	TransferTicketFormRow,
	ConfirmationFormRow,
	BackButton,
	TransferTicketCallToActionButton
} from './Ticket';
import { Validation } from '../../../utils/validation';
import { ActionInfo } from './ActionInfoText';

export class LendTicketForm extends Component {
	state = {
		emailOrPhone: '',
		disableButton: true,
		showConfirmation: false,
		errorMessage: '',
	};

	handleChange = e => {
		const emailOrPhone = e.value;
		const state = { emailOrPhone };

		if (
			Validation.mobile.test(emailOrPhone) ||
			Validation.email.test(emailOrPhone)
		) {
			state.disableButton = false;
			state.errorMessage = '';
		} else {
			state.disableButton = true;
			state.errorMessage = 'E-post eller mobilnummer är angett i fel format. Ange e-post (exemple.exemple@mejl.com) eller mobilnummer (46701234567)';
		}

		this.setState(state);
	};

	lendTicket = () => {
		const { product } = this.props;
		const input = this.state.emailOrPhone;
		let lendModel = {};

		if (Validation.mobile.test(input)) {
			lendModel.phoneNumber = input;
		} else if (Validation.email.test(input)) {
			lendModel.email = input;
		}

		this.props.onLendTicket(lendModel, product.id);
	};

	toggleConfirmation = () => {
		this.setState({ showConfirmation: true });
	};

	cancel = () => {
		this.setState(
			{
				showConfirmation: false,
				emailOrPhone: '',
				disableButton: true,
				errorMessage: '',
			},
			this.props.onBackClick
		);
	};

	enterPressed = e => {
		if (e.key === 'Enter') {
			this.toggleConfirmation();
		}
	};

	render() {
		const {
			disableButton,
			showConfirmation,
			emailOrPhone,
			errorMessage,
		} = this.state;

		if (showConfirmation) {
			return (
				<>
					<SpanRow>
						<Arrow aria-hidden="true" focusable="false" alt="" />
						<span>Låna ut biljett</span>
					</SpanRow>
					<ConfirmationText>
						{this.props.texts.private.ticketpage.tickets.lendticketconfirmation}{' '}
						{emailOrPhone} ?
					</ConfirmationText>
					<ConfirmationFormRow>
						<CallToActionButton onClick={this.lendTicket}>
							{this.props.texts.private.ticketpage.tickets.lendticketaccept}
						</CallToActionButton>
						<ActionButton onClick={this.cancel}>Avbryt</ActionButton>
					</ConfirmationFormRow>
					<BackButton onClick={this.cancel}>&lsaquo; Tillbaka</BackButton>
				</>
			);
		}
		return (
			<>
				<SpanRow>
					<Arrow aria-hidden="true" focusable="false" alt="" />
					<span>Låna ut biljett</span>
				</SpanRow>
				<ActionInfo style={{ fontSize: '13px' }}>
					{this.props.texts.private.ticketpage.tickets.lendticketinformation}
				</ActionInfo>
				<InfoSpan>
					<span>
						{this.props.texts.private.ticketpage.tickets.emailorphone}{' '}
					</span>
					<span>*</span>
				</InfoSpan>
				<ValidationError>{errorMessage}</ValidationError>
				<TransferTicketFormRow>
					<EmailOrPhoneInput
						type="text"
						value={emailOrPhone}
						handleChange={this.handleChange}
						onKeyPress={this.enterPressed}
						// errorMessage={errorMessage || ""}
						// isError={errorMessage.length > 0}
					/>
					<TransferTicketCallToActionButton
						disabled={disableButton}
						onClick={this.toggleConfirmation}
					>
						Låna ut
					</TransferTicketCallToActionButton>
				</TransferTicketFormRow>
				<BackButton whiteSpace onClick={this.cancel}>&lsaquo; Tillbaka</BackButton>
			</>
		);
	}
}

