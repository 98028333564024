import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as TicketImg } from '../../../images/ticket.svg';
import { ReactComponent as CardImg } from '../../../images/card.svg';
import { ReactComponent as MobileImg } from '../../../images/mobile.svg';
import { PRIVATE } from '../../../Paths';
import { forSmallPhoneOnly } from '../../../utils/mediaqueries';
import withTextContext from '../../../utils/withTextContext';

export class TicketNavigation extends Component {
	getIcon = path => {
		switch (path) {
			case PRIVATE.TICKET:
				return (
					<TicketIcon
						className="svg-icon"
						aria-hidden="true"
						focusable="false"
					/>
				);
			case PRIVATE.TRAVEL_CARD:
				return (
					<CardIcon className="svg-icon" aria-hidden="true" focusable="false" />
				);
			case PRIVATE.MOBILE_INFO:
				return (
					<MobileIcon
						className="svg-icon"
						aria-hidden="true"
						focusable="false"
					/>
				);
			default: {
				return (
					<TicketIcon
						className="svg-icon"
						aria-hidden="true"
						focusable="false"
					/>
				);
			}
		}
	};

	renderNavLink = (path, text) => {
		const { location } = this.props;
		let pathname = location.pathname;
		return (
			<NavItem exact to={path} activeClassName="active">
				{this.getIcon(path)}
				{pathname === path ? (
					<NavTextActive aria-live="polite" aria-atomic="true">{text}</NavTextActive>
				) : (
					<NavTextInactive>{text}</NavTextInactive>
				)}
			</NavItem>
		);
	};

	render() {
		return (
			<Text {...this.props}>
				<TextList>{this.renderNavLink(PRIVATE.TICKET, 'Biljetter')}</TextList>
				<TextList>{this.renderNavLink(PRIVATE.TRAVEL_CARD, 'Resekort')}</TextList>
				<TextList>{this.renderNavLink(PRIVATE.MOBILE_INFO, 'Reseapp')}</TextList>
			</Text>
		);
	}
}

const NavItem = styled(NavLink)`
	:hover {
		text-decoration: none;
	}
`;

const Text = styled.ul`
	text-align: center;
	display: block;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;

	a {
		display: inline-block;
		color: ${props => props.theme.dark_grey};
	}

	li + li {
		margin-left: 2em;
	}

	${forSmallPhoneOnly`
		li + li {
			margin-left: 1em;
		}
	`}
`;

const TextList = styled.li`
display: inline;
`;

const TicketIcon = styled(TicketImg)`
	width: 20px;
	height: 20px;
`;

const CardIcon = styled(CardImg)`
	width: 20px;
	height: 20px;
`;

const MobileIcon = styled(MobileImg)`
	width: 20px;
	height: 20px;
`;

const NavText = styled.h3`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 0;

	:hover {
		border-bottom: 1px solid ${props => props.theme.primary_color};
	}
`;

const NavTextActive = styled(NavText)`
	border-bottom: 1px solid ${props => props.theme.primary_color};
	padding-bottom: 2px;
`;

const NavTextInactive = styled(NavText)`
	font-weight: 300;
`;

export default withTextContext(
	connect(({ router }) => ({ location: router.location }))(TicketNavigation)
);
