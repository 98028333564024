import { createStore } from 'redux';
import throttle from 'lodash/throttle';
import { createRootReducer, getRegisteredPaymentMethodId } from '../reducer';
import { configureMiddleware } from '../middleware';
import { history } from './history';
import { loadState, saveState, clearState } from './persistance';
import { isSchool } from '../utils/helperFunctions';
import { getSchool } from '../reducer/school';

const persistedState = loadState();
const initialState = persistedState || {};

export const store = createStore(
	createRootReducer(history),
	initialState,
	configureMiddleware
);

const getStateToBePersisted = state => {
	return isSchool()
		? {
				school: { school: getSchool(state) },
		  }
		: {
				cart: state.cart,
				wallet: {
					registeredPaymentMethodId: getRegisteredPaymentMethodId(state),
				},
		  };
};

export const persistStore = () => {
	const state = store.getState();
	saveState(getStateToBePersisted(state));
};

store.subscribe(throttle(persistStore, 1000));

export const clearStore = () => clearState();
