import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SCHOOL } from '../../../Paths';
import { forMediumTabletLandscapeUp } from '../../../utils/mediaqueries';
import { connect } from 'react-redux';
import { themeConfig as regionConfig } from '../../../config';

class SchoolDesktopNavigation extends Component {
	render() {
		const { isOrgAdmin } = this.props;
		const showCulturalTrips = regionConfig.showCulturalTrips;
		return (
			<DesktopMenuItems>
				<Link to={SCHOOL.ORDER} activeClassName="active">
					Beställ
				</Link>
				<Link to={SCHOOL.GROUPS} activeClassName="active">
					Elever &amp; Grupper
				</Link>
				<Link to={SCHOOL.ORDER_HISTORY_TICKETS} activeClassName="active">
					Orderhistorik
				</Link>
				{showCulturalTrips && (
					<Link to={SCHOOL.CULTURE_TRIP} activeClassName="active">
						Kulturresor
					</Link>
				)}
				{isOrgAdmin && (
					<Link to={SCHOOL.ADMINS} activeClassName="active">
						Administratörer
					</Link>
				)}
			</DesktopMenuItems>
		);
	}
}

const DesktopMenuItems = styled.div`
	flex-grow: 1;
	text-align: center;
	display: none;

	${forMediumTabletLandscapeUp`
		display: block;
	`}
`;

const Link = styled(NavLink)`
	color: black;
	text-decoration: none;

	&:hover,
	&:focus,
	&.active {
		color: black;
		text-decoration: none;
		border-bottom: solid 2px;
	}

	& + & {
		margin-left: 2em;
	}
`;

//Connect is needed for navlink to update after redirect from /callback
export default connect(({ router }) => ({ location: router.location }))(
	SchoolDesktopNavigation
);
