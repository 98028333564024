import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as TicketImg } from '../../../images/ticket.svg';
import { ReactComponent as CardImg } from '../../../images/card.svg';
import { SCHOOL } from '../../../Paths';
import { forSmallPhoneOnly } from '../../../utils/mediaqueries';

class OrderHistoryNavigation extends Component {
	render() {
		return (
			<Text {...this.props}>
				<NavItem
					exact
					to={SCHOOL.ORDER_HISTORY_TICKETS}
					activeClassName="active"
				>
					<TicketIcon
						className="svg-icon"
						aria-hidden="true"
						focusable="false"
					/>
					<NavText>Orderhistorik biljetter</NavText>
				</NavItem>
				<NavItem
					exact
					to={SCHOOL.ORDER_HISTORY_TRAVELCARD}
					activeClassName="active"
				>
					<CardIcon className="svg-icon" aria-hidden="true" focusable="false" />
					<NavText>Orderhistorik resekort</NavText>
				</NavItem>
				<NavItem
					exact
					to={SCHOOL.ORDER_HISTORY_FINISHED_TICKETS}
					activeClassName="active"
				>
					<TicketIcon
						className="svg-icon"
						aria-hidden="true"
						focusable="false"
					/>
					<NavText>Avslutade biljetter</NavText>
				</NavItem>
			</Text>
		);
	}
}

const NavItem = styled(NavLink)`
	:hover {
		text-decoration: none;
	}
	&:not(.active) h4 {
		font-weight: 300;
	}
	&.active h4,
	:hover h4 {
		border-bottom: 1px solid ${props => props.theme.primary_color};
		padding-bottom: 2px;
	}
`;

const NavText = styled.h4`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 0;
`;

const Text = styled.div`
	text-align: center;
	display: block;
	width: 100%;
	margin-top: 3em;

	a {
		display: inline-block;
		color: ${props => props.theme.dark_grey};
	}

	a + a {
		margin-left: 2em;
	}

	${forSmallPhoneOnly`
		a + a {
			margin-left: 1em;
		}
	`}
`;

const TicketIcon = styled(TicketImg)`
	width: 20px;
	height: 20px;
`;

const CardIcon = styled(CardImg)`
	width: 20px;
	height: 20px;
`;

export default connect(({ router }) => ({ location: router.location }))(
	OrderHistoryNavigation
);
