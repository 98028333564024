import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import withTextContext from '../../../utils/withTextContext';
import { actionCreators as travellerActions } from '../../../reducer/traveller';
import { getTraveller } from '../../../reducer';
import { TextInput } from '../../common/elements/TextInput';

export class ReceiptEmail extends Component {
	handleChangeReciptEmail = e => {
		const email = e.value;
		this.props.setTravellerReceiptEmail(email);
	};

	render() {
		const { travellerEmail, isError, errorMessage } = this.props;

		return (
			<>
				<InputEmailReceipt
					type="text"
					handleChange={this.handleChangeReciptEmail}
					value={travellerEmail || ''}
					placeholder="Ange e-post"
					errorMessage={travellerEmail?.length > 0 ? errorMessage : ""}
					isError={isError}
					autoComplete="email"
					helperText="exemple.exemple@mejl.com"
				/>
			</>
		);
	}
}

const InputEmailReceipt = styled(TextInput)`
	min-width: 55px;
	width: 100%;
	font-size: 16px;
	margin-bottom: 2.5em;
`;

export default withTextContext(
	connect(
		store => ({
			traveller: getTraveller(store),
		}),
		{ ...travellerActions }
	)(ReceiptEmail)
);
