import React from 'react';
import styled from 'styled-components';

export const DefinitionList = ({ children, ...props }) => (
	<DL {...props}>{children}</DL>
);

const DL = styled.dl`
	dt,
	dd {
		width: 50%;
		display: inline-block;
		vertical-align: text-top;
		margin-bottom: 1em;
	}
`;
