import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Main } from '../common/blocks/Main';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import { getLocationState } from '../../reducer';
import { actionCreators as groupActions } from '../../reducer/groups';
import {
	actionCreators as studentActions,
	studentHasTickets,
	getStudentTicketText,
	isLoadingStudentTickets,
	getStudentBearers,
	referredAccountsLoaded,
} from '../../reducer/students';
import { actionCreators as schoolActions } from '../../reducer/school';
import { Dropdown } from '../common/elements/Dropdown';
import { GroupInfo } from './blocks/GroupInfo';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import {
	getAllGroups,
	isLoadingGroups,
	getStudentsByIds,
	hasStudentsForGroup,
	isLoadingStudentBearers,
	isLoadingStudents,
	getStudentIdsInGroup,
	getAllTicketOrderProducts,
	hasGroupError,
	hasStudentsError,
	hasLoadTicketOrderProductsError,
} from '../../reducer';
import { Loading } from '../common/blocks/Loading';
import withTextContext from '../../utils/withTextContext';
import { SCHOOL } from '../../Paths';
import { SelectedTicketWrapper } from './blocks/SelectedTicketWrapper';
import { Error } from '../common/elements/Error';
import TicketPageFilterContainer, {
	RADIOBUTTON_FILTER_CHOICES,
} from './blocks/TicketPageFilterContainer';
import { GroupTable } from './GroupPage';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { Label } from '../common/elements/Label';
import { Checkbox } from '../common/elements/Checkbox';
import { unique } from '../../utils/helperFunctions';

class OrderTicketPage extends Component {
	state = {
		checkAll: false,
		selectedGroup: { name: 'Välj grupp', groupId: '-1' },
		selectedTicketType: null,
		selectedTicketOrderProduct: null,
		selectedStudents: {},
		ticketOrderRows: [],
		selectedRadiobuttonFilter: RADIOBUTTON_FILTER_CHOICES.NO_TICKETS,
		expandTicketSummary: true,
	};

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps, prevState) {
		const { hasReferredAccounts } = this.props;
		const { selectedGroup } = this.state;
		const { groupId } = selectedGroup;

		if (
			selectedGroup.groupId !== '-1' &&
			prevState.selectedGroup.groupId !== selectedGroup.groupId &&
			!hasReferredAccounts
		) {
			(async () => {
				await this.props.getGroupReferredAccounts(groupId);
				this.props.getGroupReferredAccountTickets(groupId);
			})();
		}
	}

	loadData = async () => {
		const { groups } = this.props;

		if (isEmpty(groups)) {
			this.props.getGroups();
		}

		this.props.getTicketOrderProducts();
	}

	redirectToConfirmOrder = () =>
		this.props.push(SCHOOL.CONFIRM_TICKET_ORDER, {
			selectedGroup: this.state.selectedGroup,
			ticketOrderRows: this.state.ticketOrderRows,
		});

	onSelectGroup = selectedGroup =>
		this.setState({ selectedGroup }, () => this.loadGroupData(selectedGroup));

	onSelectTicketType = selectedTicketType =>
		this.setState({
			selectedTicketType,
			selectedStudents: {},
			checkAll: false,
		});

	loadGroupData = async selectedGroup => {
		const groupId = selectedGroup.groupId || '-1';

		const { hasStudentsInGroup } = this.props;

		if (groupId !== '-1') {
			if (!hasStudentsInGroup(groupId)) {
				await this.props.loadStudentsInGroup(groupId);
			}

			if (!this.props.hasStudentsError) {
				const studentIds = this.props.getStudentIdsInGroup(groupId);
				await this.props.getTicketsForStudentIds(studentIds);
			}
		}

		groupId !== '-1'
			? this.setState({ selectedGroup })
			: this.setState({
					selectedGroup,
					checkAll: false,
					selectedStudents: {},
			  });
	};

	onSelectTicketOrderProduct = item => {
		this.setState({ selectedTicketOrderProduct: item });
	};

	handleCheckboxClick = (name, value) => {
		let { selectedStudents } = this.state;

		const updatedSelectStudents = { ...selectedStudents, [name]: value };
		const checkAll = Object.keys(updatedSelectStudents).every(
			id => updatedSelectStudents[id]
		);

		this.setState({
			selectedStudents: updatedSelectStudents,
			checkAll,
		});
	};

	getFilteredStudents = () => {
		const {
			selectedRadiobuttonFilter,
			ticketOrderRows,
			selectedTicketType,
		} = this.state;
		const {
			getStudentIdsInGroup,
			getStudentsByIds,
			studentHasTickets,
		} = this.props;
		const { groupId } = this.state.selectedGroup;

		let studentIds = getStudentIdsInGroup(groupId);
		let studentsInGroup = getStudentsByIds(studentIds);

		const assignedStudents = unique(ticketOrderRows.map(t => t.contactId));

		let filteredStudents =
			selectedRadiobuttonFilter === RADIOBUTTON_FILTER_CHOICES.NO_TICKETS
				? studentsInGroup.filter(
						s =>
							!studentHasTickets(s.travellerId) &&
							!assignedStudents.includes(s.contactId)
				  )
				: studentsInGroup.filter(
						s =>
							studentHasTickets(s.travellerId) &&
							!assignedStudents.includes(s.contactId)
				  );

		if (selectedTicketType && selectedTicketType.id !== 'ALL') {
			let typeName = selectedTicketType.id !== 'NONE' ? selectedTicketType.title : '';

			filteredStudents = filteredStudents.filter(
				s => s.ticketType === typeName
			);
		}

		return filteredStudents;
	};

	onCheckAllBoxes = () => {
		const filteredStudents = this.getFilteredStudents();
		this.setState(state => ({
			checkAll: !state.checkAll,
			selectedStudents: Object.values(filteredStudents).reduce((o, student) => {
				return { ...o, [student.travellerId]: !state.checkAll };
			}, {}),
		}));
	};

	assignTicketsToStudents = () => {
		const { getStudentIdsInGroup, getStudentsByIds } = this.props;
		const { groupId } = this.state.selectedGroup;
		const { selectedStudents, selectedTicketOrderProduct } = this.state;
		const productId = selectedTicketOrderProduct.productId;

		const studentIds = getStudentIdsInGroup(groupId);
		const studentsInGroup = getStudentsByIds(studentIds);

		const ticketOrderRows = Object.keys(selectedStudents).reduce(
			(rows, travellerId) => {
				if (selectedStudents[travellerId] === true) {
					const contactId = studentsInGroup.find(
						s => s.travellerId === travellerId
					).contactId;
					rows.push({ productId, contactId });
				}
				return rows;
			},
			[]
		);
		this.setState({
			ticketOrderRows: [...this.state.ticketOrderRows, ...ticketOrderRows],
			selectedStudents: {},
			checkAll: false,
		});
	};

	onSelectRadiobuttonFilter = choice =>
		this.setState({
			selectedRadiobuttonFilter: choice,
			selectedStudents: {},
			checkAll: false,
		});

	goBack = () => {
		if (this.props.history) {
			this.props.history.goBack();
		} else this.props.push(SCHOOL.ORDER);
	};

	hasError = () =>
		this.props.hasLoadTicketOrderProductsError ||
		this.props.hasGroupError ||
		this.props.hasStudentsError;

	onHideClick = () =>
		this.setState(state => ({
			expandTicketSummary: !state.expandTicketSummary,
		}));

	removeTicketOrderRow = removedTicketOrder =>
		this.setState(state => ({
			ticketOrderRows: state.ticketOrderRows.filter(
				t => t.productId !== removedTicketOrder
			),
		}));

	setDefault = () => {
		const { state } = this.props;

		if (state && state.group) {
			return { ...state.group, title: state.group.name };
		}
	};

	getSelectedStudentIds = () => {
		const { selectedStudents } = this.state;
		return Object.keys(selectedStudents).reduce(
			(list, studentId) =>
				selectedStudents[studentId] ? [...list, studentId] : list,
			[]
		);
	};

	getNoOfSelectedStudents = () => this.getSelectedStudentIds().length;

	excludeAssignedStudents = students => {
		const { ticketOrderRows } = this.state;
		return students.filter(
			s => !ticketOrderRows.map(t => t.contactId).includes(s.contactId)
		);
	};

	render() {
		const {
			isLoadingGroups,
			groups = [],
			isLoadingStudentBearers,
			isLoadingStudentTickets,
			isLoadingStudents,
			getStudentsByIds,
			getStudentIdsInGroup,
			ticketOrderProducts,
			studentTicketText,
		} = this.props;
		const {
			checkAll,
			selectedGroup,
			ticketOrderRows,
			selectedStudents,
			expandTicketSummary,
		} = this.state;
		const groupId = selectedGroup.groupId;

		let studentIds = getStudentIdsInGroup(groupId);
		let studentsInGroup = getStudentsByIds(studentIds);
		const studentsExcludingAssigned = this.excludeAssignedStudents(
			studentsInGroup
		);
		const isLoadingStudentInfo =
			isLoadingStudentBearers || isLoadingStudentTickets || isLoadingStudents;

		if (isLoadingGroups) {
			return (
				<Main>
					<Loading text="Laddar grupp..." />
				</Main>
			);
		}

		const noOfSelectedStudents = this.getNoOfSelectedStudents();

		let sortedGroups = Object.values(groups || {});
		sortedGroups = sortedGroups.sort((a, b) => a.name.localeCompare(b.name));
		sortedGroups.unshift({ name: 'Välj grupp', groupId: '-1' });

		let filteredStudents = this.getFilteredStudents();
		filteredStudents = filteredStudents.sort((a, b) =>
			a.lastName.localeCompare(b.lastName)
		);

		return (
			<>
				<Main>
					{!this.hasError() && (
						<TopContainer>
							<GoBackButton onClick={() => this.goBack()}>Avbryt</GoBackButton>
							{isEmpty(sortedGroups) ? (
								<GroupDropdown
									id="groups"
									disabled={true}
									onSelect={() => {}}
									items={[]}
									renderSelectedItem={() => <div>Grupper saknas</div>}
									renderItem={() => {}}
								/>
							) : (
								<GroupDropdown
									id="groups"
									items={sortedGroups.map(g => ({ ...g, title: g.name }))}
									onSelect={this.onSelectGroup}
									defaultItem={this.setDefault()}
									renderSelectedItem={item => <span>{item.title}</span>}
									renderItem={item => <span>{item.title}</span>}
									disabled={ticketOrderRows.length > 0}
								/>
							)}
							<DropDownInfo>
								Nya grupper skapas under fliken Elever &amp; Grupper 
							</DropDownInfo>
							<GroupInfo selectedgroup={selectedGroup} />
						</TopContainer>
					)}
					<Info>
						<h1>Beställ biljetter</h1>
						<h4>Välj typ av biljett och tilldela markerade elever</h4>
					</Info>

					{!this.hasError() ? (
						<>
							<AssignTicketsContainer>
								<TicketOrderProductsDropdown
									id="ticketOrderProducts"
									items={ticketOrderProducts.map(p => ({
										...p,
										title: p.name,
									}))}
									onSelect={this.onSelectTicketOrderProduct}
									renderSelectedItem={item => (
										<span>
											{item.title} - {item.spatialTitle}
										</span>
									)}
									renderItem={item => (
										<span>
											{item.title} - {item.spatialTitle}
										</span>
									)}
								/>
								<AssignTicketsButton
									onClick={this.assignTicketsToStudents}
									disabled={noOfSelectedStudents === 0}
								>
									Tilldela markerade elever
								</AssignTicketsButton>
							</AssignTicketsContainer>

							{isLoadingStudentInfo ? (
								<Loading text="Laddar biljetter och resekort..." />
							) : (
								<TicketPageFilterContainer
									groupId={groupId}
									onSelectRadiobuttonFilter={this.onSelectRadiobuttonFilter}
									onSelectTicketType={this.onSelectTicketType}
									students={studentsExcludingAssigned.map(s => s.travellerId)}
								/>
							)}
							{filteredStudents && (
								<Table>
									<thead>
										<tr>
											<th />
											<th>Efternamn</th>
											<th>Förnamn</th>
											<th>Personnummer</th>
											<th>Hemkommun</th>
											<th>Biljetter</th>
											<th>Biljettyp</th>
											<th onClick={this.onCheckAllBoxes}>
												{checkAll ? 'Avmarkera alla' : 'Markera alla'}
											</th>
										</tr>
									</thead>

									<tbody>
										{filteredStudents.map((student, idx) => (
											<tr key={idx}>
												<td>
													<ProfileIcon />
												</td>
												<td>{student.lastName}</td>
												<td>{student.firstName}</td>
												<td>{student.personalIdentityNumber}</td>
												<td>{student.homeCounty ? student.homeCounty : '-'}</td>
												<td>{studentTicketText(student.travellerId)}</td>
												<td>{student.ticketType ? student.ticketType : '-'}</td>
												<td>
													<StyledLabel>
														<Checkbox
															key={student.travellerId}
															id={student.travellerId}
															onCheck={this.handleCheckboxClick}
															name={student.travellerId}
															checked={selectedStudents[student.travellerId]}
														/>
													</StyledLabel>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							)}
							{!selectedGroup ||
								!selectedGroup.groupId ||
								(selectedGroup.groupId === '-1' && (
									<NoGroup>Välj grupp för att starta</NoGroup>
								))}
						</>
					) : (
						<Error />
					)}
				</Main>
				<SelectedTicketWrapper
					ticketOrderRows={ticketOrderRows}
					ticketOrderProducts={ticketOrderProducts}
					expandTicketSummary={expandTicketSummary}
					onHideClick={this.onHideClick}
					onRemoveTicketOrderRow={this.removeTicketOrderRow}
					onConfirmOrder={this.redirectToConfirmOrder}
				/>
			</>
		);
	}
}

const DropDownInfo = styled.div`
	font-size: 12px;
	margin-bottom: 10px;
	width: 200px;
`;

const AssignTicketsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;

	> * {
		margin: 0 13px;
	}
`;

const TicketOrderProductsDropdown = styled(Dropdown)`
	display: block;
	margin-bottom: 2em;
`;

const AssignTicketsButton = styled(SecondaryButton)`
	font-family: 'Open Sans';
	font-size: 14px;
	font-weight: 600;
	height: 42px;
	white-space: nowrap;
`;

const TopContainer = styled.div`
	display: block;
	width: 250px;
`;

const Info = styled.div`
	text-align: center;
	margin-bottom: 2em;

	h1 {
		margin-bottom: 1em;
	}
`;

const GroupDropdown = styled(Dropdown)`
	margin: 10px 0px;

	> div {
		overflow-y: scroll;
		max-height: 500px;
	}

	div:first-of-type {
		overflow-y: unset;
		max-height: unset;
	}
`;

const GoBackButton = styled.div`
	background-color: transparent;
	border: none;
	color: ${props => props.theme.link_color};

	:hover {
		color: ${props => props.theme.link_hover_color};
		text-decoration: underline;
		cursor: pointer;
	}
`;

const NoGroup = styled.div`
	display: flex;
	justify-content: center;
	margin-top: -30px;
	font-style: italic;
`;

const StyledLabel = styled(Label)`
	float: right;
	margin-right: 1em;

	&:hover {
		cursor: pointer;
	}
`;

const Table = styled(GroupTable)`
	.not-deleted {
		color: rgb(225, 0, 0, 1);
		font-style: italic;
	}

	th:first-of-type {
		min-width: 32px;
	}

	th:last-of-type {
		&:hover {
			cursor: pointer;
		}
	}

	th:last-of-type,
	td:last-of-type {
		text-align: center;
		label {
			float: none;
			margin: 0;
		}
	}
`;

export default withTextContext(
	connect(
		store => ({
			groups: getAllGroups(store),
			getStudentIdsInGroup: getStudentIdsInGroup(store),
			getStudentsByIds: getStudentsByIds(store),
			isLoadingGroups: isLoadingGroups(store),
			isLoading: isLoadingGroups(store),
			hasStudentsInGroup: hasStudentsForGroup(store),
			studentHasTickets: studentHasTickets(store),
			studentTicketText: getStudentTicketText(store),
			selectStudentBearers: getStudentBearers(store),
			isLoadingStudentBearers: isLoadingStudentBearers(store),
			isLoadingStudents: isLoadingStudents(store),
			isLoadingStudentTickets: isLoadingStudentTickets(store),
			ticketOrderProducts: getAllTicketOrderProducts(store),
			hasLoadTicketOrderProductsError: hasLoadTicketOrderProductsError(store),
			hasGroupError: hasGroupError(store),
			hasStudentsError: hasStudentsError(store),
			state: getLocationState(store),
			hasReferredAccounts: referredAccountsLoaded(store),
		}),
		{
			...studentActions,
			...groupActions,
			...schoolActions,
			push,
		}
	)(OrderTicketPage)
);
