import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { Main } from '../common/blocks/Main';
import { forSmallPhoneOnly } from '../../utils/mediaqueries';
import withTextContext from '../../utils/withTextContext';
import { actionCreators as studentActions } from '../../reducer/students';
import {
	actionCreators as schoolActions,
	getCreatedTicketOrder,
} from '../../reducer/school';
import { SCHOOL } from '../../Paths';
import TicketOrderRowSummary from './blocks/TicketOrderRowSummary';
import { formatDate } from '../../utils/formatting';

class TicketOrderConfirmationPage extends Component {
	componentDidMount() {
		this.unlisten = this.props.history.listen(location => {
			if (location.pathname === SCHOOL.TICKET_ORDER_CONFIRMATION) {
				this.navigateToTicketPageIfNoState();
			}
		});
	}

	componentDidUpdate() {
		this.navigateToTicketPageIfNoState();
	}

	componentWillUnmount() {
		const { createdTicketOrder } = this.props;

		if (createdTicketOrder) this.props.clearCreatedTicketOrder();
		this.unlisten();
	}

	navigateToTicketPageIfNoState = () => {
		const { createdTicketOrder } = this.props;

		if (!createdTicketOrder) {
			this.props.push(SCHOOL.ORDER_TICKETS);
		}
	};

	render() {
		const { createdTicketOrder } = this.props;

		if (!createdTicketOrder) {
			return null;
		}

		const {
			serialNumber,
			createdOn,
			createdByName,
			groupName,
			invoiceReference,
			ticketOrderRows,
		} = createdTicketOrder;

		return (
			<>
				<Main>
					<FlexWrapper>
						<OrderBox>
							<OrderBoxHeader>
								<h1>Tack för din beställning</h1>
							</OrderBoxHeader>
							<div style={{ textAlign: 'center', marginBottom: '3em' }}>
								<h4>Ordernummer: {serialNumber}</h4>
							</div>
							<div style={{ marginBottom: '3em' }}>
								<p>Skapad datum: {formatDate(createdOn)}</p>
								<p>Skapad av: {createdByName}</p>
								<p>Grupp: {groupName}</p>
								<p>Fakturareferens: {invoiceReference}</p>
							</div>
							<TicketOrderRowSummary ticketOrderRows={ticketOrderRows} />
						</OrderBox>
					</FlexWrapper>
				</Main>
			</>
		);
	}
}

const OrderBoxHeader = styled.div`
	display: flex;
	margin-bottom: 3em;
	justify-content: center;
	flex-direction: row;
	h1 {
		font-weight: 600;
	}
`;

const OrderBox = styled.div`
	width: 100%;
	max-width: 570px;
	text-align: left;
	font-size: 16px;

	h4 {
		font-weight: 600;
	}

	span {
		font-size: 12px;
		font-weight: 600;
	}

	${forSmallPhoneOnly`
		border: none;
		background: none;
		padding: 0;
	`}
`;

export default withTextContext(
	connect(
		store => ({
			createdTicketOrder: getCreatedTicketOrder(store),
		}),
		{
			...studentActions,
			...schoolActions,
			push,
		}
	)(TicketOrderConfirmationPage)
);
