import {
	fetchPaymentMethods,
	getPurse as fetchPurse,
	registerPaymentMethod,
	finalizeRegisterPaymentMethod,
	deletePaymentCard,
	redeemPurseVoucher,
	replenishPurse,
	fetchPurseReplenishValues,
} from '../api';
import {
	getToken,
	getWalletId,
	isLoadingPaymentMethods as isFetchingPaymentMethods,
} from '.';
import { urlConfig } from '../config';
import isEmpty from 'lodash/isEmpty';

export const ERRORS = {
	GET_PURSE: 'GET_PURSE',
	REGISTER_PAYMENT_METHOD: 'REGISTER_PAYMENT_METHOD',
	FINALIZE_REGISTRATION: 'FINALIZE_REGISTRATION',
	GET_REGISTERED_PAYMENT_CARDS: 'GET_REGISTERED_PAYMENT_CARDS',
	DELETE_PAYMENT_CARD: 'DELETE_PAYMENT_CARD',
	REDEEM_VOUCHER: 'REDEEM_VOUCHER',
	REPLENISH_PURSE: 'REPLENISH_PURSE',
	GET_PURSE_REPLENISH_VALUES: 'GET_PURSE_REPLENISH_VALUES',
};

export const PAYMENT_PROVIDERS = {
	SWISH: 'Swish',
};

export const STORE_NAME = 'wallet';

const initialState = {
	paymentMethods: null,
	sharedPaymentMethods: null,
	purse: null,
	isLoading: false,
	errorMessage: '',
	registeredPaymentMethodId: null,
	registeredPaymentCards: null,
	replenishResponse: undefined,
	replenishValues: null,
	isLoadingSharedPaymentMethods: false,
	isLoadingRegisteredPaymentCards: false,
	isLoadingPurse: false,
};

export const actionCreators = {
	getPaymentMethods: () => async (dispatch, getState) => {
		const state = getState();
		const isLoading = isFetchingPaymentMethods(state);

		if (isLoading) {
			dispatch({ type: 'ABORT_GET_WALLET_REQUEST' });
			return;
		}

		const token = getToken(state);
		const walletId = getWalletId(state);

		dispatch({ type: 'GET_WALLET_REQUEST' });

		try {
			const paymentMethods = await fetchPaymentMethods(token, walletId);
			dispatch({ type: 'GET_WALLET_SUCCESS', paymentMethods });
		} catch (error) {
			dispatch({ type: 'GET_WALLET_FAILURE', message: error.message });
		}
	},
	clearPaymentCards: () => async dispatch => {
		dispatch({ type: 'CLEAR_PAYMENT_CARDS' });
	},
	deletePaymentCard: paymentCardId => async (dispatch, getState) => {
		dispatch({ type: 'DELETE_PAYMENT_CARD_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const walletId = getWalletId(state);

		try {
			const removedPaymentCard = await deletePaymentCard(
				token,
				walletId,
				paymentCardId
			);
			dispatch({ type: 'DELETE_PAYMENT_CARD_SUCCESS', removedPaymentCard });
			return true;
		} catch (error) {
			dispatch({
				type: 'DELETE_PAYMENT_CARD_FAILURE',
				message: ERRORS.DELETE_PAYMENT_CARD,
			});
			return false;
		}
	},
	registerPaymentMethod: (registrationPaymentMethodId, name) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'REGISTER_PAYMENT_METHOD_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const walletId = getWalletId(state);
		const item = {
			registrationPaymentMethodId,
			name,
			returnUrl: urlConfig.finalizeUrl,
		};

		try {
			const response = await registerPaymentMethod(token, walletId, item);
			dispatch({ type: 'REGISTER_PAYMENT_METHOD_SUCCESS', response });
			return response;
		} catch (error) {
			dispatch({
				type: 'REGISTER_PAYMENT_METHOD_FAILURE',
				message: ERRORS.REGISTER_PAYMENT_METHOD,
			});
			return false;
		}
	},
	finalizeRegistration: paymentMethodId => async (dispatch, getState) => {
		dispatch({ type: 'FINALIZE_REGISTRATION_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const walletId = getWalletId(state);

		try {
			await finalizeRegisterPaymentMethod(token, walletId, paymentMethodId);
			dispatch({ type: 'FINALIZE_REGISTRATION_SUCCESS' });
		} catch (error) {
			dispatch({
				type: 'FINALIZE_REGISTRATION_FAILURE',
				message: ERRORS.FINALIZE_REGISTRATION,
			});
		}
	},
	getPurse: () => async (dispatch, getState) => {
		dispatch({ type: 'GET_PURSE_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const walletId = getWalletId(state);

		try {
			const purse = await fetchPurse(token, walletId);
			dispatch({ type: 'GET_PURSE_SUCCESS', purse });
		} catch (error) {
			dispatch({ type: 'GET_PURSE_FAILURE', message: ERRORS.GET_PURSE });
		}
	},
	redeemVoucher: voucher => async (dispatch, getState) => {
		dispatch({ type: 'REDEEM_VOUCHER_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const walletId = getWalletId(state);

		try {
			const { purse } = await redeemPurseVoucher(token, walletId, voucher);
			dispatch({ type: 'REDEEM_VOUCHER_SUCCESS', purse });
			return true;
		} catch (error) {
			dispatch({
				type: 'REDEEM_VOUCHER_FAILURE',
				message: ERRORS.REDEEM_VOUCHER,
			});
			return false;
		}
	},
	replenishPurse: (amount, paymentMethodId, useSwish) => async (
		dispatch,
		getState
	) => {
		dispatch({ type: 'REPLENISH_PURSE_REQUEST' });

		const state = getState();
		const token = getToken(state);
		const walletId = getWalletId(state);
		const replenishRequest = {
			amount,
			paymentMethodId,
			returnURL: urlConfig.confirmationUrl,
			currency: 'SEK',
			mobile: useSwish,
		};

		try {
			const response = await replenishPurse(token, walletId, replenishRequest);
			dispatch({
				type: 'REPLENISH_PURSE_SUCCESS',
				replenishResponse: response,
			});
			return true;
		} catch (error) {
			dispatch({
				type: 'REPLENISH_PURSE_FAILURE',
				message: ERRORS.REPLENISH_PURSE,
			});
			return false;
		}
	},
	getPurseReplenishValues: () => async (dispatch, getState) => {
		const state = getState();
		const token = getToken(state);

		dispatch({ type: 'GET_PURSE_REPLENISH_VALUES_REQUEST' });

		try {
			const { replenishValues } = await fetchPurseReplenishValues(token);
			dispatch({ type: 'GET_PURSE_REPLENISH_VALUES_SUCCESS', replenishValues });
		} catch (error) {
			dispatch({
				type: 'GET_PURSE_REPLENISH_VALUES_FAILURE',
				message: ERRORS.GET_PURSE_REPLENISH_VALUES,
			});
		}
	},
};

export default function walletsReducer(state, action) {
	state = state || initialState;

	switch (action.type) {
		case 'GET_WALLET_REQUEST':
		case 'REGISTER_PAYMENT_METHOD_REQUEST':
		case 'FINALIZE_REGISTRATION_REQUEST':
		case 'DELETE_PAYMENT_CARD_REQUEST':
		case 'REDEEM_VOUCHER_REQUEST':
		case 'REPLENISH_PURSE_REQUEST':
		case 'GET_PURSE_REPLENISH_VALUES_REQUEST':
			return {
				...state,
				isLoading: true,
				errorMessage: '',
			};
		case 'GET_SHARED_WALLET_REQUEST':
			return {
				...state,
				isLoadingSharedPaymentMethods: true,
				errorMessage: '',
			};
		case 'GET_REGISTERED_PAYMENT_CARD_REQUEST':
			return {
				...state,
				isLoadingRegisteredPaymentCards: true,
				errorMessage: '',
			};
		case 'GET_PURSE_REQUEST':
			return {
				...state,
				isLoadingPurse: true,
				errorMessage: '',
			};
		case 'GET_WALLET_SUCCESS':
			return {
				...state,
				isLoading: false,
				paymentMethods: action.paymentMethods.registeredPaymentMethods,
				sharedPaymentMethods: action.paymentMethods.sharedPaymentMethods,
			};
		case 'REGISTER_PAYMENT_METHOD_SUCCESS':
			return {
				...state,
				isLoading: false,
				registeredPaymentMethodId: action.response.registeredPaymentMethodId,
			};
		case 'DELETE_PAYMENT_CARD_SUCCESS':
			return {
				...state,
				isLoading: false,
				registeredPaymentMethodId: action.removedPaymentCard,
			};
		case 'FINALIZE_REGISTRATION_SUCCESS':
			return {
				...state,
				isLoading: false,
			};
		case 'GET_SHARED_WALLET_SUCCESS':
			return {
				...state,
				isLoadingSharedPaymentMethods: false,
				sharedPaymentMethods: action.sharedPaymentMethods,
			};
		case 'GET_REGISTERED_PAYMENT_CARD_SUCCESS':
			return {
				...state,
				isLoadingRegisteredPaymentCards: false,
				registeredPaymentCards: action.registeredPaymentCards,
			};
		case 'CLEAR_PAYMENT_CARDS':
			return {
				...state,
				paymentMethods: null,
				sharedPaymentMethods: null,
			};
		case 'GET_PURSE_SUCCESS':
			return {
				...state,
				isLoadingPurse: false,
				purse: action.purse,
			};
		case 'REDEEM_VOUCHER_SUCCESS':
			return {
				...state,
				isLoading: false,
				purse: action.purse,
			};
		case 'REPLENISH_PURSE_SUCCESS':
			return {
				...state,
				isLoading: false,
				replenishResponse: action.replenishResponse,
			};
		case 'GET_PURSE_REPLENISH_VALUES_SUCCESS':
			return {
				...state,
				isLoading: false,
				replenishValues: action.replenishValues,
			};
		case 'GET_WALLET_FAILURE':
		case 'REGISTER_PAYMENT_METHOD_FAILURE':
		case 'FINALIZE_REGISTRATION_FAILURE':
		case 'DELETE_PAYMENT_CARD_FAILURE':
		case 'REDEEM_VOUCHER_FAILURE':
		case 'REPLENISH_PURSE_FAILURE':
		case 'GET_PURSE_REPLENISH_VALUES_FAILURE':
			return {
				...state,
				isLoading: false,
				errorMessage: action.message,
			};
		case 'GET_SHARED_WALLET_FAILURE':
			return {
				...state,
				isLoadingSharedPaymentMethods: false,
				errorMessage: action.message,
			};
		case 'GET_REGISTERED_PAYMENT_CARD_FAILURE':
			return {
				...state,
				isLoadingRegisteredPaymentCards: false,
				errorMessage: action.message,
			};
		case 'GET_PURSE_FAILURE':
			return {
				...state,
				isLoadingPurse: false,
				errorMessage: action.message,
			};
		default:
			return state;
	}
}

// Private selectors

export function getRegisteredPaymentMethodId(state) {
	return state.registeredPaymentMethodId;
}

export function isLoadingPaymentMethods(state) {
	return state.isLoading;
}

export function getPurse(state) {
	return state.purse;
}

export function hasPurseError(state) {
	return state.errorMessage === ERRORS.GET_PURSE;
}

export function hasFinalizeRegistrationError(state) {
	return state.errorMessage === ERRORS.FINALIZE_REGISTRATION;
}

export const canReplenish = store =>
	(store[STORE_NAME].paymentMethods || []).some(m => m.canReplenish);

export const hasReplenishError = store =>
	store[STORE_NAME].errorMessage === ERRORS.REPLENISH_PURSE;

export const isLoadingReplenishValues = state => state[STORE_NAME].isLoading;

export const hasLoadingReplenishValuesError = state =>
	state[STORE_NAME].errorMessage === ERRORS.GET_PURSE_REPLENISH_VALUES;

export const getReplenishValues = state => state[STORE_NAME].replenishValues;

export const hasReplenishValues = state =>
	!isEmpty(state[STORE_NAME].replenishValues);

export const getReplenishResponse = store =>
	store[STORE_NAME].replenishResponse;

export const isLoadingSharedPaymentMethods = store =>
	store[STORE_NAME].isLoadingSharedPaymentMethods;

export const isLoadingRegisteredPaymentCards = store =>
	store[STORE_NAME].isLoadingRegisteredPaymentCards;

export const isLoadingPurse = store => store[STORE_NAME].isLoadingPurse;

export const getPurchasablePaymentMethods = store =>
	(store[STORE_NAME].paymentMethods || [])
		.concat(store[STORE_NAME].sharedPaymentMethods || [])
		.filter(p => p.canPurchase);

export const getRegistrationPaymentMethods = store =>
	(store[STORE_NAME].sharedPaymentMethods || []).filter(
		p => p.providesRegistration && p.type === 'paymentcard'
	);

export const getRegisteredCards = store =>
	(store[STORE_NAME].paymentMethods || []).filter(
		p => p.type === 'paymentcard' && p.canPurchase
	);
