import React from "react";
import styled from 'styled-components';
import { forDesktopUp, forTabletLandscapeUp, forTabletPortraitUp, forZoomedInUsers } from "../../../utils/mediaqueries";

export function Main(props) {
	const { className, ...restProps } = props;
	return (
		<MainContainer {...restProps} className={className}>
			{props.children}
		</MainContainer>
	);
}

const MainContainer = styled.section`
	width: 100%;
	flex: 1 0 auto;
	padding: 3em 1em;

	${forZoomedInUsers`
		padding: 3em 0.7em;
	`}

	${forTabletPortraitUp`
		width: 570px;
		margin-left: auto;
		margin-right: auto;
	`}

	${forTabletLandscapeUp`
		width: 870px;
	`}

	${forDesktopUp`
		width: 1170px;
	`}
`;
