import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { UnstyledList } from '../../common/elements/UnstyledList';
import { ReactComponent as Bus } from '../../../images/bus.svg';
import { ReactComponent as Walk } from '../../../images/walk.svg';
import { ReactComponent as Train } from '../../../images/train.svg';
import { ReactComponent as Circle } from '../../../images/circle.svg';
import { formatTime } from '../../../utils/formatting';
import { JOURNEY_LINE_TYPES } from '../../../reducer/journey';
import { addMinutesToDate } from '../../../utils/helperFunctions';
export class JourneyLineList extends Component {
	getIcon = typeId => {
		switch (typeId) {
			case JOURNEY_LINE_TYPES.WALK:
				return (
					<Walk className="svg-icon" aria-hidden="true" focusable="false" />
				);
			case JOURNEY_LINE_TYPES.TRAIN:
				return (
					<TrainIcon
						className="svg-icon"
						aria-hidden="true"
						focusable="false"
					/>
				);
			default:
				return (
					<BusIcon className="svg-icon" aria-hidden="true" focusable="false" />
				);
		}
	};

	hasDeviations = route =>
		route.deviations.length > 0 || route.realTime.realTimeInfoField;

	render() {
		const { routes, expanded } = this.props;

		return (
			<>
				{expanded ? (
					<LineContainerExpanded>
						<LineListExpanded>
							{routes.map((r, idx) => {
								const isWalk = r.line.typeId === JOURNEY_LINE_TYPES.WALK;
								const cssClass = classNames({
									'line--walk': isWalk,
								});
								const icon = this.getIcon(r.line.typeId);

								const departureDeviation =
									r.realTime &&
									r.realTime.realTimeInfoField &&
									r.realTime.realTimeInfoField[0] &&
									r.realTime.realTimeInfoField[0].depTimeDeviationField
										? r.realTime.realTimeInfoField[0].depTimeDeviationField
										: null;
								const arrivalDeviation =
									r.realTime &&
									r.realTime.realTimeInfoField &&
									r.realTime.realTimeInfoField[0] &&
									r.realTime.realTimeInfoField[0].arrTimeDeviationField
										? r.realTime.realTimeInfoField[0].arrTimeDeviationField
										: null;

								const depTime = addMinutesToDate(
									r.departure,
									departureDeviation ?? 0
								);
								const arrTime = addMinutesToDate(
									r.arrival,
									arrivalDeviation ?? 0
								);

								return (
									<RouteContainer key={`${idx}-0`}>
										<FromTo>
											<span
												className={classNames({
													hasDeviation: departureDeviation,
												})}
											>
												<P>{formatTime(r.departure)}</P>
											</span>
											{departureDeviation && (
												<span>
													<P>{depTime}</P>
												</span>
											)}
											<CircleIcon strokeWidth="3" />
											<span className="from">
												<P>{r.from}</P>
											</span>
										</FromTo>

										<li id="expanded" className={cssClass}>
											{this.hasDeviations(r) && (
												<ExpandedDeviation>
													{r.deviations.join('. ')}
												</ExpandedDeviation>
											)}
											<ExpandedLineInfo>
												{icon}
												<P>{!isWalk && r.line.name}</P>
											</ExpandedLineInfo>
										</li>

										<FromTo>
											<span
												className={classNames({
													hasDeviation: arrivalDeviation,
												})}
											>
												<P>{formatTime(r.arrival)}</P>
											</span>
											{arrivalDeviation && (
												<span>
													<P>{arrTime}</P>
												</span>
											)}
											<CircleIcon strokeWidth="3" />
											<span className="from">
												<P>{r.to}</P>
											</span>
										</FromTo>
									</RouteContainer>
								);
							})}
						</LineListExpanded>
					</LineContainerExpanded>
				) : (
					<LineContainer>
						<LineList>
							<Lines>
								{routes.map((r, idx) => {
									const isWalk = r.line.typeId === JOURNEY_LINE_TYPES.WALK;
									const cssClass = classNames({
										'line--deviation': this.hasDeviations(r),
										'line--walk': isWalk,
									});
									const icon = this.getIcon(r.line.typeId);

									return (
										<li key={`${idx}-0`} className={cssClass}>
											<span key={`${idx}-1`}>
												{icon}
												<P>{!isWalk && r.line.name}</P>
											</span>
										</li>
									);
								})}
							</Lines>
						</LineList>
					</LineContainer>
				)}
			</>
		);
	}
}

const ExpandedDeviation = styled.div`
	font-style: italic;
	color: ${props => props.theme.dark_grey};
	margin-bottom: 0.5em;

	::before {
		content: '!';
		text-align: center;
		width: 16px;
		height: 16px;
		border-radius: 16px;
		background-color: ${props => props.theme.journey_deviation_color};
		line-height: 1.1em;
		font-size: 1.1em;
		font-weight: bold;
		font-style: normal;
		display: inline-block;
		color: white;
		margin-right: 0.3em;
	}
`;

const ExpandedLineInfo = styled.div`
	display: inline-block;
	padding: 6px 6px;
	border-radius: 6px;
	color: ${props => props.theme.textOnPrimaryColor};
	background-color: ${props => props.theme.primary_color};
`;

const Lines = styled(UnstyledList)`
	-ms-grid-row: 2;
	-ms-grid-column: 1;
	-ms-grid-column-align: start;
	-ms-grid-row-align: start;

	li {
		margin-top: 8px;
		display: inline-block;
	}

	li span {
		position: relative;
		padding: 2px 5px;
		border-radius: 6px;
		background-color: ${props => props.theme.primary_color};
		color: ${props => props.theme.textOnPrimaryColor};
	}

	li + li::before {
		content: '';
		vertical-align: middle;
		display: inline-block;
		margin-left: 2px;
		margin-right: 2px;
		width: 20px;
		height: 0;
		border-bottom: solid 2px #979797;
	}

	li.line--walk + li::before {
		border-bottom-style: dotted;
	}

	li.line--walk span {
		background-color: transparent;
	}

	li.line--walk svg {
		transform: rotate(-90deg) scale(1.3);
	}

	.line--deviation span::after {
		content: '!';
		top: -5px;
		right: -5px;
		text-align: center;
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 12px;
		font-size: 0.8em;
		color: white;
		background-color: ${props => props.theme.journey_deviation_color};
		line-height: 1em;
		font-weight: bold;
	}
`;

const FromTo = styled.div`
	display: flex;
`;

const CircleIcon = styled(Circle)`
	padding: 4px;
	flex-shrink: 0;
`;

const LineList = styled(UnstyledList)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const LineListExpanded = styled(Lines)`
	#expanded {
		margin-top: 0px;
		padding: 0.5em 1em;
		height: auto;
	}

	#expanded.line--walk {
		padding: 1em;
	}

	#expanded.line--walk ${ExpandedLineInfo} {
		margin-top: 0px;
		margin-left: -28px;
	}
`;

const RouteContainer = styled.div`
	margin-bottom: 1em;

	li.line--walk {
		border-left: 2px dotted #979797;
		margin-left: 45px;
		height: 4em;
	}

	li.line--walk svg {
		transform: rotate(0deg) scale(1.3);
	}

	li.line--walk ${ExpandedLineInfo} {
		margin-left: -13px;
		display: flex;
		margin-top: 16px;
		background-color: white;
	}

	li {
		border-left: 2px solid black;
		margin-left: 45px;
		height: 4em;
	}

	span.spanIcon {
		margin-left: 1em;
		display: flex;
		margin-top: 0.5em;
	}

	li span {
		padding: 6px 6px;
	}

	.hasDeviation {
		text-decoration: line-through;
		margin-right: 0.5em;
	}
`;

const LineContainerExpanded = styled.div`
	width: 100%;
`;

const LineContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const BusIcon = styled(Bus)`
	margin-right: 0.3em;

	path {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

const TrainIcon = styled(Train)`
	margin-right: 0.3em;

	path {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

const P = styled.p`
	margin: 0;
	display: inherit;
`;
