import React, { Component } from 'react';
import withTextContext from '../../../utils/withTextContext';
import {
	actionCreators as cultureTripActions,
	getCultureTrips,
	isLoadingCultureTrips,
} from '../../../reducer/school/culturetrips';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { GroupTable } from '../GroupPage';
import styled, { css } from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../images/check-circle.svg';
import { ReactComponent as CrossIcon } from '../../../images/cross-circle.svg';
import { ReactComponent as MinusIcon } from '../../../images/minus-circle.svg';
import { ReactComponent as ExclamationIcon } from '../../../images/exclamation-circle.svg';
import { Loading } from '../../common/blocks/Loading';
import { formatDate } from '../../../utils/formatting';
import { Main } from '../../common/blocks/Main';
import { Banner } from '../../common/blocks/Banner';

export const CULTURE_TRIP_STATUSES = {
	APPROVED: 'Godkänd',
	REJECTED: 'Avslagen',
	ERROR: 'Fel',
	REGISTERED: 'Ansökt',
};

class CultureTripWrapper extends Component {
	componentDidMount() {
		const { cultureTrips } = this.props;
		if (isEmpty(cultureTrips)) this.props.getCultureTrips();
	}

	renderCultureTripIcon = status => {
		switch (status) {
			case CULTURE_TRIP_STATUSES.APPROVED:
				return <ApprovedIcon />;
			case CULTURE_TRIP_STATUSES.REJECTED:
				return <RejectedIcon />;
			case CULTURE_TRIP_STATUSES.ERROR:
				return <ErrorIcon />;
			case CULTURE_TRIP_STATUSES.REGISTERED:
			default:
				return <PendingIcon />;
		}
	};

	render() {
		const { cultureTrips, isLoadingCultureTrips, texts } = this.props;

		const sortedCultureTrips = (cultureTrips || [])
			.filter(c => c.createdOn)
			.sort((a, b) => a.createdOn.localeCompare(b.createdOn))
			.reverse();

		return (
			<>
				<Banner bannerImg={texts.school.images.banners.culturetrippage}>
					<h2>Kulturresor</h2>
					<p>Här kan du föranmäla en gruppresa och ansöka om kulturresor.</p>
				</Banner>
				<Main>
					{this.props.children}
					<TableHeader>Registrerade resor</TableHeader>
					{cultureTrips && (
						<GroupTable>
							<thead>
								<tr>
									<th />
									<th>Status</th>
									<th>Resetyp</th>
									<th>Registrerat</th>
									<th>Ärendenummer</th>
								</tr>
							</thead>

							<tbody>
								{sortedCultureTrips.map(cultureTrip => (
									<tr key={cultureTrip.id}>
										<IconColumn>
											{this.renderCultureTripIcon(cultureTrip.status)}
										</IconColumn>
										<td>{cultureTrip.status}</td>
										<td>{cultureTrip.incidentType}</td>
										<td>{formatDate(cultureTrip.createdOn)}</td>
										<td className="nowrap">{cultureTrip.id}</td>
									</tr>
								))}
								<tr></tr>
							</tbody>
						</GroupTable>
					)}
					{isLoadingCultureTrips && <Loading />}
				</Main>
			</>
		);
	}
}

const TableHeader = styled.h3`
	text-align: center;
	margin: 30px 0;
`;

const IconColumn = styled.td`
	position: relative;
	padding: 15px 30px !important;
`;

const IconStyles = css`
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -10px;
	margin-top: -10px;
	width: 20px;
	height: 20px;
`;

const ApprovedIcon = styled(CheckIcon)`
	color: green;
	${IconStyles}
`;

const RejectedIcon = styled(CrossIcon)`
	color: ${props => props.theme.red};
	${IconStyles}
`;

const PendingIcon = styled(MinusIcon)`
	color: orange;
	${IconStyles}
`;

const ErrorIcon = styled(ExclamationIcon)`
	color: #d30808;
	${IconStyles}
`;

export default withTextContext(
	connect(
		store => ({
			cultureTrips: getCultureTrips(store),
			isLoadingCultureTrips: isLoadingCultureTrips(store),
		}),
		{
			...cultureTripActions,
			push,
		}
	)(CultureTripWrapper),
	false
);
