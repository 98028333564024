const base = {
	themeName: 'base',
	primary_color: '#6B8691',
	secondary_color: 'white',
	background1: '#6B8691',
	background2: '#6B8691',
	text: "'Open Sans', sans-serif",
	text_color: '#2E2E2E',
	dark_grey: '#2E2E2E',
	light_grey: '#7C7D7D',
	lighter_grey: '#C6C6C6',
	lightest_grey: '#f5f5f5',
	red: '#d0021b',
	white: '#fff',
	link_color: 'inherit',
	link_hover_color: 'inherit',
	textOnPrimaryColor: '#fff',
	journey_deviation_color: '#e4223a',
	h1: { font_size: '36px' },
	h2: { font_size: '24px' },
};

const halland = Object.assign({}, base, {
	themeName: 'halland',
	primary_color: '#006AB2',
	secondary_color: '#006AB2',
	background1: '#006AB2',
	background2: '#006AB2',
	link_color: 'hsl(204, 100%, 35%)',
	link_hover_color: 'hsl(204, 100%, 20%)',
});

const jonkoping = Object.assign({}, base, {
	themeName: 'jonkoping',
	primary_color: '#E20025',
	secondary_color: '#008A00',
	background1: '#E10025',
	background2: '#E10025',
	link_color: 'hsl(350, 100%, 37%)',
	link_hover_color: 'hsl(350, 100%, 22%)',
	journey_deviation_color: 'black',
});

const kalmar = Object.assign({}, base, {
	themeName: 'kalmar',
	primary_color: '#ffcd00',
	secondary_color: '#ffcd00',
	background1: '#ffcd00',
	background2: '#ffcd00',
	textOnPrimaryColor: '#000',
	link_color: 'hsl(207, 91%, 39%)',
	link_hover_color: 'hsl(207, 91%, 24%)',
});

const kronoberg = Object.assign({}, base, {
	themeName: 'kronoberg',
	primary_color: '#008937',
	secondary_color: '#008937',
	background1: '#008937',
	background2: '#008937',
	link_color: 'hsl(149, 100%, 31%)',
	link_hover_color: 'hsl(149, 100%, 16%)',
});

const themes = {
    base,
    halland,
    jonkoping,
    kalmar,
    kronoberg,
};
export { themes };
