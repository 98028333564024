import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as Check } from '../../../images/check.svg';

export class Checkbox extends Component {
	static propTypes = {
		onCheck: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		checked: PropTypes.bool,
		disabled: PropTypes.bool,
	};

	handleChange = () => {
		const { name } = this.props;

		this.props.onCheck(name, !this.props.checked)
	};

	render() {
		const { id, name, disabled = false, checked, style } = this.props;

		
		
		return (
			<CheckboxContainer style={style}>
				<HiddenCheckbox
					id={id}
					type="checkbox"
					checked={checked || false}
					onChange={this.handleChange}
					name={name}
					disabled={disabled}
				/>
				<StyledCheckbox checked={checked}>
					<CheckIcon />
				</StyledCheckbox>
			</CheckboxContainer>
		);
	}
}

const CheckIcon = styled(Check)`
	width: 10px;
	height: 10px;

	g {
		stroke: ${props => props.theme.textOnPrimaryColor};
	}
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	// Hide checkbox visually but remain accessible to screen readers.
	// Source: https://polished.js.org/docs/#hidevisually
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	background-color: ${props => (props.checked ? props.theme.primary_color : 'white')};
	border: 2px solid ${props => props.theme.themeName === "kalmar" ? "#000" : props.theme.primary_color};
	border-radius: 2px;
	transition: all 150ms;

	${HiddenCheckbox}:focus + & {
		box-shadow: 0 0 0 2px #000;
	}

	${CheckIcon} {
		visibility: ${props => (props.checked ? 'visible' : 'hidden')};
	}
`;

const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
	margin-right: 2px;
`;
