import { compose } from 'redux';
import { PRIVATE } from '../Paths';
import ensureAcceptedTerms from '../utils/ensureAcceptedTerms';
import ensureName from '../utils/ensureName';
// import ensurePersonId from "./utils/ensurePersonId";
import withAuthentication from '../utils/withAuthentication';
import HomePage from '../components/customer/HomePage';
import ProfilePage from '../components/customer/ProfilePage';
// import IssuePage from '../components/customer/IssuePage';
// import DelayJourney from '../components/customer/Issues/DelayJourney';
// import DelayRegistration from '../components/customer/Issues/DelayRegistration';
// import DelayContact from '../components/customer/Issues/DelayContact';
// import Summary from '../components/customer/Issues/Summary';
// import ReclamationPage from '../components/customer/Issues/ReclamationPage';
import TicketPage from '../components/customer/TicketPage';
import TravelCardPage from '../components/customer/TravelCardPage';
import MobileAppPage from '../components/customer/MobileAppPage';
import BuyTicket from '../components/customer/BuyTicketPage';
import CheckoutPage from '../components/customer/CheckoutPage';
import RegisterCardPage from '../components/customer/RegisterCardPage';
import RegisterPage from '../components/customer/RegisterPage';
import FinalizePaymentRegistrationPage from '../components/customer/FinalizePaymentRegistrationPage';
import PurchaseOptionsPage from '../components/customer/PurchaseOptionsPage';
import ConfirmTransactionPage from '../components/customer/ConfirmTransactionPage';
import ConfirmSwishTransactionPage from '../components/customer/ConfirmSwishTransactionPage';
import { ExternalTestPage } from '../components/customer/ExternalTestPage';
import ConfirmationPage from '../components/customer/ConfirmationPage';
import PurchasePurseFundsPage from '../components/customer/PurchasePurseFundsPage';
import PurchaseOfferPage from '../components/customer/PurchaseOfferPage';
import { withLayout } from '../utils/withLayout';
import PrivateRoot from '../components/customer/Root';
import UnauthenticatedRoot from '../components/customer/blocks/UnauthenticatedRoot';
import LoginPage from '../components/common/LoginPage';
import NotAuthorizedPage from '../components/common/NotAuthorizedPage';
import CallbackPage from '../components/common/CallbackPage';
import { SignoutPage } from '../components/common/SignoutPage';
import NotFoundPage from '../components/common/NotFoundPage';


const authenticateAndEnsureData = compose(
	withLayout(PrivateRoot),
	withAuthentication({ redirectPath: PRIVATE.LOGIN }),
	ensureAcceptedTerms,
	ensureName,
	// ensurePersonId,
);

const withUnauthenticatedLayout = component =>
	withLayout(UnauthenticatedRoot)(component);

export const customerRoutes = [
	{ path: PRIVATE.HOME, exact: true, component: withUnauthenticatedLayout(HomePage) },
	{ path: PRIVATE.PROFILE, exact: true, component: authenticateAndEnsureData(ProfilePage) },
	// { path: PRIVATE.ISSUES, exact: true, component: authenticateAndEnsureData(IssuePage) },
	// { path: PRIVATE.ISSUES_CONFIRMATION, exact: true, component: authenticateAndEnsureData(ConfirmationPage) },
	// { path: PRIVATE.DELAY_JOURNEY, exact: true, component: authenticateAndEnsureData(DelayJourney) },
	// { path: PRIVATE.DELAY_REGISTRATION, exact: true, component: authenticateAndEnsureData(DelayRegistration) },
	// { path: PRIVATE.DELAY_CONTACT, exact: true, component: authenticateAndEnsureData(DelayContact) },
	// { path: PRIVATE.DELAY_SUMMARY, exact: true, component: authenticateAndEnsureData(Summary) },
	// { path: PRIVATE.RECLAMATION, exact: true, component: authenticateAndEnsureData(ReclamationPage) },
	{ path: PRIVATE.BUY_TICKET, exact: true, component: authenticateAndEnsureData(BuyTicket) },
	{ path: PRIVATE.PURCHASE_PURSE_FUNDS, exact: true, component: authenticateAndEnsureData(PurchasePurseFundsPage) },
	{ path: PRIVATE.PURCHASE_OFFER, exact: true, component: authenticateAndEnsureData(PurchaseOfferPage) },
	{ path: PRIVATE.BUY_TICKET_OPTIONS, component: authenticateAndEnsureData(PurchaseOptionsPage) },
	{ path: PRIVATE.CONFIRM_PURCHASE, exact: true, component: authenticateAndEnsureData(ConfirmTransactionPage) },
	{ path: PRIVATE.CONFIRM_SWISH_PURCHASE, exact: true, component: authenticateAndEnsureData(ConfirmSwishTransactionPage) },
	{ path: PRIVATE.TICKET, exact: true, component: authenticateAndEnsureData(TicketPage) },
	{ path: PRIVATE.TICKET_CONFIRMATION, exact: true, component: authenticateAndEnsureData(ConfirmationPage) },
	{ path: PRIVATE.TRAVEL_CARD, exact: true, component: authenticateAndEnsureData(TravelCardPage) },
	{ path: PRIVATE.MOBILE_INFO, exact: true, component: authenticateAndEnsureData(MobileAppPage) },
	{ path: PRIVATE.CHECKOUT, exact: true, component: authenticateAndEnsureData(CheckoutPage) },
	{ path: PRIVATE.REGISTER_CARD, exact: true, component: authenticateAndEnsureData(RegisterCardPage) }, 
	{ path: PRIVATE.FINALIZE_PAYMENT_CARD_REGISTRATION, exact: true, component: authenticateAndEnsureData(FinalizePaymentRegistrationPage) },
	{ path: PRIVATE.REGISTER, exact: true, component: withUnauthenticatedLayout(RegisterPage) },
	{ path: '/external', exact: true, component: ExternalTestPage },
	{ path: PRIVATE.LOGIN, exact: true, component: withUnauthenticatedLayout(LoginPage) },
	{ path: PRIVATE.UNAUTHORIZED, exact: true, component: withUnauthenticatedLayout(NotAuthorizedPage) },
	{ path: PRIVATE.CALLBACK, exact: true, component: withUnauthenticatedLayout(CallbackPage) },
	{ path: PRIVATE.SIGNOUT, component: withUnauthenticatedLayout(SignoutPage) },
	{ component: withUnauthenticatedLayout(NotFoundPage) },
];
