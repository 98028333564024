import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ReactComponent as CardSvg } from '../../images/card.svg';
import { ConfirmationCard } from '../common/blocks/ConfirmationCard';
import { Main } from '../common/blocks/Main';
import { FlexWrapper } from '../common/blocks/FlexWrapper';
import { PRIVATE } from '../../Paths';
import {
	getRegisteredPaymentMethodId,
	isLoadingPaymentMethods,
	hasFinalizeRegistrationError,
} from '../../reducer';
import { actionCreators as walletActions } from '../../reducer/wallet';
import { Loading } from '../common/blocks/Loading';
import { Error } from './../common/elements/Error';
import { CARD_NAMES } from './ProfilePage';
import { getUrlParameter } from '../../utils/parsing';
import { SecondaryButton } from '../common/elements/SecondaryButton';
import { Card } from '../common/blocks/Card';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import { Banner } from '../common/blocks/Banner';
import withTextContext from '../../utils/withTextContext';

class FinalizePaymentRegistrationPage extends Component {
	componentDidMount() {
		document.title = "Nytt betalkort | Mitt konto"
		const { registeredPaymentMethodId, finalizeRegistration } = this.props;

		if (registeredPaymentMethodId && !this.isRegistrationCancelled())
			finalizeRegistration(registeredPaymentMethodId);
	}

	continue = async () => {
		this.props.push(PRIVATE.PROFILE, {
			selectedCard: CARD_NAMES.PAYMENTMETHODS,
		});
	};

	isRegistrationCancelled = () =>
		getUrlParameter(this.props.search, 'responseCode') === 'Cancel';

	render() {
		const {
			hasFinalizeRegistrationError,
			registeredPaymentMethodId,
			isLoading,
			texts,
		} = this.props;

		if (isLoading) {
			return <Loading />;
		}

		return (
			<>
				<Banner bannerImg={texts.private.images.banners.ticketpage}>
					<h2 style={{ marginTop: '0' }}>Nytt betalkort</h2>
				</Banner>
				<Main>
					<FlexWrapper>
						<Header>
							<CardIcon aria-hidden="true" focusable="false" />
							<h2>Nytt betalkort</h2>
						</Header>

						{(() => {
							if (this.isRegistrationCancelled()) {
								return (
									<CardBox>
										<h4 style={{ marginTop: '0' }}>
											Registrering av betalkort avbrutet
										</h4>
										<p style={{ maxWidth: '225px' }}>
											Du har avbrutit registrering av betalkort
										</p>
										<SecondaryButton
											style={{ marginTop: '1em' }}
											onClick={this.continue}
										>
											Registrera nytt betalkort
										</SecondaryButton>
									</CardBox>
								);
							}

							if (hasFinalizeRegistrationError || !registeredPaymentMethodId) {
								return <Error />;
							}

							return (
								<ConfirmationCard
									onClick={this.continue}
									text={<p>Betalkortet är nu registrerat</p>}
								/>
							);
						})()}
					</FlexWrapper>
				</Main>
			</>
		);
	}
}

const CardBox = styled(Card)`
	align-items: center;
	${forTabletPortraitUp`
		padding: 3em;
	`}
`;

const CardIcon = styled(CardSvg)`
	width: 40px;
	height: 32px;
`;
const Header = styled.div`
	margin-top: 2em;
	margin-bottom: 1em;
	text-align: center;
	display: block;

	${forTabletPortraitUp`
		display: none;
	`}
`;

function mapStateToProps(store) {
	return {
		search: store.router.location.search,
		registeredPaymentMethodId: getRegisteredPaymentMethodId(store),
		hasFinalizeRegistrationError: hasFinalizeRegistrationError(store),
		isLoading: isLoadingPaymentMethods(store),
	};
}

export default withTextContext(
	connect(mapStateToProps, { ...walletActions, push })(
		FinalizePaymentRegistrationPage
	),
	false
);
