import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import LoginDetailsIcon from '../../../images/profile.svg';
import ConsentIcon from '../../../images/article.svg';
import AddressIcon from '../../../images/email.svg';
import DeregisterAccountIcon from '../../../images/trash.svg';
import HistoryIcon from '../../../images/time.svg';
import PaymentCardIcon from '../../../images/card.svg';

class Tile extends React.Component {
	static propTypes = {
		onOpen: PropTypes.func,
	};

	expandTile = async () => {
		const { tileName } = this.props;
		this.props.onOpen(tileName);
	};

	getTileIcon() {
		let currentIcon;

		switch (this.props.icon) {
			case 'profile':
				currentIcon = LoginDetailsIcon;
				break;
			case 'article':
				currentIcon = ConsentIcon;
				break;
			case 'email':
				currentIcon = AddressIcon;
				break;
			case 'trash':
				currentIcon = DeregisterAccountIcon;
				break;
			case 'time':
				currentIcon = HistoryIcon;
				break;
			case 'card':
				currentIcon = PaymentCardIcon;
				break;
			default:
				currentIcon = LoginDetailsIcon;
				break;
		}

		return currentIcon;
	}

	getGridStyling = () => {
		let { column, columnCount, row } = this.props;
		let justifySelf = 'center';
		let ieGridColumn;
		let marginTop = '0';
		let marginBottom = '1.25em';

		//Columns are 0-index based
		if (column === 0 && columnCount !== 1) justifySelf = 'end';

		if (
			(column === 1 && columnCount === 2) ||
			(column === 2 && columnCount === 3)
		)
			justifySelf = 'start';

		//IE needs explicit rows and columns for grid
		if (column === 0) ieGridColumn = 1;
		else if (column === 1) ieGridColumn = 3;
		else ieGridColumn = 5;

		//Rows for grids is 1-index based and needs to leave a gap for the ExpandableTileArea
		row = 2 * row + 1;

		if (row !== 1) marginTop = '1.25em';

		return {
			justifySelf,
			ieGridColumn,
			row,
			marginTop,
			marginBottom,
		};
	};

	render() {
		var icon = this.getTileIcon();
		let gridStyling = this.getGridStyling();

		return (
			<TileWrapper gridStyling={gridStyling}>
				<TileIcon
					src={icon}
					className="svg-icon"
					aria-hidden="true"
					focusable="false"
					alt=""
				/>

				<TileHeader>{this.props.tileHeader}</TileHeader>
				<TileBody>{this.props.tileBody}</TileBody>

				<div style={{ visibility: this.props.isOpen ? 'hidden' : 'visible' }}>
					<ExpandTileButton aria-expanded={this.props.isOpen ? true : false} onClick={this.expandTile} type="button"  >
						Inställningar{' '}
						<span className="sr-only" aria-hidden="false">: {this.props.tileHeader}</span>
						<span
							className="glyphicon glyphicon-chevron-down"
							aria-hidden="true"
						/>
					</ExpandTileButton>
				</div>
			</TileWrapper>
		);
	}
}

function mapStateToProps({ consent }) {
	return {
		consent,
	};
}

export default connect(
	mapStateToProps,
	null
)(Tile);

const TileWrapper = styled.div`
	padding: 3em 2.5em;
	border-radius: 8px;
	background-color: ${props => props.theme.white};
	box-shadow: 0 27px 36px 12px rgba(0, 0, 0, 0.22);
	text-align: center;
	position: relative; /* in order to position close button */

	margin-top: ${props => props.gridStyling.marginTop};
	margin-bottom: ${props => props.gridStyling.marginBottom};

	-ms-grid-column: ${props => props.gridStyling.ieGridColumn};
	-ms-grid-row: ${props => props.gridStyling.row};
	-ms-grid-row-align: center;
	-ms-grid-column-align: ${props => props.gridStyling.justifySelf};
	justify-self: ${props => props.gridStyling.justifySelf};
`;

const TileHeader = styled.h2`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 18px;
	line-height: 22px;
	text-align: center;
`;
const TileBody = styled.p`
	color: ${props => props.theme.dark_grey};
	font-family: ${props => props.theme.text};
	font-size: 14px;
	font-weight: 300;
	line-height: 17px;
	text-align: center;
`;

const ExpandTileButton = styled.button`
	border: 0;
	background: none;
	cursor: pointer;

	span {
		display: block;
	}
`;

const TileIcon = styled.img`
	margin: auto;
	height: 21px;
	width: 21px;
`;
