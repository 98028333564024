import React from 'react';
import styled from 'styled-components';

export const StepVisualizer = props => {
	const count = Array.apply(null, Array(props.steps));

	return (
		<Container className={props.className} style={props.style}>
			{count.map((i, idx) => {
				const current = idx + 1;
				if (current < props.activeStep) return <InactiveStep key={current} />;
				if (current === props.activeStep) return <ActiveStep key={current} />;
				return <Step key={current} />;
			})}
		</Container>
	)
}

const size = '16px';
const Step = styled.div`
	display: inline-block;
	min-height: ${size};
	min-width: ${size};
	max-height: ${size};
	max-width: ${size};
	border: 1px solid ${props => props.theme.lighter_grey};
	border-radius: 50%;
	padding: 2px;
	margin-right: 0.5em;
	
	&:last-child {
		margin-right: 0;
	}
`;

const ActiveStep = styled(Step)`
	border-color: ${props => props.theme.primary_color};
	background-color: ${props => props.theme.primary_color};
`;

const InactiveStep = styled(Step)`
	border-color: ${props => props.theme.lighter_grey};
	background-color: ${props => props.theme.lighter_grey};
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
	margin: 1em;
`;
