import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { actionCreators as walletActions, isLoadingPurse } from '../../../reducer/wallet';
import {
	getPurse,
} from '../../../reducer';
import { Error } from '../../common/elements/Error';
import { ReactComponent as ChevronLeft } from '../../../images/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../../images/chevron-right.svg';
import { Loading } from '../../common/blocks/Loading';
import { ProfileCardHeader } from './ProfileCardHeader';
import { TextInput } from '../../common/elements/TextInput';
import { CallToActionButton, BackButton, IconLinkButton } from './PaymentMethods';
import { formatCurrency } from '../../../utils/formatting';

class Purse extends Component {
	state = {
		voucher: '',
		isInvalidVoucher: true,
		expand: false,
		showError: false,
	};

	componentDidMount() {
		this.props.getPurse();
	}

	redeemVoucher = async () => {
		const responseOk = await this.props.redeemVoucher(this.state.voucher);

		if (responseOk) {
			this.props.renderConfirmationCard(<p>Din kundkassa har nu fyllts på</p>);
		} else {
			this.setState({ showError: true });
		}
	};

	handleVoucherChange = target => {
		const voucher = target.value;
		this.setState({
			voucher,
			isInvalidVoucher: voucher.length === 0,
		});
	};

	toggleView = () =>
		this.setState(state => ({
			expand: !state.expand,
			showError: false,
			isInvalidVoucher: false,
			voucher: '',
		}));

	render() {
		const { isLoadingPurse, purse } = this.props;
		const { expand, isInvalidVoucher, showError } = this.state;

		if (!expand) {
			return (
				<>
					<ProfileCardHeader>Kundkassa</ProfileCardHeader>

					{isLoadingPurse && <Loading />}

					{purse && (
						<>
							<p>
								Saldo: <strong>{formatCurrency(purse.totalBalance)}</strong>
							</p>

							<IconLinkButton onClick={this.toggleView}>
								Lös in värdekod
								<ChevronRightIcon
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
								/>
							</IconLinkButton>
						</>
					)}
				</>
			);
		}

		return (
			<>
				<ProfileCardHeader>Skriv in din värdekod</ProfileCardHeader>

				<div className="form-group">
					<label className="sr-only" htmlFor="voucher">
						Värdekod
					</label>
					<Input
						type="text"
						value={this.state.voucher}
						id="voucher"
						placeholder="Värdekod"
						handleChange={this.handleVoucherChange}
						helperText="Behöver innehålla 16 tecken."
					/>
				</div>

				<CallToActionButton
					onClick={this.redeemVoucher}
					disabled={isLoadingPurse || isInvalidVoucher}
				>
					Lös in
				</CallToActionButton>

				{isLoadingPurse && <Loading />}

				{showError && (
					<ErrorMessage>
						Det uppstod ett fel vid inlösen av värdekoden. Det kan bero på att du angett fel antal tecken, att värdekoden redan är förbrukad eller utgången. Vänligen försök igen eller kontakta kundservice.
					</ErrorMessage>
				)}

				<BackButton onClick={this.toggleView}>
					<ChevronLeft />
					Tillbaka
				</BackButton>
			</>
		);
	}
}

const Input = styled(TextInput)`
	width: 100%;
`;

const ChevronRightIcon = styled(ChevronRight)`
	fill: none;
	stroke: ${props => props.theme.textOnPrimaryColor};
`;

const ErrorMessage = styled(Error)`
	margin-top: 0;
`;

export default connect(
	store => ({
		purse: getPurse(store),
		isLoadingPurse: isLoadingPurse(store),
	}),
	{ ...walletActions, push }
)(Purse);
