import React, { Component } from 'react';
import styled from 'styled-components';
import { ActivationOptions } from './ActivationOptions';
import { Dropdown } from '../common/elements/Dropdown';
import { TravelCard } from '../common/blocks/TravelCard';
import { forTabletPortraitUp } from '../../utils/mediaqueries';
import AddressForm from './purchase/AddressForm';
import { BEARER_OPTIONS, isNoBearer, isNewBearer } from '../../reducer/cart';

export class BearerOptions extends Component {
	static noBearer = { title: 'Utan bärare', id: BEARER_OPTIONS.NONE };
	static newBearer = { title: 'Nytt resekort', id: BEARER_OPTIONS.NEW };

	state = {
		selectedBearerId: undefined,
		activationChoices: [
			{
				id: 'automatic',
				name: 'Väntande aktivering',
				description: this.props.texts.private.buyticketpage.beareroptions
					.activationinfo,
			},
			{
				id: 'manual',
				name: 'Aktivera nu',
				description: this.props.texts.private.buyticketpage.beareroptions
				.activationnowinfo,
			},
		],
		bearerOptions: [],
	};

	static getDerivedStateFromProps(props, state) {
		const { bearers, texts } = props;
		const hasBearerState = state.bearerOptions.length > 0;

		if (!bearers || hasBearerState) return null;

		const showNewBearerOption = texts.private.buyticketpage.beareroptions.hidenewcardbearer !== "True";

		const bearerOptions = [
			...bearers.map(b => ({ title: b.name, id: b.id })),
			...(showNewBearerOption ? [BearerOptions.newBearer] : []),
			BearerOptions.noBearer,
		];

		return { bearerOptions };
	}

	onSelect = item => {
		const { selectedBearerId, bearerOptions } = this.state;

		if (selectedBearerId !== item.id) {
			const activationChoices = this.getUpdatedActivationChoices(item.id);

			this.setState(
				{
					selectedBearerId: item.id,
					bearerOptions: this.setSelectedBearer(bearerOptions, item.id),
					activationChoices,
				},
				() => this.props.onSelectBearer(this.state.selectedBearerId)
			);
		}
	};

	getUpdatedActivationChoices = selectedBearerId => {
		const { activationChoices } = this.state;
		return activationChoices.map((c, idx) => {
			const onlyManualActivation =
				isNoBearer(selectedBearerId) || isNewBearer(selectedBearerId);
			return {
				...c,
				disabled: onlyManualActivation,
				...(onlyManualActivation && { selected: idx === 0 }),
			};
		});
	};

	setSelectedBearer = (choices, selected) => {
		return choices.map(c => {
			if (c.id === selected) {
				return { ...c, selected: true };
			}
			return { ...c, selected: false };
		});
	};

	setActivation = choice => {
		const { activationChoices } = this.state;
		let isManual = choice === 'manual' ? false : true;

		this.props.onSetManualActivation(isManual);

		this.setState({
			activationChoices: activationChoices.map(c => ({
				...c,
				selected: c.id === choice,
			})),
		});
	};

	setAddress = address => this.props.onUpdateAddress(address);

	render() {
		const { bearers, texts } = this.props;
		const { selectedBearerId, activationChoices, bearerOptions } = this.state;
		const selectedBearer = bearers.find(b => b.id === selectedBearerId);

		if (bearerOptions.length === 0) return null;

		return (
			<>
				<H2>
					{texts.private.buyticketpage.beareroptions.ticketusage}
				</H2>

				<UsageContainer>
					<Options>
						<BearerDropdown
							id="bearers"
							items={bearerOptions}
							onSelect={this.onSelect}
							renderSelectedItem={item => <P>{item.title}</P>}
							renderItem={item => <P>{item.title}</P>}
						/>

						<ActivationOptions
							activationChoices={activationChoices}
							defaultChoice={activationChoices[0].id}
							onSelect={this.setActivation}
						/>
					</Options>
					<BearerBox>
						{selectedBearer && <TravelCard bearer={selectedBearer} />}
						{isNoBearer(selectedBearerId) && <NoBearer>Utan resekort</NoBearer>}
						{isNewBearer(selectedBearerId) && (
							<NoBearer>
								<p>
								{
									this.props.texts.private.buyticketpage.beareroptions
										.newtravelcard
								}
								</p>
							</NoBearer>
						)}
					</BearerBox>
				</UsageContainer>

				{isNewBearer(selectedBearerId) && (
					<AddressForm style={{ marginTop: '1em' }} />
				)}
			</>
		);
	}
}

const H2 = styled.h2`
	font-weight: 300;
	margin-bottom: 1em;
`;

const UsageContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	${forTabletPortraitUp`
		flex-direction: row;
	`}
`;

const Options = styled.div`
	min-width: 200px;

	${forTabletPortraitUp`
		width: 47%;
	`}
`;

const BearerDropdown = styled(Dropdown)`
	margin-bottom: 1em;
`;

const BearerBox = styled.div`
	max-width: 245px;
	width: 100%;
	height: 120px;
	align-self: center;

	${forTabletPortraitUp`
		width: 47%;
		align-self: flex-start;
	`}
`;

const NoBearer = styled.p`
	height: 100%;
	border: solid 1px ${props => props.theme.primary_color};
	box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.13);
	border-radius: 7px;
	min-width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1em;

	p {
		margin: 0;
	}
`;

const P = styled.p`
margin: 0;
`;
