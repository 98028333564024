import React, { Component } from 'react';
import styled from 'styled-components';
import { Card } from '../../common/blocks/Card';
import { connect } from 'react-redux';
import withTextContext from '../../../utils/withTextContext';
import { StepVisualizer } from '../../common/blocks/StepVisualizer';
import { forSmallPhoneOnly, forPhoneOnly } from '../../../utils/mediaqueries';
import { LinkButton } from '../../common/elements/LinkButton';
import { SecondaryButton } from '../../common/elements/SecondaryButton';
import { TextInput } from '../../common/elements/TextInput';
import { Radiobuttons } from '../../common/elements/Radiobuttons';
import { Validation } from '../../../utils/validation';
import { formatTime } from '../../../utils/formatting';
import {
	actionCreators as cultureTripActions,
	contactHasApp,
	isLoadingAppCheck,
	departureJourney,
	returnJourney,
} from '../../../reducer/school/culturetrips';
import { Error } from '../../common/elements/Error';
import { Loading } from '../../common/blocks/Loading';
import { SCHOOL } from '../../../Paths';
import { push } from 'connected-react-router';
import CultureTripWrapper from './CultureTripWrapper';

export const TICKET_DELIVERY_CHOICES = {
	APP: {
		id: 'APP',
		label: `Leverans av biljett till app`,
	},
	EMAIL: {
		id: 'EMAIL',
		label: `Leverans av biljett till e-post för egen utskrift`,
	},
	// TRAVELCARD: {
	// 	id: 'TRAVELCARD',
	// 	label: `Leverans av biljett till resekort`,
	// },
};

//Step 4 in form
class ContactInfo extends Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef();
	}

	state = {
		name: '',
		phoneNumber: '',
		email: '',
		modeOfDelivery: TICKET_DELIVERY_CHOICES.APP,
		errorText: undefined,
	};

	componentDidMount = () => {
		if (!this.props.returnJourney) {
			this.props.push(SCHOOL.CULTURE_TRIP_RETURN);
		} else {
			this.containerRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	};

	submitContactInfo = e => {
		e.preventDefault();
		if (this.state.modeOfDelivery === TICKET_DELIVERY_CHOICES.APP)
			this.ensureContactHasApp();
		else {
			this.saveAndContinue();
		}
	};

	ensureContactHasApp = async () => {
		try {
			if (this.props.contactHasApp === undefined)
				await this.props.checkContactPersonHasApp(this.state.phoneNumber);

			if (this.props.contactHasApp) this.saveAndContinue();
			else this.showNoAppError();
		} catch (error) {
			this.showNoAppError();
		}
	};

	showNoAppError = () =>
		this.setState({
			errorText: 'Ingen app hittades för angivet telefonnummer',
		});

	saveAndContinue = () => {
		const { name, phoneNumber, email, modeOfDelivery } = this.state;

		this.props.setContactInfo({
			name,
			phoneNumber,
			email,
			modeOfDelivery,
		});
		this.props.push(SCHOOL.CULTURE_TRIP_SUMMARY);
	};

	back = e => {
		e.preventDefault();
		this.props.push(SCHOOL.CULTURE_TRIP_RETURN);
	};

	handleChange = target =>
		this.setState({
			[target.id]: target.value,
		});

	handlePhoneNumberChange = target => {
		this.props.clearAppCheck();
		this.setState({
			[target.id]: target.value,
		});
	};

	handleBlur = ({ target }) =>
		this.setState({
			[`${target.id}Valid`]: this.isFieldValid(target.id, target.value),
		});

	isFieldValid = (field, value) => this.validators[field](value);

	validators = {
		email: value => Validation.email.test(value),
		name: value => value.length > 1,
		phoneNumber: value =>
			Validation.mobile.test(value) && Validation.onlyDigits.test(value),
	};

	setDeliveryMethod = choice => {
		this.props.clearAppCheck();
		this.setState({
			modeOfDelivery: Object.values(TICKET_DELIVERY_CHOICES).find(
				c => c.id === choice
			),
			errorText: undefined,
		});
	};

	render() {
		const {
			departureJourney,
			returnJourney,
			isLoadingAppCheck,
			texts,
		} = this.props;
		const {
			name,
			phoneNumber,
			email,
			nameValid,
			phoneNumberValid,
			emailValid,
			errorText,
		} = this.state;

		if (!departureJourney || !returnJourney) return <Loading />;

		const radiobuttonFilterChoices = Object.values(TICKET_DELIVERY_CHOICES);

		// The markup for the Step 4 UI
		return (
			<CultureTripWrapper>
				<Container ref={this.containerRef}>
					<CardBox>
						<Header>Ansökan om kulturresor</Header>
						<p>
							Framresa:{' '}
							<b>
								{departureJourney.from} - {departureJourney.to}{' '}
								{formatTime(departureJourney.departure)}
							</b>
						</p>
						<p>
							Återresa:{' '}
							<b>
								{returnJourney.from} - {returnJourney.to}{' '}
								{formatTime(returnJourney.departure)}
							</b>
						</p>
						<SubHeader>Kontaktperson</SubHeader>

						<FormContainer>
							<div className="form-group">
								<FormTextRequired>Namn</FormTextRequired>
								<TextInput
									type="text"
									value={name}
									handleChange={this.handleChange}
									className="form-control"
									id="name"
									placeholder="För- och efternamn"
									onBlur={this.handleBlur}
								/>
								{typeof nameValid !== 'undefined' && !nameValid && (
									<ErrorMessage>
										För- och efternamnet är inte rätt ifylld*
									</ErrorMessage>
								)}
							</div>
							<div className="form-group">
								<FormTextRequired>Telefonnummer</FormTextRequired>
								<TextInput
									type="tel"
									value={phoneNumber}
									handleChange={this.handlePhoneNumberChange}
									className="form-control"
									id="phoneNumber"
									placeholder="46712345678"
									onBlur={this.handleBlur}
								/>
								{typeof phoneNumberValid !== 'undefined' &&
									!phoneNumberValid && (
										<ErrorMessage>
											Telefonnummer är inte rätt ifylld*
										</ErrorMessage>
									)}
							</div>
							<div className="form-group">
								<FormTextRequired>E-post</FormTextRequired>
								<TextInput
									type="email"
									value={email}
									handleChange={this.handleChange}
									className="form-control"
									id="email"
									placeholder="E-post"
									onBlur={this.handleBlur}
								/>
								{typeof emailValid !== 'undefined' && !emailValid && (
									<ErrorMessage>Ange korrekt e-post*</ErrorMessage>
								)}
							</div>

							<TypeDescription>
								<Radiobuttons
									key={'radiobuttonFilter'}
									choices={radiobuttonFilterChoices}
									defaultChoice={radiobuttonFilterChoices[0].id}
									onSelect={this.setDeliveryMethod}
									renderChoice={(choice, renderRadiobutton) => (
										<RadioButtonContainer>
											{renderRadiobutton()}

											<span>{choice.label}</span>
											{choice === TICKET_DELIVERY_CHOICES.APP && (
												<RadioButtonExtraText>
													{texts.school.culturetrippage.contactinfo.appdelivery}
												</RadioButtonExtraText>
											)}
										</RadioButtonContainer>
									)}
								/>
							</TypeDescription>
						</FormContainer>
						{errorText && <Error>{errorText}</Error>}
						{isLoadingAppCheck && <Loading />}
						<Button
							onClick={this.submitContactInfo}
							disabled={
								!this.isFieldValid('name', name) ||
								!this.isFieldValid('phoneNumber', phoneNumber) ||
								!this.isFieldValid('email', email) ||
								isLoadingAppCheck
							}
						>
							Skicka förfrågan
						</Button>
						<LinkButton onClick={this.back}>&lsaquo; Tillbaka</LinkButton>
						<StepVisualizer activeStep={4} steps={5} />
					</CardBox>
				</Container>
			</CultureTripWrapper>
		);
	}
}

const Header = styled.h3`
	margin-top: 0;
	margin-bottom: 1em;
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
`;

const FormContainer = styled.div`
	margin: 0 60px;

	${forPhoneOnly`
		margin: 0;
	`}
`;

const SubHeader = styled.h4`
	text-align: center;
	margin-top: 70px;
`;

const FormTextRequired = styled.div`
	text-align: left;
	margin-top: 10px;
	font-size: 14px;
	:after {
		content: ' *';
		color: black;
	}
`;

const CardBox = styled(Card)`
	max-width: 570px;
	width: 100%;
	padding: 3em;

	${forSmallPhoneOnly`
		box-shadow: none;
		border: none;
		background: none;
		padding: 0;
	`}
`;

const Button = styled(SecondaryButton)`
	width: 100%;
	max-width: 300px;
	margin: 1em auto;
`;

const ErrorMessage = styled.p`
	margin-top: 0.5em;
	color: crimson;
	text-align: left;
`;

const TypeDescription = styled.div`
	flex-grow: 1;
	text-align: left;
	margin-top: 50px;

	span {
		display: block;
		margin-bottom: 10px;
	}

	i {
		font-size: 0.8em;
	}
`;

const RadioButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const RadioButtonExtraText = styled.p`
	flex-basis: 100%;
	padding-left: 30px;
	font-style: italic;
`;

export default withTextContext(
	connect(
		store => ({
			contactHasApp: contactHasApp(store),
			isLoadingAppCheck: isLoadingAppCheck(store),
			departureJourney: departureJourney(store),
			returnJourney: returnJourney(store),
		}),
		{ ...cultureTripActions, push }
	)(ContactInfo)
);
