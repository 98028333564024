import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { UnstyledList } from '../common/elements/UnstyledList';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { ReactComponent as TicketIcon } from '../../images/ticket.svg';
// import { ReactComponent as ArticleIcon } from '../../images/article.svg';
import { ReactComponent as CardIcon } from '../../images/card.svg';
import { PRIVATE } from '../../Paths';
import { connect } from 'react-redux';
import { CloseMenuButton } from './Root';
import FocusTrap from "./../common/FocusTrap"

class CustomerMobileNavigation extends Component {
	static propTypes = {
		onNavigation: PropTypes.func,
	};

	navHandler = () => {
		this.props.onNavigation();
	};

	render() {
		return (
			<Fragment>
				<MenuHeader>Meny</MenuHeader>
				<MenuList>
					<FocusTrap isNavOpen={this.props.isNavOpen}>
						<li>
							<CustomNavLink
								to={PRIVATE.TICKET}
								activeClassName="active"
								onClick={this.navHandler}
							>
								<TicketIconMenu
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
								/>
								<span>Mina biljetter</span>
							</CustomNavLink>
						</li>
						<li>
							<CustomNavLink
								to={PRIVATE.PURCHASE_OFFER}
								activeClassName="active"
								onClick={this.navHandler}
							>
								<CardIconMenu
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
								/>
								<span>Köp</span>
							</CustomNavLink>
						</li>
						{/* <l>
							<NavLink
								to={PRIVATE.ISSUES}
								activeClassName="active"
								onClick={this.navHandler}
							>
								<ArticleIconMenu
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								<span>Mina ärenden</span>
							</NavLink>
							</l> */}
						<li>
							<CustomNavLink
								to={PRIVATE.PROFILE}
								activeClassName="active"
								onClick={this.navHandler}
							>
								<ProfileIconMenu
									className="svg-icon"
									aria-hidden="true"
									focusable="false"
									alt=""
								/>
								<span>Min profil</span>
							</CustomNavLink>
						</li>
						<li>
							<CloseMenuButton
								onClick={this.props.onNavigation}
								type="button"
								className="close"
								aria-label="Stäng"
							>
								<span aria-hidden="true">&times;</span>
							</CloseMenuButton>
						</li>
					</FocusTrap>
				</MenuList>
			</Fragment>
		);
	}
}

const TicketIconMenu = styled(TicketIcon)`
	fill: ${props => props.theme.textOnPrimaryColor};
`;
const CardIconMenu = styled(CardIcon)`
	fill: ${props => props.theme.textOnPrimaryColor};
`;
// const ArticleIconMenu = styled(ArticleIcon)`
// 	fill: ${props => props.theme.textOnPrimaryColor};
// `;
const ProfileIconMenu = styled(ProfileIcon)`
	fill: ${props => props.theme.textOnPrimaryColor};
`;

const MenuList = styled(UnstyledList)`
	a {
		color: ${props => props.theme.textOnPrimaryColor};
		text-decoration: none;
		padding: 3px 0;
		display: inline-block;
		margin: 5px auto;
	}

	a.active span {
		border-bottom: solid 2px ${props => props.theme.textOnPrimaryColor};
		padding-bottom: 2px;
	}

	.svg-icon {
		margin-right: 0.5em;
	}

	.svg-icon path {
		fill: ${props => props.theme.textOnPrimaryColor};
	}
`;

const MenuHeader = styled.h2`
	color: ${props => props.theme.textOnPrimaryColor};
	border-bottom: solid 2px ${props => props.theme.textOnPrimaryColor};
	padding-bottom: 10px;
	display: inline-block;
	margin-bottom: 1em;
`;

const CustomNavLink = styled(NavLink)`
	&:focus-visible {
	outline: 2px solid #000;
		//${props => props.theme.textOnPrimaryColor};
		outline-offset: 2px;
	}
`

//Connect is needed for navlink to update after redirect from /callback
export default connect(({ router }) => ({ location: router.location }))(
	CustomerMobileNavigation
);
