import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Radiobuttons } from '../../common/elements/Radiobuttons';
import { Dropdown } from '../../common/elements/Dropdown';
import {
	studentHasTickets,
	hasLoadedTicketsForGroup,
	studentHasTicketType,
	studentHasNoTicketType,
} from '../../../reducer/students';
import { connect } from 'react-redux';
import {
	actionCreators,
	hasTicketTypes,
	getTicketTypes,
	isLoadingTicketTypes,
} from '../../../reducer/school';

export const RADIOBUTTON_FILTER_CHOICES = {
	NO_TICKETS: 'NO_TICKETS',
	HAS_TICKETS: 'HAS_TICKETS',
};

export class TicketPageFilterContainer extends React.Component {
	state = {
		noTicketCount: 0,
		hasTicketCount: 0,
		ticketFilter: [],
		ticketTypesWithCount: [],
		students: [],
	};

	componentDidMount() {
		const { hasTicketTypes } = this.props;
		if (!hasTicketTypes) this.props.getTicketTypes();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			students,
			ticketTypes,
			studentHasTickets,
			studentHasTicketType,
			studentHasNoTicketType
		} = nextProps;

		if (isEqual(prevState.students, students)) return null;

		const ticketTypeOccuranceCount = (students, ticketType) =>
			students.filter(s => studentHasTicketType(s, ticketType)).length;
		const noTicketCount = students.reduce((count, id) => (!studentHasTickets(id) ? ++count : count), 0);
		const hasTicketCount = students.reduce((count, id) => (studentHasTickets(id) ? ++count : count), 0);
		const hasNoTicketTypeCount = students.reduce((count, id) => (studentHasNoTicketType(id) ? ++count : count), 0);

		const ticketTypesWithCount = [
			{
				id: 'ALL',
				name: 'Alla',
				count: students.length,
			},
			...(ticketTypes || []).reduce((list, type) => {
				const count = ticketTypeOccuranceCount(students, type.name);

				return count > 0
					? [...list, { id: type.id, name: type.name, count }]
					: list;
			}, []),
		];

		hasNoTicketTypeCount > 0 && ticketTypesWithCount.push({ id: 'NONE', name: 'Saknar biljettyp', count: hasNoTicketTypeCount})

		return {
			noTicketCount,
			hasTicketCount,
			ticketFilter: [
				{
					id: RADIOBUTTON_FILTER_CHOICES.NO_TICKETS,
					label: `Elever utan biljetter (${noTicketCount} st)`,
				},
				{
					id: RADIOBUTTON_FILTER_CHOICES.HAS_TICKETS,
					label: `Elever med befintliga biljetter (${hasTicketCount} st)`,
				},
			],
			ticketTypesWithCount,
			students,
		};
	}

	renderTicketType = item => (
			<span>
				{item.title} ({item.count} st)
			</span>
		);

	render() {
		const {
			groupId,
			students,
			hasLoadedGroupTickets,
			isLoadingTicketTypes,
		} = this.props;
		const { ticketFilter, ticketTypesWithCount } = this.state;

		if (!hasLoadedGroupTickets(students) || isLoadingTicketTypes) return null;

		return (
			<TicketFilterContainer>
				<TicketFilterText>Visa:</TicketFilterText>
				<Radiobuttons
					key={'radiobuttonFilter'}
					choices={ticketFilter}
					defaultChoice={ticketFilter[0].id}
					onSelect={choice => this.props.onSelectRadiobuttonFilter(choice)}
					renderChoice={(choice, renderRadiobutton) => (
						<>
							<span>{choice.label}</span>

							{renderRadiobutton()}
						</>
					)}
				/>{' '}
				{ticketTypesWithCount.length > 0 && (
					<TicketTypeFilterContainer>
						<TicketTypeFilterText>
							Biljettyp
						</TicketTypeFilterText>
						<DropDownTicketType
							key={groupId}
							id="ticketTypes"
							items={ticketTypesWithCount.map(t => ({ ...t, title: t.name }))}
							onSelect={this.props.onSelectTicketType}
							renderSelectedItem={this.renderTicketType}
							renderItem={this.renderTicketType}
							disabled={isEmpty(ticketTypesWithCount)}
						/>
					</TicketTypeFilterContainer>
				)}
			</TicketFilterContainer>
		);
	}
}

const DropDownTicketType = styled(Dropdown)`
	width: 300px;
`;

const TicketFilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	font-family: ${props => props.theme.text};
	margin-left: 52px;

	label {
		color: #0a6ab3;
	}

	> * {
		margin: 10px;
		> * {
			margin: 0 3px;
		}
	}
`;

const TicketFilterText = styled.p`
	color: ${props => props.theme.text_color};
	font-weight: 600;
`;

const TicketTypeFilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-left: auto;
	max-width: 300px;
`;

const TicketTypeFilterText = styled.p`
	color: #656565;
	font-weight: 600;
	font-size: 12px;
`;

export default connect(
	store => ({
		studentHasTickets: studentHasTickets(store),
		hasLoadedGroupTickets: hasLoadedTicketsForGroup(store),
		ticketTypes: getTicketTypes(store),
		hasTicketTypes: hasTicketTypes(store),
		isLoadingTicketTypes: isLoadingTicketTypes(store),
		studentHasTicketType: studentHasTicketType(store),
		studentHasNoTicketType: studentHasNoTicketType(store),
	}),
	{ ...actionCreators }
)(TicketPageFilterContainer);
